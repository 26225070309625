import {EVENT_DUPLICATE_EVOLUTION, EVENT_EDIT_EVOLUTION} from 'amplitude';
import amplitude from 'amplitude-js';
import Button from 'components/Button';
import {PermissionsPost} from 'constants/permissions';
import dayjs from 'dayjs';
import {hasFlag} from 'helpers/bitwise';
import {hasPermissions} from 'helpers/permission';
import {nanoid} from 'nanoid';
import {func, object} from 'prop-types';
import {useHistory} from 'react-router-dom';
import {
  ROUTE_BUILDER_CREATE,
  ROUTE_POKE_BUILDER_FROM_TYPE,
  ROUTE_POST_BUILDER_WRITER,
} from 'router/routes.const';
import Survey from 'scenes/Portal/components/Survey';
import {evolutionService} from 'services';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_OPTION_COMMENTS_ENABLED,
  F_OPTION_LIKE_ENABLED,
} from 'services/evolution';
import {Post} from 'shared/front/components/Post';
import './_Styles.scss';

export const DEFAULT_TITLE = 'Title of your ideation';
export const DEFAULT_DESCRIPTION =
  'Write a short but clear description about your ideation!';
export const DEFAULT_QUESTION = 'Question of your step ?';
export const DEFAULT_ADDITIONAL_INFORMATION =
  'Any additional information relevant for the question you are asking';

const propTypes = {
  evolution: object.isRequired,
  onClick: func,
};

const defaultProps = {
  onClick: () => {},
};

export const draftTag = {
  uid: nanoid(),
  color: 'grey',
  name: 'Draft',
};

export const surveyTag = {
  uid: nanoid(),
  color: 'blue',
  name: 'Survey',
};

export const scheduledTag = (date) => ({
  uid: nanoid(),
  color: 'blue',
  name: `${new Date(date).toLocaleDateString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })} at  ${new Date(date).toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
  })}`,
});

export const expiredTag = (date) => {
  const curDate = new Date();
  const expirationDate = new Date(date);

  if (expirationDate.getTime() < curDate.getTime()) {
    return {
      uid: nanoid(),
      color: 'red',
      name: 'Expired',
    };
  }

  return {
    uid: nanoid(),
    color: 'orange',
    name: `Expires ${dayjs(date).fromNow()}`,
  };
};

const Evolution = ({evolution, onClick, onDelete}) => {
  const history = useHistory();

  let {analytics, isDraft} = evolution;

  const handleNextStep = () => {
    return;
  };
  const handleDuplicate = async (evolutionId) => {
    amplitude.getInstance().logEvent(EVENT_DUPLICATE_EVOLUTION, {
      from: 'EvolutionList',
    });
    const createdEvolution = await evolutionService.duplicateEvolution(
      evolutionId
    );
    if (
      [
        EVOLUTION_TYPE_TOUR,
        EVOLUTION_TYPE_SURVEY,
        EVOLUTION_TYPE_BANNER,
        EVOLUTION_TYPE_HINT,
      ].includes(createdEvolution.type)
    ) {
      history.push(
        ROUTE_POKE_BUILDER_FROM_TYPE(
          createdEvolution.uid,
          createdEvolution.type
        )
      );
    } else {
      history.push(ROUTE_POST_BUILDER_WRITER(createdEvolution.uid));
    }
  };

  const step = evolution.steps.sort((a, b) => a.indexOrder - b.indexOrder)[0];
  const progress = Math.ceil(((0 + 1) / evolution.steps.length) * 100);
  const isLive = isDraft === false;
  const isScheduled =
    evolution.lastStepChangeAt != null &&
    dayjs(evolution.lastStepChangeAt).isAfter(dayjs());
  const isExpired =
    evolution.expiresAt != null && dayjs(evolution.expiresAt).isBefore(dayjs());

  const isOldVersionPost = !(evolution?.steps?.[0]?.blocks?.length > 0);

  return (
    <div className="list-evolution-card" onClick={onClick}>
      <div className="actions-wrapper">
        {hasPermissions(PermissionsPost.CREATE_EDIT) && (
          <>
            <Button
              rounded={false}
              iconLeft="icon-edit-outline"
              onClick={(e) => {
                e.stopPropagation();
                amplitude.getInstance().logEvent(EVENT_EDIT_EVOLUTION, {
                  from: 'EvolutionList',
                });
                if (isOldVersionPost === true) {
                  history.push(
                    ROUTE_BUILDER_CREATE({evolutionId: evolution.uid})
                  );
                } else {
                  history.push(ROUTE_POST_BUILDER_WRITER(evolution.uid));
                }
              }}>
              edit
            </Button>
            {isOldVersionPost === false && (
              <Button
                rounded={false}
                iconLeft="icon-duplicate"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDuplicate(evolution.uid);
                }}>
                duplicate
              </Button>
            )}
          </>
        )}
        {hasPermissions(PermissionsPost.DELETE) && (
          <Button
            danger
            iconOnly
            rounded={false}
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}>
            <i className="icon-trash"></i>
          </Button>
        )}
      </div>
      <div className="top">
        <div className="post-labels">
          {isDraft === true && (
            <div className="label body-4 is-draft">Draft</div>
          )}
          {isLive === true && isScheduled === false && isExpired === false && (
            <div className="label body-4 is-live">Live</div>
          )}
          {isLive === true && isScheduled === true && (
            <div className="label body-4 is-scheduled">
              Scheduled for{' '}
              {dayjs(evolution.lastStepChangeAt).format(
                'DD MMM YYYY [at] HH:mm'
              )}
            </div>
          )}
          {isLive === true && isExpired === true && (
            <div className="label body-4 is-expired">
              Expired since{' '}
              {dayjs(evolution.expiresAt).format('DD MMM YYYY [at] HH:mm')}
            </div>
          )}
        </div>
        {isOldVersionPost === true ? (
          <Survey
            evolution={evolution}
            step={step}
            stepIndex={0}
            progress={progress}
            onVote={handleNextStep}
          />
        ) : (
          <Post post={evolution} hideDate={evolution.isDraft} />
        )}
      </div>
      {isDraft === false && (
        <>
          <div className="statistics">
            <div className="stat-item stat-views">
              <i className="isax isax-eye"></i>
              <span>{analytics?.viewsCount} views</span>
            </div>
            {hasFlag(F_OPTION_LIKE_ENABLED, evolution.optionsFlags) ===
              true && (
              <div className="stat-item stat-likes">
                <i className="isax isax-heart"></i>
                <span>{evolution.countVotes || 0} likes</span>
              </div>
            )}
            {hasFlag(F_OPTION_COMMENTS_ENABLED, evolution.optionsFlags) ===
              false && (
              <div className="stat-item stat-likes">
                <i className="isax isax-message-remove"></i>
                <span>Comment disabled</span>
              </div>
            )}
            {evolution?.segments?.length > 0 && (
              <div className="stat-item stat-segments">
                <i className="isax isax-profile-2user"></i>
                <div className="segments-list">
                  {evolution.segments.map((s) => s.name).join(' - ')}
                </div>
              </div>
            )}
            {evolution?.onTheFlySegment != null && (
              <div className="stat-item stat-segments">
                <i className="isax isax-profile-2user"></i>
                On the fly segment
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

Evolution.propTypes = propTypes;
Evolution.defaultProps = defaultProps;

export default Evolution;
