import React from 'react';
import classnames from 'classnames';
import {object, func} from 'prop-types';
import './_Styles.scss';
import {OptionItem} from '../..';
import {InnerLabeledInput} from 'scenes/Settings/components/LabeledInput';
import Input from 'components/Input';
import {hasFlag} from 'helpers/bitwise';
import {F_BOOST_SLOT_DOT} from 'services/evolution';

const propTypes = {
  evolution: object.isRequired,
  setEvolution: func.isRequired,
};

const defaultProps = {};

const positionOptions = [
  {position: 'top-left', value: 1},
  {position: 'top', value: 2},
  {position: 'top-right', value: 4},
  {position: 'right', value: 8},
  {position: 'bottom-right', value: 16},
  {position: 'bottom', value: 32},
  {position: 'bottom-left', value: 64},
  {position: 'left', value: 128},
  {position: 'right-top', value: 512},
  {position: 'right-bottom', value: 1024},
  {position: 'left-bottom', value: 2048},
  {position: 'left-top', value: 4096},
];

const Alignement = ({evolution, setEvolution}) => {
  const isHotspot = hasFlag(F_BOOST_SLOT_DOT, evolution?.boostFlags);

  const isAuto = evolution?.boostedPositionFlags === 0;
  const isCustom = evolution?.boostedPositionFlags === 256;
  const isFixed = isAuto !== true && isCustom !== true;

  const [offsetX, offsetY, offsetCardX = 0, offsetCardY = 0] = (
    evolution?.boostedPositionOffsets || '0;0;0;0'
  ).split(';');

  const selectedAlignement = positionOptions.find(
    (o) => o.value === evolution?.boostedPositionFlags
  );

  return (
    <>
      <div className="alignement-type-options">
        {isHotspot !== true && (
          <div
            className={classnames('option', {selected: isAuto === true})}
            onClick={() => {
              setEvolution({...evolution, boostedPositionFlags: 0});
            }}>
            Auto
          </div>
        )}
        <div
          className={classnames('option', {selected: isFixed === true})}
          onClick={() => {
            if (isFixed !== true) {
              setEvolution({...evolution, boostedPositionFlags: 1});
            }
          }}>
          Fixed
        </div>
        <div
          className={classnames('option', {selected: isCustom === true})}
          onClick={() => {
            setEvolution({...evolution, boostedPositionFlags: 256});
          }}>
          Custom
        </div>
      </div>

      {isCustom && (
        <>
          {isHotspot ? (
            <>
              <OptionItem>
                <InnerLabeledInput title="Hotspot offset X">
                  <Input
                    className="input-with-blue-label"
                    labelTextLeft="X"
                    type="number"
                    labelTextRight="px"
                    value={offsetX}
                    onChange={({target}) =>
                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${target.value};${offsetY};${offsetCardX};${offsetCardY}`,
                      })
                    }
                  />
                </InnerLabeledInput>
                <InnerLabeledInput title="Hotspot offset Y">
                  <Input
                    className="input-with-blue-label"
                    labelTextLeft="Y"
                    type="number"
                    labelTextRight="px"
                    value={offsetY}
                    onChange={({target}) =>
                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${offsetX};${target.value};${offsetCardX};${offsetCardY}`,
                      })
                    }
                  />
                </InnerLabeledInput>
              </OptionItem>
              <OptionItem>
                <InnerLabeledInput title="Card offset X">
                  <Input
                    className="input-with-blue-label"
                    labelTextLeft="X"
                    type="number"
                    labelTextRight="px"
                    value={offsetCardX || 0}
                    onChange={({target}) =>
                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${offsetX};${offsetY};${target.value};${offsetCardY}`,
                      })
                    }
                  />
                </InnerLabeledInput>
                <InnerLabeledInput title="Card offset Y">
                  <Input
                    className="input-with-blue-label"
                    labelTextLeft="Y"
                    type="number"
                    labelTextRight="px"
                    value={offsetCardY || 0}
                    onChange={({target}) =>
                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${offsetX};${offsetY};${offsetCardX};${target.value}`,
                      })
                    }
                  />
                </InnerLabeledInput>
              </OptionItem>
            </>
          ) : (
            <OptionItem>
              <InnerLabeledInput title="Offset X">
                <Input
                  className="input-with-blue-label"
                  labelTextLeft="X"
                  type="number"
                  labelTextRight="px"
                  value={offsetX}
                  onChange={({target}) =>
                    setEvolution({
                      ...evolution,
                      boostedPositionOffsets: `${target.value};${offsetY}`,
                    })
                  }
                />
              </InnerLabeledInput>
              <InnerLabeledInput title="Offset Y">
                <Input
                  className="input-with-blue-label"
                  labelTextLeft="Y"
                  type="number"
                  labelTextRight="px"
                  value={offsetY}
                  onChange={({target}) =>
                    setEvolution({
                      ...evolution,
                      boostedPositionOffsets: `${offsetX};${target.value}`,
                    })
                  }
                />
              </InnerLabeledInput>
            </OptionItem>
          )}
        </>
      )}
      {isFixed && (
        <div className="alignement-figure-wrapper">
          <div className="alignement-figure">
            <div className="alignement-label">
              {selectedAlignement != null ? (
                <>Always show from {selectedAlignement.position}</>
              ) : (
                <>Select a position</>
              )}
            </div>
            {positionOptions.map((o) => {
              if (isHotspot && o.value > 128) {
                return <></>;
              }
              return (
                <div
                  className={classnames('position-dot', o.position, {
                    selected: evolution?.boostedPositionFlags === o.value,
                  })}
                  onClick={() =>
                    setEvolution({...evolution, boostedPositionFlags: o.value})
                  }
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

Alignement.propTypes = propTypes;
Alignement.defaultProps = defaultProps;

export default Alignement;
