import ProgressBar from '@ramonak/react-progress-bar';
import classnames from 'classnames';
import Button from 'components/Button';
import CustomSlider from 'components/CustomSlider';
import Divider from 'components/Divider';
import {MarkdownContent} from 'components/Markdown';
import Nps from 'components/Nps';
import {TagButton} from 'components/TagButton';
import TextArea from 'components/TextArea';
import {VOTE_IDK, VOTE_NAH, VOTE_YEAH} from 'constants/index';
import colorContrast from 'font-color-contrast';
import {hasFlags} from 'helpers/bitwise';
import {array, bool, func, object} from 'prop-types';
import {useEffect, useState} from 'react';
import {
  defaultCustomization,
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOOLTIP,
} from 'services/evolution';
import {
  STEP_TYPE_CONCEPT_TEST,
  STEP_TYPE_INTERVIEW,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_BLOCK,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import {EmojiIdk, EmojiNah, EmojiYeah} from 'shared/front/components/Emojis';
import Success from './components/Sucess';
import Vote from './components/Vote';
import './_styles.scss';

const propTypes = {
  step: object.isRequired,
  progress: Number.isRequired,
  onVote: func.isRequired,
  responses: array,
  customization: object,
  steps: array.isRequired,
  tags: array,
  evolution: object,
  withLikesAndComments: bool,
};

const defaultProps = {
  responses: [],
  customization: defaultCustomization,
  tags: [],
  evolution: {},
  withLikesAndComments: false,
};

const defaultSecondaryColor = '#000000';
const operators = {
  EQUAL_TO: 'EQUAL_TO',
  NOT_EQUAL_TO: 'NOT_EQUAL_TO',
  GREATER_THAN: 'GREATER_THAN',
  LESSER_THAN: 'LESSER_THAN',
  CONTAINS: 'CONTAINS',
  DOES_NOT_CONTAIN: 'DOES_NOT_CONTAIN',
};

const PreviewSurvey = ({
  step,
  stepIndex,
  responses,
  progress,
  onVote,
  customization,
  steps,
  tags,
  evolution,
  withLikesAndComments,
}) => {
  const [value, setValue] = useState(null);
  const [playSuccess, setPlaySuccess] = useState(false);

  const {
    boostedTextsColors,
    boostedTitleFontSize,
    boostedContentFontSize,
    boostedPrimaryColor,
    boostedSecondaryColor,
  } = customization;
  const [titleColor, contentColor] = boostedTextsColors.split(';');

  const isLastStep = stepIndex + 1 === steps?.length;

  const classNames = classnames('builder-channel-survey');

  useEffect(() => {
    if (step?.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT) {
      setValue([]);
    }
    if (step?.type === STEP_TYPE_TEXT_LONG) {
      setValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    const response = responses.find((r) => r?.step?.uid === step.uid);

    if (response != null) {
      // eslint-disable-next-line default-case
      switch (step.type) {
        case STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT: {
          setValue(
            step.options.filter((o) =>
              response.selectedOptions.map((s) => s.uid).includes(o.uid)
            )
          );
          break;
        }
        case STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT: {
          setValue(
            step.options.find(
              (o) => o.uid === response.selectedOptions?.[0]?.uid
            )
          );
          break;
        }
        case STEP_TYPE_OPINION_SCALE: {
          setValue(parseInt(response.value, 10));
          break;
        }
        case STEP_TYPE_TEXT_LONG: {
          setValue(response.value);
          break;
        }
        case STEP_TYPE_SLIDER: {
          setValue(parseInt(response.value, 10));
          break;
        }
      }
    }
  }, [step, responses]);

  const handleSelectChange = (e, option, selected) => {
    e.preventDefault();
    if (step.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT) {
      // add option id to value array
      if (selected === true) {
        setValue([...value, option]);
      }
      // remove option id from value array
      else {
        setValue(value.filter((e) => e.uid !== option.uid));
      }
    }
    if (step.type === STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT) {
      if (selected === true) {
        setValue(option);
      } else {
        setValue(null);
      }
    }
  };

  const handleSubmit = async (val) => {
    // send survey data to api
    const data = {};
    const {jumps = []} = step;
    let matchingJump = null;

    if (step?.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT) {
      data.selectedOptions = value;
      matchingJump = jumps.find((j) =>
        areEqual(
          j.optionValues.map((o) => o.uid),
          value.map((v) => v.uid)
        )
      );
    }
    if (step?.type === STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT) {
      data.selectedOptions = value != null ? [value] : null;
      matchingJump = jumps.find((j) =>
        areEqual(
          j.optionValues.map((o) => o.uid),
          [value?.uid]
        )
      );
    }
    if (step?.type === STEP_TYPE_TEXT_LONG) {
      data.value = value;
      matchingJump = jumps.find((j) => {
        if (j.operator === operators.EQUAL_TO) {
          return j.value === value;
        }
        if (j.operator === operators.NOT_EQUAL_TO) {
          return j.value !== value;
        }
        if (j.operator === operators.CONTAINS) {
          return value?.includes?.(j.value) === true;
        }
        if (j.operator === operators.DOES_NOT_CONTAIN) {
          return value?.includes?.(j.value) === false;
        }
        return false;
      });
    }
    if (step?.type === STEP_TYPE_OPINION_SCALE) {
      data.value = val.toString();
      matchingJump = jumps.find((j) => j.value === val.toString());
    }
    if (step?.type === STEP_TYPE_SLIDER) {
      data.value = value?.toString();
      matchingJump = jumps.find((j) => {
        if (j.operator === operators.EQUAL_TO) {
          return parseInt(j.value, 10) === parseInt(value / 10, 10);
        }
        if (j.operator === operators.NOT_EQUAL_TO) {
          return parseInt(j.value, 10) !== parseInt(value / 10, 10);
        }
        if (j.operator === operators.GREATER_THAN) {
          return parseInt(j.value, 10) < parseInt(value / 10, 10);
        }
        if (j.operator === operators.LESSER_THAN) {
          return parseInt(j.value, 10) > parseInt(value / 10, 10);
        }
        return false;
      });
    }
    if (step?.type === STEP_TYPE_NPS) {
      data.value = val;
      matchingJump = jumps.find((j) => {
        if (j.operator === operators.EQUAL_TO) {
          return parseInt(j.value, 10) === parseInt(value, 10);
        }
        if (j.operator === operators.NOT_EQUAL_TO) {
          return parseInt(j.value, 10) !== parseInt(value, 10);
        }
        if (j.operator === operators.GREATER_THAN) {
          return parseInt(j.value, 10) < parseInt(value, 10);
        }
        if (j.operator === operators.LESSER_THAN) {
          return parseInt(j.value, 10) > parseInt(value, 10);
        }
        return false;
      });
    }

    if (matchingJump != null) {
      if (matchingJump.endSurvey === true) {
        return setPlaySuccess(true);
      } else {
        return onVote(matchingJump?.goTo?.uid || matchingJump?.goTo);
      }
    } else if (step?.endSurvey === true) {
      return setPlaySuccess(true);
    } else if (isLastStep === true) {
      return setPlaySuccess(true);
    } else {
      return onVote(step?.goTo?.uid || step?.goTo);
    }
  };

  const question = step?.question;
  const content = step?.additionalInformation;

  document.documentElement.style.setProperty(
    '--previewSurveyChoicesColor',
    colorContrast(boostedPrimaryColor)
  );
  document.documentElement.style.setProperty(
    '--previewSecondaryColor',
    boostedSecondaryColor || defaultSecondaryColor
  );
  document.documentElement.style.setProperty(
    '--previewSurveySelectDotColor',
    colorContrast(boostedSecondaryColor || defaultSecondaryColor)
  );

  let actionContent;

  if (
    [
      STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
      STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    ].includes(step?.type)
  ) {
    let inputType = 'radio';
    if (step?.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT) {
      inputType = 'checkbox';
    }
    actionContent = (
      <>
        <div className="select-wrapper">
          {step.options?.map((o) => {
            const content = o.content;
            const selected =
              step.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
                ? value?.includes?.(o)
                : value === o;
            return (
              <div>
                <div
                  className={classnames(
                    'survey-btn',
                    step?.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
                      ? 'checkbox-btn'
                      : 'select-btn',
                    {
                      selected,
                    }
                  )}
                  rounded={false}
                  onClick={(e) => handleSelectChange(e, o, !selected)}>
                  <input type={inputType} name="radio" checked={selected} />
                  {selected === false && <span className="checkmark" />}
                  {selected === true &&
                    (step.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT ? (
                      <i className="icon-tick"></i>
                    ) : (
                      <span className="select-fill"></span>
                    ))}
                  <span className="content">{content}</span>
                </div>
              </div>
            );
          })}
        </div>
        <Divider dark />
        <Vote
          step={step}
          onVote={handleSubmit}
          value={value}
          btnStyle={{
            backgroundColor: `${boostedSecondaryColor}`,
            color: colorContrast(boostedSecondaryColor),
          }}
        />
      </>
    );
  }

  if (step?.type === STEP_TYPE_TEXT_LONG) {
    actionContent = (
      <>
        <div className="input-wrapper">
          <TextArea
            className="text-long-textarea"
            style={{resize: 'none'}}
            placeholder="Tap your answer here"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <Divider dark />
        <Vote
          step={step}
          onVote={handleSubmit}
          value={value}
          btnStyle={{
            backgroundColor: `${boostedSecondaryColor}`,
            color: colorContrast(boostedSecondaryColor),
          }}
        />
      </>
    );
  }

  if (step?.type === STEP_TYPE_OPINION_SCALE) {
    actionContent = (
      <>
        <Divider dark />
        <div className="opinion-wrapper">
          <div className="opinion-scale">
            <EmojiYeah
              width={48}
              animated
              animateOnHover
              disabled={value !== VOTE_YEAH}
              tooltip=""
              tooltipProps={{on: ['hover', 'focus']}}
              onClick={() => handleSubmit(VOTE_YEAH)}
            />
            <EmojiIdk
              animated
              animateOnHover
              disabled={value !== VOTE_IDK}
              tooltip=""
              tooltipProps={{on: ['hover', 'focus']}}
              onClick={() => handleSubmit(VOTE_IDK)}
            />
            <EmojiNah
              animated
              animateOnHover
              disabled={value !== VOTE_NAH}
              tooltip=""
              tooltipProps={{on: ['hover', 'focus']}}
              onClick={() => handleSubmit(VOTE_NAH)}
            />
          </div>
        </div>
      </>
    );
  }

  if (step?.type === STEP_TYPE_SLIDER) {
    actionContent = (
      <>
        <div className="slider-wrapper">
          <CustomSlider value={value || 667} onChange={setValue} />
        </div>
        <Divider dark />
        <Vote
          step={step}
          onVote={handleSubmit}
          value={value}
          btnStyle={{
            backgroundColor: `${boostedSecondaryColor}`,
            color: colorContrast(boostedSecondaryColor),
          }}
        />
      </>
    );
  }

  if (step?.type === STEP_TYPE_NPS) {
    actionContent = (
      <div className="nps-wrapper">
        <Nps value={value} onChange={handleSubmit}></Nps>
      </div>
    );
  }

  if (step?.type === STEP_TYPE_TEXT_BLOCK) {
    const shouldDisplayMdReadMore =
      hasFlags(
        [F_BOOST_SLOT_SNIPPET, F_BOOST_SLOT_TOOLTIP, F_BOOST_SLOT_DOT],
        evolution?.boostFlags,
        true
      ) === true;

    actionContent = (
      <div className="text-block-wrapper" style={{color: contentColor}}>
        <MarkdownContent
          content={step?.mdContent}
          enableReadMore={shouldDisplayMdReadMore}
        />
      </div>
    );
  }

  if (step?.type === STEP_TYPE_CONCEPT_TEST) {
    actionContent = (
      <div className="concept-test-main-wrapper">
        <div className="concept-test-wrapper">
          <div
            className="concept-test-thumbnail"
            style={
              step.conceptThumbnailUrl != null &&
              (step.conceptThumbnailFileId != null ||
                step.thumbnails.length > 0)
                ? {backgroundImage: `url(${step.conceptThumbnailUrl})`}
                : {}
            }></div>
          <div className="concept-test-overlay"></div>
          <div className="btn-play">
            <i className="icon-play"></i>
          </div>
        </div>
      </div>
    );
  }

  if (step?.type === STEP_TYPE_INTERVIEW) {
    actionContent = (
      <div className="interview-wrapper">
        <Button cta className="not-now-btn">
          Not now
        </Button>
        <Button
          primary
          cta
          className="book-slot-btn"
          style={{
            backgroundColor: boostedSecondaryColor,
            color: colorContrast(boostedSecondaryColor),
          }}>
          Book a slot
        </Button>
      </div>
    );
  }

  if (playSuccess === true) {
    return (
      <div className={classNames}>
        <Success
          onSuccessCompleted={() => {
            setPlaySuccess(false);
            onVote(null, true);
          }}
        />
      </div>
    );
  }

  return (
    <div className={classNames}>
      <div className="scrollable-wrapper">
        <div className="question-wrapper">
          {steps.length > 1 && (
            <ProgressBar
              completed={progress}
              className="progress-wrapper"
              barContainerClassName="progress-container"
              bgColor={boostedSecondaryColor}
              isLabelVisible={false}
            />
          )}
          {tags.length > 0 && (
            <div className="tags-wrapper">
              {tags.map((t) => (
                <TagButton tag={t}></TagButton>
              ))}
            </div>
          )}
          <div className="infos">
            <div
              className="title"
              style={{
                color: titleColor,
                fontSize: `${boostedTitleFontSize}px`,
              }}>
              {question}
            </div>
            <div
              className="content"
              style={{
                color: contentColor,
                fontSize: `${boostedContentFontSize}px`,
              }}>
              <div className="md-wrapper">
                {step?.type === STEP_TYPE_TEXT_BLOCK ? (
                  <MarkdownContent content={content} />
                ) : (
                  <>{content}</>
                )}
              </div>
            </div>
          </div>
        </div>
        {actionContent}
      </div>
      {step?.type === STEP_TYPE_TEXT_BLOCK &&
        (step.ctaAction ||
          step.ctaUrl != null ||
          stepIndex + 1 !== steps.length) && (
          <div className="text-block-actions-wrapper">
            {(step.ctaAction || step.ctaUrl != null) && (
              <Button
                cta
                className={classnames('cta-btn', {
                  'with-next': stepIndex + 1 !== steps.length,
                })}
                style={{
                  color: step.ctaColor,
                  backgroundColor: step.ctaBackgroundColor,
                  borderRadius: `${step.ctaBorderRadius}px`,
                }}>
                {step.ctaLabel}
              </Button>
            )}
            {stepIndex + 1 !== steps.length && (
              <Button
                cta
                primary
                onClick={onVote}
                style={{backgroundColor: boostedSecondaryColor}}>
                Next
              </Button>
            )}
          </div>
        )}
      {step?.type === STEP_TYPE_CONCEPT_TEST &&
        stepIndex + 1 !== steps.length && (
          <div className="actions-wrapper">
            <Button cta primary onClick={onVote}>
              Next
            </Button>
          </div>
        )}
    </div>
  );
};

PreviewSurvey.propTypes = propTypes;
PreviewSurvey.defaultProps = defaultProps;

export default PreviewSurvey;

function areEqual(array1, array2) {
  if (array1.length === array2.length) {
    return array1.every((element) => {
      if (array2.includes(element)) {
        return true;
      }

      return false;
    });
  }

  return false;
}
