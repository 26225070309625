import Button from 'components/Button';
import ColorPicker from 'components/ColorPicker';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import SelectGroup from 'components/Select';
import Toggle from 'components/Toggle';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {bool, func, object, string} from 'prop-types';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getCustomizationFromTheme} from 'router/Layouts';
import {OptionItem} from 'scenes/Builder/scenes/Channel/components/CustomizeOptions';
import {
  TYPE_BANNER,
  TYPE_HINT,
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_NAVIGATION,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
  TYPE_TOUR,
} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {generalSelector} from 'selectors';
import {
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_HINT,
  F_BOOST_SLOT_NAVIGATION,
  F_BOOST_SLOT_POP_IN,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOP_BAR,
  F_BOOST_SLOT_TOUR,
} from 'services/evolution';
import {F_STEP_CTA_IN_NEW_TAB} from 'services/steps';
import isURL from 'validator/lib/isURL';
import './_Styles.scss';

export const getTypeFromBoostFlags = (boostFlags) => {
  if (hasFlag(F_BOOST_SLOT_DOT, boostFlags)) {
    return TYPE_HOTSPOT;
  } else if (hasFlag(F_BOOST_SLOT_SNIPPET, boostFlags)) {
    return TYPE_SNIPPET;
  } else if (hasFlag(F_BOOST_SLOT_POP_IN, boostFlags)) {
    return TYPE_MODAL;
  } else if (hasFlag(F_BOOST_SLOT_TOP_BAR, boostFlags)) {
    return TYPE_BANNER;
  } else if (hasFlag(F_BOOST_SLOT_TOUR, boostFlags)) {
    return TYPE_TOUR;
  } else if (hasFlag(F_BOOST_SLOT_TOOLTIP, boostFlags)) {
    return TYPE_TOOLTIP;
  } else if (hasFlag(F_BOOST_SLOT_NAVIGATION, boostFlags)) {
    return TYPE_NAVIGATION;
  } else if (hasFlag(F_BOOST_SLOT_HINT, boostFlags)) {
    return TYPE_HINT;
  }
  return null;
};

const propTypes = {
  evolution: object.isRequired,
  step: object.isRequired,
  onCtaChange: func.isRequired,
  isOpen: bool,
  closeModal: func.isRequired,
  selectedLanguage: string,
};

const defaultProps = {
  isOpen: false,
  selectedLanguage: null,
};

const CTA_ACTION_OPEN_URL = 'OPEN_URL';
const CTA_ACTION_CLOSE_POKE = 'CLOSE_POKE';

const CtaModal = ({
  evolution,
  step,
  onCtaChange,
  isOpen,
  closeModal,
  selectedLanguage,
}) => {
  const project = useSelector((state) => generalSelector.getProject(state));
  const customization = getCustomizationFromTheme(
    getTypeFromBoostFlags(evolution.boostFlags),
    project.theme
  );

  const [action, setAction] = useState(null);
  const [url, setUrl] = useState('');
  const [text, setText] = useState('');
  const [inputColor, setInputColor] = useState(
    step.ctaColor || customization?.ctaColor || '#ffffff'
  );
  const [bgColor, setBgColor] = useState(
    step.ctaBackgroundColor || customization?.ctaBackgroundColor || '#1260eb'
  );
  const [borderRadius, setBorderRadius] = useState(
    step.ctaBorderRadius || customization?.ctaBorderRadius || '34'
  );
  const [openInNewTab, setOpenInNewTab] = useState(
    hasFlag(F_STEP_CTA_IN_NEW_TAB, step.stepFlags)
  );
  const [fontSize, setFontSize] = useState(
    step.ctaFontSize || customization?.ctaFontSize || '16'
  );

  useEffect(() => {
    if (isOpen === true) {
      const url =
        selectedLanguage != null
          ? step.texts.find((t) => t.language === selectedLanguage)?.ctaUrl
          : step.ctaUrl;
      const label =
        selectedLanguage != null
          ? step.texts.find((t) => t.language === selectedLanguage)?.ctaLabel
          : step.ctaLabel;

      setUrl(url || '');
      setText(label || '');
      setAction(step.ctaAction || (step.ctaUrl != null && CTA_ACTION_OPEN_URL));
      setOpenInNewTab(hasFlag(F_STEP_CTA_IN_NEW_TAB, step.stepFlags));
    }
  }, [selectedLanguage, step, isOpen]);

  const handleSubmit = () => {
    onCtaChange({
      ctaUrl: url,
      ctaLabel: text,
      ctaColor: inputColor,
      ctaBackgroundColor: bgColor,
      ctaBorderRadius: borderRadius,
      ctaAction: action,
      ctaFontSize: fontSize,
      stepFlags:
        openInNewTab === true
          ? addFlag(F_STEP_CTA_IN_NEW_TAB, step.stepFlags)
          : removeFlag(F_STEP_CTA_IN_NEW_TAB, step.stepFlags),
    });
    closeModal();
  };
  const handleToggleOpenNewTab = ({target}) => {
    setOpenInNewTab(target.checked === true);
  };

  const isLocalhostUrl =
    /^https?:\/\/(localhost|127\.0\.0\.1)(:\d+)?(\/.*)?$/.test(url);
  const isSubmitDisabled =
    (action === CTA_ACTION_OPEN_URL &&
      (!url || (isURL(url || '') === false && isLocalhostUrl === false))) ||
    !text;

  const ctaActionOptions = [
    {label: 'Open an url', value: CTA_ACTION_OPEN_URL},
    {label: 'Close experience', value: CTA_ACTION_CLOSE_POKE},
  ];

  return (
    <Modal
      isOpen={isOpen}
      className="modal-cta"
      overlayClassName="modal-cta-overlay"
      medium
      title="Add a CTA"
      onRequestClose={closeModal}>
      <div className="modal-content">
        <div className="inputs-wrapper">
          <div className="section-title">Action</div>
          <SelectGroup
            className="select-cta-action"
            value={ctaActionOptions.find((o) => o.value === action)}
            options={ctaActionOptions}
            onChange={(o) => setAction(o?.value)}
          />
          {action === CTA_ACTION_OPEN_URL && (
            <>
              <div className="section-title">Url</div>
              <Input
                className="url"
                name="url"
                value={url}
                required
                placeholder="https://"
                onChange={(e) => setUrl(e.target.value)}
              />
              <div className="open-new-tab-wrapper">
                Open in a new tab{' '}
                <Toggle
                  checked={openInNewTab}
                  onChange={handleToggleOpenNewTab}
                />
              </div>
            </>
          )}
          <div className="section-title">Call to action text</div>
          <Input
            className="text"
            name="text"
            placeholder="Call to action text"
            value={text}
            required
            onChange={(e) => setText(e.target.value)}
          />
          <div className="section-title">Appearance</div>
          <div className="opts-content">
            <OptionItem label="Background">
              <Input
                className="input-with-blue-label"
                labelTextLeft={<i className="icon-line-corner"></i>}
                type="number"
                labelTextRight="px"
                value={borderRadius}
                onChange={({target}) => setBorderRadius(target.value)}></Input>
              <ColorPicker
                popoverStyle={{bottom: '60px', right: 0}}
                color={bgColor || '#FFFFFF'}
                disableAlpha
                onChange={(color) => setBgColor(color.hex)}>
                <Input
                  labelTextLeft={
                    <span
                      className="preview-color"
                      style={{
                        backgroundColor: bgColor,
                      }}
                    />
                  }
                  className="input-color"
                  placeholder="#FFFFFF"
                  value={bgColor?.toUpperCase()}
                />
              </ColorPicker>
            </OptionItem>
            <OptionItem label="Text">
              <Input
                className="input-with-blue-label"
                labelTextLeft="Aa"
                type="number"
                labelTextRight="px"
                value={fontSize}
                onChange={({target}) => setFontSize(target.value)}></Input>
              <ColorPicker
                popoverStyle={{bottom: '60px', right: 0}}
                color={inputColor || '#FFFFFF'}
                disableAlpha
                onChange={(color) => setInputColor(color.hex)}>
                <Input
                  labelTextLeft={
                    <span
                      className="preview-color"
                      style={{
                        backgroundColor: inputColor,
                      }}
                    />
                  }
                  className="input-color"
                  placeholder="#FFFFFF"
                  value={inputColor?.toUpperCase()}
                />
              </ColorPicker>
            </OptionItem>
          </div>
        </div>
      </div>
      <div className="actions">
        <Button className="back-btn" muted onClick={closeModal}>
          Cancel
        </Button>{' '}
        <Button primary disabled={isSubmitDisabled} onClick={handleSubmit}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

CtaModal.propTypes = propTypes;
CtaModal.defaultProps = defaultProps;

export default CtaModal;
