import {EVENT_SELECT_CHANNEL} from 'amplitude';
import amplitude from 'amplitude-js';
import classnames from 'classnames';
import Toggle from 'components/Toggle';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {delay} from 'helpers/utils';
import {useContext, useEffect, useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {BuilderContext} from 'scenes/Builder/context';
import {generalSelector} from 'selectors';
import {
  F_BOOST_SLOT_POP_IN,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOP_BAR,
  F_OPTION_BROADCAST_ON_SLACK,
  F_OPTION_SHOW_ON_PORTAL,
  F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
} from 'services/evolution';
import {InteractiveOption} from '../../../../component/InteractiveOption';
import './_styles.scss';

export const BoostChannels = () => {
  const history = useHistory();

  const {evolution, setEvolution} = useContext(BuilderContext);

  const project = useSelector((state) => generalSelector.getProject(state));

  const handleChangeBoost = (boostFlags) => {
    setEvolution({...evolution, boostFlags});
  };
  const handleChangeOptions = (optionsFlags) => {
    setEvolution({...evolution, optionsFlags});
  };
  const [linkCopied, setLinkCopied] = useState(false);

  const handleToggleTopBarNotPushingContent = (enable) => {
    if (enable) {
      setEvolution({
        ...evolution,
        optionsFlags: addFlag(
          F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
          evolution.optionsFlags
        ),
      });
    } else {
      setEvolution({
        ...evolution,
        optionsFlags: removeFlag(
          F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
          evolution.optionsFlags
        ),
      });
    }
  };

  const hasSlackIntegration = !!project?.slack?.access_token;
  const hasSlackBroadcast = hasFlag(
    F_OPTION_BROADCAST_ON_SLACK,
    evolution.optionsFlags
  );
  const hasSlotTopBar = hasFlag(F_BOOST_SLOT_TOP_BAR, evolution.boostFlags);
  const hasSlotPopIn = hasFlag(F_BOOST_SLOT_POP_IN, evolution.boostFlags);
  const hasSlotSnippet = hasFlag(F_BOOST_SLOT_SNIPPET, evolution.boostFlags);
  // const hasSlotDot = hasFlag(F_BOOST_SLOT_DOT, evolution.boostFlags);
  // const hasSlotTooltip = hasFlag(F_BOOST_SLOT_TOOLTIP, evolution.boostFlags);
  const hasPortalIntegration = hasFlag(
    F_OPTION_SHOW_ON_PORTAL,
    evolution.optionsFlags
  );

  useEffect(() => {
    const reset = async () => {
      if (linkCopied === true) {
        await delay(1500);
        setLinkCopied(false);
      }
    };

    reset();
  }, [linkCopied]);

  const inAppChannels = (
    <div className={classnames('boost-options')}>
      <div className="section-title">boost my post in-app</div>
      {/* <InteractiveOption
        id="dot-slot"
        active={hasSlotDot}
        icon="icon-slot-dot"
        title="Pulsating Dot"
        description="Show this push as a pulsating dot on my website"
        onClick={() => {
          amplitude.getInstance().logEvent(EVENT_SELECT_CHANNEL, {
            channel: 'Pulsating dot',
            selected: hasSlotDot === false,
          });
          handleChangeBoost(hasSlotDot === false ? F_BOOST_SLOT_DOT : 0);
        }}
        withRadioBox
      />
      <InteractiveOption
        id="dot-slot"
        active={hasSlotTooltip}
        icon="icon-slot-tooltip"
        title="Tooltip"
        description="Show this push as a tooltip on my website"
        onClick={() => {
          amplitude.getInstance().logEvent(EVENT_SELECT_CHANNEL, {
            channel: 'Tooltip',
            selected: hasSlotTooltip === false,
          });
          handleChangeBoost(
            hasSlotTooltip === false ? F_BOOST_SLOT_TOOLTIP : 0
          );
        }}
        withRadioBox
      /> */}
      <InteractiveOption
        active={hasSlotTopBar}
        className="in-app-top-bar"
        icon="icon-slot-top-bar"
        title="Topbar"
        description="Show this push at the top of my website"
        onClick={() => {
          amplitude.getInstance().logEvent(EVENT_SELECT_CHANNEL, {
            channel: 'Topbar',
            selected: hasSlotTopBar === false,
          });
          handleChangeBoost(hasSlotTopBar === false ? F_BOOST_SLOT_TOP_BAR : 0);
        }}
        withRadioBox>
        <AnimateHeight
          height={
            hasFlag(F_BOOST_SLOT_TOP_BAR, evolution.boostFlags) ? 'auto' : 0
          }>
          <div
            className={classnames('top-bar-push-content', {
              'is-active':
                hasFlag(
                  F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
                  evolution.optionsFlags
                ) === false,
            })}
            onClick={() => {
              handleToggleTopBarNotPushingContent(
                !hasFlag(
                  F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
                  evolution.optionsFlags
                )
              );
            }}>
            <div className="description-wrapper">
              Move all element down
              <small>
                Automatically move down all fixed element to make sure top bar
                doesn't overlap them.
              </small>
            </div>
            <Toggle
              checked={
                hasFlag(
                  F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
                  evolution.optionsFlags
                ) === false
              }
              onChange={handleToggleTopBarNotPushingContent}
            />
          </div>
        </AnimateHeight>
      </InteractiveOption>
      <InteractiveOption
        active={hasSlotPopIn}
        icon="icon-slot-pop-in"
        title="Pop-in"
        description="Show this push in a pop-in on my website"
        onClick={() => {
          amplitude.getInstance().logEvent(EVENT_SELECT_CHANNEL, {
            channel: 'Pop-in',
            selected: hasSlotPopIn === false,
          });
          handleChangeBoost(hasSlotPopIn === false ? F_BOOST_SLOT_POP_IN : 0);
        }}
        withRadioBox
      />
      <InteractiveOption
        active={hasSlotSnippet}
        icon="icon-slot-snippet"
        title="Snippet"
        description="Show this push in a snippet on my website"
        onClick={() => {
          amplitude.getInstance().logEvent(EVENT_SELECT_CHANNEL, {
            channel: 'Snippet',
            selected: hasSlotSnippet === false,
          });
          handleChangeBoost(
            hasSlotSnippet === false ? F_BOOST_SLOT_SNIPPET : 0
          );
        }}
        withRadioBox
      />
    </div>
  );
  const outAppChannels = (
    <div className="channels-list">
      <InteractiveOption
        className={classnames('channel-portal')}
        active={hasPortalIntegration}
        icon="icon-integration-portal"
        title="Changelog"
        description="Show this post in my changelog widget and portal"
        disabled></InteractiveOption>
    </div>
  );

  return (
    <div className="boost-channels-wrapper">
      {outAppChannels}
      {inAppChannels}
    </div>
  );
};
