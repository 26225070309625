import Axios from 'axios';
import {generalSelector} from 'selectors';

export const EVENT_TYPE_CLICK = 'CLICK';
export const EVENT_TYPE_HOVER = 'HOVER';
export const EVENT_TYPE_PAGE_VIEW = 'PAGE_VIEW';

export const EVENT_SOURCE_JIMO = 'JIMO';
export const EVENT_SOURCE_SEGMENT = 'SEGMENT';

export const ACTIVE_OPERATOR_SINGLE_URL = 'SINGLE_URL';
export const ACTIVE_OPERATOR_EVERYWHERE = 'EVERYWHERE';
export const ACTIVE_OPERATOR_CUSTOM = 'CUSTOM';

export const EVENT_CONDITION_TYPE_ELEMENT = 'ELEMENT';
export const EVENT_CONDITION_TYPE_URL = 'URL';
export const EVENT_CONDITION_TYPE_INPUT = 'INPUT';
export const EVENT_CONDITION_TYPE_DELAY = 'DELAY';

export const EventConditionOperand = {
  // ELEMENT
  CLICKED: 'CLICKED',
  MOUSE_DOWN: 'MOUSE_DOWN', // For edge cases where CLICKED doesn't work
  HOVERED: 'HOVERED',
  PRESENT: 'PRESENT',
  NOT_PRESENT: 'NOT_PRESENT',
  DISABLED: 'DISABLED',
  NOT_DISABLED: 'NOT_DISABLED',

  // INPUT & URL
  IS: 'IS',
  NIS: 'NIS',
  IS_EMPTY: 'IS_EMPTY',
  NIS_EMPTY: 'NIS_EMPTY',
  STARTS_WITH: 'STARTS_WITH',
  ENDS_WITH: 'ENDS_WITH',
  CONTAINS: 'CONTAINS',
  NCONTAINS: 'NCONTAINS',
  MATCHES_REGEX: 'MATCHES_REGEX',
  NMATCHES_REGEX: 'NMATCHES_REGEX',
};

// Endpoint
const EP_EVENT_GET = '/event';
const EP_EVENT_GET_BY_ID = (eventId) => `/event/${eventId}`;
const EP_EVENT_CREATE = '/event';
const EP_EVENT_UPDATE = (eventId) => `/event/${eventId}`;
const EP_EVENT_DELETE = (eventId) => `/event/${eventId}`;

// Method
export const getEvents = (filter = {}) => {
  const {relations, withLastEvent, type, codeName, sources} = filter;
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_EVENT_GET, {
    params: {relations, withLastEvent, type, codeName, sources, projectId},
  }).then((response) => response.data);
};

export const getEventById = (eventId, filter = {}) => {
  const {relations} = filter;

  return Axios.get(EP_EVENT_GET_BY_ID(eventId), {
    params: {relations},
  }).then((response) => response.data);
};

export const createEvent = (data) => {
  const {name, codeName, description, icon, conditions, logic} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_EVENT_CREATE, {
    name,
    codeName,
    description,
    icon,
    conditions,
    logic,
    projectId,
  }).then((response) => response.data);
};

export const updateEvent = (eventId, data) => {
  const {name, codeName, description, icon, conditions, logic} = data;

  return Axios.put(EP_EVENT_UPDATE(eventId), {
    name,
    codeName,
    description,
    icon,
    conditions,
    logic,
  }).then((response) => response.data);
};

export const deleteEvent = (eventId) => {
  return Axios.delete(EP_EVENT_DELETE(eventId)).then(
    (response) => response.data
  );
};
