import classnames from 'classnames';
import Button from 'components/Button';
import BtnCopy from 'components/ButtonCopy';
import Divider from 'components/Divider';
import Input from 'components/Input';
import {ModalConfirm} from 'components/Modal';
import SelectGroup from 'components/Select';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {forwardRef, useContext, useState} from 'react';
import ReactDatePicker from 'react-datepicker';
import {useSelector} from 'react-redux';
import {BuilderContext} from 'scenes/Builder/context';
import {rateLimitUnitOptions} from 'scenes/Settings/scenes/PokeControl';
import {generalSelector} from 'selectors';
import {
  F_BOOST_SLOT_TOUR,
  F_OPTION_IGNORE_RATE_LIMITING,
  RECURRENCY_DAY_BASED,
  RECURRENCY_EVERY_TIME,
  RECURRENCY_SINGLE_TIME,
  TRIGGER_TYPE_DEFAULT,
  TRIGGER_TYPE_EVENT,
  TRIGGER_TYPE_MANUAL,
} from 'services/evolution';
import {EventSelector} from '../EventSelector';
import './_styles.scss';

export const SectionWhen = () => {
  const {evolution, setEvolution, originalEvolution} =
    useContext(BuilderContext);

  const project = useSelector((state) => generalSelector.getProject(state));

  const [isPublishOpen, setPublishIsOpen] = useState(false);
  const [isExpiresOpen, setExpiresIsOpen] = useState(false);
  const [triggerMode, setTriggerMode] = useState(
    evolution?.triggerType || TRIGGER_TYPE_DEFAULT
  );
  const [showModalCreateEvent, setShowModalCreateEvent] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [showModalIgnoreRateLimiting, setShowModalIgnoreRateLimiting] =
    useState(false);

  const handleIgnoreRateLimiting = () => {
    setEvolution({
      ...evolution,
      optionsFlags: addFlag(
        F_OPTION_IGNORE_RATE_LIMITING,
        evolution.optionsFlags
      ),
    });
    setShowModalIgnoreRateLimiting(false);
  };

  const DatepickerCustomInput = forwardRef(
    ({onClick, label, active, defaultActive, onReset}, ref) => {
      return (
        <Option
          innerRef={ref}
          title={label}
          icon="icon-calendar"
          active={active}
          defaultActive={defaultActive}
          onClick={onClick}
          onReset={onReset}
        />
      );
    }
  );

  const isPoke = evolution.boostFlags > 0;
  const isTour = hasFlag(F_BOOST_SLOT_TOUR, evolution.boostFlags);
  const isCompleted = true; // Since people don't need to select the date when it should go live
  const curDate = new Date();

  let publishAtLabel = 'Now';
  let expiresAtLabel = 'Set an expiration date';

  if (evolution.lastStepChangeAt != null) {
    if (new Date(evolution.lastStepChangeAt).getTime() > curDate.getTime()) {
      publishAtLabel = `Goes live on the ${dateToString(
        evolution.lastStepChangeAt
      )}`;
    }
    if (new Date(evolution.lastStepChangeAt).getTime() < curDate.getTime()) {
      publishAtLabel = `Has been live since ${dateToString(
        evolution.lastStepChangeAt
      )}`;
    }
  }

  if (evolution.expiresAt != null) {
    expiresAtLabel = `Expires the ${dateToString(evolution.expiresAt)}`;
  }

  return (
    <div className="builder-audience-section-when">
      <div className="section-title">
        {isCompleted === false ? (
          <>
            <i className="icon-tick custom-outline"></i>
            When
          </>
        ) : (
          <>
            <i className="icon-tick"></i>
            When
          </>
        )}
      </div>
      <div className="live-timing-wrapper">
        <div className="question"> When should this go live ?</div>
        <div className="live-timing-options">
          <ReactDatePicker
            wrapperClassName="date-picker-wrapper launch-date"
            onInputClick={() => setPublishIsOpen(true)}
            onClickOutside={() => setPublishIsOpen(false)}
            open={isPublishOpen}
            selected={
              evolution.lastStepChangeAt == null
                ? new Date()
                : new Date(evolution.lastStepChangeAt)
            }
            onChange={(lastStepChangeAt) => {
              setEvolution({...evolution, lastStepChangeAt});
            }}
            dateFormat="MM/dd/yyyy h:mm"
            showTimeInput
            customInput={
              <DatepickerCustomInput
                label={publishAtLabel}
                active={
                  evolution.lastStepChangeAt != null &&
                  new Date(evolution.lastStepChangeAt).getTime() !==
                    curDate.getTime()
                }
                defaultActive={true}
                onReset={() =>
                  setEvolution({
                    ...evolution,
                    lastStepChangeAt: null,
                  })
                }
              />
            }
            popperPlacement="bottom-end"
          />
          <ReactDatePicker
            wrapperClassName="date-picker-wrapper"
            onInputClick={() => setExpiresIsOpen(true)}
            onClickOutside={() => setExpiresIsOpen(false)}
            open={isExpiresOpen}
            selected={
              evolution.expiresAt != null ? new Date(evolution.expiresAt) : null
            }
            onChange={(expiresAt) => setEvolution({...evolution, expiresAt})}
            dateFormat="MM/dd/yyyy h:mm"
            showTimeInput
            minDate={
              evolution.lastStepChangeAt != null
                ? new Date(evolution.lastStepChangeAt)
                : null
            }
            customInput={
              <DatepickerCustomInput
                label={expiresAtLabel}
                active={evolution.expiresAt != null}
                onReset={() =>
                  setEvolution({
                    ...evolution,
                    expiresAt: null,
                  })
                }
              />
            }
            popperPlacement="bottom-end"
          />
        </div>
      </div>
      <Divider />
      {evolution.boostFlags > 0 && (
        <>
          <div className="poke-recurrence-wrapper">
            <div className="question">
              {'How often should this experience be displayed ?'}
            </div>
            <div className="poke-recurrence-options">
              <div
                onClick={() => {
                  setEvolution({
                    ...evolution,
                    recurrencyType: RECURRENCY_SINGLE_TIME,
                  });
                }}
                className={classnames('recurrence-option', {
                  selected: evolution.recurrencyType === RECURRENCY_SINGLE_TIME,
                })}>
                A single time
              </div>
              <div
                onClick={() => {
                  setEvolution({
                    ...evolution,
                    recurrencyType: RECURRENCY_EVERY_TIME,
                  });
                }}
                className={classnames('recurrence-option', {
                  selected: evolution.recurrencyType === RECURRENCY_EVERY_TIME,
                })}>
                Every time
              </div>
              <div
                onClick={() => {
                  setEvolution({
                    ...evolution,
                    recurrencyType: RECURRENCY_DAY_BASED,
                  });
                }}
                className={classnames('recurrence-option custom-input-label', {
                  selected: evolution.recurrencyType === RECURRENCY_DAY_BASED,
                })}>
                Once every
                <Input
                  value={evolution.recurrencyValue}
                  type="number"
                  required
                  onChange={(e) =>
                    setEvolution({
                      ...evolution,
                      recurrencyValue: parseInt(e.target.value),
                    })
                  }
                />
                days
              </div>
            </div>
          </div>
          <Divider />
        </>
      )}
      {isPoke && (
        <>
          <div className="poke-trigger-wrapper">
            <div className="question">
              {'When should we display this experience ?'}
            </div>
            <div className="poke-trigger-options">
              <div
                onClick={() => {
                  setTriggerMode(TRIGGER_TYPE_DEFAULT);
                  setEvolution({
                    ...evolution,
                    triggerType: TRIGGER_TYPE_DEFAULT,
                  });
                }}
                className={classnames('trigger-option', {
                  selected: triggerMode === TRIGGER_TYPE_DEFAULT,
                })}>
                On page opening
              </div>
              <div
                onClick={() => {
                  setTriggerMode(TRIGGER_TYPE_EVENT);
                  setEvolution({
                    ...evolution,
                    triggerType: TRIGGER_TYPE_EVENT,
                  });
                }}
                className={classnames('trigger-option', {
                  selected: triggerMode === TRIGGER_TYPE_EVENT,
                })}>
                After an event
              </div>
              <div
                onClick={() => {
                  setTriggerMode(TRIGGER_TYPE_MANUAL);
                  setEvolution({
                    ...evolution,
                    triggerType: TRIGGER_TYPE_MANUAL,
                  });
                }}
                className={classnames('trigger-option', {
                  selected: triggerMode === TRIGGER_TYPE_MANUAL,
                })}>
                On manual trigger
              </div>
            </div>
          </div>
          {triggerMode === TRIGGER_TYPE_DEFAULT && (
            <>
              <div className="boosted-delay-wrapper trigger-settings-wrapper">
                <div className="custom-input-label">
                  {isTour ? 'Start it' : 'Display it'}
                  <Input
                    name="inputBoostedDelay"
                    value={evolution.boostedDelay / 1000}
                    type="number"
                    required
                    onChange={(e) =>
                      setEvolution({
                        ...evolution,
                        boostedDelay: e.target.value * 1000,
                      })
                    }
                  />
                  seconds after loading the page
                </div>
              </div>
            </>
          )}
          {triggerMode === TRIGGER_TYPE_EVENT && (
            <>
              <div className="event-setup-wrapper trigger-settings-wrapper">
                {evolution.onTheFlyEvent != null ? (
                  <div className="existing-event-btns">
                    {evolution.onTheFlyEvent?.uid != null && (
                      <Button
                        iconLeft="icon-edit-outline"
                        className="edit-event"
                        onClick={() => {
                          setEventToEdit(evolution.onTheFlyEvent);
                          setShowModalCreateEvent(true);
                        }}>
                        Edit on the fly event
                      </Button>
                    )}
                    <Button
                      iconLeft="icon-trash"
                      className="remove-event"
                      onClick={() =>
                        setEvolution({...evolution, onTheFlyEvent: null})
                      }>
                      Remove on the fly event
                    </Button>
                  </div>
                ) : (
                  <>
                    <EventSelector />
                    <div className="or-text">or</div>
                    <div className="on-the-fly-event-btn-wrapper">
                      <Button
                        primary
                        onClick={() => setShowModalCreateEvent(true)}>
                        Create an event on the fly
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          {triggerMode === TRIGGER_TYPE_MANUAL && (
            <>
              <div>
                Copy the code below and paste it wherever you want to trigger
                this experience.
              </div>
              <div className="code-snippet-wrapper trigger-settings-wrapper">
                <div className="btn-copy-wrapper">
                  <BtnCopy
                    textToCopy={`window.jimo.push(['do', 'boosted:trigger', [{ evolutionId : "${evolution.uid}" }]])`}
                    text="copy"
                    rounded={false}
                    thin
                    muted
                  />
                </div>
                <pre>
                  {`window.jimo.push(['do', 'boosted:trigger', [{ evolutionId : "${evolution.uid}" }]])`}
                </pre>
              </div>
            </>
          )}
          <Divider />
        </>
      )}
      {evolution.boostFlags > 0 && (
        <>
          <div className="poke-rate-limiting-wrapper">
            <div className="question">
              Ignore rate limiting settings
              <div className="poke-rate-limiting-options">
                <div
                  onClick={() => {
                    setShowModalIgnoreRateLimiting(true);
                  }}
                  className={classnames('rate-limiting-option', {
                    selected: hasFlag(
                      F_OPTION_IGNORE_RATE_LIMITING,
                      evolution.optionsFlags
                    ),
                  })}>
                  Yes
                </div>
                <div
                  onClick={() => {
                    setEvolution({
                      ...evolution,
                      optionsFlags: removeFlag(
                        F_OPTION_IGNORE_RATE_LIMITING,
                        evolution.optionsFlags
                      ),
                    });
                  }}
                  className={classnames('rate-limiting-option', {
                    selected:
                      hasFlag(
                        F_OPTION_IGNORE_RATE_LIMITING,
                        evolution.optionsFlags
                      ) === false,
                  })}>
                  No
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <ModalConfirm
        className="modal-ignore-rate-limiting"
        confirmText="Yes, ignore it"
        onConfirm={handleIgnoreRateLimiting}
        onCancel={() => setShowModalIgnoreRateLimiting(false)}
        isOpen={showModalIgnoreRateLimiting}
        onRequestClose={() => setShowModalIgnoreRateLimiting(false)}>
        <div className="content">
          <div className="info">
            You are about to exclude this experience from your rate limiting
            settings.
          </div>
          <div className="rate-limiting-settings">
            Your rate limiting settings is set to:
            <div className="rate-limit-group">
              Display
              <Input
                value={project.rateLimitPokeAmount}
                type="number"
                disabled
              />
              experience(s) every
              <Input value={project.rateLimitValue} type="number" disabled />
              <SelectGroup
                value={rateLimitUnitOptions.find(
                  (o) => o.value === project.rateLimitUnit
                )}
                options={rateLimitUnitOptions}
                isDisabled
              />
            </div>
            It helps you maintain a great experience for your end-users.
          </div>
        </div>
      </ModalConfirm>
    </div>
  );
};

export const Option = ({
  title,
  icon,
  active,
  defaultActive,
  onClick,
  onReset,
}) => {
  return (
    <div
      className={classnames('date-options-item', {
        'is-active': defaultActive === true || active === true,
      })}
      onClick={(e) => {
        if (e.target.classList.contains('icon-close')) {
          return;
        }
        onClick();
      }}>
      <i className={active !== true ? icon : 'icon-close'} onClick={onReset} />
      {title}
    </div>
  );
};
const dateToString = (dateStr) => {
  const date = new Date(dateStr);
  return `${date.toLocaleDateString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })} at ${date.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit'})}`;
};
