import Button from 'components/Button';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import React, {useState} from 'react';
import {buildLogic} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import {v4 as uuidv4} from 'uuid';
import './_Styles.scss';
import Trigger from './components/Trigger';

const Triggers = ({step, updateStep}) => {
  const {triggers = []} = step;

  const [expandedTriggerId, setExpandedTriggerId] = useState(null);

  const addTrigger = () => {
    const newTrigger = {
      uid: uuidv4(),
      conditions: [],
      logic: buildLogic(),
      actions: [],
    };
    updateStep({
      ...step,
      triggers: [...triggers, newTrigger],
    });
    setExpandedTriggerId(newTrigger.uid);
  };

  return (
    <div className="triggers-wrapper" key={step.uid}>
      {triggers.length === 0 ? (
        <div className="empty-state-wrapper">
          <EmptyStateBlock
            img={EmptyStateImgs.EmptyTriggers}
            title="Set a trigger for this step"
            description="Triggers automate actions based on user interactions, enhancing your tours with specified conditions and actions."
          />
          <Button reverted primary iconLeft="icon-plus" onClick={addTrigger}>
            Add trigger
          </Button>
        </div>
      ) : (
        <>
          {triggers.map((trigger) => {
            const isEmpty =
              !trigger.conditions?.length && !trigger.actions?.length;
            return (
              <Trigger
                key={trigger.uid}
                trigger={trigger}
                onChange={(updatedTrigger) =>
                  updateStep({
                    ...step,
                    triggers: triggers.map((_trigger) =>
                      _trigger.uid === trigger.uid ? updatedTrigger : _trigger
                    ),
                  })
                }
                isExpanded={expandedTriggerId === trigger.uid || isEmpty}
                onToggleExpand={setExpandedTriggerId}
                onDelete={() =>
                  updateStep({
                    ...step,
                    triggers: triggers.filter(
                      (_trigger) => _trigger.uid !== trigger.uid
                    ),
                  })
                }
              />
            );
          })}
          <Button className="add-trigger-btn" onClick={addTrigger}>
            Add trigger
          </Button>
        </>
      )}
    </div>
  );
};

export default Triggers;
