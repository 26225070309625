import classnames from 'classnames';
import Button from 'components/Button';
import Loader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {ROUTE_SETTINGS_SUBSCRIPTION_PLAN} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {subscriptionService} from 'services';
import {Swaler} from 'swaler';
import CardPaymentMethod from '../components/CardPaymentMethod';
import './_Styles.scss';

class SettingsSubscriptionCards extends React.Component {
  logger = new Swaler('SettingsSubscriptionCards');

  constructor() {
    super();
    this.state = {
      paymentMethodLast4: null,
      paymentMethodBrand: null,
      stripeSubscriptionId: null,
      isLoading: true,
      isCreatingSession: false,
    };
  }

  fetchPaymentMethod = async () => {
    const {projectSubscription} = this.props;

    try {
      const subscription = await subscriptionService.getSubscriptionById(
        projectSubscription.uid,
        {relations: ['payment_method', 'stripe']}
      );

      this.setState({
        paymentMethodLast4: subscription.paymentMethodLast4,
        paymentMethodBrand: subscription.paymentMethodBrand,
        stripeSubscriptionId: subscription.stripeSubscriptionId,
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      this.logger.error('Fetch project subscription failed with error ', code);
      return toastDanger([title, message], {actions});
    }
  };

  handleManageSubscription = async () => {
    this.setState({isCreatingSession: true});
    const portalUrl = await subscriptionService.createStripePortalSession();

    if (portalUrl == null) {
      return;
    }
    window.location = portalUrl;
  };

  render() {
    const {
      isLoading,
      paymentMethodLast4,
      paymentMethodBrand,
      stripeSubscriptionId,
      isCreatingSession,
    } = this.state;
    const classNames = classnames('s-settings-subscription-cards', {
      'is-loading': isLoading === true,
      'fade-in': isLoading === false,
    });

    if (isLoading) {
      return (
        <div className={classNames}>
          <Loader></Loader>
        </div>
      );
    }
    return (
      <div className={classNames}>
        <div className="settings-body">
          {stripeSubscriptionId != null && paymentMethodBrand != null ? (
            <CardPaymentMethod
              showTitle
              paymentMethodLast4={paymentMethodLast4}
              paymentMethodBrand={paymentMethodBrand}
              action={
                <Button
                  primary
                  light
                  thin
                  loading={isCreatingSession}
                  onClick={this.handleManageSubscription}>
                  Manage my subscription
                </Button>
              }></CardPaymentMethod>
          ) : (
            <div className="no-cards">
              No associated card yet,{' '}
              <Link
                to={{
                  pathname: ROUTE_SETTINGS_SUBSCRIPTION_PLAN,
                  state: {doSettingsAnimation: false},
                }}>
                choose a plan
              </Link>{' '}
              to add a card.
            </div>
          )}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    await this.fetchPaymentMethod();
    this.setState({isLoading: false});
  }
}

const mapStateToProps = (state) => ({
  projectSubscription: generalSelector.getProjectSubscription(state),
});

export default connect(mapStateToProps)(SettingsSubscriptionCards);
