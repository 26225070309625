import classnames from 'classnames';
import Button from 'components/Button';
import {hasFlag} from 'helpers/bitwise';
import {buildStormwindUrl} from 'helpers/utils';
import IframeResizer from 'iframe-resizer-react';
import {useEffect, useRef} from 'react';
import {previewPost} from 'scenes/Settings/scenes/Themes/components/ChangelogThemePreview/utils';
import {projectService} from 'services';
import {
  F_EXTRA_DISABLE_TRIGGER_NOTIF_BADGE,
  F_PAGE_FEED,
  TRIGGER_BADGE,
  TRIGGER_CUSTOM,
  TRIGGER_DEFAULT,
  TRIGGER_SIDE_BADGE,
  WIDGET_POSITION_BOTTOM_LEFT,
  WIDGET_POSITION_BOTTOM_RIGHT,
} from 'services/project';
import {EmojiBell} from 'shared/front/components/Emojis';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('PreviewWidget');

export const PreviewWidget = (props) => {
  const {
    project,
    widgetPosition,
    showTrigger,
    widgetTriggerMode,
    widgetThemeColor,
    widgetTriggerMessage,
    widgetContentFlags,
    extraFlags,
  } = props;

  const iframeRef = useRef(null);

  const sendPostPreviewUpdate = () => {
    if (iframeRef.current != null) {
      iframeRef.current?.sendMessage({
        action: 'POST_PREVIEW_UPDATE',
        data: {evolution: previewPost},
      });
    }
  };

  const sendThemeUpdate = () => {
    if (iframeRef.current != null) {
      iframeRef.current?.sendMessage({
        action: 'CHANGELOG_OVERRIDE_THEME',
        data: {theme: project.changelogStyle},
      });
    }
  };

  const sendProjectUpdate = () => {
    if (iframeRef.current != null) {
      iframeRef.current?.sendMessage({
        action: 'PROJECT_UPDATE',
        data: {
          project,
        },
      });
    }
  };

  useEffect(() => {
    sendThemeUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.changelogStyle]);

  useEffect(() => {
    sendProjectUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const classNames = classnames('preview-widget', {
    'is-right': widgetPosition === WIDGET_POSITION_BOTTOM_RIGHT,
    'is-iframe-hidden': showTrigger === true,
  });
  const triggerClassNames = classnames('preview-widget-triggers', {
    'mode-custom': widgetTriggerMode === TRIGGER_CUSTOM,
    'mode-default': widgetTriggerMode === TRIGGER_DEFAULT,
    'mode-badge': widgetTriggerMode === TRIGGER_BADGE,
    'mode-side-badge': widgetTriggerMode === TRIGGER_SIDE_BADGE,
    'has-position-left fade-in-left':
      widgetPosition === WIDGET_POSITION_BOTTOM_LEFT,
    'has-position-right fade-in-right':
      widgetPosition === WIDGET_POSITION_BOTTOM_RIGHT,
    'is-hidden': showTrigger === false,
  });
  const widgetFeedEnabled = hasFlag(F_PAGE_FEED, widgetContentFlags);

  const url = buildStormwindUrl(
    projectService.getProjectPortalUrl(project),
    `/w/feed`,
    'preview=true'
  );

  return (
    <>
      <div className={classNames}>
        <IframeResizer
          forwardRef={iframeRef}
          title="changelog-preview-iframe"
          loading="lazy"
          src={url}
          className="changelog-iframe"
          allowFullScreen
          onMessage={({message}) => {
            try {
              const m = JSON.parse(message);

              if (m.action === 'POST_PREVIEW_REQUEST') {
                sendPostPreviewUpdate();
                sendThemeUpdate();
              }
            } catch (err) {
              logger.error(`Couldn't parse message sent by widget: ${err}`);
            }
          }}
        />
      </div>
      <div
        className={triggerClassNames}
        style={{
          backgroundColor:
            widgetTriggerMode === TRIGGER_DEFAULT
              ? widgetThemeColor
              : undefined,
        }}>
        {widgetTriggerMode === TRIGGER_DEFAULT && (
          <>
            <div className="message-wrapper">
              {widgetTriggerMessage.length !== 0
                ? widgetTriggerMessage
                : widgetFeedEnabled === true
                ? `What's new on ${project.name}?`
                : `Help us improve ${project.name}`}
            </div>
            <EmojiBell width={42} autoplay={true}></EmojiBell>
          </>
        )}
        {widgetTriggerMode === TRIGGER_CUSTOM && (
          <Button rounded={false} primary>
            Your custom button
          </Button>
        )}
        {widgetTriggerMode === TRIGGER_BADGE && (
          <div className="preview-whats-new">
            What's new? <div className="whats-new-badge">2</div>
          </div>
        )}
        {widgetTriggerMode === TRIGGER_SIDE_BADGE && (
          <div className="preview-trigger-side-wrapper">
            <div
              className="preview-trigger-side"
              style={{
                backgroundColor: widgetThemeColor,
              }}>
              <div className="message">
                {widgetTriggerMessage.length !== 0
                  ? widgetTriggerMessage
                  : widgetFeedEnabled === true
                  ? `What's new on ${project.name}?`
                  : `Help us improve ${project.name}`}
              </div>
              {hasFlag(F_EXTRA_DISABLE_TRIGGER_NOTIF_BADGE, extraFlags) ===
                false && (
                <div className="preview-trigger-side-notification">2</div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PreviewWidget;
