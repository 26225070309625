import classnames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import ModalTagManager from 'components/ModalTagManager';
import {TagButton} from 'components/TagButton';
import {array, func} from 'prop-types';
import {useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {tagService} from 'services';
import {TAG_CONTEXT_EVOLUTION} from 'services/tag';
import './_Styles.scss';

const propTypes = {selectedTags: array.isRequired, handleTagsChange: func};

const defaultProps = {handleTagsChange: () => {}};

const EvolutionTagSelect = ({selectedTags, handleTagsChange}) => {
  const [showModalTagManager, setShowModalTagManager] = useState(false);

  const refDropdownStatus = useRef();

  const {data: tags = [], refetch} = useQuery({
    queryKey: ['tags'],
    queryFn: () => tagService.getTags({contexts: TAG_CONTEXT_EVOLUTION}),
  });

  return (
    <div className="evolution-tag-select">
      <Dropdown
        innerRef={refDropdownStatus}
        className="dropdown-evolution-manage-tags"
        position="bottom left"
        trigger={
          <>
            {selectedTags?.length > 0 ? (
              <TagButton tag={selectedTags[0]} interactive={false} />
            ) : (
              <Button thin className="add-status-btn" iconLeft="icon-plus">
                Add a status
              </Button>
            )}
          </>
        }>
        <div className="tags-wrapper">
          <div className="header">
            <span className="title">Status</span>
            <span
              className="manage-tags-btn"
              onClick={() => setShowModalTagManager(true)}>
              manage status
            </span>
          </div>
          <Divider />
          <div className="tags-list">
            {tags?.length > 0 ? (
              tags?.map((t) => {
                const isTagSelected = selectedTags
                  .map((t) => t.uid)
                  .includes(t.uid);

                return (
                  <div
                    className={classnames('tag-option', {
                      selected: isTagSelected,
                    })}>
                    <TagButton
                      tag={t}
                      interactive={false}
                      onClick={() => {
                        refDropdownStatus.current.close();
                        const currentTag = selectedTags[0];

                        if (currentTag == null || currentTag.uid !== t.uid) {
                          handleTagsChange([t]);
                        } else if (currentTag.uid === t.uid) {
                          handleTagsChange([]);
                        }
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <div>No status</div>
            )}
          </div>
        </div>
      </Dropdown>
      <ModalTagManager
        isOpen={showModalTagManager}
        context={TAG_CONTEXT_EVOLUTION}
        enableAdd
        enableEditColorPicker
        enableEditTagPreview
        onRequestClose={() => {
          setShowModalTagManager(false);
          refetch();
        }}
      />
    </div>
  );
};

EvolutionTagSelect.propTypes = propTypes;
EvolutionTagSelect.defaultProps = defaultProps;

export default EvolutionTagSelect;
