/* eslint-disable import/no-anonymous-default-export */
import {
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';

// Startup
export const FEATURE_POKE_LIVE_LIMITED = 'FEATURE_POKE_LIVE_LIMITED';
export const FEATURE_PORTAL = 'FEATURE_PORTAL';
export const FEATURE_CORE = 'FEATURE_CORE';
export const FEATURE_SUPPORT = 'FEATURE_SUPPORT';
export const FEATURE_CUSTOM_DOMAIN = 'FEATURE_CUSTOM_DOMAIN';
export const FEATURE_MULTIPLE_LANGUAGES = 'FEATURE_MULTIPLE_LANGUAGES';
// Growth
export const FEATURE_POKE_LIVE_UNLIMITED = 'FEATURE_POKE_LIVE_UNLIMITED';
export const FEATURE_LINK_SHARING = 'FEATURE_LINK_SHARING';
export const FEATURE_SUPPORT_PRIORITY = 'FEATURE_SUPPORT_PRIORITY';
// Scale
export const FEATURE_DEDICATED_CSM = 'FEATURE_DEDICATED_CSM';
export const FEATURE_WHITE_LABEL = 'FEATURE_WHITE_LABEL';

export default {
  [PLAN_STARTUP_ID]: [
    {
      uid: FEATURE_POKE_LIVE_LIMITED,
      title: 'Up to 5 Active Experiences',
      description:
        'Product Tour, Surveys & NPS, In-app announcement, and Concept Test Design',
    },
    {
      uid: FEATURE_MULTIPLE_LANGUAGES,
      title: 'Multiple languages',
    },
    {
      uid: FEATURE_PORTAL,
      title: 'In-app Changelog',
    },
    {
      uid: FEATURE_CORE,
      title: 'Access to integrations, user identification and segmentation',
    },
    {
      uid: FEATURE_SUPPORT,
      title: 'Email and chat support',
    },
  ],
  [PLAN_GROWTH_ID]: [
    {
      uid: FEATURE_POKE_LIVE_UNLIMITED,
      title: 'Unlimited Active Experiences',
      description:
        'Product Tour, Surveys & NPS, In-app announcement, and Concept Test Design',
    },
    {
      uid: FEATURE_LINK_SHARING,
      title: 'Link sharing',
    },
    {
      uid: FEATURE_SUPPORT_PRIORITY,
      title: 'Priority support',
    },
  ],
  [PLAN_SCALE_ID]: [
    {
      uid: FEATURE_POKE_LIVE_UNLIMITED,
      title: 'Unlimited Active Experiences',
      description:
        'Product Tour, Surveys & NPS, In-app announcement, and Concept Test Design',
    },
    {
      uid: FEATURE_DEDICATED_CSM,
      title: 'Dedicated Customer Support Management',
    },
    {
      uid: FEATURE_WHITE_LABEL,
      title: 'Hide Jimo label',
    },
  ],
};
