import Button from 'components/Button';
import Card from 'components/Card';
import EmptyState from 'components/EmptyState';
import Loader from 'components/Loader';
import {Modal} from 'components/Modal';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {hasUsernameGenerated} from 'helpers/utils';
import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {ROUTE_MOVE_LIVE_FOR_VOTE} from 'router/routes.const';
import {requestService} from 'services';
import {EmojiMultipleEvos} from 'shared/front/components/Emojis';
import {Swaler} from 'swaler';
import './_Styles.scss';

class ModalAdd extends React.Component {
  logger = new Swaler('ModalAdd');
  constructor() {
    super();
    this.state = {
      isLoadingRequest: true,
      requests: [],
    };
  }

  fetchRequests = async () => {
    this.setState({isLoadingRequest: true});
    try {
      const requests = await requestService.getRequests({
        relations: ['author'],
        hasLinkedEvolution: false,
      });

      this.setState({isLoadingRequest: false, requests});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      this.logger.error(`Get requests failed with error`, code);
      toastDanger([title, message], {actions});
      this.setState({isLoadingRequest: false});
    }
  };

  render() {
    const {...rest} = this.props;
    const {requests, isLoadingRequest} = this.state;

    return (
      <Modal
        {...rest}
        small
        title="Add an evolution for vote"
        className="modal-live-for-vote-add">
        <div className="request-list">
          <div className="list-title">Start from an existing request</div>
          {requests.map((r) /** request */ => {
            const from = r.user != null ? r.user : r.jimer;
            const username =
              hasUsernameGenerated(from?.username) &&
              from?.externalUsername != null
                ? from?.externalUsername
                : from?.username;

            return (
              <Card
                className="fade-in-right"
                key={r.uid}
                interactive
                onClick={() =>
                  this.props.history.push(
                    ROUTE_MOVE_LIVE_FOR_VOTE(undefined, r.uid)
                  )
                }>
                <div className="from">
                  from {from != null ? username : 'Deleted user'}
                </div>
                {r.title}
                <i className="icon-chevron-right"></i>
              </Card>
            );
          })}
          {requests.length === 0 && isLoadingRequest === false && (
            <div className="is-empty">
              <EmptyState icon={<EmojiMultipleEvos></EmojiMultipleEvos>}>
                You don't have available requests at the moment
              </EmptyState>
            </div>
          )}
          {isLoadingRequest === true && (
            <div className="is-loading">
              <Loader width="20px"></Loader>
            </div>
          )}
        </div>
        <div className="or-actions">
          or
          <Link to={ROUTE_MOVE_LIVE_FOR_VOTE()}>
            <Button primary>Create an evolution</Button>
          </Link>
        </div>
      </Modal>
    );
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.isOpen === false && this.props.isOpen === true) {
      try {
        await this.fetchRequests();
      } catch (err) {
        return;
      }
    }
  }
}

export default withRouter(ModalAdd);
