import InputGroup from 'components/Input';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import React, {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {NPS_INTERACTIVE_BACKGROUND} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {BLOCK_TYPE_NPS} from 'services/steps';
import RadioGroup from '../../../../../../components/RadioGroup';
import PixelPicker from '../../components/items/PixelPicker';
import './_Styles.scss';

const animatedOptions = [
  {label: 'Yes', value: 'yes'},
  {label: 'No', value: 'no'},
];

const Nps = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    evolution,
  } = useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_NPS, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_NPS);

  if (block == null) {
    return <></>;
  }

  const {value, flags, style = {}} = block;
  const [labelLeft, labelRight] = value.split(';');

  return (
    <div className="block-settings nps">
      <div className="section">
        <div className="section-title">Style</div>
        <div className="section-content">
          {evolution.style?.background?.type === 'gradient' && (
            <div className="section-item">
              <div className="section-item-title">Animation</div>
              <div className="section-item-content">
                <RadioGroup
                  value={
                    hasFlag(NPS_INTERACTIVE_BACKGROUND, flags) ? 'yes' : 'no'
                  }
                  options={animatedOptions}
                  onSelect={(value) =>
                    updateBlock({
                      flags:
                        value === 'yes'
                          ? addFlag(NPS_INTERACTIVE_BACKGROUND, flags)
                          : removeFlag(NPS_INTERACTIVE_BACKGROUND, flags),
                    })
                  }
                />
              </div>
            </div>
          )}
          <div className="section-item">
            <div className="section-item-title">Left label</div>
            <div className="section-item-content">
              <InputGroup
                className="nps-left-label-input"
                value={labelLeft}
                onChange={({target}) =>
                  updateBlock({
                    value: `${target.value};${labelRight}`,
                  })
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Right label</div>
            <div className="section-item-content">
              <InputGroup
                className="nps-right-label-input"
                value={labelRight}
                onChange={({target}) =>
                  updateBlock({
                    value: `${labelLeft};${target.value}`,
                  })
                }
              />
            </div>
          </div>
          <NpsStyle style={style} updateStyle={updateBlock} />
        </div>
      </div>
    </div>
  );
};

export const NpsStyle = ({style, updateStyle}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Font-size</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style?.fontSize}
            min={8}
            max={42}
            onChange={(value) =>
              updateStyle({
                style: {...style, fontSize: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Padding</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style?.padding}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, padding: value},
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export default Nps;
