import classnames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import {toastDanger} from 'components/Toaster';
import {randomInt} from 'helpers/utils';
import {object} from 'prop-types';
import {useContext, useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {tagService} from 'services';
import {TAG_CONTEXT_EVOLUTION} from 'services/tag';
import {Swaler} from 'swaler';

import {errorHelpers} from 'helpers';
import {BuilderContext} from 'scenes/Builder/context';
import './_Styles.scss';

const COLORS = [
  {
    name: 'Yellow',
    color: '#AD9C01',
  },
  {
    name: 'Blue',
    color: '#012ABB',
  },
  {
    name: 'Red',
    color: '#BB0139',
  },
  {
    name: 'Purple',
    color: '#B701BB',
  },
  {
    name: 'Gray',
    color: '#373737',
  },
];

const logger = new Swaler('PostTagSelector');

const propTypes = {
  evolution: object.isRequired,
};

const defaultProps = {};

const PostTagSelector = () => {
  const {evolution, setEvolution} = useContext(BuilderContext);

  // Fetch tags
  const {
    data: tags = [],
    refetch: refetchTags,
    isLoading: isLoadingTags,
  } = useQuery({
    queryKey: ['tags', 'evolutions'],
    queryFn: () => tagService.getTags({contexts: [TAG_CONTEXT_EVOLUTION]}),
    refetchOnWindowFocus: false,
  });

  const [search, setSearch] = useState('');
  const [tagName, setTagName] = useState('');
  const [defaultNewTagColor, setDefaultNewTagColor] = useState(
    COLORS[randomInt(1, COLORS.length) - 1]
  );
  const [evolutionTags, setEvolutionTags] = useState(evolution?.tags || []);
  const [preventDropdownClose, setPreventDropdownClose] = useState(false);

  const refDropdownTagSettings = useRef([]);
  const refInputTagName = useRef([]);

  useEffect(() => {
    if (isLoadingTags === false && evolutionTags.length <= 0) {
      const tag = tags?.find((t) => t.name === 'New');
      if (tag != null) {
        setEvolution({
          ...evolution,
          tags: [tag],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingTags]);

  useEffect(() => {
    if (evolutionTags?.length > 0) {
      setEvolution({
        ...evolution,
        tags: evolution.tags?.map((t) => ({
          ...t,
          ...(tags?.find((tag) => tag.uid === t.uid) || {}),
        })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const handleUpdateName = async (tag) => {
    try {
      await tagService.updateTag(tag.uid, {
        name: tagName,
      });
      refetchTags();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating tag name failed with err : ', code);
      toastDanger([title, message], {actions});
    }
  };
  const handleUpdateColor = async (tag, color) => {
    try {
      await tagService.updateTag(tag.uid, {
        color,
      });
      refetchTags();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating tag color failed with err : ', code);
      toastDanger([title, message], {actions});
    }
  };
  const handleCreateTag = async (name) => {
    try {
      const createdTag = await tagService.createTag({
        name,
        color: defaultNewTagColor.color,
        context: TAG_CONTEXT_EVOLUTION,
      });
      setSearch('');
      await refetchTags();
      setDefaultNewTagColor(COLORS[randomInt(1, COLORS.length) - 1]);
      assignTag(createdTag);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Create tag failed with err : ', code);
      toastDanger([title, message], {
        actions,
      });
    }
  };
  const handleDeleteTag = async (tag) => {
    try {
      await tagService.deleteTag(tag.uid);
      unassignTag(tag);
      refetchTags();
      refDropdownTagSettings.current.forEach((dp) => dp.close());
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Delete tag failed with err : ', code);
      toastDanger([title, message], {
        actions,
      });
    }
  };
  const handleUpdatePokeTags = async (updatedTags) => {
    // try {
    //   await evolutionService.updateEvolutionTags(evolution.uid, updatedTags);
    // } catch (err) {
    //   logger.error('Updating evolution tags failed with err : ', err.message);
    //   toastDanger(['Wups...', 'Something went wrong, please try again.']);
    // }
    setEvolution({
      ...evolution,
      tags: updatedTags,
    });
  };

  const assignTag = (tag) => {
    return setEvolutionTags((evolutionTags) => evolutionTags.concat(tag));
  };
  const unassignTag = (tag) => {
    return setEvolutionTags((evolutionTags) =>
      evolutionTags.filter((pt) => pt.uid !== tag.uid)
    );
  };

  useEffect(() => {
    refDropdownTagSettings.current = refDropdownTagSettings.current.slice(
      0,
      tags.length
    );
  }, [tags]);
  useEffect(() => {
    refInputTagName.current = refInputTagName.current.slice(0, tags.length);
  }, [tags]);
  useEffect(() => {
    if (
      JSON.stringify(evolutionTags) !== JSON.stringify(evolution.tags || [])
    ) {
      handleUpdatePokeTags(evolutionTags);
    }
  }, [evolutionTags]);
  useEffect(() => {
    setEvolutionTags(evolution.tags || []);
  }, [evolution.tags]);

  const trigger =
    evolutionTags.length === 0 ? (
      <Button thin>add tag</Button>
    ) : (
      <div className="tags-list-wrapper">
        <div className="tags-list">
          {evolutionTags
            .map((pt) => tags.find((t) => t.uid === pt.uid))
            .filter((pt) => pt !== undefined)
            .map((t) => (
              <div
                className={classnames('item-tag')}
                style={{
                  border: `1px solid ${t.color}`,
                  backgroundColor: `${t.color}20`,
                  color: t.color,
                }}>
                {t.name}
              </div>
            ))}
        </div>

        <i className="icon-chevron-bottom"></i>
      </div>
    );
  const filteredTags = tags.filter((t) =>
    t.name.toLowerCase().includes(search.toLowerCase())
  );

  if (isLoadingTags === true) {
    return <></>;
  }
  return (
    <div className="post-tag-selector">
      <Dropdown
        position="right"
        closeOnDocumentClick={preventDropdownClose === false}
        offsetY={8}
        trigger={trigger}
        className="dp-post-tag-selector"
        onOpen={() => {
          setDefaultNewTagColor(COLORS[randomInt(1, COLORS.length) - 1]);
        }}
        onClose={() => {
          setSearch('');
        }}>
        <div className="tag-selector-search-wrapper">
          {evolutionTags
            .map((pt) => tags.find((t) => t.uid === pt.uid))
            .filter((pt) => pt !== undefined)
            .map((t) => (
              <div
                className="tag-selected"
                style={{
                  border: `1px solid ${t.color}`,
                  backgroundColor: `${t.color}20`,
                  color: t.color,
                }}>
                {t.name}
                <i className="icon-close" onClick={() => unassignTag(t)}></i>
              </div>
            ))}
          <Input
            value={search}
            onChange={({target}) => setSearch(target.value)}
            placeholder="Search or create a tag..."
            onPressEnter={() => {
              if (filteredTags.length === 0) {
                handleCreateTag(search);
              } else if (filteredTags.length === 1) {
                assignTag(filteredTags[0]);
              } else {
                return;
              }
              setSearch('');
            }}
          />
        </div>
        <div className="tags-list-wrapper">
          <div className="list-header">Select a tag or create one</div>
          <div className="list">
            {filteredTags.length > 0 ? (
              filteredTags.map((t, index) => {
                const hasTag =
                  evolutionTags.findIndex((pt) => pt.uid === t.uid) > -1;

                return (
                  <div
                    className="list-item"
                    onClick={() => {
                      hasTag === true ? unassignTag(t) : assignTag(t);
                    }}>
                    <div
                      className={classnames('item-tag', {
                        'is-selected': hasTag,
                      })}
                      style={{
                        border: `1px solid ${t.color}`,
                        backgroundColor: `${t.color}20`,
                        color: t.color,
                      }}>
                      {t.name}
                    </div>
                    <Dropdown
                      innerRef={(el) =>
                        (refDropdownTagSettings.current[index] = el)
                      }
                      className="dp-post-tag-settings"
                      triggerClassName="dp-trigger-tag-settings"
                      position="right top"
                      offsetX={18}
                      offsetY={-14}
                      trigger={
                        <Button iconOnly>
                          <i className="icon-horizontal-menu"></i>
                        </Button>
                      }
                      onOpen={() => {
                        setTagName(t.name);
                        setPreventDropdownClose(true);
                      }}
                      onClose={() => {
                        setPreventDropdownClose(false);
                      }}>
                      <div className="tag-name-wrapper">
                        <Input
                          ref={(el) => (refInputTagName.current[index] = el)}
                          value={tagName}
                          onChange={({target}) => setTagName(target.value)}
                          onBlur={() => handleUpdateName(t)}
                          onPressEnter={() =>
                            refInputTagName.current[index]?.blur()
                          }></Input>
                        <Button
                          iconOnly
                          danger
                          onClick={() => handleDeleteTag(t)}>
                          <i className="icon-trash"></i>
                        </Button>
                      </div>
                      <div className="list-color-wrapper">
                        <div className="list-header">Colors</div>
                        <div className="list">
                          {COLORS.map((c) => (
                            <div
                              className="item-color"
                              onClick={() => handleUpdateColor(t, c.color)}>
                              <div className="color-infos">
                                <div
                                  className="color-preview"
                                  style={{
                                    border: `1px solid ${c.color}`,
                                    backgroundColor: `${c.color}10`,
                                    color: c.color,
                                  }}></div>
                                {c.name}
                              </div>
                              {t.color === c.color && (
                                <i className="icon-tick"></i>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                );
              })
            ) : search.length > 0 ? (
              <div
                className="new-tag-wrapper"
                onClick={() => handleCreateTag(search)}>
                Create{' '}
                <div
                  className="new-tag"
                  style={{
                    border: `1px solid ${defaultNewTagColor.color}`,
                    backgroundColor: `${defaultNewTagColor.color}20`,
                    color: defaultNewTagColor.color,
                  }}>
                  {search}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

PostTagSelector.propTypes = propTypes;
PostTagSelector.defaultProps = defaultProps;

export default PostTagSelector;
