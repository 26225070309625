import classnames from 'classnames';
import React from 'react';
import {string, bool} from 'prop-types';
import './_Styles.scss';

const LabeledInputPropTypes = {
  title: string.isRequired,
  description: string,
  className: string,
  large: bool,
  vertical: bool,
};
const LabeledInputDefaultProps = {
  description: null,
  className: '',
  large: false,
  vertical: false,
};

const LabeledInput = ({
  title,
  description,
  children,
  className,
  large,
  vertical,
}) => {
  const classNames = classnames('settings-labeled-input', className, {
    'is-large': large === true,
    'is-vertical': vertical === true,
  });

  return (
    <div className={classNames}>
      <div className="label">
        {title}
        {description != null && <p>{description}</p>}
      </div>
      <div className="input-wrapper">{children}</div>
    </div>
  );
};

LabeledInput.propTypes = LabeledInputPropTypes;
LabeledInput.defaultProps = LabeledInputDefaultProps;

const InnerLabeledInputPropTypes = {
  title: string.isRequired,
  className: string,
};
const InnerLabeledInputDefaultProps = {
  className: '',
};
export const InnerLabeledInput = ({title, className, children}) => {
  const classNames = classnames('inner-labeled-input', className);

  return (
    <div className={classNames}>
      <div className="inner-label-title">{title}</div>
      {children}
    </div>
  );
};

InnerLabeledInput.propTypes = InnerLabeledInputPropTypes;
InnerLabeledInput.defaultProps = InnerLabeledInputDefaultProps;

export default LabeledInput;
