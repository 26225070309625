import {Element} from 'domhandler';
import {getDefaultBlockFromType} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {F_BOOST_SLOT_TOP_BAR} from 'services/evolution';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
  getDefaultStep,
} from 'services/steps';
import {payloadSlateToDomConfig, slateToHtml} from 'slate-serializers';
import {v4 as uuidv4} from 'uuid';

const config = {
  ...payloadSlateToDomConfig,
  elementTransforms: {
    ...payloadSlateToDomConfig.elementTransforms,
    a: ({node, children = []}) => {
      const attrs = {};
      if (node.linkType) {
        attrs['data-link-type'] = node.linkType;
      }
      if (node.newTab) {
        attrs.target = '_blank';
      }
      return new Element(
        'a',
        {
          href: node.url,
          ...attrs,
        },
        children
      );
    },
  },
};

export const createStepFromPost = (post, boostFlags) => {
  const blocks = [];

  const postBlocks = post.steps[0].blocks;

  const textBlock = postBlocks.find((block) => block.type === BLOCK_TYPE_TITLE);
  const bodyBlock = postBlocks.find((block) => block.type === BLOCK_TYPE_BODY);
  const labelBlock = postBlocks.find(
    (block) => block.type === BLOCK_TYPE_LABEL
  );
  const ctaBlock = postBlocks.find(
    (block) => block.type === BLOCK_TYPE_PRIMARY_CTA
  );
  const profileBlock = postBlocks.find(
    (block) => block.type === BLOCK_TYPE_USER
  );

  if (textBlock != null) {
    blocks.push({
      ...getDefaultBlockFromType(BLOCK_TYPE_TITLE),
      value: textBlock.value,
      translations: textBlock.translations.map((t) => ({
        ...t,
        uid: uuidv4(),
      })),
    });
  }

  // eslint-disable-next-line no-unused-vars
  const [_text, _action, optAction] = (ctaBlock?.value || '').split(';');

  // if there is a cta, add it with the same behavior as the original post
  if (ctaBlock != null && optAction === 'url-open') {
    blocks.push({
      ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
      value: ctaBlock.value,
      translations: ctaBlock.translations.map((t) => ({
        ...t,
        uid: uuidv4(),
      })),
    });
  } else {
    // otherwise, add a default cta to open the post
    blocks.push({
      ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
      value: `Read more;step-next;post-open;${post.uid}`,
    });
  }

  if (boostFlags !== F_BOOST_SLOT_TOP_BAR) {
    if (bodyBlock != null) {
      // if media_embed found in body, add a media block
      const media = bodyBlock.rawValue.find(
        (block) => ['img', 'media_embed'].includes(block.type) === true
      );
      if (media != null) {
        blocks.push({
          ...getDefaultBlockFromType(BLOCK_TYPE_MEDIA),
          value: `${media.type === 'img' ? 'image' : 'video'};${
            media.url
          };media;fill`,
        });
      }

      // remove images and videos from body
      const rawValue = bodyBlock.rawValue.filter((block) => {
        if (['media_embed', 'img'].includes(block.type) === true) {
          return false;
        }
        if (
          block.type === 'paragraph' &&
          block.children?.length === 1 &&
          block.children[0].text === ''
        ) {
          return false;
        }
        return true;
      });
      const value = slateToHtml(rawValue, config);

      blocks.push({
        ...getDefaultBlockFromType(BLOCK_TYPE_BODY),
        value: value,
        rawValue: rawValue,
        translations: bodyBlock.translations.map((t) => {
          const rawValue = t.rawValue.filter(
            (block) => block.type !== 'media_embed'
          );
          const value = slateToHtml(rawValue, config);

          return {
            ...t,
            uid: uuidv4(),
            value: value,
            rawValue: rawValue,
          };
        }),
      });
    }

    if (labelBlock != null) {
      const tags = post.tags.map((tag) => tag.name).join(' - ');

      blocks.push({
        ...getDefaultBlockFromType(BLOCK_TYPE_LABEL),
        value: tags,
      });
    }

    if (profileBlock != null) {
      blocks.push({
        ...getDefaultBlockFromType(BLOCK_TYPE_USER),
        value: profileBlock.value,
        file: profileBlock.file,
      });
    }
  }

  return getDefaultStep({
    blocks,
  });
};
