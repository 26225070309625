import classnames from 'classnames';
import commaNumber from 'comma-number';

import Button from 'components/Button';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {hasFlag} from 'helpers/bitwise';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {isEligibleToTrial} from 'services/project';
import {
  F_IS_PAYING,
  F_IS_TRIALING,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
} from 'services/subscription';
import planDetails from '../../Plan/plan-details';
import './_Styles.v2.scss';

dayjs.extend(relativeTime);

const PlanCard = ({
  id,
  prices,
  mau,
  seats,
  modeAnnual,
  onUpgrade,
  onCancel,
}) => {
  const [annual, monthly] = prices;
  const price = modeAnnual === true ? annual : monthly;

  const projectSubscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  return (
    <div
      className={classnames('plan-card', id.toLowerCase(), {
        active: projectSubscription.plan === id,
      })}>
      <div className="plan-gradient"></div>
      {hasFlag(F_IS_TRIALING, projectSubscription.extraFlags) === true &&
        projectSubscription.plan === id && (
          <div className="trial-in-progress">
            Trial end in{' '}
            {dayjs(projectSubscription.stripeCurrentPeriodEnd * 1000).fromNow()}
          </div>
        )}
      <div className="plan-name">{id.toLowerCase()}</div>
      <div className="plan-price">
        ${price}
        <small>/mo</small>
      </div>
      <div className="plan-mau">Up to {commaNumber(mau)} MAU</div>
      <div className="plan-seats">Up to {seats} seats</div>
      {id === PLAN_GROWTH_ID && <p>Everything in Startup, plus</p>}
      {id === PLAN_SCALE_ID && <p>Everything in Growth, plus</p>}
      <div className="plan-attributes">
        {planDetails[id].map((detail) => (
          <div className="plan-attribute">
            <i className="icon-tick"></i> {detail.title}
          </div>
        ))}
      </div>
      {hasFlag(F_IS_TRIALING, projectSubscription.extraFlags) === false &&
      projectSubscription.plan === id ? (
        <Button className="btn-upgrade" disabled muted thin>
          Current plan
        </Button>
      ) : (
        <Button
          className="btn-upgrade"
          primary
          reverted
          thin
          iconRight="icon-chevron-right"
          onClick={() => onUpgrade(id)}>
          {isEligibleToTrial(id) === true
            ? 'Start my 14 days trial'
            : hasFlag(F_IS_PAYING, projectSubscription.extraFlags)
            ? `Switch to ${id.toLowerCase()}`
            : 'Upgrade'}
        </Button>
      )}
      {id === projectSubscription.plan && (
        <div className="plan-cancel" onClick={onCancel}>
          {hasFlag(F_IS_PAYING, projectSubscription.extraFlags) === true
            ? 'I want to cancel my plan'
            : 'I want to cancel my trial'}
        </div>
      )}
    </div>
  );
};

export default PlanCard;
