import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import {hasFlag} from 'helpers/bitwise';
import {useContext, useState} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {DropdownAddAction} from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/TriggerActions';
import {F_BOOST_SLOT_HINT, F_BOOST_SLOT_TOP_BAR} from 'services/evolution';
import {
  BLOCK_TYPE_PRIMARY_CTA,
  STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  STEP_CONDITION_ACTION_TYPE_DISMISS,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_OPEN_POST,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
  STEP_TYPE_INTERVIEW,
} from 'services/steps';
import {
  NAVIGATION_CLOSE,
  NAVIGATION_NONE,
  NAVIGATION_STEP_PREV,
} from 'shared/front/components/Poke/components/BlockCta';
import {Swaler} from 'swaler';
import {v4 as uuidv4} from 'uuid';
import RadioGroup from '../../../../../../components/RadioGroup';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import {optionalActions} from '../../components/modals/Action';
import Navigation, {
  navigationActions,
} from '../../components/modals/Navigation';
import {fontWeightOptions} from '../Title';
import {fontFamiliesOptions} from '../Title/utils';
import './_Styles.scss';
import ButtonAction from './components/ButtonAction';

const alignOptions = [
  {label: <i className="icon-align-b-l" />, value: 'left'},
  {label: <i className="icon-align-b-c" />, value: 'center'},
  {label: <i className="icon-align-b-r" />, value: 'right'},
];

const logger = new Swaler('Button');

const Button = () => {
  const {
    controlledEvolution: evolution,
    selectedStep: step,
    updateBlock: uptBlock,
    selectedBlockType,
  } = useContext(BuilderContext);

  const isBannerStep = hasFlag(F_BOOST_SLOT_TOP_BAR, evolution.boostFlags);
  const isHintStep = hasFlag(F_BOOST_SLOT_HINT, evolution.boostFlags);

  const [newlyAddedAction, setNewlyAddedAction] = useState(null);

  const updateBlock = (updateObj) => {
    uptBlock(selectedBlockType, updateObj);
  };

  const handleAddAction = (type) => {
    const newAction = {
      uid: uuidv4(),
      type,
    };

    updateBlock({
      actions: [...actions, newAction],
    });
    setNewlyAddedAction(newAction);
  };

  const block = step?.blocks.find((b) => b.type === selectedBlockType);

  if (block == null) {
    return <></>;
  }

  const {value, style, actions = []} = block;
  const [text, action, optAction, urlOrPostId = '', fallbackUrl = ''] =
    value.split(';');

  const navigationAction = navigationActions.find(
    (a) => a.value === (action || 'none')
  );
  const optionalAction = optionalActions.find(
    (a) => a.value === (optAction || 'none')
  );

  const omitActions = [
    STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
    STEP_CONDITION_ACTION_TYPE_DISMISS,
  ];

  if (
    actions
      .map((action) => action.type)
      .some((t) =>
        [
          STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
          STEP_CONDITION_ACTION_TYPE_OPEN_POST,
          STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
        ].includes(t)
      ) === true
  ) {
    omitActions.push(
      STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
      STEP_CONDITION_ACTION_TYPE_OPEN_POST,
      STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW
    );
  }
  if (
    actions
      .map((action) => action.type)
      .some((t) =>
        [
          STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
          STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
        ].includes(t)
      ) === true
  ) {
    omitActions.push(STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO);
  }
  if (
    actions
      .map((action) => action.type)
      .includes(STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE) === true
  ) {
    omitActions.push(STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE);
  }

  return (
    <div className="block-settings button">
      <div className="section">
        <div className="section-title">Actions</div>
        <div className="section-content">
          {isHintStep !== true && (
            <div className="section-item">
              <div className="section-item-title">Navigation</div>
              <div className="section-item-content navigation-picker">
                <PopupSetting
                  trigger={
                    <ClickableInput
                      value={navigationAction?.name}
                      leftLabel={
                        <div className="navigation-icon-wrapper">
                          {navigationAction?.icon != null ? (
                            <i className={navigationAction.icon} />
                          ) : (
                            <span
                              className="navigation-icon"
                              style={{background: '#FFFFFF'}}
                            />
                          )}
                        </div>
                      }
                    />
                  }
                  title="Navigation"
                  content={
                    <Navigation
                      value={action}
                      omit={
                        selectedBlockType === BLOCK_TYPE_PRIMARY_CTA ||
                        step.type === STEP_TYPE_INTERVIEW
                          ? [
                              NAVIGATION_CLOSE,
                              NAVIGATION_NONE,
                              NAVIGATION_STEP_PREV,
                            ]
                          : []
                      }
                      onChange={(value) =>
                        updateBlock({
                          value: `${text};${value};${optAction};${urlOrPostId}`,
                        })
                      }
                    />
                  }
                />
              </div>
            </div>
          )}
          <div className="section-item">
            <div className="section-item-title">Actions</div>
            <div className="section-item-content action-picker">
              <div className="actions-wrapper">
                {actions.map((action) => {
                  return (
                    <ButtonAction
                      key={action.uid}
                      action={action}
                      setAction={(updatedAction) => {
                        updateBlock({
                          actions: actions.map((_action) =>
                            _action.uid === action.uid ? updatedAction : _action
                          ),
                        });
                      }}
                      onDelete={() => {
                        updateBlock({
                          actions: actions.filter(
                            (_action) => _action.uid !== action.uid
                          ),
                        });
                      }}
                      defaultOpen={newlyAddedAction?.uid === action.uid}
                    />
                  );
                })}
                <DropdownAddAction
                  className="add-action-element-button"
                  omit={omitActions}
                  usedActionTypes={actions.map((action) => action.type)}
                  onAddAction={handleAddAction}
                  position="left top"
                  offsetY={-4}
                  offsetX={8}
                  trigger={
                    <ClickableInput
                      className="add-action-element-placeholder-btn"
                      inputProps={{placeholder: 'Add action...'}}
                      leftLabel={
                        <div className="action-icon-wrapper">
                          <i className="isax isax-flash-15" />
                        </div>
                      }
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">Layout</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Text</div>
            <div className="section-item-content">
              <InputGroup
                className="button-content-input"
                value={text}
                onChange={({target}) =>
                  updateBlock({
                    value: `${target.value};${action};${optAction};${urlOrPostId}`,
                  })
                }
              />
            </div>
          </div>
          <ButtonStyle
            style={style}
            updateStyle={updateBlock}
            hideAlign={isBannerStep}
          />
        </div>
      </div>
    </div>
  );
};

export const ButtonStyle = ({style, updateStyle, hideAlign = false}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Font family</div>
        <div className="section-item-content">
          <SelectGroup
            small
            options={fontFamiliesOptions}
            value={fontFamiliesOptions.find(
              (o) => o.value === style.fontFamily
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontFamily: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font-size</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.fontSize}
            min={8}
            max={42}
            onChange={(value) => {
              updateStyle({
                style: {...style, fontSize: value},
              });
            }}
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font weight</div>
        <div className="section-item-content">
          <SelectGroup
            isSearchable={false}
            small
            options={fontWeightOptions}
            value={fontWeightOptions.find(
              (o) => o.value === (style.fontWeight || '400')
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontWeight: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Padding</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.padding}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, padding: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Radius</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.borderRadius}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderRadius: value},
              })
            }
            radius
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Text color</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Text color"
            value={style.fontColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, fontColor: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Background</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title={'Background'}
            value={style.primaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, primaryColor: value},
              })
            }
            erasable
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Border</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Border color"
            value={style.borderColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderColor: value},
              })
            }
            erasable
          />
        </div>
      </div>
      {hideAlign !== true && (
        <div className="section-item">
          <div className="section-item-title body-3">Position</div>
          <div className="section-item-content">
            <RadioGroup
              value={style.align}
              options={alignOptions}
              onSelect={(value) =>
                updateStyle({
                  style: {
                    ...style,
                    align: value,
                  },
                })
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Button;
