import {miscActions} from 'actions';
import Button from 'components/Button';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {EmojiIdk} from 'shared/front/components/Emojis';
import './_Styles.scss';

const MobileOverlay = () => {
  const misc = useSelector((state) => state.misc);

  const dispatch = useDispatch();

  const setHideMobileOverlay = (isHidden) =>
    dispatch(miscActions.setHideMobileOverlay(isHidden));

  if (misc.hideMobileOverlay === true) {
    return <></>;
  }

  return (
    <div className="mobile-overlay">
      <div className="wrapper">
        <EmojiIdk animated autoplay={true} loop={true}></EmojiIdk>
        <div className="title">Oups... Your screen seems too small!</div>
        <p>
          To ensure the best and lovely experience with Jimo, we recommend using
          a computer or any other large screen!
        </p>
        <p>
          We are thinking about different way to support mobile and small
          screens, we will keep you up to date!
        </p>
        <p>
          <Button
            onClick={() => setHideMobileOverlay(true)}
            light
            iconRight="icon-chevron-right">
            Continue anyway
          </Button>
        </p>
      </div>
    </div>
  );
};

export default MobileOverlay;
