import Divider from 'components/Divider';
import DefaultLoader from 'components/Loader';
import React, {createContext, useState} from 'react';
import {useQuery} from 'react-query';
import {useRouteMatch} from 'react-router-dom';
import {evolutionService} from 'services';
import {
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  TRIGGER_TYPE_NONE,
  TRIGGER_TYPE_URL,
} from 'services/evolution';
import {Swaler} from 'swaler';
import './_styles.scss';
import PokeAudienceHeader from './components/AudienceHeader';
import Goal from './sections/Goal';
import Summary from './sections/Summary';
import Trigger from './sections/Trigger';
import When from './sections/When';
import Where from './sections/Where';
import Who from './sections/Who';

export const SECTION_TRIGGER = 1;
export const SECTION_WHERE = 2;
export const SECTION_WHO = 3;
export const SECTION_WHEN = 4;
export const SECTION_GOAL = 5;
export const SECTION_SUMMARY = 6;

export const AudienceContext = createContext({
  evolution: null,
  setEvolution: () => {},
  refetchEvolution: () => {},
  selectedSection: SECTION_TRIGGER,
  setSelectedSection: () => {},
  checkedSections: [],
  setCheckedSections: () => {},
  jimersCount: null,
  setJimersCount: () => {},
  save: () => {},
});

const logger = new Swaler('PokeAudience');

const sections = [
  {section: SECTION_TRIGGER, component: Trigger},
  {section: SECTION_WHERE, component: Where},
  {section: SECTION_WHO, component: Who},
  {section: SECTION_WHEN, component: When},
  {section: SECTION_GOAL, component: Goal},
  {section: SECTION_SUMMARY, component: Summary},
];

const PokeAudience = () => {
  const [evolution, setEvolution] = useState(null);
  const [selectedSection, setSelectedSection] = useState(SECTION_TRIGGER);
  const [checkedSections, setCheckedSections] = useState([]);
  const [jimersCount, setJimersCount] = useState(null);

  const match = useRouteMatch();
  const evolutionId = match?.params?.evolutionId;

  const {isLoading, refetch} = useQuery({
    queryKey: ['evolution', 'poke-audience', evolutionId],
    queryFn: async () => {
      const evolution = await evolutionService.getEvolutionById(evolutionId, {
        relations: [
          'texts',
          'tags',
          'segments',
          'steps',
          'boostedPaths',
          'steps.thumbnails',
          'event',
          'tourSteps',
          'theme',
          'section',
          'tracker',
        ],
      });

      if (evolution.isDraft !== true) {
        const availableSections = getAvailableSections(evolution);
        setCheckedSections(availableSections.map((s) => s.section));
      }
      return setEvolution(evolution);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const save = async () => {
    try {
      await evolutionService.updateEvolution(evolution.uid, {
        ...evolution,
      });
    } catch (err) {
      logger.error('Publishing failed with error ', err);
    }
  };

  const getAvailableSections = (evolution) => {
    const isHint = evolution?.type === EVOLUTION_TYPE_HINT;
    const isSurvey = evolution?.type === EVOLUTION_TYPE_SURVEY;
    let availableSections = sections;

    if (
      evolution?.triggerType === TRIGGER_TYPE_URL ||
      evolution?.triggerType === TRIGGER_TYPE_NONE
    ) {
      availableSections = sections.filter((s) =>
        [SECTION_TRIGGER, SECTION_GOAL, SECTION_SUMMARY].includes(s.section)
      );
    }
    if (isHint) {
      availableSections = sections.filter((s) =>
        [SECTION_WHERE, SECTION_WHO, SECTION_WHEN, SECTION_SUMMARY].includes(
          s.section
        )
      );
    }

    if (isSurvey) {
      availableSections = availableSections.filter((s) =>
        [
          SECTION_TRIGGER,
          SECTION_WHERE,
          SECTION_WHO,
          SECTION_WHEN,
          SECTION_SUMMARY,
        ].includes(s.section)
      );
    }

    return availableSections;
  };

  const availableSections = getAvailableSections(evolution);

  return (
    <AudienceContext.Provider
      value={{
        evolution,
        setEvolution,
        refetchEvolution: refetch,
        selectedSection,
        setSelectedSection,
        checkedSections,
        setCheckedSections,
        jimersCount,
        setJimersCount,
        save,
        isBoosted: evolution?.isBoostOf != null,
        sections: availableSections,
      }}>
      <div className="poke-audience-wrapper">
        {isLoading === true ? (
          <div className="loader-wrapper">
            <DefaultLoader />
          </div>
        ) : evolution == null ? (
          <div>This evolution does not exist</div>
        ) : (
          <>
            <PokeAudienceHeader />
            <Divider />
            <div className="poke-audience">
              {availableSections.map((section, index) => (
                <section.component key={section.section} index={index + 1} />
              ))}
            </div>
          </>
        )}
      </div>
    </AudienceContext.Provider>
  );
};

export default PokeAudience;
