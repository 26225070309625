import BtnCopy from 'components/ButtonCopy';
import {Environment} from 'conf/env';
import * as htmlEntities from 'html-entities';
import {renderToString} from 'react-dom/server';
import './_Styles.scss';

const prod = Environment.NODE_ENV === 'production';

export function trackingCode(projectId) {
  return `<script>
    (function(j,i,m,o) {
        if (j["jimo"] != null) {
          return;
        }

        var s = document.createElement("script");

        s.type = "text/javascript";
        s.async = true;
        s.src = "${Environment.CDN_URL}/jimo-invader.js";
        
        j['jimo'] = [];
        j['JIMO_PROJECT_ID'] = "${projectId}";
        ${prod === false ? "j['JIMO_DEBUG'] = true;" : ''}

        document.getElementsByTagName("head")[0].appendChild(s);
    })(window);
</script>`;
}
export function trackingCodeAngular(projectId) {
  return `import { Renderer2, OnInit, Inject } from '@angular/core';
  import { DOCUMENT } from '@angular/common';
  
  class MyComponent implements OnInit {
  
      constructor(
          private _renderer2: Renderer2, 
          @Inject(DOCUMENT) private _document: Document
      ) { }
  
      public ngOnInit() {
          if (window.jimo != null) {
            return;
          }
          window.jimo = [];

          const script = this._renderer2.createElement('script');
  
          window['JIMO_PROJECT_ID'] = "${projectId}"; // Update this
          ${prod === false ? "window['JIMO_DEBUG'] = true;" : ''}
          script.type = "application/ld+json";
          s.type = 'text/javascript';
          s.async = true;
          s.src = "${Environment.CDN_URL}/jimo-invader.js";
          this._renderer2.appendChild(this._document.body, script);
      }
  }`;
}
export function trackingCodeVue(projectId) {
  return `new Vue({
    mounted: () => {
        if (window.jimo != null) {
          return;
        }
        window.jimo = [];
        
        const s = document.createElement('script');

        s.type = 'text/javascript';
        s.async = true;
        s.src = "${Environment.CDN_URL}/jimo-invader.js";
        window['JIMO_PROJECT_ID'] = "${projectId}"; // Update this
        ${prod === false ? "window['JIMO_DEBUG'] = true;" : ''}
        document.getElementsByTagName('head')[0].appendChild(s);
    }
  }).$mount('#app')`;
}
export function trackingCodeReact(projectId) {
  return `// For project using Hook component
useEffect(() => {
    if (window.jimo != null) {
      return;
    }
    window.jimo = [];

    const s = document.createElement('script');

    s.type = 'text/javascript';
    s.async = true;
    s.src = "${Environment.CDN_URL}/jimo-invader.js";
    window['JIMO_PROJECT_ID'] = "${projectId}"; // Update this
    ${prod === false ? "window['JIMO_DEBUG'] = true;" : ''}
    document.getElementsByTagName('head')[0].appendChild(s);
}, []);



// For project using Class component
async componentDidMount() {
    if (window.jimo != null) {
      return;
    }
    window.jimo = [];

    const s = document.createElement('script');

    s.type = 'text/javascript';
    s.async = true;
    s.src = "${Environment.CDN_URL}/jimo-invader.js";
    window['JIMO_PROJECT_ID'] = "${projectId}"; // Update this
    ${prod === false ? "window['JIMO_DEBUG'] = true;" : ''}
    document.getElementsByTagName('head')[0].appendChild(s);
}`;
}

export function TrackingCodeWithStyle({framework = '', ...rest}) {
  const code =
    framework === 'react'
      ? trackingCodeReact(rest.project.uid)
      : framework === 'angular'
      ? trackingCodeAngular(rest.project.uid)
      : framework === 'vue'
      ? trackingCodeVue(rest.project.uid)
      : trackingCode(rest.project.uid);

  return (
    <div className="code-snippet-wrapper">
      <div className="btn-copy-wrapper">
        <BtnCopy
          textToCopy={htmlEntities.AllHtmlEntities.decode(renderToString(code))}
          text="copy"
          rounded={false}
          thin
          muted
        />
      </div>
      <pre>{code}</pre>
    </div>
  );
}

export function trackingCodeWithoutStyle(framework = '', projectId) {
  const code =
    framework === 'react'
      ? trackingCodeReact(projectId)
      : framework === 'angular'
      ? trackingCodeAngular(projectId)
      : framework === 'vue'
      ? trackingCodeVue(projectId)
      : trackingCode(projectId);

  return code;
}
