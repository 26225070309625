import SettingsTitle from 'scenes/Settings/components/Title';
import AppearancePortal from './Portal';
import './_Styles.scss';

const Changelog = () => {
  return (
    <div className="s-settings-appearance fade-in">
      <SettingsTitle title="Changelog" withDivider={false} />
      <AppearancePortal />
    </div>
  );
};

export default Changelog;
