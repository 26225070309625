import './_styles.scss';

export const BannerTrial = () => {
  return (
    <div className="settings-card plan-banner-trial">
      <div className="title-4 b-500">
        Try Startup or Growth plan for free for 14 days
      </div>
      <div className="banner-description body-2 n-700">
        Simply pick any plan, and you can start your trial today. Discover the
        features that suit you best. No credit card needed, cancel anytime.
      </div>
    </div>
  );
};
