import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useState} from 'react';
import {subscriptionService} from 'services';
import {Swaler} from 'swaler';

const logger = new Swaler('useFetchProration');

export const useFetchProration = ({onError = () => {}} = {}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [proration, setProration] = useState(null);

  async function fetch({plan, addons, modeAnnual}) {
    setIsFetching(true);

    try {
      const proration = await subscriptionService.getProration({
        plan,
        addons,
        modeAnnual,
      });

      setIsFetching(false);
      setProration(proration);
      return proration;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsFetching(false);
      logger.error(`Fetch proration failed with error : `, code);
      toastDanger([title, message], {actions});
      return onError();
    }
  }
  return {
    isFetching,
    proration,
    fetch,
  };
};

export const useFetchAddonExtraMauProration = ({onError = () => {}} = {}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [proration, setProration] = useState(null);

  async function fetch() {
    setIsFetching(true);

    try {
      const proration = await subscriptionService.getProrationAddonExtraMau();

      setIsFetching(false);
      setProration(proration);
      return proration;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsFetching(false);
      logger.error(
        `Fetch addon extra mau proration failed with error : `,
        code
      );
      toastDanger([title, message], {actions});
      return onError();
    }
  }
  return {
    isFetching,
    proration,
    fetch,
  };
};
