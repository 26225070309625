import classNames from 'classnames';
import Alert from 'components/Alert';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import SegmentAttributesEditor from 'components/SegmentAttributesEditor';
import {
  buildLogic,
  isSegmentationValid,
} from 'components/SegmentAttributesEditor/utils';
import {errorHelpers} from 'helpers';
import {bool, func} from 'prop-types';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {jimerService, segmentService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func,
};

const defaultProps = {};

const JIMERS_PER_PAGE = 20;

const logger = new Swaler('EditSegmentationDrawer');

const EditSegmentationDrawer = ({
  isOpen,
  onRequestClose,
  selectedSegments = [],
  onTheFlySegment: onTheFlySegmentProp,
  onUpdate,
  title,
  ...rest
}) => {
  const [page, setPage] = useState(0);
  const [jimers, setJimers] = useState({
    data: [],
    skip: 0,
    take: JIMERS_PER_PAGE,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [lookupData, setLookupData] = useState(null);
  const [localSegments, setLocalSegments] = useState(null);
  const [localOnTheFlySegment, setLocalOnTheFlySegment] = useState(null);

  const {data: segments = []} = useQuery({
    queryKey: 'segments',
    queryFn: segmentService.getSegments,
  });

  useEffect(() => {
    setLocalOnTheFlySegment(onTheFlySegmentProp);
    setLocalSegments(selectedSegments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const fetchJimers = async (pageParam = page) => {
    setIsLoading(true);

    const oldPage = page;
    setPage(pageParam);

    try {
      const res = await jimerService.getJimers(
        {
          ...(lookupData?.attributes?.length > 0 && lookupData?.logic != null
            ? {attributes: lookupData.attributes, logic: lookupData.logic}
            : {segmentIds: localSegments?.map((s) => s.uid)}),
          take: JIMERS_PER_PAGE,
          skip: pageParam * JIMERS_PER_PAGE,
        },
        {}
      );

      setJimers((prev) => ({
        ...res,
        data: [...prev.data, ...res.data],
      }));
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }
      const {code} = errorHelpers.parseError(err);

      setPage(oldPage);
      logger.error('Fetching values failed with error ', code);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setJimers({
      data: [],
      skip: 0,
      take: JIMERS_PER_PAGE,
      total: 0,
    });
    fetchJimers(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSegments, lookupData?.attributes, lookupData?.logic]);

  const onTheFlySegment = localOnTheFlySegment ?? {
    temporary: true,
    attributes: [],
    logic: buildLogic(),
  };

  const closeModal = () => {
    onRequestClose();
  };

  const handleDeleteOnTheFlySegment = async () => {
    if (onTheFlySegment.uid != null) {
      await segmentService.deleteSegment(onTheFlySegment.uid);
    }
    setLocalOnTheFlySegment(null);
  };

  const handleRefresh = () => {
    setLookupData({
      attributes: JSON.parse(JSON.stringify(localOnTheFlySegment?.attributes)),
      logic: JSON.parse(JSON.stringify(localOnTheFlySegment?.logic)),
    });
  };

  const handleSave = () => {
    onUpdate(localSegments, localOnTheFlySegment);
    closeModal();
  };

  const hasSetupSegmentation =
    localSegments?.length !== 0 || lookupData?.attributes?.length > 0;

  const hasChanges = () =>
    onTheFlySegment?.attributes?.length > 0 &&
    (JSON.stringify(lookupData?.attributes) !==
      JSON.stringify(localOnTheFlySegment?.attributes) ||
      JSON.stringify(lookupData?.logic) !==
        JSON.stringify(localOnTheFlySegment?.logic));

  const isSaveDisabled = hasChanges() === true;

  const {isValid} = isSegmentationValid(
    onTheFlySegment?.attributes,
    onTheFlySegment?.logic
  );

  return (
    <Modal
      className="edit-segmentation-drawer fade-in-right"
      title={title || 'Filter users'}
      isOpen={isOpen}
      {...rest}
      onRequestClose={() => {
        closeModal();
      }}
      footer={
        <>
          <Button
            muted
            className="cancel-btn"
            onClick={() => {
              closeModal();
            }}>
            Cancel
          </Button>
          <Button
            reverted
            primary
            disabled={isSaveDisabled}
            onClick={handleSave}>
            Save
          </Button>
        </>
      }>
      <div className="content-wrapper">
        <div className="poke-segment-editing-wrapper">
          <div className="section-wrapper">
            <div className="section-title">Select an existing segment</div>
            {localOnTheFlySegment?.attributes?.length > 0 && (
              <Alert warning>
                If you want to use existing segments, you have to remove all
                attributes used in your segment on the fly.
              </Alert>
            )}
            <div className="list-segment">
              <Button
                className={classNames('item-segment', {
                  selected:
                    localSegments?.length === 0 &&
                    onTheFlySegment?.attributes?.length === 0,
                })}
                rounded={false}
                iconLeft="icon-duplicate"
                disabled={onTheFlySegment?.attributes?.length > 0}
                onClick={() => {
                  setLocalSegments([]);
                }}>
                All users
              </Button>
              {segments?.map((s) => {
                const isSelected = localSegments
                  ?.map((es) => es.uid)
                  .includes(s.uid);

                return (
                  <Button
                    key={s.uid}
                    className={classNames('item-segment', {
                      selected: isSelected === true,
                    })}
                    rounded={false}
                    iconLeft="icon-duplicate"
                    disabled={onTheFlySegment?.attributes?.length > 0}
                    onClick={() => {
                      setLocalSegments(
                        isSelected === true
                          ? localSegments.filter((seg) => s.uid !== seg.uid)
                          : [...localSegments, s]
                      );
                    }}>
                    {s.name}
                  </Button>
                );
              })}
            </div>
          </div>
          <div className="section-wrapper">
            <div className="section-title">Or create one on the fly</div>
            <SegmentAttributesEditor
              dropdownProps={{
                offsetY: 6,
                className: 'in-builder',
                segmentOnTheFlyDisabled: localSegments?.length > 0,
              }}
              hideDefaultActions={true}
              onSelectExistingSegment={(s) =>
                setLocalSegments(localSegments.concat(s))
              }
              onRemoveOnTheFly={
                onTheFlySegment?.attributes?.length > 0
                  ? handleDeleteOnTheFlySegment
                  : null
              }
              attributes={localOnTheFlySegment?.attributes}
              setAttributes={(attributes) => {
                setLocalOnTheFlySegment((prev) =>
                  attributes?.length === 0
                    ? null
                    : {
                        ...prev,
                        attributes,
                      }
                );
              }}
              logic={onTheFlySegment?.logic}
              setLogic={(logic) => {
                setLocalOnTheFlySegment((prev) =>
                  logic == null
                    ? null
                    : {
                        ...prev,
                        logic,
                      }
                );
              }}
            />
          </div>
          <div
            className={classNames('selected-users-wrapper', {
              'requires-lookup': hasChanges(),
            })}>
            {isLoading === true ? (
              <div className="loader-wrapper">
                <DefaultLoader width={24} />
              </div>
            ) : (
              <>
                <div className="selected-users">
                  <div className="avatar-wrapper">
                    {
                      // display as many avatars as jimers total or 4 if superior
                      [...Array(Math.min(jimers.total, 4))].map((_, i) => (
                        <Avatar key={i} jimer={{uid: `${i}`}} />
                      ))
                    }
                  </div>
                  <div className="selected-users-count">
                    {jimers.total} user{jimers.total > 1 ? 's' : ''} selected
                  </div>
                </div>
                <div className="selected-users-actions">
                  <div
                    className="cancel-btn"
                    onClick={() => {
                      setLocalOnTheFlySegment(onTheFlySegmentProp);
                      setLocalSegments(selectedSegments);
                    }}>
                    Cancel
                  </div>
                  <div
                    className={classNames('apply-btn', {
                      disabled: !isValid,
                    })}
                    onClick={() => {
                      if (isValid) {
                        handleRefresh();
                      }
                    }}>
                    Apply
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

EditSegmentationDrawer.propTypes = propTypes;
EditSegmentationDrawer.defaultProps = defaultProps;

export default EditSegmentationDrawer;
