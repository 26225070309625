import {GlobalContext} from 'contextes/Global';
import {useContext} from 'react';

export const usePlanPreview = () => {
  const {setModalPlanPreview} = useContext(GlobalContext);

  function preview({planId, title}) {
    setModalPlanPreview({planId, title});
  }

  return {
    preview,
  };
};
