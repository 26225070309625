import classNames from 'classnames';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import DefaultLoader from 'components/Loader';
import {ModalConfirm} from 'components/Modal';
import {toastDanger} from 'components/Toaster';
import {PermissionsPeople} from 'constants/permissions';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {hasPermissions} from 'helpers/permission';
import {hasUsernameGenerated} from 'helpers/utils';
import {array, func, object, string} from 'prop-types';
import React, {useEffect, useState} from 'react';
import PokeBlock from 'components/PokeBlock';
import ModalJimerProfile from 'scenes/Users/components/ModalJimerProfile';
import UserTagSelector from 'scenes/Users/components/UserTagSelector';
import {jimerService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import UsersLikedDrawer from './components/UsersLikedDrawer';

const propTypes = {
  evolution: object,
  segments: array,
  selectedSegments: string,
  setSelectedSegments: func,
};

const defaultProps = {
  evolution: null,
  segments: [],
  selectedSegment: null,
  setSelectedSegment: null,
};

const JIMERS_PER_PAGE = 8;

const logger = new Swaler('LastUsersLikedList');

const LastUsersLikedList = ({evolution}) => {
  const [shownJimerId, setShownJimerId] = useState(null);
  const [page, setPage] = useState(0);
  const [jimers, setJimers] = useState({
    data: [],
    skip: 0,
    take: JIMERS_PER_PAGE,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [jimerId, setJimerId] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const fetchJimers = async (pageParam = page) => {
    setIsLoading(true);

    const oldPage = page;
    setPage(pageParam);

    try {
      const res = await jimerService.getJimers(
        {
          evolutionId: evolution.uid,
          take: JIMERS_PER_PAGE,
          skip: pageParam * JIMERS_PER_PAGE,
          isChangelogLike: true,
        },
        {}
      );

      setJimers((prev) => ({
        ...res,
        data: [...prev.data, ...res.data],
      }));
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }
      const {code} = errorHelpers.parseError(err);

      setPage(oldPage);
      logger.error('Fetching values failed with error ', code);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setJimers({
      data: [],
      skip: 0,
      take: JIMERS_PER_PAGE,
      total: 0,
    });
    fetchJimers(0);
  }, [evolution.uid]);

  const handleDeleteJimer = async (jimerId) => {
    setDeleting(true);
    try {
      await jimerService.deleteJimer(jimerId);
      setDeleting(false);
      setShowDeleteConfirm(null);
      // onDelete(jimerId);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setDeleting(false);
      logger.error('Deleting jimer failed with error ', code);
      return toastDanger([title, message], {actions});
    }
  };

  const handleExportUsers = async () => {
    const jimers = await jimerService.getJimers(
      {
        evolutionId: evolution.uid,
        isChangelogLike: true,
        exportCsv: true,
      },
      {}
    );

    const fileName = `users-${evolution?.uid}.csv`;
    const data = new Blob([jimers], {type: 'text/csv'});
    const jsonURL = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <PokeBlock
      className="post-last-users-liked"
      title={
        <>
          Likes
          {jimers.total > 0 && (
            <div className="users-actions">
              <Button thin onClick={() => handleExportUsers()}>
                Export as CSV
              </Button>
            </div>
          )}
        </>
      }
    >
      {isLoading === true && jimers.total === 0 ? (
        <div className="loader-wrapper">
          <DefaultLoader />
        </div>
      ) : jimers.total === 0 ? (
        <EmptyStateBlock
          img={EmptyStateImgs.EmptyUsersReached}
          title="No users liked yet"
          description="Once this post reach your users, you'll see them here"
        />
      ) : (
        <>
          <div className={'list-users-table'}>
            <div className="top">
              <div></div>
              <div>Name / Id</div>
              <div>Email</div>
              <div>Liked at</div>
              <div>Tags</div>
            </div>

            <div className="list-wrapper">
              <div className="list">
                {jimers.data.map((jimer) => {
                  const date = jimer.lastActivityAt;

                  const username =
                    jimer.externalUsername != null &&
                    jimer.externalUsername.length !== 0
                      ? jimer.externalUsername
                      : jimer.username;
                  const email =
                    jimer.personalEmail != null
                      ? jimer.personalEmail
                      : jimer.externalEmail;
                  const isAnonymous =
                    hasUsernameGenerated(username) === true &&
                    !jimer.identifyToken &&
                    !jimer.externalUid;

                  return (
                    <div
                      key={jimer.uid}
                      className="card-user"
                      onClick={() => {
                        setJimerId(jimer.uid);
                        setShowProfile(true);
                      }}
                    >
                      <div className="user-avatar-wrapper">
                        <Avatar jimer={jimer} />
                      </div>
                      <div className="username-wrapper">
                        <div className="username">
                          {isAnonymous ? (
                            'Anonymous'
                          ) : !!username ? (
                            username
                          ) : (
                            <em>username</em>
                          )}
                        </div>
                        {isAnonymous === true ? (
                          <div className="anonymous-name">{username}</div>
                        ) : jimer.identifyToken || jimer.externalUid ? (
                          <div className="anonymous-name">
                            {jimer.identifyToken || jimer.externalUid}
                          </div>
                        ) : null}
                      </div>
                      <div className="user-email">
                        <span>{email ? email : 'none'}</span>
                      </div>

                      <div className={classNames('user-last-activity')}>
                        <div className="content">
                          <div className="event-date">
                            {dayjs(date).fromNow()}
                          </div>
                        </div>
                      </div>
                      <div className="user-tags">
                        <UserTagSelector
                          jimer={jimer}
                          maxTags={2}
                          readOnly={
                            hasPermissions(PermissionsPeople.USER_EDIT) ===
                            false
                          }
                        />
                      </div>
                      {hasPermissions(PermissionsPeople.USER_DELETE) && (
                        <div className="user-actions">
                          <Button
                            iconOnly
                            thin
                            rounded={false}
                            danger
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeleteConfirm(jimer.uid);
                            }}
                            disabled={showDeleteConfirm != null}
                          >
                            <i className="icon-trash"></i>{' '}
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <Button
                className="sell-all-users-btn"
                onClick={() => setIsDrawerOpen(true)}
                disabled={jimers.data.length === 0}
              >
                See all {jimers?.total} users
              </Button>
            </div>
          </div>
          <ModalConfirm
            className="modal-confirm-delete-jimer"
            isOpen={showDeleteConfirm != null}
            onConfirm={() => handleDeleteJimer(showDeleteConfirm)}
            onCancel={() => setShowDeleteConfirm(null)}
          >
            <div className="content">
              All data related to this jimer including activity and surveys will
              be forever deleted.
            </div>
          </ModalConfirm>
          <ModalJimerProfile
            isOpen={showProfile}
            onRequestClose={() => setShowProfile(false)}
            jimerId={jimerId}
          />
        </>
      )}

      {shownJimerId != null && (
        <ModalJimerProfile
          isOpen={shownJimerId != null}
          onRequestClose={() => setShownJimerId(null)}
          jimerId={shownJimerId}
        />
      )}
      {isDrawerOpen && (
        <UsersLikedDrawer
          isOpen={isDrawerOpen}
          onRequestClose={() => setIsDrawerOpen(false)}
          evolution={evolution}
        />
      )}
    </PokeBlock>
  );
};

LastUsersLikedList.propTypes = propTypes;
LastUsersLikedList.defaultProps = defaultProps;

export default LastUsersLikedList;
