import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import {validateTrigger} from 'helpers/step';
import React, {createContext, useContext, useEffect, useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {BuilderContext} from 'scenes/Builder/context';
import EventConditions, {
  AddConditionDropdown,
} from 'scenes/SuccessTracker/components/Event/components/EventConditions';
import {buildLogic} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import {
  EVENT_CONDITION_TYPE_DELAY,
  EVENT_CONDITION_TYPE_ELEMENT,
  EVENT_CONDITION_TYPE_INPUT,
  EVENT_CONDITION_TYPE_URL,
} from 'services/event';
import TriggerActions from '../TriggerActions';
import './_Styles.scss';

export const TriggerContext = createContext();

const ConditionIcon = ({type}) => {
  let icon, iconClassName;

  switch (type) {
    case EVENT_CONDITION_TYPE_ELEMENT:
      icon = 'isax isax-mouse-circle';
      iconClassName = 'element';
      break;
    case EVENT_CONDITION_TYPE_INPUT:
      icon = 'isax isax-keyboard';
      iconClassName = 'input';
      break;
    case EVENT_CONDITION_TYPE_URL:
      icon = 'isax isax-receipt-search';
      iconClassName = 'url';
      break;
    case EVENT_CONDITION_TYPE_DELAY:
      icon = 'isax isax-clock';
      iconClassName = 'delay';
      break;
    default:
      break;
  }

  return (
    <div className={classNames('condition-icon', iconClassName)}>
      <i className={icon} />
    </div>
  );
};

const CustomIndicatorLabel = ({className, text}) => {
  return (
    <div className={classNames('custom-indicator-label', className)}>
      <div className="label">{text}</div>
      <div className="arrow-right" />
    </div>
  );
};

const Trigger = ({trigger, onChange, isExpanded, onToggleExpand, onDelete}) => {
  const {isInApp, messenger, setSelectingElementTriggerConditionId} =
    useContext(BuilderContext);

  const {conditions: conditionsProp, logic: logicProp, actions = []} = trigger;

  const [conditions, setConditions] = useState(conditionsProp || []);
  const [logic, setLogic] = useState(logicProp || buildLogic(trigger));

  const handleChange = (updatedTrigger) => {
    onChange({
      ...trigger,
      ...updatedTrigger,
    });
    onToggleExpand(trigger.uid);
  };

  useEffect(() => {
    handleChange({
      ...trigger,
      conditions,
      logic,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions, logic]);

  const issues = validateTrigger(trigger);

  return (
    <TriggerContext.Provider
      value={{
        trigger,
        setTrigger: (update) => {
          handleChange({
            ...trigger,
            ...update,
          });
        },
        isExpanded,
      }}>
      <div
        className={classNames('trigger', {
          'is-expanded': isExpanded,
          'is-invalid': issues.length > 0,
        })}>
        <div
          className="trigger-header"
          onClick={() => {
            if (isExpanded !== true) {
              onToggleExpand(trigger.uid);
            } else {
              onToggleExpand(null);
            }
          }}>
          <div className="trigger-header-content">
            <div className="body-3 n-600">
              {issues.length > 0 ? (
                issues.join(', ')
              ) : conditions.length > 0 ? (
                <>
                  {conditions.length} condition
                  {conditions.length > 1 ? 's' : ''}
                </>
              ) : (
                'No conditions set yet'
              )}
            </div>
            {isExpanded !== true && conditions.length > 0 && (
              <div className="condition-icons">
                {conditions.map((condition, index) => (
                  <ConditionIcon key={index} type={condition.type} />
                ))}
              </div>
            )}
          </div>
          <i className="icon-chevron-bottom" />
        </div>
        <AnimateHeight
          className="collapsable-content-wrapper"
          height={isExpanded ? 'auto' : '0'}>
          <Divider />
          <div className="trigger-conditions">
            {conditions.length > 0 && (
              <CustomIndicatorLabel text="If" className="if" />
            )}
            <EventConditions
              conditions={conditions}
              setConditions={setConditions}
              logic={logic}
              setLogic={setLogic}
              borderless
              compact
              settingsPositioning="right center"
              CustomEmptyState={(props) => (
                <AddConditionDropdown
                  trigger={
                    <div className="custom-condition-empty-state">
                      <CustomIndicatorLabel text="If" className="if" />
                      <Button
                        className="add-condition-btn"
                        thin
                        tertiary
                        iconLeft="icon-plus">
                        Add condition
                      </Button>
                    </div>
                  }
                  {...props}
                  omitTypes={['logicGroup']}
                />
              )}
              onSelectElement={
                isInApp
                  ? (conditionId, opts) => {
                      messenger?.sendSelectTargetElement(opts);
                      setSelectingElementTriggerConditionId(conditionId);
                    }
                  : null
              }
            />
          </div>
        </AnimateHeight>
        <Divider />
        <div
          className={classNames('trigger-actions-wrapper', {
            'is-expanded': isExpanded,
            'is-empty': actions.length === 0,
          })}>
          {isExpanded && <CustomIndicatorLabel className="then" text="Then" />}
          <div className="trigger-actions-content">
            {isExpanded && (
              <>
                {actions.length > 0 && (
                  <div className="trigger-icon-wrapper">
                    <i className="isax isax-play-circle5 b-400" />
                  </div>
                )}
              </>
            )}
            <TriggerActions />
          </div>
        </div>
        {isExpanded && (
          <>
            <Divider />
            <div className="trigger-delete-wrapper" onClick={onDelete}>
              <div className="icon-wrapper">
                <i className="isax isax-trash r-400" />
              </div>
              <div className="r-400">Delete trigger</div>
            </div>
          </>
        )}
      </div>
    </TriggerContext.Provider>
  );
};

export default Trigger;
