import classnames from 'classnames';
import Button from 'components/Button';
import LivePreview from 'components/LivePreview';
import {hasFlag} from 'helpers/bitwise';
import {useContext, useEffect, useRef, useState} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_POP_IN,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOP_BAR,
} from 'services/evolution';
import './_styles.scss';

export const Preview = ({onCustomize = () => {}}) => {
  const {evolution, customization} = useContext(BuilderContext);

  const [stepIndex, setStepIndex] = useState(0);

  const previewRef = useRef();

  const hasNoDeliveryMethod = evolution.boostFlags === 0;
  const previewTopBar = hasFlag(F_BOOST_SLOT_TOP_BAR, evolution.boostFlags);
  const previewSnippet = hasFlag(F_BOOST_SLOT_SNIPPET, evolution.boostFlags);
  const previewPopIn = hasFlag(F_BOOST_SLOT_POP_IN, evolution.boostFlags);
  const previewDot = hasFlag(F_BOOST_SLOT_DOT, evolution.boostFlags);

  useEffect(() => {
    setStepIndex(0);
  }, [previewTopBar, previewSnippet, previewPopIn, previewDot]);

  return (
    <div
      key={hasNoDeliveryMethod}
      className={classnames('builder-channel-preview fade-in', {
        'has-no-delivery-method': hasNoDeliveryMethod,
        'has-preview-top-bar': previewTopBar === true,
      })}>
      {hasNoDeliveryMethod === true ? (
        'Pick a delivery method to see how it will look like.'
      ) : (
        <>
          <div className="btn-customize-wrapper">
            <Button
              thin
              rounded={false}
              iconLeft="icon-customize"
              inverted
              onClick={onCustomize}>
              Customize appearance
            </Button>
          </div>
          <div className="main-wrapper">
            <LivePreview
              boostedEvolution={{...evolution, ...customization}}
              previewRef={previewRef}
            />
          </div>
        </>
      )}
    </div>
  );
};
