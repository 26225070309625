class EventBus {
  listeners = new Map();

  on(event, callback) {
    this.listeners.set(event, callback);
  }
  dispatch(event, data = null) {
    const callback = this.listeners.get(event);

    if (callback != null) {
      callback(data);
      return;
    }
  }
  remove(event) {
    this.listeners.delete(event);
  }
}

const eventBus = new EventBus();

export default eventBus;
