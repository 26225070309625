import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import React, {useContext, useEffect} from 'react';
import sanitizeHtml from 'sanitize-html';
import {hasFlag} from '../../../../helpers/bitwise';
import {
  BLOCK_BODY,
  BLOCK_CHOICE,
  BLOCK_CONCEPT,
  BLOCK_INTERVIEW,
  BLOCK_LABEL,
  BLOCK_MEDIA,
  BLOCK_NPS,
  BLOCK_OPEN_QUESTION,
  BLOCK_OPINION,
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_SLIDER,
  BLOCK_STEPPER,
  BLOCK_TITLE,
  BLOCK_USER,
} from '../../constants/blocks';
import {
  CUSTOM_ATTRIBUTE_SOURCE_HUBSPOT,
  CUSTOM_ATTRIBUTE_SOURCE_SEGMENT,
} from '../../constants/jimer';
import {F_SLOT_HINT, F_SLOT_TOP_BAR} from '../../constants/poke';
import {PokeContext, PokeStateContext} from '../../context';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import {decodeHtml} from '../BlockBody';
import {ACTION_POST_OPEN, ACTION_URL_OPEN} from '../BlockCta';
import {MEDIA_POSITION_BOTTOM, MEDIA_POSITION_TOP} from '../BlockMedia';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';

export const parseTitleValue = (value) => {
  const [text, action, actionData] = value.split('|-|');

  return {text, action, actionData};
};

export const getUserAttributePath = (attribute, source) => {
  if (
    [CUSTOM_ATTRIBUTE_SOURCE_HUBSPOT, CUSTOM_ATTRIBUTE_SOURCE_SEGMENT].includes(
      source
    ) === true
  ) {
    return `${source.toLowerCase()}.${attribute}`;
  } else {
    return attribute;
  }
};

export const VariableToSpan = ({children, user, inBuilder, ...props}) => {
  const {
    'data-attribute': dataAttribute,
    'data-fallback': dataFallback,
    'data-source': dataSource,
  } = props;

  const attributePath = getUserAttributePath(dataAttribute, dataSource);

  const value = user?.attributes[attributePath] || dataFallback;

  return (
    <span
      className={classnames('variable', {
        'in-builder': inBuilder === true,
      })}>
      {' '}
      {inBuilder ? dataAttribute : value}{' '}
    </span>
  );
};

export const BlockTitle = () => {
  const {poke, user} = useContext(PokeContext);
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    language,
    onWidgetOpen,
    goToNextStep,
    addFontFamily,
    onUrlClick,
  } = useContext(PokeStateContext);

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_TITLE,
  });

  const block = currentStep.blocks.find((b) => b.type === BLOCK_TITLE);
  const {text, action, actionData} = parseTitleValue(block.value);
  const {align, fontColor, fontFamily, ...restStyle} = block.style;

  const isInPokeBanner = hasFlag(F_SLOT_TOP_BAR, poke.boostFlags);

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const handleClick = () => {
    if (isInPokeBanner !== true) {
      return;
    }

    if (
      [ACTION_URL_OPEN, ACTION_POST_OPEN].includes(action) === true &&
      !!actionData
    ) {
      goToNextStep();
    }

    if (action === ACTION_URL_OPEN) {
      window.open(actionData, '_blank');
    } else if (action === ACTION_POST_OPEN) {
      onWidgetOpen(actionData);
    }
  };

  const isTopBlock =
    isInPokeBanner !== true &&
    currentStep.blocks.some((b) => {
      return (
        b.type === BLOCK_LABEL ||
        b.type === BLOCK_USER ||
        (b.type === BLOCK_STEPPER && b.style.position === 'top') ||
        (b.type === BLOCK_MEDIA && b.style.position === MEDIA_POSITION_TOP)
      );
    }) !== true;

  const isBottomBlock =
    isInPokeBanner !== true &&
    currentStep.blocks.some((b) => {
      return (
        (b.type === BLOCK_MEDIA &&
          b.style.position === MEDIA_POSITION_BOTTOM) ||
        (b.type === BLOCK_STEPPER && b.style.position === 'bottom') ||
        b.type === BLOCK_CHOICE ||
        b.type === BLOCK_INTERVIEW ||
        b.type === BLOCK_NPS ||
        b.type === BLOCK_OPINION ||
        b.type === BLOCK_OPEN_QUESTION ||
        b.type === BLOCK_SLIDER ||
        b.type === BLOCK_CONCEPT ||
        (b.type === BLOCK_PRIMARY_CTA && b.removed !== true) ||
        (b.type === BLOCK_SECONDARY_CTA && b.removed !== true) ||
        b.type === BLOCK_BODY
      );
    }) !== true;

  const translation = block.translations?.find((t) => t.language === language);

  const sanitizedContent = sanitizeHtml(
    translation != null ? decodeHtml(translation.value) : decodeHtml(text),
    {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        'img',
        'iframe',
        'ins',
        'del',
        'variable',
      ]),
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        a: ['href', 'target'],
        variable: ['class', 'data-attribute', 'data-fallback', 'data-source'],
      },
    }
  );

  const isSingleBannerBlock = isInPokeBanner && currentStep.blocks.length === 1;

  const isClickable =
    inBuilder !== true &&
    [ACTION_URL_OPEN, ACTION_POST_OPEN].includes(action) === true &&
    !!actionData;

  const isHint = hasFlag(F_SLOT_HINT, poke.boostFlags);

  const ATagtoDiv = ({children, ...props}) => {
    const {href} = props;
    return (
      <span className="a-div" onClick={() => onUrlClick(href)}>
        {children}
      </span>
    );
  };

  return (
    <div
      className={classnames('poke-block-title', `align-${align}`, {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        'is-in-poke-banner': isInPokeBanner,
        selected: selectedBlock === BLOCK_TITLE,
        'is-top-block': isTopBlock,
        'is-bottom-block': isBottomBlock,
        'is-single-banner-block': isSingleBannerBlock,
        'is-clickable': isClickable === true,
      })}
      style={{...restStyle, color: fontColor, fontFamily}}
      onClick={(e) => {
        e.stopPropagation();
        if (inBuilder === true) {
          onBlockSelected(BLOCK_TITLE);
        } else {
          handleClick();
        }
      }}>
      <Markdown
        className="body-markdown"
        options={{
          overrides: {
            a: {
              component: ATagtoDiv,
            },
            variable: {
              component: (props) => (
                <VariableToSpan {...props} user={user} inBuilder={inBuilder} />
              ),
            },
          },
        }}>
        {sanitizedContent}
      </Markdown>
      <ClickableBlockOverlay withoutOverflow={isHint} />
    </div>
  );
};
