import Select from 'components/Select';
import {useContext} from 'react';
import {useQuery} from 'react-query';
import {BuilderContext} from 'scenes/Builder/context';
import {eventService} from 'services';
import './_styles.scss';

export const EventSelector = () => {
  const {evolution, setEvolution} = useContext(BuilderContext);

  const {data: events, isLoading} = useQuery({
    queryKey: ['events'],
    queryFn: () => eventService.getEvents(),
  });

  const eventOptions = events?.map((e) => ({
    label: e.name,
    value: e.uid,
  }));

  return (
    <div className="builder-audience-event-selector">
      <Select
        options={eventOptions}
        placeholder="Select an event"
        onChange={(e) => setEvolution({...evolution, eventId: e?.value})}
        value={eventOptions?.find((e) => e.value === evolution.eventId)}
        isClearable
        isLoading={isLoading}
      />
    </div>
  );
};
