import {
  OPINION_TYPE_EMOJI,
  OPINION_TYPE_SMILEY,
  parseOpinionValue,
} from '../components/BlockOpinion';
import {
  BLOCK_CHOICE,
  BLOCK_NPS,
  BLOCK_OPEN_QUESTION,
  BLOCK_OPINION,
  BLOCK_SLIDER,
  isInteractiveBlock,
} from '../constants/blocks';
import {isInteractiveStep} from '../constants/steps';

export const EVALUATE_CLOSE = 'close';
export const EVALUATE_COMPLETED = 'completed';

const operators = {
  EQUAL_TO: 'EQUAL_TO',
  NOT_EQUAL_TO: 'NOT_EQUAL_TO',
  GREATER_THAN: 'GREATER_THAN',
  LESSER_THAN: 'LESSER_THAN',
  CONTAINS: 'CONTAINS',
  DOES_NOT_CONTAIN: 'DOES_NOT_CONTAIN',
};

export const evaluate = ({steps, step, stepIndex, response}) => {
  const {jumps = []} = step;
  let matchingJump = null;
  const isLastInteractiveStep =
    steps
      .slice(stepIndex + 1, steps.length)
      .some((s) => isInteractiveStep(s)) === false;
  const blockInteractive = step.blocks.find((b) => isInteractiveBlock(b.type));

  if (blockInteractive?.type === BLOCK_CHOICE) {
    matchingJump = jumps.find((j) => {
      if (j.conditionOperator === 'ALL' || j.conditionOperator == null) {
        return areEqual(
          j.optionValues.map((o) => o.uid || o),
          response.map((r) => r.uid || r)
        );
      }
      if (j.conditionOperator === 'NOT') {
        return !areEqual(
          j.optionValues.map((o) => o.uid || o),
          response.map((r) => r.uid || r)
        );
      }
      if (j.conditionOperator === 'AT_LEAST_ONE_OF') {
        return response.some((v) =>
          j.optionValues.map((o) => o.uid || o).includes(v.uid)
        );
      }
      if (j.conditionOperator === 'NONE_OF') {
        return !response.some((v) =>
          j.optionValues.map((o) => o.uid || o).includes(v.uid)
        );
      }
      return false;
    });
  }
  if (blockInteractive?.type === BLOCK_OPEN_QUESTION) {
    matchingJump = jumps.find((j) => {
      if (j.operator === operators.EQUAL_TO) {
        return j.value === response;
      }
      if (j.operator === operators.NOT_EQUAL_TO) {
        return j.value !== response;
      }
      if (j.operator === operators.CONTAINS) {
        return response?.includes?.(j.value) === true;
      }
      if (j.operator === operators.DOES_NOT_CONTAIN) {
        return response?.includes?.(j.value) === false;
      }
      return null;
    });
  }
  if (blockInteractive?.type === BLOCK_OPINION) {
    const value = parseOpinionValue(blockInteractive.value);
    response = response.toString();
    matchingJump = jumps.find((j) => {
      if ([OPINION_TYPE_SMILEY, OPINION_TYPE_EMOJI].includes(value.type)) {
        if (j.conditionOperator === 'ALL' || j.conditionOperator == null) {
          return j.value === response.toString();
        }
        if (j.conditionOperator === 'NOT') {
          return j.value !== response.toString();
        }
        if (j.conditionOperator === 'AT_LEAST_ONE_OF') {
          return (j.value || '')
            .split(';')
            .map((o) => o)
            .includes(response.toString());
        }
        if (j.conditionOperator === 'NONE_OF') {
          return !(j.value || '')
            .split(';')
            .map((o) => o)
            .includes(response.toString());
        }
      } else {
        if (j.operator === operators.EQUAL_TO) {
          return parseInt(j.value, 10) === parseInt(response, 10);
        }
        if (j.operator === operators.NOT_EQUAL_TO) {
          return parseInt(j.value, 10) !== parseInt(response, 10);
        }
        if (j.operator === operators.GREATER_THAN) {
          return parseInt(j.value, 10) < parseInt(response, 10);
        }
        if (j.operator === operators.LESSER_THAN) {
          return parseInt(j.value, 10) > parseInt(response, 10);
        }
      }
      return null;
    });
  }
  if (blockInteractive?.type === BLOCK_SLIDER) {
    response = response.toString();
    matchingJump = jumps.find((j) => {
      if (j.operator === operators.EQUAL_TO) {
        return parseInt(j.value, 10) === parseInt(response / 10, 10);
      }
      if (j.operator === operators.NOT_EQUAL_TO) {
        return parseInt(j.value, 10) !== parseInt(response / 10, 10);
      }
      if (j.operator === operators.GREATER_THAN) {
        return parseInt(j.value, 10) < parseInt(response / 10, 10);
      }
      if (j.operator === operators.LESSER_THAN) {
        return parseInt(j.value, 10) > parseInt(response / 10, 10);
      }
      return null;
    });
  }
  if (blockInteractive?.type === BLOCK_NPS) {
    matchingJump = jumps.find((j) => {
      if (j.operator === operators.EQUAL_TO) {
        return parseInt(j.value, 10) === parseInt(response, 10);
      }
      if (j.operator === operators.NOT_EQUAL_TO) {
        return parseInt(j.value, 10) !== parseInt(response, 10);
      }
      if (j.operator === operators.GREATER_THAN) {
        return parseInt(j.value, 10) < parseInt(response, 10);
      }
      if (j.operator === operators.LESSER_THAN) {
        return parseInt(j.value, 10) > parseInt(response, 10);
      }
      return null;
    });
  }

  // Jump / End Survey : we close the poke
  // Step / End Survey : we close the poke
  if (
    matchingJump?.endSurvey === true ||
    (matchingJump == null && step?.endSurvey === true)
  ) {
    return {state: EVALUATE_CLOSE};
  }

  if (matchingJump != null) {
    const index = steps
      .map((s) => s.uid)
      .indexOf(matchingJump.goTo?.uid || matchingJump.goTo);
    if (index > 0) {
      const isNextStepAfterLastInteractiveStep =
        steps.slice(index, steps.length).some((s) => isInteractiveStep(s)) ===
        false;
      // If next step is after last interactive step : we set survey to completed in the return object along with the next index
      const state =
        isNextStepAfterLastInteractiveStep === true ? EVALUATE_COMPLETED : null;
      return {
        state,
        stepIndex: index,
      };
    }
  } else if (step?.goTo != null) {
    const index = steps.map((s) => s.uid).indexOf(step.goTo?.uid || step.goTo);
    if (index > 0) {
      const isNextStepAfterLastInteractiveStep =
        steps.slice(index, steps.length).some((s) => isInteractiveStep(s)) ===
        false;
      // If next step is after last interactive step : we set survey to completed in the return object along with the next index
      const state =
        isNextStepAfterLastInteractiveStep === true ? EVALUATE_COMPLETED : null;

      return {
        state,
        stepIndex: index,
      };
    }
  }

  // If no jump or goTo, if current step was last interactive step : we set survey to completed in the return object along with the next index
  const state = isLastInteractiveStep === true ? EVALUATE_COMPLETED : null;

  return {
    state,
    stepIndex: stepIndex + 1,
  };
};

export function areEqual(array1, array2) {
  if (array1.length === array2.length) {
    return array1.every((element) => {
      if (array2.includes(element)) {
        return true;
      }

      return false;
    });
  }

  return false;
}
