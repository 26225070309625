import React, {useCallback, useRef} from 'react';
import {Group} from '@visx/group';
import {coerceNumber, scaleLinear, scaleOrdinal, scaleUtc} from '@visx/scale';
import {AreaClosed, LinePath} from '@visx/shape';
import {useTooltip, TooltipWithBounds} from '@visx/tooltip';
import {localPoint} from '@visx/event';
import {GlyphCircle} from '@visx/glyph';
import * as allCurves from '@visx/curve';
import dayjs from 'dayjs';
import {withParentSize} from '@visx/responsive';
import {AnimatedAxis, AnimatedGridRows} from '@visx/react-spring';
import './_Styles.scss';
import {array, string} from 'prop-types';
import {LinearGradient} from '@visx/gradient';

const getMinMax = (vals) => {
  const numericVals = vals.map(coerceNumber);
  return [Math.min(...numericVals), Math.max(...numericVals)];
};

const propTypes = {
  data: array,
  series: array,
  yLabel: string,
  yUnit: string,
};

const defaultProps = {
  data: [],
  series: [],
  yLabel: '',
  yUnit: '',
};

const LineChart = React.memo(
  ({data, series, parentWidth: width, parentHeight: height, yLabel, yUnit}) => {
    const {
      tooltipData,
      tooltipLeft = 0,
      tooltipTop = 0,
      showTooltip,
      hideTooltip,
    } = useTooltip();

    const ref = useRef();
    // define margins from where to start drawing the chart
    const margin = {top: 30, right: 30, bottom: 30, left: 30};

    // defining inner measurements
    const legendSpace = 20;
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom - legendSpace;

    // Defining selector functions
    const getDate = (d) => d?.date;
    const getValue = (d) => d?.value;

    const formatDate = (date) => {
      return dayjs(date).format('MMM DD');
    };

    const timeValues = series[0].map((e) => new Date(e.date));
    const dateScale = scaleUtc({
      domain: getMinMax(timeValues),
      range: [0, innerWidth],
    });

    const handleTooltip = (event) => {
      const {x: xRaw, y: yRaw} = localPoint(event) || {x: 0, y: 0};
      const x = xRaw - margin.left;
      const y = yRaw - margin.top;
      const x0 = dateScale.invert(x);
      const date = dayjs(x0).format('YYYY-MM-DD');

      const dataAtDates = series.map((s) => s.find((c) => c.date === date));
      if (dataAtDates.filter((d) => d).length > 0) {
        showTooltip({
          tooltipData: dataAtDates,
          tooltipLeft: dateScale(new Date(getDate(dataAtDates[0]))),
          tooltipTop: y,
        });
      }
    };

    const maxValuePerDay = Math.max(...data.map(getValue));

    const xMax = innerWidth;
    const yMax = innerHeight;

    const yScale = scaleLinear({
      range: [yMax, 0],
      domain: [0, maxValuePerDay],
      nice: true,
    });

    dateScale.rangeRound([0, xMax]);
    yScale.range([yMax, 0]);

    const Memoized = React.memo(LinePath);

    const yTicks = yScale.ticks().filter((t) => t % 1 <= 0);
    const xTicks = dateScale.ticks().filter((_, index, arr) => {
      return index % parseInt(arr.length / 4) === 0;
    });
    // filter dates if there is already a date with the same day in the array
    const uniqueXTicksArray = xTicks.filter(
      (date, index, arr) =>
        arr.findIndex((d) => dayjs(d).isSame(date, 'day')) === index
    );

    return (
      <div style={{height, width}} className="line-chart" ref={ref}>
        <svg width={width} height={height - legendSpace}>
          <Group left={margin.left} top={margin.top} height={height}>
            <AnimatedGridRows
              scale={yScale}
              width={innerWidth}
              height={innerHeight - margin.top}
              stroke="rgba(0, 0, 0)"
              strokeOpacity={0.05}
            />
            <AnimatedAxis
              orientation="left"
              hideTicks
              hideAxisLine
              tickStroke={'transparent'}
              scale={yScale}
              tickValues={yTicks}
              tickFormat={(t) => parseInt(t)}
              numTicks={3}
              hideZero
              tickLabelProps={() => ({
                fill: '#000000',
                fontSize: 14,
                textAnchor: 'middle',
              })}
            />
            <text
              x="-125"
              y="20"
              transform="rotate(-90)"
              fontSize={12}
              fill="#000000">
              {yLabel}
            </text>
            <AnimatedAxis
              top={innerHeight}
              scale={dateScale}
              tickFormat={formatDate}
              hideTicks
              tickValues={uniqueXTicksArray}
              numTicks={timeValues.length < 10 ? timeValues.length - 1 : 11}
              label={'time'}
              hideAxisLine
              tickStroke={'transparent'}
              tickLabelProps={() => ({
                fill: '#000000',
                fontSize: 14,
                textAnchor: 'middle',
              })}
            />
            {series.map((sData, i) => {
              const gradientId = `gradient-${i}`;
              return (
                <>
                  <LinearGradient
                    id={gradientId}
                    from="#E5EFFF"
                    fromOpacity={1}
                    to="#E5EFFF"
                    toOpacity={0.2}
                  />
                  <AreaClosed
                    data={sData}
                    x={(d) => dateScale(new Date(getDate(d))) ?? 0}
                    y={(d) => yScale(getValue(d)) ?? 0}
                    yScale={yScale}
                    strokeWidth={0}
                    stroke={`url(#${gradientId})`}
                    fill={`url(#${gradientId})`}
                    curve={allCurves['curveLinear']}
                  />
                  <Memoized
                    key={i}
                    curve={allCurves['curveLinear']}
                    stroke="#1260eb80"
                    strokeWidth={2}
                    data={sData}
                    x={(d) => dateScale(new Date(getDate(d))) ?? 0}
                    y={(d) => yScale(getValue(d)) ?? 0}
                  />
                </>
              );
            })}

            {tooltipData &&
              tooltipData.map((d, i) => {
                if (d != null) {
                  return (
                    <g>
                      <GlyphCircle
                        left={tooltipLeft}
                        top={yScale(d?.value) + 2}
                        size={110}
                        fill="#fff"
                        stroke="#0000001a"
                        strokeWidth={2}
                      />
                    </g>
                  );
                }
                return <></>;
              })}
            <rect
              x={0}
              y={0}
              width={innerWidth}
              height={innerHeight}
              onTouchStart={handleTooltip}
              fill={'transparent'}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => hideTooltip()}
            />
          </Group>
        </svg>
        {tooltipData ? (
          <TooltipWithBounds
            className="tooltip-with-bounds"
            key={Math.random()}
            top={tooltipTop + ref.current?.getBoundingClientRect()?.top}
            left={
              tooltipLeft + ref.current?.getBoundingClientRect()?.left - 230
            }>
            <div className="date">{`${dayjs(tooltipData?.[0]?.date).format(
              'MMMM DD'
            )}`}</div>
            {tooltipData.map((d) => {
              return (
                <div className="tooltip-value">
                  <div
                    className="line-indicator"
                    style={{backgroundColor: '#1260eb80'}}
                  />
                  {parseInt(d?.value)}
                  <span className="tooltip-yUnit">{yUnit}</span>
                  <span className="tooltip-ylabel">{d.label}</span>
                </div>
              );
            })}
          </TooltipWithBounds>
        ) : null}
      </div>
    );
  }
);

LineChart.propTypes = propTypes;
LineChart.defaultProps = defaultProps;

export default withParentSize(LineChart);
