import classnames from 'classnames';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import {hasFlag} from 'helpers/bitwise';
import {isActiveOnDomain, isActiveOnPath} from 'helpers/utils';
import {bool, func, object} from 'prop-types';
import {useState} from 'react';
import {F_BOOST_SLOT_TOUR} from 'services/evolution';
import {v4 as uuidv4} from 'uuid';
import './_styles.scss';

const operatorOptions = [
  {label: 'Starts with', value: 'STARTS_WITH'},
  {label: "Doesn't start with", value: 'DOESNT_START_WITH'},
  {label: 'Ends with', value: 'ENDS_WITH'},
  {label: "Doesn't end with", value: 'DOESNT_END_WITH'},
  {label: 'Contains', value: 'CONTAINS'},
  {label: "Doesn't contain", value: 'DOESNT_CONTAIN'},
  {label: 'Equals', value: 'EQUALS'},
  {label: "Doesn't equal", value: 'DOESNT_EQUAL'},
  {label: 'Matches regex', value: 'MATCHES_REGEX'},
];

const globalOperatorOptions = [
  {label: 'Should match one of the path rules', value: 'OR'},
  {label: 'Should match all the path rules', value: 'AND'},
];

const propTypes = {
  evolution: object.isRequired,
  setEvolution: func.isRequired,
  small: bool,
};

const defaultProps = {
  small: false,
};

export const PathSelector = ({evolution, setEvolution, small}) => {
  return (
    <div
      className={classnames('builder-audience-path-selector', {
        small: small === true,
      })}>
      <Rules
        paths={evolution.boostedPaths}
        onPathsChange={(paths) => {
          setEvolution({...evolution, boostedPaths: paths});
        }}
        pathOperator={evolution.boostedPathOperator}
        onPathOperatorChange={(operator) => {
          setEvolution({...evolution, boostedPathOperator: operator});
        }}
        small={small}
      />
      <Domains evolution={evolution} setEvolution={setEvolution} />
      <UrlTester
        paths={evolution.boostedPaths}
        domainFilter={evolution.boostedDomainFilter}
        pathOperator={evolution.boostedPathOperator}
        isTour={hasFlag(evolution.boostedSlot, F_BOOST_SLOT_TOUR)}
      />
    </div>
  );
};

PathSelector.propTypes = propTypes;
PathSelector.defaultProps = defaultProps;

export const Rules = ({
  paths = [],
  onPathsChange,
  pathOperator,
  onPathOperatorChange,
  small = false,
}) => {
  return (
    <div className="rules-wrapper">
      <div className="rules-title">Rules</div>
      <div className="path-list">
        {paths.length >= 2 && (
          <Select
            className="global-operator-select"
            options={globalOperatorOptions}
            placeholder="Select an operator"
            closeMenuOnSelect
            onChange={(option) => {
              onPathOperatorChange(option?.value);
            }}
            value={globalOperatorOptions?.find((o) => pathOperator === o.value)}
          />
        )}
        {paths.map((path) => (
          <BoostedPath
            small={small}
            boostedPath={path}
            updatePath={(update) => {
              onPathsChange(
                paths.map((p) => {
                  if (p.uid === path.uid) {
                    return {
                      ...p,
                      ...update,
                    };
                  }
                  return p;
                })
              );
            }}
            deletePath={() =>
              onPathsChange(paths.filter((p) => p.uid !== path.uid))
            }
          />
        ))}
      </div>
      <Button
        className="btn-add-path"
        onClick={() => {
          onPathsChange([
            ...paths,
            {
              uid: uuidv4(),
              path: '',
              operator: 'STARTS_WITH',
            },
          ]);
        }}
        iconLeft="icon-plus-rounded">
        Add rule
      </Button>
    </div>
  );
};

export const Domains = ({evolution, setEvolution}) => {
  return (
    <div className="domains-wrapper">
      <div className="domains-title">Domains</div>
      {evolution.boostedDomainFilter != null ? (
        <>
          <Input
            className="input-domain-filter"
            placeholder="Insert a domain or a list of domains or a regex"
            iconRight="icon-trash"
            iconRightProps={{
              onClick: () =>
                setEvolution({...evolution, boostedDomainFilter: null}),
            }}
            value={evolution.boostedDomainFilter}
            onChange={({target}) =>
              setEvolution({
                ...evolution,
                boostedDomainFilter: target.value,
              })
            }></Input>
          <div className="filter-domain-info-wrapper">
            <p>
              <i className="icon-info-circle-o"></i>Tips
            </p>
            <p className="filter-domain-info">
              - A domain :<pre>foo.com</pre>
            </p>
            <p className="filter-domain-info">
              - List of domains :<pre>foo.com;bar.com</pre> (separate domains
              with a<pre>;</pre>)
            </p>
            <p className="filter-domain-info">
              - Regex :<pre>\.bar.com$</pre> (match all subdomains ending with{' '}
              <pre>bar.com</pre>)
            </p>
          </div>
        </>
      ) : (
        <Button
          className="btn-add-domain"
          onClick={() => {
            setEvolution({
              ...evolution,
              boostedDomainFilter: '',
            });
          }}
          iconLeft="icon-plus-rounded">
          Add domain
        </Button>
      )}
    </div>
  );
};

export const UrlTester = ({
  paths,
  domainFilter,
  pathOperator,
  isTour = false,
}) => {
  const [testUrl, setTestUrl] = useState('');

  let testUrlText = null;
  let code = null;

  if (testUrl) {
    if (!(paths?.length > 0) && !(domainFilter?.length > 0)) {
      testUrlText = 'No path rules added yet';
      code = 'NO_RULES';
    } else {
      let pathname = null;
      let host = null;

      try {
        const url = new URL(testUrl);
        host = url.host;
        pathname = url.href.replace(url.origin, '');
      } catch (e) {
        testUrlText =
          'Invalid URL, enter a valid URL including protocol please';
        code = 'INVALID_URL';
      }

      if (code == null) {
        const activeOnDomain =
          domainFilter != null
            ? isActiveOnDomain(domainFilter, host)
            : {isActive: true};
        const isActOnDomain = activeOnDomain.isActive;
        const isActive = isActiveOnPath(pathOperator, paths, pathname);

        if (isActive === true && isActOnDomain === true) {
          testUrlText = `Your experience will be shown on the given URL`;
          code = 'URL_MATCH';
        } else {
          if (activeOnDomain.error != null) {
            testUrlText = `An error occurred: ${activeOnDomain.error.message}`;
          } else {
            testUrlText = `Your experience will not be shown on the given URL`;
          }
          code = 'URL_NO_MATCH';
        }
      }
    }
  }

  return (
    <div
      className={classnames('url-tester-wrapper', {
        'invalid-url': ['INVALID_URL', 'NO_RULES'].includes(code),
        'url-match': code === 'URL_MATCH',
        'url-no-match': code === 'URL_NO_MATCH',
      })}>
      <div className="url-tester-title">Test your url</div>
      <div className="url-tester">
        <div className="icon-wrapper">
          <i className="icon-back" />
        </div>
        <Input
          className="url-tester-input"
          name="value"
          type="string"
          placeholder="www.xyz.com"
          value={testUrl}
          onChange={(e) => {
            setTestUrl(e.target.value);
          }}
        />
      </div>
      {testUrlText != null && (
        <div
          className={classnames('url-tester-text', {
            'invalid-url': ['INVALID_URL', 'NO_RULES'].includes(code),
            'url-match': code === 'URL_MATCH',
            'url-no-match': code === 'URL_NO_MATCH',
          })}>
          {testUrlText}
        </div>
      )}
    </div>
  );
};

export const BoostedPath = ({
  boostedPath,
  updatePath,
  deletePath,
  small = false,
}) => {
  const {path, operator} = boostedPath;

  return (
    <div className="builder-audience-boosted-path">
      <Select
        className="operator-select"
        options={operatorOptions}
        placeholder="Select an operator"
        closeMenuOnSelect
        onChange={(option) => {
          updatePath({operator: option?.value});
        }}
        value={operatorOptions?.find((o) => operator === o.value)}
      />
      <Input
        required
        className="path-input"
        name="value"
        type="string"
        placeholder={
          ['STARTS_WITH', 'DOESNT_START_WITH'].includes(operator)
            ? '/path-start or ?path-start or #path-start'
            : 'path-content'
        }
        value={path || ''}
        onChange={(e) => {
          updatePath({path: e.target.value});
        }}
        labelTextLeft={small ? '.com' : 'www.xyz.com'}
        iconRight="icon-trash"
        iconRightProps={{
          onClick: () => {
            deletePath();
          },
        }}
      />
    </div>
  );
};
