import {HOTSPOT_SHAPE_DEFAULT} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Hotspot';

export const extractFields = (obj, template) => {
  const result = {};

  for (const [key] of Object.entries(template)) {
    if (obj[key] !== undefined) {
      result[key] = obj[key];
    }
  }

  return result;
};

export const customizationTemplate = {
  boostedPrimaryColor: '#ffffff',
  boostedSecondaryColor: '#1260EB',
  boostedTextsColors: '#071331;#071331',
  boostedRoundness: 8,
  boostedTitleFontSize: 18,
  boostedContentFontSize: 12,
  boostedDotStyle: `#1260EB;18;${HOTSPOT_SHAPE_DEFAULT}`,
  boostedSize: null,
  boostedLightbox: 'SOFT',
  boostedAnimations: 'slide;slide;left;left',
};
