import {hasFlag} from 'helpers/bitwise';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {F_EXTRA_PORTAL_WIDGET_ENABLED} from 'services/project';
import Standalone from '../components/Standalone';
import Widget from '../components/Widget';
import './_Styles.scss';

export const PORTAL_TYPE_STANDALONE = 'STANDALONE';
export const PORTAL_TYPE_WIDGET = 'WIDGET';

const AppearancePortal = () => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const [portalType, setPortalType] = useState(
    hasFlag(F_EXTRA_PORTAL_WIDGET_ENABLED, project.extraFlags)
      ? PORTAL_TYPE_WIDGET
      : PORTAL_TYPE_STANDALONE
  );

  return (
    <div className="appearance-portal">
      <div className="main-wrapper">
        <div className="main-settings-wrapper">
          <div
            style={{
              display:
                portalType === PORTAL_TYPE_STANDALONE ? 'initial' : 'none',
            }}>
            <Standalone portalType={portalType} setPortalType={setPortalType} />
          </div>
          <div
            style={{
              display: portalType === PORTAL_TYPE_WIDGET ? 'initial' : 'none',
            }}>
            <Widget portalType={portalType} setPortalType={setPortalType} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppearancePortal;
