import classNames from 'classnames';
import {bool, func, object, string} from 'prop-types';
import React from 'react';
import './_Styles.scss';

const propTypes = {
  className: string,
  value: string,
  onErase: func,
  erasable: bool,
  iconLeft: object,
  disabled: bool,
  inputProps: object,
  isEditing: bool,
  invalid: bool,
};
const defaultProps = {
  className: '',
  value: '',
  onErase: () => {},
  erasable: false,
  leftLabel: null,
  iconLef: null,
  disabled: false,
  inputProps: {},
  isEditing: false,
  invalid: false,
};

const ClickableInput = ({
  className,
  value,
  onErase,
  leftLabel,
  iconLeft,
  erasable,
  disabled,
  inputProps,
  active,
  invalid,
  ...rest
}) => {
  const handleErase = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onErase();
  };

  return (
    <div
      className={classNames('clickable-input', className, {
        disabled,
        'is-editing': active,
        'empty-left-label': !leftLabel,
        'is-invalid': invalid,
      })}
      {...rest}>
      {leftLabel && <div className="label-left">{leftLabel}</div>}
      <div className="content">
        {value ? (
          <div className="value">{value}</div>
        ) : (
          <div className="n-400">{inputProps?.placeholder || 'Add...'}</div>
        )}
      </div>
      {erasable === true && value !== null && (
        <div className="label-right" onClick={handleErase}>
          <i className="icon-close n-500" />
        </div>
      )}
    </div>
  );
};

ClickableInput.propTypes = propTypes;
ClickableInput.defaultProps = defaultProps;

export default ClickableInput;
