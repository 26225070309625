import classNames from 'classnames';
import {func, string} from 'prop-types';
import React from 'react';
import './_Styles.scss';

export const ACTION_NONE = 'none';
export const ACTION_STEP_NEXT = 'step-next';
export const ACTION_STEP_PREV = 'step-prev';
export const ACTION_CLOSE = 'close';

export const navigationActions = [
  {
    name: 'None',
    icon: 'icon-close-circle',
    value: ACTION_NONE,
  },
  {
    name: 'To next step',
    icon: 'icon-forward',
    value: ACTION_STEP_NEXT,
  },
  {
    name: 'To previous step',
    icon: 'icon-backward',
    value: ACTION_STEP_PREV,
  },
  {
    name: 'Dismiss',
    icon: 'icon-go-next',
    value: ACTION_CLOSE,
  },
];

const propTypes = {
  value: string,
  omit: [string],
  onChange: func,
};

const defaultProps = {
  value: null,
  omit: [],
  onChange: () => {},
};

const Navigation = ({value, omit, onChange}) => {
  return (
    <div className="navigation-list">
      {navigationActions
        .filter((a) => omit.includes(a.value) === false)
        .map((action) => (
          <div
            className={classNames('navigation-item', {
              selected: value === action.value,
            })}
            onClick={() => onChange(action.value)}>
            <i className={action.icon} />
            {action.name}
          </div>
        ))}
    </div>
  );
};

Navigation.propTypes = propTypes;
Navigation.defaultProps = defaultProps;

export default Navigation;
