import classnames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import DefaultLoader from 'components/Loader';

export const UpgradeResume = ({
  plan,
  coupon,
  taxData,
  eligibleToTrial,
  requireApplyCoupon,
  onConfirm,
  isConfirming,
  isFetchingTaxData,
  submitDisabled,
  modeAnnualBilling,
}) => {
  const basePricePerMonth =
    modeAnnualBilling === true ? plan.prices[0] : plan.prices[1];
  const basePrice =
    modeAnnualBilling === true ? basePricePerMonth * 12 : basePricePerMonth;
  const subTotal =
    coupon == null
      ? basePrice
      : Math.round(
          basePrice - (coupon.stripeData.percent_off / 100) * basePrice
        );
  const total = taxData.reduce(
    (sum, tax) => subTotal + subTotal * (tax.percentage / 100),
    subTotal
  );
  const className = classnames('upgrade-resume', {
    'has-discount': coupon != null,
  });

  return (
    <div className={className}>
      {eligibleToTrial === true ? (
        <span className="trial-label">
          You are eligible for a 14 days free trial
        </span>
      ) : (
        <>
          <div className="plan-price-detail-wrapper">
            <div className="detail-item detail-plan">
              <div className="detail-label">Plan {plan.title}</div>
              <div className="detail-value">${basePrice}</div>
            </div>
            <div className="detail-description description-plan">
              ${basePricePerMonth} per month - billed{' '}
              {modeAnnualBilling === true ? 'yearly' : 'monthly'}
            </div>
            {coupon != null && (
              <>
                <div className="detail-item detail-discount">
                  <div className="detail-label">Discount</div>
                  <div className="detail-value">
                    {coupon.stripeData.percent_off}%
                  </div>
                </div>
                <div className="detail-description description-discount">
                  {coupon.stripeData.duration !== 'forever' &&
                    `Applied for the next ${coupon.stripeData.duration_in_months} months.`}
                </div>
              </>
            )}
            <Divider />
            <div className="detail-item detail-subtotal">
              <div className="detail-label">Subtotal</div>
              <div className="detail-value">${subTotal}</div>
            </div>
            <div className="detail-item detail-tax">
              <div className="detail-label">Tax</div>
              <div className="detail-value">
                {taxData.length === 0 && isFetchingTaxData === false && '-'}
                {isFetchingTaxData === true && <DefaultLoader width="8px" />}
              </div>
            </div>
            {taxData.map((tax) => (
              <div className="detail-item detail-tax-entry">
                <div className="detail-label"></div>
                <div className="detail-value">
                  {tax.displayName} &middot; {tax.percentage}%{' '}
                  <span>
                    (${Math.round(subTotal * (tax.percentage / 100) * 10) / 10})
                  </span>
                </div>
              </div>
            ))}
            <div className="detail-item detail-total">
              <div className="detail-label">Total for today</div>
              <div className="detail-value">${total}</div>
            </div>
          </div>
          <Button
            className="btn-confirm-upgrade"
            primary
            iconRight="icon-chevron-right"
            disabled={requireApplyCoupon || submitDisabled}
            onClick={onConfirm}
            loading={isConfirming}>
            {requireApplyCoupon === true
              ? 'Apply your coupon'
              : eligibleToTrial === true
              ? 'Start your trial'
              : `Upgrade to ${plan.title}`}
          </Button>
        </>
      )}
    </div>
  );
};
