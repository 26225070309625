import {generalActions} from 'actions';
import {uptProjectMember} from 'actions/general';
import classnames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Input from 'components/Input';
import Select from 'components/Select';
import TextArea from 'components/TextArea';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag} from 'helpers/bitwise';
import React from 'react';
import {connect} from 'react-redux';
import {generalSelector} from 'selectors';
import {projectService} from 'services';
import {
  F_EXTRA_PORTAL_STANDALONE_ENABLED,
  F_EXTRA_PORTAL_WIDGET_ENABLED,
  F_GET_STARTED_CUSTOMIZE_PORTAL,
  F_GET_STARTED_ENABLE_ROADMAP,
  F_PAGE_ROADMAP,
  PORTAL_STYLE_LIGHT,
} from 'services/project';
import {Swaler} from 'swaler';
import LabeledInput from '../../../../components/LabeledInput';
import PreviewContainer from '../../../../components/PreviewContainer';
import Saver from '../../../../components/Saver';
import {PORTAL_TYPE_STANDALONE, PORTAL_TYPE_WIDGET} from '../../Portal';
import {IframeMessenger} from '../../helpers/iframe-messenger';
import {PortalContentToggler} from '../ContentToggler';
import PreviewPortal from '../PreviewPortal';
import './_Styles.scss';

const PREVIEW_MODE_DESKTOP = 'MODE_DESKTOP';
const PREVIEW_MODE_MOBILE = 'MODE_MOBILE';

export const selectPortalStyle = [
  {
    label: [<div className="dot-dark"></div>, 'Dark'],
    value: projectService.PORTAL_STYLE_DARK,
  },
  {
    label: [<div className="dot-light"></div>, 'Light'],
    value: projectService.PORTAL_STYLE_LIGHT,
  },
];

class Standalone extends React.Component {
  logger = new Swaler('AppearancePortal');

  constructor(props) {
    super();
    this.state = {
      isSaving: false,
      isUploadingLogo: false,
      isDeletingLogo: false,
      previewMode: PREVIEW_MODE_DESKTOP,
      portalStyle: undefined,
      portalLanguage: undefined,
      portalContentFlags: undefined,
      inputPortalWelcomeTitle: null,
      inputPortalWelcomeMessage: null,
      portalSectionOrder: undefined,
    };
    this.selectLanguageOptions = [
      {
        label: [<i className="icon-detect"></i>, 'Detect from user country'],
        value: 'AUTO',
      },
      {
        label: [<span className="flag-icon flag-icon-fr"></span>, 'Français'],
        value: 'FR',
      },
      {
        label: [<span className="flag-icon flag-icon-gb"></span>, 'English'],
        value: 'EN',
      },
      {
        label: [<span className="flag-icon flag-icon-es"></span>, 'Español'],
        value: 'ES',
      },
      {
        label: [<span className="flag-icon flag-icon-pt"></span>, 'Português'],
        value: 'PT',
      },
      {
        label: [<span className="flag-icon flag-icon-it"></span>, 'Italiano'],
        value: 'IT',
      },
      {
        label: [<span className="flag-icon flag-icon-de"></span>, 'Deutsch'],
        value: 'DE',
      },
      {
        label: [<span className="flag-icon flag-icon-jp"></span>, '日本'],
        value: 'JP',
      },
      {
        label: [<span className="flag-icon flag-icon-cn"></span>, '中國人'],
        value: 'ZH',
      },
      {
        label: [<span className="flag-icon flag-icon-sk"></span>, 'Slovenský'],
        value: 'SK',
      },
      {
        label: [<span className="flag-icon flag-icon-se"></span>, 'Svenska'],
        value: 'SV',
      },
    ];
    this.refInputFileLogo = React.createRef();
    this.iframeMessenger = new IframeMessenger(
      React.createRef(),
      props.project
    );
  }

  hasChanges = () =>
    this.state.portalStyle != null ||
    this.state.portalLanguage != null ||
    this.state.portalContentFlags != null ||
    this.state.portalSectionOrder != null ||
    this.props.project.portalWelcomeTitle !==
      this.state.inputPortalWelcomeTitle ||
    this.props.project.portalWelcomeMessage !==
      this.state.inputPortalWelcomeMessage;

  handleChangePreviewMode = (previewMode) => this.setState({previewMode});
  handleInputChange = ({target}, setNullIfEmpty) =>
    this.setState({
      [target.name]:
        setNullIfEmpty === true && target.value.length === 0
          ? null
          : target.value,
    });
  handleStyleChange = (option) =>
    this.setState({
      portalStyle:
        option.value === this.props.project.portalStyle
          ? undefined
          : option.value,
    });
  handleLanguageChange = (option) =>
    this.setState({
      portalLanguage:
        option.value === this.props.project.portalLanguage
          ? undefined
          : option.value,
    });
  handleContentChange = (portalContentFlags) => {
    const {project} = this.props;

    this.setState({
      portalContentFlags:
        project.portalContentFlags === portalContentFlags
          ? undefined
          : portalContentFlags,
    });
  };
  handleSectionOrderChange = (portalSectionOrder) => {
    const {project} = this.props;

    this.setState({
      portalSectionOrder:
        project.portalSectionOrder === portalSectionOrder
          ? undefined
          : portalSectionOrder,
    });
  };
  handleSave = async () => {
    const {project, projectMember, uptProject, uptProjectMember} = this.props;
    const {
      portalStyle,
      portalLanguage,
      portalContentFlags,
      inputPortalWelcomeTitle,
      inputPortalWelcomeMessage,
      portalSectionOrder,
    } = this.state;

    this.setState({isSaving: true});
    try {
      const projectUpdated = await projectService.updateProject(project.uid, {
        portalStyle,
        portalLanguage,
        portalContentFlags,
        portalWelcomeTitle: inputPortalWelcomeTitle,
        portalWelcomeMessage: inputPortalWelcomeMessage,
        portalSectionOrder,
      });

      uptProject(projectUpdated);
      this.handleResetChanges();
      this.setState({isSaving: false});
      if (
        hasFlag(
          F_GET_STARTED_CUSTOMIZE_PORTAL,
          projectMember.getStartedFlags
        ) === false
      ) {
        const getStartedFlagsUpdated = addFlag(
          F_GET_STARTED_CUSTOMIZE_PORTAL,
          projectMember.getStartedFlags
        );

        await projectService.updateGetStartedFlag({
          flag: getStartedFlagsUpdated,
        });
        uptProjectMember({getStartedFlags: getStartedFlagsUpdated});
      }
      if (
        hasFlag(F_PAGE_ROADMAP, projectUpdated.portalContentFlags) === true &&
        hasFlag(F_GET_STARTED_ENABLE_ROADMAP, projectMember.getStartedFlags) ===
          false
      ) {
        const newGetStartedFlag = addFlag(
          F_GET_STARTED_ENABLE_ROADMAP,
          projectMember.getStartedFlags
        );

        await projectService.updateGetStartedFlag({flag: newGetStartedFlag});
        uptProjectMember({
          getStartedFlags: newGetStartedFlag,
        });
      }
      return toastSuccess(['Done!', `Changes has been saved successfully!`], {
        autoClose: 3000,
        toastId: 'changes-saved',
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      this.setState({isSaving: false});
      this.logger.error('Saving changelog appearance failed with error ', code);
      return toastDanger([title, message], {actions});
    }
  };
  handleResetChanges = () =>
    this.setState({
      portalStyle: undefined,
      portalLanguage: undefined,
      inputPortalWelcomeTitle: this.props.project.portalWelcomeTitle,
      inputPortalWelcomeMessage: this.props.project.portalWelcomeMessage,
      portalContentFlags: undefined,
      portalSectionOrder: undefined,
    });
  handleUploadLogo = async (e) => {
    const {uptProject} = this.props;
    const file = e.target.files.length !== 0 ? e.target.files[0] : null;

    if (file == null) {
      return;
    }
    this.setState({isUploadingLogo: true});
    try {
      const uploadedFile = await projectService.uploadPortalLogo({file});
      this.setState({isUploadingLogo: false});
      uptProject({portalLogoUrl: uploadedFile.publicUrl});
      toastSuccess(
        ['Logo uploaded', 'Your logo is now available on your changelog!'],
        {toastId: 'logo-uploaded'}
      );
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      this.setState({isUploadingLogo: false});
      this.logger.error('Upload changelog logo failed with error ', code);
      return toastDanger([title, message], {actions});
    }
  };
  handleDeletePortalLogo = async (e) => {
    const {uptProject} = this.props;

    this.setState({isDeletingLogo: true});
    try {
      await projectService.deletePortalLogo();
      uptProject({portalLogoUrl: null});
      this.setState({isDeletingLogo: false});
      toastSuccess('Logo deleted!', {toastId: 'logo-deleted'});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      this.setState({isDeletingLogo: false});
      this.logger.error('Delete changelog logo failed with error ', code);
      return toastDanger([title, message], {actions});
    }
  };

  render() {
    const {project, portalType, setPortalType} = this.props;
    const {
      previewMode,
      portalStyle = project.portalStyle,
      portalLanguage = project.portalLanguage,
      portalContentFlags = project.portalContentFlags,
      inputPortalWelcomeTitle,
      inputPortalWelcomeMessage,
      isSaving,
      isUploadingLogo,
      isDeletingLogo,
      portalSectionOrder = project.portalSectionOrder,
    } = this.state;
    const classNames = classnames('appearance-standalone fade-in');
    const welcomeTitle =
      inputPortalWelcomeTitle == null
        ? `Help us improve ${project.name}`
        : inputPortalWelcomeTitle;
    const welcomeMessage =
      inputPortalWelcomeMessage == null
        ? `Welcome to our feedback changelog, you can vote potential update, preview upcoming features and have a real impact on ${project.name}.`
        : inputPortalWelcomeMessage;

    this.iframeMessenger.sendProjectUpdate({
      ...project,
      portalStyle,
      portalContentFlags,
      portalLanguage,
      portalWelcomeTitle: inputPortalWelcomeTitle,
      portalWelcomeMessage: inputPortalWelcomeMessage,
      portalSectionOrder,
    });

    return (
      <div className={classNames}>
        <div className="content">
          <div className="settings-wrapper">
            <div className="type-select-btns">
              <Button
                className={classnames({
                  selected: portalType === PORTAL_TYPE_WIDGET,
                })}
                onClick={() => setPortalType(PORTAL_TYPE_WIDGET)}
                disabled={
                  hasFlag(
                    F_EXTRA_PORTAL_WIDGET_ENABLED,
                    this.props.project.extraFlags
                  ) === false
                }>
                Widget
              </Button>
              <Button
                className={classnames({
                  selected: portalType === PORTAL_TYPE_STANDALONE,
                })}
                onClick={() => setPortalType(PORTAL_TYPE_STANDALONE)}
                disabled={
                  hasFlag(
                    F_EXTRA_PORTAL_STANDALONE_ENABLED,
                    this.props.project.extraFlags
                  ) === false
                }>
                Standalone
              </Button>
            </div>
            <LabeledInput title="Style" className="change-style">
              <Select
                hasSettingsMenuColor
                options={selectPortalStyle}
                value={selectPortalStyle.find((s) => s.value === portalStyle)}
                onChange={this.handleStyleChange}></Select>
            </LabeledInput>
            <Divider></Divider>
            <LabeledInput
              title="Content"
              description="Decide what to display in your changelog"
              className="change-content">
              <PortalContentToggler
                contentFlags={portalContentFlags}
                onChange={this.handleContentChange}
                sectionOrder={portalSectionOrder || 'feed;roadmap;feedback'}
                onSectionOrderChange={this.handleSectionOrderChange}
              />
            </LabeledInput>
            <Divider></Divider>
            <LabeledInput
              title="Welcoming Message"
              description="Use your own tone of voice to introduce this feedback changelog to your users"
              className="change-welcome-texts">
              <Input
                name="inputPortalWelcomeTitle"
                placeholder={welcomeTitle}
                value={
                  inputPortalWelcomeTitle == null ? '' : inputPortalWelcomeTitle
                }
                onChange={(e) => this.handleInputChange(e, true)}></Input>
              <TextArea
                name="inputPortalWelcomeMessage"
                placeholder={welcomeMessage}
                value={
                  inputPortalWelcomeMessage == null
                    ? ''
                    : inputPortalWelcomeMessage
                }
                onChange={(e) => this.handleInputChange(e, true)}></TextArea>
            </LabeledInput>
            <Divider></Divider>
            <LabeledInput
              title="Add your logo"
              description={
                <>
                  We recommend a size of 150x50 pixels. <br />
                  Type: JPG, JPEG, GIF, PNG
                </>
              }>
              <input
                style={{display: 'none'}}
                type="file"
                ref={this.refInputFileLogo}
                onChange={this.handleUploadLogo}
              />
              {project.portalLogoUrl == null ? (
                <Button
                  className="btn-upload-logo"
                  rounded={false}
                  loading={isUploadingLogo}
                  onClick={() => this.refInputFileLogo.current.click()}>
                  Upload logo
                </Button>
              ) : (
                <div className="portal-logo">
                  <div className="logo-preview">
                    <img
                      src={project.portalLogoUrl}
                      alt={`${project.name} portal logo`}
                    />
                  </div>
                  <Button
                    muted
                    iconRight="icon-trash"
                    onClick={this.handleDeletePortalLogo}
                    loading={isDeletingLogo}>
                    Remove current logo
                  </Button>
                </div>
              )}
            </LabeledInput>
            <Divider />
            <LabeledInput
              title="Language"
              description="(autodetect, or force a language)"
              className="change-language">
              <Select
                hasSettingsMenuColor
                menuPlacement="top"
                options={this.selectLanguageOptions}
                value={this.selectLanguageOptions.find(
                  (o) => o.value === portalLanguage
                )}
                onChange={this.handleLanguageChange}></Select>
            </LabeledInput>
          </div>
          <div className="preview-container-wrapper">
            <PreviewContainer className="preview-portal-container">
              <a
                href={projectService.getProjectPortalUrl(project)}
                target="_blank"
                rel="noopener noreferrer">
                <Button className="btn-live-view" iconLeft="icon-goto" thin>
                  Live view
                </Button>
              </a>
              <div className="buttons-devices">
                <Button
                  iconOnly
                  thin
                  primary={portalStyle === PORTAL_STYLE_LIGHT}
                  onClick={() =>
                    this.handleChangePreviewMode(PREVIEW_MODE_MOBILE)
                  }>
                  <i className="icon-mobile"></i>
                </Button>
                <Button
                  iconOnly
                  thin
                  primary={portalStyle === PORTAL_STYLE_LIGHT}
                  onClick={() =>
                    this.handleChangePreviewMode(PREVIEW_MODE_DESKTOP)
                  }>
                  <i className="icon-desktop"></i>
                </Button>
              </div>
              <PreviewPortal
                project={project}
                isMobile={previewMode === PREVIEW_MODE_MOBILE}
                refIframe={this.iframeMessenger.refIframe}></PreviewPortal>
            </PreviewContainer>
          </div>
        </div>
        <Saver
          onSave={this.handleSave}
          onCancel={this.handleResetChanges}
          isOpen={this.hasChanges()}
          isSaving={isSaving}></Saver>
      </div>
    );
  }

  componentDidMount() {
    const {project} = this.props;

    this.setState({
      inputPortalWelcomeTitle: project.portalWelcomeTitle,
      inputPortalWelcomeMessage: project.portalWelcomeMessage,
    });
  }
}

const mapStateToProps = (state) => ({
  project: generalSelector.getProject(state),
  projectMember: generalSelector.getProjectMember(state),
});
const mapDispatchToProps = (dispatch) => ({
  uptProject: (project) => dispatch(generalActions.uptProject(project)),
  uptProjectMember: (data) => dispatch(uptProjectMember(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Standalone);
