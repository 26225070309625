import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_HINT,
  BLOCK_TYPE_INTERVIEW,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
  getDefaultStepStyle,
} from 'services/steps';
import {
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_STEPPER,
} from 'services/steps';
import {getDefaultBlockFromType} from 'scenes/PokeBuilder/components/BlockManager/utils';

export const getThemeStyleFromEvolution = (evolution, step) => {
  const themeStyle = {
    stepStyle: evolution.style,
    blocksStyle: step?.blocks.reduce((acc, block) => {
      acc[block.type] = {
        // add value or other properties here if needed
        style: block.style,
      };

      return acc;
    }, {}),
  };

  themeStyle.blocksStyle['HOTSPOT'] = {
    style: evolution.boostedDotStyle,
  };

  return themeStyle;
};

export const getColorsFromThemeStyle = (themeStyle) => {
  const {stepStyle, blocksStyle} = themeStyle;

  const backgroundColor =
    stepStyle.background.type === 'gradient'
      ? `linear-gradient(213.36deg, ${stepStyle.background.primaryColor}, ${stepStyle.background.secondaryColor})`
      : stepStyle.background.primaryColor || '#FFFFFF';

  const primaryButtonColor =
    blocksStyle?.[BLOCK_TYPE_PRIMARY_CTA]?.style?.primaryColor;
  const secondaryButtonColor =
    blocksStyle?.[BLOCK_TYPE_SECONDARY_CTA]?.style?.primaryColor;
  const labelColor = blocksStyle?.[BLOCK_TYPE_LABEL]?.style?.primaryColor;
  const stepperColor = blocksStyle?.[BLOCK_TYPE_STEPPER]?.style?.primaryColor;
  const stepperActiveColor =
    blocksStyle?.[BLOCK_TYPE_STEPPER]?.style?.secondaryColor;
  const choiceColor = blocksStyle?.[BLOCK_TYPE_CHOICE]?.style?.primaryColor;
  const sliderColor = blocksStyle?.[BLOCK_TYPE_SLIDER]?.style?.primaryColor;
  const sliderActiveColor =
    blocksStyle?.[BLOCK_TYPE_SLIDER]?.style?.secondaryColor;

  const colors = [
    backgroundColor,
    primaryButtonColor,
    secondaryButtonColor,
    labelColor,
    stepperColor,
    stepperActiveColor,
    choiceColor,
    sliderColor,
    sliderActiveColor,
  ]
    .filter((c) => c)
    .slice(0, 4);

  return colors;
};

export const getFontsFromThemeStyle = (themeStyle) => {
  const {blocksStyle} = themeStyle;

  const fonts = Object.keys(blocksStyle || {}).reduce((acc, block) => {
    const style = blocksStyle[block]?.style;
    if (style?.fontFamily && !acc.includes(style.fontFamily)) {
      acc.push(style.fontFamily);
    }
    return acc;
  }, []);

  return fonts?.join(', ') || 'Default';
};

export const generateThemeFromPalette = (themeBase) => {
  const {palette, borderRadius, fontFamilies} = themeBase;
  const style = {
    stepStyle: getDefaultStepStyle({
      shadow: {
        color: `${palette[2]}4d`, // 4d for 30% opacity
        x: 10,
        y: 10,
        blur: 20,
      },
      borderRadius,
      background: {
        type: 'color',
        animated: false,
        primaryColor: palette[2],
        secondaryColor: null,
      },
    }),
    blocksStyle: {
      [BLOCK_TYPE_TITLE]: {
        style: {
          ...getDefaultBlockFromType(BLOCK_TYPE_TITLE).style,
          fontColor: palette[0],
          fontFamily: fontFamilies[0],
        },
      },
      [BLOCK_TYPE_BODY]: {
        style: {
          ...getDefaultBlockFromType(BLOCK_TYPE_BODY).style,
          fontColor: palette[0],
          fontFamily: fontFamilies[0],
        },
      },
      [BLOCK_TYPE_LABEL]: {
        style: {
          ...getDefaultBlockFromType(BLOCK_TYPE_LABEL).style,
          fontColor: palette[0],
          fontFamily: fontFamilies[0],
          primaryColor: `${palette[0]}4d`,
          borderColor: palette[0],
          borderRadius,
        },
      },
      [BLOCK_TYPE_MEDIA]: {
        style: {
          ...getDefaultBlockFromType(BLOCK_TYPE_MEDIA).style,
          borderRadius,
        },
      },
      [BLOCK_TYPE_USER]: {
        style: {
          ...getDefaultBlockFromType(BLOCK_TYPE_USER).style,
          borderRadius,
        },
      },
      [BLOCK_TYPE_STEPPER]: {
        style: {
          ...getDefaultBlockFromType(BLOCK_TYPE_STEPPER).style,
          primaryColor: palette[0],
          secondaryColor: `${palette[0]}4d`,
        },
      },
      [BLOCK_TYPE_PRIMARY_CTA]: {
        style: {
          ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA).style,
          fontColor: palette[0],
          fontFamily: fontFamilies[0],
          primaryColor: palette[1],
          borderColor: palette[3],
          borderRadius,
        },
      },
      [BLOCK_TYPE_SECONDARY_CTA]: {
        style: {
          ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA).style,
          fontColor: palette[0],
          fontFamily: fontFamilies[0],
          primaryColor: palette[1],
          borderColor: palette[3],
          borderRadius,
        },
      },
      [BLOCK_TYPE_CHOICE]: {
        style: {
          ...getDefaultBlockFromType(BLOCK_TYPE_CHOICE).style,
          primaryColor: palette[0],
          fontFamily: fontFamilies[0],
          borderRadius,
        },
      },
      [BLOCK_TYPE_SLIDER]: {
        style: {
          ...getDefaultBlockFromType(BLOCK_TYPE_SLIDER).style,
          primaryColor: palette[0],
          secondaryColor: `${palette[0]}4d`,
        },
      },
      [BLOCK_TYPE_OPEN_QUESTION]: {
        style: {
          ...getDefaultBlockFromType(BLOCK_TYPE_OPEN_QUESTION).style,
          primaryColor: `${palette[2]}4d`,
        },
      },
      [BLOCK_TYPE_INTERVIEW]: {
        style: {
          ...getDefaultBlockFromType(BLOCK_TYPE_INTERVIEW).style,
          primaryColor: palette[1],
          secondaryColor: palette[1],
          fontPrimaryColor: palette[0],
          fontSecondaryColor: palette[0],
          borderRadius,
        },
      },
    },
  };

  return style;
};

export const applyThemeToEvolution = (evolution, theme) => {
  const {style} = theme;

  // delete width and height from step style to prevent overwriting
  delete style.stepStyle.width;
  delete style.stepStyle.height;

  const hotspotStyle = style?.blocksStyle?.['HOTSPOT']?.style;

  const styledEvolution = {
    ...evolution,
    style: {
      ...evolution.style,
      ...style.stepStyle,
    },
    tourSteps: evolution.tourSteps?.map((t) => {
      t.style = {
        ...t.style,
        ...style.stepStyle,
      };
      t.steps = t.steps.map((s) => {
        s.blocks = s.blocks.map((b) => {
          if (b.type !== BLOCK_TYPE_HINT) {
            return {
              ...b,
              style:
                style.blocksStyle?.[b.type]?.style ||
                getDefaultBlockFromType(b.type).style,
            };
          }
          return b;
        });
        return s;
      });
      t.boostedDotStyle = hotspotStyle || t.boostedDotStyle;
      return t;
    }),
    steps: evolution.steps?.map((s) => {
      s.blocks = s.blocks.map((b) => {
        return {
          ...b,
          style:
            style.blocksStyle?.[b.type]?.style ||
            getDefaultBlockFromType(b.type).style,
        };
      });
      s.prototypes = s.prototypes?.map((p) => {
        p.steps = p.steps.map((ss) => {
          ss.blocks = ss.blocks.map((b) => {
            return {
              ...b,
              style:
                style.blocksStyle?.[b.type]?.style ||
                getDefaultBlockFromType(b.type).style,
            };
          });
          return ss;
        });
        return p;
      });

      return s;
    }),
    boostedDotStyle: hotspotStyle || evolution.boostedDotStyle,
    themeId: theme.uid,
    theme: theme,
  };

  return styledEvolution;
};

export const merge = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], merge(target?.[key], source?.[key]));
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source);
  return target;
};

export const countDifferences = (obj1, obj2) => {
  let count = 0;

  function compareObjects(obj1, obj2) {
    const keys1 = obj1 != null ? Object.keys(obj1) : [];
    const keys2 = obj2 != null ? Object.keys(obj2) : [];
    const allKeys = [...new Set([...keys1, ...keys2])];

    allKeys.forEach((key) => {
      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        const res = compareObjects(obj1[key], obj2[key]) || 0;
        count += res;
      } else if (
        obj1[key] !== obj2[key] &&
        (obj1[key] != null || obj2[key] != null)
      ) {
        count++;
      }
    });
  }

  compareObjects(obj1, obj2);
  return count;
};
