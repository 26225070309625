import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import {Menu, MenuItem} from 'components/Menu';
import {UserAvatar} from 'components/UserAvatar';
import {PermissionsSettings} from 'constants/permissions';
import {hasPermissions} from 'helpers/permission';
import {Link} from 'react-router-dom';
import {
  ROUTE_LOGOUT,
  ROUTE_ONBOARDING_PROJECT,
  ROUTE_SETTINGS_BILLING,
  ROUTE_SETTINGS_GENERAL,
  ROUTE_SETTINGS_INSTALLATION,
  ROUTE_SETTINGS_INTEGRATIONS,
  ROUTE_SETTINGS_PLAN,
  ROUTE_SETTINGS_POKE_CONTROL,
  ROUTE_SETTINGS_PROFILE,
  ROUTE_SETTINGS_TEAM,
  ROUTE_SETTINGS_THEMES,
  ROUTE_SWITCH_PROJECT,
} from 'router/routes.const';
import './_Styles.scss';

const DropdownSwitchProjectContent = ({projects, history}) => {
  const handleClick = (projectId) => {
    history.push(ROUTE_SWITCH_PROJECT(projectId));
  };

  return (
    <Menu>
      <div className="project-lists">
        {projects
          .sort((a, b) => ('' + a.name).localeCompare(b.name))
          .map((p) => (
            <MenuItem key={p.uid} onClick={() => handleClick(p.uid)}>
              {p.name}
            </MenuItem>
          ))}
      </div>
      <Divider />
      <Link to={ROUTE_ONBOARDING_PROJECT}>
        <MenuItem icon="icon-plus">New project</MenuItem>
      </Link>
    </Menu>
  );
};

const DropdownContent = ({user, project, projects, history}) => {
  const username =
    user.username.length <= 20
      ? user.username
      : user.username.slice(0, 15).concat('...');
  const email =
    user.email.length <= 18
      ? user.email
      : user.email.slice(0, 18).concat('...');

  return (
    <Menu className="navbar-dropdown-menu">
      <Dropdown
        position="right top"
        trigger={
          <MenuItem
            className="item-switch-project"
            icon="icon-chevron-right"
            iconPosition="right">
            {project.portalLogoUrl != null ? (
              <div
                className="avatar"
                style={{
                  backgroundImage: `url(${project.portalLogoUrl})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundColor: 'unset',
                  border: '1px solid #e3e3e3',
                }}></div>
            ) : (
              <div className="avatar">{project.name[0]}</div>
            )}
            <div className="side-content">
              {project.name}
              <small>Switch project</small>
            </div>
          </MenuItem>
        }
        triggerStyle={null}
        on={['hover']}
        contentClassName="navbar-dropdown-content"
        offsetX={-4}
        contentAnimation="">
        <DropdownSwitchProjectContent projects={projects} history={history} />
      </Dropdown>
      {hasPermissions(PermissionsSettings.GENERAL_ACCESS) && (
        <Link to={ROUTE_SETTINGS_GENERAL}>
          <MenuItem>General</MenuItem>
        </Link>
      )}
      {hasPermissions(PermissionsSettings.INSTALLATION_ACCESS) && (
        <Link to={ROUTE_SETTINGS_INSTALLATION}>
          <MenuItem>Installation</MenuItem>
        </Link>
      )}
      {hasPermissions(PermissionsSettings.THEME_ACCESS) && (
        <Link to={ROUTE_SETTINGS_THEMES}>
          <MenuItem>Themes</MenuItem>
        </Link>
      )}
      {hasPermissions(PermissionsSettings.POKE_CONTROL_ACCESS) && (
        <Link to={ROUTE_SETTINGS_POKE_CONTROL}>
          <MenuItem>Rate limiting</MenuItem>
        </Link>
      )}
      {hasPermissions(PermissionsSettings.TEAM_ACCESS) && (
        <Link to={ROUTE_SETTINGS_TEAM}>
          <MenuItem>Team</MenuItem>
        </Link>
      )}
      {hasPermissions(PermissionsSettings.INTEGRATION_ACCESS) && (
        <Link to={ROUTE_SETTINGS_INTEGRATIONS}>
          <MenuItem>Integrations</MenuItem>
        </Link>
      )}
      {hasPermissions(PermissionsSettings.BILLING_ACCESS) && (
        <Link to={ROUTE_SETTINGS_PLAN}>
          <MenuItem>Plan</MenuItem>
        </Link>
      )}
      {hasPermissions(PermissionsSettings.BILLING_ACCESS) && (
        <Link to={ROUTE_SETTINGS_BILLING}>
          <MenuItem>Billing</MenuItem>
        </Link>
      )}
      <Divider />
      <Link to={ROUTE_SETTINGS_PROFILE}>
        <MenuItem className="navbar-dropdown-user-infos">
          <UserAvatar user={user} onlyAvatar />
          <div className="username">
            {username} <span className="email">{email.slice(0)}</span>
          </div>
        </MenuItem>
      </Link>
      <Divider />
      <Link to={ROUTE_LOGOUT}>
        <MenuItem className="logout-btn">Logout</MenuItem>
      </Link>
    </Menu>
  );
};

export default DropdownContent;
