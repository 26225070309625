import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import {hasFlag} from 'helpers/bitwise';
import {createContext, useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {BuilderContext} from 'scenes/Builder/context';
import {generalSelector} from 'selectors';
import {F_EXTRA_DISABLE_RUN_JAVASCRIPT_TRIGGERS} from 'services/project';
import {
  STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  STEP_CONDITION_ACTION_TYPE_DISMISS,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_OPEN_POST,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
} from 'services/steps';
import {v4 as uuidv4} from 'uuid';
import Action from '../Action';
import {TriggerContext} from '../Trigger';
import './_Styles.scss';

export const EventConditionsEditorContext = createContext();

const DropdownMenuItem = ({iconClassName, icon, title, subtitle, onClick}) => {
  return (
    <div className="dropdown-menu-item" onClick={onClick}>
      <div className={classNames('icon-wrapper', iconClassName)}>{icon}</div>
      <div className="content">
        <div className="title body-3 n-800">{title}</div>
        <div className="subtitle body-4 n-700">{subtitle}</div>
      </div>
    </div>
  );
};

export const DropdownAddAction = ({omit = [], onAddAction, ...rest}) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const [showDropdown, setShowDropdown] = useState(false);

  const allActionTypes = [
    STEP_CONDITION_ACTION_TYPE_DISMISS,
    STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
    STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
    STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
    STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
    STEP_CONDITION_ACTION_TYPE_OPEN_POST,
    STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  ];

  if (omit.length === allActionTypes.length) {
    return null;
  }

  return (
    <Dropdown
      open={showDropdown}
      className="add-action-condition"
      position="bottom left"
      repositionOnResize={true}
      offsetY={-64}
      offsetX={8}
      onOpen={() => setShowDropdown(true)}
      onClose={() => setShowDropdown(false)}
      trigger={
        <Button
          tertiary
          className="add-action-element-btn"
          onClick={() => setShowDropdown(true)}
          thin
          iconLeft="icon-plus">
          Add action
        </Button>
      }
      {...rest}>
      <div className="actions-list">
        {omit.includes(STEP_CONDITION_ACTION_TYPE_GO_TO_STEP) === false && (
          <DropdownMenuItem
            iconClassName="go-to-step"
            icon={<i className="isax isax-arrow-right" />}
            title="Go to step"
            subtitle="Navigate to another step"
            onClick={() => {
              onAddAction(STEP_CONDITION_ACTION_TYPE_GO_TO_STEP);
              setShowDropdown(false);
            }}
          />
        )}
        {omit.includes(STEP_CONDITION_ACTION_TYPE_DISMISS) === false && (
          <DropdownMenuItem
            iconClassName="dismiss"
            icon={<i className="isax isax-close-square" />}
            title="Dismiss flow"
            subtitle="Dismiss the current experience"
            onClick={() => {
              onAddAction(STEP_CONDITION_ACTION_TYPE_DISMISS);
              setShowDropdown(false);
            }}
          />
        )}
        {omit.includes(STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE) ===
          false && (
          <DropdownMenuItem
            iconClassName="launch-experience"
            icon={<i className="isax isax-routing" />}
            title="Launch experience"
            subtitle="Start another Jimo experience"
            onClick={() => {
              onAddAction(STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE);
              setShowDropdown(false);
            }}
          />
        )}
        {omit.includes(STEP_CONDITION_ACTION_TYPE_OPEN_POST) === false && (
          <DropdownMenuItem
            iconClassName="open-post"
            icon={<i className="isax isax-slider-vertical-1" />}
            title="Open Post"
            subtitle="Open a changelog post"
            onClick={() => {
              onAddAction(STEP_CONDITION_ACTION_TYPE_OPEN_POST);
              setShowDropdown(false);
            }}
          />
        )}
        {omit.includes(STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO) === false && (
          <DropdownMenuItem
            iconClassName="navigate-to"
            icon={<i className="isax isax-send-2" />}
            title="Navigate to"
            subtitle="Send user to a specific page URL"
            onClick={() => {
              onAddAction(STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO);
              setShowDropdown(false);
            }}
          />
        )}
        {omit.includes(STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE) === false &&
          hasFlag(
            F_EXTRA_DISABLE_RUN_JAVASCRIPT_TRIGGERS,
            project.extraFlags
          ) === false && (
            <DropdownMenuItem
              iconClassName="run-js-code"
              icon={<i className="isax isax-document-code" />}
              title="Run JavaScript code"
              subtitle="Execute custom JavaScript code"
              onClick={() => {
                onAddAction(STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE);
                setShowDropdown(false);
              }}
            />
          )}
        {/* {omit.includes(STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW) === false && (
          <DropdownMenuItem
            iconClassName="book-interview"
            icon={<i className="isax isax-calendar-search" />}
            title="Book Interview"
            subtitle="Book an interview"
            onClick={() => {
              onAddAction(STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW);
              setShowDropdown(false);
            }}
          />
        )} */}
      </div>
    </Dropdown>
  );
};

const TriggerActions = () => {
  const {evolution: evolutionContext, selectedStep} =
    useContext(BuilderContext);
  const {trigger, setTrigger, isExpanded} = useContext(TriggerContext);
  const {actions} = trigger;

  const steps = evolutionContext.tourSteps.map((ts) => ts.steps).flat();

  const [newlyAddedAction, setNewlyAddedAction] = useState(null);

  const onChange = (newActions) => {
    setTrigger({
      ...trigger,
      actions: newActions,
    });
  };

  const handleAddAction = (type) => {
    // handle case of adding a go to step action when step is first step and there is no step to go to
    const currentStepIndex = steps.findIndex((s) => s.uid === selectedStep.uid);
    const nextStep = steps[currentStepIndex + 1];

    const newAction = {
      uid: uuidv4(),
      type,
      ...(type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP && {
        value: nextStep == null ? 'next-step-placeholder' : null,
        step: nextStep != null ? {uid: nextStep.uid} : null,
      }),
    };

    onChange([...actions, newAction]);
    setNewlyAddedAction(newAction);
  };

  const usedActionTypes = actions.map((action) => action.type);

  const omit = [
    STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
    STEP_CONDITION_ACTION_TYPE_OPEN_POST,
  ];

  if (
    usedActionTypes.some((t) =>
      [
        STEP_CONDITION_ACTION_TYPE_DISMISS,
        STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
        STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
      ].includes(t)
    )
  ) {
    omit.push(
      STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
      STEP_CONDITION_ACTION_TYPE_DISMISS,
      STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE
    );
  }
  if (usedActionTypes.includes(STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO)) {
    omit.push(STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO);
  }
  if (usedActionTypes.includes(STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE)) {
    omit.push(STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE);
  }

  return (
    <div className="trigger-actions">
      {isExpanded !== true && actions.length === 0 && (
        <div className="empty-state-reduced-actions body-3 n-600">
          No actions
        </div>
      )}
      {actions?.map((action, index) => {
        return (
          <>
            <Action
              action={action}
              onChange={(updatedAction) => {
                onChange(
                  actions.map((_action) =>
                    _action.uid === action.uid ? updatedAction : _action
                  )
                );
              }}
              onDelete={() => {
                onChange(
                  actions.filter((_action) => _action.uid !== action.uid)
                );
              }}
              isExpanded={isExpanded}
              defaultOpen={newlyAddedAction?.uid === action.uid}
            />
            {isExpanded !== true && index < actions.length - 1 && <Divider />}
          </>
        );
      })}
      {isExpanded && (
        <div className="logic-actions">
          <DropdownAddAction omit={omit} onAddAction={handleAddAction} />
        </div>
      )}
    </div>
  );
};

export default TriggerActions;
