import classnames from 'classnames';
import Card from 'components/Card';
import React from 'react';
import './_Styles.scss';

export default (props) => {
  const {paymentMethodLast4, paymentMethodBrand, action, dark, showTitle} =
    props;
  const classNames = classnames('card-payment-method', {
    'is-dark': dark === true,
  });
  const brandName =
    paymentMethodBrand === 'visa'
      ? 'Visa'
      : paymentMethodBrand === 'mastercard'
      ? 'MasterCard'
      : paymentMethodBrand === 'amex'
      ? 'American Express'
      : 'Card';

  return (
    <Card className={classNames}>
      <div className={`brand-wrapper ${paymentMethodBrand}`}></div>
      <div className="last4-wrapper">
        {showTitle === true && (
          <div className="title">This account is billed to</div>
        )}
        <strong>
          {brandName} ending by {paymentMethodLast4}
        </strong>
      </div>
      <div className="actions-wrapper">{action}</div>
    </Card>
  );
};
