import {dataActions, generalActions} from 'actions';
import Loader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import {Environment} from 'conf/env';
import {posthog} from 'posthog-js';
import queryString from 'query-string';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {ROUTE_ONBOARDING_PROJECT, ROUTE_TOURS} from 'router/routes.const';
import {authService, meService, rolesService, segmentService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

class LoginWithCode extends React.Component {
  logger = new Swaler('LoginWithCode');

  signInWithCode = async () => {
    const qs = queryString.parse(this.props.location.search);

    if (!qs.code) {
      return this.props.history.push(ROUTE_TOURS);
    }
    try {
      const result = await authService.signInWithLoginCode(qs.code);

      return this.triggerSignIn(result);
    } catch (err) {
      toastDanger([
        'Wups...',
        'Something went wrong, please contact our support!',
      ]);
    }
  };
  triggerSignIn = async (signInResult) => {
    const {user} = signInResult;
    const {
      setUser,
      setProject,
      setProjects,
      history,
      setCustomRoles,
      setSegments,
      setCustomAttributes,
      setBuiltInRoles,
    } = this.props;
    const qs = queryString.parse(this.props.location.search);

    try {
      const projects = await meService.getMyProjects();

      if (window.jimo) {
        window.jimo.push(['set', 'user:email', [user.email]]);
        window.jimo.push(['set', 'user:id', [user.uid]]);
      }
      if (Environment.NODE_ENV === 'production') {
        posthog.identify(user.uid);
      }
      if (projects.length === 0) {
        setUser(user);
        return history.push(ROUTE_ONBOARDING_PROJECT);
      }

      const project = projects.find(
        (p) =>
          p.uid === (qs.goToProject != null ? qs.goToProject : projects[0].uid)
      );
      const segments = await segmentService.getSegments({
        projectId: project.uid,
      });
      const customAttributes = await segmentService.getSegmentCustomAttributes(
        project.uid
      );
      const customRoles = await rolesService.getCustomRoles(project);
      const builtInRoles = await rolesService.getBuiltInRoles();

      setSegments(segments);
      setCustomAttributes(customAttributes);
      setCustomRoles(customRoles);
      setBuiltInRoles(builtInRoles);
      setProjects(projects);
      setProject(qs.goToProject != null ? qs.goToProject : projects[0].uid);
      setUser(user);
      history.push(ROUTE_TOURS);
    } catch (err) {
      this.logger.error(
        `Could not fetch my projects, failed with error ${err.message}`,
        err
      );
      return;
    }
  };

  render() {
    return (
      <div className="s-login-with-code">
        <Loader></Loader>
      </div>
    );
  }

  async componentDidMount() {
    await this.signInWithCode();
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(generalActions.setUser(user)),
  setProject: (project) => dispatch(generalActions.setProject(project)),
  setProjects: (projects) => dispatch(generalActions.setProjects(projects)),
  setSegments: (segments) => dispatch(dataActions.setSegments(segments)),
  setCustomAttributes: (customAttributes) =>
    dispatch(dataActions.setCustomAttributes(customAttributes)),
  setCustomRoles: (roles) => dispatch(dataActions.setCustomRoles(roles)),
  setBuiltInRoles: (roles) => dispatch(dataActions.setBuiltInRoles(roles)),
});

export default withRouter(connect(null, mapDispatchToProps)(LoginWithCode));
