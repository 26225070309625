import classnames from 'classnames';
import DefaultLoader from 'components/Loader';
import RadioGroup from 'components/RadioGroup';
import {hasFlag} from 'helpers/bitwise';
import {useFetchSubscriptionPlansAndAddons} from 'hooks/useFetchSubscriptionPlansAndAddons';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {dataSelector, generalSelector} from 'selectors';
import {isEligibleToTrial, isPaying} from 'services/project';
import {
  F_MODE_ANNUAL_BILLING,
  PLAN_BASIC_ID,
  PLAN_GROWTH_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import {Swaler} from 'swaler';
import './_styles.scss';
import {BannerTrial} from './components/BannerTrial';
import {ItemPlan} from './components/ItemPlan';

const logger = new Swaler('SettingsPlan');

export default function SettingsPlan() {
  useFetchSubscriptionPlansAndAddons();

  const plans = useSelector((state) => dataSelector.getSubscriptionsPlans());
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const [modeAnnualBilling, setModeAnnualBilling] = useState(
    isPaying() === false
      ? true
      : hasFlag(F_MODE_ANNUAL_BILLING, subscription.extraFlags)
  );
  const preventPeriodChanges =
    isPaying() &&
    hasFlag(F_MODE_ANNUAL_BILLING, subscription.extraFlags) !==
      modeAnnualBilling;
  const canStartTrial =
    isEligibleToTrial(PLAN_STARTUP_ID) === true &&
    isEligibleToTrial(PLAN_GROWTH_ID) === true;

  return (
    <SettingsBody
      className={classnames('s-settings-plan', {'extra-p-b': canStartTrial})}>
      <div className="settings-header">
        <div className="title-3">Plan</div>
        <a className="subtitle-3" href="https://usejimo.com/pricing">
          <span>Compare Plans</span> <i className="isax isax-export-3"></i>
        </a>
      </div>
      {canStartTrial && <BannerTrial />}
      <div className="plan-billing-mode">
        <RadioGroup
          className="element-billing-period"
          options={[
            {
              label: <div className="body-3">Pay Monthly</div>,
              value: 'monthly',
            },
            {
              label: (
                <div className="body-3">
                  Pay Yearly <span className="body-4">Save 16%</span>
                </div>
              ),
              value: 'yearly',
            },
          ]}
          value={modeAnnualBilling === true ? 'yearly' : 'monthly'}
          onSelect={(value) => {
            if (value === 'monthly') {
              setModeAnnualBilling(false);
            } else {
              setModeAnnualBilling(true);
            }
          }}
        />
      </div>
      <div className={classnames('plan-list', {loading: plans.length === 0})}>
        {plans.length === 0 ? (
          <DefaultLoader width="24px" />
        ) : (
          plans
            .filter((p) => p.uid !== PLAN_BASIC_ID)
            .map((plan) => (
              <ItemPlan
                plan={plan}
                modeAnnualBilling={modeAnnualBilling}
                preventPeriodChanges={preventPeriodChanges}
              />
            ))
        )}
      </div>
    </SettingsBody>
  );
}
