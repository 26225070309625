import {stepsService} from 'services';
import {
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_TEXT_BLOCK,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import {v4 as uuidv4} from 'uuid';

export const bannerTemplate = (customization) => ({
  steps: [
    {
      uid: uuidv4(),
      question: "We've just launched [Product] to [Outcome] 🎉",
      additionalInformation: '',
      type: STEP_TYPE_TEXT_BLOCK,
      ctaLabel: 'Take a look',
      ctaUrl: 'https://www.usejimo.com',
      ctaBackgroundColor: customization?.ctaBackgroundColor || '#1260eb',
      ctaBorderRadius: customization?.ctaBorderRadius || 34,
      ctaColor: customization?.ctaColor || '#ffffff',
    },
  ],
});

export const defaultTemplate = (customization) => ({
  steps: [
    {
      uid: uuidv4(),
      question: 'How satisfied are you with your experience with [Product] ?',
      additionalInformation: 'This will help us improve your experience',
      type: STEP_TYPE_NPS,
    },
    stepsService.createStepObj(STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT, {
      question: 'Any problems accessing/setting up [Feature]?',
      options: [
        {
          content: 'Yes',
        },
        {
          content: 'No',
        },
      ],
    }),
    stepsService.createStepObj(STEP_TYPE_TEXT_LONG, {
      question: 'What was the issue?',
    }),
    {
      uid: uuidv4(),
      question: "We've just released [<new feature>] 🎉",
      mdContent:
        '<p>This means you can:</p>\n<ul>\n<li>Value prop #1</li>\n<li>Value prop #2</li>\n</ul>\n',
      mdRawContent:
        '{"blocks":[{"key":"e99p0","text":"This means you can:","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"8t89j","text":"Value prop #1","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3frpi","text":"Value prop #2","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
      type: STEP_TYPE_TEXT_BLOCK,
      ctaLabel: 'Take a look',
      ctaUrl: 'https://www.usejimo.com',
      ctaBackgroundColor: customization?.ctaBackgroundColor || '#1260eb',
      ctaBorderRadius: customization?.ctaBorderRadius || 34,
      ctaColor: customization?.ctaColor || '#ffffff',
    },
  ],
});
