import classnames from 'classnames';
import React from 'react';
import './_Styles.scss';

export default class Card extends React.Component {
  _getClassName = () => {
    const {muted} = this.props;

    return classnames('preview-card', this.props.className, {
      muted: muted === true,
    });
  };
  render() {
    return (
      <div {...this.props} className={this._getClassName()}>
        {this.props.children}
      </div>
    );
  }
}
