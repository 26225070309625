import {useContext, useEffect, useState} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {Swaler} from 'swaler';
import BlockList from './components/BlockList';
import EditStep from './components/EditStep';
import EvolutionEditorLanguage from './components/EvolutionEditorLanguage';
import EvolutionTagSelect from './components/EvolutionTagSelect';
import Preview from './components/Preview';
import './_styles.scss';

const logger = new Swaler('BuilderCreate');

export const BuilderCreate = (props) => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedStepId, setSelectedStepId] = useState(null);

  const {evolution, setEvolution, stepErrors, setStepErrors} =
    useContext(BuilderContext);

  useEffect(() => {
    if (selectedStepId != null) {
      if (stepErrors[selectedStepId] == null) {
        setStepErrors({...stepErrors, [selectedStepId]: false});
      } else {
        setStepErrors({...stepErrors, [selectedStepId]: true});
      }
    }
  }, [selectedStepId]);
  useEffect(() => {
    if (selectedStepId == null && evolution.steps.length !== 0) {
      setSelectedStepId(evolution.steps[0].uid);
    }
  }, [evolution]);

  const handleAddLanguage = (languageCode) => {
    setEvolution({
      ...evolution,
      texts: (evolution.texts || []).concat({
        language: languageCode,
        title: '',
        content: '',
        rawContent: '',
      }),
    });
  };
  const handleRemoveLanguage = (languageCode) =>
    setEvolution({
      ...evolution,
      steps: evolution.steps.map((s) => ({
        ...s,
        texts: s.texts.filter((t) => t.language !== languageCode),
        prototypes: s.prototypes.map((p) => ({
          ...p,
          steps: p.steps.map((ps) => ({
            ...ps,
            texts: ps.texts.filter((pst) => pst.language !== languageCode),
          })),
        })),
      })),
    });
  const handleStepUpdate = (stepToUpdate) => {
    setEvolution((prevState) => ({
      ...prevState,
      steps: prevState.steps.map((s) => {
        if (s.uid === stepToUpdate.uid) {
          return {...s, ...stepToUpdate};
        } else {
          return {
            ...s,
            prototypes: s.prototypes.map((p) => ({
              ...p,
              steps: p.steps.map((ps) =>
                ps.uid === stepToUpdate.uid ? {...ps, ...stepToUpdate} : ps
              ),
            })),
          };
        }
      }),
    }));
  };

  const selectedStep =
    evolution.steps.find((e) => e.uid === selectedStepId) ??
    evolution.steps.reduce((stepToFind, step) => {
      if (step.prototypes.length < 0) {
        return stepToFind;
      }
      step.prototypes.forEach((p) =>
        p.steps.forEach((ps) =>
          ps.uid === selectedStepId ? (stepToFind = ps) : null
        )
      );
      return stepToFind;
    }, null);

  return (
    <div className="s-builder-create fade-in">
      <div className="editor-tag-language-wrapper">
        <EvolutionTagSelect
          selectedTags={evolution.tags}
          handleTagsChange={(tags) => setEvolution({...evolution, tags})}
        />
        <EvolutionEditorLanguage
          evolution={evolution}
          onSelectLanguage={(code) => setSelectedLanguage(code)}
          onAddLanguage={handleAddLanguage}
          onRemoveLanguage={handleRemoveLanguage}
        />
      </div>
      <div className="main-wrapper">
        <div className="steps-wrapper">
          <BlockList
            evolution={evolution}
            updateSteps={(steps) =>
              setEvolution((prevState) => ({
                ...prevState,
                steps,
              }))
            }
            selectedStepId={selectedStepId}
            setSelectedStepId={setSelectedStepId}
          />
        </div>
        <div className="step-content-wrapper">
          {evolution.steps.length === 0 || selectedStep == null ? (
            <></>
          ) : (
            <EditStep
              key={selectedStep.uid}
              steps={evolution.steps}
              step={selectedStep}
              selectedLanguage={selectedLanguage}
              updateStep={handleStepUpdate}
              evolution={evolution}
              updateEvolutionOptions={(options) =>
                setEvolution({...evolution, optionsFlags: options})
              }
              showErrors={stepErrors[selectedStepId]}
              disabled={evolution.isDraft === false}
            />
          )}
        </div>
        <div className="preview-wrapper">
          {selectedStep != null && (
            <Preview
              selectedStep={selectedStep}
              selectedLanguage={selectedLanguage}
              evolution={evolution}
            />
          )}
        </div>
      </div>
    </div>
  );
};
