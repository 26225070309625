import {Movie} from '@styled-icons/material-outlined';
import {ELEMENT_MEDIA_EMBED, insertMediaEmbed} from '@udecode/plate';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import InputGroup from 'components/Input';
import {useMyPlateEditorRef} from 'components/MarkdownEditor/utils';
import {any, func, string} from 'prop-types';
import {useState} from 'react';
import MarkToolbarButton from '../MarkToolbarButton';
import './_Styles.scss';

const propTypes = {
  type: string,
  fileUrl: string,
  altText: string,
  sizing: string,
  file: any,
  onChange: func,
};

const defaultProps = {
  type: null,
  fileUrl: null,
  altText: null,
  sizing: null,
  file: null,
  onChange: () => {},
};

const MediaToolbarButton = () => {
  const editor = useMyPlateEditorRef();

  const [url, setUrl] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Dropdown
      open={dropdownOpen}
      onOpen={() => setDropdownOpen(true)}
      onClose={() => setDropdownOpen(false)}
      trigger={
        <MarkToolbarButton nodeType={ELEMENT_MEDIA_EMBED}>
          {<Movie />}
        </MarkToolbarButton>
      }>
      <div className="media-embed-wrapper">
        <div className="media-embed">
          <div className="section-item">
            <InputGroup
              value={url}
              className="link-input"
              labelTextLeft={<i className="icon-link" />}
              placeholder="youtube, loom or vimeo"
              onChange={({target}) => setUrl(target.value)}
              onKeyPress={({key}) => {
                if (key === 'Enter') {
                  insertMediaEmbed(editor, {url});
                  setDropdownOpen(false);
                }
              }}
            />
          </div>
        </div>
        <Divider />
        <div className="actions">
          <Button
            className="btn-cancel"
            onClick={() => {
              setUrl('');
              setDropdownOpen(false);
            }}>
            Cancel
          </Button>
          <Button
            primary
            className="btn-add"
            onClick={() => {
              insertMediaEmbed(editor, {url});
              setDropdownOpen(false);
            }}>
            Add
          </Button>
        </div>
      </div>
    </Dropdown>
  );
};

MediaToolbarButton.propTypes = propTypes;
MediaToolbarButton.defaultProps = defaultProps;

export default MediaToolbarButton;
