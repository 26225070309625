import {createAlignPlugin} from '@udecode/plate-alignment';
import {
  createBoldPlugin,
  createItalicPlugin,
  createStrikethroughPlugin,
  createUnderlinePlugin,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
} from '@udecode/plate-basic-marks';
import {
  createPlugins,
  Plate,
  PlateContent,
  PlateLeaf,
} from '@udecode/plate-common';
import {createEmojiPlugin} from '@udecode/plate-emoji';
import {createHeadingPlugin} from '@udecode/plate-heading';
import {createJuicePlugin} from '@udecode/plate-juice';
import {createLinkPlugin, ELEMENT_LINK} from '@udecode/plate-link';
import {createImagePlugin, createMediaEmbedPlugin} from '@udecode/plate-media';
import {
  createParagraphPlugin,
  ELEMENT_PARAGRAPH,
} from '@udecode/plate-paragraph';
import {createDeserializeCsvPlugin} from '@udecode/plate-serializer-csv';
import {createDeserializeDocxPlugin} from '@udecode/plate-serializer-docx';
import {createDeserializeMdPlugin} from '@udecode/plate-serializer-md';

import FixedToolbar from '../elements/BasicEditorToolbar';
import {
  createVariablePlugin,
  ELEMENT_VARIABLE,
} from '../plugins/VariablePlugin';

import {SlateTransformer} from '@accordproject/markdown-slate';
import {createSingleLinePlugin} from '@udecode/plate-break';
import {Element, Text} from 'domhandler';
import {useEffect, useRef, useState} from 'react';
import {payloadSlateToDomConfig, slateToHtml} from 'slate-serializers';
import VariableConfigModal from '../components/VariableConfigModal';
import {LinkElement} from '../elements/LinkElement';
import {LinkFloatingToolbar} from '../elements/LinkFloatingToolbar';
import VariableElement from '../elements/VariableElement';
import {MarkdownEditorContext} from '../utils';
import './_Styles.scss';

const BoldLeaf = (props) => <PlateLeaf as="strong" {...props} />;
const ItalicLeaf = (props) => <PlateLeaf as="em" {...props} />;
const StrikethroughLeaf = (props) => <PlateLeaf as="s" {...props} />;
const UnderlineLeaf = (props) => <PlateLeaf as="u" {...props} />;

const config = {
  ...payloadSlateToDomConfig,
  elementTransforms: {
    ...payloadSlateToDomConfig.elementTransforms,
    a: ({node, children = []}) => {
      const attrs = {};
      if (node.linkType) {
        attrs['data-link-type'] = node.linkType;
      }
      if (node.newTab) {
        attrs.target = '_blank';
      }
      return new Element(
        'a',
        {
          href: node.url,
          ...attrs,
        },
        children
      );
    },
    variable: ({node, children = []}) => {
      // Ensure node.variable is a string
      const variableText =
        typeof node.attribute === 'string' ? node.attribute || '' : '';

      const variableElement = new Element(
        'variable',
        {
          class: 'variable',
          'data-fallback': node.fallbackValue || '',
          'data-attribute': node.attribute || '',
          'data-source': node.attributeSource,
        },
        [new Text(variableText)]
      );

      // Handle formatting
      let formattedElement = variableElement;
      if (node.children && node.children[0]) {
        if (node.children[0].bold) {
          formattedElement = new Element('strong', {}, [formattedElement]);
        }
        if (node.children[0].italic) {
          formattedElement = new Element('i', {}, [formattedElement]);
        }
        if (node.children[0].underline) {
          formattedElement = new Element('u', {}, [formattedElement]);
        }
        if (node.children[0].strikethrough) {
          formattedElement = new Element('s', {}, [formattedElement]);
        }
      }

      return formattedElement;
    },
  },
};

const BasicEditor = ({
  value,
  rawValue,
  onChange,
  disabled,
  placeholder,
  onFocus = () => {},
  onBlur = () => {},
  isSingleLine = false,
}) => {
  const plugins = createPlugins(
    [
      createParagraphPlugin(),
      createHeadingPlugin(),
      createLinkPlugin({
        renderAfterEditable: LinkFloatingToolbar,
      }),
      createImagePlugin(),
      createMediaEmbedPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createAlignPlugin({
        inject: {
          props: {
            validTypes: [ELEMENT_PARAGRAPH],
          },
        },
      }),
      createEmojiPlugin(),
      createDeserializeDocxPlugin(),
      createDeserializeCsvPlugin(),
      createDeserializeMdPlugin(),
      createJuicePlugin(),
      createVariablePlugin(),
      ...(isSingleLine === true ? [createSingleLinePlugin()] : []),
    ],
    {
      components: {
        [ELEMENT_LINK]: LinkElement,
        [MARK_BOLD]: BoldLeaf,
        [MARK_ITALIC]: ItalicLeaf,
        [MARK_STRIKETHROUGH]: StrikethroughLeaf,
        [MARK_UNDERLINE]: UnderlineLeaf,
        [ELEMENT_VARIABLE]: VariableElement,
      },
    }
  );

  const [currentVariable, setCurrentVariable] = useState(null);
  const [newVariable, setNewVariable] = useState(null);

  const previousValueRef = useRef(rawValue);

  useEffect(() => {
    if (rawValue == null || Array.isArray(rawValue) === false) {
      return;
    }

    if (previousValueRef.current == null) {
      previousValueRef.current = rawValue;
      return;
    }

    const previousVariables = previousValueRef.current?.[0]?.children
      ?.filter((node) => node.type === 'variable')
      .map((node) => node.uid);

    const newVariable = rawValue[0].children?.find(
      (node) =>
        node.type === 'variable' && !previousVariables.includes(node.uid)
    );

    if (newVariable) {
      setNewVariable(newVariable);
    }

    previousValueRef.current = rawValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawValue]);

  useEffect(() => {
    if (currentVariable != null) {
      onFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVariable]);

  const handleChange = (nextValue) => {
    // serialize slate state to an html string
    const html = slateToHtml(nextValue, config);
    onChange({rawValue: nextValue, value: html});
  };

  let editorValue;

  if (rawValue != null) {
    editorValue = rawValue;
  } else {
    const slateTransformer = new SlateTransformer();
    const slateValue = slateTransformer.fromMarkdown(value);
    editorValue = slateValue.document.children;
  }

  return (
    <MarkdownEditorContext.Provider
      value={{
        currentVariable,
        setCurrentVariable,
        newVariable,
        setNewVariable,
      }}>
      <div className="basic-editor">
        <Plate plugins={plugins} value={editorValue} onChange={handleChange}>
          <FixedToolbar />
          <VariableConfigModal
            trigger={
              <PlateContent
                className="editor-wrapper"
                readOnly={disabled}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            }
          />
        </Plate>
      </div>
    </MarkdownEditorContext.Provider>
  );
};

export default BasicEditor;
