import {AVAILABLE_LANGUAGES} from 'scenes/Builder/scenes/Create/components/EvolutionEditorLanguage';

export const findLanguages = (evolution) => {
  let languages = [];

  if (evolution != null) {
    if (evolution.tourSteps?.length > 0) {
      evolution.tourSteps.forEach((cur) => {
        cur.steps?.forEach((s) =>
          s.blocks?.forEach((b) => {
            b.translations?.forEach((t) =>
              languages.indexOf(t.language) < 0
                ? (languages = languages.concat(t.language))
                : null
            );
            b.options?.forEach((o) =>
              o.labels?.forEach((ol) =>
                languages.indexOf(ol.language) < 0
                  ? (languages = languages.concat(ol.language))
                  : null
              )
            );
          })
        );
      });
    } else {
      evolution.steps.forEach((cur) => {
        cur.blocks?.forEach((b) => {
          b.translations?.forEach((t) =>
            languages.indexOf(t.language) < 0
              ? (languages = languages.concat(t.language))
              : null
          );
          b.options?.forEach((o) =>
            o.labels?.forEach((ol) =>
              languages.indexOf(ol.language) < 0
                ? (languages = languages.concat(ol.language))
                : null
            )
          );
        });
        cur.prototypes.forEach((p) =>
          p.steps?.forEach((ps) =>
            ps.blocks?.forEach((pb) => {
              pb.translations?.forEach((pst) =>
                languages.indexOf(pst.language) < 0
                  ? (languages = languages.concat(pst.language))
                  : null
              );
              pb.options?.forEach((o) =>
                o.labels?.forEach((ol) =>
                  languages.indexOf(ol.language) < 0
                    ? (languages = languages.concat(ol.language))
                    : null
                )
              );
            })
          )
        );
      });
    }
  }

  return languages
    .filter((n) => n)
    .map((languageCode) =>
      AVAILABLE_LANGUAGES.find((l) => l.code === languageCode)
    );
};
