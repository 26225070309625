import ProgressBar from '@ramonak/react-progress-bar';
import classnames from 'classnames';
import Button from 'components/Button';
import CustomSlider from 'components/CustomSlider';
import {MarkdownContent} from 'components/Markdown';
import Nps from 'components/Nps';
import {TagButton} from 'components/TagButton';
import TextArea from 'components/TextArea';
import {hasFlag} from 'helpers/bitwise';
import {array, string} from 'prop-types';
import {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {
  F_OPTION_COMMENTS_ENABLED,
  F_OPTION_LIKE_ENABLED,
} from 'services/evolution';
import {
  STEP_TYPE_CONCEPT_TEST,
  STEP_TYPE_INTERVIEW,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_BLOCK,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import {EmojiIdk, EmojiNah, EmojiYeah} from 'shared/front/components/Emojis';
import PreviewCard from '../PreviewCard';
import './_Styles.scss';

export const DEFAULT_TITLE = 'Title of your ideation';
export const DEFAULT_DESCRIPTION =
  'Write a short but clear description about your ideation!';
export const DEFAULT_QUESTION = 'Question of your step ?';
export const DEFAULT_ADDITIONAL_INFORMATION =
  'Any additional information relevant for the question you are asking';

const propTypes = {
  title: string,
  content: string,
  tags: array,
  steps: array,
  selectedStep: string,
  selectedLanguage: string,
};

const defaultProps = {
  title: '',
  content: '',
  tags: [],
  steps: [],
  selectedStep: null,
  selectedLanguage: null,
};

const Preview = ({selectedStep, selectedLanguage, evolution}) => {
  const {
    tags,
    steps,
    typeformFormId,
    ctaLabel: ctaLabelEvolution,
    ctaColor,
    ctaBackgroundColor,
    ctaBorderRadius,
  } = evolution;

  const {setShowModalCta} = useContext(BuilderContext);

  const title =
    selectedStep.texts?.find((t) => t.language === selectedLanguage)
      ?.question ||
    selectedStep.question ||
    DEFAULT_QUESTION;
  const content =
    selectedStep.texts?.find((t) => t.language === selectedLanguage)
      ?.additionalInformation ||
    selectedStep.additionalInformation ||
    DEFAULT_ADDITIONAL_INFORMATION;
  const mdContent =
    selectedStep?.texts?.find((t) => t.language === selectedLanguage)
      ?.mdContent || selectedStep?.mdContent;
  const ctaLabel =
    selectedStep?.texts?.find((t) => t.language === selectedLanguage)
      ?.ctaLabel || selectedStep?.ctaLabel;

  const hasQuestion = [
    STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    STEP_TYPE_NPS,
    STEP_TYPE_SLIDER,
    STEP_TYPE_OPINION_SCALE,
    STEP_TYPE_TEXT_BLOCK,
    STEP_TYPE_TEXT_LONG,
    STEP_TYPE_CONCEPT_TEST,
    STEP_TYPE_INTERVIEW,
  ].includes(selectedStep?.type);
  const hasAdditionalInformation = [
    STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    STEP_TYPE_NPS,
    STEP_TYPE_SLIDER,
    STEP_TYPE_OPINION_SCALE,
    STEP_TYPE_TEXT_LONG,
    STEP_TYPE_CONCEPT_TEST,
    STEP_TYPE_INTERVIEW,
  ].includes(selectedStep?.type);

  const selectedStepIndex = steps.indexOf(selectedStep);

  let actionContent = null;

  if (typeformFormId != null) {
    actionContent = (
      <div className="cta-wrapper">
        <Button
          style={{
            color: ctaColor,
            backgroundColor: ctaBackgroundColor,
            borderRadius: `${ctaBorderRadius}px`,
          }}>
          {ctaLabelEvolution}
        </Button>
      </div>
    );
  }

  if (
    [
      STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
      STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    ].includes(selectedStep?.type)
  ) {
    actionContent = (
      <div className="select-wrapper">
        {selectedStep.options?.map((e, index) => {
          const content =
            e.labels.find((l) => l.language === selectedLanguage)?.content ||
            e.content;
          return (
            <div>
              <Button
                className={classnames(
                  selectedStep?.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
                    ? 'checkbox-btn'
                    : 'select-btn',
                  {
                    selected: index === 0,
                  }
                )}
                rounded={false}
                onClick={(e) => e.preventDefault()}>
                <input type="radio" name="radio" checked={index === 0} />
                <span className="checkmark" />
                <span className="content">{content}</span>
              </Button>
            </div>
          );
        })}
      </div>
    );
  }

  if (selectedStep?.type === STEP_TYPE_TEXT_LONG) {
    actionContent = (
      <div className="input-wrapper">
        <TextArea
          className="text-long-textarea"
          style={{resize: 'none'}}
          placeholder="Tap your answer here"
        />
      </div>
    );
  }

  if (selectedStep?.type === STEP_TYPE_OPINION_SCALE) {
    actionContent = (
      <>
        <div className="opinion-wrapper">
          <div className="opinion-scale">
            <EmojiYeah
              width={48}
              animated
              animateOnHover
              tooltip=""
              tooltipProps={{on: ['hover', 'focus']}}
            />
            <EmojiIdk
              animated
              animateOnHover
              tooltip=""
              tooltipProps={{on: ['hover', 'focus']}}
            />
            <EmojiNah
              animated
              animateOnHover
              tooltip=""
              tooltipProps={{on: ['hover', 'focus']}}
            />
          </div>
        </div>
      </>
    );
  }

  if (selectedStep?.type === STEP_TYPE_SLIDER) {
    actionContent = (
      <>
        <div className="slider-wrapper">
          <CustomSlider />
        </div>
      </>
    );
  }

  if (selectedStep?.type === STEP_TYPE_NPS) {
    actionContent = (
      <>
        <div className="nps-wrapper">
          <Nps />
        </div>
      </>
    );
  }

  if (selectedStep?.type === STEP_TYPE_TEXT_BLOCK) {
    const hasLikeEnabled = hasFlag(
      F_OPTION_LIKE_ENABLED,
      evolution.optionsFlags
    );
    const hasCommentEnabled = hasFlag(
      F_OPTION_COMMENTS_ENABLED,
      evolution.optionsFlags
    );

    actionContent = (
      <>
        <div className="text-block-wrapper">
          <MarkdownContent content={mdContent}></MarkdownContent>
        </div>
        {(hasLikeEnabled === true || hasCommentEnabled === true) && (
          <div className="btns-wrapper">
            {hasLikeEnabled === true && (
              <Button iconLeft="icon-heart-o" thin>
                42 likes
              </Button>
            )}
            {hasCommentEnabled === true && (
              <Button iconLeft="icon-comment-o" thin>
                24 comments
              </Button>
            )}
          </div>
        )}
      </>
    );
  }

  if (selectedStep?.type === STEP_TYPE_CONCEPT_TEST) {
    actionContent = (
      <div className="concept-test-wrapper">
        <div
          className="concept-test-thumbnail"
          style={
            selectedStep.conceptThumbnailUrl != null &&
            (selectedStep.conceptThumbnailFileId != null ||
              selectedStep.thumbnails.length > 0)
              ? {backgroundImage: `url(${selectedStep.conceptThumbnailUrl})`}
              : {}
          }></div>
        <div className="concept-test-overlay"></div>
        <div className="btn-play">
          <i className="icon-play"></i>
        </div>
      </div>
    );
  }

  if (selectedStep?.type === STEP_TYPE_INTERVIEW) {
    actionContent = (
      <div className="interview-wrapper">
        <Button className="not-now-btn">Not now</Button>
        <Button primary className="book-slot-btn">
          Book a slot
        </Button>
      </div>
    );
  }

  return (
    <div className="builder-create-preview-wrapper">
      <PreviewCard
        className={classnames('builder-create-preview', {
          'type-text-block': selectedStep.type === STEP_TYPE_TEXT_BLOCK,
        })}>
        <div className="scrollable-wrapper">
          {selectedStepIndex > 1 && (
            <ProgressBar
              completed={Math.ceil(
                ((selectedStepIndex + 1) / steps.length) * 100
              )}
              className="progress-wrapper"
              barContainerClassName="progress-container"
              bgColor="#208DFF"
              isLabelVisible={false}
            />
          )}
          {tags.length > 0 && (
            <div className="tags-wrapper">
              {tags
                .filter((t) => t)
                .map((t) => (
                  <TagButton tag={t} interactive={false} />
                ))}
            </div>
          )}
          <div className="infos">
            {hasQuestion && <div className="title">{title}</div>}
            {hasAdditionalInformation && (
              <div className="description">
                <MarkdownContent content={content} />
              </div>
            )}
          </div>
          {actionContent}
        </div>
        {(selectedStep.ctaAction ||
          selectedStep.ctaUrl != null ||
          selectedStepIndex + 1 !== steps.length) &&
          selectedStep.type !== STEP_TYPE_INTERVIEW && (
            <div className="actions-wrapper">
              {selectedStep.ctaUrl != null && (
                <Button
                  className="cta-btn"
                  style={{
                    backgroundColor: selectedStep.ctaBackgroundColor,
                    color: selectedStep.ctaColor,
                    borderRadius: `${selectedStep.ctaBorderRadius}px`,
                  }}>
                  {ctaLabel}
                </Button>
              )}
              {selectedStepIndex + 1 !== steps.length && (
                <Button primary>Next</Button>
              )}
            </div>
          )}
      </PreviewCard>
    </div>
  );
};

Preview.propTypes = propTypes;
Preview.defaultProps = defaultProps;

export default Preview;
