import {generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import ColorPicker from 'components/ColorPicker';
import Input from 'components/Input';
import RadioGroup from 'components/RadioGroup';
import SelectGroup from 'components/Select';
import {addFlag, hasFlags} from 'helpers/bitwise';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {components} from 'react-select';
import {
  ROUTE_ONBOARDING_DETAILS_STEP_2_2,
  ROUTE_ONBOARDING_DETAILS_STEP_3_1,
} from 'router/routes.const';
import {
  HOTSPOT_ANIMATION_PULSE,
  HOTSPOT_SHAPE_DEFAULT,
} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Hotspot';
import {getDefaultChoiceOption} from 'scenes/PokeBuilder/components/BlockEditor/blocks/MultipleChoice';
import {getDefaultBlockFromType} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {defaultTheme} from 'scenes/Settings/scenes/Changelog/Pokes';
import PreviewWidget from 'scenes/Settings/scenes/Changelog/components/PreviewWidget';
import {IframeMessenger} from 'scenes/Settings/scenes/Changelog/helpers/iframe-messenger';
import ThemePreview from 'scenes/Settings/scenes/Themes/components/ThemePreview';
import {
  getPreviewPoke,
  stylePreviewPoke,
} from 'scenes/Settings/scenes/Themes/utils';
import {generalSelector} from 'selectors';
import {meService, projectService, themeService} from 'services';
import {defaultCustomization} from 'services/evolution';
import {ME_F_ONBOARDING_DONE_STEP_2_3} from 'services/me';
import {
  F_USAGE_V2_IN_APP_ANNOUNCEMENT,
  F_USAGE_V2_IN_APP_SURVEY,
  F_USAGE_V2_PORTAL_NEWS_FEED,
  F_USAGE_V2_PRODUCT_TOUR,
  F_USAGE_V2_PUBLIC_ROADMAP,
  F_USAGE_V2_TOOLTIPS,
  F_USAGE_V2_UP_VOTE,
  F_USAGE_V2_WIDGET_NEWS_FEED,
  defaultChangelogStyle,
} from 'services/project';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_INTERVIEW,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_STEPPER,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
  STEP_TYPE_TEXT_BLOCK,
  getDefaultStep,
} from 'services/steps';
import {CHOICE_DISPOSITION_BLOCK} from 'shared/front/components/Poke/components/BlockChoice';
import {
  BLOCK_CHOICE,
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
} from 'shared/front/components/Poke/constants/blocks';
import {
  F_SLOT_DOT,
  F_SLOT_POP_IN,
  F_SLOT_SNIPPET,
  F_SLOT_TOOLTIP,
} from 'shared/front/components/Poke/constants/poke';
import {v4 as uuidv4} from 'uuid';
import Survey from '../../../Builder/scenes/Edit/components/PreviewSurvey/index';
import './step-2-3.scss';

const TAB_IN_APP = 'tab-in-app';
const TAB_WIDGET = 'tab-widget';
const TAB_PORTAL = 'tab-portal';

const {Option, Control: ControlComponent} = components;
const IconOption = (props) => {
  return (
    <Option className="select-icon-option" {...props}>
      <div className="icon-wrapper">{props.data.icon}</div>
      {props.data.label}
    </Option>
  );
};
export const Control = (props) => {
  const {children, selectedValue, ...rest} = props;

  return (
    <ControlComponent {...rest}>
      <div
        className="selected-icon-option"
        style={{display: 'flex', alignItems: 'center'}}>
        <div className="icon-wrapper">{selectedValue?.icon}</div>
        {children}
      </div>
    </ControlComponent>
  );
};

export const OnboardingDetailsStep23 = ({
  triggerPTLAnimation,
  triggerSquareAnimationOut,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialProject = useSelector((state) =>
    generalSelector.getProject(state)
  );
  const user = useSelector((state) => generalSelector.getUser(state));

  const uptProject = (data) => dispatch(generalActions.uptProject(data));
  const uptUser = (data) => dispatch(generalActions.uptUser(data));

  const [playAnimationOut, setPlayAnimationOut] = useState(false);
  const [project, setProject] = useState(initialProject);
  const [theme, setTheme] = useState(
    initialProject.defaultTheme || defaultTheme
  );
  const [themeInAppAccent, setThemeInAppAccent] = useState(
    project?.theme?.style.stepStyle?.background?.primaryColor.slice(0, 7) ||
      '#1260EB'
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewPoke, setPreviewPoke] = useState(getPreviewPoke());

  useEffect(() => {
    setTheme({
      ...theme,
      hotspotStyle: `${themeInAppAccent};22`,
      style: {
        ...theme.style,
        stepStyle: {
          ...theme.style.stepStyle,
          background: {
            ...theme.style.stepStyle.background,
            primaryColor: `${themeInAppAccent}ff`,
          },
        },
        blocksStyle: {
          ...theme.style.blocksStyle,
          [BLOCK_TYPE_TITLE]: {
            ...theme.style.blocksStyle[BLOCK_TYPE_TITLE],
            style: {
              ...theme.style.blocksStyle[BLOCK_TYPE_TITLE].style,
              fontSize: 20,
              fontWeight: 500,
            },
          },
          [BLOCK_TYPE_LABEL]: {
            ...theme.style.blocksStyle[BLOCK_TYPE_LABEL],
            style: {
              ...theme.style.blocksStyle[BLOCK_TYPE_LABEL].style,
              fontColor: `${themeInAppAccent}ff`,
              borderColor: `${themeInAppAccent}bf`,
              primaryColor: `${themeInAppAccent}14`,
            },
          },
          [BLOCK_TYPE_CHOICE]: {
            ...theme.style.blocksStyle[BLOCK_TYPE_CHOICE],
            style: {
              ...theme.style.blocksStyle[BLOCK_TYPE_CHOICE].style,
              primaryColor: `${themeInAppAccent}ff`,
              disposition: CHOICE_DISPOSITION_BLOCK,
              withCheckbox: true,
            },
          },
          [BLOCK_TYPE_SLIDER]: {
            ...theme.style.blocksStyle[BLOCK_TYPE_SLIDER],
            style: {
              ...theme.style.blocksStyle[BLOCK_TYPE_SLIDER].style,
              primaryColor: `${themeInAppAccent}b3`,
              secondaryColor: `${themeInAppAccent}29`,
            },
          },
          [BLOCK_TYPE_STEPPER]: {
            ...theme.style.blocksStyle[BLOCK_TYPE_STEPPER],
            style: {
              ...theme.style.blocksStyle[BLOCK_TYPE_STEPPER].style,
              primaryColor: `${themeInAppAccent}ff`,
              secondaryColor: `${themeInAppAccent}29`,
            },
          },
          [BLOCK_TYPE_INTERVIEW]: {
            ...theme.style.blocksStyle[BLOCK_TYPE_INTERVIEW],
            style: {
              ...theme.style.blocksStyle[BLOCK_TYPE_INTERVIEW].style,
              fontPrimaryColor: `${themeInAppAccent}ff`,
              borderPrimaryColor: `${themeInAppAccent}ff`,
            },
          },
          [BLOCK_PRIMARY_CTA]: {
            ...theme.style.blocksStyle[BLOCK_PRIMARY_CTA],
            style: {
              ...theme.style.blocksStyle[BLOCK_PRIMARY_CTA].style,
              primaryColor: `${themeInAppAccent}ff`,
            },
          },
        },
      },
    });
  }, [themeInAppAccent]);

  const handleGoBack = () => {
    setPlayAnimationOut(true);
    setTimeout(() => {
      history.push(ROUTE_ONBOARDING_DETAILS_STEP_2_2);
    }, 1200);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    if (hasProjectChanges === true) {
      await projectService.updateProject(project.uid, {
        widgetThemeColor: project.widgetThemeColor,
        widgetRoundness: project.widgetRoundness,
        changelogStyle: project.changelogStyle,
      });
      uptProject({
        widgetThemeColor: project.widgetThemeColor,
        widgetRoundness: project.widgetRoundness,
        changelogStyle: project.changelogStyle,
      });
    }
    if (hasThemeChanges === true || initialProject.defaultTheme == null) {
      let savedTheme = null;
      if (theme.uid == null) {
        savedTheme = await themeService.createTheme(theme);
      } else {
        savedTheme = await themeService.updateTheme(theme.uid, theme);
      }
      await themeService.setDefaultTheme(savedTheme.uid);
      uptProject({
        ...project,
        defaultTheme: theme,
        theme,
      });
    }
    const onboardingFlags = addFlag(
      ME_F_ONBOARDING_DONE_STEP_2_3,
      user.onboardingFlags
    );

    await meService.updateOnboardingFlag(onboardingFlags);
    uptUser({onboardingFlags});
    setPlayAnimationOut(true);
    triggerSquareAnimationOut();
    setTimeout(() => {
      triggerPTLAnimation('in', () => {
        history.push(ROUTE_ONBOARDING_DETAILS_STEP_3_1);
      });
    }, 1200);
  };

  const refIframePreviewWidget = useRef(null);
  const refIframePreviewPortal = useRef(null);
  const refIframeMsgPreviewWidget = useRef(
    new IframeMessenger(refIframePreviewWidget, project)
  );
  const refIframeMsgPreviewPortal = useRef(
    new IframeMessenger(refIframePreviewPortal, project)
  );

  const hasThemeChanges =
    JSON.stringify(initialProject?.theme || defaultTheme) !==
    JSON.stringify(theme);
  const hasProjectChanges =
    JSON.stringify(initialProject) !== JSON.stringify(project);
  const tabsInAppEnabled = hasFlags(
    [
      F_USAGE_V2_IN_APP_ANNOUNCEMENT,
      F_USAGE_V2_IN_APP_SURVEY,
      F_USAGE_V2_PRODUCT_TOUR,
      F_USAGE_V2_TOOLTIPS,
    ],
    project.usageFlags,
    true
  );
  const tabsWidgetEnabled =
    hasFlags([F_USAGE_V2_WIDGET_NEWS_FEED], project.usageFlags, true) === true;
  const tabsPortalEnabled =
    hasFlags(
      [
        F_USAGE_V2_PORTAL_NEWS_FEED,
        F_USAGE_V2_PUBLIC_ROADMAP,
        F_USAGE_V2_UP_VOTE,
      ],
      project.usageFlags,
      true
    ) === true;

  refIframeMsgPreviewWidget.current.sendProjectUpdate(project);
  refIframeMsgPreviewPortal.current.sendProjectUpdate(project);

  const [tab, setTab] = useState(
    tabsInAppEnabled === true
      ? TAB_IN_APP
      : tabsWidgetEnabled === true
      ? TAB_WIDGET
      : TAB_PORTAL
  );
  const styledPreviewPoke = stylePreviewPoke(theme, {
    ...previewPoke,
    boostedDotStyle: `${themeInAppAccent};22;${HOTSPOT_SHAPE_DEFAULT};${HOTSPOT_ANIMATION_PULSE}`,
    ...(previewPoke.boostFlags === F_SLOT_POP_IN
      ? {
          steps: [
            getDefaultStep({
              ...previewPoke.steps[0],
              name: 'Step #1',
              blocks: [
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_TITLE),
                  value: `Welcome to ${project.name}|-|none|-|`,
                },
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_BODY),
                  value: `We're thrilled to have you join our platform, where efficiency meets innovation. With our cutting-edge features and user-friendly interface, you'll experience seamless workflows like never before. Our dedicated support team is here to assist you every step of the way, ensuring your journey with us is nothing short of exceptional. Get ready to elevate your productivity and achieve your goals with our powerful SaaS solution!`,
                },
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_MEDIA),
                  value: `image;https://appinventiv.com/wp-content/uploads/sites/1/2019/02/Animation-Top-UIUX-Trends-2020-1.gif;placeholder;fill`,
                },
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
                  value: 'Get started;;;',
                },
                {
                  ...getDefaultBlockFromType(BLOCK_SECONDARY_CTA),
                  value: 'Skip;;;',
                },
              ],
            }),
          ],
        }
      : {}),
    ...(previewPoke.boostFlags === F_SLOT_SNIPPET
      ? {
          steps: [
            getDefaultStep({
              ...previewPoke.steps[0],
              name: 'Step #1',
              blocks: [
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_TITLE),
                  value: `Which integrations would you like to see next on ${project.name} ?|-|none|-|`,
                },
                {
                  ...getDefaultBlockFromType(BLOCK_CHOICE),
                  options: [
                    {
                      ...getDefaultChoiceOption(),
                      indexOrder: 0,
                      content: 'Salesforce',
                      uid: '1',
                    },
                    {
                      ...getDefaultChoiceOption(),
                      indexOrder: 1,
                      content: 'Hubspot',
                      uid: '2',
                    },
                    {
                      ...getDefaultChoiceOption(),
                      indexOrder: 2,
                      content: 'Pipedrive',
                      uid: '3',
                    },
                    {
                      ...getDefaultChoiceOption(),
                      indexOrder: 2,
                      content: 'Zendesk',
                      uid: '4',
                    },
                  ],
                  style: {
                    disposition: '',
                  },
                },
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
                  value: 'Submit;;;',
                },
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_USER),
                  value: `Jane Doe;CPO @${project.name};https://i.gyazo.com/6ad9b6e2f08afe09b71105ef1fffee6c.png`,
                },
              ],
            }),
          ],
        }
      : {}),
    ...(previewPoke.boostFlags === F_SLOT_DOT
      ? {
          steps: [
            getDefaultStep({
              ...previewPoke.steps[0],
              name: 'Step #1',
              blocks: [
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_LABEL),
                  value: `New`,
                },
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_TITLE),
                  value: `Glowing in the dark`,
                },
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_BODY),
                  value: `Get ready to experience a whole new dimension as we unveil our dark theme! Embrace the elegance of deep hues and immerse yourself in a visually stunning interface that's easy on the eyes.`,
                },
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_MEDIA),
                  value: `image;https://www.breakfreegraphics.com/wp-content/uploads/2020/12/1FHfXUsvjBjLLPG17AiXLgA.gif;placeholder;fill`,
                },
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
                  value: 'Switch to dark;;;',
                },
              ],
            }),
          ],
        }
      : {}),
    ...(previewPoke.boostFlags === F_SLOT_TOOLTIP
      ? {
          steps: [
            getDefaultStep({
              ...previewPoke.steps[0],
              name: 'Step #1',
              blocks: [
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_TITLE),
                  value: `Almost there!`,
                },
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_BODY),
                  value: `Unlock new possibilities as you explore our advanced modules and discover hidden gems that will revolutionize your workflow. From customizable dashboards to advanced analytics, our application empowers you to take control and achieve remarkable results. `,
                },
                {
                  ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
                  value: 'Go next;;;',
                },
              ],
            }),
          ],
        }
      : {}),
  });

  const changelogStyle = project.changelogStyle ?? defaultChangelogStyle;
  const {general} = changelogStyle;
  const {background} = general ?? {};

  const backgroundOptions = [
    {
      label: 'Circular shape',
      icon: <div className="circular-icon" />,
      value: 'circular',
    },
    {
      label: 'Line shape',
      icon: <div className="line-icon" />,
      value: 'linear',
    },
    {
      label: 'Gradient',
      icon: (
        <div
          className="gradient-icon"
          style={{
            background: `linear-gradient(213.36deg, ${background.primaryColor}, ${background.secondaryColor})`,
          }}
        />
      ),
      value: 'gradient',
    },
    {
      label: 'Solid color',
      icon: (
        <div
          className="solid-icon"
          style={{
            background: background?.primaryColor,
          }}
        />
      ),
      value: 'solid',
    },
  ];

  const selectedValue = backgroundOptions.find((o) => {
    if (background?.type === 'shape') {
      return o.value === background?.shape;
    }
    return o.value === background?.type;
  });

  return (
    <div
      className={classnames(
        's-onboarding-details-step s-onboarding-details-step-2-3',
        {
          'is-exiting': playAnimationOut,
        }
      )}>
      <div className="details-customize-wrapper">
        <div>
          <h1>Let's customize Jimo</h1>
          <p>
            Make sure we match your branding. You will find more <br />
            options of customization later.
          </p>
        </div>
        <div className="customize-main-wrapper">
          {[tabsInAppEnabled, tabsPortalEnabled, tabsWidgetEnabled].filter(
            (f) => f === true
          ).length > 1 ? (
            <div className="onboarding-customize-tabs">
              {tabsInAppEnabled === true && (
                <Button
                  iconLeft="icon-slot-snippet"
                  className={tab === TAB_IN_APP ? 'selected' : ''}
                  onClick={() => setTab(TAB_IN_APP)}>
                  In-app
                </Button>
              )}
              {tabsWidgetEnabled === true && (
                <Button
                  iconLeft="icon-usage-widget"
                  className={tab === TAB_WIDGET ? 'selected' : ''}
                  onClick={() => setTab(TAB_WIDGET)}>
                  Changelog
                </Button>
              )}
            </div>
          ) : (
            <>
              {tabsInAppEnabled === true && <h3>In-app customization</h3>}
              {tabsWidgetEnabled === true && <h3>Changelog customization</h3>}
            </>
          )}
          <div className="details-customize-inputs">
            {/* Tab in-app */}
            {tab === TAB_IN_APP && (
              <>
                <div className="customize-input-wrapper customize-accent-color">
                  <div className="customize-input-label">Accent color</div>
                  <ColorPicker
                    popoverStyle={{bottom: '-225px', left: 0}}
                    color={themeInAppAccent}
                    disableAlpha
                    onChange={(color) => setThemeInAppAccent(color.hex)}>
                    <Input
                      labelTextLeft={
                        <span
                          className="preview-color"
                          style={{
                            backgroundColor: themeInAppAccent,
                          }}
                        />
                      }
                      className="input-color"
                      placeholder="FFFFFF"
                      value={themeInAppAccent
                        ?.toUpperCase()
                        .slice(1, themeInAppAccent.length)}
                    />
                  </ColorPicker>
                </div>
              </>
            )}
            {/* Tab Widget */}
            {tab === TAB_WIDGET && (
              <>
                <div className="customize-input-wrapper customize-accent-color">
                  <div className="customize-input-label">Background</div>
                  <SelectGroup
                    options={backgroundOptions}
                    value={selectedValue}
                    onChange={(option) => {
                      setProject({
                        ...project,
                        changelogStyle: {
                          ...changelogStyle,
                          general: {
                            ...general,
                            background: {
                              ...background,
                              ...(['linear', 'circular'].includes(option.value)
                                ? {
                                    type: 'shape',
                                    shape: option.value,
                                  }
                                : {
                                    type: option.value,
                                  }),
                            },
                          },
                        },
                      });
                    }}
                    components={{
                      Option: IconOption,
                      Control: (props) => (
                        <Control {...props} selectedValue={selectedValue} />
                      ),
                    }}
                  />
                </div>
                <div className="customize-input-wrapper customize-accent-color">
                  <div className="customize-input-label">Color 1</div>
                  <ColorPicker
                    popoverStyle={{bottom: '-225px', left: 0}}
                    color={background?.primaryColor}
                    disableAlpha
                    onChange={(color) =>
                      setProject({
                        ...project,
                        changelogStyle: {
                          ...changelogStyle,
                          general: {
                            ...general,
                            background: {
                              ...background,
                              primaryColor: color.hex,
                            },
                          },
                        },
                      })
                    }>
                    <Input
                      labelTextLeft={
                        <span
                          className="preview-color"
                          style={{
                            backgroundColor: background?.primaryColor,
                          }}
                        />
                      }
                      className="input-color"
                      placeholder="FFFFFF"
                      value={background?.primaryColor
                        ?.toUpperCase()
                        .slice(1, background?.primaryColor?.length)}
                    />
                  </ColorPicker>
                </div>
                {['shape', 'gradient'].includes(background?.type) && (
                  <div className="customize-input-wrapper customize-accent-color">
                    <div className="customize-input-label">Color 2</div>
                    <ColorPicker
                      popoverStyle={{bottom: '-225px', left: 0}}
                      color={background?.secondaryColor}
                      disableAlpha
                      onChange={(color) =>
                        setProject({
                          ...project,
                          changelogStyle: {
                            ...changelogStyle,
                            general: {
                              ...general,
                              background: {
                                ...background,
                                secondaryColor: color.hex,
                              },
                            },
                          },
                        })
                      }>
                      <Input
                        labelTextLeft={
                          <span
                            className="preview-color"
                            style={{
                              backgroundColor: background?.secondaryColor,
                            }}
                          />
                        }
                        className="input-color"
                        placeholder="FFFFFF"
                        value={background?.secondaryColor
                          ?.toUpperCase()
                          .slice(1, background?.secondaryColor?.length)}
                      />
                    </ColorPicker>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="customize-actions">
          <Button cta disabled={isSubmitting} onClick={handleGoBack}>
            Back
          </Button>
          <Button
            loading={isSubmitting}
            className="btn-continue"
            cta
            primary
            secondary
            iconRight="icon-chevron-right"
            onClick={handleSubmit}>
            {hasThemeChanges === false && hasProjectChanges === false
              ? 'Setup later'
              : 'Continue'}
          </Button>
        </div>
      </div>
      <div
        className={classnames('details-customize-preview-wrapper', {
          'is-portal-preview': tab === TAB_PORTAL,
          'is-widget-preview': tab === TAB_WIDGET,
          'is-in-app-preview': tab === TAB_IN_APP,
        })}>
        <div className="customize-preview-overlay"></div>
        <div className="customize-preview-container">
          <div className="in-app-preview-wrapper">
            <RadioGroup
              value={styledPreviewPoke.boostFlags}
              options={[
                {
                  label: <i className="icon-slot-pop-in"></i>,
                  value: F_SLOT_POP_IN,
                },
                {
                  label: <i className="icon-slot-dot"></i>,
                  value: F_SLOT_DOT,
                },
                {
                  label: <i className="icon-slot-tooltip"></i>,
                  value: F_SLOT_TOOLTIP,
                },
                {
                  label: <i className="icon-slot-snippet"></i>,
                  value: F_SLOT_SNIPPET,
                },
              ]}
              onSelect={(option) => {
                setPreviewPoke({
                  ...styledPreviewPoke,
                  boostFlags: option,
                });
              }}
            />
            <ThemePreview
              poke={styledPreviewPoke}
              setPreviewPoke={setPreviewPoke}
              disableCardClickableOverlay
              reRenderOnShapeChange
            />
          </div>
          <PreviewWidget project={project} refIframe={refIframePreviewWidget} />
        </div>
      </div>
    </div>
  );
};

const stepPlaceholder = {
  uid: uuidv4(),
  question: "We've just released [<new feature>] 🎉",
  mdContent:
    '<p>Excepteur esse laborum proident occaecat ad. Nisi laborum id velit esse dolore enim sit irure do in consectetur cupidatat magna elit. Magna enim nulla elit labore aliqua mollit esse ea cillum.</p>',
  mdRawContent:
    '{"blocks":[{"key":"e99p0","text":"This means you can:","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"8t89j","text":"Value prop #1","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3frpi","text":"Value prop #2","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  type: STEP_TYPE_TEXT_BLOCK,
};

const PreviewDot = ({theme}) => {
  return (
    <div className="onboarding-preview-dot">
      <div
        className="dot"
        style={{
          backgroundColor: theme.hotspotSecondaryColor,
          color: theme.hotspotSecondaryColor,
        }}></div>
      <div className="snippet-wrap">
        <div
          className={classnames('preview-snippet', {})}
          style={{
            borderRadius: `${theme.hotspotRoundness}px`,
          }}>
          <Survey
            step={stepPlaceholder}
            stepIndex={1}
            progress={33}
            onVote={() => {}}
            steps={[stepPlaceholder, stepPlaceholder, stepPlaceholder]}
            customization={{
              ...defaultCustomization,
              boostedPositionFlags: theme.hotspotPositionFlags,
              boostedTextsColors: theme.hotspotTextsColors,
              boostedPrimaryColor: theme.hotspotPrimaryColor,
              boostedSecondaryColor: theme.hotspotSecondaryColor,
              boostedRoundness: theme.hotspotRoundness,
              boostedContentFontSize: theme.hotspotContentFontSize,
              boostedTitleFontSize: theme.hotspotTitleFontSize,
              boostedDotStyle: theme.hotspotStyle,
              boostedSize: theme.hotspotSize,
              ctaBorderRadius: theme.hotspotCtaBorderRadius,
              // ctaBackgroundColor: theme.hotspotCtaBackgroundColor,
              ctaBackgroundColor: theme.hotspotSecondaryColor,
            }}
          />
        </div>
      </div>
    </div>
  );
};
