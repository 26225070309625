import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {Menu, MenuItem} from 'components/Menu';
import React, {useContext, useRef, useState} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {evolutionService} from 'services';
import {F_BOOST_SLOT_NAVIGATION, getDefaultEvolution} from 'services/evolution';
import {func, bool, string, object} from 'prop-types';
import {v4 as uuidv4} from 'uuid';
import './_Styles.scss';
import {
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_NAVIGATION,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {getIcon} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal';
import {getTypeFromBoostFlags} from 'scenes/Builder/component/CtaModal';
import classNames from 'classnames';
import {Swaler} from 'swaler';
import {getCustomizationFromTheme} from 'router/Layouts';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {getBoostFlags} from 'scenes/Pushes/components/ModalCreatePoke';
import Divider from 'components/Divider';
import {ModalConfirm} from 'components/Modal';
import {hasFlag} from 'helpers/bitwise';
import {
  ACTIVE_OPERATOR_CUSTOM,
  ACTIVE_OPERATOR_EVERYWHERE,
  ACTIVE_OPERATOR_SINGLE_URL,
} from 'scenes/EmbeddedElementSelectorBuilder';
import {isActiveOnPath} from 'helpers/utils';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {toastWarning} from 'components/Toaster';
import {isPokeValid} from 'scenes/Builder/component/BuilderHeader/utils';
import {isUrlMatchingNavigation} from 'scenes/PokeBuilder/components/AdoptionStepsManager';

const stepItems = [
  {
    value: TYPE_HOTSPOT,
    icon: (
      <div className="icon-wrapper scratch">
        <i className="icon-slot-dot" />
      </div>
    ),
    title: 'Hotspot',
    description: 'Target a specific element of your page',
  },
  {
    value: TYPE_TOOLTIP,
    icon: (
      <div className="icon-wrapper ideation">
        <i className="icon-slot-tooltip" />
      </div>
    ),
    title: 'Tooltip',
    description: `Keep your user's attention on an element`,
  },
  {
    value: TYPE_MODAL,
    icon: (
      <div className="icon-wrapper interview">
        <i className="icon-slot-pop-in" />
      </div>
    ),
    title: 'Pop-in',
    description: 'Show the full post in a centered pop-in',
  },
  {
    value: TYPE_SNIPPET,
    icon: (
      <div className="icon-wrapper preview">
        <i className="icon-slot-snippet" />
      </div>
    ),
    title: 'Snippet',
    description: 'Keep it contextual and discreet',
  },
];

const logger = new Swaler('TourFooter');

const propTypes = {
  onStepTypeSelect: func,
  onContinue: func.isRequired,
  inDalaran: bool,
  currentUrl: string,
  navigateTo: func,
  switchToNavigatorMode: func,
  onStepHovered: func,
  stepEditorRef: object,
  draggedOffset: object,
};

const defaultProps = {
  onStepTypeSelect: () => {},
  onContinue: () => {},
  inDalaran: false,
  currentUrl: null,
  navigateTo: () => {},
  switchToNavigatorMode: () => {},
  onStepHovered: () => {},
  stepEditorRef: null,
  draggedOffset: null,
};

const TourFooter = ({
  onStepTypeSelect,
  onContinue,
  inDalaran,
  currentUrl,
  navigateTo,
  switchToNavigatorMode,
  onStepHovered,
  stepEditorRef,
  draggedOffset,
}) => {
  const {evolution, setEvolution, selectedTourStepId, setSelectedTourStepId} =
    useContext(BuilderContext);

  const project = useSelector((state) => generalSelector.getProject(state));

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stepIdToDelete, setStepIdToDelete] = useState(null);
  const [draggedStepId, setDraggedStepId] = useState(null);

  const handleSelectTourStep = (tourStepId) => {
    const tourSteps = evolution.tourSteps || [];
    tourSteps.forEach((s) => {
      const [tourIndexOrder] = (s.tourStepInfo || '0;0;0').split(';');
      s.tourIndexOrder = tourIndexOrder;
    });
    tourSteps.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);
    const tourStep = evolution.tourSteps?.find((s) => s.uid === tourStepId);
    const index = evolution.tourSteps?.map((s) => s.uid).indexOf(tourStepId);
    const stepsBefore = evolution.tourSteps?.slice(0, index + 1).reverse();
    const lastNavigationStep =
      stepsBefore.find((s) => hasFlag(F_BOOST_SLOT_NAVIGATION, s.boostFlags)) ||
      evolution;

    const isUrlMatching = isUrlMatchingNavigation(
      lastNavigationStep,
      currentUrl
    );
    const isNavigationStep = hasFlag(
      F_BOOST_SLOT_NAVIGATION,
      tourStep?.boostFlags
    );

    if (isNavigationStep === true) {
      switchToNavigatorMode();
    }
    // if there is no navigation step before, navigate to boostedActiveUrl anyway if different from currentUrl
    if (
      lastNavigationStep === evolution &&
      currentUrl !== evolution.boostedActiveUrl
    ) {
      navigateTo(evolution.boostedActiveUrl);
    } else if (isUrlMatching !== true) {
      navigateTo(lastNavigationStep.boostedActiveUrl);
    }

    setSelectedTourStepId(tourStepId);
    setDropdownOpen(false);
  };

  const handleTypeClick = (type, afterStepId) => {
    const newTourStep = getDefaultEvolution({
      uid: uuidv4(),
      boostFlags: getBoostFlags(type),
      ...getCustomizationFromTheme(type, project.theme),
      ...(type === TYPE_NAVIGATION
        ? {
            boostedActiveOperator: ACTIVE_OPERATOR_SINGLE_URL,
          }
        : {}),
    });
    const afterStepIndex = evolution.tourSteps
      .sort((a, b) => a.tourIndexOrder - b.tourIndexOrder)
      .map((s) => s.uid)
      .indexOf(afterStepId);
    const tourSteps = [
      ...(evolution.tourSteps?.slice(0, afterStepIndex + 1) || []),
      newTourStep,
      ...(evolution.tourSteps?.slice(afterStepIndex + 1) || []),
    ];
    tourSteps.forEach((step, index) => {
      step.tourStepInfo = [index].join(';');
    });
    setEvolution({
      ...evolution,
      tourSteps,
    });
    onStepTypeSelect(type);
    setDropdownOpen(false);
    setSelectedTourStepId(newTourStep.uid);
  };

  const handleContinueClick = async () => {
    setIsLoading(true);
    try {
      const savedEvolution = await evolutionService.updateEvolution(
        evolution.uid,
        evolution
      );
      onContinue(savedEvolution);
    } catch (err) {
      logger.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteStep = (stepId) => {
    if (stepId === selectedTourStepId) {
      const index = evolution.tourSteps.map((s) => s.uid).indexOf(stepId);
      setSelectedTourStepId(
        evolution.tourSteps[index > 0 ? index - 1 : index + 1]?.uid
      );
    }

    setEvolution({
      ...evolution,
      tourSteps: evolution.tourSteps.filter((s) => s.uid !== stepId),
    });
    setStepIdToDelete(null);
  };

  const onDragStart = ({draggableId}) => {
    setDraggedStepId(draggableId);
  };
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const {
      source: {index: sourceIndex, droppableId: sourceDroppableId},
      destination: {index: destinationIndex},
    } = result;

    const navigationGroups = evolution.tourSteps?.reduce((acc, cur, index) => {
      const isNavigationStep = hasFlag(F_BOOST_SLOT_NAVIGATION, cur.boostFlags);
      if (index === 0 || isNavigationStep) {
        acc.push([cur]);
      } else {
        acc[acc.length - 1].push(cur);
      }

      return acc;
    }, []);

    if (
      destinationIndex === 0 &&
      hasFlag(
        F_BOOST_SLOT_NAVIGATION,
        navigationGroups[sourceDroppableId][0]?.boostFlags
      )
    ) {
      toastWarning('Cannot move tour step before navigation step.');
      return;
    }
    const offsetIndex = navigationGroups.reduce((acc, cur, index) => {
      if (index < sourceDroppableId) {
        acc += cur.length;
      }
      return acc;
    }, 0);

    const reorderedSteps = Array.from(evolution.tourSteps);
    const [removed] = reorderedSteps.splice(sourceIndex + offsetIndex, 1);
    reorderedSteps.splice(destinationIndex + offsetIndex, 0, removed);
    reorderedSteps.forEach((s, index) => {
      s.tourStepInfo = [index].join(';');
      s.tourIndexOrder = index;
    });
    setEvolution({...evolution, tourSteps: reorderedSteps});
    setDraggedStepId(null);
  };

  const isSubmitDisabled = isPokeValid(evolution) !== true;

  const navigationGroups = evolution.tourSteps?.reduce((acc, cur, index) => {
    const isNavigationStep = hasFlag(F_BOOST_SLOT_NAVIGATION, cur.boostFlags);
    if (index === 0 || isNavigationStep) {
      acc.push([cur]);
    } else {
      acc[acc.length - 1].push(cur);
    }

    return acc;
  }, []);

  return (
    <div className="tour-footer">
      <div
        className="tour-steps-wrapper"
        onMouseLeave={() => onStepHovered(null)}>
        {navigationGroups?.length <= 0 && (
          <DropdownAddStep
            dropdownOpen={true}
            setDropdownOpen={setDropdownOpen}
            handleTypeClick={handleTypeClick}
          />
        )}

        {navigationGroups?.map((group, groupIndex) => {
          const isGroupActive = group
            .map((s) => s.uid)
            .includes(selectedTourStepId);
          const lastGroupStep = group[group.length - 1];

          return (
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <Droppable
                droppableId={groupIndex.toString()}
                type="tour-steps"
                direction="horizontal">
                {(dropProvided) => (
                  <>
                    <div
                      className={classNames('group-steps-wrapper', {
                        passive:
                          draggedStepId != null &&
                          group?.map((s) => s.uid).includes(draggedStepId) !==
                            true,
                      })}>
                      <div
                        className="group-steps"
                        style={{position: 'relative'}}
                        ref={dropProvided.innerRef}
                        {...dropProvided.droppableProps}>
                        {group?.map((tourStep, index) => {
                          const type = getTypeFromBoostFlags(
                            tourStep.boostFlags
                          );
                          const icon = getIcon(type);

                          if (
                            hasFlag(
                              F_BOOST_SLOT_NAVIGATION,
                              tourStep.boostFlags
                            )
                          ) {
                            return (
                              <div
                                className={classNames('tour-step', {
                                  selected: tourStep.uid === selectedTourStepId,
                                })}
                                onClick={() =>
                                  handleSelectTourStep(tourStep.uid)
                                }
                                onMouseEnter={() =>
                                  onStepHovered(tourStep.uid)
                                }>
                                {icon}
                                <Button
                                  className="delete-btn"
                                  iconLeft="icon-trash"
                                  iconOnly
                                  danger
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    setStepIdToDelete(tourStep.uid);
                                  }}
                                />
                              </div>
                            );
                          }

                          return (
                            <Draggable
                              key={tourStep.uid}
                              draggableId={tourStep.uid}
                              index={index}>
                              {(dragProvided, snapshot) => {
                                const draggableStyle =
                                  dragProvided.draggableProps.style;
                                let style = {...draggableStyle};

                                if (
                                  snapshot.isDragging &&
                                  stepEditorRef != null
                                ) {
                                  const rect =
                                    stepEditorRef.current?.getBoundingClientRect();
                                  const offset = {
                                    x:
                                      stepEditorRef.current?.offsetLeft +
                                      draggedOffset?.x,
                                    y:
                                      stepEditorRef.current?.offsetTop +
                                      draggedOffset?.y,
                                  }; // your fixed container left/top position
                                  if (inDalaran === true) {
                                    offset.x = offset.x + rect?.x;
                                    offset.y = offset.y + rect?.y;
                                  }

                                  const x =
                                    dragProvided.draggableProps.style.left -
                                    offset.x;
                                  const y =
                                    dragProvided.draggableProps.style.top -
                                    offset.y;

                                  style = {
                                    ...draggableStyle,
                                    left: x,
                                    top: y,
                                  };
                                }

                                return (
                                  <>
                                    <div
                                      className="tour-step-wrapper"
                                      ref={dragProvided.innerRef}
                                      {...dragProvided.draggableProps}
                                      {...dragProvided.dragHandleProps}
                                      style={style}>
                                      <div
                                        className={classNames('tour-step', {
                                          selected:
                                            tourStep.uid === selectedTourStepId,
                                          'is-dragging': snapshot.isDragging,
                                        })}
                                        onClick={() =>
                                          handleSelectTourStep(tourStep.uid)
                                        }
                                        onMouseEnter={() =>
                                          onStepHovered(tourStep.uid)
                                        }>
                                        {icon}
                                        <Button
                                          className="delete-btn"
                                          iconLeft="icon-trash"
                                          iconOnly
                                          danger
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            setStepIdToDelete(tourStep.uid);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {dragProvided.placeholder}
                                  </>
                                );
                              }}
                            </Draggable>
                          );
                        })}

                        {dropProvided.placeholder}
                      </div>
                      {isGroupActive && (
                        <DropdownAddStep
                          dropdownOpen={dropdownOpen}
                          setDropdownOpen={setDropdownOpen}
                          handleTypeClick={handleTypeClick}
                          lastGroupStep={lastGroupStep}
                          withNavigationStep
                        />
                      )}
                    </div>
                    {groupIndex < navigationGroups.length - 1 && (
                      <i className="icon-chevron-right group-separator" />
                    )}
                  </>
                )}
              </Droppable>
            </DragDropContext>
          );
        })}
      </div>
      {inDalaran !== true && (
        <div className="action-btns">
          <Button
            cta
            className="continue-btn"
            secondary
            light
            onClick={handleContinueClick}
            iconRight="icon-chevron-right"
            disabled={isSubmitDisabled}
            loading={isLoading}>
            Continue to audience
          </Button>
        </div>
      )}
      <ModalConfirm
        className="delete-step-modal"
        title="Delete step?"
        isOpen={stepIdToDelete != null}
        onConfirm={() => handleDeleteStep(stepIdToDelete)}
        onCancel={() => setStepIdToDelete(null)}
        cancelText="Cancel"
        confirmText="Delete"
        cancelBtnProps={{
          cta: false,
        }}
        confirmBtnProps={{
          danger: true,
          primary: false,
          cta: false,
        }}>
        <div className="content">
          Are you sure you want to remove this step?
        </div>
      </ModalConfirm>
    </div>
  );
};

TourFooter.propTypes = propTypes;
TourFooter.defaultProps = defaultProps;

const DropdownAddStep = ({
  dropdownOpen,
  setDropdownOpen,
  handleTypeClick,
  lastGroupStep = null,
  withNavigationStep = false,
}) => {
  return (
    <Dropdown
      className="dropdown-add-step"
      open={dropdownOpen}
      position="top"
      offsetY={12}
      trigger={
        <div className="add-step-btn-wrapper">
          <Button
            className="add-step-btn"
            onClick={() => setDropdownOpen(true)}
            rounded={false}
            iconLeft="icon-plus"
            iconOnly
          />
        </div>
      }>
      <Menu className="steps-menu">
        <div className="menu-title">Add to the tour</div>
        <div className="poke-steps">
          {stepItems.map((step) => {
            const {value, icon, title, description} = step;
            return (
              <MenuItem
                className="steps-menu-item"
                icon={icon}
                iconPosition="left"
                onClick={() => handleTypeClick(value, lastGroupStep?.uid)}>
                <div className="content">
                  <div className="title">{title}</div>
                  <div className="description">{description}</div>
                </div>
              </MenuItem>
            );
          })}
        </div>
        {withNavigationStep && (
          <>
            <Divider />
            <div className="navigation-step-wrapper">
              <MenuItem
                className="steps-menu-item"
                icon={
                  <div className="icon-wrapper preview">
                    <i className="icon-forward" />
                  </div>
                }
                iconPosition="left"
                onClick={() =>
                  handleTypeClick(TYPE_NAVIGATION, lastGroupStep?.uid)
                }>
                <div className="content">
                  <div className="title">Navigation step</div>
                  <div className="description">
                    Redirect users to another page
                  </div>
                </div>
              </MenuItem>
            </div>
          </>
        )}
      </Menu>
    </Dropdown>
  );
};

export default TourFooter;
