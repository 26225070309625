import classnames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Input from 'components/Input';
import DefaultLoader from 'components/Loader';
import TextArea from 'components/TextArea';
import {toastDanger} from 'components/Toaster';
import Toggle from 'components/Toggle';
import WysiwygEditor from 'components/WysiwygEditor';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import eventBus from 'helpers/event-bus';
import {getStepIssue} from 'helpers/step';
import {array, bool, func, object, string} from 'prop-types';
import {useContext, useEffect, useRef, useState} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {fileService, stepsService} from 'services';
import {
  F_BOOST_SLOT_TOP_BAR,
  F_OPTION_COMMENTS_ENABLED,
  F_OPTION_LIKE_ENABLED,
  F_OPTION_OPEN_WIDGET_ON_CLICK,
  F_OPTION_SHOW_ON_PORTAL,
} from 'services/evolution';
import {
  CONCEPT_TEST_DOMAINS,
  CONCEPT_TEST_DOMAIN_FIGMA,
  CONCEPT_TEST_DOMAIN_INVIS,
  CONCEPT_TEST_DOMAIN_INVISIONAPP,
  CONCEPT_TEST_DOMAIN_MAZE,
  STEP_TYPE_CONCEPT_TEST,
  STEP_TYPE_INTERVIEW,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_BLOCK,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import {v4 as uuidv4} from 'uuid';
import ModalCta from '../../../../component/CtaModal';
import Conditions from '../Conditions';
import './_Styles.scss';
import iconCalendly from './icons/calendly.png';
import iconFigma from './icons/figma.png';
import iconGoogleCalendar from './icons/google-calendar.png';
import iconInvision from './icons/invision.png';
import iconMaze from './icons/maze.png';

export const E_BUILDER_CREATE_RESET_CTA = 'E_BUILDER_CREATE_RESET_CTA';

const propTypes = {
  steps: array.isRequired,
  step: object.isRequired,
  updateStep: func.isRequired,
  evolution: object.isRequired,
  updateEvolutionOptions: func.isRequired,
  selectedLanguage: string,
  showErrors: bool,
  disabled: bool,
};
const defaultProps = {
  selectedLanguage: null,
  showErrors: false,
  disabled: false,
};

const EditStep = ({
  evolution,
  updateEvolutionOptions,
  steps,
  step,
  updateStep,
  selectedLanguage,
  showErrors,
  disabled,
}) => {
  const {type, texts = [], options = []} = step;

  const {showModalCta, setShowModalCta} = useContext(BuilderContext);

  const [isUploading, setIsUploading] = useState(false);

  const shownOnPortal = hasFlag(
    F_OPTION_SHOW_ON_PORTAL,
    evolution?.optionsFlags
  );
  const isOnlyBanner =
    hasFlag(F_BOOST_SLOT_TOP_BAR, evolution.boostFlags) && !shownOnPortal;

  const hasMultiChoice = [
    stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  ].includes(type);
  const canAddConditions = [
    STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    STEP_TYPE_NPS,
    STEP_TYPE_SLIDER,
    STEP_TYPE_OPINION_SCALE,
  ].includes(type);
  const hasQuestion = [
    STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    STEP_TYPE_NPS,
    STEP_TYPE_SLIDER,
    STEP_TYPE_OPINION_SCALE,
    STEP_TYPE_TEXT_BLOCK,
    STEP_TYPE_TEXT_LONG,
    STEP_TYPE_CONCEPT_TEST,
    STEP_TYPE_INTERVIEW,
  ].includes(type);
  const hasAdditionalInformation = [
    STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    STEP_TYPE_NPS,
    STEP_TYPE_SLIDER,
    STEP_TYPE_OPINION_SCALE,
    STEP_TYPE_TEXT_LONG,
    STEP_TYPE_CONCEPT_TEST,
    STEP_TYPE_INTERVIEW,
  ].includes(type);
  const hasMdEditor = [STEP_TYPE_TEXT_BLOCK].includes(type);
  const hasToggleEvolutionOptions = [STEP_TYPE_TEXT_BLOCK].includes(type);
  const hasCta = [STEP_TYPE_TEXT_BLOCK].includes(type);

  const refTextArea = useRef();
  const refInputThumbnail = useRef();

  useEffect(() => {
    eventBus.on(E_BUILDER_CREATE_RESET_CTA, () =>
      handleCtaConfigChange({
        ctaUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaAction: null,
      })
    );

    return () => eventBus.remove(E_BUILDER_CREATE_RESET_CTA);
  }, []);

  useEffect(() => {
    if (selectedLanguage != null) {
      const question =
        texts.find((t) => t.language === selectedLanguage)?.question ||
        step.question;
      const additionalInformation =
        texts.find((t) => t.language === selectedLanguage)
          ?.additionalInformation || step.additionalInformation;
      const mdContent =
        texts.find((t) => t.language === selectedLanguage)?.mdContent ||
        step.mdContent;
      const mdRawContent =
        texts.find((t) => t.language === selectedLanguage)?.mdRawContent ||
        step.mdRawContent;

      const text = texts.find((t) => t.language === selectedLanguage);

      options.forEach((o) => {
        const content =
          o.labels.find((l) => l.language === selectedLanguage)?.content ||
          o.content;

        const label = o.labels.find((l) => l.language === selectedLanguage);

        if (!label) {
          o.labels = [...o.labels, {language: selectedLanguage, content}];
        }
      });

      if (text == null) {
        updateStep({
          ...step,
          texts: [
            ...texts,
            {
              language: selectedLanguage,
              question,
              additionalInformation,
              mdContent,
              mdRawContent,
            },
          ],
          options,
        });
      } else {
        updateStep({
          ...step,
          texts: texts.map((t) =>
            t.language === text.language
              ? {
                  ...t,
                  question,
                  additionalInformation,
                  mdContent,
                  mdRawContent,
                }
              : t
          ),
          options,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const handleQuestionChange = (e) => {
    if (selectedLanguage == null) {
      updateStep({
        ...step,
        question: e.target.value,
      });
    } else {
      const textIndex = texts.map((e) => e.language).indexOf(selectedLanguage);
      if (textIndex < 0) {
        updateStep({
          ...step,
          texts: [
            ...texts,
            {
              language: selectedLanguage,
              question: e.target.value,
              additionalInformation: '',
            },
          ],
        });
      } else {
        updateStep({
          ...step,
          texts: texts.map((t) =>
            t.language === selectedLanguage
              ? {...t, question: e.target.value}
              : t
          ),
        });
      }
    }
  };

  const handleAdditionalInformationChange = (e) => {
    if (selectedLanguage == null) {
      updateStep({
        ...step,
        additionalInformation: e.target.value,
      });
    } else {
      const textIndex = texts.map((e) => e.language).indexOf(selectedLanguage);
      if (textIndex < 0) {
        updateStep({
          ...step,
          texts: [
            ...texts,
            {
              language: selectedLanguage,
              question: '',
              additionalInformation: e.target.value,
            },
          ],
        });
      } else {
        updateStep({
          ...step,
          texts: texts.map((t) =>
            t.language === selectedLanguage
              ? {...t, additionalInformation: e.target.value}
              : t
          ),
        });
      }
    }
  };

  const handleAddOption = (e) => {
    e.preventDefault();
    updateStep({
      ...step,
      options: [
        ...step.options,
        {
          uid: uuidv4(),
          content: '',
          labels: [],
          indexOrder: step.options.length,
        },
      ],
    });
  };

  const handleDeleteOption = (index) => {
    updateStep({
      ...step,
      options: step.options.filter((e, i) => i !== index),
    });
  };

  const handleMdContentChange = ({content, rawContent}) => {
    if (selectedLanguage == null) {
      updateStep({
        ...step,
        mdContent: content,
        mdRawContent: rawContent,
      });
    } else {
      const textIndex = texts.map((e) => e.language).indexOf(selectedLanguage);

      if (textIndex < 0) {
        updateStep({
          ...step,
          texts: [
            ...texts,
            {
              language: selectedLanguage,
              mdContent: content,
              mdRawContent: rawContent,
            },
          ],
        });
      } else {
        updateStep({
          ...step,
          texts: texts.map((t) =>
            t.language === selectedLanguage
              ? {...t, mdContent: content, mdRawContent: rawContent}
              : t
          ),
        });
      }
    }
  };

  const handleOptionContentChange = (optionId, newContent) => {
    const optionIndex = step.options.map((e) => e.uid).indexOf(optionId);

    if (optionIndex >= 0) {
      const option = step.options[optionIndex];

      if (selectedLanguage == null) {
        updateStep({
          ...step,
          options: step.options.map((o) =>
            o.uid === optionId ? {...o, content: newContent} : o
          ),
        });
      } else {
        const languageIndex = option.labels
          .map((e) => e.language)
          .indexOf(selectedLanguage);
        if (languageIndex < 0) {
          updateStep({
            ...step,
            options: step.options.map((o) =>
              o.uid === optionId
                ? {
                    ...o,
                    labels: [
                      ...o.labels,
                      {
                        content: newContent,
                        language: selectedLanguage,
                      },
                    ],
                  }
                : o
            ),
          });
        } else {
          updateStep({
            ...step,
            options: step.options.map((o) =>
              o.uid === optionId
                ? {
                    ...o,
                    labels: o.labels.map((l) =>
                      l.language === selectedLanguage
                        ? {...l, content: newContent}
                        : l
                    ),
                  }
                : o
            ),
          });
        }
      }
    }
  };

  const handleCtaConfigChange = (config) => {
    if (selectedLanguage == null) {
      updateStep({
        ...step,
        ...config,
      });
    } else {
      const textIndex = texts.map((e) => e.language).indexOf(selectedLanguage);

      if (textIndex < 0) {
        updateStep({
          ...step,
          texts: [...texts, ...config],
        });
      } else {
        updateStep({
          ...step,
          texts: texts.map((t) =>
            t.language === selectedLanguage ? {...t, ...config} : t
          ),
        });
      }
    }
  };

  const handleChangeInteractiveLink = ({target}) => {
    const interactiveLink = encodeURIComponent(target.value);

    if (selectedLanguage == null) {
      updateStep({
        ...step,
        interactiveLink: target.value,
      });
    } else {
      const textIndex = texts.map((e) => e.language).indexOf(selectedLanguage);

      if (textIndex < 0) {
        updateStep({
          ...step,
          texts: [...texts, ...{interactiveLink}],
        });
      } else {
        updateStep({
          ...step,
          texts: texts.map((t) =>
            t.language === selectedLanguage ? {...t, ...{interactiveLink}} : t
          ),
        });
      }
    }
  };

  const handleConceptTestThumbnailsChange = async ({target}) => {
    if (!target.files || target.files.length === 0) {
      return;
    }
    setIsUploading(true);
    const file = await uploadFile(target.files[0]);
    setIsUploading(false);

    if (file != null) {
      updateStep({
        ...step,
        conceptThumbnailUrl: file.publicUrl,
        conceptThumbnailFileId: file.uid,
        thumbnails: [file],
      });
    }
  };

  const handleDeleteConceptThumbnail = async () => {
    if (step.thumbnails.length > 0) {
      try {
        await fileService.deletePublicFile(step.thumbnails[0].uid);
      } catch (err) {
        // if (err)
      }
      updateStep({
        ...step,
        conceptThumbnailUrl: null,
        conceptThumbnailFileId: null,
      });
    }
  };

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    try {
      const uploadedFile = await fileService.uploadPublicFile({file});

      return uploadedFile;
    } catch (err) {
      const {title, message, actions} = errorHelpers.parseError(err);

      toastDanger([title, message], {
        actions,
      });
      return null;
    }
  };

  const handleToggleLikes = (hasFlag) => {
    if (hasFlag === true) {
      return updateEvolutionOptions(
        addFlag(F_OPTION_LIKE_ENABLED, evolution.optionsFlags)
      );
    }
    return updateEvolutionOptions(
      removeFlag(F_OPTION_LIKE_ENABLED, evolution.optionsFlags)
    );
  };
  const handleToggleComments = (hasFlag) => {
    if (hasFlag === true) {
      return updateEvolutionOptions(
        addFlag(F_OPTION_COMMENTS_ENABLED, evolution.optionsFlags)
      );
    }
    return updateEvolutionOptions(
      removeFlag(F_OPTION_COMMENTS_ENABLED, evolution.optionsFlags)
    );
  };
  const handleToggleOpenWidget = (hasFlag) => {
    if (hasFlag === true) {
      return updateEvolutionOptions(
        addFlag(F_OPTION_OPEN_WIDGET_ON_CLICK, evolution.optionsFlags)
      );
    }
    return updateEvolutionOptions(
      removeFlag(F_OPTION_OPEN_WIDGET_ON_CLICK, evolution.optionsFlags)
    );
  };

  const question =
    selectedLanguage != null
      ? texts.find((t) => t.language === selectedLanguage)?.question
      : step.question;
  const additionalInformation =
    selectedLanguage != null
      ? texts.find((t) => t.language === selectedLanguage)
          ?.additionalInformation
      : step.additionalInformation;
  const mdContent =
    texts.find((t) => t.language === selectedLanguage)?.mdContent ||
    step.mdContent;
  const mdRawContent =
    texts.find((t) => t.language === selectedLanguage)?.mdRawContent ||
    step.mdRawContent;
  const interactiveLink =
    texts.find((t) => t.language === selectedLanguage)?.interactiveLink ||
    step.interactiveLink;
  const isLastStep =
    evolution.steps[evolution.steps?.length - 1]?.uid === step.uid;
  const issues = getStepIssue(step);

  return (
    <div
      className={classnames('form-wrapper-step', {
        'without-padding': type === STEP_TYPE_TEXT_BLOCK,
      })}>
      {showErrors === true && issues?.length > 0 && (
        <div className="issues-list">
          {issues.map((i) => (
            <li>{i}</li>
          ))}
        </div>
      )}
      {hasQuestion && (
        <div className="question-wrapper">
          <Input
            required
            className={classnames('step-question', {
              invalid: showErrors === true && !question,
            })}
            placeholder={
              step?.type === STEP_TYPE_TEXT_BLOCK
                ? 'Type a title'
                : 'Type your question here'
            }
            name="question"
            value={question}
            onChange={handleQuestionChange}
          />
        </div>
      )}
      {hasAdditionalInformation && (
        <div className="text-area-wrapper">
          <TextArea
            innerRef={refTextArea}
            className="step-additional-information"
            placeholder="Add additional information here"
            name="additionalInformation"
            value={additionalInformation}
            onChange={handleAdditionalInformationChange}
          />
        </div>
      )}
      {hasMultiChoice && (
        <>
          <Divider />
          <div className="type-btns">
            <Button
              className={classnames('type-btn', {
                selected:
                  type === stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
                disabled: disabled === true,
              })}
              thin
              iconLeft="icon-checkbox"
              onClick={(e) => {
                e.preventDefault();
                updateStep({
                  ...step,
                  type: stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
                });
              }}
              disabled={disabled}>
              Multi-select
            </Button>
            <Button
              thin
              className={classnames('type-btn', {
                selected:
                  type === stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
                disabled: disabled === true,
              })}
              rounded={false}
              iconLeft="icon-radiobox"
              onClick={(e) => {
                e.preventDefault();
                updateStep({
                  ...step,
                  type: stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
                });
              }}
              disabled={disabled}>
              Single-select
            </Button>
          </div>
          <div className="options">
            {options
              .sort((a, b) => a.indexOrder - b.indexOrder)
              .map((option, index) => {
                const content =
                  selectedLanguage != null
                    ? option.labels.find((l) => l.language === selectedLanguage)
                        ?.content
                    : option.content;
                return (
                  <div className="choice-wrapper">
                    <Input
                      required
                      className={classnames('choice-label', {
                        invalid: showErrors === true && !content,
                      })}
                      placeholder={`Choice ${index + 1}`}
                      name="name"
                      value={content || ''}
                      onChange={(e) =>
                        handleOptionContentChange(option.uid, e.target.value)
                      }
                      disabled={disabled}
                      labelTextRight={
                        index > 1 && disabled !== true ? (
                          <i
                            className="icon-trash"
                            onClick={() => handleDeleteOption(index)}></i>
                        ) : null
                      }
                    />
                    {index + 1 === options.length && disabled !== true ? (
                      <Button
                        className={classnames('add-option-btn')}
                        iconLeft="icon-plus-rounded"
                        iconOnly
                        onClick={handleAddOption}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
          </div>
        </>
      )}
      {hasMdEditor && !isOnlyBanner && (
        <div className={classnames('text-area-wrapper')}>
          <WysiwygEditor
            value={mdContent}
            rawValue={mdRawContent}
            language={selectedLanguage}
            onChange={handleMdContentChange}
          />
        </div>
      )}
      {hasCta && (
        <div className="cta-group-wrapper">
          <div className="cta-wrapper">
            {step.ctaUrl == null ? (
              <Button
                className="btn-add-cta"
                rounded={false}
                thin
                iconLeft="icon-plus-rounded"
                onClick={() => setShowModalCta(true)}>
                Add CTA
              </Button>
            ) : (
              <>
                <Button
                  className="btn-edit-cta"
                  thin
                  iconLeft="icon-edit-outline"
                  onClick={() => setShowModalCta(true)}>
                  Edit CTA
                </Button>
                <Button
                  className="btn-remove-cta"
                  thin
                  danger
                  iconLeft="icon-trash"
                  onClick={() => eventBus.dispatch(E_BUILDER_CREATE_RESET_CTA)}>
                  Remove CTA
                </Button>
              </>
            )}
          </div>
          <Divider />
        </div>
      )}
      {hasToggleEvolutionOptions && !isOnlyBanner && (
        <div className="evolution-toggle-options">
          {shownOnPortal && (
            <>
              {/* LIKES */}
              <div
                className={classnames('activate-likes-wrapper', {
                  'is-active': hasFlag(
                    F_OPTION_LIKE_ENABLED,
                    evolution.optionsFlags
                  ),
                })}
                onClick={() => {
                  handleToggleLikes(
                    !hasFlag(F_OPTION_LIKE_ENABLED, evolution.optionsFlags)
                  );
                }}>
                <div className="description-wrapper">
                  Likes
                  <small>
                    Show a like button so that users can likes your text
                  </small>
                </div>
                <Toggle
                  checked={hasFlag(
                    F_OPTION_LIKE_ENABLED,
                    evolution.optionsFlags
                  )}
                  onChange={handleToggleLikes}
                />
              </div>
              {/* COMMENTS */}
              <div
                className={classnames('activate-comments-wrapper', {
                  'is-active': hasFlag(
                    F_OPTION_COMMENTS_ENABLED,
                    evolution.optionsFlags
                  ),
                })}
                onClick={() => {
                  handleToggleComments(
                    !hasFlag(F_OPTION_COMMENTS_ENABLED, evolution.optionsFlags)
                  );
                }}>
                <div className="description-wrapper">
                  Comments
                  <small>Let users post comment about this text</small>
                </div>
                <Toggle
                  checked={hasFlag(
                    F_OPTION_COMMENTS_ENABLED,
                    evolution.optionsFlags
                  )}
                />
              </div>
              {/* TITLE ON WIDGET */}
              <div
                className={classnames('open-widget-wrapper', {
                  'is-active':
                    isLastStep === true &&
                    hasFlag(
                      F_OPTION_OPEN_WIDGET_ON_CLICK,
                      evolution.optionsFlags
                    ),
                })}
                onClick={() => {
                  if (isLastStep === true) {
                    handleToggleOpenWidget(
                      !hasFlag(
                        F_OPTION_OPEN_WIDGET_ON_CLICK,
                        evolution.optionsFlags
                      )
                    );
                  }
                }}>
                <div className="description-wrapper">
                  Title clickable
                  <small>
                    Open the widget when users click on the title, in your app
                  </small>
                </div>
                <Toggle
                  disabled={isLastStep !== true}
                  checked={
                    isLastStep === true &&
                    hasFlag(
                      F_OPTION_OPEN_WIDGET_ON_CLICK,
                      evolution.optionsFlags
                    )
                  }
                  onChange={handleToggleOpenWidget}
                />
              </div>
            </>
          )}
        </div>
      )}
      <Divider />
      <div className="conditions-wrapper">
        <div className="conditions-legend">Conditions</div>
        <Conditions
          options={options}
          steps={steps}
          step={step}
          updateStep={updateStep}
          canAddConditions={canAddConditions}
        />
      </div>
      {step.type === STEP_TYPE_CONCEPT_TEST && (
        <div className="concept-test-wrapper">
          <Input
            placeholder="Add a prototype link"
            value={decodeURIComponent(interactiveLink)}
            onChange={handleChangeInteractiveLink}
            className={classnames({
              invalid:
                showErrors === true &&
                CONCEPT_TEST_DOMAINS.some((d) =>
                  interactiveLink.includes(d)
                ) === false,
            })}
            labelTextRight={
              interactiveLink.includes(CONCEPT_TEST_DOMAIN_FIGMA) ? (
                <img src={iconFigma} alt="Figma" />
              ) : [
                  CONCEPT_TEST_DOMAIN_INVIS,
                  CONCEPT_TEST_DOMAIN_INVISIONAPP,
                ].some((domain) => interactiveLink.includes(domain)) ? (
                <img src={iconInvision} alt="Invision" />
              ) : interactiveLink.includes(CONCEPT_TEST_DOMAIN_MAZE) ? (
                <img src={iconMaze} alt="Maze" />
              ) : undefined
            }></Input>

          {step.interactiveLink.length === 0 ? (
            <div className="icons-brand-wrapper fade-in">
              <a
                href="https://help.usejimo.com/help-center/v/using-jimo/integrations/figma"
                target="_blank"
                rel="noreferrer">
                <img src={iconFigma} alt="Figma" />
              </a>
              <a
                href="https://help.usejimo.com/help-center/v/using-jimo/integrations/invision"
                target="_blank"
                rel="noreferrer">
                <img src={iconInvision} alt="Invision" />
              </a>
              <a
                href="https://help.usejimo.com/help-center/v/using-jimo/integrations/maze"
                target="_blank"
                rel="noreferrer">
                <img src={iconMaze} alt="Maze" />
              </a>
            </div>
          ) : (
            <>
              <Divider></Divider>
              <input
                ref={refInputThumbnail}
                type="file"
                style={{display: 'none'}}
                onChange={handleConceptTestThumbnailsChange}
              />
              {step.conceptThumbnailUrl != null &&
              (step.conceptThumbnailFileId != null ||
                step.thumbnails.length > 0) ? (
                <div className="concept-thumbnail-wrapper">
                  <div className="thumb-preview-name-wrapper">
                    <img
                      src={step.conceptThumbnailUrl}
                      alt="Thumbnail"
                      width={60}
                      height={40}
                    />
                    {step.thumbnails[0].originalName}
                  </div>
                  <i
                    className="icon-close"
                    onClick={handleDeleteConceptThumbnail}></i>
                </div>
              ) : (
                <div
                  className={classnames('btn-thumbnail', {
                    'is-uploading': isUploading === true,
                  })}
                  onClick={() => refInputThumbnail.current.click()}>
                  {isUploading === true ? (
                    <>
                      <DefaultLoader width={14}></DefaultLoader> uploading your
                      thumbnail...
                    </>
                  ) : (
                    <>
                      <i className="icon-upload"></i> upload a thumbnail
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
      {step.type === STEP_TYPE_INTERVIEW && (
        <div className="interview-wrapper">
          <Input
            placeholder="Add a calendar link"
            value={interactiveLink}
            onChange={handleChangeInteractiveLink}
            labelTextRight={
              interactiveLink.includes('figma.com') ? (
                <img src={iconFigma} alt="Figma" />
              ) : interactiveLink.includes('invis.io') ? (
                <img src={iconInvision} alt="Invision" />
              ) : interactiveLink.includes('maze.co') ? (
                <img src={iconMaze} alt="Maze" />
              ) : undefined
            }
          />

          <div className="icons-brand-wrapper fade-in">
            <a
              href="https://help.usejimo.com/help-center/v/using-jimo/integrations/calendly"
              target="_blank"
              rel="noreferrer">
              <img src={iconCalendly} alt="Calendly" />
            </a>
            <a
              href="https://help.usejimo.com/help-center/v/using-jimo/integrations/google-calendar"
              target="_blank"
              rel="noreferrer">
              <img src={iconGoogleCalendar} alt="Google" />
            </a>
          </div>
        </div>
      )}
      <ModalCta
        evolution={evolution}
        step={step}
        onCtaChange={handleCtaConfigChange}
        isOpen={showModalCta}
        closeModal={() => setShowModalCta(false)}
        selectedLanguage={selectedLanguage}
      />
    </div>
  );
};

EditStep.propTypes = propTypes;
EditStep.defaultProps = defaultProps;

export default EditStep;
