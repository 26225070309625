import {
  EVENT_CONDITION_TYPE_DELAY,
  EVENT_CONDITION_TYPE_ELEMENT,
  EVENT_CONDITION_TYPE_INPUT,
  EVENT_CONDITION_TYPE_URL,
  EventConditionOperand,
} from 'services/event';
import {v4 as uuidv4} from 'uuid';

const removeMissingAttributesFromLogic = (logic, conditions) => {
  logic.children = logic.children.filter((child) => {
    if (child.type === 'condition') {
      return conditions.find((attr) => attr.uid === child.attributeId);
    } else if (child.type === 'logicGroup') {
      removeMissingAttributesFromLogic(child, conditions);
      return true;
    }
    return true;
  });
};
export const buildLogic = (event = {}) => {
  const {logic = null, conditions = []} = event || {};

  if (logic == null) {
    return {
      uid: uuidv4(),
      type: 'logicGroup',
      operator: 'and',
      children: conditions.map((attr) => ({
        type: 'condition',
        attributeId: attr.uid,
      })),
    };
  } else {
    // remove missing conditions from logic
    removeMissingAttributesFromLogic(logic, conditions);
    return logic;
  }
};

export const addAttributeToLogic = (logic, attribute, groupId) => {
  // search through logic object to find the group with the given id and add the attribute to it
  const addAttributeToGroup = (group) => {
    if (group.uid === groupId) {
      group.children.push({
        type: 'condition',
        attributeId: attribute.uid,
      });
    } else {
      group.children.forEach((child) => {
        if (child.type === 'logicGroup') {
          addAttributeToGroup(child);
        }
      });
    }
  };

  addAttributeToGroup(logic);

  return logic;
};

export const addLogicGroupToLogic = (logic, groupId) => {
  // search through logic object to find the group with the given id and add the attribute to it
  const addLogicGroupToGroup = (group) => {
    if (group.uid === groupId) {
      group.children.push({
        uid: uuidv4(),
        type: 'logicGroup',
        operator: group.operator === 'and' ? 'or' : 'and',
        children: [],
      });
    } else {
      group.children.forEach((child) => {
        if (child.type === 'logicGroup') {
          addLogicGroupToGroup(child);
        }
      });
    }
  };

  addLogicGroupToGroup(logic);
  return logic;
};

export const deleteAttributeFromLogic = (logic, attributeId) => {
  const deleteAttributeFromGroup = (group) => {
    group.children = group.children.filter((child) => {
      if (child.type === 'condition') {
        return child.attributeId !== attributeId;
      } else if (child.type === 'logicGroup') {
        deleteAttributeFromGroup(child);
        return true;
      }
      return true;
    });
  };

  deleteAttributeFromGroup(logic);
  return logic;
};

export const deleteLogicGroupFromLogic = (logic, conditions, groupId) => {
  const removeNestedConditions = (group) => {
    group.children = group.children.filter((child) => {
      if (child.type === 'condition') {
        conditions = conditions.filter(
          (attr) => attr.uid !== child.attributeId
        );
        return false;
      } else if (child.type === 'logicGroup') {
        removeNestedConditions(child);
        return true;
      }
      return true;
    });
  };
  const deleteLogicGroupFromGroup = (group) => {
    group.children = group.children.filter((child) => {
      if (child.type === 'logicGroup') {
        if (child.uid === groupId) {
          // remove all conditions from the group
          removeNestedConditions(child);
          return false;
        } else {
          deleteLogicGroupFromGroup(child);
          return true;
        }
      }
      return true;
    });
  };

  deleteLogicGroupFromGroup(logic);
  return {logic, conditions};
};

export const updateLogicOperator = (logic, groupId, operator) => {
  const updateOperatorFromGroup = (group) => {
    if (group.uid === groupId) {
      group.operator = operator;
    } else {
      group.children.forEach((child) => {
        if (child.type === 'logicGroup') {
          updateOperatorFromGroup(child);
        }
      });
    }
  };

  updateOperatorFromGroup(logic);
  return logic;
};

export const hasConditionInLogic = (logic, attributeId) => {
  let hasCondition = false;

  const checkConditionInGroup = (group) => {
    group.children?.forEach((child) => {
      if (child.type === 'condition') {
        if (child.attributeId === attributeId) {
          hasCondition = true;
        }
      } else if (child.type === 'logicGroup') {
        checkConditionInGroup(child);
      }
    });
  };

  checkConditionInGroup(logic);
  return hasCondition;
};

export const createCondition = (type = '') => {
  const attribute = {
    uid: uuidv4(),
    type,
    operand:
      type === EVENT_CONDITION_TYPE_ELEMENT
        ? EventConditionOperand.CLICKED
        : type === EVENT_CONDITION_TYPE_INPUT
        ? EventConditionOperand.NIS_EMPTY
        : EventConditionOperand.IS,
    value: '',
    querySelector: '',
  };

  return attribute;
};

export const isEventValid = (conditions = [], logic) => {
  if (logic == null) {
    return {isValid: false, issues: ['Empty logic']};
  }

  let isValid = true;
  const issues = [];

  const checkAttributesFromLogic = (logic) => {
    if (!(logic.children?.length > 0)) {
      isValid = false;
      issues.push(['Empty logic group']);
    }
    logic.children?.forEach((child) => {
      if (child.type === 'condition') {
        const condition = conditions?.find(
          (attr) => attr.uid === child.attributeId
        );
        if (condition == null) {
          isValid = false;
          issues.push([`Condition ${child.attributeId} is missing`]);
        }
      } else if (child.type === 'logicGroup') {
        checkAttributesFromLogic(child);
      }
    });
  };

  checkAttributesFromLogic(logic);

  for (const condition of conditions) {
    if (isEventConditionValid(condition) === false) {
      isValid = false;
      issues.push([`Condition ${condition.uid} is invalid`]);
    }
  }

  return {isValid, issues};
};

export const isEventConditionValid = (condition) => {
  let isValid = true;

  if (condition.type == null) {
    isValid = false;
  }
  if (condition.type === EVENT_CONDITION_TYPE_ELEMENT) {
    if (
      !condition.querySelectorManual?.elementText &&
      !condition.querySelectorManual?.cssSelector &&
      !condition.querySelector
    ) {
      isValid = false;
    }
    if (
      [
        EventConditionOperand.CLICKED,
        EventConditionOperand.MOUSE_DOWN,
        EventConditionOperand.HOVERED,
        EventConditionOperand.PRESENT,
        EventConditionOperand.NOT_PRESENT,
        EventConditionOperand.DISABLED,
        EventConditionOperand.NOT_DISABLED,
      ].includes(condition.operand) !== true
    ) {
      isValid = false;
    }
  }

  if (condition.type === EVENT_CONDITION_TYPE_URL) {
    if (
      [
        EventConditionOperand.IS,
        EventConditionOperand.NIS,
        EventConditionOperand.STARTS_WITH,
        EventConditionOperand.ENDS_WITH,
        EventConditionOperand.CONTAINS,
        EventConditionOperand.NCONTAINS,
        EventConditionOperand.MATCHES_REGEX,
        EventConditionOperand.NMATCHES_REGEX,
      ].includes(condition.operand) !== true
    ) {
      isValid = false;
    }

    if (condition.value == null || condition.value === '') {
      isValid = false;
    }
  }

  if (condition.type === EVENT_CONDITION_TYPE_INPUT) {
    if (
      !condition.querySelectorManual?.elementText &&
      !condition.querySelectorManual?.cssSelector &&
      !condition.querySelector
    ) {
      isValid = false;
    }
    if (
      [
        EventConditionOperand.IS,
        EventConditionOperand.NIS,
        EventConditionOperand.IS_EMPTY,
        EventConditionOperand.NIS_EMPTY,
        EventConditionOperand.STARTS_WITH,
        EventConditionOperand.ENDS_WITH,
        EventConditionOperand.CONTAINS,
        EventConditionOperand.NCONTAINS,
        EventConditionOperand.MATCHES_REGEX,
        EventConditionOperand.NMATCHES_REGEX,
      ].includes(condition.operand) !== true
    ) {
      isValid = false;
    }
    if (
      [
        EventConditionOperand.IS,
        EventConditionOperand.NIS,
        EventConditionOperand.STARTS_WITH,
        EventConditionOperand.ENDS_WITH,
        EventConditionOperand.CONTAINS,
        EventConditionOperand.NCONTAINS,
        EventConditionOperand.MATCHES_REGEX,
        EventConditionOperand.NMATCHES_REGEX,
      ].includes(condition.operand)
    ) {
      if (condition.value == null || condition.value === '') {
        isValid = false;
      }
    }
  }

  if (condition.type === EVENT_CONDITION_TYPE_DELAY) {
    if (condition.value <= 0) {
      isValid = false;
    }
  }

  return isValid;
};
