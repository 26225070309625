import {hasFlag} from 'helpers/bitwise';
import {useContext, useEffect} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import StepEditor from 'scenes/EmbeddedPokeBuilder/components/StepEditor';
import {stepsService} from 'services';
import {F_BOOST_SLOT_TOP_BAR} from 'services/evolution';
import {Preview} from './components/Preview';
import './_styles.scss';

export const BuilderEdit = () => {
  const {evolution, setEvolution, setSelectedStepId, setSelectedTourStepId} =
    useContext(BuilderContext);

  useEffect(() => {
    if (evolution.tourSteps?.length > 0) {
      setSelectedTourStepId(evolution.tourSteps[0]?.uid);
      setSelectedStepId(evolution.tourSteps[0]?.steps?.[0]?.uid);
    } else {
      if (evolution.steps?.length > 0) {
        setSelectedStepId(evolution.steps[0]?.uid);
      } else {
        if (hasFlag(F_BOOST_SLOT_TOP_BAR, evolution.boostFlags)) {
          const step = stepsService.createStepObj(
            stepsService.STEP_TYPE_TEXT_BLOCK
          );

          setSelectedStepId(step.uid);
          setEvolution({
            ...evolution,
            steps: evolution.steps.concat(step),
          });
        }
      }
    }
  }, []);

  return (
    <div className="s-builder-edit">
      <div className="main-wrapper">
        <div key={2} className="content-wrapper fade-in">
          <StepEditor inDalaran />
        </div>
      </div>
      <div className="preview-wrapper">
        <Preview />
      </div>
    </div>
  );
};
