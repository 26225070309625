import classNames from 'classnames';
import Divider from 'components/Divider';
import {bool} from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import PostBlockManager from '../PostBlockManager';
import PostLanguageSelector from '../PostLanguageSelector';
import {findLanguages} from '../PostLanguageSelector/utils';
import './_Styles.scss';

export const MODE_ADD_BLOCK = 'MODE_ADD_BLOCK';
export const MODE_NAVIGATOR = 'MODE_NAVIGATOR';
export const MODE_TRANSLATIONS = 'MODE_TRANSLATIONS';

const propTypes = {
  hideContent: bool,
};

const defaultProps = {
  hideContent: false,
};

const PostBuilderBar = () => {
  const {evolution, setEvolution, mode, setMode, setSelectedLanguage} =
    useContext(BuilderContext);

  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    setLanguages(findLanguages(evolution));
  }, []);

  const handleDeleteLanguage = (code) => {
    setEvolution({
      ...evolution,
      steps: evolution.steps.map((step) => {
        step.blocks = step.blocks.map((block) => {
          block.translations = block.translations?.filter(
            (t) => t.language !== code
          );
          block.options = block.options?.map((option) => {
            option.labels = option.labels.filter((l) => l.language !== code);
            return option;
          });
          return block;
        });
        return step;
      }),
    });
  };

  return (
    <div className="post-builder-bar-wrapper">
      <div className="post-builder-bar">
        <PostBlockManager
          trigger={
            <div
              className={classNames('bar-item', {
                selected: mode === MODE_ADD_BLOCK,
              })}>
              <i className="icon-plus-rounded" />
            </div>
          }
        />
        <Divider vertical />
        <div
          className={classNames('bar-item', {
            selected: mode === MODE_NAVIGATOR,
          })}
          onClick={() => {
            setSelectedLanguage(null);
            setMode(MODE_NAVIGATOR);
          }}>
          <i className="icon-pencil-edit-outline" />
        </div>
        <Divider vertical />
        <div
          className={classNames('bar-item', 'translations', {
            selected: mode === MODE_TRANSLATIONS,
          })}
          onClick={() => setMode(MODE_TRANSLATIONS)}>
          <i className="icon-translate" />
        </div>
      </div>
      {mode === MODE_TRANSLATIONS && (
        <PostLanguageSelector
          evolution={evolution}
          onRemoveLanguage={handleDeleteLanguage}
          languages={languages}
          setLanguages={setLanguages}
        />
      )}
    </div>
  );
};

PostBuilderBar.propTypes = propTypes;
PostBuilderBar.defaultProps = defaultProps;

export default PostBuilderBar;
