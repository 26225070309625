import classnames from 'classnames';
import Loader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import React from 'react';
import {Link} from 'react-router-dom';
import {ROUTE_SETTINGS_SUBSCRIPTION_PLAN} from 'router/routes.const';
import {subscriptionService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

class SettingsSubscriptionInvoices extends React.Component {
  logger = new Swaler('SettingsSubscriptionInvoices');

  constructor() {
    super();
    this.state = {
      isLoading: true,
      invoices: [],
    };
  }

  fetchInvoices = async () => {
    try {
      const invoices = await subscriptionService.getInvoices();

      this.setState({invoices});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      this.logger.error('Fetching invoices failed with error ', code);
      toastDanger([title, message], {actions});
      return err;
    }
  };

  render() {
    const {invoices, isLoading} = this.state;
    const classNames = classnames('s-settings-subscription-invoices', {
      'is-loading': isLoading === true,
      'fade-in': isLoading === false,
    });

    if (isLoading === true) {
      return (
        <div className={classNames}>
          <Loader></Loader>
        </div>
      );
    }
    return (
      <div className={classNames}>
        <div className="settings-body invoices">
          {invoices.length === 0 ? (
            <div className="no-invoices">
              No invoices yet{' '}
              <Link
                to={{
                  pathname: ROUTE_SETTINGS_SUBSCRIPTION_PLAN,
                  state: {doSettingsAnimation: false},
                }}>
                choose a plan
              </Link>
            </div>
          ) : (
            invoices.map((i) => (
              <div className="invoice">
                <div className="date">
                  {dayjs.unix(i.created).format('MMMM YY')}
                </div>
                <div className="price">
                  {i.price / 100}
                  {i.currency === 'eur' ? '€' : '$'}
                </div>
                <div className="link">
                  <a href={i.link} target="_blank" rel="noopener noreferrer">
                    view
                  </a>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    await this.fetchInvoices();
    this.setState({isLoading: false});
  }
}

export default SettingsSubscriptionInvoices;
