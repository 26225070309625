import Loader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useEffect} from 'react';
import {subscriptionService} from 'services';
import {Swaler} from 'swaler';

const logger = new Swaler('TrialLauncher');

export const TrialLauncher = ({plan, onSuccess}) => {
  const launchTrial = async () => {
    try {
      await subscriptionService.createOrUpdateStripeSubscription(
        {plan: plan.uid},
        {
          isTrial: true,
        }
      );
      onSuccess();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Launching trial failed with error ', code);
      toastDanger([title, message], {
        actions,
      });
    }
  };

  useEffect(() => {
    launchTrial();
  }, []);

  return (
    <div className="trial-launcher">
      <Loader dark width="18px"></Loader>
      Setting up your trial on {plan.title} 🎉
    </div>
  );
};
