import {Elements, useStripe} from '@stripe/react-stripe-js';
import {dataActions} from 'actions';
import classnames from 'classnames';
import commaNumber from 'comma-number';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {LineProgress} from 'components/Progress';
import {toastDanger} from 'components/Toaster';
import {stripePromised} from 'conf/stripe';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {useAddonCancel} from 'hooks/useAddonCancel';
import {useAddonCheckout} from 'hooks/useAddonCheckout';
import {useCancelPlan} from 'hooks/useCancelPlan';
import {useInvoiceUpcoming} from 'hooks/useInvoiceUpcoming';
import {usePlanUsage} from 'hooks/usePlanUsage';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import {ROUTE_SETTINGS_PLAN} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {dataSelector, generalSelector} from 'selectors';
import {subscriptionService} from 'services';
import {
  getDayToReset,
  isEligibleToTrial,
  isPaying,
  isTrying,
} from 'services/project';
import {
  ADDON_EXTRA_MAU_ID,
  ADDON_WHITE_LABEL_ID,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
  hasAddon,
  hasAnnualBilling,
  hasSaasMantraPlan,
} from 'services/subscription';
import {Swaler} from 'swaler';
import './_styles.scss';
import {BillingCardLaunchTrial} from './components/CardLaunchTrial';
import {BillingCardTrial} from './components/CardTrial';

const logger = new Swaler('SettingsBilling');

function SettingsBilling() {
  const history = useHistory();
  const dispatch = useDispatch();
  const stripe = useStripe();

  const {mau} = usePlanUsage();
  const {cancel, isCanceling} = useCancelPlan();
  const {
    fetch: fetchUpcoming,
    invoice,
    isFetching: isFetchingInvoice,
  } = useInvoiceUpcoming();
  const {cancel: cancelAddon} = useAddonCancel();
  const {start} = useAddonCheckout();

  const setSubscriptionPlans = (plans) =>
    dispatch(dataActions.setSubscriptionPlans(plans));
  const setSubscriptionAddons = (addons) =>
    dispatch(dataActions.setSubscriptionAddons(addons));

  const project = useSelector((state) => generalSelector.getProject(state));
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );
  const addons = useSelector((state) =>
    dataSelector.getSubscriptionsAddons(state)
  );

  const [creatingPortalUrl, setCreatingPortalUrl] = useState(false);

  const handleManageSubscription = async () => {
    setCreatingPortalUrl(true);

    const portalUrl = await subscriptionService.createStripePortalSession();

    if (portalUrl == null) {
      return;
    }
    window.location = portalUrl;
  };
  const fetchPlansAndAddons = async () => {
    const plans = await subscriptionService.getSubscriptionPlans();
    const addons = await subscriptionService.getSubscriptionAddons();

    setSubscriptionPlans(plans);
    setSubscriptionAddons(addons);
  };

  const {data: invoices = [], isLoading: isLoadingInvoices} = useQuery({
    queryKey: 'invoices',
    queryFn: () => subscriptionService.getInvoices(),
    refetchOnWindowFocus: false,
    onError: (err) => {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Fetching invoices failed with error ', code);
      toastDanger([title, message], {actions});
    },
  });

  useEffect(() => {
    if (isPaying()) {
      fetchUpcoming();
    }
  }, [hasAddon(ADDON_WHITE_LABEL_ID)]);
  useEffect(() => {
    if (plans.length === 0 || addons.length === 0) {
      fetchPlansAndAddons();
    }
  }, []);

  const plan = plans.find((p) => p.uid === subscription.plan);
  const addonWhiteLabel = addons.find((a) => a.uid === ADDON_WHITE_LABEL_ID);
  const dayToReset = getDayToReset();

  if (plans.length === 0 || addons.length === 0) {
    return (
      <SettingsBody className="s-settings-billing">
        <div className="title-3">Billing</div>
        <DefaultLoader width="12px" />
      </SettingsBody>
    );
  }
  return (
    <SettingsBody className="s-settings-billing">
      <div className="title-3">Billing</div>
      {isPaying() === false && isEligibleToTrial(PLAN_GROWTH_ID) === true && (
        <BillingCardLaunchTrial />
      )}
      {isTrying() === true && <BillingCardTrial />}
      <div className="settings-card card-current-plan">
        <div className="card-header">
          <div className="subtitle-3">Plan</div>
        </div>
        <div className="card-body">
          {isPaying() === false &&
            isTrying() === false &&
            hasSaasMantraPlan(project) === false && (
              <div className="billing-plan-details">
                <div className="left-side">
                  <div className="title-4">Free</div>
                  <div className="body-2 n-700">$0 /month</div>
                </div>
                <div className="right-side">
                  <Button
                    primary
                    onClick={() => history.push(ROUTE_SETTINGS_PLAN)}>
                    Explore plans
                  </Button>
                </div>
              </div>
            )}
          {(isTrying() === true ||
            isPaying() === true ||
            hasSaasMantraPlan(project)) && (
            <div
              className={classnames(
                'billing-plan-details',
                `plan-${subscription.plan.toLowerCase()}`
              )}>
              <div className="main-wrapper">
                <div className="left-side">
                  <div className="plan-logo-title-wrapper">
                    <div className="plan-logo"></div>
                    <div className="plan-title title-4">
                      {subscription.plan.toLowerCase()}
                    </div>
                  </div>
                  <div className="body-2 n-700">
                    {isTrying() === true
                      ? 'Free for 14 days'
                      : hasSaasMantraPlan(project) === true
                      ? 'Saas mantra applied'
                      : hasAnnualBilling()
                      ? `$${plan.prices[0] * 12} /year`
                      : `$${plan.prices[1]} /month`}
                  </div>
                </div>
                <div className="right-side">
                  <Button thin danger onClick={cancel} loading={isCanceling}>
                    Cancel {isTrying() === true ? 'trial' : 'plan'}
                  </Button>
                  <Button
                    thin
                    onClick={() => history.push(ROUTE_SETTINGS_PLAN)}>
                    Explore plans
                  </Button>
                </div>
              </div>
              {hasAddon(ADDON_WHITE_LABEL_ID) === true && (
                <div className="addon-white-label-wrapper">
                  <div className="left-side">
                    <div className="subtitle-3">+ Hide Jimo Label</div>
                    <div className="body-3 n-700">
                      {hasAnnualBilling()
                        ? `$${addonWhiteLabel.prices[0] * 12} / year`
                        : `$${addonWhiteLabel.prices[1]} / month`}
                    </div>
                  </div>
                  <div className="right-side">
                    <Button
                      danger
                      thin
                      onClick={() => cancelAddon(ADDON_WHITE_LABEL_ID)}>
                      Cancel add-on
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
          {isPaying() === true && (
            <div className="plan-details">
              <div className="item-detail">
                <div className="body-3 n-700">Billing period</div>
                <div className="subtitle-3">
                  {hasAnnualBilling() ? 'Yearly' : 'Monthly'}
                </div>
              </div>
              <div className="item-detail">
                <div className="body-3 n-700">Next payment</div>
                <div className="subtitle-3">
                  {isFetchingInvoice === true && <DefaultLoader width="10px" />}
                  {invoice != null
                    ? dayjs(subscription.stripeCurrentPeriodEnd * 1000).format(
                        'MMMM DD, YYYY'
                      )
                    : '-'}
                </div>
              </div>
              <div className="item-detail">
                <div className="body-3 n-700">Upcoming amount</div>
                <div className="subtitle-3">
                  {isFetchingInvoice === true && <DefaultLoader width="10px" />}
                  {invoice != null ? `$${invoice.billingAmount / 100}` : '-'}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {(isTrying() || isPaying()) && (
        <div
          className={classnames('settings-card card-mau-usage', {
            exceeded: mau.exceeded,
            percent90: mau.percentage >= 90 && mau.percentage < 100,
          })}>
          <div className="card-header">
            <div className="left-side">
              <div className="card-title subtitle-3">
                {mau.exceeded === true ? (
                  <>
                    <i className="isax isax-danger5"></i>
                    You have reached your MAU limit
                  </>
                ) : mau.percentage >= 90 ? (
                  <>
                    <i className="isax isax-danger5"></i>
                    You have almost reached your MAU limit
                  </>
                ) : (
                  'MAU for the current month'
                )}
              </div>
              <div className="body-3 n-700">
                If you reach the limit, new active users will not view your
                experiences. <br /> Users active this month can still view them.
              </div>
            </div>
            <div className="right-side">
              <>
                <Button
                  thin
                  onClick={() => history.push(ROUTE_SETTINGS_PLAN)}
                  primary={mau.percentage > 90 && dayToReset > 4}>
                  Upgrade plan
                </Button>
                <Button
                  thin
                  primary={mau.percentage > 90 && dayToReset <= 4}
                  onClick={() => start(ADDON_EXTRA_MAU_ID)}>
                  Increase limit
                </Button>
              </>
            </div>
          </div>
          <div className="card-body">
            <LineProgress lineHeight={17} value={mau.percentage} />
            <div className="additional-wrapper">
              <div className="body-3 n-700">
                {commaNumber(mau.current > mau.max ? mau.max : mau.current)} /{' '}
                {commaNumber(mau.max)} MAU
                {hasAddon(ADDON_EXTRA_MAU_ID) && (
                  <span className="n-600">
                    {' '}
                    (+{subscription.extraJimers} MAU until{' '}
                    {dayjs().add(dayToReset, 'day').format('MMM DD, YYYY')})
                  </span>
                )}
              </div>

              <div className="body-3 n-700">
                {dayToReset === 0
                  ? 'Today'
                  : `Reset in ${dayToReset}
                ${dayToReset > 1 ? ' days' : ' day'}`}
              </div>
            </div>
          </div>
        </div>
      )}
      {subscription.paymentMethodBrand != null && (
        <div className="settings-card card-saved-payment-method">
          <div className="card-header">
            <div className="left-side">
              <div className="subtitle-3">Payment method</div>
              <div className="body-3 n-700">
                <i className="isax isax-card"></i>{' '}
                <span className="payment-method-brand">
                  {subscription.paymentMethodBrand}
                </span>
                ending with {subscription.paymentMethodLast4}
              </div>
            </div>
            <div className="right-side">
              <Button
                thin
                iconLeft="isax isax-edit-2"
                loading={creatingPortalUrl}
                onClick={handleManageSubscription}>
                Update
              </Button>
            </div>
          </div>
        </div>
      )}
      {hasSaasMantraPlan(project) === false && (
        <div className="settings-card card-invoices">
          <div className="card-header">
            <div className="subtitle-3">Invoices</div>
          </div>
          <div className="card-body">
            {isLoadingInvoices === true && <DefaultLoader width="20px" />}
            {isLoadingInvoices === false && invoices.length === 0 && (
              <div className="invoices-empty body-3 n-700">No history yet</div>
            )}
            {isLoadingInvoices === false && invoices.length > 0 && (
              <div className="invoice-list">
                <div className="list-header">
                  <div className="body-3 n-500">Bill date</div>
                  <div className="body-3 n-500">Amount</div>
                  <div className="body-3 n-500">Plan</div>
                  <div className="body-3 n-500">Invoices</div>
                </div>
                {invoices.map((i) => (
                  <div className="item-invoice">
                    <div className="invoice-date body-2">
                      {dayjs.unix(i.created).format('MMMM DD, YYYY')}
                    </div>
                    <div className="invoice-price body-2">
                      {i.currency !== 'eur' && '$'}
                      {i.price / 100}
                      {i.currency === 'eur' && '€'}
                    </div>
                    <div>
                      {i.products
                        .map((p) =>
                          p === PLAN_STARTUP_ID
                            ? `${
                                i.isTrial === true
                                  ? 'Startup Free Trial'
                                  : i.isAnnual === true
                                  ? 'Startup Yearly Plan'
                                  : 'Startup Monthly Plan'
                              }`
                            : p === PLAN_GROWTH_ID
                            ? `${
                                i.isTrial === true
                                  ? 'Growth Free Trial'
                                  : i.isAnnual === true
                                  ? 'Growth Yearly Plan'
                                  : 'Growth Monthly Plan'
                              }`
                            : p === PLAN_SCALE_ID
                            ? `${
                                i.isTrial === true
                                  ? 'Scale Free Trial'
                                  : i.isAnnual === true
                                  ? 'Scale Yearly Plan'
                                  : 'Scale Monthly Plan'
                              }`
                            : p === ADDON_WHITE_LABEL_ID
                            ? 'White Label'
                            : p.includes('Addon')
                            ? p
                            : ''
                        )
                        .join(', ')}
                    </div>
                    <div className="invoice-link body-2">
                      <a
                        href={i.link}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Button thin>View Invoice</Button>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </SettingsBody>
  );
}

export default function SettingsBillingWithStripe(props) {
  return (
    <Elements stripe={stripePromised}>
      <SettingsBilling {...props} />
    </Elements>
  );
}
