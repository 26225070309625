import classNames from 'classnames';
import Alert from 'components/Alert';
import Divider from 'components/Divider';
import SelectGroup from 'components/Select';
import Toggle from 'components/Toggle';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {MODE_TRIGGERS} from 'scenes/PokeBuilder/components/PokeBuilderSidebar';
import {
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_HINT,
  F_BOOST_SLOT_POP_IN,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOP_BAR,
  F_OPTION_DISMISS_ON_CROSS_CLICK,
  F_OPTION_DISMISS_ON_OUTSIDE_CLICK,
  F_OPTION_DISMISS_ON_OUTSIDE_HOVER,
  F_OPTION_DOT_SHOW_ON_HOVER,
  F_OPTION_PROGRESS_ON_TARGET_CLICK,
  F_OPTION_SHOW_ON_TARGET_HOVER,
  F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
  F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND,
} from 'services/evolution';
import {
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_SLIDER,
  F_STEP_DISCOVERY_STEP_REQUIRED,
  F_STEP_HEIGHT_CUSTOM,
  getDefaultStepStyle,
} from 'services/steps';
import RadioGroup from '../../../../../../components/RadioGroup';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import Background from '../../components/modals/Background';
import GroupAnimations, {
  animationsIn,
  animationsOut,
} from '../../components/modals/GroupAnimations';
import Height, {
  HEIGHT_TYPE_AUTO,
  HEIGHT_TYPE_CUSTOM,
} from '../../components/modals/Height';
import Position, {positionsArr} from '../../components/modals/Position';
import Shadow from '../../components/modals/Shadow';
import Width from '../../components/modals/Width';
import './_Styles.scss';

const DISMISS_CROSS = 'cross';
const DISMISS_OUTSIDE = 'outside';
const DISMISS_HOVER_OUTSIDE = 'hover-outside';

const dismissOptions = [
  {label: 'Click the cross', value: [DISMISS_CROSS]},
  {label: 'Click outside', value: [DISMISS_OUTSIDE]},
  {
    label: 'Click the cross or outside',
    value: [DISMISS_CROSS, DISMISS_OUTSIDE],
  },
  {label: "Can't be dismissed", value: []},
  {label: 'Hover outside', value: [DISMISS_HOVER_OUTSIDE]},
];

const showOptions = [
  {label: 'On click', value: 'click'},
  {label: 'On hover', value: 'hover'},
  {label: 'On target hover', value: 'target-hover'},
];

const animationElementsOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const bannerAutoPushElementDownOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const skipIfTargetNotFoundOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const progressOptions = [
  {
    label: 'Next button only',
    value: 'next-button',
    for: [
      F_BOOST_SLOT_SNIPPET,
      F_BOOST_SLOT_POP_IN,
      F_BOOST_SLOT_DOT,
      F_BOOST_SLOT_TOOLTIP,
    ],
  },
  {
    label: 'Target element click',
    value: 'pointed-element',
    for: [F_BOOST_SLOT_TOOLTIP, F_BOOST_SLOT_DOT],
  },
];

export const layoutOptions = [
  {label: <i className="icon-slot-snippet" />, value: F_BOOST_SLOT_SNIPPET},
  {label: <i className="icon-slot-pop-in" />, value: F_BOOST_SLOT_POP_IN},
  {label: <i className="icon-slot-dot" />, value: F_BOOST_SLOT_DOT},
  {label: <i className="icon-slot-tooltip" />, value: F_BOOST_SLOT_TOOLTIP},
];

const Group = () => {
  const {
    evolution: parentEvolution,
    setEvolution: setParentEvolution,
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
    selectedStep,
    updateStep,
    isInApp,
    setIsEditingTargetElement,
    setMode,
  } = useContext(BuilderContext);

  const handleThemeStyleChange = (changes) => {
    if (evolution.uid === parentEvolution.uid) {
      setParentEvolution({
        ...parentEvolution,
        style: {...style, ...changes},
      });
    } else {
      setParentEvolution({
        ...parentEvolution,
        tourSteps: parentEvolution.tourSteps.map((tourStep) => {
          if (tourStep.uid === evolution.uid) {
            return {
              ...tourStep,
              style: {...tourStep.style, ...changes},
            };
          }
          return tourStep;
        }),
      });
    }
  };

  const {type} = parentEvolution;

  const isBannerStep = hasFlag(F_BOOST_SLOT_TOP_BAR, evolution.boostFlags);
  const isHotspotStep = hasFlag(F_BOOST_SLOT_DOT, evolution.boostFlags);
  const isTooltipStep = hasFlag(F_BOOST_SLOT_TOOLTIP, evolution.boostFlags);
  const isHintStep = hasFlag(F_BOOST_SLOT_HINT, evolution.boostFlags);
  const isSnippetStep = hasFlag(F_BOOST_SLOT_SNIPPET, evolution.boostFlags);

  const stepStyle = selectedStep?.style || getDefaultStepStyle();
  const flags = selectedStep?.stepFlags || 0;
  const style = evolution?.style || getDefaultStepStyle();

  const backgroundColor =
    style.background?.type === 'gradient'
      ? `linear-gradient(213.36deg, ${style.background.primaryColor}, ${style.background.secondaryColor})`
      : style.background?.primaryColor || '#FFFFFF';

  const animationIn =
    style.animationIn != null
      ? animationsIn.find((a) => a.value === style.animationIn)
      : null;
  const animationOut =
    style.animationOut != null
      ? animationsOut.find((a) => a.value === style.animationOut)
      : null;

  const hasInteractivityBlock =
    selectedStep?.blocks.find(
      (b) =>
        [
          BLOCK_TYPE_CHOICE,
          BLOCK_TYPE_NPS,
          BLOCK_TYPE_OPEN_QUESTION,
          BLOCK_TYPE_OPINION,
          BLOCK_TYPE_SLIDER,
        ].includes(b.type) === true
    ) != null;

  const selectedAlignment = positionsArr.find(
    (o) => o.value === evolution?.boostedPositionFlags
  );

  return (
    <div className="block-settings group">
      <>
        {isBannerStep === false && isHintStep === false && (
          <>
            <div className="section">
              <div className="section-title">General</div>
              <div className="section-content">
                {type === EVOLUTION_TYPE_SURVEY && hasInteractivityBlock && (
                  <div className="section-item">
                    <div className="section-item-title">Required</div>
                    <div className="section-item-content">
                      <Toggle
                        checked={hasFlag(F_STEP_DISCOVERY_STEP_REQUIRED, flags)}
                        onChange={({target}) => {
                          if (target.checked) {
                            updateStep(selectedStep?.uid, {
                              stepFlags: addFlag(
                                F_STEP_DISCOVERY_STEP_REQUIRED,
                                flags
                              ),
                            });
                          } else {
                            updateStep(selectedStep?.uid, {
                              stepFlags: removeFlag(
                                F_STEP_DISCOVERY_STEP_REQUIRED,
                                flags
                              ),
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="section-item">
                  <div className="section-item-title">Type</div>
                  <div className="section-item-content">
                    <RadioGroup
                      value={evolution.boostFlags}
                      options={layoutOptions}
                      onSelect={(value) => {
                        setEvolution({
                          ...evolution,
                          boostFlags: value,
                          ...(value !== evolution.boostFlags
                            ? value === F_BOOST_SLOT_DOT
                              ? {boostedPositionFlags: 1}
                              : value === F_BOOST_SLOT_TOOLTIP
                              ? {boostedPositionFlags: 0}
                              : {}
                            : {}),
                        });
                      }}
                    />
                  </div>
                </div>
                {isSnippetStep === true && (
                  <div className="section-item">
                    <div className="section-item-title">Position</div>
                    <div className="section-item-content snippet-position-picker">
                      <div className="alignment-figure-wrapper">
                        <div className="alignment-figure">
                          <div className="alignment-label">
                            {selectedAlignment != null ? (
                              <>Always show from {selectedAlignment.position}</>
                            ) : (
                              <>Select a position</>
                            )}
                          </div>
                          {positionsArr.map((o) => {
                            if (o.value >= 128 || o.value === 8) {
                              return <></>;
                            }
                            return (
                              <div
                                className={classNames(
                                  'position-dot',
                                  o.position,
                                  {
                                    selected:
                                      evolution?.boostedPositionFlags ===
                                      o.value,
                                  }
                                )}
                                onClick={() =>
                                  setEvolution({
                                    ...evolution,
                                    boostedPositionFlags: o.value,
                                  })
                                }
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {(isHotspotStep || isTooltipStep) && (
                  <div className="section-item">
                    <div className="section-item-title">Position</div>
                    <div className="section-item-content position-picker">
                      {isInApp === true ? (
                        <ClickableInput
                          value={
                            evolution.querySelectorManual?.elementText !=
                              null ||
                            evolution.querySelectorManual?.cssSelector != null
                              ? 'Manual selector'
                              : evolution.boostedQueryselector ||
                                'Select an element'
                          }
                          leftLabel={
                            <div className="position-icon-wrapper">
                              <i className="icon-target" />
                            </div>
                          }
                          onClick={() => {
                            setIsEditingTargetElement(true);
                          }}
                        />
                      ) : (
                        <PopupSetting
                          trigger={
                            <ClickableInput
                              value={
                                evolution.querySelectorManual?.elementText !=
                                  null ||
                                evolution.querySelectorManual?.cssSelector !=
                                  null
                                  ? 'Manual selector'
                                  : evolution.boostedQueryselector ||
                                    'Select an element'
                              }
                              leftLabel={
                                <div className="position-icon-wrapper">
                                  <i className="icon-target" />
                                </div>
                              }
                            />
                          }
                          title="Position"
                          large
                          content={
                            <Position
                              evolution={evolution}
                              setEvolution={setEvolution}
                            />
                          }
                          invalid={
                            evolution.querySelectorManual == null &&
                            !evolution.boostedQueryselector
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {type === EVOLUTION_TYPE_TOUR &&
                  (isTooltipStep === true || isHotspotStep === true) && (
                    <div className="section-item">
                      <div
                        className="section-item-title"
                        style={{maxWidth: '40%'}}>
                        Skip if target element not found
                      </div>
                      <div className="section-item-content">
                        <RadioGroup
                          value={hasFlag(
                            F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND,
                            evolution.optionsFlags
                          )}
                          options={skipIfTargetNotFoundOptions}
                          onSelect={(value) =>
                            setEvolution({
                              ...evolution,
                              optionsFlags:
                                value === true
                                  ? addFlag(
                                      F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND,
                                      evolution.optionsFlags
                                    )
                                  : removeFlag(
                                      F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND,
                                      evolution.optionsFlags
                                    ),
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <Divider />
          </>
        )}

        <div className="section">
          <div className="section-title">Behavior</div>
          <div className="section-content">
            {(isHotspotStep === true || isHintStep === true) && (
              <div className="section-item">
                <div className="section-item-title">To show</div>
                <div className="section-item-content">
                  <SelectGroup
                    isSearchable={false}
                    options={showOptions.filter((o) => {
                      if (isHotspotStep === true) {
                        return o.value !== 'target-hover';
                      }
                      return true;
                    })}
                    value={
                      hasFlag(
                        F_OPTION_DOT_SHOW_ON_HOVER,
                        evolution.optionsFlags
                      )
                        ? showOptions.find((o) => o.value === 'hover')
                        : hasFlag(
                            F_OPTION_SHOW_ON_TARGET_HOVER,
                            evolution.optionsFlags
                          )
                        ? showOptions.find((o) => o.value === 'target-hover')
                        : showOptions.find((o) => o.value === 'click')
                    }
                    onChange={(option) => {
                      let updatedFlags = evolution.optionsFlags;

                      if (option.value === 'click') {
                        updatedFlags = removeFlag(
                          F_OPTION_DOT_SHOW_ON_HOVER,
                          updatedFlags
                        );
                        updatedFlags = removeFlag(
                          F_OPTION_SHOW_ON_TARGET_HOVER,
                          updatedFlags
                        );
                      } else if (option.value === 'hover') {
                        updatedFlags = addFlag(
                          F_OPTION_DOT_SHOW_ON_HOVER,
                          evolution.optionsFlags
                        );
                        updatedFlags = removeFlag(
                          F_OPTION_SHOW_ON_TARGET_HOVER,
                          updatedFlags
                        );
                      } else {
                        updatedFlags = removeFlag(
                          F_OPTION_DOT_SHOW_ON_HOVER,
                          evolution.optionsFlags
                        );
                        updatedFlags = addFlag(
                          F_OPTION_SHOW_ON_TARGET_HOVER,
                          updatedFlags
                        );
                      }

                      setEvolution({
                        ...evolution,
                        optionsFlags: updatedFlags,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            <div className="section-item">
              <div className="section-item-title">To dismiss</div>
              <div className="section-item-content">
                <SelectGroup
                  isSearchable={false}
                  className="dismiss-option-select"
                  classNamePrefix="dismiss-option-select-component"
                  options={dismissOptions.filter((o) => {
                    if (isBannerStep === true) {
                      return (
                        o.value.length === 0 ||
                        (o.value.length === 1 &&
                          o.value.includes(DISMISS_CROSS))
                      );
                    }
                    if (isHintStep !== true) {
                      if (o.value.includes(DISMISS_HOVER_OUTSIDE) === true) {
                        return false;
                      }
                    }
                    return true;
                  })}
                  value={(() => {
                    const hasCrossDismiss = hasFlag(
                      F_OPTION_DISMISS_ON_CROSS_CLICK,
                      evolution.optionsFlags
                    );
                    const hasOutsideDismiss = hasFlag(
                      F_OPTION_DISMISS_ON_OUTSIDE_CLICK,
                      evolution.optionsFlags
                    );
                    const hasHoverOutsideDismiss = hasFlag(
                      F_OPTION_DISMISS_ON_OUTSIDE_HOVER,
                      evolution.optionsFlags
                    );

                    const value = []
                      .concat(hasCrossDismiss === true ? DISMISS_CROSS : [])
                      .concat(hasOutsideDismiss === true ? DISMISS_OUTSIDE : [])
                      .concat(
                        hasHoverOutsideDismiss === true
                          ? DISMISS_HOVER_OUTSIDE
                          : []
                      );

                    return dismissOptions.find(
                      (o) => JSON.stringify(o.value) === JSON.stringify(value)
                    );
                  })()}
                  onChange={(option) => {
                    let optionsFlags = evolution.optionsFlags;

                    if (option.value.includes(DISMISS_CROSS) === true) {
                      optionsFlags = addFlag(
                        F_OPTION_DISMISS_ON_CROSS_CLICK,
                        optionsFlags
                      );
                    } else {
                      optionsFlags = removeFlag(
                        F_OPTION_DISMISS_ON_CROSS_CLICK,
                        optionsFlags
                      );
                    }
                    if (option.value.includes(DISMISS_OUTSIDE) === true) {
                      optionsFlags = addFlag(
                        F_OPTION_DISMISS_ON_OUTSIDE_CLICK,
                        optionsFlags
                      );
                    } else {
                      optionsFlags = removeFlag(
                        F_OPTION_DISMISS_ON_OUTSIDE_CLICK,
                        optionsFlags
                      );
                    }
                    if (option.value.includes(DISMISS_HOVER_OUTSIDE) === true) {
                      optionsFlags = addFlag(
                        F_OPTION_DISMISS_ON_OUTSIDE_HOVER,
                        optionsFlags
                      );
                    } else {
                      optionsFlags = removeFlag(
                        F_OPTION_DISMISS_ON_OUTSIDE_HOVER,
                        optionsFlags
                      );
                    }
                    setEvolution({
                      ...evolution,
                      optionsFlags,
                    });
                  }}
                />
              </div>
            </div>
            {isBannerStep === true && (
              <div className="section-content">
                <div className="section-item">
                  <div className="section-item-title">
                    Move all fixed elements down
                  </div>
                  <div className="section-item-content">
                    <RadioGroup
                      value={
                        !hasFlag(
                          F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
                          evolution.optionsFlags
                        )
                      }
                      options={bannerAutoPushElementDownOptions}
                      onSelect={(value) => {
                        const updatedOptionsFlags =
                          !value === true
                            ? addFlag(
                                F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
                                evolution.optionsFlags
                              )
                            : removeFlag(
                                F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
                                evolution.optionsFlags
                              );

                        setEvolution({
                          ...evolution,
                          optionsFlags: updatedOptionsFlags,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {type === EVOLUTION_TYPE_TOUR && (
              <>
                {(isHotspotStep === true || isTooltipStep === true) && (
                  <div className="section-item">
                    <div className="section-item-title">To progress</div>
                    <div className="section-item-content">
                      <SelectGroup
                        isSearchable={false}
                        className="progress-option-select"
                        options={progressOptions.filter((options) =>
                          options.for.includes(evolution.boostFlags)
                        )}
                        value={
                          hasFlag(
                            F_OPTION_PROGRESS_ON_TARGET_CLICK,
                            evolution.optionsFlags
                          )
                            ? progressOptions.find(
                                (o) => o.value === 'pointed-element'
                              )
                            : progressOptions.find(
                                (o) => o.value === 'next-button'
                              )
                        }
                        onChange={(option) => {
                          if (option.value === 'next-button') {
                            setEvolution({
                              ...evolution,
                              optionsFlags: removeFlag(
                                F_OPTION_PROGRESS_ON_TARGET_CLICK,
                                evolution.optionsFlags
                              ),
                            });
                          } else {
                            setEvolution({
                              ...evolution,
                              optionsFlags: addFlag(
                                F_OPTION_PROGRESS_ON_TARGET_CLICK,
                                evolution.optionsFlags
                              ),
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="section-item triggers">
                  <div className="section-item-title">Triggers</div>
                  <div className="section-item-content">
                    <ClickableInput
                      className={classNames('triggers-btn', {
                        'with-triggers': selectedStep?.triggers?.length > 0,
                      })}
                      onClick={() => setMode(MODE_TRIGGERS)}
                      leftLabel={<i className="isax isax-hierarchy-3" />}
                      value={
                        selectedStep?.triggers?.length > 0
                          ? `${selectedStep?.triggers?.length} trigger${
                              selectedStep?.triggers?.length > 1 ? 's' : ''
                            }`
                          : null
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Divider />
      </>

      <div className="section">
        <div className="section-title">Step style</div>
        <div className="section-content">
          {isBannerStep !== true && (
            <div className="section-item">
              <div className="section-item-title">Width</div>
              <div className="section-item-content">
                <PopupSetting
                  trigger={
                    <ClickableInput
                      value={
                        stepStyle.width != null
                          ? `${stepStyle.width}px`
                          : 'Default'
                      }
                    />
                  }
                  title="Width"
                  content={
                    <Width
                      value={stepStyle.width}
                      onChange={(value) =>
                        updateStep(selectedStep?.uid, {
                          style: {...stepStyle, width: value},
                        })
                      }
                    />
                  }
                />
              </div>
            </div>
          )}

          <div className="section-item">
            <div className="section-item-title">Height</div>
            <div className="section-item-content">
              <PopupSetting
                trigger={
                  <ClickableInput
                    value={
                      hasFlag(F_STEP_HEIGHT_CUSTOM, flags) === true
                        ? `${stepStyle.height}px`
                        : 'Auto'
                    }
                  />
                }
                title="Height"
                content={
                  <Height
                    height={stepStyle.height}
                    type={
                      hasFlag(F_STEP_HEIGHT_CUSTOM, flags) === true
                        ? HEIGHT_TYPE_CUSTOM
                        : HEIGHT_TYPE_AUTO
                    }
                    onChangeType={(type) => {
                      if (type === HEIGHT_TYPE_AUTO) {
                        updateStep(selectedStep?.uid, {
                          stepFlags: removeFlag(F_STEP_HEIGHT_CUSTOM, flags),
                        });
                      } else {
                        updateStep(selectedStep?.uid, {
                          stepFlags: addFlag(F_STEP_HEIGHT_CUSTOM, flags),
                        });
                      }
                    }}
                    onChange={({height}) =>
                      updateStep(selectedStep?.uid, {
                        style: {...stepStyle, height},
                      })
                    }
                    min={isBannerStep === true ? 30 : undefined}
                    max={isBannerStep === true ? 100 : undefined}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      {isHintStep === true && (
        <>
          <div className="section">
            <div className="section-title">Tooltip positioning</div>
            <div className="section-content">
              <div className="alignment-figure-wrapper">
                <div className="alignment-figure">
                  <div className="alignment-label">
                    {selectedAlignment != null ? (
                      <>Always show from {selectedAlignment.position}</>
                    ) : (
                      <>Select a position</>
                    )}
                  </div>
                  {positionsArr.map((o) => {
                    return (
                      <div
                        className={classNames('position-dot', o.position, {
                          selected: evolution?.boostedPositionFlags === o.value,
                        })}
                        onClick={() =>
                          setEvolution({
                            ...evolution,
                            boostedPositionFlags: o.value,
                          })
                        }
                      />
                    );
                  })}
                </div>
              </div>
              <div className="section-item">
                <div className="section-item-title">Gap</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={style.hintOffset}
                    min={0}
                    max={50}
                    onChange={(value) =>
                      handleThemeStyleChange({hintOffset: value})
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <Divider />
        </>
      )}
      <div className="section">
        <div className="section-title">Style</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Shadow</div>
            <div className="section-item-content shadow-picker">
              <PopupSetting
                trigger={
                  <ClickableInput
                    value={style.shadow?.color}
                    leftLabel={
                      <span
                        className="preview-shadow-color"
                        style={{background: style.shadow?.color || '#FFFFFF'}}
                      />
                    }
                    erasable={true}
                    onErase={() =>
                      handleThemeStyleChange({
                        shadow: {color: null, opacity: 0, x: 0, y: 0, blur: 0},
                      })
                    }
                  />
                }
                title="Shadow"
                content={
                  <Shadow
                    value={style.shadow}
                    onChange={(value) =>
                      handleThemeStyleChange({shadow: value})
                    }
                  />
                }
              />
            </div>
          </div>
          {isBannerStep === true && (
            <div className="section-item">
              <div className="section-item-title">Margin</div>
              <div className="section-item-content">
                <PixelPicker
                  value={style.margin}
                  min={0}
                  max={24}
                  onChange={(value) => handleThemeStyleChange({margin: value})}
                />
              </div>
            </div>
          )}
          <div className="section-item">
            <div className="section-item-title">Radius</div>
            <div className="section-item-content">
              <PixelPicker
                value={style.borderRadius}
                min={0}
                max={50}
                onChange={(value) =>
                  handleThemeStyleChange({borderRadius: value})
                }
                radius
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Background</div>
            <div className="section-item-content background-picker">
              <PopupSetting
                trigger={
                  <ClickableInput
                    value={
                      style.background?.type === 'gradient'
                        ? 'Gradient'
                        : style.background?.primaryColor
                    }
                    leftLabel={
                      <span
                        className="preview-background-color"
                        style={{
                          background: backgroundColor,
                          ...(style.background?.type === 'gradient'
                            ? {border: 0}
                            : {}),
                        }}
                      />
                    }
                  />
                }
                title="Background"
                content={
                  <Background
                    value={style.background}
                    onChange={(value) =>
                      handleThemeStyleChange({background: value})
                    }
                  />
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Border</div>
            <div className="section-item-content">
              <ColorPickerInput
                title="Border color"
                value={style.borderColor || null}
                onChange={(value) =>
                  handleThemeStyleChange({borderColor: value})
                }
                erasable
              />
            </div>
          </div>
          {isBannerStep !== true && isHintStep !== true && (
            <div className="section-item">
              <div className="section-item-title">Overlay</div>
              <div className="section-item-content">
                <ColorPickerInput
                  title="Overlay"
                  value={style.overlay}
                  onChange={(value) => handleThemeStyleChange({overlay: value})}
                  erasable
                  inputProps={{
                    onClick: () => {
                      if (style.overlay == null) {
                        handleThemeStyleChange({overlay: '#00000029'});
                      }
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Divider />
      {isHintStep === false && (
        <div className="section">
          <div className="section-title">Animations</div>
          {isInApp === true && (
            <Alert className="alert-animation-in-app-builder" info>
              You can preview animations by launching a preview from the{' '}
              <i className="icon-play-o"></i> button at the top right.
            </Alert>
          )}
          <div className="section-content">
            <div className="section-item">
              <div className="section-item-title">Elements</div>
              <div className="section-item-content">
                <RadioGroup
                  value={style.withElementsAnimations || false}
                  options={animationElementsOptions}
                  onSelect={(value) =>
                    setEvolution({
                      ...evolution,
                      style: {...style, withElementsAnimations: value},
                    })
                  }
                />
              </div>
            </div>
            <div className="section-item">
              <div className="section-item-title">In</div>
              <div className="section-item-content animation-in-picker">
                <PopupSetting
                  trigger={
                    <ClickableInput
                      value={animationIn?.name}
                      leftLabel={
                        <div className="animation-icon-wrapper">
                          {animationIn?.icon != null ? (
                            <i className={animationIn.icon} />
                          ) : (
                            <span
                              className="animation-icon"
                              style={{background: '#FFFFFF'}}
                            />
                          )}
                        </div>
                      }
                      erasable={animationIn != null}
                      onErase={() =>
                        setEvolution({
                          ...evolution,
                          style: {...style, animationIn: null},
                        })
                      }
                    />
                  }
                  title="Animations-in"
                  content={
                    <GroupAnimations
                      value={style.animationIn}
                      onChange={(value) =>
                        setEvolution({
                          ...evolution,
                          style: {...style, animationIn: value},
                        })
                      }
                      type="in"
                    />
                  }
                />
              </div>
            </div>
            <div className="section-item">
              <div className="section-item-title">Out</div>
              <div className="section-item-content animation-out-picker">
                <PopupSetting
                  trigger={
                    <ClickableInput
                      value={animationOut?.name}
                      leftLabel={
                        <div className="animation-icon-wrapper">
                          {animationOut?.icon != null ? (
                            <i className={animationOut.icon} />
                          ) : (
                            <span
                              className="animation-icon"
                              style={{background: '#FFFFFF'}}
                            />
                          )}
                        </div>
                      }
                      erasable={animationOut != null}
                      onErase={() =>
                        setEvolution({
                          ...evolution,
                          style: {...style, animationOut: null},
                        })
                      }
                    />
                  }
                  title="Animations-out"
                  content={
                    <GroupAnimations
                      value={style.animationOut}
                      onChange={(value) =>
                        setEvolution({
                          ...evolution,
                          style: {...style, animationOut: value},
                        })
                      }
                      type="out"
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Group;
