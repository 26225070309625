import Button from 'components/Button';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_INTEGRATIONS_HUBSPOT} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {hubspotService} from 'services';
import Logo from '../imgs/hubspot.svg';
import './_Styles.scss';
import HubspotSettings from './components/HubspotSettings';

export const HubspotLogo = () => {
  return <img className="hubspot-logo" src={Logo} alt="hubspot-logo" />;
};

const Hubspot = () => {
  const history = useHistory();

  const project = useSelector((state) => generalSelector.getProject());
  const {hubspot} = project;
  const hubspotActive = hubspot != null && hubspot.portalId != null;

  const [isHubspotLoading, setIsHubspotLoading] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const handleAddToHubspot = async () => {
    setIsHubspotLoading(true);
    const url = await hubspotService.generateUrl(project.uid);
    window.open(url, '_self', 'noopener,noreferrer');
  };

  return (
    <div className="settings-card integration-hubspot">
      <div className="logo-action-wrapper">
        <img className="logo" src={Logo} alt="hubspot-logo" />
        {!hubspotActive ? (
          <Button
            primary
            thin
            iconRight="icon-chevron-right"
            loading={isHubspotLoading}
            onClick={handleAddToHubspot}>
            Connect
          </Button>
        ) : (
          <Button
            thin
            iconLeft="isax isax-setting-2"
            onClick={() => history.push(ROUTE_SETTINGS_INTEGRATIONS_HUBSPOT)}>
            Configure
          </Button>
        )}
      </div>
      <div className="integration-details-wrapper">
        <div className="subtitle-3 integration-title">
          Hubspot{' '}
          {hubspotActive === true && (
            <div className="label-connected body-4">
              <i className="isax isax-tick-circle"></i> Connected
            </div>
          )}
        </div>
        <div className="body-3">Connect Jimo to your HubSpot workspace</div>
      </div>
      {isSettingsOpen === true && (
        <HubspotSettings
          isOpen={isSettingsOpen}
          onRequestClose={() => setIsSettingsOpen(false)}
        />
      )}
    </div>
  );
};

export default Hubspot;
