import classnames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {Menu, MenuItem} from 'components/Menu';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {array, bool, func, object} from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import './_Styles.scss';

export const AVAILABLE_LANGUAGES = [
  {code: 'FR', label: 'french', icon: 'flag-icon-fr'},
  {code: 'EN', label: 'english', icon: 'flag-icon-gb'},
  {code: 'ES', label: 'spanish', icon: 'flag-icon-es'},
  {code: 'PT', label: 'portuguese', icon: 'flag-icon-pt'},
  {code: 'IT', label: 'italian', icon: 'flag-icon-it'},
  {code: 'DE', label: 'german', icon: 'flag-icon-de'},
  {code: 'JP', label: 'japanese', icon: 'flag-icon-jp'},
  {code: 'ZH', label: 'chinese', icon: 'flag-icon-cn'},
  {code: 'SV', label: 'swedish', icon: 'flag-icon-se'},
  {code: 'NL', label: 'dutch', icon: 'flag-icon-nl'},
  {code: 'FI', label: 'Finnish', icon: 'flag-icon-fi'},
  {code: 'RU', label: 'Russian', icon: 'flag-icon-ru'},
  {code: 'CS', label: 'Czech', icon: 'flag-icon-cz'},
  {code: 'HU', label: 'Hungarian', icon: 'flag-icon-hu'},
  {code: 'PL', label: 'Polish', icon: 'flag-icon-pl'},
  {code: 'DA', label: 'Danish', icon: 'flag-icon-dk'},
  {code: 'NO', label: 'Norwegian', icon: 'flag-icon-no'},
  {code: 'RO', label: 'Romanian', icon: 'flag-icon-ro'},
];

const propTypes = {
  evolution: object,
  disableAddButton: bool,
  onAddLanguage: func.isRequired,
  onSelectLanguage: func.isRequired,
  onRemoveLanguage: func.isRequired,
  defaultLanguages: array,
};

const defaultProps = {
  evolution: null,
  disableAddButton: false,
  defaultLanguages: [],
};

const EvolutionEditorLanguage = ({
  evolution,
  disableAddButton = false,
  onAddLanguage,
  onSelectLanguage,
  onRemoveLanguage,
  defaultLanguages = [],
}) => {
  const {update} = useUpdateSubscription();
  const projectSubscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const [languages, setLanguages] = useState([]);
  const [activeLanguage, setActiveLanguage] = useState(null);

  const refDropdown = useRef();

  useEffect(() => {
    let languages = [];

    if (evolution != null) {
      languages = evolution.steps.reduce((languages, step) => {
        step.texts.forEach((t) =>
          languages.indexOf(t.language) < 0
            ? (languages = languages.concat(t.language))
            : null
        );
        step.prototypes.forEach((p) =>
          p.steps.forEach((ps) =>
            ps.texts.forEach((pst) =>
              languages.indexOf(pst.language) < 0
                ? (languages = languages.concat(pst.language))
                : null
            )
          )
        );
        return languages;
      }, []);
    }
    setLanguages(
      languages.map((languageCode) =>
        AVAILABLE_LANGUAGES.find((l) => l.code === languageCode)
      )
    );
  }, []);

  useEffect(() => {
    if (defaultLanguages?.length > 0) {
      setLanguages(
        defaultLanguages?.map((languageCode) =>
          AVAILABLE_LANGUAGES.find((l) => l.code === languageCode)
        )
      );
    }
  }, [defaultLanguages]);

  const handleAddLanguage = (language) => {
    refDropdown.current.close();

    if (
      [PLAN_STARTUP_ID, PLAN_SCALE_ID, PLAN_GROWTH_ID].includes(
        projectSubscription.plan
      ) === false
    ) {
      return update({planId: PLAN_STARTUP_ID});
    }
    setLanguages(languages.concat(language));
    handleSelectLanguage(language.code);
    onAddLanguage(language.code);
  };
  const handleDelete = (e, languageCode) => {
    e.stopPropagation();
    setLanguages(languages.filter((l) => l.code !== languageCode));
    handleSelectLanguage(null);
    onRemoveLanguage(languageCode);
  };
  const handleSelectLanguage = (languageCode) => {
    setActiveLanguage(languageCode);
    onSelectLanguage(languageCode);
  };

  return (
    <div className="evolution-editor-language">
      <div className="language-list">
        <div
          onClick={() => handleSelectLanguage(null)}
          className={classnames('language', {
            active: activeLanguage == null,
          })}>
          Default
        </div>
        {languages.map((l) => {
          return (
            <div
              key={l.code}
              onClick={() => handleSelectLanguage(l.code)}
              className={classnames('language', {
                active: activeLanguage === l.code,
              })}>
              {l.label}
              {activeLanguage === l.code && (
                <i
                  className="icon-trash"
                  onClick={(e) => handleDelete(e, l.code)}></i>
              )}
            </div>
          );
        })}
      </div>
      {disableAddButton === false && (
        <Dropdown
          className="dropdown-add-languages"
          innerRef={refDropdown}
          position={'bottom'}
          offsetY={7}
          trigger={
            <Button type="button" iconLeft="icon-plus" thin>
              Add a translation
            </Button>
          }>
          <Menu>
            {AVAILABLE_LANGUAGES.filter(
              (al) => languages.find((l) => l.code === al.code) == null
            ).map((l) => (
              <MenuItem
                className="dropdown-language-item"
                onClick={() => handleAddLanguage(l)}
                icon={`flag-icon ${l.icon}`}
                key={l.code}>
                {l.label}
              </MenuItem>
            ))}
          </Menu>
        </Dropdown>
      )}
    </div>
  );
};

EvolutionEditorLanguage.propTypes = propTypes;
EvolutionEditorLanguage.defaultProps = defaultProps;

export default EvolutionEditorLanguage;
