import {toastDanger} from 'components/Toaster';
import {PermissionsSettings} from 'constants/permissions';
import {GlobalContext} from 'contextes/Global';
import {errorHelpers} from 'helpers';
import {hasPermissions} from 'helpers/permission';
import {useContext, useState} from 'react';
import {subscriptionService} from 'services';
import {getCurrentAddons, hasAnnualBilling} from 'services/subscription';
import {Swaler} from 'swaler';
import {useUpdatePlan} from './useUpdatePlan';

const logger = new Swaler('useDowngradePlan');

export const useDowngradePlan = ({
  onSuccess = () => {},
  onError = () => {},
} = {}) => {
  const {setModalPlanDowngrade, setModalWelcomePlan} =
    useContext(GlobalContext);

  const {update} = useUpdatePlan();

  const [isDowngrading, setIsDowngrading] = useState(false);

  async function downgrade(planId) {
    setModalPlanDowngrade({
      planId,
      onCancel: () => {
        setModalPlanDowngrade(null);
      },
      onSubmit: async () => {
        await doDowngrade(planId).then(() => {
          setModalPlanDowngrade(null);
          setModalWelcomePlan({planId, refreshProjectAfterClose: true});
        });
      },
    });
  }

  async function doDowngrade(planId) {
    const canDo = hasPermissions([PermissionsSettings.BILLING_ACCESS]);

    if (canDo === false) {
      return toastDanger([
        'Permission denied',
        "You don't have the permissions to downgrade the plan. For more information, please contact your project administrator.",
      ]);
    }
    setIsDowngrading(true);
    try {
      await subscriptionService.createOrUpdateStripeSubscription({
        plan: planId,
        addons: getCurrentAddons(),
        modeAnnual: hasAnnualBilling(),
      });
      await update(planId);
      onSuccess();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsDowngrading(false);
      logger.error('Downgrading plan failed with error ', code);
      toastDanger([title, message], {
        actions,
      });
      throw new Error('DOWNGRADING_FAILED');
    } finally {
      setIsDowngrading(false);
    }
  }

  return {
    downgrade,
    isDowngrading,
  };
};
