import {dataActions} from 'actions';
import classnames from 'classnames';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import {toastDanger} from 'components/Toaster';
import Toggle from 'components/Toggle';
import {
  PermissionsEvent,
  PermissionsPeople,
  PermissionsPoke,
  PermissionsPost,
  PermissionsRequest,
  PermissionsSettings,
  PermissionsSuccessTracker,
} from 'constants/permissions';
import {errorHelpers} from 'helpers';
import {useRef, useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {useDispatch, useSelector} from 'react-redux';
import {dataSelector} from 'selectors';
import {rolesService} from 'services';
import {
  PROJECT_ROLE_ADMIN,
  PROJECT_ROLE_EDITOR,
  PROJECT_ROLE_MEMBER,
} from 'services/project';
import {getRoleNameBySlug} from 'services/roles';
import {Swaler} from 'swaler';
import './_roles-manager.scss';
import iconAdmin from './imgs/icon-admin.svg';
import iconContributor from './imgs/icon-contributor.svg';
import iconEditor from './imgs/icon-editor.svg';
import iconViewer from './imgs/icon-viewer.svg';

const logger = new Swaler('RolesManager');

const Permissions = [
  {
    title: 'Experiences (in-app interactions)',
    permission: PermissionsPoke.ACCESS,
    permissions: [
      {
        icon: 'isax isax-edit-2',
        title: 'Create & Edit',
        description: 'Allow member to create & edit experiences',
        permission: PermissionsPoke.CREATE_EDIT,
      },
      {
        icon: 'isax isax-send-2',
        title: 'Publish',
        description: 'Allow member to publish an experience on your app',
        permission: PermissionsPoke.PUBLISH,
      },
      {
        icon: 'isax isax-trash',
        title: 'Delete',
        description: 'Allow member to create & edit experiences',
        permission: PermissionsPoke.DELETE,
      },
      {
        icon: 'isax isax-chart-2',
        title: 'Analytics',
        description: 'Allow member to access analytics of an experience',
        permission: PermissionsPoke.ANALYTICS,
      },
    ],
  },
  {
    title: 'Posts (changelog)',
    permission: PermissionsPost.ACCESS,
    permissions: [
      {
        icon: 'isax isax-edit-2',
        title: 'Create & Edit',
        description: 'Allow member to create & edit posts',
        permission: PermissionsPost.CREATE_EDIT,
      },
      {
        icon: 'isax isax-send-2',
        title: 'Publish',
        description: 'Allow member to publish a post in your changelog',
        permission: PermissionsPost.PUBLISH,
      },
      {
        icon: 'isax isax-trash',
        title: 'Delete',
        description: 'Allow member to create & edit posts',
        permission: PermissionsPost.DELETE,
      },
      {
        icon: 'isax isax-chart-2',
        title: 'Analytics',
        description: 'Access analytics of a post',
        permission: PermissionsPost.ANALYTICS,
      },
    ],
  },
  {
    title: 'Success Tracker',
    permission: PermissionsSuccessTracker.ACCESS,
    permissions: [
      {
        icon: 'isax isax-edit-2',
        title: 'Create & Edit',
        description: 'Allow member to create & edit trackers',
        permission: PermissionsSuccessTracker.CREATE_EDIT,
      },
      {
        icon: 'isax isax-trash',
        title: 'Delete tracker',
        description: 'Allow member to delete a tracker',
        permission: PermissionsSuccessTracker.DELETE,
      },
    ],
  },
  {
    title: 'Events',
    permission: PermissionsEvent.ACCESS,
    permissions: [
      {
        icon: 'isax isax-edit-2',
        title: 'Create & Edit',
        description: 'Allow member to create & edit events',
        permission: PermissionsEvent.CREATE_EDIT,
      },
      {
        icon: 'isax isax-trash',
        title: 'Delete event',
        description: 'Allow member to delete an event',
        permission: PermissionsEvent.DELETE,
      },
    ],
  },
  {
    title: 'Users & Segments',
    permission: PermissionsPeople.ACCESS,
    permissions: [
      {
        icon: 'isax isax-edit-2',
        title: 'Edit user',
        description: 'Allow member to assign tags to a user',
        permission: PermissionsPeople.USER_EDIT,
      },
      {
        icon: 'isax isax-trash',
        title: 'Delete user',
        description: 'Allow member to delete a user',
        permission: PermissionsPeople.USER_DELETE,
      },
      {
        icon: 'isax isax-edit-2',
        title: 'Create & Edit segments',
        description: 'Allow member to create and edit segments',
        permission: PermissionsPeople.SEGMENT_CREATE_EDIT,
      },
      {
        icon: 'isax isax-trash',
        title: 'Delete segments',
        description: 'Allow member to delete segments',
        permission: PermissionsPeople.SEGMENT_DELETE,
      },
    ],
  },
  {
    title: 'Requests',
    permission: PermissionsRequest.ACCESS,
    permissions: [
      {
        icon: 'isax isax-edit-2',
        title: 'Create & Edit requests',
        description: 'Allow member to create and edit requests',
        permission: PermissionsRequest.CREATE_EDIT,
      },
      {
        icon: 'isax isax-trash',
        title: 'Delete requests',
        description: 'Allow member to delete requests',
        permission: PermissionsRequest.DELETE,
      },
    ],
  },
  {
    title: 'Settings',
    permission: PermissionsSettings.ACCESS,
    permissions: [
      {
        icon: 'isax isax-setting-2',
        title: 'General',

        description:
          'Allow member to update the general settings of the project',
        permission: PermissionsSettings.GENERAL_ACCESS,
      },
      {
        icon: 'isax isax-brush-4',
        title: 'Theme',
        description: 'Allow member to create, edit or delete themes',
        permission: PermissionsSettings.THEME_ACCESS,
      },
      {
        icon: 'isax isax-directbox-notif',
        title: 'Changelog',
        description: 'Allow member to update the settings of the changelog',
        permission: PermissionsSettings.CHANGELOG_ACCESS,
      },
      {
        icon: 'isax isax-simcard-1',
        title: 'Installation',
        description:
          'Allow member to update the installation settings of the project',
        permission: PermissionsSettings.INSTALLATION_ACCESS,
      },
      {
        icon: 'isax isax-mouse-square',
        title: 'Rate limiting',
        description: 'Allow member to update settings of the rate limiting',
        permission: PermissionsSettings.POKE_CONTROL_ACCESS,
      },
      {
        icon: 'isax isax-people',
        title: 'Team',
        description: 'Allow member to access team settings',
        permission: PermissionsSettings.TEAM_ACCESS,
      },
      {
        icon: 'isax isax-profile-add',
        title: 'Invite team member',
        description: 'Allow member to invite a new team member',
        permission: PermissionsSettings.TEAM_MEMBER_INVITE,
      },
      {
        icon: 'isax isax-profile-delete',
        title: 'Remove team member',
        description: 'Allow member to remove an existing team member',
        permission: PermissionsSettings.TEAM_MEMBER_DELETE,
      },
      {
        icon: 'isax isax-user-octagon',
        title: 'Update team member role',
        description: 'Allow member to change the role of a team member',
        permission: PermissionsSettings.TEAM_MEMBER_ROLE_CHANGE,
      },
      {
        icon: 'isax isax-user-cirlce-add',
        title: 'Manage roles',
        description: 'Allow member to create, edit or delete team roles',
        permission: PermissionsSettings.TEAM_UPDATE_ROLE,
      },
      {
        icon: 'isax isax-dollar-circle',
        title: 'Plan',
        description: 'Allow member to manage plan',
        permission: PermissionsSettings.BILLING_ACCESS,
      },
      {
        icon: 'isax isax-card',
        title: 'Billing',
        description:
          'Allow member to manage billing, invoices and payment methods',
        permission: PermissionsSettings.BILLING_ACCESS,
      },
      {
        icon: 'isax isax-element-plus',
        title: 'Integrations',
        description: 'Allow member to manage integrations',
        permission: PermissionsSettings.INTEGRATION_ACCESS,
      },
    ],
  },
];

export const RolesManager = ({members}) => {
  const dispatch = useDispatch();

  const setCustomRoles = (roles) => dispatch(dataActions.setCustomRoles(roles));

  const customRoles = useSelector((state) =>
    dataSelector.getCustomRoles(state)
  );
  const builtInRoles = useSelector((state) =>
    dataSelector.getBuiltInRoles(state)
  );

  const [role, setRole] = useState(null);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);

  const handleCreateRole = async () => {
    setCreating(true);
    try {
      const role = await rolesService.createRole({
        name: `Role ${customRoles.length}`,
      });

      setCustomRoles(customRoles.concat(role));
      setRole(role);
      setCreating(false);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setCreating(false);
      logger.error('Creating role failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };

  const handleDeleteRole = async (roleId) => {
    setDeleting(true);
    try {
      await rolesService.deleteRole(roleId);

      setCustomRoles(customRoles.filter((r) => r.uid !== roleId));
      setRole(null);
      setDeleting(false);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setDeleting(false);
      logger.error('Deleting role failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };

  const handleUpdateRole = async (updatedRole = role) => {
    setUpdating(true);
    try {
      await rolesService.updateRole(updatedRole);
      setCustomRoles(
        customRoles.map((r) => (r.uid === updatedRole.uid ? updatedRole : r))
      );
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating role failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setUpdating(false);
    }
  };

  const handleUpdatePermission = (updatedPermissions) => {
    const updatedRole = {
      ...role,
      permissions: updatedPermissions,
    };

    setRole(updatedRole);
    handleUpdateRole(updatedRole);
  };

  const isBuiltInRole = role != null && typeof role === 'string';

  return (
    <div className="settings-role-manager">
      <div className="settings-card card-default-roles">
        <div className="card-header">
          <div className="subtitle-3">Default roles</div>
          <div className="body-3 n-700">
            Default roles can't be edited, you can create a custom role
            according to your need
          </div>
        </div>
        <div className="list-roles">
          {builtInRoles.map((r) => (
            <div
              className={classnames(
                'item-role built-in',
                `role-${r.slug.toLowerCase()}`
              )}
              onClick={() => setRole(r.slug)}>
              <img
                src={
                  r.slug === PROJECT_ROLE_ADMIN
                    ? iconAdmin
                    : r.slug === PROJECT_ROLE_MEMBER
                    ? iconContributor
                    : r.slug === PROJECT_ROLE_EDITOR
                    ? iconEditor
                    : iconViewer
                }
                alt=""
              />
              <div className="role-name-wrapper subtitle-3">
                {getRoleNameBySlug(r.slug)}
                <span className="n-600">
                  {members.filter((m) => m.role === r.slug).length}
                </span>
              </div>
              <div className="role-description body-3 n-700">
                {r.slug === PROJECT_ROLE_ADMIN
                  ? 'Full access over every areas of this project'
                  : r.slug === PROJECT_ROLE_MEMBER
                  ? 'Access to all, except project settings'
                  : r.slug === PROJECT_ROLE_EDITOR
                  ? 'Limited to editing experiences only'
                  : 'Limited to access experience analytics only'}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="settings-card card-custom-roles">
        <div className="card-header">
          <div className="card-header-main">
            <div className="subtitle-3">Custom roles</div>
            <div className="body-3 n-700">
              Define unique roles with specific permissions tailored to your
              team's needs
            </div>
          </div>
          <Button
            iconLeft="icon-plus"
            thin
            onClick={handleCreateRole}
            loading={creating}>
            New role
          </Button>
        </div>
        {customRoles.length > 0 && (
          <div className="list-roles">
            {customRoles.map((r) => {
              const roleMembers = members.filter(
                (m) => m.customRole != null && m.customRole.uid === r.uid
              );
              const permissionsSections = [
                PermissionsPoke.ACCESS,
                PermissionsPost.ACCESS,
                PermissionsRequest.ACCESS,
                PermissionsPeople.ACCESS,
                PermissionsSettings.ACCESS,
                PermissionsSuccessTracker.ACCESS,
                PermissionsEvent.ACCESS,
              ];
              const permissionsSectionsEnabled = r.permissions
                .filter((p) => permissionsSections.includes(p))
                .map((p) => {
                  if (p === PermissionsPeople.ACCESS) return 'Users & Segments';
                  if (p === PermissionsPoke.ACCESS) return 'Experiences';
                  if (p === PermissionsPost.ACCESS) return 'Changelog';
                  if (p === PermissionsSuccessTracker.ACCESS)
                    return 'Success Tracker';
                  if (p === PermissionsEvent.ACCESS) return 'Events';
                  if (p === PermissionsRequest.ACCESS) return 'Requests';
                  if (p === PermissionsSettings.ACCESS) return 'Settings';
                });

              return (
                <div
                  className={classnames('item-role', {
                    active: role != null && role.uid === r.uid,
                  })}
                  onClick={() => setRole(r)}>
                  <div className="role-members-avatars">
                    {roleMembers.length === 0 ? (
                      <div className="item-avatar empty"></div>
                    ) : (
                      <>
                        {roleMembers.slice(0, 3).map((member) => (
                          <div
                            className="item-avatar"
                            style={
                              member.user.avatarUrl != null
                                ? {
                                    backgroundImage: `url(${member.user.avatarUrl})`,
                                  }
                                : {
                                    backgroundColor: 'var(--b-300)',
                                  }
                            }>
                            {member.user.avatarUrl == null &&
                              member.user.username[0].toUpperCase()}
                          </div>
                        ))}
                        {roleMembers.length > 3 && (
                          <div className="item-avatar more body-3 n-700">
                            +{roleMembers.length - 3}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="role-name-wrapper subtitle-3">
                    {r.name}
                    <span className="n-600">{roleMembers.length}</span>
                  </div>
                  <div className="body-3 n-700">
                    {permissionsSectionsEnabled.length ===
                    permissionsSections.length
                      ? 'Access to Experiences, Changelog, Success Tracker, Events, Users & Segments, and Settings'
                      : permissionsSectionsEnabled.length === 0
                      ? 'This role do not have have permissions'
                      : permissionsSectionsEnabled.length === 1
                      ? `Access to ${permissionsSectionsEnabled[0]}`
                      : permissionsSectionsEnabled.length === 2
                      ? `Access to ${permissionsSectionsEnabled[0]} and ${permissionsSectionsEnabled[1]}`
                      : `Access to ${permissionsSectionsEnabled
                          .slice(
                            0,
                            permissionsSectionsEnabled.length === 1
                              ? 1
                              : permissionsSectionsEnabled.length - 1
                          )
                          .join(', ')
                          .concat(' and ')
                          .concat(
                            permissionsSectionsEnabled
                              .slice(
                                permissionsSectionsEnabled.length - 1,
                                permissionsSectionsEnabled.length
                              )
                              .join(',')
                          )}`}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <RoleDrawer
        isOpen={role != null}
        role={role}
        isBuiltInRole={isBuiltInRole}
        deleting={deleting}
        updating={updating}
        members={members}
        builtInRoles={builtInRoles}
        customRoles={customRoles}
        onRequestClose={() => setRole(null)}
        onRoleChange={setRole}
        onRoleUpdate={handleUpdateRole}
        onDeleteRole={handleDeleteRole}
        onPermissionUpdate={handleUpdatePermission}
      />
    </div>
  );
};

const RoleDrawer = ({
  role,
  isBuiltInRole,
  deleting,
  updating,
  members,
  builtInRoles,
  customRoles,
  onRoleChange,
  onRoleUpdate,
  onDeleteRole,
  onPermissionUpdate,
  ...props
}) => {
  const refInputName = useRef(null);

  const permissions =
    role == null
      ? []
      : isBuiltInRole === true
      ? builtInRoles.find((r) => r.slug === role).permissions
      : role.permissions;
  const membersForRole =
    role != null
      ? members.filter((m) =>
          isBuiltInRole === true
            ? m.role === role
            : m.customRole?.uid === role.uid
        )
      : [];

  return (
    <Modal
      {...props}
      overlayClassName="modal-role-permissions-overlay"
      className={classnames('modal-role-permissions', {
        'empty-state': role == null,
      })}>
      {role == null ? (
        <p>Select a role to edit its permissions</p>
      ) : (
        <>
          <div className="role-title">
            <span className="subtitle-2">Role settings</span>
            {isBuiltInRole === false && (
              <Button
                danger
                thin
                loading={deleting}
                iconLeft="icon-trash"
                disabled={membersForRole.length > 0}
                onClick={() => onDeleteRole(role.uid)}>
                {membersForRole.length > 0 ? 'This role has members' : 'Delete'}
              </Button>
            )}
          </div>
          <div className="role-scroll-wrapper">
            {isBuiltInRole === true && (
              <Alert icon="isax isax-info-circle" title="Built-in role">
                A built-in role cannot be updated. Instead, you can go for a
                custom role and setup it as you wish.
              </Alert>
            )}
            <div className="role-name-wrapper">
              <Input
                ref={refInputName}
                legend={
                  <>
                    Name
                    <small
                      className={classnames('n-500', {
                        visible:
                          isBuiltInRole === false &&
                          customRoles.find((r) => r.uid === role.uid)?.name !==
                            role.name,
                      })}>
                      press enter to save
                    </small>
                  </>
                }
                placeholder="Name of the role"
                value={
                  isBuiltInRole === true ? getRoleNameBySlug(role) : role.name
                }
                onChange={({target}) =>
                  onRoleChange({
                    ...role,
                    name: target.value,
                  })
                }
                onBlur={() => {
                  onRoleUpdate();
                }}
                onPressEnter={() => {
                  refInputName?.current.blur();
                }}
                disabled={isBuiltInRole}
              />
            </div>
            {Permissions.map((section) => (
              <div
                className={classnames('permissions-section', {
                  'built-in': isBuiltInRole,
                })}>
                <div
                  className={classnames('permission-header subtitle-3', {
                    disabled:
                      permissions.includes(section.permission) === false,
                  })}>
                  {section.title}
                  <Button
                    disabled={isBuiltInRole}
                    tertiary
                    thin
                    onClick={() => {
                      const sectionPermissions = Permissions.find(
                        (p) => p.permission === section.permission
                      ).permissions.map((p) => p.permission);
                      const permissionsUpdated = permissions
                        .concat(
                          permissions.includes(section.permission) === false
                            ? section.permission
                            : []
                        )
                        .concat(
                          sectionPermissions.filter(
                            (sp) => permissions.includes(sp) === false
                          )
                        );

                      onPermissionUpdate(permissionsUpdated);
                    }}>
                    Allow all
                  </Button>
                </div>
                <AnimateHeight height="auto" className="permissions-list">
                  {section.permissions.map((p) => (
                    <div
                      className={classnames('permission-item', {
                        'is-disabled': isBuiltInRole,
                      })}
                      onClick={() => {
                        if (isBuiltInRole === true) return;
                        if (permissions.includes(p.permission) === false) {
                          return onPermissionUpdate(
                            permissions
                              .concat(p.permission)
                              .concat(
                                permissions.includes(section.permission) ===
                                  false
                                  ? section.permission
                                  : []
                              )
                          );
                        } else {
                          let updatedPermissions = permissions.filter(
                            (ep) => ep !== p.permission
                          );

                          if (
                            updatedPermissions.some((p) =>
                              section.permissions
                                .map((sp) => sp.permission)
                                .includes(p)
                            ) === false
                          ) {
                            updatedPermissions = updatedPermissions.filter(
                              (ep) => ep !== section.permission
                            );
                          }
                          return onPermissionUpdate(updatedPermissions);
                        }
                      }}>
                      <div className="permission-infos">
                        <div className="icon-wrapper">
                          <i className={p.icon}></i>
                        </div>
                        <div className="name-wrapper">
                          <span className="subtitle-3">{p.title}</span>
                          <span className="body-3 n-700">{p.description}</span>
                        </div>
                      </div>
                      <div className="permission-actions">
                        <Toggle
                          checked={permissions.includes(p.permission)}
                          disabled={isBuiltInRole}
                        />
                      </div>
                    </div>
                  ))}
                </AnimateHeight>
              </div>
            ))}
          </div>
        </>
      )}
    </Modal>
  );
};
