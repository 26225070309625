import React, {useContext} from 'react';
import './_Styles.scss';
import AudienceSection from 'scenes/PokeAudience/components/AudienceSection';
import {AudienceContext} from 'scenes/PokeAudience';
import classNames from 'classnames';
import {
  ACTIVE_OPERATOR_CUSTOM,
  ACTIVE_OPERATOR_EVERYWHERE,
  ACTIVE_OPERATOR_SINGLE_URL,
} from 'scenes/EmbeddedElementSelectorBuilder';
import InputGroup from 'components/Input';
import {PathSelector} from 'scenes/PokeAudience/components/PathSelector';
import Divider from 'components/Divider';
import {InteractiveOption} from 'scenes/Builder/component/InteractiveOption';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {
  F_DEVICE_DESKTOP,
  F_DEVICE_MOBILE,
  F_DEVICE_TABLET,
  TRIGGER_TYPE_EVENT,
  TRIGGER_TYPE_LEAVE_PAGE,
} from 'services/evolution';
import {EVENT_TYPE_HOVER} from 'services/event';
import Tooltip from 'components/Tooltip';
import {number} from 'prop-types';

export const getWhereTitle = (evolution) => {
  const deviceFlags = evolution?.deviceFlags || 0;
  const boostedActiveOperator = evolution?.boostedActiveOperator;

  const activeDevices = [];
  let activePages = 'Everywhere';

  if (hasFlag(F_DEVICE_DESKTOP, deviceFlags)) {
    activeDevices.push('Desktop');
  }
  if (hasFlag(F_DEVICE_TABLET, deviceFlags)) {
    activeDevices.push('Tablet');
  }
  if (hasFlag(F_DEVICE_MOBILE, deviceFlags)) {
    activeDevices.push('Mobile');
  }

  if (boostedActiveOperator === ACTIVE_OPERATOR_EVERYWHERE) {
    activePages = 'Every page';
  } else if (boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL) {
    activePages = evolution.boostedActiveUrl || 'Missing url';
  } else if (boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM) {
    activePages = 'On filtered URLs';
  }

  return `On ${activeDevices.join(', ')} - ${activePages}`;
};

const propTypes = {
  index: number.isRequired,
};

const defaultProps = {};

const Where = ({index}) => {
  const {evolution, setEvolution} = useContext(AudienceContext);

  const isOnPageLeaving = evolution?.triggerType === TRIGGER_TYPE_LEAVE_PAGE;
  const isHoverEvent =
    evolution?.triggerType === TRIGGER_TYPE_EVENT &&
    (evolution?.event?.type === EVENT_TYPE_HOVER ||
      evolution?.onTheFlyEvent?.type === EVENT_TYPE_HOVER);

  return (
    <AudienceSection
      className="where"
      index={index}
      title={`Where: ${getWhereTitle(evolution)}`}>
      <div className="sub-section-title">
        Select devices to show this experience on
      </div>
      <div className="device-selection-wrapper">
        <InteractiveOption
          withCheckBox
          active={hasFlag(F_DEVICE_DESKTOP, evolution.deviceFlags)}
          icon="icon-desktop"
          title="Desktop"
          onClick={() => {
            if (evolution.deviceFlags === F_DEVICE_DESKTOP) {
              return;
            }
            setEvolution({
              ...evolution,
              deviceFlags: hasFlag(F_DEVICE_DESKTOP, evolution.deviceFlags)
                ? removeFlag(F_DEVICE_DESKTOP, evolution.deviceFlags)
                : addFlag(F_DEVICE_DESKTOP, evolution.deviceFlags),
            });
          }}
        />
        <Tooltip
          className="tooltip-warning-device"
          offsetY={4}
          disabled={isOnPageLeaving !== true && isHoverEvent !== true}
          trigger={
            <div>
              <InteractiveOption
                withCheckBox
                active={
                  hasFlag(F_DEVICE_TABLET, evolution.deviceFlags) &&
                  !isOnPageLeaving &&
                  !isHoverEvent
                }
                disabled={isOnPageLeaving || isHoverEvent}
                icon="icon-tablet"
                title="Tablet"
                onClick={() => {
                  if (evolution.deviceFlags === F_DEVICE_TABLET) {
                    return;
                  }
                  setEvolution({
                    ...evolution,
                    deviceFlags: hasFlag(F_DEVICE_TABLET, evolution.deviceFlags)
                      ? removeFlag(F_DEVICE_TABLET, evolution.deviceFlags)
                      : addFlag(F_DEVICE_TABLET, evolution.deviceFlags),
                  });
                }}
              />
            </div>
          }>
          Tablet and mobile devices are not supported with the selected trigger
        </Tooltip>
        <Tooltip
          className="tooltip-warning-device"
          offsetY={4}
          disabled={isOnPageLeaving !== true && isHoverEvent !== true}
          trigger={
            <div>
              <InteractiveOption
                withCheckBox
                active={
                  hasFlag(F_DEVICE_MOBILE, evolution.deviceFlags) &&
                  !isOnPageLeaving &&
                  !isHoverEvent
                }
                disabled={isOnPageLeaving || isHoverEvent}
                icon="icon-mobile"
                title="Mobile"
                onClick={() => {
                  if (evolution.deviceFlags === F_DEVICE_MOBILE) {
                    return;
                  }
                  setEvolution({
                    ...evolution,
                    deviceFlags: hasFlag(F_DEVICE_MOBILE, evolution.deviceFlags)
                      ? removeFlag(F_DEVICE_MOBILE, evolution.deviceFlags)
                      : addFlag(F_DEVICE_MOBILE, evolution.deviceFlags),
                  });
                }}
              />
            </div>
          }>
          Tablet and mobile devices are not supported with the selected trigger
        </Tooltip>
      </div>
      <Divider />
      <div className="sub-section-title">
        Select the pages where this experience will be displayed
      </div>
      <div className="url-selection-wrapper">
        <div className="url-selection-options">
          <div
            onClick={() =>
              setEvolution({
                ...evolution,
                boostedActiveOperator: ACTIVE_OPERATOR_EVERYWHERE,
              })
            }
            className={classNames('selection-option', {
              selected:
                evolution.boostedActiveOperator === ACTIVE_OPERATOR_EVERYWHERE,
            })}>
            <i className="icon-browser" />
            Everywhere
          </div>
          <div
            onClick={() =>
              setEvolution({
                ...evolution,
                boostedActiveOperator: ACTIVE_OPERATOR_SINGLE_URL,
              })
            }
            className={classNames('selection-option', {
              selected:
                evolution.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL,
            })}>
            <i className="icon-link" />
            On a single URL
          </div>
          <div
            onClick={() =>
              setEvolution({
                ...evolution,
                boostedActiveOperator: ACTIVE_OPERATOR_CUSTOM,
              })
            }
            className={classNames('selection-option', {
              selected:
                evolution.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM ||
                evolution.boostedActiveOperator == null,
            })}>
            <i className="icon-filter" />
            On filtered URLs
          </div>
        </div>
      </div>
      {evolution.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL && (
        <InputGroup
          required
          className="active-url-input"
          name="value"
          type="string"
          placeholder="https://www.yourawesomecompany.com/"
          value={evolution.boostedActiveUrl}
          onChange={(e) => {
            setEvolution({...evolution, boostedActiveUrl: e.target.value});
          }}
        />
      )}
      {(evolution.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM ||
        evolution.boostedActiveOperator == null) && (
        <PathSelector evolution={evolution} setEvolution={setEvolution} />
      )}
    </AudienceSection>
  );
};

Where.propTypes = propTypes;
Where.defaultProps = defaultProps;

export default Where;
