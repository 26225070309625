import {generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import Input from 'components/Input';
import SelectGroup from 'components/Select';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {addFlag} from 'helpers/bitwise';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  ROUTE_GET_STARTED,
  ROUTE_HOME,
  ROUTE_JOIN,
  ROUTE_ONBOARDING_DETAILS_JOIN,
  ROUTE_ONBOARDING_DETAILS_STEP_2_1,
} from 'router/routes.const';
import {generalSelector, miscSelector} from 'selectors';
import {meService, projectService} from 'services';
import {
  ME_F_ONBOARDING_COMPLETED,
  ME_F_ONBOARDING_DONE_STEP_1_2,
} from 'services/me';
import {Swaler} from 'swaler';
import './step-1-2.scss';

const COMPANY_SIZE_OPTIONS = [
  {
    label: '1',
    value: 'SIZE_1',
  },
  {
    label: '2-5',
    value: 'SIZE_2',
  },
  {
    label: '6-20',
    value: 'SIZE_3',
  },
  {
    label: '21-50',
    value: 'SIZE_4',
  },
  {
    label: '51-100',
    value: 'SIZE_5',
  },
  {
    label: '101-500',
    value: 'SIZE_6',
  },
  {
    label: '500+',
    value: 'SIZE_7',
  },
];

const logger = new Swaler('Onboarding/1-2');

export const OnboardingDetailsStep12 = ({
  triggerOnboardingEnd,
  triggerSquareAnimationOut,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const refInputWebsite = useRef();

  const general = useSelector((state) => state.general);
  const user = useSelector((state) => generalSelector.getUser(state));
  const invitationCode = useSelector((state) =>
    miscSelector.getInvitationCode(state)
  );
  const projects = useSelector((state) => generalSelector.getProjects(state));

  const uptUser = (updatedUser) =>
    dispatch(generalActions.uptUser(updatedUser));

  const [playAnimationOut, setPlayAnimationOut] = useState(false);
  const [company, setCompany] = useState(user.companyName ?? '');
  const [website, setWebsite] = useState(user.companyWebsite ?? '');
  const [size, setSize] = useState(user.companySize);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    try {
      let onboardingFlags = addFlag(
        ME_F_ONBOARDING_DONE_STEP_1_2,
        user.onboardingFlags
      );
      const updatedUser = await meService.updateMe({
        companyName: company,
        companySize: size.value,
        companyWebsite: website,
      });
      const projectsToJoin = await projectService.getProjectsToJoin();

      await meService.updateOnboardingFlag(onboardingFlags);
      uptUser({...updatedUser, onboardingFlags});
      setPlayAnimationOut(true);
      setTimeout(async () => {
        if (invitationCode != null || projects.length > 0) {
          onboardingFlags = addFlag(ME_F_ONBOARDING_COMPLETED, onboardingFlags);
          await meService.updateOnboardingFlag(onboardingFlags);
          uptUser({...updatedUser, onboardingFlags});
          triggerSquareAnimationOut(true);
          return triggerOnboardingEnd(() => {
            if (invitationCode != null) {
              history.push(ROUTE_JOIN(invitationCode));
            } else {
              history.push(ROUTE_HOME);
            }
          });
        }
        if (general.project != null) {
          triggerSquareAnimationOut(true);
          return triggerOnboardingEnd(() => {
            history.push(ROUTE_GET_STARTED);
          });
        } else {
          if (projectsToJoin.length > 0) {
            history.push(ROUTE_ONBOARDING_DETAILS_JOIN);
          } else {
            history.push(ROUTE_ONBOARDING_DETAILS_STEP_2_1);
          }
        }
      }, 1200);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating user failed with error ', code);
      toastDanger([title, message], {actions});
      setIsSubmitting(false);
    }
  };

  const updateWebsiteCustomValidity = () => {
    refInputWebsite.current.setCustomValidity(
      'Please enter a URL including https, like so https://mywebsite.com'
    );
  };

  useEffect(() => {
    if (refInputWebsite.current != null) {
      refInputWebsite.current.addEventListener(
        'invalid',
        updateWebsiteCustomValidity,
        false
      );
    }
  }, [refInputWebsite.current]);

  const canContinue = company.length > 0 && website.length > 0 && size != null;

  return (
    <div
      className={classnames(
        's-onboarding-details-step s-onboarding-details-step-1-2',
        {
          'is-exiting': playAnimationOut,
        }
      )}>
      <h1>
        Great! <br /> Tell us about your company
      </h1>
      <p>It will take you less than 2 minutes</p>

      <form onSubmit={handleSubmit}>
        <div className="form-label">Where do you work?</div>
        <div className="inputs-wrapper">
          <Input
            placeholder="Company"
            required
            value={company}
            onChange={({target}) => setCompany(target.value)}></Input>
        </div>
        <div className="form-label">What's your company website ?</div>
        <div className="inputs-wrapper">
          <Input
            ref={refInputWebsite}
            type="url"
            placeholder="https://your-company.com"
            required
            value={website}
            onChange={({target}) => {
              refInputWebsite.current.setCustomValidity('');
              refInputWebsite.current.reportValidity();
              setWebsite(target.value);
            }}></Input>
        </div>
        <div className="form-label">What's the size of your company ?</div>
        <SelectGroup
          isSearchable={false}
          options={COMPANY_SIZE_OPTIONS}
          inverted
          required
          value={size}
          onChange={(size) => setSize(size)}></SelectGroup>
        <Button
          loading={isSubmitting}
          disabled={canContinue === false}
          cta
          primary
          secondary
          iconRight="icon-chevron-right">
          Continue
        </Button>
      </form>
    </div>
  );
};
