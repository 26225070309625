import React, {useContext, useEffect, useState} from 'react';
import './_Styles.scss';
import AudienceSection from 'scenes/PokeAudience/components/AudienceSection';
import {AudienceContext} from 'scenes/PokeAudience';
import {InteractiveOption} from 'scenes/Builder/component/InteractiveOption';
import ModalSegmentation from 'scenes/PokeAudience/sections/Who/components/SegmentationModal';
import {Swaler} from 'swaler';
import {jimerService} from 'services';
import {number} from 'prop-types';
import Button from 'components/Button';
import classNames from 'classnames';
import {ROUTE_POST_BUILDER_AUDIENCE} from 'router/routes.const';

const logger = new Swaler('SectionWho');

export const getWhoTitle = (evolution, jimersCount) => {
  const hasSetupSegmentation =
    evolution.segments?.length !== 0 ||
    (evolution.onTheFlySegment != null &&
      evolution.onTheFlySegment?.attributes?.length > 0);

  const usersSelectedCountStr =
    jimersCount === null || jimersCount === undefined
      ? ''
      : `(${jimersCount} users selected)`;
  const segmentTitle =
    hasSetupSegmentation === true
      ? evolution?.segments?.length > 0
        ? `${evolution.segments
            .map((s) => s.name)
            .join(', ')} ${usersSelectedCountStr}`
        : `On the fly segment ${usersSelectedCountStr}`
      : 'All people';

  return segmentTitle;
};

const propTypes = {
  index: number.isRequired,
};

const defaultProps = {};

const Who = ({index}) => {
  const {evolution, setEvolution, jimersCount, setJimersCount, isBoosted} =
    useContext(AudienceContext);

  const [showSegmentationModal, setShowSegmentationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const hasSetupSegmentation =
    evolution.segments?.length !== 0 ||
    (evolution.onTheFlySegment != null &&
      evolution.onTheFlySegment?.attributes?.length > 0);
  const isPoke = evolution.boostFlags > 0;

  const handleResetSegmentation = () => {
    setEvolution({
      ...evolution,
      segments: [],
      onTheFlySegment: null,
    });
  };

  useEffect(() => {
    if (showSegmentationModal !== true) {
      const hasSetupSegmentation =
        evolution.segments?.length > 0 ||
        evolution.onTheFlySegment?.attributes?.length > 0;
      if (hasSetupSegmentation) {
        fetchJimersCount();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evolution.segments, evolution.onTheFlySegment, showSegmentationModal]);

  const fetchJimersCount = async () => {
    setIsLoading(true);
    try {
      const res = await jimerService.getJimers(
        {
          ...(evolution.onTheFlySegment?.attributes?.length > 0
            ? {
                attributes: evolution.onTheFlySegment.attributes,
                logic: evolution.onTheFlySegment.logic,
              }
            : {segmentIds: evolution?.segments?.map((s) => s.uid)}),
          take: 1,
          skip: 0,
        },
        {}
      );
      setJimersCount(res.total);
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }
      logger.error('Fetching jimers failed with error ', err);
    } finally {
      setIsLoading(false);
    }
  };

  const usersSelectedCountStr =
    isLoading === true ? '' : `(${jimersCount} users selected)`;
  const segmentTitle =
    hasSetupSegmentation === true
      ? evolution?.segments?.length > 0
        ? `${evolution.segments
            .map((s) => s.name)
            .join(', ')} ${usersSelectedCountStr}`
        : `On the fly segment ${usersSelectedCountStr}`
      : 'Specific users';
  let descriptionPokeType = isPoke ? 'experience' : 'post';
  let segmentDescription = `Show this ${descriptionPokeType} to a segment of users based on different attributes`;
  if (hasSetupSegmentation === true) {
    segmentDescription = `This ${descriptionPokeType} will be shown to users of ${
      evolution?.segments?.length > 0
        ? `${evolution.segments.map((s) => s.name).join(', ')} segment${
            evolution.segments.length > 1 ? 's' : ''
          }`
        : `your on the fly segment`
    }`;
  }

  return (
    <AudienceSection
      className={classNames('who', {
        'is-boosted': isBoosted === true,
      })}
      index={index}
      title={`Who: ${getWhoTitle(evolution, jimersCount)}`}
      forceChecked={isBoosted}>
      <div className="who-editor-wrapper">
        <div className="sub-section-title">
          Select the segment of users you want to show the experience to
        </div>
        <InteractiveOption
          active={hasSetupSegmentation === false}
          iconComponent={
            <>
              <i className="icon default-icon icon-users" />
              <i className="icon active-hovered-icon icon-settings" />
            </>
          }
          title="All people"
          description={
            isPoke
              ? 'Show this experience to everyone'
              : 'Show this post to everyone'
          }
          onClick={handleResetSegmentation}
          withRadioBox
        />
        <span>Or</span>
        <InteractiveOption
          active={hasSetupSegmentation === true}
          iconComponent={
            <>
              <i className="icon default-icon icon-users" />
              <i className="icon active-hovered-icon icon-settings" />
            </>
          }
          title={segmentTitle}
          description={segmentDescription}
          onClick={() => {
            setShowSegmentationModal(true);
          }}
          withRadioBox
        />
      </div>
      {isBoosted === true && (
        <div className="boosted-info-wrapper">
          <div className="boosted-info-text">
            This experience is linked to a post of the changelog, the audience
            is the same as for this one and can’t be modified !
          </div>
          <div className="boosted-info-actions">
            <Button
              className="boosted-info-btn"
              primary
              reverted
              onClick={() =>
                window.open(
                  ROUTE_POST_BUILDER_AUDIENCE(evolution.isBoostOf.uid),
                  '_blank',
                  'noopener noreferrer'
                )
              }>
              Go to the post audience
            </Button>
          </div>
        </div>
      )}

      <ModalSegmentation
        isOpen={showSegmentationModal}
        onRequestClose={() => setShowSegmentationModal(false)}
        evolution={evolution}
        setEvolution={setEvolution}
      />
    </AudienceSection>
  );
};

Who.propTypes = propTypes;
Who.defaultProps = defaultProps;

export default Who;
