import {generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import {crispHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {useIsExtensionInstalled} from 'helpers/utils';
import Lottie from 'lottie-react';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  ROUTE_GET_STARTED,
  ROUTE_GET_STARTED_INSTALL,
} from 'router/routes.const';
import {GetStartedStepItem} from 'scenes/GetStarted/component/GetSartedStepItem';
import {GetStartedStepContainer} from 'scenes/GetStarted/component/GetStartedStepContainer';
import ModalCreatePoke from 'scenes/Pushes/components/ModalCreatePoke';
import {generalSelector} from 'selectors';
import {projectService} from 'services';
import {
  F_EXTRA_FIRST_POKE_ADOPTION,
  F_EXTRA_FIRST_POKE_DISCOVERY,
  PROJECT_ROLE_ADMIN,
  PROJECT_ROLE_EDITOR,
  PROJECT_ROLE_MEMBER,
} from 'services/project';
import {Swaler} from 'swaler';
import animAdoption3 from './animations/adoption-3.json';
import confettiAnimation from './animations/confetti.json';
import animDiscovery4 from './animations/discovery-4.mp4';
import './_styles.scss';
import {EVOLUTION_TYPE_SURVEY, EVOLUTION_TYPE_TOUR} from 'services/evolution';

const CARDS = [
  {
    id: 'adoption',
    title: 'Create your first Modal or Tour',
    description: 'Increase feature adoption drastically.',
    icon: <i className="icon-layers"></i>,
    iconColor: '#E6FDE8',
    animation: 'adoption-1',
    type: EVOLUTION_TYPE_TOUR,
  },
  {
    id: 'discovery',
    title: 'Create your first Survey',
    description: 'Make product discovery continuous.',
    icon: <i className="icon-layers"></i>,
    iconColor: '#E6F5FD',
    animation: 'discovery-1',
    type: EVOLUTION_TYPE_SURVEY,
  },
];

const logger = new Swaler('GetStarted/Try');

export const GetStartedTry = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const project = useSelector((state) => generalSelector.getProject(state));
  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );

  const canCreatePoke = [
    PROJECT_ROLE_ADMIN,
    PROJECT_ROLE_EDITOR,
    PROJECT_ROLE_MEMBER,
  ].includes(projectMember?.role);

  const hasTryAdoption =
    canCreatePoke && hasFlag(F_EXTRA_FIRST_POKE_ADOPTION, project.extraFlags);
  const hasTryDiscovery =
    canCreatePoke && hasFlag(F_EXTRA_FIRST_POKE_DISCOVERY, project.extraFlags);

  const uptProject = (data) => dispatch(generalActions.uptProject(data));

  const [createData, setCreateData] = useState({
    type: null,
  });
  const [animation, setAnimation] = useState(null);

  const {isInstalled, check} = useIsExtensionInstalled();

  const refVideo = useRef(null);
  const refSuccessDiv = useRef(null);

  const handleRefreshProject = async (context) => {
    try {
      const refreshedProject = await projectService.getProject(project.uid);

      uptProject(refreshedProject);
    } catch (err) {
      logger.error('Refreshing project failed with error ', err.message);
    }
  };

  useEffect(() => {
    window.addEventListener('focus', check);
    return () => {
      window.removeEventListener('focus', check);
    };
  }, []);

  useEffect(() => {
    handleRefreshProject();
  }, []);

  const allTries = [hasTryAdoption, hasTryDiscovery];
  const tryProgress = Math.round(
    (allTries.filter((b) => b === true).length / 2) * 100
  );

  return (
    <div className="s-get-started-try">
      <div className="gs-top">
        <Button
          onClick={() =>
            history.push({
              pathname: ROUTE_GET_STARTED,
              state: {
                expandGetStarted: true,
              },
            })
          }
          light
          iconOnly>
          <i className="icon-chevron-left"></i>
        </Button>
        Test & setup Jimo
      </div>
      <div className="gs-body">
        <GetStartedStepContainer>
          <GetStartedStepItem
            value={1}
            progress={isInstalled === true ? 100 : 0}
            title="Install our Jimo chrome extension">
            <div
              className={classnames(
                'gs-container gs-container-install-chrome-extension',
                {'is-success': isInstalled === true}
              )}>
              <div className="gs-try-cover">
                <iframe
                  title="illu extension"
                  src="https://my.spline.design/integrationscopy-ca614ac98ce52ba4dcb3ec66da9e5008/"
                  frameborder="0"></iframe>
              </div>
              {isInstalled === true ? (
                <div className="extension-installed">
                  <div className="confetti-wrapper">
                    <Lottie
                      animationData={confettiAnimation}
                      loop={false}></Lottie>
                  </div>
                  <i className="icon-tick"></i> Chrome extension installed
                </div>
              ) : (
                <a
                  href="https://chrome.google.com/webstore/detail/jimo/koipflmbgiibbkcfccgpocdgifapofje?hl=en&authuser=0"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Button
                    className="btn-install-extension"
                    iconLeft="icon-brand-chrome">
                    Install our Chrome extension
                  </Button>
                </a>
              )}

              <small>
                You won't be able to display anything to your users until Jimo
                is fully installed.
              </small>
            </div>
          </GetStartedStepItem>
          <GetStartedStepItem
            value={2}
            progress={tryProgress > 0 ? 100 : 0}
            title="Create your first content"
            opacityOnHover={isInstalled === false}>
            <div className="gs-container-create-content">
              <div className="gs-container">
                {CARDS.map((c, i) => (
                  <>
                    <div
                      className={classnames('gs-card gs-card-create-content', {
                        'is-done':
                          c.id === 'adoption'
                            ? hasTryAdoption
                            : c.id === 'discovery'
                            ? hasTryDiscovery
                            : false,
                      })}
                      onMouseOver={() => {
                        setAnimation(c.animation);
                      }}>
                      <div
                        className="card-cc-top"
                        onClick={() => {
                          if (c.type != null) {
                            return setCreateData({
                              type: c.type,
                              context: c.context,
                            });
                          }
                          if (c.goTo != null) {
                            history.push(c.goTo);
                          }
                        }}>
                        <div
                          className="icon-wrapper"
                          style={{backgroundColor: c.iconColor}}>
                          {c.icon}
                        </div>
                        <div className="content-description">
                          {c.title}
                          <small>{c.description}</small>
                        </div>
                        {((c.id === 'adoption' && hasTryAdoption !== true) ||
                          (c.id === 'discovery' &&
                            hasTryDiscovery !== true)) && (
                          <div className="create-btn">
                            Create
                            <i className="icon-plus" />
                          </div>
                        )}
                      </div>
                    </div>
                    {i < CARDS.length - 1 && (
                      <div className="or-separator">or</div>
                    )}
                  </>
                ))}
              </div>
              <div className="illustration-wrapper">
                {animation === 'adoption-1' && (
                  <Lottie animationData={animAdoption3}></Lottie>
                )}

                {animation === 'discovery-1' && (
                  <video
                    ref={refVideo}
                    src={animDiscovery4}
                    autoPlay
                    muted></video>
                )}
              </div>
            </div>
          </GetStartedStepItem>
          {tryProgress > 0 && (
            <GetStartedStepItem>
              <div
                ref={refSuccessDiv}
                className="gs-container gs-container-try-end">
                Your first poke is ready to be pushed live, all you need to do
                is to install now!
                <p>
                  If you have any feedback so far,{' '}
                  <span
                    onClick={() =>
                      crispHelpers.startCrispThread(
                        'I just finished to "quick test" and I have some feedbacks to give you: \n\n '
                      )
                    }>
                    let us know.
                  </span>
                </p>
                <Button
                  cta
                  secondary
                  iconRight="icon-chevron-right"
                  onClick={() =>
                    history.push({
                      pathname: ROUTE_GET_STARTED_INSTALL,
                      state: {expandGetStarted: true},
                    })
                  }>
                  Continue to installation
                </Button>
                <div className="iframe-wrapper">
                  <iframe
                    src="https://my.spline.design/collaboratecopy-28222a336ead1767d4d9767105da0ce8"
                    frameborder="0"
                    title="animation"
                    width="700px"
                    height="700px"></iframe>
                </div>
              </div>
            </GetStartedStepItem>
          )}
        </GetStartedStepContainer>
        <ModalCreatePoke
          isOpen={createData.type != null}
          type={createData.type}
          onRequestClose={() =>
            setCreateData({
              type: null,
              context: null,
            })
          }
        />
      </div>
    </div>
  );
};
