import Button from 'components/Button';
import {useHistory} from 'react-router-dom';
import {ROUTE_GET_STARTED} from 'router/routes.const';
import Plan from 'scenes/Settings/scenes/Subscription/Plan';
import './_styles.scss';

export const GetStartedTrial = () => {
  const history = useHistory();

  return (
    <div className="s-get-started-trial">
      <div className="gs-top">
        <Button
          onClick={() =>
            history.push({
              pathname: ROUTE_GET_STARTED,
              state: {
                expandGetStarted: true,
              },
            })
          }
          light
          iconOnly>
          <i className="icon-chevron-left"></i>
        </Button>
        Start a free trial to go live
      </div>
      <div className="gs-body">
        <Plan fromGetStarted />
      </div>
    </div>
  );
};
