import Alert from 'components/Alert';
import Button from 'components/Button';
import React, {useContext, useEffect} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import './_Styles.scss';

export const CommonEB = ({error}) => {
  return (
    <div className="common-error-boundary">
      <div className="body">
        <div className="logo">
          <i className="icon-jimo"></i>
        </div>
        <div className="eb-delimiter"></div>
        <div className="content">
          <h2>
            Erf, something just broked... sorry...{' '}
            <span role="img" aria-label="oups">
              😳
            </span>
          </h2>
          <p>
            We have been warned but feel free to contact us, even to express
            your anger... and if you can join the error bellow to your message
            it would be perfect{' '}
            <span role="img" aria-label="yeah">
              👌
            </span>
          </p>
          <Alert danger>
            {error != null ? error.message : 'No error message available'}
          </Alert>
        </div>
        <div className="eb-delimiter"></div>
        <div className="actions">
          <>
            <Button
              text="Go back to safety"
              primary
              onClick={() => (window.location = '/')}
              iconPadding="small"
              iconLeft="icon-chevron-left"></Button>
            <Button
              muted
              text="Refresh"
              iconRight="icon-replay"
              iconPadding="medium"
              onClick={() => window.location.reload()}></Button>
          </>
        </div>
      </div>
    </div>
  );
};

export const PokeBuilderEB = ({error}) => {
  const {messenger, isInApp} = useContext(BuilderContext);

  useEffect(() => {
    if (isInApp === true) {
      messenger?.sendAbortAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="poke-builder-error-boundary">
      <div className="body">
        <div className="logo">
          <i className="icon-jimo"></i>
        </div>
        <div className="eb-delimiter"></div>
        <div className="content">
          <h2>
            Erf, something just broked... sorry...{' '}
            <span role="img" aria-label="oups">
              😳
            </span>
          </h2>
          <p>
            We have been warned but feel free to contact us, even to express
            your anger... and if you can join the error bellow to your message
            it would be perfect{' '}
            <span role="img" aria-label="yeah">
              👌
            </span>
          </p>
          <Alert danger>
            {error != null ? error.message : 'No error message available'}
          </Alert>
        </div>
        <div className="eb-delimiter"></div>
        <div className="actions">
          {isInApp === true ? (
            <Button
              text="Go back to safety"
              primary
              onClick={() => messenger?.sendCloseEmbeddedBuilder()}
              iconPadding="small"
              iconLeft="icon-chevron-left"
            />
          ) : (
            <>
              <Button
                text="Go back to safety"
                primary
                onClick={() => (window.location = '/')}
                iconPadding="small"
                iconLeft="icon-chevron-left"></Button>
              <Button
                muted
                text="Refresh"
                iconRight="icon-replay"
                iconPadding="medium"
                onClick={() => window.location.reload()}></Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
