import classnames from 'classnames';
import React from 'react';
import './_Styles.scss';

const PreviewContainer = (props) => {
  const {className, children} = props;
  const classNames = classnames('settings-preview-container', className);

  return (
    <div className={classNames}>
      <div className="overlay"></div>
      {children}
    </div>
  );
};

export default PreviewContainer;
