import {generalActions} from 'actions';
import classNames from 'classnames';
import Button from 'components/Button';
import ColorPicker from 'components/ColorPicker';
import Divider from 'components/Divider';
import Input from 'components/Input';
import {hasFlag} from 'helpers/bitwise';
import {bool, func, number, object} from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getCustomizationFromTheme} from 'router/Layouts';
import {getTypeFromBoostFlags} from 'scenes/Builder/component/CtaModal';
import ElementPicker from 'scenes/Builder/scenes/Audience/components/ElementPicker';
import {HOTSPOT_SHAPE_DEFAULT} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Hotspot';
import {InnerLabeledInput} from 'scenes/Settings/components/LabeledInput';
import {
  defaultTheme,
  getThemeFieldsFromCustomization,
} from 'scenes/Settings/scenes/Changelog/Pokes';
import {generalSelector} from 'selectors';
import {evolutionService, themeService} from 'services';
import {
  BOOSTED_ANIMATION_FADE,
  BOOSTED_ANIMATION_GROW,
  BOOSTED_ANIMATION_NONE,
  BOOSTED_ANIMATION_ORIENTATION_BOTTOM,
  BOOSTED_ANIMATION_ORIENTATION_LEFT,
  BOOSTED_ANIMATION_ORIENTATION_RIGHT,
  BOOSTED_ANIMATION_ORIENTATION_TOP,
  BOOSTED_ANIMATION_SLIDE,
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_POP_IN,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOP_BAR,
  defaultCustomization,
} from 'services/evolution';
import {Swaler} from 'swaler';
import './_styles.scss';
import {ActivableOption} from './components/ActivatableOption';
import Alignement from './components/Alignement';
import {customizationTemplate, extractFields} from './utils';

const getDefaultWidth = (typeFlag) => {
  if (
    [F_BOOST_SLOT_DOT, F_BOOST_SLOT_SNIPPET, F_BOOST_SLOT_TOOLTIP].includes(
      typeFlag
    )
  ) {
    return 328;
  }
  return null;
};

const propTypes = {
  boostFlags: number,
  evolution: object,
  setEvolution: func,
  setOriginalEvolution: func,
  customization: object,
  setCustomization: func,
  onClose: func,
  cardless: bool,
  dotPositionInCustomization: bool,
  withCtaStyling: bool,
  withSaveDefault: bool,
  onSelectElementClick: func,
};

const defaultProps = {
  boostFlags: 0,
  evolution: null,
  setEvolution: () => {},
  setOriginalEvolution: () => {},
  customization: defaultCustomization,
  setCustomization: () => {},
  onClose: () => {},
  cardless: false,
  dotPositionInCustomization: false,
  withCtaStyling: false,
  withSaveDefault: false,
  onSelectElementClick: null,
};

const logger = new Swaler('CustomizeOptions');

export const CustomizeOptions = ({
  boostFlags,
  evolution,
  setEvolution,
  setOriginalEvolution,
  customization,
  setCustomization,
  onClose,
  cardless,
  dotPositionInCustomization,
  withCtaStyling,
  withSaveDefault,
  isTourStep,
  onSelectElementClick,
}) => {
  const dispatch = useDispatch();

  const project = useSelector((state) => generalSelector.getProject(state));

  let {
    boostedPrimaryColor,
    boostedSecondaryColor,
    boostedTextsColors,
    boostedRoundness,
    boostedTitleFontSize,
    boostedContentFontSize,
    boostedDotStyle,
    boostedSize,
    boostedLightbox,
    boostedAnimations,
    ctaBorderRadius,
    ctaBackgroundColor,
    ctaColor,
    boostedZIndex,
  } = customization;

  const [titleColor, contentColor] = boostedTextsColors.split(';');
  const [dotColor, dotSize] = boostedDotStyle.split(';');
  const [animationEntry, animationLeave, orientationEntry, orientationLeave] =
    boostedAnimations?.split(';');
  const previewTopBar = hasFlag(F_BOOST_SLOT_TOP_BAR, boostFlags);
  const previewSnippet = hasFlag(F_BOOST_SLOT_SNIPPET, boostFlags);
  const previewPopIn = hasFlag(F_BOOST_SLOT_POP_IN, boostFlags);
  const previewDot = hasFlag(F_BOOST_SLOT_DOT, boostFlags);
  const previewTooltip = hasFlag(F_BOOST_SLOT_TOOLTIP, boostFlags);

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (cardless === true && isTourStep !== true) {
      setEvolution({...(evolution || {}), ...customization});
    }
  }, [cardless, customization]);

  const handleStyleChange = (updatedStyle) => {
    if (isTourStep) {
      setEvolution({
        ...evolution,
        ...updatedStyle,
      });
    } else {
      setCustomization(updatedStyle);
    }
  };

  const handleCancel = () => {
    setCustomization({
      boostedPrimaryColor: evolution?.boostedPrimaryColor ?? '#ffffff',
      boostedSecondaryColor: evolution?.boostedSecondaryColor ?? '#1260EB',
      boostedTextsColors: evolution?.boostedTextsColors ?? '#071331;#071331',
      boostedRoundness: evolution?.boostedRoundness ?? 8,
      boostedTitleFontSize: evolution?.boostedTitleFontSize ?? 18,
      boostedContentFontSize: evolution?.boostedContentFontSize ?? 12,
      boostedDotStyle:
        evolution?.boostedDotStyle ?? `#1260EB;18;${HOTSPOT_SHAPE_DEFAULT}`,
      boostedSize: evolution?.boostedSize ?? null,
      boostedLightbox: evolution?.boostedLightbox ?? 'SOFT',
      boostedZIndex: evolution?.boostedZIndex ?? null,
      boostedAnimations:
        evolution?.boostedAnimations ?? 'slide;slide;left;left',
    });
    onClose();
  };
  const handleSave = async () => {
    await evolutionService.updateEvolution(evolution?.uid, {
      ...(evolution || {}),
      ...customization,
    });
    setEvolution({...(evolution || {}), ...customization});
    setOriginalEvolution({...(evolution || {}), ...customization});
    onClose();
  };

  const handleSetDefault = async () => {
    setIsSaving(true);
    const themeFields = getThemeFieldsFromCustomization(
      getTypeFromBoostFlags(boostFlags),
      customization
    );
    // remove null fields
    Object.keys(themeFields).forEach((key) => {
      if (themeFields[key] == null) {
        delete themeFields[key];
      }
    });
    try {
      const savedTheme = await themeService.createTheme({
        ...(project.theme || defaultTheme),
        ...themeFields,
      });
      dispatch(
        generalActions.uptProject({...project, theme: savedTheme}, project.uid)
      );
    } catch (err) {
      logger.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  const content = (
    <div className="opts-content">
      {evolution != null &&
        [previewDot, previewTooltip].some((b) => b === true) && (
          <>
            <div className="section">
              <div className="section-title">Targeted element</div>
              <div className="element-selection">
                <OptionItem>
                  <ElementPicker
                    pokeToPreview={evolution}
                    querySelector={evolution?.boostedQueryselector}
                    onQuerySelectorChange={(querySelector) => {
                      setEvolution({
                        ...evolution,
                        boostedQueryselector: querySelector,
                      });
                    }}
                    onSelectElement={onSelectElementClick}
                  />
                </OptionItem>
              </div>
            </div>
            <Divider />
          </>
        )}
      {[previewDot, previewTooltip].some((b) => b === true) &&
        evolution != null && (
          <>
            <div className="section">
              <div className="section-title">Alignement</div>
              <Alignement evolution={evolution} setEvolution={setEvolution} />
              <OptionItem>
                <InnerLabeledInput title="z-index">
                  <Input
                    className="input-with-blue-label z-index-input"
                    labelTextLeft={<i className="icon-duplicate"></i>}
                    type="number"
                    placeholder="auto"
                    labelTextRight=" "
                    value={boostedZIndex}
                    onChange={({target}) =>
                      handleStyleChange({
                        ...customization,
                        boostedZIndex: target.value,
                      })
                    }
                  />
                </InnerLabeledInput>
              </OptionItem>
            </div>

            <Divider />
          </>
        )}
      {[previewDot].some((b) => b === true) && (
        <>
          <div className="section">
            <div className="section-title">Hotspot</div>
            {previewDot && (
              <OptionItem>
                <InnerLabeledInput title="Hotspot size">
                  <Input
                    className="input-with-blue-label"
                    type="number"
                    labelTextRight="px"
                    value={dotSize}
                    onChange={({target}) =>
                      handleStyleChange({
                        ...customization,
                        boostedDotStyle: [dotColor, target.value].join(';'),
                      })
                    }
                  />
                </InnerLabeledInput>
                <InnerLabeledInput title="Hotspot color">
                  <ColorPicker
                    popoverStyle={{bottom: '-225px', right: 0}}
                    color={dotColor || '#FFFFFF'}
                    disableAlpha
                    onChange={(color) =>
                      handleStyleChange({
                        ...customization,
                        boostedDotStyle: [color.hex, dotSize].join(';'),
                      })
                    }>
                    <Input
                      labelTextLeft={
                        <span
                          className="preview-color"
                          style={{
                            backgroundColor: dotColor || '#FFFFFF',
                          }}
                        />
                      }
                      className="input-color"
                      placeholder="#FFFFFF"
                      value={dotColor?.toUpperCase()}
                    />
                  </ColorPicker>
                </InnerLabeledInput>
              </OptionItem>
            )}
          </div>
          <Divider />
        </>
      )}
      {[previewSnippet].some((b) => b === true) && (
        <div className="section section-animations">
          <div className="section-title">Animations</div>
          <OptionItem label="Type">
            <ActivableOption
              isActive={animationEntry === BOOSTED_ANIMATION_NONE}
              onClick={() =>
                handleStyleChange({
                  ...customization,
                  boostedAnimations: `${BOOSTED_ANIMATION_NONE};${BOOSTED_ANIMATION_NONE};${orientationEntry};${orientationLeave}`,
                })
              }>
              None
            </ActivableOption>
            <ActivableOption
              isActive={animationEntry === BOOSTED_ANIMATION_FADE}
              onClick={() =>
                handleStyleChange({
                  ...customization,
                  boostedAnimations: `${BOOSTED_ANIMATION_FADE};${BOOSTED_ANIMATION_FADE};${orientationEntry};${orientationLeave}`,
                })
              }>
              Fade
            </ActivableOption>
            <ActivableOption
              isActive={animationEntry === BOOSTED_ANIMATION_SLIDE}
              onClick={() =>
                handleStyleChange({
                  ...customization,
                  boostedAnimations: `${BOOSTED_ANIMATION_SLIDE};${BOOSTED_ANIMATION_SLIDE};${orientationEntry};${orientationLeave}`,
                })
              }>
              Slide
            </ActivableOption>
            <ActivableOption
              isActive={animationEntry === BOOSTED_ANIMATION_GROW}
              onClick={() =>
                handleStyleChange({
                  ...customization,
                  boostedAnimations: `${BOOSTED_ANIMATION_GROW};${BOOSTED_ANIMATION_GROW};${orientationEntry};${orientationLeave}`,
                })
              }>
              Grow
            </ActivableOption>
          </OptionItem>
          {animationEntry === BOOSTED_ANIMATION_SLIDE && (
            <OptionItem label="Orientation">
              <ActivableOption
                isActive={
                  orientationEntry === BOOSTED_ANIMATION_ORIENTATION_LEFT
                }
                onClick={() => {
                  handleStyleChange({
                    ...customization,
                    boostedAnimations: `${animationEntry};${animationLeave};${BOOSTED_ANIMATION_ORIENTATION_LEFT};${BOOSTED_ANIMATION_ORIENTATION_LEFT}`,
                  });
                }}>
                Left
              </ActivableOption>
              <ActivableOption
                isActive={
                  orientationEntry === BOOSTED_ANIMATION_ORIENTATION_RIGHT
                }
                onClick={() => {
                  handleStyleChange({
                    ...customization,
                    boostedAnimations: `${animationEntry};${animationLeave};${BOOSTED_ANIMATION_ORIENTATION_RIGHT};${BOOSTED_ANIMATION_ORIENTATION_RIGHT}`,
                  });
                }}>
                Right
              </ActivableOption>
              <ActivableOption
                isActive={
                  orientationEntry === BOOSTED_ANIMATION_ORIENTATION_TOP
                }
                onClick={() => {
                  handleStyleChange({
                    ...customization,
                    boostedAnimations: `${animationEntry};${animationLeave};${BOOSTED_ANIMATION_ORIENTATION_TOP};${BOOSTED_ANIMATION_ORIENTATION_TOP}`,
                  });
                }}>
                Top
              </ActivableOption>
              <ActivableOption
                isActive={
                  orientationEntry === BOOSTED_ANIMATION_ORIENTATION_BOTTOM
                }
                onClick={() => {
                  handleStyleChange({
                    ...customization,
                    boostedAnimations: `${animationEntry};${animationLeave};${BOOSTED_ANIMATION_ORIENTATION_BOTTOM};${BOOSTED_ANIMATION_ORIENTATION_BOTTOM}`,
                  });
                }}>
                Bottom
              </ActivableOption>
            </OptionItem>
          )}
          <Divider></Divider>
        </div>
      )}
      <div className="section">
        <div className="section-title">Card</div>
        {[previewPopIn, previewTooltip].some((b) => b === true) && (
          <>
            <OptionItem className="lightbox-pick">
              <InnerLabeledInput title="Lightbox">
                <div className="lightbox-options">
                  <ActivableOption
                    className={classNames('lightbox-option')}
                    isActive={boostedLightbox === 'NONE'}
                    onClick={() =>
                      handleStyleChange({
                        ...customization,
                        boostedLightbox: 'NONE',
                      })
                    }>
                    None
                  </ActivableOption>
                  <ActivableOption
                    className={classNames('lightbox-option')}
                    isActive={
                      boostedLightbox === 'SOFT' || boostedLightbox == null
                    }
                    onClick={() =>
                      handleStyleChange({
                        ...customization,
                        boostedLightbox: 'SOFT',
                      })
                    }>
                    Soft
                  </ActivableOption>
                  <ActivableOption
                    className={classNames('lightbox-option')}
                    isActive={boostedLightbox === 'HARD'}
                    onClick={() =>
                      handleStyleChange({
                        ...customization,
                        boostedLightbox: 'HARD',
                      })
                    }>
                    Hard
                  </ActivableOption>
                </div>
              </InnerLabeledInput>
            </OptionItem>
          </>
        )}
        {[previewDot, previewSnippet, previewTooltip].some(
          (b) => b === true
        ) && (
          <>
            <OptionItem>
              <InnerLabeledInput title="Card width">
                <Input
                  className="input-with-blue-label"
                  type="number"
                  labelTextRight="px"
                  value={boostedSize || getDefaultWidth(boostFlags)}
                  onChange={({target}) =>
                    handleStyleChange({
                      ...customization,
                      boostedSize: target.value,
                    })
                  }
                />
              </InnerLabeledInput>
            </OptionItem>
          </>
        )}
        <OptionItem>
          <InnerLabeledInput title="Roundness">
            <Input
              disabled={previewTopBar === true}
              className="input-with-blue-label"
              labelTextLeft={<i className="icon-line-corner"></i>}
              type="number"
              labelTextRight="px"
              value={boostedRoundness}
              onChange={({target}) =>
                handleStyleChange({
                  ...customization,
                  boostedRoundness: target.value,
                })
              }></Input>
          </InnerLabeledInput>
          <InnerLabeledInput title="Background">
            <ColorPicker
              popoverStyle={{bottom: '-225px', right: 0}}
              color={boostedPrimaryColor || '#FFFFFF'}
              disableAlpha
              onChange={(color) =>
                handleStyleChange({
                  ...customization,
                  boostedPrimaryColor: color.hex,
                })
              }>
              <Input
                labelTextLeft={
                  <span
                    className="preview-color"
                    style={{
                      backgroundColor: boostedPrimaryColor,
                    }}
                  />
                }
                className="input-color"
                placeholder="#FFFFFF"
                value={boostedPrimaryColor?.toUpperCase()}
              />
            </ColorPicker>
          </InnerLabeledInput>
        </OptionItem>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">Content</div>
        {[previewSnippet, previewPopIn, previewDot, previewTooltip].some(
          (b) => b === true
        ) && (
          <>
            <OptionItem className="opt-item-accent">
              <InnerLabeledInput title="Accent roundness">
                <Input
                  disabled
                  className="input-with-blue-label"
                  labelTextLeft={<i className="icon-line-corner"></i>}
                  type="number"
                  labelTextRight="px"
                  value={46}
                  onChange={({target}) =>
                    handleStyleChange({boostedRoundness: target.value})
                  }></Input>
              </InnerLabeledInput>
              <InnerLabeledInput title="Accent color">
                <ColorPicker
                  popoverStyle={{bottom: '-225px', right: 0}}
                  color={boostedSecondaryColor || '#FFFFFF'}
                  disableAlpha
                  onChange={(color) =>
                    handleStyleChange({
                      ...customization,
                      boostedSecondaryColor: color.hex,
                    })
                  }>
                  <Input
                    labelTextLeft={
                      <span
                        className="preview-color"
                        style={{
                          backgroundColor: boostedSecondaryColor,
                        }}
                      />
                    }
                    className="input-color"
                    placeholder="#FFFFFF"
                    value={boostedSecondaryColor?.toUpperCase()}
                  />
                </ColorPicker>
              </InnerLabeledInput>
            </OptionItem>
          </>
        )}
        <OptionItem>
          <InnerLabeledInput title="Title size">
            <Input
              className="input-with-blue-label"
              labelTextLeft="Aa"
              type="number"
              labelTextRight="px"
              value={boostedTitleFontSize}
              max={previewTopBar === true ? 32 : undefined}
              onChange={({target}) =>
                handleStyleChange({
                  ...customization,
                  boostedTitleFontSize: target.value,
                })
              }></Input>
          </InnerLabeledInput>
          <InnerLabeledInput title="Title color">
            <ColorPicker
              popoverStyle={{bottom: '-225px', right: 0}}
              color={titleColor || '#FFFFFF'}
              disableAlpha
              onChange={(color) =>
                handleStyleChange({
                  ...customization,
                  boostedTextsColors: [color.hex, contentColor].join(';'),
                })
              }>
              <Input
                labelTextLeft={
                  <span
                    className="preview-color"
                    style={{
                      backgroundColor: titleColor,
                    }}
                  />
                }
                className="input-color"
                placeholder="#FFFFFF"
                value={titleColor?.toUpperCase()}
              />
            </ColorPicker>
          </InnerLabeledInput>
        </OptionItem>
        {[previewSnippet, previewPopIn, previewDot, previewTooltip].some(
          (b) => b === true
        ) && (
          <>
            <OptionItem>
              <InnerLabeledInput title="Content size">
                <Input
                  className="input-with-blue-label"
                  labelTextLeft={'Aa'}
                  type="number"
                  labelTextRight="px"
                  value={boostedContentFontSize}
                  onChange={({target}) =>
                    handleStyleChange({
                      ...customization,
                      boostedContentFontSize: target.value,
                    })
                  }></Input>
              </InnerLabeledInput>
              <InnerLabeledInput title="Content color">
                <ColorPicker
                  popoverStyle={{bottom: '-225px', right: 0}}
                  color={contentColor || '#FFFFFF'}
                  disableAlpha
                  onChange={(color) =>
                    handleStyleChange({
                      ...customization,
                      boostedTextsColors: [titleColor, color.hex].join(';'),
                    })
                  }>
                  <Input
                    labelTextLeft={
                      <span
                        className="preview-color"
                        style={{
                          backgroundColor: contentColor,
                        }}
                      />
                    }
                    className="input-color"
                    placeholder="#FFFFFF"
                    value={contentColor?.toUpperCase()}
                  />
                </ColorPicker>
              </InnerLabeledInput>
            </OptionItem>
          </>
        )}
      </div>
      {withCtaStyling && (
        <>
          <Divider />
          <div className="section">
            <div className="section-title">CTA buttons</div>
            <OptionItem>
              <InnerLabeledInput title="Roundness">
                <Input
                  className="input-with-blue-label"
                  labelTextLeft={<i className="icon-line-corner"></i>}
                  type="number"
                  labelTextRight="px"
                  value={ctaBorderRadius}
                  onChange={({target}) =>
                    handleStyleChange({
                      ...customization,
                      ctaBorderRadius: target.value,
                    })
                  }></Input>
              </InnerLabeledInput>
              <InnerLabeledInput title="Background color">
                <ColorPicker
                  popoverStyle={{bottom: '-225px', right: 0}}
                  color={ctaBackgroundColor || '#1260eb'}
                  disableAlpha
                  onChange={(color) =>
                    handleStyleChange({
                      ...customization,
                      ctaBackgroundColor: color.hex,
                    })
                  }>
                  <Input
                    labelTextLeft={
                      <span
                        className="preview-color"
                        style={{
                          backgroundColor: ctaBackgroundColor || '#1260eb',
                        }}
                      />
                    }
                    className="input-color"
                    placeholder="#1260eb"
                    value={ctaBackgroundColor?.toUpperCase()}
                  />
                </ColorPicker>
              </InnerLabeledInput>
            </OptionItem>
            <OptionItem>
              <InnerLabeledInput title="Text color">
                <ColorPicker
                  popoverStyle={{bottom: '-225px', right: 0}}
                  color={ctaColor || '#FFFFFF'}
                  disableAlpha
                  onChange={(color) =>
                    handleStyleChange({
                      ...customization,
                      ctaColor: color.hex,
                    })
                  }>
                  <Input
                    labelTextLeft={
                      <span
                        className="preview-color"
                        style={{
                          backgroundColor: ctaColor,
                        }}
                      />
                    }
                    className="input-color"
                    placeholder="#FFFFFF"
                    value={ctaColor?.toUpperCase()}
                  />
                </ColorPicker>
              </InnerLabeledInput>
            </OptionItem>
          </div>
        </>
      )}
    </div>
  );

  const defaultCustomization = getCustomizationFromTheme(
    getTypeFromBoostFlags(boostFlags),
    project.theme
  );
  delete defaultCustomization.ctaBackgroundColor;
  delete defaultCustomization.ctaBorderRadius;
  delete defaultCustomization.ctaColor;
  delete defaultCustomization.boostedPositionFlags;
  delete defaultCustomization.boostedZIndex;

  const onlyCustomizationFields = extractFields(
    customization,
    customizationTemplate
  );

  const isDifferentFromTheme =
    JSON.stringify(defaultCustomization) !==
    JSON.stringify(onlyCustomizationFields);

  if (cardless === true) {
    return (
      <div
        className={classNames('builder-channel-customize-options', {
          'with-saver': withSaveDefault && isDifferentFromTheme,
          'is-tour-step': isTourStep,
        })}>
        {content}
        {withSaveDefault && isDifferentFromTheme && (
          <div className={classNames('customize-options-saver fade-in-bottom')}>
            <div className="content-wrapper">
              <h2>Update default style</h2>
              To apply as default style, click on Set as default.
            </div>
            <div className="buttons-wrapper">
              <Button
                thin
                cta
                primary
                loading={isSaving}
                onClick={handleSetDefault}>
                Set as default
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="builder-channel-customize-options">
      <div className="opts-header">
        Appearance
        <div className="btn-wrapper">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button primary onClick={handleSave}>
            Save
          </Button>
          <Button
            primary
            onClick={async () => {
              await handleSetDefault();
              handleSave();
            }}>
            Save and set as default
          </Button>
        </div>
      </div>
      {content}
    </div>
  );
};

CustomizeOptions.propTypes = propTypes;
CustomizeOptions.defaultProps = defaultProps;

export const OptionItem = ({children, label, className}) => {
  return (
    <div className={classNames('opt-item', className)}>
      <div className="opt-label">{label}</div>
      <div className="opt-inputs">{children}</div>
    </div>
  );
};
