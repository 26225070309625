import classnames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Loader from 'components/Loader';
import {Menu, MenuItem} from 'components/Menu';
import Tabs from 'components/Tabs';
import {toastWarning} from 'components/Toaster';
import {crispHelpers} from 'helpers';
import useScrollDetector from 'hooks/UseScrollDetector';
import html2canvas from 'html2canvas';
import {default as qs} from 'query-string';
import {useRef, useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {useLocation, useRouteMatch} from 'react-router-dom';
import {generalSelector} from 'selectors';
import {evolutionService} from 'services';
import {EVOLUTION_TYPE_HINT, EVOLUTION_TYPE_SURVEY} from 'services/evolution';
import {
  PROJECT_ROLE_ADMIN,
  PROJECT_ROLE_MEMBER,
  PROJECT_ROLE_VIEWER,
} from 'services/project';
import {
  BLOCK_TYPE_CHOICE,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
} from 'services/steps';
import {Swaler} from 'swaler';
import './_Styles.scss';
import IssueReporting from './component/IssueReporting';
import Overview from './component/Overview';
import PokeHeader from './component/PokeHeader';
import Report from './component/Report';
import SlackConnect from './component/SlackConnect';
import Statistics from './component/Statistics';
import ListLastUsersReached from './component/Statistics/components/ListLastUsersReached';
import ModalPublishSuccess from './component/Statistics/components/ModalPublishSuccess';
import ModalPublishSuccessWithPost from './component/Statistics/components/ModalPublishSuccessWithPost';

const logger = new Swaler('Poke');

const TAB_REPORT = 'Report';
const TAB_STATISTICS = 'Statistics';
const TAB_ISSUES = 'Issues';
const TAB_INTEGRATIONS = 'Integrations';

export const Poke = ({evolutionId: evolutionIdProp}) => {
  const match = useRouteMatch();
  const location = useLocation();

  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );

  const queryString = qs.parse(location.search);

  const {evolutionId: evolutionIdParam} = match.params;

  const evolutionId = evolutionIdProp || evolutionIdParam;

  const [activeTab, setActiveTab] = useState(TAB_STATISTICS);
  const [showModalPublishSuccess, setShowModalPublishSuccess] = useState(
    queryString.showSuccess === 'true'
  );
  const [showModalPublishWithPostSuccess, setShowModalPublishWithPostSuccess] =
    useState(queryString.showSuccessWithPost === 'true');
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const contentRef = useRef();
  const refDropdown = useRef(null);
  const reportRef = useRef();

  const isScrolled = useScrollDetector(contentRef);

  const {
    data: evolution,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['evolution', 'poke', evolutionId],
    queryFn: async () => {
      const evolution = await evolutionService.getEvolutionById(evolutionId, {
        relations: [
          'author',
          'comments',
          'comments.likes',
          'tags',
          'steps',
          'analytics',
          'count.votes',
          'tourSteps',
          'count.issues',
          'tracker',
        ],
      });

      if (evolution == null) {
        logger.error("This experience doesn't exists or has been deleted");
        return null;
      }

      const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;

      if (isSurvey === false) {
        return evolution;
      } else {
        const surveys = await evolutionService.getEvolutionSurveys(evolutionId);

        // Let's add nested prototypes steps into evolution.steps
        evolution.steps = evolution.steps.reduce((steps, step) => {
          if (step.prototypes.length === 0) {
            return steps.concat(step);
          }
          return steps.concat(step).concat(
            step.prototypes[0].steps.map((ps) => ({
              ...ps,
              fromPrototypeStep: {title: step.title, uid: step.uid},
            }))
          );
        }, []);
        // Let's set the step.responses based on surveys
        evolution.steps = evolution.steps.map((step) => {
          const stepResponses = surveys.reduce((responses, survey) => {
            const response = survey.responses.find(
              (r) => r.step.uid === step.uid
            );

            if (response != null) {
              return [...responses].concat(response);
            }
            return responses;
          }, []);
          if (
            [
              STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
              STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
            ].includes(step.type)
          ) {
            const choiceBlock = step.blocks?.find(
              (b) => b.type === BLOCK_TYPE_CHOICE
            );
            if (choiceBlock != null) {
              step.options = choiceBlock.options;
            }
          }
          step.options = step.options.map((option) => {
            const optionsResponses = stepResponses.reduce(
              (options, response) => {
                const optionResponse = response.selectedOptions.find(
                  (o) => o.uid === option.uid
                );

                if (optionResponse != null) {
                  return [...options].concat(response);
                }
                return options;
              },
              []
            );

            return {...option, responses: optionsResponses};
          });
          return {...step, responses: stepResponses};
        });
        return {...evolution, surveys};
      }
    },
    enabled: evolutionId != null,
    refetchOnWindowFocus: false,
  });

  const handleExportResponses = async (type) => {
    const isDiscovery = evolution.steps.length > 0; // Use evolution.context when new builder is pushed

    const responses =
      isDiscovery === true
        ? await evolutionService.exportDiscoveryResponses(evolution.uid, type)
        : await evolutionService.exportAdoptionResponses(evolution.uid, type);
    const fileName = `responses-${evolution.uid}.${type}`;
    const data =
      type === 'json'
        ? new Blob([JSON.stringify(responses)], {type: 'text/json'})
        : new Blob([responses], {type: 'text/csv'});
    const jsonURL = window.URL.createObjectURL(data);
    const link = document.createElement('a');

    refDropdown.current.close();
    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadReport = async () => {
    setIsLoadingDownload(true);
    try {
      refDropdown.current.close();
      const element = reportRef.current;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL('image/jpg');
      const link = document.createElement('a');

      link.href = data;
      link.download = 'report.jpg';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      logger.error(err);
      toastWarning(
        [
          'Unexpected error',
          `An unexpected error has been detected. Please try again or contact the support!`,
        ],
        {
          actions: [
            {
              text: 'Contact support',
              props: {
                onClick: () => {
                  crispHelpers.startCrispThread(
                    `I just ran into an unexpected error (${err.message}). Can you help me ? :-)`
                  );
                },
                iconLeft: 'icon-chat',
              },
            },
          ],
        }
      );
    } finally {
      setIsLoadingDownload(false);
    }
  };

  const canExportResponses = [
    PROJECT_ROLE_ADMIN,
    PROJECT_ROLE_MEMBER,
    PROJECT_ROLE_VIEWER,
  ].includes(projectMember.role);

  const isDraft = evolution?.isDraft === true;
  const isSurvey = evolution?.type === EVOLUTION_TYPE_SURVEY;
  const hasResponses = evolution?.surveys?.length > 0;

  const isHint = evolution?.type === EVOLUTION_TYPE_HINT;

  useEffect(() => {
    if (evolution?.type === EVOLUTION_TYPE_SURVEY) {
      setActiveTab(TAB_REPORT);
    }
  }, [evolution?.type]); //

  return (
    <div
      className={classnames('s-poke', {
        'is-empty': evolution == null,
      })}>
      {evolution != null && (
        <PokeHeader
          evolution={evolution}
          refetch={refetch}
          scrolled={isScrolled}
        />
      )}
      <div className="poke-content-wrapper" ref={contentRef}>
        {isLoading ? (
          <div className="loader-wrapper">
            <Loader />
          </div>
        ) : evolution == null ? (
          <div className="main">Couldn't find experience</div>
        ) : (
          <div className="poke-content">
            <div className="section">
              <div className="section-title">{isHint ? 'Hints' : 'Steps'}</div>
              <div className="section-content">
                <Overview evolution={evolution} />
              </div>
            </div>
            <div className="section">
              <div className="section-title">
                Insights
                {canExportResponses === true &&
                  hasResponses === true &&
                  isSurvey &&
                  isDraft !== true && (
                    <Dropdown
                      className="action-export-data"
                      innerRef={refDropdown}
                      position="bottom center"
                      disabled={!hasResponses || isLoadingDownload}
                      trigger={
                        <Button
                          className="btn-export-data"
                          disabled={!hasResponses}
                          loading={isLoadingDownload}
                          iconLeft="icon-download">
                          Export responses
                        </Button>
                      }>
                      <Menu className="menu-export-data">
                        <MenuItem onClick={() => handleExportResponses('json')}>
                          Export to <span>JSON</span>
                        </MenuItem>
                        <MenuItem onClick={() => handleExportResponses('csv')}>
                          Export to <span>CSV</span>
                        </MenuItem>
                        <MenuItem onClick={() => handleDownloadReport()}>
                          Export to <span>JPG</span>
                        </MenuItem>
                      </Menu>
                    </Dropdown>
                  )}
              </div>
              <div className="tabs-wrapper">
                <Tabs defaultTab={activeTab} onTabChange={setActiveTab}>
                  {isSurvey ? (
                    <div
                      label={TAB_REPORT}
                      iconLeft="isax isax-clipboard-text"
                    />
                  ) : null}
                  <div
                    label={TAB_STATISTICS}
                    iconLeft="isax isax-chart-square"
                  />
                  {isSurvey ? (
                    <div
                      label={TAB_INTEGRATIONS}
                      iconLeft="isax isax-element-plus"
                    />
                  ) : null}
                  <div
                    label={TAB_ISSUES}
                    iconLeft="isax isax-danger"
                    extraLabel={
                      evolution?.countIssues > 0 && (
                        <div className="issues-count">
                          <span className="count">
                            {evolution?.countIssues}
                          </span>
                        </div>
                      )
                    }
                  />
                </Tabs>
              </div>

              <div className="section-content">
                {/* {context === EVOLUTION_CONTEXT_ADOPTION && <FeatureUsageEmptyState />} */}
                {activeTab === TAB_STATISTICS && (
                  <>
                    <Statistics evolution={evolution} />
                    <ListLastUsersReached evolution={evolution} />
                  </>
                )}
                {activeTab === TAB_REPORT && (
                  <Report evolution={evolution} reportRef={reportRef} />
                )}
                {activeTab === TAB_INTEGRATIONS && (
                  <SlackConnect
                    evolution={evolution}
                    refetchEvolution={refetch}
                  />
                )}
                {activeTab === TAB_ISSUES && (
                  <IssueReporting evolution={evolution} refetch={refetch} />
                )}
              </div>
            </div>

            <ModalPublishSuccess
              isOpen={showModalPublishSuccess}
              onRequestClose={() => setShowModalPublishSuccess(false)}
              evolution={evolution}
            />

            <ModalPublishSuccessWithPost
              isOpen={showModalPublishWithPostSuccess}
              onRequestClose={() => setShowModalPublishWithPostSuccess(false)}
              evolution={evolution}
            />
          </div>
        )}
      </div>
    </div>
  );
};
