import classnames from 'classnames';
import {useContext, useEffect, useRef, useState} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {evolutionService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import PostPreview from './components/PostPreview';
import Writer from './components/Writer';

const logger = new Swaler('PostBuilder');

const PostBuilder = () => {
  const {evolution, setOriginalEvolution, setLastAutoSaveAt} =
    useContext(BuilderContext);

  const [savedDraft, setSavedDraft] = useState(evolution);

  const evolutionRef = useRef(evolution);
  const savedDraftRef = useRef();
  const hasSubmittedRef = useRef();

  const hasSubmittedRefCurrent = hasSubmittedRef.current;
  const evolutionRefCurrent = evolutionRef.current;

  useEffect(() => {
    evolutionRef.current = evolution;
  }, [evolution]);

  useEffect(() => {
    savedDraftRef.current = savedDraft;
  }, [savedDraft]);

  // Poke autosave
  useEffect(() => {
    const interval = setInterval(() => {
      if (evolutionRefCurrent?.isDraft === true) {
        saveDraft();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      if (
        hasSubmittedRefCurrent === false &&
        evolutionRefCurrent?.isDraft === true
      ) {
        saveDraft();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveDraft = async () => {
    // check that draft needs to be saved
    if (
      JSON.stringify(evolutionRef.current) ===
      JSON.stringify(savedDraftRef.current)
    ) {
      return;
    }

    try {
      await evolutionService.updateEvolution(evolutionRef.current.uid, {
        ...evolutionRef.current,
      });

      setLastAutoSaveAt(new Date().getTime());
      setSavedDraft(evolutionRef.current);
      setOriginalEvolution({...evolutionRef.current});
    } catch (err) {
      logger.error('Update evolution draft failed with error ', err);
    }
  };

  return (
    <div className={classnames('post-builder')}>
      <Writer />
      <PostPreview />
    </div>
  );
};

export default PostBuilder;
