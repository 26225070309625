import classNames from 'classnames';
import Alert from 'components/Alert';
import Button from 'components/Button';
import {crispHelpers} from 'helpers';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {dataSelector, generalSelector} from 'selectors';
import {subscriptionService} from 'services';
import {Swaler} from 'swaler';

const logger = new Swaler('DowngradeForm');

export const DowngradeForm = ({plan, onCancel, onDowngrade}) => {
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );

  const currentPlan = plans.find((p) => p.uid === subscription.plan);

  const [error, setError] = useState(null);

  const {isFetching: isConfirming, refetch: confirm} = useQuery({
    enabled: false,
    queryKey: 'confirmDowngrade',
    queryFn: async () => {
      setError(null);
      await subscriptionService.createOrUpdateStripeSubscription({
        plan: plan.uid,
      });
      onDowngrade();
    },
    onError: (err) => {
      logger.error('Downgrading failed with error', err);
      setError(err);
    },
    retry: false,
  });

  return (
    <div className={classNames('downgrade-form', {'has-error': error != null})}>
      <div className="label-downgrade">
        Downgrade from{' '}
        <span className="downgrade-from">{currentPlan.title}</span> to
      </div>
      <div className="plans-title">{plan.title}</div>
      {error != null && (
        <Alert danger>
          Ups, something went wrong when trying to downgrade. <br />{' '}
          <span
            className="crisp-trigger"
            onClick={() =>
              crispHelpers.startCrispThread(
                `I need help downgrading from ${currentPlan.title} to ${plan.title}! I'm getting the following error : ${error.message}`
              )
            }>
            &rarr; Talk with our support
          </span>
        </Alert>
      )}
      <div className="actions">
        <Button onClick={onCancel} disabled={isConfirming} muted>
          Cancel
        </Button>
        <Button primary onClick={confirm} loading={isConfirming}>
          Yes, downgrade
        </Button>
      </div>
    </div>
  );
};
