import React, {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {BLOCK_TYPE_STEPPER} from 'services/steps';
import RadioGroup from '../../../../../../components/RadioGroup';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import './_Styles.scss';

const typeOptions = [
  // {label: <i className="icon-plus-rounded" />, value: 'dot'},
  {label: <i className="icon-stepper-text" />, value: 'text'},
  {label: <i className="icon-stepper-line-point" />, value: 'dot-line'},
  {label: <i className="icon-stepper-line" />, value: 'line'},
];

const positionOptions = [
  {label: 'Top', value: 'top'},
  {label: 'Bottom', value: 'bottom'},
];

const alignOptions = [
  {label: <i className="icon-align-b-l" />, value: 'left'},
  {label: <i className="icon-align-b-c" />, value: 'center'},
  {label: <i className="icon-align-b-r" />, value: 'right'},
];

const Stepper = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_STEPPER, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_STEPPER);

  if (block == null) {
    return <></>;
  }

  const {style} = block;

  return (
    <div className="block-settings stepper">
      <div className="section">
        <div className="section-title">Style</div>
        <div className="section-content">
          <StepperStyle
            style={style}
            updateStyle={updateBlock}
            hideStepActive={style.type === 'text'}
          />
        </div>
      </div>
    </div>
  );
};

export const StepperStyle = ({style, updateStyle, hideStepActive = false}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Step</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Step"
            value={style.primaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, primaryColor: value},
              })
            }
          />
        </div>
      </div>
      {hideStepActive !== true && (
        <div className="section-item">
          <div className="section-item-title body-3">Step active</div>
          <div className="section-item-content">
            <ColorPickerInput
              inputProps={{small: true}}
              title="Step active"
              value={style.secondaryColor}
              onChange={(value) =>
                updateStyle({
                  style: {...style, secondaryColor: value},
                })
              }
            />
          </div>
        </div>
      )}
      <div className="section-item">
        <div className="section-item-title body-3">Type</div>
        <div className="section-item-content">
          <RadioGroup
            value={style.type}
            options={typeOptions}
            onSelect={(value) =>
              updateStyle({
                style: {
                  ...style,
                  type: value,
                },
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Position</div>
        <div className="section-item-content">
          <RadioGroup
            value={style.position}
            options={positionOptions}
            onSelect={(value) =>
              updateStyle({
                style: {
                  ...style,
                  position: value,
                },
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Align</div>
        <div className="section-item-content">
          <RadioGroup
            value={style.align}
            options={alignOptions}
            onSelect={(value) =>
              updateStyle({
                style: {
                  ...style,
                  align: value,
                },
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export default Stepper;
