import {default as classNames, default as classnames} from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {Menu, MenuItem} from 'components/Menu';
import {ModalConfirm} from 'components/Modal';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {array, bool, func, object, string} from 'prop-types';
import {useContext, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {BuilderContext} from 'scenes/Builder/context';
import {AVAILABLE_LANGUAGES} from 'scenes/Builder/scenes/Create/components/EvolutionEditorLanguage';
import {generalSelector} from 'selectors';
import {
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import './_Styles.scss';
import {findLanguages} from './utils';

const propTypes = {
  evolution: object,
  selectedLanguage: string,
  disableAddButton: bool,
  onAddLanguage: func.isRequired,
  onSelectLanguage: func.isRequired,
  onRemoveLanguage: func.isRequired,
  defaultLanguages: array,
  languages: array,
  setLanguages: func,
};

const defaultProps = {
  evolution: null,
  selectedLanguage: null,
  disableAddButton: false,
  defaultLanguages: [],
  languages: [],
  setLanguages: () => {},
};

const PostLanguageSelector = ({
  evolution,
  disableAddButton = false,
  onRemoveLanguage,
  defaultLanguages = [],
  languages,
  setLanguages,
}) => {
  const projectSubscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const {selectedLanguage, setSelectedLanguage} = useContext(BuilderContext);
  const {update} = useUpdateSubscription();

  const [activeLanguage, setActiveLanguage] = useState(
    selectedLanguage || null
  );
  const [languageToDelete, setLanguageToDelete] = useState(null);

  const refDropdown = useRef();

  useEffect(() => {
    setLanguages(findLanguages(evolution));
  }, []);

  useEffect(() => {
    if (defaultLanguages?.length > 0) {
      setLanguages(
        defaultLanguages?.map((languageCode) =>
          AVAILABLE_LANGUAGES.find((l) => l.code === languageCode)
        )
      );
    }
  }, [defaultLanguages]);

  const handleAddLanguage = (language) => {
    refDropdown.current.close();

    if (
      [PLAN_STARTUP_ID, PLAN_SCALE_ID, PLAN_GROWTH_ID].includes(
        projectSubscription.plan
      ) === false
    ) {
      return update({planId: PLAN_STARTUP_ID});
    }
    if (languages.find((l) => l.code === language.code) == null) {
      setLanguages(languages.concat(language));
    }
    handleSelectLanguage(language.code);
    setSelectedLanguage(language.code);
  };
  const handleDelete = (languageCode) => {
    setLanguages(languages.filter((l) => l.code !== languageCode));
    handleSelectLanguage(null);
    onRemoveLanguage(languageCode);
    setLanguageToDelete(null);
  };
  const handleSelectLanguage = (languageCode) => {
    setActiveLanguage(languageCode);
    setSelectedLanguage(languageCode);
  };

  return (
    <div className="post-language-selector">
      <div className="language-list">
        {languages.map((l) => {
          return (
            <div
              key={l.code}
              onClick={() => handleSelectLanguage(l.code)}
              className={classnames('language', {
                active: activeLanguage === l.code,
              })}>
              <div className="language-content">
                {l.label}
                {activeLanguage === l.code && (
                  <i
                    className="icon-close"
                    onClick={(e) => {
                      e.stopPropagation();
                      setLanguageToDelete(l.code);
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
        {disableAddButton === false && (
          <Dropdown
            className="dropdown-add-languages"
            innerRef={refDropdown}
            position={'bottom'}
            offsetY={7}
            trigger={
              <Button
                className={classNames('add-translation-btn', {
                  'has-languages': languages.length > 0,
                })}
                iconLeft="icon-plus-rounded"
                thin>
                {languages.length > 0 ? '' : 'Add a translation'}
              </Button>
            }>
            <Menu>
              {AVAILABLE_LANGUAGES.filter(
                (al) => languages.find((l) => l.code === al.code) == null
              )
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((l) => (
                  <MenuItem
                    className="dropdown-language-item"
                    onClick={() => handleAddLanguage(l)}
                    icon={`flag-icon ${l.icon}`}
                    key={l.code}>
                    {l.label}
                  </MenuItem>
                ))}
            </Menu>
          </Dropdown>
        )}
      </div>

      <ModalConfirm
        className="delete-language-modal"
        title="Delete translations?"
        isOpen={languageToDelete != null}
        onConfirm={() => handleDelete(languageToDelete)}
        onCancel={() => setLanguageToDelete(null)}
        cancelText="Cancel"
        confirmText="Delete"
        cancelBtnProps={{
          cta: false,
        }}
        confirmBtnProps={{
          danger: true,
          primary: false,
          cta: false,
        }}>
        <div className="content">
          Are you sure you want to remove this language?
        </div>
      </ModalConfirm>
    </div>
  );
};

PostLanguageSelector.propTypes = propTypes;
PostLanguageSelector.defaultProps = defaultProps;

export default PostLanguageSelector;
