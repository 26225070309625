import classnames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import {ModalConfirm} from 'components/Modal';
import Tabs from 'components/Tabs';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import {getStepIssue} from 'helpers/step';
import {bool, func} from 'prop-types';
import {useContext, useEffect, useRef, useState} from 'react';
import {
  DragDropContext,
  Draggable as DraggableDnd,
  Droppable,
} from 'react-beautiful-dnd';
import Draggable from 'react-draggable';
import {isPokeValid} from 'scenes/Builder/component/BuilderHeader/utils';
import {BuilderContext} from 'scenes/Builder/context';
import {CustomizeOptions} from 'scenes/Builder/scenes/Channel/components/CustomizeOptions';
import {
  BLOCKS,
  DropdownAddBlock,
} from 'scenes/Builder/scenes/Create/components/DropdownAddBlock';
import EditStep from 'scenes/Builder/scenes/Create/components/EditStep';
import EvolutionEditorLanguage from 'scenes/Builder/scenes/Create/components/EvolutionEditorLanguage';
import {evolutionService, stepsService} from 'services';
import {
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_NAVIGATION,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOP_BAR,
  F_BOOST_SLOT_TOUR,
  F_OPTION_SHOW_ON_PORTAL,
} from 'services/evolution';
import {BehaviorOptions} from '../BehaviorOptions';
import TourFooter from '../TourFooter';
import NavigationStep from './components/NavigationStep';
import './_Styles.scss';

const TAB_CONTENT = 'Content';
const TAB_APPEARANCE = 'Appearance';
const TAB_BEHAVIOR = 'Behavior';

const propTypes = {
  onContinue: func,
  onTabChange: func,
  inDalaran: bool,
  hideFooter: bool,
  onSelectElementClick: func,
};

const defaultProps = {
  onContinue: () => {},
  onTabChange: () => {},
  inDalaran: false,
  hideFooter: false,
  onSelectElementClick: () => {},
};

const StepEditor = ({
  onContinue,
  onTabChange,
  inDalaran,
  hideFooter,
  onSelectElementClick,
}) => {
  const [tab, setTab] = useState(TAB_CONTENT);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [addBlockOpen, setAddBlockOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [draggedOffset, setDraggedOffset] = useState({x: 0, y: 0});
  const [stepIdToDelete, setStepIdToDelete] = useState(null);

  const stepEditorRef = useRef();

  const {
    evolution: contextEvolution,
    setEvolution: setContextEvolution,
    setOriginalEvolution,
    customization: contextCustomization,
    setCustomization,
    stepErrors,
    setStepErrors,
    selectedStepId,
    setSelectedStepId,
    selectedTourStepId,
  } = useContext(BuilderContext);

  // handling of evolution and setEvolution for tour steps
  const evolution =
    selectedTourStepId != null
      ? contextEvolution.tourSteps.find((s) => s.uid === selectedTourStepId)
      : contextEvolution;
  const setEvolution = (updatedEvolution) => {
    if (selectedTourStepId != null) {
      setContextEvolution({
        ...contextEvolution,
        tourSteps: contextEvolution.tourSteps.map((s) => {
          if (s.uid === selectedTourStepId) {
            return updatedEvolution;
          } else {
            return s;
          }
        }),
      });
    } else {
      setContextEvolution(updatedEvolution);
    }
  };
  const customization =
    selectedTourStepId != null
      ? contextEvolution.tourSteps.find((s) => s.uid === selectedTourStepId)
      : contextCustomization;

  useEffect(() => {
    if (selectedTourStepId != null) {
      const index = contextEvolution.tourSteps
        ?.map((s) => s.uid)
        .indexOf(selectedTourStepId);
      if (index >= 0) {
        return setSelectedStepId(
          contextEvolution.tourSteps[index]?.steps?.[0]?.uid
        );
      }
    }
  }, [selectedTourStepId]);

  useEffect(() => {
    if (selectedStepId != null) {
      if (stepErrors[selectedStepId] == null) {
        setStepErrors({...stepErrors, [selectedStepId]: false});
      } else {
        setStepErrors({...stepErrors, [selectedStepId]: true});
      }
    }
  }, [selectedStepId]);

  const addStep = (type, fromPrototypeData) => {
    const step = stepsService.createStepObj(type);

    if (fromPrototypeData == null) {
      setEvolution({
        ...evolution,
        steps: evolution.steps.concat({
          ...step,
          indexOrder: evolution.steps.length,
        }),
      });
      setSelectedStepId(step.uid);
    } else {
      const updatedSteps = evolution.steps.map((s) =>
        s.uid === fromPrototypeData.stepId
          ? {
              ...s,
              prototypes: s.prototypes.map((p) =>
                p.uid === fromPrototypeData.prototypeId
                  ? {
                      ...p,
                      steps: p.steps.concat({
                        ...step,
                        indexOrder: p.steps.length,
                      }),
                    }
                  : p
              ),
            }
          : s
      );

      setSelectedStepId(step.uid);
      setEvolution({
        ...evolution,
        steps: updatedSteps,
      });
    }
  };

  const handleAddLanguage = (languageCode) => {
    setEvolution({
      ...evolution,
      texts: (evolution.texts || []).concat({
        language: languageCode,
        title: '',
        content: '',
        rawContent: '',
      }),
    });
  };
  const handleRemoveLanguage = (languageCode) =>
    setEvolution({
      ...evolution,
      steps: evolution.steps.map((s) => ({
        ...s,
        texts: s.texts.filter((t) => t.language !== languageCode),
        prototypes: s.prototypes.map((p) => ({
          ...p,
          steps: p.steps.map((ps) => ({
            ...ps,
            texts: ps.texts.filter((pst) => pst.language !== languageCode),
          })),
        })),
      })),
    });
  const handleStepUpdate = (stepToUpdate) => {
    setEvolution({
      ...evolution,
      steps: evolution.steps.map((s) => {
        if (s.uid === stepToUpdate.uid) {
          return {...s, ...stepToUpdate};
        } else {
          return {
            ...s,
            prototypes: s.prototypes.map((p) => ({
              ...p,
              steps: p.steps.map((ps) =>
                ps.uid === stepToUpdate.uid ? {...ps, ...stepToUpdate} : ps
              ),
            })),
          };
        }
      }),
    });
  };

  const handleContinueClick = async () => {
    setIsLoading(true);
    const savedEvolution = await evolutionService.updateEvolution(
      evolution.uid,
      evolution
    );
    onContinue(savedEvolution);
    setIsLoading(false);
  };

  const onDragStart = ({source, type}) => {};
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const {
      source: {index: sourceIndex},
      destination: {index: destinationIndex},
    } = result;

    const reorderedSteps = Array.from(evolution.steps);
    const [removed] = reorderedSteps.splice(sourceIndex, 1);
    reorderedSteps.splice(destinationIndex, 0, removed);
    reorderedSteps.forEach((s, index) => {
      s.indexOrder = index;
    });

    setEvolution({...evolution, steps: reorderedSteps});
  };

  const handleStop = (e, dragElement) => {
    setDraggedOffset({x: dragElement.x, y: dragElement.y});
  };

  const handleDeleteStep = (stepId) => {
    if (selectedStepId === stepId) {
      const index = evolution.steps.map((s) => s.uid).indexOf(stepId);
      if (index > 0) {
        setSelectedStepId(evolution.steps[index - 1].uid);
      } else if (evolution.steps.length <= 1) {
        setAddBlockOpen(true);
      } else {
        setSelectedStepId(evolution.steps[index + 1]?.uid);
      }
    }

    const updatedSteps = Array.from(evolution.steps).filter(
      (s) => s.uid !== stepId
    );
    updatedSteps.sort((a, b) => a.indexOrder - b.indexOrder);
    updatedSteps.forEach((s, index) => {
      s.indexOrder = index;
    });

    setEvolution({
      ...evolution,
      steps: updatedSteps,
    });
    setStepIdToDelete(null);
  };

  if (evolution == null) {
    return <></>;
  }

  const selectedStep =
    evolution.steps.find((e) => e.uid === selectedStepId) ??
    evolution.steps.reduce((stepToFind, step) => {
      if (step.prototypes.length < 0) {
        return stepToFind;
      }
      step.prototypes.forEach((p) =>
        p.steps.forEach((ps) =>
          ps.uid === selectedStepId ? (stepToFind = ps) : null
        )
      );
      return stepToFind;
    }, null);

  const isOnlyBanner =
    hasFlag(F_BOOST_SLOT_TOP_BAR, contextEvolution.boostFlags) &&
    !hasFlag(F_OPTION_SHOW_ON_PORTAL, contextEvolution.optionsFlags);
  const isSubmitDisabled = isPokeValid(evolution) !== true;
  const isDraft = evolution.isDraft;
  const withSubList =
    evolution.steps.find((s) => s.uid === selectedStepId)?.prototypes?.length >
      0 ||
    evolution.steps
      .map((s) => s.prototypes[0]?.steps.map((e) => e.uid))
      .some((a) => a?.includes(selectedStepId));

  const isTour = hasFlag(F_BOOST_SLOT_TOUR, contextEvolution.boostFlags);

  const isNavigationStep = hasFlag(
    F_BOOST_SLOT_NAVIGATION,
    evolution.boostFlags
  );

  return (
    <>
      <Draggable
        disabled={inDalaran === true}
        handle=".handle"
        onStop={handleStop}>
        <div
          className={classnames('step-editor', {
            'in-app': inDalaran !== true,
            'is-tour': isTour,
          })}
          ref={stepEditorRef}>
          {inDalaran !== true && (
            <div className="handle">
              Edit {evolution.title}
              <div className="icon-drag">
                <i className="icon-move" />
              </div>
            </div>
          )}
          {isTour && selectedTourStepId == null ? (
            <></>
          ) : isNavigationStep ? (
            <NavigationStep evolution={evolution} setEvolution={setEvolution} />
          ) : (
            <Tabs
              defaultTab={tab}
              onTabChange={(tab) => {
                setTab(tab);
                onTabChange();
              }}>
              <div label={TAB_CONTENT}>
                {!isOnlyBanner && (
                  <>
                    <div
                      className={classnames('step-block-list', {
                        'with-sub-list': withSubList,
                      })}>
                      <DragDropContext
                        onDragEnd={onDragEnd}
                        onDragStart={onDragStart}
                        style={{overflow: 'auto'}}>
                        <Droppable
                          droppableId="blocks"
                          type="blocks"
                          direction="horizontal"
                          className="droppable-block-list">
                          {(dropProvided) => (
                            <>
                              <div
                                ref={dropProvided.innerRef}
                                {...dropProvided.droppableProps}>
                                {evolution.steps
                                  .sort((a, b) => a.indexOrder - b.indexOrder)
                                  .map((step, index) => {
                                    const issue = getStepIssue(step);
                                    const blockType = BLOCKS.find(
                                      (b) => b.step === step.type
                                    );

                                    return (
                                      <DraggableDnd
                                        key={step.uid}
                                        draggableId={step.uid}
                                        index={index}
                                        isDragDisabled={
                                          evolution.isDraft !== true
                                        }>
                                        {(dragProvided, snapshot) => {
                                          if (snapshot.isDragging) {
                                            const rect =
                                              stepEditorRef.current?.getBoundingClientRect();
                                            const offset = {
                                              x:
                                                stepEditorRef.current
                                                  ?.offsetLeft +
                                                draggedOffset.x,
                                              y:
                                                stepEditorRef.current
                                                  ?.offsetTop + draggedOffset.y,
                                            }; // your fixed container left/top position
                                            if (inDalaran === true) {
                                              offset.x = offset.x + rect?.x;
                                              offset.y = offset.y + rect?.y;
                                            }

                                            const x =
                                              dragProvided.draggableProps.style
                                                .left - offset.x;
                                            const y =
                                              dragProvided.draggableProps.style
                                                .top - offset.y;
                                            dragProvided.draggableProps.style.left =
                                              x;
                                            dragProvided.draggableProps.style.top =
                                              y;
                                          }
                                          return (
                                            <>
                                              <div
                                                className={classnames(
                                                  'block-wrapper',
                                                  {
                                                    'is-dragging':
                                                      snapshot.isDragging,
                                                  }
                                                )}
                                                ref={dragProvided.innerRef}
                                                {...dragProvided.draggableProps}
                                                {...dragProvided.dragHandleProps}>
                                                <div
                                                  className={classnames(
                                                    'block-selector',
                                                    {
                                                      selected:
                                                        step.uid ===
                                                        selectedStepId,
                                                      invalid:
                                                        selectedStepId !==
                                                          step.uid &&
                                                        issue != null,
                                                      'is-dragging':
                                                        snapshot.isDragging,
                                                      'with-sub-list':
                                                        (evolution.steps.find(
                                                          (s) =>
                                                            s.uid ===
                                                            selectedStepId
                                                        )?.prototypes?.length >
                                                          0 &&
                                                          step.uid ===
                                                            selectedStepId) ||
                                                        step.prototypes[0]?.steps
                                                          .map((e) => e.uid)
                                                          .includes(
                                                            selectedStepId
                                                          ),
                                                    }
                                                  )}
                                                  style={{
                                                    background:
                                                      blockType.iconBackgroundColor,
                                                    border: `2px solid ${blockType.iconBackgroundColor}`,
                                                  }}
                                                  onClick={() =>
                                                    setSelectedStepId(step.uid)
                                                  }
                                                  provided={dragProvided}>
                                                  <div className="block-type-icon">
                                                    {blockType.icon}
                                                  </div>
                                                  {!isOnlyBanner && isDraft && (
                                                    <i
                                                      className="icon-close"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        setStepIdToDelete(
                                                          step.uid
                                                        );
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                                {step.prototypes?.length > 0 &&
                                                  (selectedStepId ===
                                                    step.uid ||
                                                    step.prototypes[0]?.steps
                                                      .map((e) => e.uid)
                                                      .includes(
                                                        selectedStepId
                                                      )) && (
                                                    <div className="sub-blocks-wrapper">
                                                      {step.prototypes[0]?.steps?.map(
                                                        (prototypeStep) => {
                                                          const issue =
                                                            getStepIssue(
                                                              prototypeStep
                                                            );
                                                          const blockType =
                                                            BLOCKS.find(
                                                              (b) =>
                                                                b.step ===
                                                                prototypeStep.type
                                                            );

                                                          return (
                                                            <div
                                                              className={classnames(
                                                                'sub-block-selector',
                                                                {
                                                                  selected:
                                                                    prototypeStep.uid ===
                                                                    selectedStepId,
                                                                  invalid:
                                                                    selectedStepId !==
                                                                      prototypeStep.uid &&
                                                                    issue !=
                                                                      null,
                                                                  'is-dragging':
                                                                    snapshot.isDragging,
                                                                }
                                                              )}
                                                              style={{
                                                                background:
                                                                  blockType.iconBackgroundColor,
                                                                border: `2px solid ${blockType.iconBackgroundColor}`,
                                                              }}
                                                              onClick={() =>
                                                                setSelectedStepId(
                                                                  prototypeStep.uid
                                                                )
                                                              }
                                                              provided={
                                                                dragProvided
                                                              }>
                                                              <div className="block-type-icon">
                                                                {blockType.icon}
                                                              </div>
                                                              {!isOnlyBanner &&
                                                                isDraft && (
                                                                  <i
                                                                    className="icon-close"
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.preventDefault();
                                                                      e.stopPropagation();

                                                                      if (
                                                                        selectedStepId ===
                                                                        prototypeStep.uid
                                                                      ) {
                                                                        const prototypeSteps =
                                                                          step
                                                                            .prototypes[0]
                                                                            ?.steps;
                                                                        const index =
                                                                          prototypeSteps
                                                                            .map(
                                                                              (
                                                                                s
                                                                              ) =>
                                                                                s.uid
                                                                            )
                                                                            .indexOf(
                                                                              prototypeStep.uid
                                                                            );
                                                                        if (
                                                                          index >
                                                                          0
                                                                        ) {
                                                                          setSelectedStepId(
                                                                            prototypeSteps[
                                                                              index -
                                                                                1
                                                                            ]
                                                                              .uid
                                                                          );
                                                                        } else if (
                                                                          prototypeSteps.length <=
                                                                          1
                                                                        ) {
                                                                        } else {
                                                                          setSelectedStepId(
                                                                            prototypeSteps[
                                                                              index +
                                                                                1
                                                                            ]
                                                                              ?.uid
                                                                          );
                                                                        }
                                                                      }
                                                                      setEvolution(
                                                                        {
                                                                          ...evolution,
                                                                          steps:
                                                                            evolution.steps.map(
                                                                              (
                                                                                s
                                                                              ) => {
                                                                                if (
                                                                                  s.prototypes?.[0]?.steps
                                                                                    ?.map(
                                                                                      (
                                                                                        pS
                                                                                      ) =>
                                                                                        pS.uid
                                                                                    )
                                                                                    ?.includes(
                                                                                      prototypeStep.uid
                                                                                    )
                                                                                ) {
                                                                                  const updatedSteps =
                                                                                    Array.from(
                                                                                      s
                                                                                        .prototypes[0]
                                                                                        .steps
                                                                                    ).filter(
                                                                                      (
                                                                                        pS
                                                                                      ) =>
                                                                                        pS.uid !==
                                                                                        prototypeStep.uid
                                                                                    );
                                                                                  updatedSteps.sort(
                                                                                    (
                                                                                      a,
                                                                                      b
                                                                                    ) =>
                                                                                      a.indexOrder -
                                                                                      b.indexOrder
                                                                                  );
                                                                                  updatedSteps.forEach(
                                                                                    (
                                                                                      s,
                                                                                      index
                                                                                    ) => {
                                                                                      s.indexOrder =
                                                                                        index;
                                                                                    }
                                                                                  );

                                                                                  s.prototypes[0].steps =
                                                                                    updatedSteps;
                                                                                }
                                                                                return s;
                                                                              }
                                                                            ),
                                                                        }
                                                                      );
                                                                    }}
                                                                  />
                                                                )}
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                      {evolution.isDraft ===
                                                        true && (
                                                        <DropdownAddBlock
                                                          dropdownProps={{
                                                            position:
                                                              'right center',
                                                            trigger: (
                                                              <div className="btn-add-sub-block">
                                                                <i className="icon-plus"></i>
                                                              </div>
                                                            ),
                                                            triggerClassName:
                                                              'sub-list-dropdown-trigger',
                                                          }}
                                                          omitBlocks={[
                                                            stepsService.STEP_TYPE_CONCEPT_TEST,
                                                          ]}
                                                          onAdd={(stepToAdd) =>
                                                            addStep(stepToAdd, {
                                                              stepId: step.uid,
                                                              prototypeId:
                                                                step
                                                                  .prototypes[0]
                                                                  ?.uid,
                                                            })
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                  )}
                                              </div>
                                              {dragProvided.placeholder}
                                            </>
                                          );
                                        }}
                                      </DraggableDnd>
                                    );
                                  })}
                              </div>
                              {dropProvided.placeholder}
                            </>
                          )}
                        </Droppable>
                      </DragDropContext>
                      {evolution.isDraft === true && !isOnlyBanner && (
                        <DropdownAddBlock
                          open={addBlockOpen || !(evolution.steps.length > 0)}
                          onClose={() => setAddBlockOpen(false)}
                          dropdownProps={{
                            position: 'left',
                            trigger: (
                              <div className="icon-wrapper">
                                <i className="icon-plus" />
                              </div>
                            ),
                            triggerClassName: 'list-dropdown-trigger',
                          }}
                          // omitBlocks={
                          //   context === EVOLUTION_CONTEXT_ADOPTION
                          //     ? [
                          //         stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
                          //         stepsService.STEP_TYPE_TEXT_LONG,
                          //         stepsService.STEP_TYPE_NPS,
                          //         stepsService.STEP_TYPE_SLIDER,
                          //         stepsService.STEP_TYPE_TYPEFORM,
                          //         stepsService.STEP_TYPE_CONCEPT_TEST,
                          //         stepsService.STEP_TYPE_INTERVIEW,
                          //       ]
                          //     : []
                          // }
                          onAdd={addStep}
                        />
                      )}
                    </div>
                    <Divider />
                  </>
                )}

                {selectedStep != null && (
                  <>
                    <EvolutionEditorLanguage
                      evolution={evolution}
                      onSelectLanguage={(code) => setSelectedLanguage(code)}
                      onAddLanguage={handleAddLanguage}
                      onRemoveLanguage={handleRemoveLanguage}
                    />
                    <EditStep
                      key={selectedStep.uid}
                      steps={evolution.steps}
                      step={selectedStep}
                      selectedLanguage={selectedLanguage}
                      updateStep={handleStepUpdate}
                      evolution={evolution}
                      updateEvolutionOptions={(options) =>
                        setEvolution({...evolution, optionsFlags: options})
                      }
                      showErrors={stepErrors[selectedStepId]}
                      disabled={evolution.isDraft === false}
                    />
                  </>
                )}
              </div>
              <div label={TAB_APPEARANCE}>
                <CustomizeOptions
                  boostFlags={evolution.boostFlags}
                  evolution={evolution}
                  setEvolution={setEvolution}
                  setOriginalEvolution={setOriginalEvolution}
                  customization={customization}
                  setCustomization={setCustomization}
                  cardless
                  withSaveDefault
                  isTourStep={isTour}
                  onSelectElementClick={inDalaran ? null : onSelectElementClick}
                />
              </div>
              {hasFlags(
                [F_BOOST_SLOT_DOT, F_BOOST_SLOT_TOOLTIP, F_BOOST_SLOT_TOP_BAR],
                evolution.boostFlags,
                true
              ) || isTour ? (
                <div label={TAB_BEHAVIOR}>
                  <BehaviorOptions
                    evolution={evolution}
                    setEvolution={setEvolution}
                    isTourStep={isTour}
                  />
                </div>
              ) : (
                <></>
              )}
            </Tabs>
          )}
          {inDalaran !== true && hideFooter !== true && (
            <div className="footer-wrapper">
              <Button
                cta
                className="continue-btn"
                secondary
                light
                onClick={handleContinueClick}
                iconRight="icon-chevron-right"
                disabled={isSubmitDisabled}
                loading={isLoading}>
                Continue to audience
              </Button>
            </div>
          )}
          {inDalaran === true && isTour && (
            <TourFooter
              inDalaran
              stepEditorRef={stepEditorRef}
              draggedOffset={draggedOffset}
            />
          )}
        </div>
      </Draggable>
      <ModalConfirm
        className="delete-block-modal"
        title="Delete block?"
        isOpen={stepIdToDelete != null}
        onConfirm={() => handleDeleteStep(stepIdToDelete)}
        onCancel={() => setStepIdToDelete(null)}
        cancelText="Cancel"
        confirmText="Delete"
        cancelBtnProps={{
          cta: false,
        }}
        confirmBtnProps={{
          danger: true,
          primary: false,
          cta: false,
        }}>
        <div className="content">
          Are you sure you want to remove this block?
        </div>
      </ModalConfirm>
    </>
  );
};

StepEditor.propTypes = propTypes;
StepEditor.defaultProps = defaultProps;

export default StepEditor;
