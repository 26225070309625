import React from 'react';
import './_Styles.scss';
import AudienceSection from 'scenes/PokeAudience/components/AudienceSection';
import SummaryInfos from './components/SummaryInfos';
import SummaryPreview from './components/SummaryPreview';
import {number} from 'prop-types';

const propTypes = {
  index: number.isRequired,
};

const defaultProps = {};

const Summary = ({index}) => {
  return (
    <AudienceSection
      className="summary"
      index={index}
      title="Summary"
      isLastSection>
      <SummaryInfos />
      <SummaryPreview />
    </AudienceSection>
  );
};

Summary.propTypes = propTypes;
Summary.defaultProps = defaultProps;

export default Summary;
