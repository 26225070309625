import {toastDanger} from 'components/Toaster';
import {PermissionsSettings} from 'constants/permissions';
import {GlobalContext} from 'contextes/Global';
import {errorHelpers} from 'helpers';
import {hasPermissions} from 'helpers/permission';
import {useContext, useState} from 'react';
import {subscriptionService} from 'services';
import {getCurrentAddons, hasAnnualBilling} from 'services/subscription';
import {Swaler} from 'swaler';
import {useUpdatePlan} from './useUpdatePlan';

const logger = new Swaler('useUpgradePlan');

export const useUpgradePlan = ({
  onSuccess = () => {},
  onError = () => {},
} = {}) => {
  const {setModalPlanUpgrade, setModalWelcomePlan} = useContext(GlobalContext);

  const {update} = useUpdatePlan();

  const [isUpgrading, setIsUpgrading] = useState(false);

  async function upgrade(planId) {
    setModalPlanUpgrade({
      planId,
      onCancel: () => {
        setModalPlanUpgrade(null);
      },
      onSubmit: async () => {
        await doUpgrade(planId).then(() => {
          setModalPlanUpgrade(null);
          setModalWelcomePlan({planId, refreshProjectAfterClose: true});
        });
      },
    });
  }

  async function doUpgrade(planId) {
    const canDo = hasPermissions([PermissionsSettings.BILLING_ACCESS]);

    if (canDo === false) {
      return toastDanger([
        'Permission denied',
        "You don't have the permissions to upgrade the plan. For more information, please contact your project administrator.",
      ]);
    }
    setIsUpgrading(true);
    try {
      await subscriptionService.createOrUpdateStripeSubscription({
        plan: planId,
        addons: getCurrentAddons(),
        modeAnnual: hasAnnualBilling(),
      });
      await update(planId);
      onSuccess();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsUpgrading(false);
      logger.error('Upgrading plan failed with error ', code);
      toastDanger([title, message], {
        actions,
      });
      throw new Error('UPGRADING_FAILED');
    } finally {
      setIsUpgrading(false);
    }
  }

  return {
    upgrade,
    isUpgrading,
  };
};
