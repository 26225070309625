import {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {
  BLOCK_TYPE_ANIMATION,
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_CONCEPT,
  BLOCK_TYPE_HINT,
  BLOCK_TYPE_HOTSPOT,
  BLOCK_TYPE_INTERVIEW,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_STEPPER,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
} from 'services/steps';
import Animation from './blocks/Animation';
import Button from './blocks/Button';
import Concept from './blocks/Concept';
import Group from './blocks/Group';
import Interview from './blocks/Interview';
import Media from './blocks/Media';
import MultipleChoice from './blocks/MultipleChoice';
import Nps from './blocks/Nps';
import OpenQuestion from './blocks/OpenQuestion';
import OpinionScale from './blocks/OpinionScale';
import Paragraph from './blocks/Paragraph';
import Profile from './blocks/Profile';
import Slider from './blocks/Slider';
import Stepper from './blocks/Stepper';
import Tag from './blocks/Tag';
import Title from './blocks/Title';
import './_Styles.scss';
import Hotspot from './blocks/Hotspot';
import {hasFlag} from 'helpers/bitwise';
import {F_SLOT_NAVIGATION} from 'shared/front/components/Poke/constants/poke';
import Navigation from './blocks/Navigation';
import Hint from './blocks/Hint';

const propTypes = {};
const defaultProps = {};

const BlockEditor = () => {
  const {selectedStepId, selectedStep, selectedBlockType, controlledEvolution} =
    useContext(BuilderContext);

  const isNavigationStep = hasFlag(
    F_SLOT_NAVIGATION,
    controlledEvolution?.boostFlags
  );

  if ((selectedStepId == null || selectedStep == null) && !isNavigationStep) {
    return <></>;
  }

  return (
    <div className="poke-block-editor-wrapper">
      {selectedBlockType == null && !isNavigationStep && <Group />}
      {selectedBlockType === BLOCK_TYPE_TITLE && <Title />}
      {selectedBlockType === BLOCK_TYPE_BODY && <Paragraph />}
      {[BLOCK_TYPE_PRIMARY_CTA, BLOCK_TYPE_SECONDARY_CTA].includes(
        selectedBlockType
      ) && <Button />}
      {selectedBlockType === BLOCK_TYPE_LABEL && <Tag />}
      {selectedBlockType === BLOCK_TYPE_MEDIA && <Media />}
      {selectedBlockType === BLOCK_TYPE_STEPPER && <Stepper />}
      {selectedBlockType === BLOCK_TYPE_USER && <Profile />}
      {selectedBlockType === BLOCK_TYPE_CHOICE && <MultipleChoice />}
      {selectedBlockType === BLOCK_TYPE_SLIDER && <Slider />}
      {selectedBlockType === BLOCK_TYPE_NPS && <Nps />}
      {selectedBlockType === BLOCK_TYPE_OPINION && <OpinionScale />}
      {selectedBlockType === BLOCK_TYPE_ANIMATION && <Animation />}
      {selectedBlockType === BLOCK_TYPE_OPEN_QUESTION && <OpenQuestion />}
      {selectedBlockType === BLOCK_TYPE_CONCEPT && <Concept />}
      {selectedBlockType === BLOCK_TYPE_INTERVIEW && <Interview />}
      {selectedBlockType === BLOCK_TYPE_HOTSPOT && <Hotspot />}
      {selectedBlockType === BLOCK_TYPE_HINT && <Hint />}
      {isNavigationStep && <Navigation />}
    </div>
  );
};

BlockEditor.propTypes = propTypes;
BlockEditor.defaultProps = defaultProps;

export default BlockEditor;
