import {dataActions} from 'actions';
import classnames from 'classnames';
import Loader from 'components/Loader';
import ModalPlanUpgrade, {
  MODE_CHECKOUT,
  MODE_DOWNGRADE,
  MODE_LAUNCH_TRIAL,
} from 'components/ModalPlanUpgradeOld';
import Toggle from 'components/Toggle';
import {Environment} from 'conf/env';
import {hasFlag} from 'helpers/bitwise';
import posthog from 'posthog-js';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {dataSelector, generalSelector} from 'selectors';
import {subscriptionService} from 'services';
import {isEligibleToTrial} from 'services/project';
import {
  F_IS_PAYING,
  F_IS_TRIALING,
  F_MODE_ANNUAL_BILLING,
  PLAN_BASIC_ID,
  hasSaasMantraPlan,
  isUpgrade,
} from 'services/subscription';
import {Swaler} from 'swaler';
import PlanCard from '../components/PlanCard/index.v2';
import './_Styles.scss';

const SUPPORTED_CURRENCIES = ['$', '€', '£'];

const logger = new Swaler('SettingsSubscriptionPlan');

const SettingsSubscriptionPlan = () => {
  const dispatch = useDispatch();

  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );
  const projectSubscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const setSubscriptionPlans = (plans) =>
    dispatch(dataActions.setSubscriptionPlans(plans));

  const [upgradeToPlan, setUpgradeToPlan] = useState(null);
  const [currency, setCurrency] = useState('$');
  const [loading, setLoading] = useState(true);
  const [modeAnnual, setModeAnnual] = useState(true);

  const classNames = classnames('s-settings-subscription-plan', {
    'is-loading': loading === true,
    'fade-in': loading === false,
  });

  const fetchSubscriptionPlans = async () => {
    try {
      const plans = await subscriptionService.getSubscriptionPlans();

      setSubscriptionPlans(plans);
      setLoading(false);
    } catch (err) {
      return logger.error('Fetching plans failed with error ', err);
    }
  };
  const handlePlanUpgrade = (planId) => {
    setUpgradeToPlan(planId);
  };

  useEffect(() => {
    fetchSubscriptionPlans();
  }, []);

  const isPaying = hasFlag(F_IS_PAYING, projectSubscription.extraFlags);

  if (loading === true) {
    return (
      <div className={classNames}>
        <Loader></Loader>
      </div>
    );
  }
  return (
    <div className={classNames}>
      <div className="settings-body">
        {hasSaasMantraPlan() === true && (
          <div className="deal-saas-mantra">Saas Mantra</div>
        )}
        <div className="header-wrapper">
          <div></div>
          <div
            className={classnames('price-mode', {
              'is-paying': isPaying,
            })}>
            Monthly{' '}
            <Toggle
              disabled={isPaying}
              checked={
                isPaying === true
                  ? hasFlag(
                      F_MODE_ANNUAL_BILLING,
                      projectSubscription.extraFlags
                    )
                  : modeAnnual
              }
              onChange={() => setModeAnnual(!modeAnnual)}
            />{' '}
            Annual <span>-16%</span>
          </div>
          <a
            without
            rel="noreferrer"
            onClick={() => {
              if (Environment.NODE_ENV === 'production') {
                posthog.capture('go-to-plan-comparison');
              }
            }}
            href="https://www.usejimo.com/pricing"
            target="_blank">
            View plan comparison <i className="icon-goto"></i>
          </a>
        </div>
        <div className="plans">
          {[]
            .concat(plans)
            .sort((a, b) => a.index - b.index)
            .filter((p) => p.uid !== PLAN_BASIC_ID)
            .map((p) => (
              <PlanCard
                id={p.uid}
                prices={p.prices}
                mau={p.mau}
                seats={p.seats}
                modeAnnual={modeAnnual}
                onUpgrade={handlePlanUpgrade}
                onCancel={() => handlePlanUpgrade(PLAN_BASIC_ID)}
              />
            ))}
        </div>
      </div>
      <ModalPlanUpgrade
        isOpen={upgradeToPlan != null}
        initialMode={
          upgradeToPlan == null
            ? undefined
            : isEligibleToTrial(upgradeToPlan) === true
            ? MODE_LAUNCH_TRIAL
            : isUpgrade(upgradeToPlan) === true ||
              (upgradeToPlan !== PLAN_BASIC_ID &&
                hasFlag(F_IS_TRIALING, projectSubscription.extraFlags) === true)
            ? MODE_CHECKOUT
            : hasFlag(F_IS_PAYING, projectSubscription.extraFlags) === false &&
              upgradeToPlan !== PLAN_BASIC_ID
            ? MODE_CHECKOUT
            : MODE_DOWNGRADE
        }
        planId={upgradeToPlan}
        defaultModeAnnualBilling={modeAnnual}
        onRequestClose={() => setUpgradeToPlan(null)}></ModalPlanUpgrade>
    </div>
  );
};

export default SettingsSubscriptionPlan;
