import classNames from 'classnames';
import Button from 'components/Button';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import Label from 'components/Label';
import DefaultLoader from 'components/Loader';
import Tooltip from 'components/Tooltip';
import dayjs from 'dayjs';
import {hasFlag} from 'helpers/bitwise';
import {object} from 'prop-types';
import React, {useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_POKE_BUILDER_FROM_TYPE} from 'router/routes.const';
import {
  ACTION_BOOK_INTERVIEW,
  ACTION_LAUNCH_EXPERIENCE,
  ACTION_POST_OPEN,
  ACTION_URL_OPEN,
} from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Action';
import {evolutionAnalyticsService} from 'services';
import {F_BOOST_SLOT_NAVIGATION} from 'services/evolution';
import {BLOCK_TYPE_PRIMARY_CTA, BLOCK_TYPE_SECONDARY_CTA} from 'services/steps';
import {Swaler} from 'swaler';
import UsersReachedDrawer from '../UsersReachedDrawer';
import './_Styles.scss';
import CtaAction from './imgs/tooltip_action.svg';

const CtaActivityItem = ({evolution, cta, totalClicksCount}) => {
  const [showUsersModal, setShowUsersModal] = useState(false);

  const {
    index,
    step,
    removed,
    updateData,
    value,
    clicksCount,
    uniqueClicksCount,
  } = cta;

  // eslint-disable-next-line no-unused-vars
  const [text, _, optAction] = value.split(';');

  let typeName =
    cta.type === BLOCK_TYPE_PRIMARY_CTA ? 'Primary Button' : 'Secondary Button';
  let actionLabel, actionIcon;
  if (optAction === ACTION_URL_OPEN) {
    actionLabel = 'Open URL';
    actionIcon = 'icon-link';
  } else if (optAction === ACTION_POST_OPEN) {
    actionLabel = 'Open Post';
    actionIcon = 'icon-portal';
  } else if (optAction === ACTION_BOOK_INTERVIEW) {
    actionLabel = 'Open calendar';
    actionIcon = 'icon-calendar';
  } else if (optAction === ACTION_LAUNCH_EXPERIENCE) {
    actionLabel = 'Launch experience';
    actionIcon = 'isax isax-routing-2';
  }

  const percentageOfTotalClicks =
    totalClicksCount > 0
      ? Math.round((clicksCount * 100) / totalClicksCount)
      : 0;

  return (
    <div className="cta-activity-item">
      <div className="cta-activity-item-content">
        <div className="cta-info">
          <div className="cta-basic-info">
            Step {index + 1} &bull; {typeName}
          </div>
          <div className="cta-name">"{text}"</div>
          {percentageOfTotalClicks > 0 && (
            <div
              className="chart-wrapper"
              style={{
                width: `${percentageOfTotalClicks}%`,
              }}
            />
          )}
          {(step.removed === true ||
            removed === true ||
            ['added', 'modified'].includes(updateData?.operation)) && (
            <div className="label-hidden-wrapper">
              <Label
                size="small"
                type="neutral"
                primary
                iconLeft={
                  step.removed === true || removed === true
                    ? 'isax isax-eye-slash'
                    : null
                }
                className="label-hidden">
                {step.removed === true || removed === true
                  ? 'Hidden'
                  : updateData.operation === 'added'
                  ? 'Added'
                  : 'Modified'}
              </Label>
            </div>
          )}
        </div>
        <div className="cta-action">
          <i className={actionIcon} />
          {actionLabel}
        </div>
        <div className="cta-clicks">{clicksCount || '-'}</div>
        <div
          className={classNames('cta-users', {
            clickable: uniqueClicksCount > 0,
          })}
          onClick={() => {
            if (uniqueClicksCount > 0) {
              setShowUsersModal(true);
            }
          }}>
          {uniqueClicksCount || '-'}
        </div>
      </div>

      {showUsersModal && (
        <UsersReachedDrawer
          isOpen={showUsersModal}
          onRequestClose={() => setShowUsersModal(false)}
          block={cta}
          evolution={evolution}
          title={`Users who clicked on "${text}"`}
        />
      )}
    </div>
  );
};

const propTypes = {
  evolution: object,
  startDate: object.isRequired,
  endDate: object.isRequired,
};

const defaultProps = {
  evolution: null,
};

const logger = new Swaler('CtaActivity');

const CtaActivity = ({evolution, startDate, endDate}) => {
  const history = useHistory();

  const steps = useMemo(() => {
    return evolution?.tourSteps?.length > 0
      ? evolution?.tourSteps
          .filter(
            (t) => hasFlag(F_BOOST_SLOT_NAVIGATION, t.boostFlags) !== true
          )
          .map((t) =>
            t.steps.map((s) => {
              const tourStepIndex = (t.tourStepInfo || '0;0;0').split(';')[0];
              return {...s, _tourStepIndex: tourStepIndex};
            })
          )
          .flat()
          .sort((a, b) => {
            if (a._tourStepIndex === b._tourStepIndex) {
              return a.indexOrder - b.indexOrder;
            }
            return a._tourStepIndex - b._tourStepIndex;
          })
      : evolution?.steps;
  }, [evolution]);

  const ctas = steps?.reduce((acc, step, index) => {
    const stepCtas = step.blocks.filter((b) => {
      if ([BLOCK_TYPE_PRIMARY_CTA, BLOCK_TYPE_SECONDARY_CTA].includes(b.type)) {
        const {value} = b;
        // eslint-disable-next-line no-unused-vars
        const [text, action, optAction, urlOrPostId] = value.split(';');

        if (
          [
            ACTION_URL_OPEN,
            ACTION_POST_OPEN,
            ACTION_BOOK_INTERVIEW,
            ACTION_LAUNCH_EXPERIENCE,
          ].includes(optAction)
        ) {
          return true;
        }
      }
      return false;
    });
    acc.push(...stepCtas.map((cta) => ({...cta, step, index})));
    return acc;
  }, []);

  const {
    data: analyticsHourlyByStep = [],
    isLoading: isLoadingAnalyticHourlyByStep,
  } = useQuery({
    queryKey: [
      'analyticsHourlyPerBlock',
      ...(ctas?.map((c) => c.uid) || []),
      startDate,
      endDate,
    ],
    queryFn: () => {
      return evolutionAnalyticsService.getAnalyticsHourlyByBlock({
        startDate,
        endDate: dayjs(endDate).add(1, 'day').toDate(),
        blockIds: ctas?.map((c) => c.uid) || [],
      });
    },
    enabled: ctas?.length > 0,
    onError: (err) => {
      logger.error(
        'Could not fetch steps analytics, failed with err',
        err.message
      );
    },
  });

  const blocksWithAnalytics = analyticsHourlyByStep?.reduce((acc, cur) => {
    const index = acc.map((block) => block.uid).indexOf(cur.block_uid);
    if (index >= 0) {
      if (acc[index].uniqueClicksCount == null) {
        acc[index].uniqueClicksCount = parseInt(
          cur.sum_unique_clicks_count,
          10
        );
      } else {
        acc[index].uniqueClicksCount =
          acc[index].uniqueClicksCount +
          parseInt(cur.sum_unique_clicks_count, 10);
      }
      if (acc[index].clicksCount == null) {
        acc[index].clicksCount = parseInt(cur.sum_clicks_count, 10);
      } else {
        acc[index].clicksCount =
          acc[index].clicksCount + parseInt(cur.sum_clicks_count, 10);
      }
    }
    return acc;
  }, JSON.parse(JSON.stringify(ctas || [])));

  const totalClicksCount = blocksWithAnalytics?.reduce(
    (acc, cur) => (acc += cur.clicksCount || 0),
    0
  );

  return (
    <div className="cta-activity">
      {ctas?.length === 0 ? (
        <div className="empty-state-wrapper">
          <EmptyStateBlock
            img={EmptyStateImgs.EmptyCta}
            title="Start tracking your Call-to-Action clicks"
            description="Configure your button actions with 'Open Post' or 'Open URL' to begin tracking"
          />
          <Button
            primary
            iconLeft={'icon-pencil-edit-outline'}
            onClick={() =>
              history.push(
                ROUTE_POKE_BUILDER_FROM_TYPE(evolution.uid, evolution.type)
              )
            }>
            Edit experience
          </Button>
        </div>
      ) : isLoadingAnalyticHourlyByStep ? (
        <div className="loader-wrapper">
          <DefaultLoader />
        </div>
      ) : (
        <>
          <div className="cta-activity-header">
            <div>Button Label</div>
            <div style={{justifySelf: 'right'}}>
              Action
              <Tooltip
                className="cta-action-tooltip"
                offsetY={8}
                arrow={true}
                trigger={<i className="icon-info-circle-o" />}>
                <img src={CtaAction} alt="cta action" />
                Only buttons with actions set to 'Open Post', 'Open URL' & 'Open
                calendar' are tracked.
              </Tooltip>
            </div>
            <div style={{justifySelf: 'right'}}>Clicks</div>
            <div style={{justifySelf: 'right'}}>Users</div>
          </div>
          <div className="cta-activity-content">
            {blocksWithAnalytics?.map((cta) => {
              return (
                <CtaActivityItem
                  evolution={evolution}
                  cta={cta}
                  totalClicksCount={totalClicksCount}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

CtaActivity.propTypes = propTypes;
CtaActivity.defaultProps = defaultProps;

export default CtaActivity;
