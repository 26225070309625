import {EVENT_ADD_BLOCK} from 'amplitude';
import amplitude from 'amplitude-js';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import {useRef, useState} from 'react';
import {stepsService} from 'services';
import Block from '../Block';
import typeform from './icons/typeform.svg';
import './_Styles.scss';

export const CATEGORY_1 = 'Ask';
export const CATEGORY_2 = 'Test';
export const CATEGORY_3 = 'Say';

export const BLOCKS = [
  {
    category: CATEGORY_1,
    title: 'Opinion scale',
    description: 'Measure opinion with rating scale',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-vote-opinion" />,
    step: stepsService.STEP_TYPE_OPINION_SCALE,
    className: 'step-opinion-scale',
  },
  {
    category: CATEGORY_1,
    title: 'Multiple choice',
    description: 'Ask a question with multiple choices',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-vote-multi-choice" />,
    step: stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    className: 'step-multi-choice',
  },
  {
    category: CATEGORY_1,
    title: 'Open question',
    description: 'Collect text',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-vote-open-question" />,
    step: stepsService.STEP_TYPE_TEXT_LONG,
    className: 'step-text-long',
  },
  {
    category: CATEGORY_1,
    title: 'NPS',
    description: 'Measure with a net promoter score',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-vote-nps" />,
    step: stepsService.STEP_TYPE_NPS,
    className: 'step-nps',
  },
  {
    category: CATEGORY_1,
    title: 'Emoji slider',
    description: 'Measure opinion with sliding scale',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-vote-slider" />,
    step: stepsService.STEP_TYPE_SLIDER,
    className: 'step-slider',
  },
  {
    category: CATEGORY_1,
    title: 'Typeform',
    description: 'Integrate a typeorm survey',
    iconBackgroundColor: '#fdefe6',
    comingSoon: true,
    icon: (
      <img
        src={typeform}
        alt="typeform-icon"
        className="icon"
        style={{height: '25px'}}
      />
    ),
    step: stepsService.STEP_TYPE_TYPEFORM,
    className: 'step-survey-typeform',
  },
  {
    category: CATEGORY_2,
    title: 'Concept test',
    description: 'Conduct a research on your design',
    iconBackgroundColor: '#E6F5FD',
    icon: <i className="icon-filter-preview" />,
    step: stepsService.STEP_TYPE_CONCEPT_TEST,
    className: 'step-concept-test',
  },
  {
    category: CATEGORY_2,
    title: 'Interview prompt',
    description: 'Invite users to book a slot in your calendar',
    iconBackgroundColor: '#E6F5FD',
    beta: true,
    icon: <i className="icon-user-interview" />,
    step: stepsService.STEP_TYPE_INTERVIEW,
    className: 'step-user-interview',
  },
  {
    category: CATEGORY_3,
    title: 'Text block',
    description: 'Write basic texts',
    iconBackgroundColor: '#E6FDE8',
    icon: <i className="icon-filter-announcement" />,
    step: stepsService.STEP_TYPE_TEXT_BLOCK,
    className: 'step-text-block',
  },
  {
    category: CATEGORY_3,
    title: 'Thank you',
    description: 'End the interaction with joy',
    iconBackgroundColor: '#E6FDE8',
    icon: <i className="icon-heart-o" />,
    step: stepsService.STEP_TYPE_SUCCESS,
    className: 'step-thanks-block',
  },
  // add single choice to prevent unnamed block
  {
    title: 'Multiple choice',
    description: 'Ask a question with multiple choices',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-vote-multi-choice" />,
    step: stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    className: 'step-multi-choice',
  },
];

export const getFilteredBlocks = (search) => {
  return search.length === 0
    ? BLOCKS
    : BLOCKS.filter(
        (b) =>
          b.title.toLowerCase().includes(search.toLowerCase()) ||
          b.description.toLowerCase().includes(search.toLowerCase())
      );
};

export const DropdownAddBlock = ({
  open = null,
  dropdownProps = {},
  omitBlocks = [],
  onAdd = () => {},
  onClose = () => {},
}) => {
  const [search, setSearch] = useState('');

  const refDropdownAdd = useRef();

  const handleAdd = (step) => {
    onAdd(step);
    refDropdownAdd.current.close();
  };
  const blocksCategory1 = getFilteredBlocks(search).filter(
    (b) => b.category === CATEGORY_1 && omitBlocks.includes(b.step) === false
  );
  const blocksCategory2 = getFilteredBlocks(search).filter(
    (b) => b.category === CATEGORY_2 && omitBlocks.includes(b.step) === false
  );
  const blocksCategory3 = getFilteredBlocks(search).filter(
    (b) => b.category === CATEGORY_3 && omitBlocks.includes(b.step) === false
  );

  return (
    <Dropdown
      innerRef={refDropdownAdd}
      className="dropdown-add-block"
      position="top right"
      offsetX={12}
      onClose={() => {
        setSearch('');
        onClose();
      }}
      repositionOnResize={false}
      open={open}
      {...dropdownProps}>
      <div className="search-wrapper">
        <Input
          labelTextLeft={<i className="icon-search"></i>}
          placeholder="Find a block type"
          muted
          value={search}
          onChange={({target}) => setSearch(target.value)}></Input>
      </div>
      <div className="block-categories">
        {blocksCategory1.length > 0 && (
          <>
            <div className="category-title">{CATEGORY_1}</div>
            <div className="items">
              {blocksCategory1.map((b, index) => (
                <Block
                  key={b.step || index}
                  muted
                  iconBackgroundColor={b.iconBackgroundColor}
                  title={b.title}
                  description={b.description}
                  icon={b.icon}
                  comingSoon={b.comingSoon}
                  beta={b.beta}
                  onClick={() => {
                    amplitude.getInstance().logEvent(EVENT_ADD_BLOCK, {
                      type: b.title,
                    });
                    handleAdd(b.step);
                  }}
                />
              ))}
            </div>
          </>
        )}
        {blocksCategory2.length > 0 && (
          <>
            <div className="category-title">{CATEGORY_2}</div>
            <div className="items">
              {blocksCategory2.map((b, index) => (
                <Block
                  key={b.step || index}
                  muted
                  iconBackgroundColor={b.iconBackgroundColor}
                  title={b.title}
                  description={b.description}
                  icon={b.icon}
                  comingSoon={b.comingSoon}
                  beta={b.beta}
                  onClick={() => {
                    amplitude.getInstance().logEvent(EVENT_ADD_BLOCK, {
                      type: b.title,
                    });
                    handleAdd(b.step);
                  }}
                />
              ))}
            </div>
          </>
        )}
        {blocksCategory3.length > 0 && (
          <>
            <div className="category-title">{CATEGORY_3}</div>
            <div className="items">
              {blocksCategory3.map((b, index) => (
                <Block
                  key={b.step || index}
                  muted
                  iconBackgroundColor={b.iconBackgroundColor}
                  title={b.title}
                  description={b.description}
                  icon={b.icon}
                  comingSoon={b.comingSoon}
                  beta={b.beta}
                  onClick={() => {
                    amplitude.getInstance().logEvent(EVENT_ADD_BLOCK, {
                      type: b.title,
                    });
                    handleAdd(b.step);
                  }}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </Dropdown>
  );
};
