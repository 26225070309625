import classnames from 'classnames';
import Lottie from 'lottie-react';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  BLOCK_OPINION,
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_STEPPER,
  getDefaultResponseForBlock,
} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {useDisableSubmitOnVote} from '../../hooks/useDisableSubmitOnVote';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';
import star from './animations/star.json';

export const OPINION_TYPE_SMILEY = 'smiley';
export const OPINION_TYPE_STAR = 'star';
export const OPINION_TYPE_EMOJI = 'emoji';
export const OPINION_SCALE_3 = '3';
export const OPINION_SCALE_4 = '4';
export const OPINION_SCALE_5 = '5';
export const OPINION_SCALE_6 = '6';
export const OPINION_SCALE_7 = '7';

export const parseOpinionValue = (value) => {
  const [type, scale, labelLeft, labelRight] = value.split(';');

  return {
    type,
    scale: parseInt(scale, 10),
    labelLeft,
    labelRight,
  };
};

export const opinionTypes = {
  [OPINION_TYPE_SMILEY]: {
    [OPINION_SCALE_7]: [
      {label: '😡', value: '1'},
      {label: '🙁', value: '2'},
      {label: '😕', value: '3'},
      {label: '😐', value: '4'},
      {label: '🙂', value: '5'},
      {label: '😀', value: '6'},
      {label: '😍', value: '7'},
    ],
    [OPINION_SCALE_6]: [
      {label: '😡', value: '1'},
      {label: '🙁', value: '2'},
      {label: '😕', value: '3'},
      {label: '🙂', value: '5'},
      {label: '😀', value: '6'},
      {label: '😍', value: '7'},
    ],
    [OPINION_SCALE_5]: [
      {label: '😡', value: '1'},
      {label: '🙁', value: '2'},
      {label: '😐', value: '4'},
      {label: '😀', value: '6'},
      {label: '😍', value: '7'},
    ],
    [OPINION_SCALE_4]: [
      {label: '😡', value: '1'},
      {label: '🙁', value: '2'},
      {label: '😀', value: '6'},
      {label: '😍', value: '7'},
    ],
    [OPINION_SCALE_3]: [
      {label: '😡', value: '1'},
      {label: '😐', value: '4'},
      {label: '😍', value: '7'},
    ],
  },
  [OPINION_TYPE_EMOJI]: {},
};

export const BlockOpinion = () => {
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    response = getDefaultResponseForBlock(BLOCK_OPINION),
    updateResponse,
    submitResponse,
    language,
  } = useContext(PokeStateContext);

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_OPINION,
  });
  const disableSubmitOnVote = useDisableSubmitOnVote(currentStep);

  const [starHoverIndex, setStarHoverIndex] = useState(-1);

  const block = currentStep.blocks.find((b) => b.type === BLOCK_OPINION);
  const style = block.style || {};
  const value = parseOpinionValue(block.value);
  const [responseValue] =
    typeof response === 'string' ? response?.split(';') : [response];

  const handleResponse = (index) => {
    if (inBuilder === true) {
      return;
    }
    updateResponse(index);
    if (disableSubmitOnVote === false) {
      submitResponse({response: index});
    }
  };

  const translation = block.translations?.find((t) => t.language === language);
  const [translatedLabelLeft, translatedLabelRight] = translation?.value?.split(
    ';'
  ) ?? [value.labelLeft, value.labelRight];

  const hasCta =
    currentStep.blocks.some((b) => {
      return (
        (b.type === BLOCK_PRIMARY_CTA && b.removed !== true) ||
        (b.type === BLOCK_SECONDARY_CTA && b.removed !== true)
      );
    }) === true;

  const hasStepperBelow =
    hasCta !== true &&
    currentStep.blocks.some((b) => {
      return b.type === BLOCK_STEPPER && b.style.position === 'bottom';
    }) === true;

  return (
    <div
      className={classnames('poke-block-opinion', `type-${value.type}`, {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        'has-cta': hasCta === true,
        'has-stepper-below': hasStepperBelow === true,
        'is-bottom-block': hasCta !== true && hasStepperBelow !== true,
        selected: selectedBlock === BLOCK_OPINION,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_OPINION);
      }}>
      <div className="opinion-wrapper" style={{gap: style.padding}}>
        {[OPINION_TYPE_SMILEY, OPINION_TYPE_EMOJI].includes(value.type) &&
          opinionTypes[value.type][value.scale].map((item) => (
            <div
              key={item.value}
              className={classnames(`opinion-item opinion-${value.type}`, {
                'is-active': responseValue === item.value,
                'is-muted': response.length > 0 && responseValue !== item.value,
              })}
              style={{
                fontSize: style.fontSize,
                height: style.fontSize,
                width: style.fontSize,
                padding: style.fontSize / 3,
              }}
              onClick={() => handleResponse(item.value)}>
              {item.label}
            </div>
          ))}
        {value.type === OPINION_TYPE_STAR &&
          new Array(value.scale).fill(0).map((s, i) => (
            <Star
              key={i}
              fill={i < starHoverIndex}
              targeted={i === starHoverIndex}
              onMouseEnter={() => {
                setStarHoverIndex(() => i);
              }}
              onMouseLeave={() => {
                setStarHoverIndex(() =>
                  responseValue >= 1 ? responseValue - 1 : -1
                );
              }}
              style={{height: style.fontSize}}
              onClick={() => {
                setStarHoverIndex(i);
                handleResponse(i + 1);
              }}
            />
          ))}
      </div>
      <div
        className="labels-wrapper"
        style={{
          fontFamily: 'Inter',
        }}>
        <div className="label-left">{translatedLabelLeft}</div>
        <div className="label-right">{translatedLabelRight}</div>
      </div>
      <ClickableBlockOverlay />
    </div>
  );
};

const Star = ({
  fill = false,
  targeted = false,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  onClick = () => {},
  style = {},
}) => {
  const refLottie = useRef(null);

  const triggerAnimation = () => {
    if (refLottie.current == null) {
      return;
    }
    refLottie.current.playSegments([0, 50]);
    refLottie.current.play();
  };
  const triggerFill = () => {
    if (refLottie.current == null) {
      return;
    }
    refLottie.current.playSegments([49, 50], true);
    refLottie.current.play();
  };
  const triggerUnFill = () => {
    if (refLottie.current == null) {
      return;
    }
    if (fill === false) {
      refLottie.current.playSegments([0, 1], true);
      refLottie.current.play();
    }
  };

  useEffect(() => {
    if (refLottie.current != null) {
      if (fill === true) {
        triggerFill();
      } else {
        triggerUnFill();
      }
      if (targeted === true) {
        triggerAnimation();
      }
    }
  }, [fill, targeted, triggerUnFill]);

  return (
    <div
      className="opinion-item opinion-star"
      onMouseEnter={() => {
        onMouseEnter();
      }}
      onMouseLeave={() => {
        onMouseLeave();
      }}
      style={style}
      onClick={onClick}>
      <div className="star-wrapper">
        <Lottie
          lottieRef={refLottie}
          animationData={star}
          autoPlay={false}
          loop={false}
        />
      </div>
    </div>
  );
};
