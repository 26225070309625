import {toastDanger} from 'components/Toaster';
import {PermissionsSettings} from 'constants/permissions';
import {GlobalContext} from 'contextes/Global';
import {errorHelpers} from 'helpers';
import {useJimoIdentify} from 'helpers/jimoOnJimo';
import {hasPermissions} from 'helpers/permission';
import {useContext, useState} from 'react';
import {subscriptionService} from 'services';
import {PLAN_BASIC_ID} from 'services/subscription';
import {Swaler} from 'swaler';
import {useUpdatePlan} from './useUpdatePlan';

const logger = new Swaler('useCancelPlan');

export const useCancelPlan = ({
  onSuccess = () => {},
  onError = () => {},
} = {}) => {
  const {setModalFeedbackCancelPlan, setModalPlanCancelDone} =
    useContext(GlobalContext);
  const {pushAttributes} = useJimoIdentify({forceIsIdentified: true});

  const {update} = useUpdatePlan();

  const [isCanceling, setIsCanceling] = useState(false);

  async function cancel() {
    setModalFeedbackCancelPlan({
      onCancel: () => {
        setModalFeedbackCancelPlan(null);
      },
      onSubmit: async () => {
        await doCancel().then(() => {
          setModalFeedbackCancelPlan(null);
          setModalPlanCancelDone(true);
        });
      },
    });
  }

  async function doCancel() {
    const canDo = hasPermissions([PermissionsSettings.BILLING_ACCESS]);

    if (canDo === false) {
      return toastDanger([
        'Permission denied',
        "You don't have the permissions to cancel the plan. For more information, please contact your project administrator.",
      ]);
    }
    setIsCanceling(true);
    try {
      await subscriptionService.createOrUpdateStripeSubscription({
        plan: PLAN_BASIC_ID,
      });
      await update(PLAN_BASIC_ID);
      onSuccess();
      pushAttributes({
        isTrialing: false,
        isPaying: false,
        hasTrialEnded: true,
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsCanceling(false);
      logger.error('Canceling plan failed with error ', code);
      toastDanger([title, message], {
        actions,
      });
      throw new Error('CANCEL_FAILED');
    } finally {
      setIsCanceling(false);
    }
  }

  return {
    cancel,
    isCanceling,
  };
};
