import {generalActions} from 'actions';
import classnames from 'classnames';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Input from 'components/Input';
import {toastDanger} from 'components/Toaster';
import {Environment} from 'conf/env';
import {errorHelpers} from 'helpers';
import {errorCodes} from 'helpers/error';
import {useJimoIdentify} from 'helpers/jimoOnJimo';
import {posthog} from 'posthog-js';
import {useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {useDispatch} from 'react-redux';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {
  ROUTE_LOGIN,
  ROUTE_ONBOARDING_DETAILS_STEP_1_1,
  ROUTE_REGISTER_EMAIL_SENT,
} from 'router/routes.const';
import ButtonGoogle from 'scenes/Onboarding/components/ButtonGoogle';
import {authService} from 'services';
import {AUTH_TYPE_EMAIL} from 'services/auth';
import {Swaler} from 'swaler';
import './_Styles.scss';
import logo from './imgs/logo.svg';

const logger = new Swaler('Register');

export const OnboardingRegister = ({playAnimationOut, triggerAnimationOut}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const {identify} = useJimoIdentify();

  const setUser = (user) => dispatch(generalActions.setUser(user));

  const [isGoogleDisabled, setIsGoogleDisabled] = useState(false);
  const [errorRegister, setErrorRegister] = useState(null);
  const [isRegistering, setIsRegistering] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const [inputEmail, setInputEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsChecking(true);
    try {
      await authService.checkEmail(inputEmail);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsChecking(false);
      setIsRegistering(null);
      logger.error('Failed to sign up using password with error ', code);
      toastDanger([title, message], {actions, toastId: 'register-error'});
      return;
    }
    try {
      await authService.signUpWithEmailPassword({
        email: inputEmail,
        locale: navigator.language,
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);
      setIsChecking(false);
      setIsRegistering(null);

      if (
        [
          errorCodes.EMAIL_ALREADY_TAKEN,
          errorCodes.EMAIL_IS_NOT_CORPORATE,
        ].includes(code) === false
      ) {
        logger.error('Failed to sign up using password with error ', code);
      }
      toastDanger([title, message], {actions, toastId: 'register-error'});
      return;
    }
    triggerAnimationOut();
    setTimeout(() => {
      history.push(ROUTE_REGISTER_EMAIL_SENT);
    }, 1000);
  };
  const handleGoogleLoginFailure = (response) => {
    logger.error(`Google Login Failure`, response);
    setIsRegistering(null);
    if (response.error === 'idpiframe_initialization_failed') {
      setIsGoogleDisabled(true);
      setErrorRegister(
        `Google login is not available because of the following error : ${response?.details}`
      );
      return;
    }
    setErrorRegister('We could not sign up with Google!');
  };
  const handleGoogleLoginSuccess = async (response) => {
    const {access_token} = response;

    try {
      const signUpResult = await authService.signUpWithGoogle(access_token, {
        locale: navigator.language,
      });

      setUser(signUpResult.user); // Sign In
      if (Environment.NODE_ENV === 'production') {
        posthog.identify(signUpResult.user.uid);
      }
      identify(signUpResult.user);
      handleGoToDetails({
        playDetailsAnimationIn: true,
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      if (
        [
          errorCodes.EMAIL_ALREADY_TAKEN,
          errorCodes.EMAIL_IS_NOT_CORPORATE,
        ].includes(code) === false
      ) {
        logger.error('Failed to sign up with google, failed with error ', code);
      }
      setIsRegistering(null);
      toastDanger([title, message], {actions});
      return;
    }
  };

  const handleGoToDetails = (historyState = {}) => {
    triggerAnimationOut({
      name: 'from-register-to-details',
      duration: 500,
      next: () => {
        history.push(ROUTE_ONBOARDING_DETAILS_STEP_1_1, {
          ...historyState,
          playDetailsAnimationIn: true,
        });
      },
    });
  };

  return (
    <div
      className={classnames('s-onboarding-register', {
        'is-exiting': playAnimationOut === true,
      })}>
      <img className="jimo-logo" src={logo} alt="logo jimo" height={44} />
      <h1>
        {location.state?.fromJoin === true ? (
          <>
            Create your account and <span>join your team</span>!
          </>
        ) : (
          <>
            Get started with your <span>free trial</span> today!
          </>
        )}
      </h1>
      <AnimateHeight height={errorRegister !== null ? 'auto' : 0}>
        <RegisterError error={errorRegister} />
      </AnimateHeight>
      <ButtonGoogle
        fullwidth
        disabled={
          isGoogleDisabled === true || isRegistering === AUTH_TYPE_EMAIL
        }
        loading={isRegistering != null && isRegistering !== AUTH_TYPE_EMAIL}
        onSuccess={handleGoogleLoginSuccess}
        onFailure={handleGoogleLoginFailure}
        onClick={() => {
          setIsRegistering(true);
          setErrorRegister(null);
        }}>
        Sign up with Google
      </ButtonGoogle>
      <div className="divider-wrapper">
        <Divider dark>OR</Divider>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="email-label">Email</div>
        <Input
          placeholder="me@company.com"
          required
          type="email"
          value={inputEmail}
          onChange={({target}) => setInputEmail(target.value)}></Input>
        <Button
          className="btn-sign-up"
          cta
          secondary
          iconRight="icon-chevron-right"
          loading={isChecking}>
          Sign up for free
        </Button>
      </form>
      <Link to={ROUTE_LOGIN}>I already have an account</Link>
      <div className="tos">
        By signing up, you agree to the{' '}
        <a href="https://www.usejimo.com/terms-services">Terms of Service</a>{' '}
        and <a href="https://www.usejimo.com/privacy">Privacy Policy</a>.
      </div>
    </div>
  );
};

function RegisterError({error}) {
  let alertBody = null;

  if (Array.isArray(error)) {
    alertBody = (
      <ul>
        {error.map((e, i) => (
          <li key={i}>{e}</li>
        ))}
      </ul>
    );
  }
  if (typeof error === 'string') {
    if (error === 'EMAIL_ALREADY_TAKEN') {
      alertBody = (
        <div>
          This email is already taken, try to{' '}
          <Link to={ROUTE_LOGIN}>sign in</Link>!
        </div>
      );
    } else if (error === 'EMAIL_NOT_VERIFIED') {
      alertBody = (
        <div>
          We sent you an email to verify your email, check your inbox and spams.
        </div>
      );
    } else {
      alertBody = error;
    }
  }
  return <Alert danger>{alertBody}</Alert>;
}
