import React, {useContext, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {evolutionAnalyticsService} from 'services';
import {object} from 'prop-types';
import './_Styles.scss';
import {Swaler} from 'swaler';
import {hasFlag} from 'helpers/bitwise';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_TOUR,
  F_BOOST_SLOT_NAVIGATION,
} from 'services/evolution';
import DefaultLoader from 'components/Loader';
import {getIcon} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal';
import {getTypeFromBoostFlags} from 'scenes/Builder/component/CtaModal';
import Button from 'components/Button';
import dayjs from 'dayjs';
import UsersReachedDrawer from '../UsersReachedDrawer';
import {PokeStatisticsContext} from '../..';
import classNames from 'classnames';
import {HiddenStepTooltip} from 'scenes/Poke/component/Overview';
import Label from 'components/Label';

const StepActivityItem = ({
  evolution,
  tourStep,
  step,
  percentage,
  count,
  hasLabel,
}) => {
  const [showUsersModal, setShowUsersModal] = useState(false);

  const icon = getIcon(
    getTypeFromBoostFlags(tourStep?.boostFlags || evolution?.boostFlags)
  );

  return (
    <div
      className={classNames('step-activity-item', {
        'is-removed': step.removed,
      })}>
      <div className="step-stats">
        <div className="percentage">{percentage}%</div>
        <div className="users-count">{count} users</div>
      </div>
      <div className="chart-wrapper">
        {percentage > 0 && (
          <div
            className="chart"
            style={{
              height: `${Math.min(100, percentage)}%`,
            }}
          />
        )}
        <Button
          className="btn-see-users"
          thin
          onClick={() => {
            setShowUsersModal(true);
          }}>
          See users
        </Button>
      </div>
      <div className="step-info-wrapper">
        <div className="step-info">
          <div className="step-icon">{icon}</div>
          <div className="step-name">{step.name}</div>
        </div>
        {hasLabel && (
          <>
            {step.removed === true ? (
              <HiddenStepTooltip
                step={step}
                trigger={
                  <div className="label-hidden-wrapper">
                    <Label
                      size="small"
                      type="neutral"
                      primary
                      iconLeft="isax isax-eye-slash"
                      className="label-hidden">
                      Hidden
                    </Label>
                  </div>
                }
              />
            ) : step.updateData != null ? (
              <div className="label-hidden-wrapper">
                <Label
                  size="small"
                  type="neutral"
                  primary
                  className="label-hidden">
                  {step.updateData.operation === 'added'
                    ? 'Added'
                    : step.updateData.operation === 'reordered'
                    ? 'Reordered'
                    : 'Modified'}
                </Label>
              </div>
            ) : (
              <div className="label-placeholder" />
            )}
          </>
        )}
      </div>
      {showUsersModal && (
        <UsersReachedDrawer
          isOpen={showUsersModal}
          onRequestClose={() => setShowUsersModal(false)}
          evolution={evolution}
          step={step}
        />
      )}
    </div>
  );
};

const propTypes = {
  evolution: object,
  startDate: object.isRequired,
  endDate: object.isRequired,
};

const defaultProps = {
  evolution: null,
};

const logger = new Swaler('StepsActivityNew');

const StepsActivityNew = () => {
  const {evolution, timeRange} = useContext(PokeStatisticsContext);

  const [startDate, endDate] = timeRange;

  const steps = useMemo(() => {
    return evolution?.tourSteps?.length > 0
      ? evolution?.tourSteps
          .filter(
            (t) => hasFlag(F_BOOST_SLOT_NAVIGATION, t.boostFlags) !== true
          )
          .map((t) =>
            t.steps.map((s) => {
              const tourStepIndex = (t.tourStepInfo || '0;0;0').split(';')[0];
              return {...s, _tourStepIndex: tourStepIndex, poke: t};
            })
          )
          .flat()
          .sort((a, b) => {
            if (a._tourStepIndex === b._tourStepIndex) {
              return a.indexOrder - b.indexOrder;
            }
            return a._tourStepIndex - b._tourStepIndex;
          })
      : evolution?.steps;
  }, [evolution]);

  const {
    data: analyticsHourlyByStep = [],
    isLoading: isLoadingAnalyticHourlyByStep,
  } = useQuery({
    queryKey: ['analyticsHourlyPerStep', evolution.uid, startDate, endDate],
    queryFn: () => {
      return evolutionAnalyticsService.getAnalyticsHourlyByStep({
        startDate,
        endDate: dayjs(endDate).add(1, 'day').toDate(),
        evolutionIds: [evolution.uid],
      });
    },
    onError: (err) => {
      logger.error(
        'Could not fetch steps analytics, failed with err',
        err.message
      );
    },
  });

  const {data: analyticsHourly, isLoading: isLoadingAnalyticsHourly} = useQuery(
    {
      queryKey: ['analyticsHourly', evolution.uid, startDate, endDate],
      queryFn: () => {
        return evolutionAnalyticsService.getAnalyticsHourly({
          startDate,
          endDate: dayjs(endDate).add(1, 'day').toDate(),
          evolutionIds: [evolution.uid],
        });
      },
      onError: (err) => {
        logger.error('Could not fetch analytics, failed with err', err.message);
      },
    }
  );

  const stepsWithAnalytics = analyticsHourlyByStep?.reduce((acc, cur) => {
    const index = acc.map((step) => step.uid).indexOf(cur.step_uid);
    if (index >= 0) {
      if (acc[index].uniqueViewsCount == null) {
        acc[index].uniqueViewsCount = parseInt(cur.sum_unique_views_count, 10);
      } else {
        acc[index].uniqueViewsCount =
          acc[index].uniqueViewsCount +
          parseInt(cur.sum_unique_views_count, 10);
      }
    }
    return acc;
  }, JSON.parse(JSON.stringify(steps || [])));

  const uniqueTourStartedCount = analyticsHourly?.reduce((acc, cur) => {
    return acc + parseInt(cur.sum_unique_tour_started_count, 10);
  }, 0);
  const evolutionUniqueViewsCount = analyticsHourly?.reduce((acc, cur) => {
    return acc + parseInt(cur.sum_unique_views_count, 10);
  }, 0);

  const isAdoption = [
    EVOLUTION_TYPE_BANNER,
    EVOLUTION_TYPE_TOUR,
    EVOLUTION_TYPE_HINT,
  ].includes(evolution?.type);

  const denominator = isAdoption
    ? uniqueTourStartedCount
    : evolutionUniqueViewsCount;

  const hasHiddenStep = stepsWithAnalytics.some((s) => s.removed);
  const hasLabel = stepsWithAnalytics.some((s) => s.updateData != null);

  return (
    <div className="steps-activity-new">
      {isLoadingAnalyticHourlyByStep || isLoadingAnalyticsHourly ? (
        <div className="loader-wrapper">
          <DefaultLoader />
        </div>
      ) : (
        <div className="steps-activity-content">
          {hasHiddenStep === true && (
            <div className="hidden-step-warning">
              <i className="icon-info-circle-o n-700" />
              <div className="content">
                <div className="title subtitle-4 n-800">
                  You have hidden step(s) in this Experience
                </div>
                <div className="description body-4 n-700">
                  Analytics of the hidden step(s) are still available but the
                  step will be skipped for your users.
                </div>
              </div>
            </div>
          )}
          <div className="steps-list">
            {stepsWithAnalytics?.map((step) => {
              const percentage =
                denominator > 0 && step.uniqueViewsCount > 0
                  ? Math.round((step.uniqueViewsCount * 100) / denominator)
                  : 0;

              return (
                <StepActivityItem
                  evolution={evolution}
                  tourStep={step.poke}
                  step={step}
                  percentage={percentage}
                  count={step.uniqueViewsCount}
                  hasLabel={hasLabel}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

StepsActivityNew.propTypes = propTypes;
StepsActivityNew.defaultProps = defaultProps;

export default StepsActivityNew;
