import classnames from 'classnames';
import {toastDanger} from 'components/Toaster';
import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  genKey,
  Modifier,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {errorHelpers} from 'helpers';
import htmlToDraft from 'html-to-draftjs';
import {List} from 'immutable';
import {markdownToDraft} from 'markdown-draft-js';
import {array, func, string} from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import sanitizeHtml from 'sanitize-html';
import {fileService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('WysiwygEditor');

const handlePastedText = (text, html, editorState, onChange) => {
  const selectedBlock = getSelectedBlock(editorState);
  if (selectedBlock && selectedBlock.type === 'code') {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    return true;
  } else if (html) {
    const fixedHTML = sanitizeHtml(html, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        'img',
        'iframe',
        'ins',
        'del',
      ]),
      allowedIframeHostnames: [
        'www.youtube.com',
        'player.vimeo.com',
        'www.loom.com',
      ],
      allowedAttributes: {
        iframe: ['src'],
        img: ['src', 'alt'],
        a: ['href', 'target'],
      },
    });
    const contentBlock = htmlToDraft(fixedHTML);
    let contentState = editorState.getCurrentContent();
    contentBlock.entityMap.forEach((value, key) => {
      contentState = contentState.mergeEntityData(key, value);
    });
    contentState = Modifier.replaceWithFragment(
      contentState,
      editorState.getSelection(),
      new List(contentBlock.contentBlocks)
    );
    onChange(EditorState.push(editorState, contentState, 'insert-fragments'));
    return true;
  }
  return false;
};

const vimeoPattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;

const EmbeddedLinkBlock = ({blockProps}) => {
  const {src} = blockProps;
  return (
    <div className="embedded-link">
      <iframe title="embedded-link-iframe" src={src} />
    </div>
  );
};

const ImageBlock = (props) => {
  const {block, contentState, handleDelete} = props;

  const data = contentState.getEntity(block.getEntityAt(0)).getData();
  const emptyHtml = ' ';

  return (
    <div className="embedded-image-wrapper">
      {emptyHtml}
      <div className="img-wrapper">
        <img
          src={data.src}
          alt={data.alt || ''}
          style={{
            height: data.height || 'auto',
            width: data.width || 'auto',
          }}></img>
        <div className="image-remove-btn" onClick={handleDelete}>
          <i className="icon-trash" />
        </div>
      </div>
    </div>
  );
};

const getSelectedBlock = (editorState) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const blockStartKey = selection.getStartKey();

  return contentState.getBlockMap().get(blockStartKey);
};

const propTypes = {
  value: string.isRequired,
  rawValue: string,
  onChange: func.isRequired,
  excludeFromToolbar: array,
  language: string,
};
const defaultProps = {
  rawValue: null,
  excludeFromToolbar: [],
  language: null,
};

const WysiwygEditor = ({
  value,
  rawValue,
  language,
  onChange,
  excludeFromToolbar,
}) => {
  const [editorState, setEditorState] = useState(null);
  const [focus, setFocus] = useState(false);

  const rawValueRef = useRef();
  const editorStateRef = useRef();

  useEffect(() => {
    rawValueRef.current = rawValue;
  }, [rawValue]);

  useEffect(() => {
    editorStateRef.current = editorState;
  }, [editorState]);

  useEffect(() => {
    let rawData;

    if (rawValue) {
      rawData = JSON.parse(rawValue);
    } else {
      rawData = markdownToDraft(value, {
        blockEntities: {
          image: function (item) {
            return {
              type: 'IMAGE',
              mutability: 'IMMUTABLE',
              data: {
                src: item.src,
                alt: item.alt,
              },
            };
          },
        },
      });

      rawData = {
        ...rawData,
        blocks: rawData.blocks
          .map((block) => {
            const entityKey =
              block.entityRanges?.length > 0 ? block.entityRanges[0].key : null;
            const hasImage =
              entityKey != null &&
              rawData.entityMap[entityKey].type === 'IMAGE';

            if (hasImage) {
              const aroundBlock = (text = '') => ({
                data: {},
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: [],
                key: genKey(),
                text,
                type: 'unstyled',
              });

              return [
                aroundBlock(),
                {
                  ...block,
                  ...{
                    key: genKey(),
                    type: 'atomic',
                    text: ' ',
                    entityRanges: block.entityRanges?.map((range) => ({
                      ...range,
                      length: 1,
                    })),
                  },
                },
                aroundBlock(block.text),
              ];
            }

            return block;
          })
          .flat(),
      };
    }
    const contentState = convertFromRaw(rawData);
    setEditorState(EditorState.createWithContent(contentState));
  }, [language]);

  const handleDeleteImage = (block) => {
    let contentState = editorStateRef.current.getCurrentContent();
    const rawObject = convertToRaw(contentState);
    const index = rawObject.blocks.map((b) => b.key).indexOf(block.key);
    if (
      rawObject.blocks[index + 1]?.type === 'unstyled' &&
      rawObject.blocks[index + 1]?.text === ''
    ) {
      rawObject.blocks.splice(index + 1, 1);
    }
    rawObject.blocks.splice(index, 1);
    if (
      rawObject.blocks[index - 1]?.type === 'unstyled' &&
      rawObject.blocks[index - 1]?.text === ''
    ) {
      rawObject.blocks.splice(index - 1, 1);
    }

    const keysToDelete = Object.keys(rawObject.entityMap)
      .filter((key) => rawObject.entityMap[key].type !== 'LINK')
      .map((key) => {
        if (
          !rawObject.blocks
            .filter((b) => b.type === 'atomic')
            .map((b) => b.entityRanges.map((e) => e.key.toString()))
            .flat()
            .includes(key)
        ) {
          return key;
        }
      })
      .filter((k) => k);

    keysToDelete.map((key) => {
      rawObject.blocks.forEach(
        (b) =>
          (b.entityRanges = b.entityRanges.filter(
            (e) => e.key.toString() !== key
          ))
      );
      delete rawObject.entityMap[key];
    });

    const markdown = draftToHtml(rawObject);
    onChange({content: markdown, rawContent: JSON.stringify(rawObject)});

    contentState = convertFromRaw(rawObject);
    setEditorState(EditorState.createWithContent(contentState));
  };

  const handleChange = (editorState, a) => {
    setEditorState(editorState);

    const content = editorState.getCurrentContent();
    const rawObject = convertToRaw(content);

    const keysToDelete = Object.keys(rawObject.entityMap)
      .filter((key) => rawObject.entityMap[key].type !== 'LINK')
      .map((key) => {
        if (
          !rawObject.blocks
            .filter((b) => b.type === 'atomic')
            .map((b) => b.entityRanges.map((e) => e.key.toString()))
            .flat()
            .includes(key)
        ) {
          return key;
        }
      })
      .filter((k) => k);

    keysToDelete.map((key) => {
      rawObject.blocks.forEach(
        (b) =>
          (b.entityRanges = b.entityRanges.filter(
            (e) => e.key.toString() !== key
          ))
      );
      delete rawObject.entityMap[key];
    });

    const markdown = draftToHtml(rawObject);
    onChange({content: markdown, rawContent: JSON.stringify(rawObject)});
  };

  const handleUploadImage = async (file) => {
    try {
      const uploadedFile = await fileService.uploadPublicFile({file});
      return {data: {link: uploadedFile.publicUrl}};
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Uploading files failed with error `, code);
      return toastDanger([title, message], {actions});
    }
  };

  const getYoutubeId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };
  const handleYoutubeUrl = (url) => {
    const videoId = getYoutubeId(url);
    url = `https://www.youtube.com/embed/${videoId}`;
    return url;
  };
  const handleEmbedUrl = (url) => {
    if (url.startsWith('https://www.loom.com/share')) {
      return url.replace('share', 'embed');
    }
    if (getYoutubeId(url)) {
      return handleYoutubeUrl(url);
    }
    if (vimeoPattern.test(url) && !url.includes('player.vimeo.com')) {
      return url.replace(vimeoPattern, 'https://player.vimeo.com/video/$1');
    }
    return url;
  };

  return (
    <Editor
      handlePastedText={handlePastedText}
      customBlockRenderFunc={(block) => {
        // check that rawValue is neither null nor empty string
        if (rawValueRef.current && block.type === 'atomic') {
          const {blocks, entityMap} = JSON.parse(rawValueRef.current);
          const foundBlock = blocks.find((b) => b.key === block.key);
          const entity = entityMap[foundBlock?.entityRanges[0]?.key];
          if (entity?.type === 'EMBEDDED_LINK') {
            return {
              component: EmbeddedLinkBlock,
              editable: true,
              props: {
                src: entity.data.src,
              },
            };
          } else if (entity?.type === 'IMAGE') {
            return {
              component: (props) => {
                return (
                  <ImageBlock
                    {...props}
                    handleDelete={() => handleDeleteImage(props.block)}
                  />
                );
              },
              editable: false,
            };
          } else {
            return {
              component: () => <></>,
            };
          }
        }
      }}
      editorState={editorState}
      wrapperClassName="wysiwyg-wrapper"
      editorClassName={classnames('wysiwyg-editor', {focused: focus})}
      toolbarClassName="wysiwyg-toolbar"
      onEditorStateChange={handleChange}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      toolbar={{
        options: [
          'blockType',
          'inline',
          'list',
          'link',
          'image',
          'embedded',
          'emoji',
        ].filter((o) => excludeFromToolbar.includes(o) === false),
        inline: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ['bold', 'italic', 'underline', 'strikethrough'],
          bold: {
            icon: null,
            className: 'inline-option-bold icon-text-bold',
          },
          italic: {
            icon: null,
            className: 'inline-option-italic icon-text-italic',
          },
          underline: {
            icon: null,
            className: 'inline-option-underline icon-text-underline',
          },
          strikethrough: {
            icon: null,
            className: 'inline-option-strikethrough icon-text-strike',
          },
        },
        blockType: {
          inDropdown: true,
          options: ['Normal', 'H1', 'H2', 'H3'],
          className: 'block-type',
          dropdownClassName: 'block-type-dropdown',
        },
        list: {
          className: undefined,
          options: ['unordered', 'ordered'],
          unordered: {
            icon: null,
            className: 'list-option-bullet icon-list-bullet',
          },
          ordered: {
            icon: null,
            className: 'list-option-numbered icon-list-numbered',
          },
        },
        link: {
          options: ['link'],
          defaultTargetOption: '_blank',
          popupClassName: 'link-popup',
          link: {icon: null, className: 'icon-alt-link link-option'},
        },
        embedded: {
          icon: null,
          className: 'video-option icon-video',
          popupClassName: 'embedded-popup',
          embedCallback: handleEmbedUrl,
          defaultSize: {
            height: 'auto',
            width: 'auto',
          },
        },
        image: {
          icon: null,
          className: 'icon-image image-option',
          popupClassName: 'image-popup',
          uploadCallback: handleUploadImage,
          alignmentEnabled: false,
          previewImage: true,
          alt: {present: false, mandatory: false},
          defaultSize: {
            height: 'auto',
            width: 'auto',
          },
        },
        emoji: {
          icon: null,
          className: 'icon-emoji emoji-option',
          popupClassName: 'emoji-popup',
        },
      }}
      placeholder="Write your text here..."
      plugins={[]}
    />
  );
};

WysiwygEditor.propTypes = propTypes;
WysiwygEditor.defaultProps = defaultProps;

export default WysiwygEditor;
