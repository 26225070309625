import classnames from 'classnames';
import Divider from 'components/Divider';
import Input from 'components/Input';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {
  ACTIVE_OPERATOR_CUSTOM,
  ACTIVE_OPERATOR_EVERYWHERE,
  ACTIVE_OPERATOR_SINGLE_URL,
} from 'scenes/EmbeddedElementSelectorBuilder';
import {
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOUR,
} from 'services/evolution';
import ElementPicker from '../ElementPicker';
import {PathSelector} from '../PathSelector';
import './_styles.scss';

export const SectionWhere = () => {
  const {evolution, setEvolution} = useContext(BuilderContext);
  const isCompleted = hasFlags(
    [F_BOOST_SLOT_DOT, F_BOOST_SLOT_TOOLTIP],
    evolution.boostFlags,
    true
  )
    ? evolution.boostedQueryselector != null
    : true;
  const isTour = hasFlag(F_BOOST_SLOT_TOUR, evolution.boostFlags);

  return (
    <div className="builder-audience-section-where">
      <div className="section-title">
        {isCompleted === false ? (
          <>
            <i className="icon-tick custom-outline"></i>
            Where
          </>
        ) : (
          <>
            <i className="icon-tick"></i>
            Where
          </>
        )}
      </div>
      {/* {hasFlags(
        [F_BOOST_SLOT_DOT, F_BOOST_SLOT_TOOLTIP],
        evolution.boostFlags,
        true
      ) && (
        <>
          <div className="target-element-label">Targeted element</div>
          <ElementPicker
            pokeToPreview={evolution}
            querySelector={evolution?.boostedQueryselector}
            onQuerySelectorChange={(querySelector) => {
              setEvolution({...evolution, boostedQueryselector: querySelector});
            }}
            large
          />
          <Divider />
        </>
      )} */}
      <div className="url-selection-wrapper">
        <div className="question">{'Where should this experience start ?'}</div>
        <div
          className={classnames('url-selection-options', {
            'with-margin-bottom': [
              ACTIVE_OPERATOR_SINGLE_URL,
              ACTIVE_OPERATOR_CUSTOM,
            ].includes(evolution.boostedActiveOperator),
          })}>
          <div
            onClick={() =>
              setEvolution({
                ...evolution,
                boostedActiveOperator: ACTIVE_OPERATOR_EVERYWHERE,
              })
            }
            className={classnames('selection-option', {
              selected:
                evolution.boostedActiveOperator === ACTIVE_OPERATOR_EVERYWHERE,
            })}>
            <i className="icon-browser" />
            Everywhere
          </div>
          <div
            onClick={() =>
              setEvolution({
                ...evolution,
                boostedActiveOperator: ACTIVE_OPERATOR_CUSTOM,
              })
            }
            className={classnames('selection-option', {
              selected:
                evolution.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM ||
                evolution.boostedActiveOperator == null,
            })}>
            <i className="icon-filter" />
            On filtered URLs
          </div>
          <div
            onClick={() =>
              setEvolution({
                ...evolution,
                boostedActiveOperator: ACTIVE_OPERATOR_SINGLE_URL,
              })
            }
            className={classnames('selection-option', {
              selected:
                evolution.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL,
            })}>
            <i className="icon-link" />
            On a single URL
          </div>
        </div>
      </div>
      {evolution.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL && (
        <Input
          required
          className="active-url-input"
          name="value"
          type="string"
          placeholder="https://www.yourawesomecompany.com/"
          value={evolution.boostedActiveUrl}
          onChange={(e) => {
            setEvolution({...evolution, boostedActiveUrl: e.target.value});
          }}
        />
      )}
      {(evolution.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM ||
        evolution.boostedActiveOperator == null) && (
        <PathSelector evolution={evolution} setEvolution={setEvolution} />
      )}
    </div>
  );
};
