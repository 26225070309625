import {v4 as uuidv4} from 'uuid';
import Banner1 from './images/banner-1.svg';
import Banner2 from './images/banner-2.svg';
import Banner3 from './images/banner-3.svg';
import Banner4 from './images/banner-4.svg';
import Banner5 from './images/banner-5.svg';
import Banner6 from './images/banner-6.svg';
import CongratsOnMilestone from './images/congrats-on-milestone.svg';
import DiscoverSaveShortcut from './images/discover-save-shortcut.svg';
import ExcitingNewFeatures from './images/exciting-new-features.svg';
import GiveUsFeedback from './images/give-us-feedback.svg';
import InviteToEarnRewards from './images/invite-to-earn-rewards.svg';
import NeedHelpCheckOut from './images/need-help-check-out.svg';
import SafetyAndSecurity from './images/safety-and-security.svg';
import Survey1 from './images/survey-1.svg';
import Survey10 from './images/survey-10.svg';
import Survey11 from './images/survey-11.svg';
import Survey12 from './images/survey-12.svg';
import Survey13 from './images/survey-13.svg';
import Survey14 from './images/survey-14.svg';
import Survey2 from './images/survey-2.svg';
import Survey3 from './images/survey-3.svg';
import Survey4 from './images/survey-4.svg';
import Survey5 from './images/survey-5.svg';
import Survey6 from './images/survey-6.svg';
import Survey7 from './images/survey-7.svg';
import Survey8 from './images/survey-8.svg';
import Survey9 from './images/survey-9.svg';
import SuspiciousActivityDetected from './images/suspicious-activity-detected.svg';
import Tour1 from './images/tours-1.svg';
import Tour10 from './images/tours-10.svg';
import Tour11 from './images/tours-11.svg';
import Tour12 from './images/tours-12.svg';
import Tour13 from './images/tours-13.svg';
import Tour14 from './images/tours-14.svg';
import Tour15 from './images/tours-15.svg';
import Tour16 from './images/tours-16.svg';
import Tour17 from './images/tours-17.svg';
import Tour18 from './images/tours-18.svg';
import Tour2 from './images/tours-2.svg';
import Tour3 from './images/tours-3.svg';
import Tour4 from './images/tours-4.svg';
import Tour5 from './images/tours-5.svg';
import Tour6 from './images/tours-6.svg';
import Tour7 from './images/tours-7.svg';
import Tour8 from './images/tours-8.svg';
import Tour9 from './images/tours-9.svg';
import TryPremiumFree from './images/try-premium-free.svg';
import UnlockThisFeature from './images/unlock-this-feature.svg';
import WatchAndLearn from './images/watch-and-learn.svg';
import WhyAreYouLeaving from './images/why-are-you-leaving.svg';

export const TYPE_HOTSPOT = 'HOTSPOT';
export const TYPE_BANNER = 'BANNER';
export const TYPE_SNIPPET = 'SNIPPET';
export const TYPE_MODAL = 'MODAL';
export const TYPE_TOUR = 'TOUR';
export const TYPE_TOOLTIP = 'TOOLTIP';
export const TYPE_NAVIGATION = 'NAVIGATION';
export const TYPE_SURVEY = 'SURVEY';
export const TYPE_HINT = 'HINT';

const templatesArr = [
  {
    experienceType: 'BANNER',
    id: '05bbd744-e3a2-4a5c-8890-a08382b12ce8',
    image: Banner6,
    tags: ['Feature Announcement'],
    type: 'SNIPPET',
    title: 'Announce new feature with banner',
    description:
      'Quickly engage users by using this template to announce exciting new features with eye-catching banners. Perfect for grabbing attention and ensuring your audience stays informed about the latest updates just by a glance.',
    tourSteps: [
      {
        uid: 'f4717d23-0b88-4389-a3a6-358284ba87cb',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2359305,
        lastStepChangeAt: '2023-12-14T04:46:45.000Z',
        createdAt: '2023-12-14T04:44:29.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:50:51.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'fade-in;fade-out',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'BANNER',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '05bbd744-e3a2-4a5c-8890-a08382b12ce8',
        boostFlags: 1,
        steps: [
          {
            uid: '1664674d-e9de-42ba-b877-4a573c2987d8',
            style: {
              width: null,
              height: 60,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2023-12-14T04:44:29.000Z',
            mazeUrl: null,
            stepFlags: 2,
            endSurvey: false,
            blocks: [
              {
                uid: '2e2cae8d-bac3-4413-beef-9df88a272dea',
                type: 'PRIMARY_CTA',
                value: 'Try now;step-next;url-open;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2023-12-14T04:44:29.000Z',
                file: null,
              },
              {
                uid: '5a1b47a7-e9b1-46c3-8530-24138c5784f7',
                type: 'TITLE',
                value: 'Moji AI to boost your product adoption|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 17,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2023-12-14T04:44:29.000Z',
                file: null,
              },
              {
                uid: '97a2f861-41b7-4d05-96f8-f2fe9bb010e0',
                type: 'LABEL',
                value: 'New!',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#ff0000ff',
                  fontFamily: 'Montserrat',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#da31311a',
                },
                createdAt: '2024-01-03T02:57:50.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: false,
        primaryColor: '#ffffffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 0,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: '07fafe1f-b4dc-45a7-b1ea-3fe4b5aede0d',
    image: Tour2,
    tags: ['Onboarding'],
    type: 'TOUR',
    title: 'Take users on a product tour',
    description:
      "Introduce users seamlessly to your product's features with this onboarding template. Perfect for guiding new users, ensuring a smooth and informed journey through your app's functionality",
    tourSteps: [
      {
        uid: '6b3a51f5-dff9-49ed-bbb2-cca0289aa801',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-29T05:43:17.000Z',
        createdAt: '2024-01-29T05:41:50.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-31T06:58:08.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector:
          '.flex:nth-child(1) > .w-full:nth-child(1) > .relative > .flex-auto',
        boostedPositionFlags: 16,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://templates.usejimo.com/pages/profile.html',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '1;1;2',
        context: null,
        type: 'TOOLTIP',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '07fafe1f-b4dc-45a7-b1ea-3fe4b5aede0d',
        boostFlags: 32,
        steps: [
          {
            uid: '8368c92a-488c-49e4-b656-1d03daec49f4',
            style: {
              width: 400,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'Step 3',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-29T05:41:50.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '2a5ef3c5-ca71-404a-8265-18857beb0da8',
                type: 'STEPPER',
                value: null,
                rawValue: null,
                flags: 0,
                style: {
                  type: 'dot-line',
                  align: 'left',
                  position: 'top',
                  primaryColor: '#0038ffff',
                  secondaryColor: '#0038ff33',
                },
                createdAt: '2024-01-29T05:41:52.000Z',
                file: null,
              },
              {
                uid: '3306f088-4b91-4b09-bbb7-ed3357cc6adf',
                type: 'USER',
                value:
                  'Raphaël;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-hvEftpaecII5.jpg;undefined',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-01-29T05:41:52.000Z',
                file: null,
              },
              {
                uid: '3ad4cf6a-4b44-4db9-88b0-bac5e71e5203',
                type: 'TITLE',
                value: 'Tailor to Your Needs 🛠️|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 23,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-29T05:41:52.000Z',
                file: null,
              },
              {
                uid: '93cfeb43-c35c-43ee-baf6-38705b7756d0',
                type: 'PRIMARY_CTA',
                value: 'Next;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-29T05:41:52.000Z',
                file: null,
              },
              {
                uid: 'b2d9b8c0-6509-41f2-b184-5bd5da8c3305',
                type: 'SECONDARY_CTA',
                value: 'Back;step-prev;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-01-29T05:41:52.000Z',
                file: null,
              },
              {
                uid: 'bb47c94f-2440-4bd1-8104-33fc69d8e370',
                type: 'BODY',
                value:
                  '<p>Customize Jimo to align with your unique preferences. Personalize settings for a tailored experience that suits you.</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Customize Jimo to align with your unique preferences. Personalize settings for a tailored experience that suits you.',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-29T05:41:52.000Z',
                file: null,
              },
            ],
          },
        ],
      },
      {
        uid: '8f38140f-6459-4861-a354-7dc438aa1e79',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: null,
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-04T06:15:04.000Z',
        createdAt: '2024-01-04T06:05:44.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-31T06:58:08.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://templates.usejimo.com/',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;3',
        context: null,
        type: 'SNIPPET',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '07fafe1f-b4dc-45a7-b1ea-3fe4b5aede0d',
        boostFlags: 4,
        steps: [
          {
            uid: '41e125f3-3238-4033-a7a3-09002b7e8276',
            style: {
              width: 360,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'Introduce',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-04T06:05:44.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '1d5463d8-5ebf-4708-9469-8ab6c51feacb',
                type: 'SECONDARY_CTA',
                value: 'Later;close;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-01-04T06:08:59.000Z',
                file: null,
              },
              {
                uid: '650132a4-513a-4951-8902-200e777ab2a3',
                type: 'USER',
                value:
                  'Raphaël;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-hvEftpaecII5.jpg;undefined',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-01-04T06:06:56.000Z',
                file: null,
              },
              {
                uid: '67269b20-2a11-4bc2-b8e7-858373964b9e',
                type: 'BODY',
                value:
                  '<p>Let&apos;s discover the key features that make Jimo your go-to solution for increasing product adoption</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: "Let's discover the key features that make Jimo your go-to solution for increasing product adoption",
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-04T06:05:44.000Z',
                file: null,
              },
              {
                uid: 'a04edd48-b2f3-4c21-ad79-241434790d1b',
                type: 'TITLE',
                value: 'Welcome! Let me show you around 😆|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-04T06:05:44.000Z',
                file: null,
              },
              {
                uid: 'aa6812c7-1425-4da6-b72f-9a338681c0b4',
                type: 'PRIMARY_CTA',
                value: 'Show me around;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-04T06:05:44.000Z',
                file: null,
              },
            ],
          },
        ],
      },
      {
        uid: 'c2366b56-e8cf-4046-a1b6-5edbbaf1cea5',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-29T05:43:17.000Z',
        createdAt: '2024-01-29T05:41:53.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-31T06:58:08.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector:
          '.w-full:nth-child(2) > .relative > .flex-auto:nth-child(1)',
        boostedPositionFlags: 0,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '2;2;1',
        context: null,
        type: 'TOOLTIP',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '07fafe1f-b4dc-45a7-b1ea-3fe4b5aede0d',
        boostFlags: 32,
        steps: [
          {
            uid: '7c5eb696-899a-434c-83f1-393769c219df',
            style: {
              width: 400,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'Step 3',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-29T05:41:53.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '7908111b-bede-4288-9eef-613d0c1f0942',
                type: 'STEPPER',
                value: null,
                rawValue: null,
                flags: 0,
                style: {
                  type: 'dot-line',
                  align: 'left',
                  position: 'top',
                  primaryColor: '#0038ffff',
                  secondaryColor: '#0038ff33',
                },
                createdAt: '2024-01-29T05:41:53.000Z',
                file: null,
              },
              {
                uid: '9687ff33-95e7-4ffa-803f-d27ab539faed',
                type: 'BODY',
                value:
                  '<p>Dive into our intuitive interface designed for effortless navigation. Access key features effortlessly to enhance your workflow.</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Dive into our intuitive interface designed for effortless navigation. Access key features effortlessly to enhance your workflow.',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-29T05:41:53.000Z',
                file: null,
              },
              {
                uid: '97aacb53-af95-430d-a4ad-16f4eb5bc2de',
                type: 'USER',
                value:
                  'Raphaël;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-hvEftpaecII5.jpg;undefined',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-01-29T05:41:53.000Z',
                file: null,
              },
              {
                uid: 'a54dc202-b8d1-4a56-9220-3f4345019b2f',
                type: 'SECONDARY_CTA',
                value: 'Back;step-prev;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-01-29T05:41:53.000Z',
                file: null,
              },
              {
                uid: 'a5ab058a-ff55-45ab-874c-d9076a6d0f30',
                type: 'PRIMARY_CTA',
                value: 'Next;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-29T05:41:53.000Z',
                file: null,
              },
              {
                uid: 'a6607442-884b-40af-b639-5a8b2e0467ff',
                type: 'TITLE',
                value: 'Seamlessly Navigate 🧭|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 23,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-29T05:41:53.000Z',
                file: null,
              },
            ],
          },
        ],
      },
      {
        uid: 'dcbc6db8-bf2b-44f2-b025-36808774d75d',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-29T05:43:17.000Z',
        createdAt: '2024-01-29T05:41:25.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-31T06:58:08.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: '.w-full:nth-child(3) .flex-auto',
        boostedPositionFlags: 32,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '3;3;0',
        context: null,
        type: 'TOOLTIP',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '07fafe1f-b4dc-45a7-b1ea-3fe4b5aede0d',
        boostFlags: 32,
        steps: [
          {
            uid: '324f42c6-70a4-445a-8e4d-b1c0d25b2f9d',
            style: {
              width: 400,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'Step 3',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-29T05:41:25.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '4ec32bb0-3277-43bc-a269-1e26e3ba4732',
                type: 'BODY',
                value:
                  '<p>Dive into our powerful features designed to elevate your experience. Discover how Jimo can transform your workflow.</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Dive into our powerful features designed to elevate your experience. Discover how Jimo can transform your workflow.',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-29T05:41:27.000Z',
                file: null,
              },
              {
                uid: '64a92d0a-4e4b-475b-ac96-226e1022e6d1',
                type: 'USER',
                value:
                  'Raphaël;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-hvEftpaecII5.jpg;undefined',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-01-29T05:41:27.000Z',
                file: null,
              },
              {
                uid: '7810b201-7c6e-41bb-a05a-a332706a3ea9',
                type: 'STEPPER',
                value: null,
                rawValue: null,
                flags: 0,
                style: {
                  type: 'dot-line',
                  align: 'left',
                  position: 'top',
                  primaryColor: '#0038ffff',
                  secondaryColor: '#0038ff33',
                },
                createdAt: '2024-01-29T05:41:27.000Z',
                file: null,
              },
              {
                uid: 'beea98d8-c194-444d-a6d9-62c61ddc9fdf',
                type: 'TITLE',
                value: 'Harness the Power of Jimo ⚡️|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 23,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-29T05:41:27.000Z',
                file: null,
              },
              {
                uid: 'cc22ab6d-d8e4-45e9-9c19-f33cd865dd5a',
                type: 'SECONDARY_CTA',
                value: 'Back;step-prev;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-01-29T05:41:27.000Z',
                file: null,
              },
              {
                uid: 'de3251f3-c881-4b68-9208-403ad2c71a67',
                type: 'PRIMARY_CTA',
                value: 'Finish Tour;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-29T05:41:27.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: '133c6371-67f1-469e-80ab-af667725dfac',
    image: Tour9,
    tags: ['Marketing'],
    type: 'TOUR',
    title: 'Ask users to leave a review on G2',
    description:
      "Boost your app's credibility by strategically asking users for G2 reviews. Trigger it post-purchase or when they accomplish a significant task to capture positive sentiments. Maximize your app's visibility and trustworthiness on G2 with authentic user reviews.",
    tourSteps: [
      {
        uid: '480823de-e928-4361-877a-696b1cf1cf52',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: null,
          background: {
            type: 'color',
            animated: true,
            primaryColor: '#071331ff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 9,
        lastStepChangeAt: '2024-01-03T07:27:51.000Z',
        createdAt: '2024-01-03T07:14:27.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-02-01T08:37:21.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'SNIPPET',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '133c6371-67f1-469e-80ab-af667725dfac',
        boostFlags: 4,
        steps: [
          {
            uid: '730ee31b-b885-41b6-a99a-c61bb7760603',
            style: {
              width: 408,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-03T07:14:27.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '3da4bc82-5aa6-454b-a331-4bd4b3acec36',
                type: 'TITLE',
                value:
                  'Did you enjoy using Jimo? Share Your Experience on G2! ⭐️|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 20,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-03T07:14:27.000Z',
                file: null,
              },
              {
                uid: '78e2b40f-35a9-4180-bd0c-888c60a56f71',
                type: 'MEDIA',
                value:
                  'image;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-2EHZMWPJ7Sip.png;logo;fill',
                rawValue: null,
                flags: 0,
                style: {padding: 20, position: 'top', borderRadius: 16},
                createdAt: '2024-01-03T07:17:17.000Z',
                file: null,
              },
              {
                uid: 'c8360640-b81a-42dd-b93f-c2d61f392913',
                type: 'BODY',
                value:
                  '<p>Your feedback is invaluable! Help us grow by leaving a review on G2. Share your thoughts and let others know about your experience with Jimo&#x1f606;</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Your feedback is invaluable! Help us grow by leaving a review on G2. Share your thoughts and let others know about your experience with Jimo😆',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#b0b0b0ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-03T07:14:27.000Z',
                file: null,
              },
              {
                uid: 'd2a2daa6-5187-43d1-8961-386833a7f69a',
                type: 'SECONDARY_CTA',
                value: 'Maybe later;close;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#ffffff1a',
                },
                createdAt: '2024-01-03T07:17:05.000Z',
                file: null,
              },
              {
                uid: 'eaa57b9f-f8de-4f4f-9ed3-a677d4152e75',
                type: 'PRIMARY_CTA',
                value: 'Leave a review;step-next;url-open;https://www.g2.com/',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-03T07:14:27.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: '28ff51dd-0947-47bd-a6f6-11e0338143c3',
    image: Tour3,
    tags: ['Product Insight'],
    type: 'TOUR',
    title: 'Educate users on a specific feature',
    description:
      'Empower users with a single tip on a specific feature, enhancing your product understanding. Ideal for new users or those seeking deeper insights into specific functionalities',
    tourSteps: [
      {
        uid: 'f5f6bd12-cfbc-4a80-8fef-4a705989e555',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: null,
          background: {
            type: 'color',
            animated: true,
            primaryColor: '#ffffffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 9,
        lastStepChangeAt: '2024-01-04T06:03:58.000Z',
        createdAt: '2024-01-04T05:52:18.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:48:09.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 0,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'SNIPPET',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '28ff51dd-0947-47bd-a6f6-11e0338143c3',
        boostFlags: 4,
        steps: [
          {
            uid: '2790587f-349c-4648-be92-f0851d296095',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-04T05:52:18.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '10942883-75e1-48d0-b4a0-d361ae840711',
                type: 'MEDIA',
                value:
                  'image;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-zacYSz6G4MdQ.png;logo;fill',
                rawValue: null,
                flags: 0,
                style: {padding: 20, position: 'top', borderRadius: 16},
                createdAt: '2024-01-04T05:54:18.000Z',
                file: null,
              },
              {
                uid: '2913c50d-6b45-4556-ab44-f45563ef19f8',
                type: 'LABEL',
                value: '💡 Did you know?',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#bb7817ff',
                  fontFamily: 'Montserrat',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#dad1311a',
                },
                createdAt: '2024-01-04T05:53:19.000Z',
                file: null,
              },
              {
                uid: 'd495d59d-f60e-4604-bd03-7c2f42c9d5d3',
                type: 'TITLE',
                value:
                  'You can drag and drop the gallery blocks with ease|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  fontSize: 21,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-04T05:52:18.000Z',
                file: null,
              },
              {
                uid: 'ffa10f61-7fc3-406e-9eed-7f2491336a85',
                type: 'PRIMARY_CTA',
                value: 'Got it;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-04T05:52:18.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'BANNER',
    id: '31593bfb-83b0-48eb-8e3b-facbaf7632a4',
    image: Banner5,
    tags: ['Product Insight'],
    type: 'TOUR',
    title: 'Tell users product’s tips & tricks',
    description:
      'Guide users with a valuable tips and tricks for maximizing your product efficiency. Ideal for new users and seasoned pros seeking to uncover hidden features and enhance their overall product experience.',
    tourSteps: [
      {
        uid: '09e009da-0b3d-461f-8122-9304879279b0',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#e07900ff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2359305,
        lastStepChangeAt: '2024-01-03T03:05:24.000Z',
        createdAt: '2024-01-03T03:00:13.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:51:21.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'fade-in;fade-out',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'BANNER',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '31593bfb-83b0-48eb-8e3b-facbaf7632a4',
        boostFlags: 1,
        steps: [
          {
            uid: '8f90acc2-cdf2-4980-95be-6de5b42b9ffe',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-03T03:00:13.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '306d7861-309e-4bd2-a377-49fff6debfa7',
                type: 'TITLE',
                value:
                  'You can create a new view with different filters on each one|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 17,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-03T03:00:13.000Z',
                file: null,
              },
              {
                uid: '378039c4-af0d-4d20-8c73-94c8d7ab4580',
                type: 'LABEL',
                value: '💡 Tips',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#e0b600ff',
                },
                createdAt: '2024-01-03T03:00:52.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 0,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: '3512239a-1a4a-4860-9be3-9220dc2c22e0',
    image: Tour14,
    tags: ['Onboarding'],
    type: 'TOUR',
    title: 'Welcome a new user',
    description:
      'Guide first-time users through your app seamlessly. Deliver a warm introduction, essential features overview, and prompt actions for a smooth start. Ideal for enhancing user engagement from the first interaction.',
    tourSteps: [
      {
        uid: '05d82917-4488-4d37-8c19-5698835f4c6e',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-31T07:13:30.000Z',
        createdAt: '2024-01-31T07:10:41.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-31T07:14:54.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: '.w-full:nth-child(3) .flex-auto',
        boostedPositionFlags: 0,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '3;3;0',
        context: null,
        type: 'TOOLTIP',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '3512239a-1a4a-4860-9be3-9220dc2c22e0',
        boostFlags: 32,
        steps: [
          {
            uid: 'dc97f42a-ed21-4319-93dd-77d84d0e43c9',
            style: {
              width: 400,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-31T07:10:42.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '1812e762-88d7-4a89-980e-04a56974c341',
                type: 'TITLE',
                value: 'Fixed less than 24 hours|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 20,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-31T07:10:44.000Z',
                file: null,
              },
              {
                uid: '60c2b166-23fd-4d2a-b750-9d2f4c9b292e',
                type: 'SECONDARY_CTA',
                value: 'Back;step-prev;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-01-31T07:10:44.000Z',
                file: null,
              },
              {
                uid: '644f0fae-8ced-44a3-b76d-fb7f4c2016a8',
                type: 'BODY',
                value:
                  '<p>Sit back and relax, we&apos;ll swiftly repair it and have it on its way to you in 24 hours. </p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: "Sit back and relax, we'll swiftly repair it and have it on its way to you in 24 hours. ",
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-31T07:10:44.000Z',
                file: null,
              },
              {
                uid: '7046daac-8f85-44f5-b20e-fcc9f811c003',
                type: 'PRIMARY_CTA',
                value: 'Finish;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-31T07:10:44.000Z',
                file: null,
              },
              {
                uid: '7eb4dff6-d329-431a-baa9-d9fe9642b56f',
                type: 'STEPPER',
                value: null,
                rawValue: null,
                flags: 0,
                style: {
                  type: 'dot-line',
                  align: 'left',
                  position: 'top',
                  primaryColor: '#0038ffff',
                  secondaryColor: '#0038ff33',
                },
                createdAt: '2024-01-31T07:10:44.000Z',
                file: null,
              },
              {
                uid: 'c9837f0b-8fd5-4cc3-bf8e-66de59681fae',
                type: 'USER',
                value:
                  'John Doe;CPO @fixit;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-01-31T07:10:44.000Z',
                file: null,
              },
            ],
          },
        ],
      },
      {
        uid: '19c8b152-4413-453d-9ccf-64b35c354cfc',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-31T07:13:30.000Z',
        createdAt: '2024-01-31T07:10:36.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-31T07:14:54.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector:
          '.w-full:nth-child(2) > .relative > .flex-auto:nth-child(1)',
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '2;2;1',
        context: null,
        type: 'TOOLTIP',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '3512239a-1a4a-4860-9be3-9220dc2c22e0',
        boostFlags: 32,
        steps: [
          {
            uid: '165d9c6a-9d59-4179-b7b2-2db9794b0480',
            style: {
              width: 400,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-31T07:10:36.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '53307cdd-b4f2-4bfb-8792-b67342b234a3',
                type: 'STEPPER',
                value: null,
                rawValue: null,
                flags: 0,
                style: {
                  type: 'dot-line',
                  align: 'left',
                  position: 'top',
                  primaryColor: '#0038ffff',
                  secondaryColor: '#0038ff33',
                },
                createdAt: '2024-01-31T07:10:40.000Z',
                file: null,
              },
              {
                uid: '73381245-a008-46c6-9773-0123465c2052',
                type: 'USER',
                value:
                  'John Doe;CPO @fixit;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-01-31T07:10:40.000Z',
                file: null,
              },
              {
                uid: '7f34c9f9-7e27-44db-8e88-fc3c59fb9e94',
                type: 'BODY',
                value:
                  '<p>You can pack your device securely, and we&apos;ll take care of the rest to ensure it reaches us swiftly for repair.</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: "You can pack your device securely, and we'll take care of the rest to ensure it reaches us swiftly for repair.",
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-31T07:10:40.000Z',
                file: null,
              },
              {
                uid: 'a8241cf4-ae9f-4220-8bc5-f6cc6b381a1e',
                type: 'TITLE',
                value: 'Ship Your Device with Ease|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 20,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-31T07:10:40.000Z',
                file: null,
              },
              {
                uid: 'd4c02daf-2ac2-4d0a-b10f-571da1adb7dc',
                type: 'PRIMARY_CTA',
                value: 'Next;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-31T07:10:40.000Z',
                file: null,
              },
              {
                uid: 'd98f9f3d-8d9a-4b84-b0e3-eafd07e91602',
                type: 'SECONDARY_CTA',
                value: 'Back;step-prev;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-01-31T07:10:40.000Z',
                file: null,
              },
            ],
          },
        ],
      },
      {
        uid: '3b6eef7c-1b26-4a5b-a933-2f28983857c4',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-03T04:15:22.000Z',
        createdAt: '2024-01-03T03:56:55.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-31T07:14:54.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://templates.usejimo.com/',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;3',
        context: null,
        type: 'MODAL',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '3512239a-1a4a-4860-9be3-9220dc2c22e0',
        boostFlags: 2,
        steps: [
          {
            uid: 'dc02f55e-0ff8-45c7-bf75-5df56351bf0c',
            style: {
              width: 640,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-03T03:56:55.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '043677e7-935a-4340-81c6-a657f4d7f216',
                type: 'USER',
                value:
                  'John Doe;CPO @fixit;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-TG8OhuVr0GfI.jpg;undefined',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-01-03T06:53:09.000Z',
                file: null,
              },
              {
                uid: '1c6d5abf-6f07-43f9-a7b3-143c3026ab84',
                type: 'BODY',
                value:
                  '<p>Your go-to solution for premium, low-cost repairs for all your Apple devices. Let&apos;s get started on enhancing your device&apos;s performance!</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: "Your go-to solution for premium, low-cost repairs for all your Apple devices. Let's get started on enhancing your device's performance!",
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-03T03:56:55.000Z',
                file: null,
              },
              {
                uid: '1fb3ff63-7884-4b3a-99b9-971d343f4112',
                type: 'TITLE',
                value: 'Hey, welcome to fixit!|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 20,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-03T03:56:55.000Z',
                file: null,
              },
              {
                uid: '49bcf010-e5f4-4378-8e6b-a70e5b0992b5',
                type: 'MEDIA',
                value:
                  'image;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-i6cdgk3Q8X6v.png;logo;fill',
                rawValue: null,
                flags: 0,
                style: {
                  height: 340,
                  padding: 20,
                  position: 'top',
                  borderRadius: 16,
                },
                createdAt: '2024-01-03T04:04:58.000Z',
                file: null,
              },
              {
                uid: '60b8bc6e-beb6-4dfd-8c9a-03622e23fdc4',
                type: 'PRIMARY_CTA',
                value: 'Get Started;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-03T03:56:55.000Z',
                file: null,
              },
            ],
          },
        ],
      },
      {
        uid: '65249596-d27f-47c2-9163-c37d115225c5',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-31T07:13:30.000Z',
        createdAt: '2024-01-31T07:10:30.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-31T07:14:54.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector:
          '.flex:nth-child(1) > .w-full:nth-child(1) > .relative > .flex-auto',
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0;0;110',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '1;1;2',
        context: null,
        type: 'TOOLTIP',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '3512239a-1a4a-4860-9be3-9220dc2c22e0',
        boostFlags: 32,
        steps: [
          {
            uid: '77d44aa1-f53a-4de3-b27a-51aa55ec6985',
            style: {
              width: 400,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-31T07:10:30.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '13579381-83d3-4151-a883-da4fd2607ae1',
                type: 'PRIMARY_CTA',
                value: 'Next;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-31T07:10:30.000Z',
                file: null,
              },
              {
                uid: '150a17f7-2d6d-46a9-a34e-bbb721ab4355',
                type: 'SECONDARY_CTA',
                value: 'Back;step-prev;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-01-31T07:10:30.000Z',
                file: null,
              },
              {
                uid: '9fd43e03-df9b-4da0-98da-2333b7ae4ee9',
                type: 'BODY',
                value:
                  '<p>Tell us about your Apple device and the specific issue you&apos;re facing to our expert technicians</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: "Tell us about your Apple device and the specific issue you're facing to our expert technicians",
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-31T07:10:30.000Z',
                file: null,
              },
              {
                uid: 'b2d594b0-024c-4cae-8dad-a2df44050cd9',
                type: 'TITLE',
                value: 'Choose Your Device and Issue\n|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 20,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-31T07:10:30.000Z',
                file: null,
              },
              {
                uid: 'c3cf8995-4ad7-480e-be49-62b7e60a125a',
                type: 'STEPPER',
                value: null,
                rawValue: null,
                flags: 0,
                style: {
                  type: 'dot-line',
                  align: 'left',
                  position: 'top',
                  primaryColor: '#0038ffff',
                  secondaryColor: '#0038ff33',
                },
                createdAt: '2024-01-31T07:10:30.000Z',
                file: null,
              },
              {
                uid: 'f9992e8b-0d2f-42bd-ae8d-ab3648b64b9e',
                type: 'USER',
                value:
                  'John Doe;CPO @fixit;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-01-31T07:10:30.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'BANNER',
    id: '4b82ece4-7875-4608-8462-aee3641b9f76',
    image: Banner4,
    tags: ['Marketing'],
    type: 'TOUR',
    title: 'Announce a flash sale',
    description:
      'Quickly spread the word about limited-time offers. This template helps you to create attention-grabbing announcements for flash sales. Ideal for boosting urgency and engaging customers instantly. Perfect for time-sensitive promotions.',
    tourSteps: [
      {
        uid: '921a762b-5129-4995-9a40-a0ad7189b91f',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#e71616ff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2359305,
        lastStepChangeAt: '2024-01-03T06:17:43.000Z',
        createdAt: '2024-01-03T06:16:08.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:51:52.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'fade-in;fade-out',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'BANNER',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '4b82ece4-7875-4608-8462-aee3641b9f76',
        boostFlags: 1,
        steps: [
          {
            uid: '28950bcb-2f42-4666-9adc-166a2734545c',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-03T06:16:08.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '981bc962-6d8d-4b6d-9b63-705145fb1cf9',
                type: 'TITLE',
                value: 'Enjoy 30% Off on Select Items - Today Only!|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 17,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-03T06:16:09.000Z',
                file: null,
              },
              {
                uid: 'eadc603f-7372-4ba0-8d3b-7076eb0f83ae',
                type: 'LABEL',
                value: 'Limited Discount',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#cf0202ff',
                  fontFamily: 'Montserrat',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#da31311a',
                },
                createdAt: '2024-01-03T06:16:36.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: '7261abd3-a972-4c7b-a0a1-52b466cd6bfa',
    image: Tour4,
    tags: ['Marketing', 'E-commerce'],
    type: 'TOUR',
    title: 'Highlight a discounted product in your store',
    description:
      'Boost sales by showcasing discounted products on your E-commerce store. Capture customer attention, drive purchases, and maximize revenue effortlessly.',
    tourSteps: [
      {
        uid: '1035d0cf-d683-4845-b4e4-3a3b0f8b6575',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#db5858ff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-04T05:39:03.000Z',
        createdAt: '2024-01-04T05:34:39.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:47:40.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'MODAL',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '7261abd3-a972-4c7b-a0a1-52b466cd6bfa',
        boostFlags: 2,
        steps: [
          {
            uid: '30cdde20-9076-4834-b96e-594f8103d08b',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-04T05:34:39.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '18332d6b-fe3b-47bc-90ee-cf542e04eef2',
                type: 'MEDIA',
                value:
                  'image;https://images.unsplash.com/photo-1523779105320-d1cd346ff52b?q=80&w=2346&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D;logo;fill',
                rawValue: null,
                flags: 0,
                style: {
                  height: 300,
                  padding: 20,
                  position: 'top',
                  borderRadius: 24,
                },
                createdAt: '2024-01-04T05:37:07.000Z',
                file: null,
              },
              {
                uid: '2c71d181-e097-4ca5-bbfe-e40213e2e453',
                type: 'PRIMARY_CTA',
                value:
                  'Claim discount now;step-next;url-open;https://yourproductdiscount.com',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#d1370dff',
                },
                createdAt: '2024-01-04T05:34:39.000Z',
                file: null,
              },
              {
                uid: '61f3d109-bec3-4e58-9d8b-1fe6e8c6591b',
                type: 'LABEL',
                value: '-50% OFF',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#ff0000ff',
                  fontFamily: 'Montserrat',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#da31311a',
                },
                createdAt: '2024-01-04T05:36:22.000Z',
                file: null,
              },
              {
                uid: '787509c7-b8ff-4078-82c6-9d87daf8804b',
                type: 'BODY',
                value:
                  '<p>We&apos;re delighted to offer you a limited-time discount on <strong>Leather Bag Brown 11L.</strong> Grab this special deal now and enjoy the discount!</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: "We're delighted to offer you a limited-time discount on ",
                      },
                      {bold: true, text: 'Leather Bag Brown 11L.'},
                      {
                        text: ' Grab this special deal now and enjoy the discount!',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'center',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-04T05:34:39.000Z',
                file: null,
              },
              {
                uid: 'd3f4b52b-1975-4727-ac19-3ef97f6bbd12',
                type: 'TITLE',
                value: "We've got a special offer just for you!|-|none|-|",
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-04T05:34:39.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: '76003962-4b6c-4dfd-8f96-a2401ca164c7',
    image: Tour15,
    tags: ['Onboarding'],
    type: 'TOUR',
    title: 'Onboard users to a new feature',
    description:
      'Quickly guide users through our latest feature, ensuring a seamless transition. Ideal for introducing updates, enhancing user familiarity, and maximizing engagement. Perfect when launching new functionalities or improvements.',
    tourSteps: [
      {
        uid: '86830356-47e2-4b29-a2ec-0d7e9d1a3e06',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2359305,
        lastStepChangeAt: '2024-02-05T13:51:34.000Z',
        createdAt: '2024-02-05T13:51:59.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-02-05T15:41:54.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: '.w-full > .p-4',
        boostedPositionFlags: 16,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://templates.usejimo.com/',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'fade-in;fade-out',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '1;1;1',
        context: null,
        type: 'TOOLTIP',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '76003962-4b6c-4dfd-8f96-a2401ca164c7',
        boostFlags: 32,
        steps: [
          {
            uid: '70539a79-3549-4550-b5bf-54df51a1d212',
            style: {
              width: 400,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-02-05T13:51:59.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '38689da7-4724-4fdd-aa7d-687dcbcb4841',
                type: 'TITLE',
                value: 'Tailor SnapStats to Your Needs|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 20,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
              {
                uid: '821bcfe2-b809-4b14-b023-de9d542a43e8',
                type: 'STEPPER',
                value: null,
                rawValue: null,
                flags: 0,
                style: {
                  type: 'dot-line',
                  align: 'left',
                  position: 'top',
                  primaryColor: '#0038ffff',
                  secondaryColor: '#0038ff33',
                },
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
              {
                uid: 'd15b03f2-79fb-4643-bdd8-b17736fcc524',
                type: 'BODY',
                value:
                  '<p>Customize settings and preferences to make SnapStats work seamlessly for you.</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Customize settings and preferences to make SnapStats work seamlessly for you.',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
              {
                uid: 'de6f02b1-25ca-4d6d-89f5-26c487a28349',
                type: 'USER',
                value:
                  'John Doe;CPO @SnapStats;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;null',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
              {
                uid: 'ea4d6a42-b526-4afb-a95a-857fea71aad4',
                type: 'PRIMARY_CTA',
                value: 'Next;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
              {
                uid: 'fdf74828-dc14-4dc1-a18b-3e70ab6020e8',
                type: 'SECONDARY_CTA',
                value: 'Back;step-prev;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
            ],
          },
        ],
      },
      {
        uid: '9453d18f-eda4-4b81-b378-d00bb9d02ac1',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2359305,
        lastStepChangeAt: '2024-02-05T13:51:34.000Z',
        createdAt: '2024-02-05T13:51:58.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-02-05T15:41:54.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector:
          '.flex:nth-child(1) > .w-full:nth-child(1) > .relative > .flex-auto',
        boostedPositionFlags: 0,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://templates.usejimo.com/',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'fade-in;fade-out',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;2',
        context: null,
        type: 'TOOLTIP',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '76003962-4b6c-4dfd-8f96-a2401ca164c7',
        boostFlags: 32,
        steps: [
          {
            uid: '187085cf-73ed-4f03-aad1-6b93e26c23a1',
            style: {
              width: 400,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-02-05T13:51:59.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '0b292bce-23a3-4875-85e3-8bb5e3f2c795',
                type: 'STEPPER',
                value: null,
                rawValue: null,
                flags: 0,
                style: {
                  type: 'dot-line',
                  align: 'left',
                  position: 'top',
                  primaryColor: '#0038ffff',
                  secondaryColor: '#0038ff33',
                },
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
              {
                uid: '3b2d68c3-d4bd-4e83-8979-44b944b26ee3',
                type: 'USER',
                value:
                  'John Doe;CPO @SnapStats;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;null',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
              {
                uid: '8351dcc4-ac2b-4930-bf69-e0bfd49152c6',
                type: 'TITLE',
                value: 'Welcome to SnapStats!|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 20,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
              {
                uid: '9625afe9-ced4-4244-af73-2802da95f7a2',
                type: 'PRIMARY_CTA',
                value: 'Next;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
              {
                uid: 'c3fb4728-c402-4c26-8343-b0e8ba769d30',
                type: 'BODY',
                value:
                  '<p>Let&apos;s discovery the potential of SnapStats as we guide you through its powerful capabilities.</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: "Let's discovery the potential of SnapStats as we guide you through its powerful capabilities.",
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
            ],
          },
        ],
      },
      {
        uid: 'f4e16e95-d269-47d9-9ca3-7b9432c2a35f',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2359305,
        lastStepChangeAt: '2024-02-05T13:42:03.000Z',
        createdAt: '2024-02-05T13:51:59.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-02-05T15:41:54.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector:
          '.flex:nth-child(1) > .w-full:nth-child(1) > .relative > .flex-auto',
        boostedPositionFlags: 32,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'fade-in;fade-out',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '2;2;0',
        context: null,
        type: 'TOOLTIP',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '76003962-4b6c-4dfd-8f96-a2401ca164c7',
        boostFlags: 32,
        steps: [
          {
            uid: 'd0123664-52ca-4a41-8649-85ec378cdd9c',
            style: {
              width: 400,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-02-05T13:51:59.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '03fe8e66-d9eb-45f1-a50f-0f37dcfd4b51',
                type: 'USER',
                value:
                  'John Doe;CPO @SnapStats;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;null',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
              {
                uid: '628ffdff-4d94-4f5d-a574-b7603b3cc454',
                type: 'SECONDARY_CTA',
                value: 'Back;step-prev;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
              {
                uid: '67142669-1888-45f5-ab9f-3f51366d5380',
                type: 'BODY',
                value:
                  '<p>Congratulations! You&apos;re all set to leverage the full power of SnapStats in your day-to-day tasks.</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: "Congratulations! You're all set to leverage the full power of SnapStats in your day-to-day tasks.",
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
              {
                uid: '80da0b13-b499-4bcf-9b21-9ca3d17ea052',
                type: 'TITLE',
                value: 'Your SnapStats Journey Begins|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 20,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
              {
                uid: '9daa853f-ad4c-4454-9402-0527dc0f72f9',
                type: 'STEPPER',
                value: null,
                rawValue: null,
                flags: 0,
                style: {
                  type: 'dot-line',
                  align: 'left',
                  position: 'top',
                  primaryColor: '#0038ffff',
                  secondaryColor: '#0038ff33',
                },
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
              {
                uid: 'afd2c5ec-0488-43de-8a34-a49338a64a4a',
                type: 'PRIMARY_CTA',
                value: 'Finish;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-02-05T13:51:59.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 0,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: '779240bd-19d6-4947-8733-b98ddfde3eb5',
    image: Tour10,
    tags: ['Nudging'],
    type: 'TOUR',
    title: 'Nudge users to read changelog post',
    description:
      'Gently guide users to your Changelog post and make them stay informed about updates. This template prompts users to read changelog posts, ensuring they grasp new features and improvements. Ideal for enhancing user engagement after app updates.',
    tourSteps: [
      {
        uid: '260df37e-6571-4cf5-9e71-22e3935c0bf5',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: null,
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 9,
        lastStepChangeAt: '2024-01-03T07:04:59.000Z',
        createdAt: '2024-01-03T07:02:39.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-25T05:54:16.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'SNIPPET',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '779240bd-19d6-4947-8733-b98ddfde3eb5',
        boostFlags: 4,
        steps: [
          {
            uid: '4e187a20-d67f-41b5-a4f7-5727e595f788',
            style: {
              width: 400,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-03T07:02:39.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '09285cab-bd6c-48cb-904b-930b8fce5fa5',
                type: 'SECONDARY_CTA',
                value: 'No, thanks;close;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-01-03T07:04:30.000Z',
                file: null,
              },
              {
                uid: '4ca99abe-f3f7-4962-a19e-99f617901e07',
                type: 'LABEL',
                value: 'New',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#ff0000ff',
                  fontFamily: 'Montserrat',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#da31311a',
                },
                createdAt: '2024-01-03T07:03:37.000Z',
                file: null,
              },
              {
                uid: '9b92989b-a1d7-4aef-8b6e-8468304a8146',
                type: 'BODY',
                value:
                  '<p>We&apos;ve just rolled out an exciting update! Head to our changelog to discover our new Success Tracker experience</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: "We've just rolled out an exciting update! Head to our changelog to discover our new Success Tracker experience",
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-03T07:02:39.000Z',
                file: null,
              },
              {
                uid: 'eba6b71e-e374-42f1-98a3-a17416081b94',
                type: 'PRIMARY_CTA',
                value:
                  'Open Post;step-next;post-open;c504870e-408c-448a-a271-1f6745120096',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-03T07:02:39.000Z',
                file: null,
              },
              {
                uid: 'f888dcf0-5078-4d51-a86c-5a31b40561c0',
                type: 'TITLE',
                value: 'Get to know more about Success Tracker|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-03T07:02:39.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: '89b5b15d-05fd-4b50-bf91-244da7790f8c',
    image: Tour6,
    tags: ['Product Insight'],
    type: 'TOUR',
    title: 'Use in-product videos to showcase features',
    description:
      "Enhance user understanding by displaying key features inside an in-product videos. Ideal for Product Insight, guide users seamlessly through your product's functionalities with a video for a smoother user experience.",
    tourSteps: [
      {
        uid: 'b63dafd8-bc67-4cb3-afc2-d920a3d53d34',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 9,
        lastStepChangeAt: '2024-01-04T04:59:12.000Z',
        createdAt: '2024-01-04T04:54:51.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:46:21.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'MODAL',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '89b5b15d-05fd-4b50-bf91-244da7790f8c',
        boostFlags: 2,
        steps: [
          {
            uid: '162d6e4a-3146-4bae-840b-b5bdd968768d',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-04T04:54:51.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '059db789-d927-45e5-b141-a95d29605bbe',
                type: 'PRIMARY_CTA',
                value: 'Got it;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-04T04:54:51.000Z',
                file: null,
              },
              {
                uid: '2a8c1e99-d700-4fb1-ab5e-1c71127571a0',
                type: 'USER',
                value:
                  'Raphaël;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-hvEftpaecII5.jpg;undefined',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-01-04T04:56:37.000Z',
                file: null,
              },
              {
                uid: 'bfe2eca0-253d-4a75-875f-a1b88ba44965',
                type: 'BODY',
                value:
                  '<p>Watch and discover how to make the most out of Jimo to your use case</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Watch and discover how to make the most out of Jimo to your use case',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-04T04:54:51.000Z',
                file: null,
              },
              {
                uid: 'cf53f435-660c-4a95-a0c3-6323e3628eab',
                type: 'TITLE',
                value: "Let's see Jimo in Action!|-|none|-|",
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-04T04:54:51.000Z',
                file: null,
              },
              {
                uid: 'e4c5f39c-2f10-4663-84b6-59b7d15aa93b',
                type: 'MEDIA',
                value: 'video;https://www.youtube.com/watch?v=EzJt47Gae3s;',
                rawValue: null,
                flags: 0,
                style: {
                  height: 312,
                  padding: 20,
                  position: 'bottom',
                  borderRadius: 16,
                },
                createdAt: '2024-01-04T04:56:38.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'BANNER',
    id: '8acbb779-2389-4c05-b605-6d71878c3258',
    image: Banner1,
    tags: ['Marketing'],
    type: 'TOUR',
    title: 'Announce company funding to users',
    description:
      "Communicate your company's financial milestones to users. Use this template to share funding updates, fostering transparency and building trust. Ideal for marketing strategies that highlight growth and value to your audience.",
    tourSteps: [
      {
        uid: 'c0c606ec-1fa6-4ffe-8c83-10168315e4a3',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2359305,
        lastStepChangeAt: '2024-01-03T07:34:12.000Z',
        createdAt: '2024-01-03T07:31:25.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:53:31.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'fade-in;fade-out',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'BANNER',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '8acbb779-2389-4c05-b605-6d71878c3258',
        boostFlags: 1,
        steps: [
          {
            uid: '60c3a4c9-6a71-4a82-b3a8-d518a44c6c5f',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-03T07:31:25.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: 'd0be738a-42e1-4e61-af49-d409b2e9f695',
                type: 'PRIMARY_CTA',
                value:
                  'Read article;step-next;url-open;https://yoursitearticle.com',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-03T07:31:25.000Z',
                file: null,
              },
              {
                uid: 'de6a5cbe-a353-43db-8017-e3a2dfcd78b6',
                type: 'TITLE',
                value:
                  'Jimo raises $1M! 🚀 Exciting times ahead for even more innovation!|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-03T07:31:25.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'SURVEY',
    id: '91ff73a9-4e67-4ed8-b0e4-ae5622656c10',
    image: Survey4,
    tags: ['Survey'],
    type: 'SNIPPET',
    title: 'Evaluate product experience ',
    description:
      'Use this survey template to gather valuable insights on user satisfaction, identify strengths, and pinpoint areas for improvement in your product. Perfect for post-launch assessments and enhancing user engagement.',
    tourSteps: [],
    steps: [
      {
        uid: '0a2b4625-553d-4456-baee-8e94dce7ec2e',
        style: {
          width: 360,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step opinion scale 1',
        question: null,
        additionalInformation: null,
        type: 'OPINION_SCALE',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-04T06:37:44.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '44419139-4592-4990-84b3-ff64570d6ddb',
            type: 'PRIMARY_CTA',
            value: 'Continue;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-01-04T06:37:44.000Z',
            file: null,
            options: [],
          },
          {
            uid: '6f98598c-cb82-403f-81a6-3a16ef9346c3',
            type: 'TITLE',
            value: 'Hey, how satisfied are you using Jimo?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 22,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-04T06:37:44.000Z',
            file: null,
            options: [],
          },
          {
            uid: '87e5e78c-897f-4287-a39e-ff65a85f76e7',
            type: 'OPINION',
            value: 'smiley;5;Very disappointed;Very satisfied',
            rawValue: null,
            flags: 0,
            style: {padding: 4, fontSize: 30},
            createdAt: '2024-01-04T06:37:44.000Z',
            file: null,
            options: [],
          },
          {
            uid: '95741777-59b1-43db-a37f-c807d272e246',
            type: 'USER',
            value:
              'Raphaël;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-hvEftpaecII5.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {borderRadius: 8},
            createdAt: '2024-01-04T06:52:39.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: {
          uid: '41034aa5-ac57-4685-832c-5e90c474fdb3',
          style: {
            width: null,
            height: null,
            margin: 0,
            shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
            overlay: null,
            background: {
              type: 'color',
              animated: false,
              primaryColor: '#FFFFFF',
              secondaryColor: null,
            },
            animationIn: 'fade',
            animationOut: 'fade',
            borderRadius: 0,
            withElementsAnimations: true,
          },
          name: 'Step open question 1',
          question: null,
          additionalInformation: null,
          type: 'TEXT_LONG',
          mdContent: null,
          mdRawContent: null,
          indexOrder: 2,
          ctaLabel: null,
          ctaColor: null,
          ctaBackgroundColor: null,
          ctaBorderRadius: null,
          ctaUrl: null,
          ctaAction: null,
          ctaFontSize: null,
          interactiveLink: null,
          conceptThumbnailUrl: null,
          createdAt: '2024-01-04T06:44:16.000Z',
          mazeUrl: null,
          stepFlags: 0,
          endSurvey: false,
        },
        jumps: [
          {
            uid: '16cae6ca-0501-4bb4-a713-0da37e007f3d',
            operator: 'EQUAL_TO',
            conditionOperator: 'AT_LEAST_ONE_OF',
            value: '6;7',
            endSurvey: false,
            goTo: {
              uid: 'a8a60c9c-1d56-4b88-a5c9-6c74565cdfa0',
              style: {
                width: null,
                height: null,
                margin: 0,
                shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
                overlay: null,
                background: {
                  type: 'color',
                  animated: false,
                  primaryColor: '#FFFFFF',
                  secondaryColor: null,
                },
                animationIn: 'fade',
                animationOut: 'fade',
                borderRadius: 0,
                withElementsAnimations: true,
              },
              name: 'Step success 1',
              question: null,
              additionalInformation: null,
              type: 'SUCCESS',
              mdContent: null,
              mdRawContent: null,
              indexOrder: 3,
              ctaLabel: null,
              ctaColor: null,
              ctaBackgroundColor: null,
              ctaBorderRadius: null,
              ctaUrl: null,
              ctaAction: null,
              ctaFontSize: null,
              interactiveLink: null,
              conceptThumbnailUrl: null,
              createdAt: '2024-01-04T06:45:08.000Z',
              mazeUrl: null,
              stepFlags: 0,
              endSurvey: false,
            },
            optionValues: [],
          },
        ],
        prototypes: [],
      },
      {
        uid: '41034aa5-ac57-4685-832c-5e90c474fdb3',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-04T06:44:16.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '70b97c30-dc0d-4e3f-8613-57dcf2514023',
            type: 'USER',
            value:
              'Raphaël;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-hvEftpaecII5.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {borderRadius: 8},
            createdAt: '2024-01-04T06:41:34.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'ba46c446-0eec-4414-aef8-7633a9ca1fc2',
            type: 'TITLE',
            value: 'Can you tell us how can we improve our product?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 20,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-04T06:44:16.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'ebc3c2c8-eb28-415d-8760-501eda54bfb2',
            type: 'PRIMARY_CTA',
            value: 'Submit;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-01-04T06:44:16.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'ef65b1a4-1d0d-4b6e-ad13-67fb263a7053',
            type: 'OPEN_QUESTION',
            value: 'Enter your feedback',
            rawValue: null,
            flags: 0,
            style: {borderRadius: 8, primaryColor: '#d4d4de4d'},
            createdAt: '2024-01-04T06:44:16.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '8ed21beb-ecc7-427d-ab27-58561aed1db1',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 2',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-04T06:49:03.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '31c95d23-7f7f-46a9-a027-0fe657ea329b',
            type: 'OPEN_QUESTION',
            value: 'Let us know',
            rawValue: null,
            flags: 0,
            style: {borderRadius: 8, primaryColor: '#d4d4de4d'},
            createdAt: '2024-01-04T06:49:03.000Z',
            file: null,
            options: [],
          },
          {
            uid: '381f0a8b-fdbf-433b-a04e-0de92290a710',
            type: 'PRIMARY_CTA',
            value: 'Continue;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-01-04T06:49:03.000Z',
            file: null,
            options: [],
          },
          {
            uid: '404d4a2d-4bcd-4c6b-8fc3-85c3edca745d',
            type: 'BODY',
            value: '<p>Would you like to share what went wrong?</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Would you like to share what went wrong?',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-04T06:49:20.000Z',
            file: null,
            options: [],
          },
          {
            uid: '5899eb7b-8182-4659-b731-416408fbf5f1',
            type: 'TITLE',
            value: 'We are deeply sorry!|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 20,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-04T06:49:03.000Z',
            file: null,
            options: [],
          },
          {
            uid: '614dde3b-4296-45c7-9973-b01ac156abcb',
            type: 'USER',
            value:
              'Raphaël;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-hvEftpaecII5.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {borderRadius: 8},
            createdAt: '2024-01-04T06:46:07.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'a8a60c9c-1d56-4b88-a5c9-6c74565cdfa0',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step success 1',
        question: null,
        additionalInformation: null,
        type: 'SUCCESS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 3,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-04T06:45:08.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '1a381bcd-55bf-466b-b06a-3025a28b1c69',
            type: 'USER',
            value:
              'Raphaël;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-hvEftpaecII5.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {borderRadius: 8},
            createdAt: '2024-01-04T06:45:32.000Z',
            file: null,
            options: [],
          },
          {
            uid: '4e23ca8b-3faf-4a2d-a648-f0c75c96efd3',
            type: 'ANIMATION',
            value: 'confetti',
            rawValue: null,
            flags: 0,
            style: null,
            createdAt: '2024-01-04T06:45:08.000Z',
            file: null,
            options: [],
          },
          {
            uid: '52d02412-42f9-4a93-900a-a1c965009db7',
            type: 'BODY',
            value:
              '<p>You just completed our survey. We really appreciate your response.</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'You just completed our survey. We really appreciate your response.',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-04T06:45:08.000Z',
            file: null,
            options: [],
          },
          {
            uid: '8d385865-32e2-4b5c-93dc-58999f3bd42f',
            type: 'TITLE',
            value: 'Thank you for your input!|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-04T06:45:08.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
    ],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: null,
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: '96fdc22a-6b2e-41c5-ae37-05fc178dc98c',
    image: Tour11,
    tags: ['Marketing'],
    type: 'TOUR',
    title: 'Give a special message to users',
    description:
      'Craft a personalized message to engage your audience. Ideal for product launches, promotions, or thanking customers. Boost marketing impact by creating a memorable connection with your users.',
    tourSteps: [
      {
        uid: '6fbf3f73-68b7-4a81-b082-86adf5ae8c67',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-03T07:00:35.000Z',
        createdAt: '2024-01-03T06:56:11.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-02-01T08:37:51.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'MODAL',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '96fdc22a-6b2e-41c5-ae37-05fc178dc98c',
        boostFlags: 2,
        steps: [
          {
            uid: '44d6c9b7-e5a7-4da7-b302-24ec0f6c30ec',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-03T06:56:11.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '6bbdf520-8337-4e2e-8209-87bc11e953a7',
                type: 'MEDIA',
                value: 'video;https://www.youtube.com/watch?v=1kYXkKqsCaA;',
                rawValue: null,
                flags: 0,
                style: {
                  height: 320,
                  padding: 20,
                  position: 'top',
                  borderRadius: 16,
                },
                createdAt: '2024-01-03T06:56:15.000Z',
                file: null,
              },
              {
                uid: '790d0314-1aaf-4264-9eb2-fba1a04a4013',
                type: 'BODY',
                value:
                  '<p>I want to express my heartfelt gratitude for being part of our journey. Your support fuels our passion for innovation. Thank you for being with us every step of the way. Together, let&apos;s continue shaping the future!</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: "I want to express my heartfelt gratitude for being part of our journey. Your support fuels our passion for innovation. Thank you for being with us every step of the way. Together, let's continue shaping the future!",
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-03T06:56:11.000Z',
                file: null,
              },
              {
                uid: '80b63ff5-00b3-4582-95b7-a5331b990440',
                type: 'USER',
                value:
                  'Raphaël;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-hvEftpaecII5.jpg;undefined',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-01-03T06:59:53.000Z',
                file: null,
              },
              {
                uid: 'b78dc738-c545-4071-b03e-00b8a1f9e48e',
                type: 'TITLE',
                value:
                  'Hey, you received a special Message from Our Co-Founder 👀|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-03T06:56:11.000Z',
                file: null,
              },
              {
                uid: 'cb391e5f-c3c1-4608-ab07-cf34ab9441e0',
                type: 'PRIMARY_CTA',
                value:
                  'Watch Video;step-next;url-open;https://www.youtube.com/watch?v=1kYXkKqsCaA',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-03T06:56:11.000Z',
                file: null,
              },
              {
                uid: 'e474f9fd-48be-49b9-a1a3-306d7c280c20',
                type: 'SECONDARY_CTA',
                value: 'Close;close;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-01-03T06:59:30.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: '9b21581b-76aa-4007-9336-035db8cab480',
    image: Tour17,
    tags: ['Feature Announcement'],
    type: 'SNIPPET',
    title: 'Announce new feature with media',
    description:
      'Effortlessly unveil your latest updates with an image or video. Use this template to craft engaging feature announcements by using multimedia content. Perfect for introducing new functionalities and giving users a visual of your announcement.',
    tourSteps: [
      {
        uid: '1e9a0072-e457-46a7-a677-9668ec16c5be',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#0079f8ff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2023-12-14T08:39:26.000Z',
        createdAt: '2023-12-14T08:35:18.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:25:46.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;pulse',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'MODAL',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '9b21581b-76aa-4007-9336-035db8cab480',
        boostFlags: 2,
        steps: [
          {
            uid: 'cf9e17f4-fee6-464a-987d-cf3d0775b90b',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2023-12-14T08:35:18.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '344e78ee-9c9c-4244-bf41-16002af90344',
                type: 'TITLE',
                value: 'Welcome to Jimo!|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2023-12-14T08:35:19.000Z',
                file: null,
              },
              {
                uid: '4b5fc8da-f7ea-4c0c-bb2e-d739d04de5dd',
                type: 'PRIMARY_CTA',
                value: 'Explore Jimo;step-next;url-open;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 16,
                  fontSize: 16,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2023-12-14T08:35:19.000Z',
                file: null,
              },
              {
                uid: '55ddba5b-d333-4172-a4c1-1d8ccedef4c0',
                type: 'BODY',
                value:
                  '<p>You can watch our product showcase to see how much you can achieve in Jimo!</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'You can watch our product showcase to see how much you can achieve in Jimo!',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2023-12-14T08:35:19.000Z',
                file: null,
              },
              {
                uid: '95061a74-582a-4852-81b3-a7677419b28a',
                type: 'USER',
                value:
                  'Raphaël;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-hvEftpaecII5.jpg;undefined',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-01-03T03:07:13.000Z',
                file: null,
              },
              {
                uid: 'c4bab0ba-ddda-4a90-9696-d24a3cf83103',
                type: 'MEDIA',
                value:
                  'video;https://www.loom.com/share/f59595c4a04c4c849b8e5d3f0fcc57e4?sid=663fddaa-8461-4eea-8b82-1a3a72de6f9e;',
                rawValue: null,
                flags: 0,
                style: {
                  height: 360,
                  padding: 20,
                  position: 'top',
                  borderRadius: 16,
                },
                createdAt: '2023-12-14T08:36:15.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#0079f8ff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: 'a97d23c4-fe9c-4e1b-a0c5-44f7ddacccb8',
    image: Tour13,
    tags: ['Nudging'],
    type: 'TOUR',
    title: 'Nudge users to start free trial',
    description:
      'Encourage conversion by strategically prompting users to explore the benefits of a free trial. Utilize this template to gently guide users towards experiencing the full range of features, fostering product understanding before commitment. Ideal for boosting conversion rates.',
    tourSteps: [
      {
        uid: '8672561e-785b-431b-996d-922d580353ab',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: false,
            primaryColor: '#aeffab00',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 9,
        lastStepChangeAt: '2024-01-03T06:27:44.000Z',
        createdAt: '2024-01-03T06:24:07.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:29:16.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'MODAL',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: 'a97d23c4-fe9c-4e1b-a0c5-44f7ddacccb8',
        boostFlags: 2,
        steps: [
          {
            uid: '7b5bcd2d-52fa-4b84-a27c-4693c8c9bd03',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-03T06:24:07.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '295bc250-b47a-4480-af87-b3ea5b6a8ff9',
                type: 'BODY',
                value:
                  '<p>Upgrade to the Startup Plan today and get exclusive benefits including</p><p></p><p></p><p><strong>&#x2713;</strong> Adding a custom domain</p><p></p><p><strong>&#x2713;</strong> 2,500 monthly active users</p><p></p><p><strong>&#x2713;</strong> 2 seats</p><p></p><p><strong>&#x2713;</strong> Up to 5 active experiences</p><p></p><p><strong>&#x2713;</strong> and much more</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Upgrade to the Startup Plan today and get exclusive benefits including',
                      },
                    ],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [{text: ''}],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [{text: ''}],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {bold: true, text: '✓'},
                      {text: ' Adding a custom domain'},
                    ],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [{text: ''}],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {bold: true, text: '✓'},
                      {text: ' 2,500 monthly active users'},
                    ],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [{text: ''}],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [{bold: true, text: '✓'}, {text: ' 2 seats'}],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [{text: ''}],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {bold: true, text: '✓'},
                      {text: ' Up to 5 active experiences'},
                    ],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [{text: ''}],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {bold: true, text: '✓'},
                      {text: ' and much more'},
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#757070ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-03T06:24:07.000Z',
                file: null,
              },
              {
                uid: '2cae7a3e-01ed-4872-b129-59980815f7dd',
                type: 'SECONDARY_CTA',
                value: 'Not now;close;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-01-03T06:27:06.000Z',
                file: null,
              },
              {
                uid: '7d38e949-6bdf-4138-934f-2b3974943a5b',
                type: 'PRIMARY_CTA',
                value:
                  'Start Free Trial;step-next;url-open;https://yoursite.com',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#1ba822ff',
                },
                createdAt: '2024-01-03T06:24:07.000Z',
                file: null,
              },
              {
                uid: 'ba0bd1e2-af9d-454c-bf90-e298899142ce',
                type: 'MEDIA',
                value:
                  'image;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-IgLJDqGvdVKl.png;logo;fill',
                rawValue: null,
                flags: 0,
                style: {
                  height: 240,
                  padding: 20,
                  position: 'top',
                  borderRadius: 16,
                },
                createdAt: '2024-01-03T06:27:34.000Z',
                file: null,
              },
              {
                uid: 'c6cc0142-5a7f-433a-bc1d-ea4a0f3583e4',
                type: 'TITLE',
                value: 'Elevate Your Experience with Startup Plan|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-03T06:24:07.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'SURVEY',
    id: 'ae1bd6b3-694e-4468-b961-51a23e5ecf1e',
    image: Survey3,
    tags: ['Marketing', 'E-commerce'],
    type: 'SNIPPET',
    title: 'Receive subscription emails by offering discount benefits',
    description:
      'Send targeted discount offers via email to subscribers, driving sales and loyalty. Ideal for e-commerce businesses aiming to nurture relationships and drive conversions.',
    tourSteps: [],
    steps: [
      {
        uid: '36086cfa-4c30-4f8c-98a6-a1e27bef95fc',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step success 1',
        question: null,
        additionalInformation: null,
        type: 'SUCCESS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-24T04:54:49.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '5e101ed0-5ec8-41b4-8f84-340682b1e717',
            type: 'ANIMATION',
            value: 'confetti',
            rawValue: null,
            flags: 0,
            style: null,
            createdAt: '2024-01-24T04:54:49.000Z',
            file: null,
            options: [],
          },
          {
            uid: '60131f50-fbb5-4746-896f-9ceccbfc1a05',
            type: 'BODY',
            value:
              '<p>We&apos;ve just sent you the discount code to your email. Happy shopping!</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: "We've just sent you the discount code to your email. Happy shopping!",
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-24T04:54:49.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'd7ef8d8b-98a1-4221-9167-2990f98a43d9',
            type: 'TITLE',
            value: 'Thank you for your input!|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Playfair Display',
              fontWeight: '600',
            },
            createdAt: '2024-01-24T04:54:49.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'a3bf8dd4-b858-4a0c-9bb9-9e309d140acf',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-04T05:42:21.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '01955e6c-e1c4-48d4-b079-d4272b2156a5',
            type: 'LABEL',
            value: 'Limited offer',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 8,
              fontSize: 14,
              fontColor: '#ff0000ff',
              fontFamily: 'Montserrat',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#da31311a',
            },
            createdAt: '2024-01-04T05:43:24.000Z',
            file: null,
            options: [],
          },
          {
            uid: '06ec5fd3-309a-4cfc-bf30-de1c24dd5679',
            type: 'PRIMARY_CTA',
            value: 'Get my 10% Off;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Inter',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#ff6000ff',
            },
            createdAt: '2024-01-04T05:42:21.000Z',
            file: null,
            options: [],
          },
          {
            uid: '9ab62b19-9316-4f3b-9f9e-a447a36c6e3f',
            type: 'TITLE',
            value: 'Join us & enjoy 10% off on your first order|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 26,
              fontColor: '#000000ff',
              fontFamily: 'Playfair Display',
              fontWeight: '600',
            },
            createdAt: '2024-01-04T05:42:21.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'b37c3614-61db-4fc4-95c1-2ae8647db1db',
            type: 'OPEN_QUESTION',
            value: 'Enter your email address',
            rawValue: null,
            flags: 0,
            style: {borderRadius: 8, primaryColor: '#ffffff4d'},
            createdAt: '2024-01-04T05:42:21.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
    ],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: null,
      background: {
        type: 'color',
        animated: true,
        primaryColor: '#ffe6bfff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: 'af6dd483-5eb2-477d-a01f-58e44313bb30',
    image: Tour18,
    tags: ['Feature Announcement'],
    type: 'SNIPPET',
    title: 'Announce new feature contextually',
    description:
      'Seamlessly introduce new app features with this template. It guides you to communicate updates contextually, ensuring users stay informed and engaged. Perfect for enhancing user experience through a simple product tour.',
    tourSteps: [
      {
        uid: '97b71403-ea9d-432b-bc38-55fb8e5624c6',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#0079f8ff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 524297,
        lastStepChangeAt: '2023-12-14T05:18:32.000Z',
        createdAt: '2023-12-14T05:01:33.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-25T13:26:47.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: '.px-4:nth-child(3)',
        boostedPositionFlags: 16,
        boostedPositionOffsets: '-400;400;72;110',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://templates.usejimo.com/',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#3a16e7ff;24;default;pulse',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'HOTSPOT',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: 'af6dd483-5eb2-477d-a01f-58e44313bb30',
        boostFlags: 8,
        steps: [
          {
            uid: 'b6688949-2fd9-4d60-a789-810aae112c70',
            style: {
              width: 456,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2023-12-14T05:01:33.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '0394c7e7-ad39-49c0-a79b-2c5add0871ba',
                type: 'LABEL',
                value: 'New',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#ff0000ff',
                  fontFamily: 'Montserrat',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#da31311a',
                },
                createdAt: '2024-01-03T03:19:34.000Z',
                file: null,
              },
              {
                uid: '6e4fcece-11a2-41e9-a4fc-c85dd5d8a002',
                type: 'SECONDARY_CTA',
                value: 'Skip;close;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 16,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2023-12-14T08:43:33.000Z',
                file: null,
              },
              {
                uid: 'ace4659c-5be8-414d-993b-667afb5ffdf4',
                type: 'TITLE',
                value: 'HubSpot Integration is Now Available!|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2023-12-14T05:01:33.000Z',
                file: null,
              },
              {
                uid: 'ce2b5b25-fdaf-402a-b3a0-8a1ffa2ba5f2',
                type: 'BODY',
                value:
                  '<p>Streamline your workflow and boost productivity effortlessly!</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Streamline your workflow and boost productivity effortlessly!',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2023-12-14T05:01:33.000Z',
                file: null,
              },
              {
                uid: 'de15cdbd-a208-4c8a-8db2-c5143eb63ea1',
                type: 'PRIMARY_CTA',
                value:
                  'Try now;step-next;url-open;https://yourapp.com/integration',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 16,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2023-12-14T05:01:33.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#0079f8ff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: 'b0cd7045-af8d-41dc-9a90-999da4011587',
    image: Tour8,
    tags: ['Onboarding'],
    type: 'TOUR',
    title: 'Take users on a new feature tour',
    description:
      "Guide users through your app's latest features with this onboarding template. Perfect for introducing updates, making users feel confident exploring new functionalities.",
    tourSteps: [
      {
        uid: '5d8679a9-c04c-479f-8c8b-b7445d612223',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-31T07:03:26.000Z',
        createdAt: '2024-01-31T06:59:16.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-31T07:09:14.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: '.px-3:nth-child(3)',
        boostedPositionFlags: 0,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '3;3;0',
        context: null,
        type: 'TOOLTIP',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: 'b0cd7045-af8d-41dc-9a90-999da4011587',
        boostFlags: 32,
        steps: [
          {
            uid: 'b8de00e4-5ed9-4442-b722-79f9a2896f27',
            style: {
              width: 400,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-31T06:59:16.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '214b263f-0d51-4b30-b9d2-1e8888b9b2e6',
                type: 'TITLE',
                value: 'Save your filters in a view|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-31T06:59:16.000Z',
                file: null,
              },
              {
                uid: '25ec297c-6cc1-451b-a7f1-8c94a54dd8c1',
                type: 'BODY',
                value:
                  '<p>Boost productivity by saving your favorite filters into a view. Everyone in your team to would see the same view as well</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Boost productivity by saving your favorite filters into a view. Everyone in your team to would see the same view as well',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-31T06:59:16.000Z',
                file: null,
              },
              {
                uid: '4cb49e0d-12b0-4c51-9c05-67bb8eff34a5',
                type: 'PRIMARY_CTA',
                value: 'Finish;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-31T06:59:16.000Z',
                file: null,
              },
              {
                uid: '4e8e20bd-d219-4746-97a8-c8fde7a97571',
                type: 'STEPPER',
                value: null,
                rawValue: null,
                flags: 0,
                style: {
                  type: 'dot-line',
                  align: 'left',
                  position: 'top',
                  primaryColor: '#0038ffff',
                  secondaryColor: '#0038ff33',
                },
                createdAt: '2024-01-31T06:59:16.000Z',
                file: null,
              },
              {
                uid: '9923dfbe-89f9-47e0-896e-d950aed51199',
                type: 'SECONDARY_CTA',
                value: 'Back;step-prev;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-01-31T06:59:16.000Z',
                file: null,
              },
            ],
          },
        ],
      },
      {
        uid: '60f461b7-4d28-408e-a697-b518c857464c',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-31T07:03:26.000Z',
        createdAt: '2024-01-31T06:59:11.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-31T07:09:14.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: '.mb-6:nth-child(2)',
        boostedPositionFlags: 0,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '2;2;1',
        context: null,
        type: 'TOOLTIP',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: 'b0cd7045-af8d-41dc-9a90-999da4011587',
        boostFlags: 32,
        steps: [
          {
            uid: '7abb31d7-e0b5-4bd1-87d7-11de1c91fdc7',
            style: {
              width: 400,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-31T06:59:11.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '6b82a866-dd29-4441-b11c-00d0efaeaeca',
                type: 'PRIMARY_CTA',
                value: 'Next;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-31T06:59:12.000Z',
                file: null,
              },
              {
                uid: '8e29759d-2c34-4977-a117-7a9d785e02c8',
                type: 'SECONDARY_CTA',
                value: 'Back;step-prev;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-01-31T06:59:12.000Z',
                file: null,
              },
              {
                uid: '95b77b0f-e7d2-4455-8bb0-f4f9fa3b09b0',
                type: 'STEPPER',
                value: null,
                rawValue: null,
                flags: 0,
                style: {
                  type: 'dot-line',
                  align: 'left',
                  position: 'top',
                  primaryColor: '#0038ffff',
                  secondaryColor: '#0038ff33',
                },
                createdAt: '2024-01-31T06:59:12.000Z',
                file: null,
              },
              {
                uid: 'b56de0c9-ca0a-4f91-b449-f3e0cafc8943',
                type: 'TITLE',
                value: 'Fine-Tune Your Results|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-31T06:59:12.000Z',
                file: null,
              },
              {
                uid: 'c98957ae-792c-41b4-b738-605607e791dd',
                type: 'BODY',
                value:
                  '<p>Dive deeper with multi-layered filtering. Refine your searches and isolate the information you need with precision.</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Dive deeper with multi-layered filtering. Refine your searches and isolate the information you need with precision.',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-31T06:59:12.000Z',
                file: null,
              },
            ],
          },
        ],
      },
      {
        uid: '94a963db-9d12-4fcf-a35f-c342f9b29696',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-31T07:02:33.000Z',
        createdAt: '2024-01-31T06:59:03.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-31T07:09:14.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: '.px-4:nth-child(3)',
        boostedPositionFlags: 16,
        boostedPositionOffsets: '0;0;0;110',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://templates.usejimo.com/',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '1;1;2',
        context: null,
        type: 'TOOLTIP',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: 'b0cd7045-af8d-41dc-9a90-999da4011587',
        boostFlags: 32,
        steps: [
          {
            uid: '64f47b4d-eb82-4c85-935d-ce83af59affd',
            style: {
              width: 400,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-31T06:59:03.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '10d4c6d5-13a2-4c69-a7fd-59d02ce5ed0d',
                type: 'TITLE',
                value: 'Customize Your View here|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-31T06:59:12.000Z',
                file: null,
              },
              {
                uid: '35f36f89-d172-4880-ae5f-9bb3a9aa2f06',
                type: 'SECONDARY_CTA',
                value: 'Back;step-prev;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-01-31T06:59:12.000Z',
                file: null,
              },
              {
                uid: '7ec2e8d8-ab05-4b94-84c8-3180ebb9bd46',
                type: 'PRIMARY_CTA',
                value: 'Next;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-31T06:59:12.000Z',
                file: null,
              },
              {
                uid: '98579474-c666-461b-8240-4af23ca128fd',
                type: 'BODY',
                value:
                  '<p>Uncover the flexibility of our filters. Tailor your view to focus on what matters most to you</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Uncover the flexibility of our filters. Tailor your view to focus on what matters most to you',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-31T06:59:12.000Z',
                file: null,
              },
              {
                uid: 'db52bdf0-691b-4fa0-bf2d-7837ed69be0c',
                type: 'STEPPER',
                value: null,
                rawValue: null,
                flags: 0,
                style: {
                  type: 'dot-line',
                  align: 'left',
                  position: 'top',
                  primaryColor: '#0038ffff',
                  secondaryColor: '#0038ff33',
                },
                createdAt: '2024-01-31T06:59:12.000Z',
                file: null,
              },
            ],
          },
        ],
      },
      {
        uid: 'f0a0e01e-27f1-4e29-86d9-9f8c50a49d72',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'slide-down',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 9,
        lastStepChangeAt: '2024-01-03T07:54:16.000Z',
        createdAt: '2024-01-03T07:36:37.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-31T07:09:14.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://templates.usejimo.com/',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;3',
        context: null,
        type: 'SNIPPET',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: 'b0cd7045-af8d-41dc-9a90-999da4011587',
        boostFlags: 4,
        steps: [
          {
            uid: 'b2cacfdc-6ddc-4708-89fc-3509badf9f41',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-03T07:36:37.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '095c859f-1a57-4294-bb97-9e2106a49ae6',
                type: 'LABEL',
                value: 'New feature',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#ff0000ff',
                  fontFamily: 'Montserrat',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#da31311a',
                },
                createdAt: '2024-01-03T07:41:46.000Z',
                file: null,
              },
              {
                uid: '5138bbf0-083a-43fc-a669-4b133daba9ce',
                type: 'MEDIA',
                value:
                  'image;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-JRvIow1Xlr39.png;logo;fill',
                rawValue: null,
                flags: 0,
                style: {padding: 20, position: 'top', borderRadius: 16},
                createdAt: '2024-01-03T07:43:23.000Z',
                file: null,
              },
              {
                uid: '744471e8-ec7b-4948-9be9-3b0ce1a93f03',
                type: 'TITLE',
                value: 'Filtering just got easier|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-03T07:36:37.000Z',
                file: null,
              },
              {
                uid: 'b8d5ada6-a458-43a4-abcf-acd5893cab1f',
                type: 'SECONDARY_CTA',
                value: 'Maybe later;close;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#4950731a',
                },
                createdAt: '2024-01-03T07:43:32.000Z',
                file: null,
              },
              {
                uid: 'd460b7aa-7bf2-4723-bbc8-5dd93ef05113',
                type: 'PRIMARY_CTA',
                value: 'Take a look;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-03T07:36:37.000Z',
                file: null,
              },
              {
                uid: 'dbf8deed-46a7-47b8-b397-2c2c43dce805',
                type: 'BODY',
                value:
                  '<p>Let&apos;s try our latest feature that empowers you to streamline your experience. With advanced filtering, managing your data has never been more efficient.</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: "Let's try our latest feature that empowers you to streamline your experience. With advanced filtering, managing your data has never been more efficient.",
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-03T07:36:37.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'BANNER',
    id: 'b4b6ee23-ef14-4739-8461-144e6acd01bb',
    image: Banner2,
    tags: ['Product Insight'],
    type: 'TOUR',
    title: 'Announce a downtime maintenance',
    description:
      'Quickly inform your users about scheduled maintenance or fixes. This template ensures clear communication during downtime, minimizing user confusion. Ideal for planned maintenance notifications.',
    tourSteps: [
      {
        uid: '95df8664-1f06-463d-bf4a-d2922677e687',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'color',
            animated: true,
            primaryColor: '#ff8300ff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2359305,
        lastStepChangeAt: '2024-01-03T07:10:39.000Z',
        createdAt: '2024-01-03T07:07:21.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:52:53.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'fade-in;fade-out',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'BANNER',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: 'b4b6ee23-ef14-4739-8461-144e6acd01bb',
        boostFlags: 1,
        steps: [
          {
            uid: '99217df0-a8a2-432b-b7f7-d188394b252e',
            style: {
              width: null,
              height: 30,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-03T07:07:21.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '6d2602ce-7593-4ee6-a305-beed1dfb0741',
                type: 'TITLE',
                value:
                  'Upcoming scheduled maintenance: Saturday, March 6 2024 2:00 PM PST. Jimo will be unavailable for 2 hours.|-|url-open|-|[Your status page]',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '500',
                },
                createdAt: '2024-01-03T07:09:34.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: 'b7897200-c28f-413a-aea4-644c5aa6269c',
    image: Tour12,
    tags: ['Onboarding'],
    type: 'TOUR',
    title: 'Celebrate users for an achievement',
    description:
      'Engage new users by celebrating their milestones! Use this template to create a warm welcome, fostering a positive first impression. Recognize their achievements and guide users towards a rewarding app experience.',
    tourSteps: [
      {
        uid: 'bd8cdd05-5a26-4997-8a65-bbd7f60d2471',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#26a1ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 9,
        lastStepChangeAt: '2024-01-03T06:52:12.000Z',
        createdAt: '2024-01-03T06:41:13.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:30:48.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'MODAL',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: 'b7897200-c28f-413a-aea4-644c5aa6269c',
        boostFlags: 2,
        steps: [
          {
            uid: '67bba053-8888-452c-94d0-7bf635034294',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-03T06:41:13.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '3218208c-6ebd-4997-93c7-07edea469ba2',
                type: 'PRIMARY_CTA',
                value: 'Got it, Thanks!;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-03T06:41:13.000Z',
                file: null,
              },
              {
                uid: '37050e93-d3d7-4067-905e-25e45e53247a',
                type: 'USER',
                value:
                  'Raphaël;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-hvEftpaecII5.jpg;undefined',
                rawValue: null,
                flags: 0,
                style: {borderRadius: 8},
                createdAt: '2024-01-03T06:44:16.000Z',
                file: null,
              },
              {
                uid: '5208c56d-b648-4015-9993-417a36ce2c23',
                type: 'MEDIA',
                value:
                  'image;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-KRLte6oDu40s.png;logo;fit',
                rawValue: null,
                flags: 0,
                style: {padding: 20, position: 'top', borderRadius: 16},
                createdAt: '2024-01-03T06:44:18.000Z',
                file: null,
              },
              {
                uid: 'a1541b7c-957f-412e-bf54-a46233f9b768',
                type: 'BODY',
                value:
                  '<p>We&apos;re thrilled to celebrate your 100th milestone on Jimo! Your dedication and success are truly commendable. Keep up the great work! &#x1f973;</p>',
                rawValue: [
                  {
                    type: 'p',
                    children: [
                      {
                        text: "We're thrilled to celebrate your 100th milestone on Jimo! Your dedication and success are truly commendable. Keep up the great work! 🥳",
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-03T06:41:13.000Z',
                file: null,
              },
              {
                uid: 'f7ef429b-f53b-4f9a-9c48-d020fa41d5c2',
                type: 'TITLE',
                value:
                  'Woohoo! Congratulations on Unlocking your 100th Milestone 🎉\n|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-03T06:41:13.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: 'beb077cd-86a4-486a-8b50-ec6e28e03df8',
    image: Tour5,
    tags: ['Marketing', 'E-commerce'],
    type: 'TOUR',
    title: 'Direct users to a new product',
    description:
      'Guide users to a specific product. Ideal for marketing campaigns in e-commerce apps, ensuring a seamless introduction to new offerings, boosting engagement, and driving conversions.',
    tourSteps: [
      {
        uid: 'c5363afe-0fb1-4cca-86c9-d27b6b2aebba',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'color',
            animated: true,
            primaryColor: '#ffffffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-04T05:32:33.000Z',
        createdAt: '2024-01-04T05:28:57.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:47:03.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'MODAL',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: 'beb077cd-86a4-486a-8b50-ec6e28e03df8',
        boostFlags: 2,
        steps: [
          {
            uid: '4722e264-562f-424b-a64e-de7ccd2aac95',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-04T05:28:57.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '40e6c9fe-1bfa-48dd-a051-e9660fadd62a',
                type: 'TITLE',
                value: 'Have you seen our new product?|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-04T05:28:57.000Z',
                file: null,
              },
              {
                uid: '41c98f12-53de-41ce-82fb-de577333c0c1',
                type: 'MEDIA',
                value:
                  'image;https://images.unsplash.com/photo-1695527081793-91a2d4b5b103?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D;logo;fill',
                rawValue: null,
                flags: 0,
                style: {
                  height: 356,
                  padding: 20,
                  position: 'top',
                  borderRadius: 16,
                },
                createdAt: '2024-01-04T05:30:12.000Z',
                file: null,
              },
              {
                uid: '9be4495f-24ec-43a8-abaa-6532c329e65d',
                type: 'LABEL',
                value: 'Welcome Back',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#0038ffff',
                  fontFamily: 'Montserrat',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: null,
                },
                createdAt: '2024-01-04T05:29:46.000Z',
                file: null,
              },
              {
                uid: 'cbad11e6-edde-407d-aabc-e1ceab5f78c6',
                type: 'BODY',
                value:
                  '<p>Grab one now. We only have a limited of them in stock.</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Grab one now. We only have a limited of them in stock.',
                        object: 'text',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'center',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-04T05:28:57.000Z',
                file: null,
              },
              {
                uid: 'd3f62a92-bd35-4ae3-a428-af8d18da4db6',
                type: 'PRIMARY_CTA',
                value: 'Show me now;step-next;url-open;www.yourproduct.com',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-04T05:28:57.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'SURVEY',
    id: 'c56cd4d2-f697-49aa-b3de-a5bee55e6dce',
    image: Survey2,
    tags: ['Survey'],
    type: 'SNIPPET',
    title: 'Ask users where did the users find you',
    description: '',
    tourSteps: [],
    steps: [
      {
        uid: '7c44e54c-ac0d-4f03-abac-3f5eea1dac9c',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step multiple choice 1',
        question: null,
        additionalInformation: null,
        type: 'MULTIPLE_CHOICE_MULTI_SELECT',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-04T06:23:08.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '50cf4a10-e814-4318-8833-7497e16bbe78',
            type: 'CHOICE',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              padding: 12,
              fontSize: 14,
              fontFamily: 'Inter',
              disposition: 'inline',
              borderRadius: 12,
              primaryColor: '#dad3d352',
              withCheckbox: false,
            },
            createdAt: '2024-01-04T06:23:08.000Z',
            file: null,
            options: [
              {
                uid: '5ac65479-562f-4a7b-8ad6-f26b01c51000',
                content: 'Advertisement',
                indexOrder: 3,
              },
              {
                uid: '94ad8f61-6ac1-4e02-8bef-6fc5281f8a09',
                content: 'Instagram',
                indexOrder: 0,
              },
              {
                uid: '983b3165-0f62-493c-8b02-d1e1b4408735',
                content: 'Friends',
                indexOrder: 2,
              },
              {
                uid: 'c4d9f39d-84d1-495b-b6fc-f8cc15ce3ebc',
                content: 'Facebook',
                indexOrder: 1,
              },
              {
                uid: 'f8714693-43f3-488e-8e46-29a229b85d33',
                content: 'Other',
                indexOrder: 4,
              },
            ],
          },
          {
            uid: 'b37f0515-c587-43bf-874b-4a3a5c4e6407',
            type: 'TITLE',
            value: 'How did you initially hear about us?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-04T06:23:08.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'd44706d3-c88b-45dc-b56a-cf3cb17e2b06',
            type: 'LABEL',
            value: 'Thanks for purchasing! 🥳',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 0,
              fontSize: 14,
              fontColor: '#08915fff',
              fontFamily: 'Montserrat',
              borderColor: null,
              borderRadius: 24,
              primaryColor: null,
            },
            createdAt: '2024-01-04T06:23:54.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'e4f0eadb-9906-432e-a989-1fb715a954b2',
            type: 'PRIMARY_CTA',
            value: 'Submit;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#08915fff',
            },
            createdAt: '2024-01-04T06:23:08.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'af26bb46-2686-4603-a971-d3ab0dee6671',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step success 1',
        question: null,
        additionalInformation: null,
        type: 'SUCCESS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-04T06:27:20.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '0b576feb-8a2f-42fb-ad95-52ac0f1ac5ef',
            type: 'PRIMARY_CTA',
            value: 'Close;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#08915fff',
            },
            createdAt: '2024-01-04T06:27:20.000Z',
            file: null,
            options: [],
          },
          {
            uid: '14023dcc-ee59-4e27-ab52-7f1d66dde8d8',
            type: 'BODY',
            value:
              '<p>You just completed our survey. We really appreciate it!</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'You just completed our survey. We really appreciate it!',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#000000',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-04T06:27:20.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'bb7fb777-c37d-4aa7-a9a8-708c5c35019a',
            type: 'ANIMATION',
            value: 'confetti',
            rawValue: null,
            flags: 0,
            style: null,
            createdAt: '2024-01-04T06:27:20.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'fe9917d5-2a25-4852-b48d-e40cda314ef0',
            type: 'TITLE',
            value: 'Thank you for your response! 🎉|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-04T06:27:20.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
    ],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: null,
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#1dd08eff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'BANNER',
    id: 'd20a961d-9a6e-4b63-9c9e-5ae355fba7f3',
    image: Banner3,
    tags: ['Feature Announcement'],
    type: 'TOUR',
    title: 'Announce a webinar',
    description:
      'Quickly share key details about upcoming webinars with your audience. Use this template to provide clear schedules, topics, and registration links.',
    tourSteps: [
      {
        uid: 'e0a34ed2-0504-4f90-81cd-fc53959f684b',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#4688ffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 262153,
        lastStepChangeAt: '2024-01-03T06:20:32.000Z',
        createdAt: '2024-01-03T06:19:35.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:52:28.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'fade-in;fade-out',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'BANNER',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: 'd20a961d-9a6e-4b63-9c9e-5ae355fba7f3',
        boostFlags: 1,
        steps: [
          {
            uid: 'bd8fd622-eb6d-4d3b-8f9b-6e2bab5d8db4',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-03T06:19:35.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '7282455a-99a9-4531-b104-db8f140dd9fc',
                type: 'PRIMARY_CTA',
                value: 'Register now;step-next;url-open;https://yourevent.com',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0038ffff',
                },
                createdAt: '2024-01-03T06:19:35.000Z',
                file: null,
              },
              {
                uid: 'fb1269a7-0920-4946-ac5e-f14729333bfe',
                type: 'TITLE',
                value:
                  'Next month! Join Us on Mastering Digital Marketing webinar in July 24, 2024|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 17,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-03T06:19:35.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: 'd6a038b7-6706-493f-97a0-dd712a79b19c',
    image: Tour7,
    tags: ['Nudging'],
    type: 'TOUR',
    title: "Draw user's attention to a specific element",
    description:
      'Guide users to highlight vital elements. Perfect for ensuring users notice critical information or take specific actions without overwhelming them. Ideal for enhancing user engagement and task completion. Use when clarity is key.',
    tourSteps: [
      {
        uid: '39f5ac39-5a40-433d-b4e1-bfff561ff8e3',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#00000021',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#c996e5ff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 9,
        lastStepChangeAt: '2024-01-04T04:50:22.000Z',
        createdAt: '2024-01-04T04:47:04.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:45:41.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: '.flex-wrap > .justify-end',
        boostedPositionFlags: 0,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://templates.usejimo.com/',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'TOOLTIP',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: 'd6a038b7-6706-493f-97a0-dd712a79b19c',
        boostFlags: 32,
        steps: [
          {
            uid: '5e859276-622e-422c-ae55-486af832496b',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-04T04:47:04.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '68888d69-1cd0-4585-b58d-c766de651328',
                type: 'BODY',
                value:
                  '<p>You can now effortlessly narrow down your results with our standout feature. </p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'You can now effortlessly narrow down your results with our standout feature. ',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-04T04:47:04.000Z',
                file: null,
              },
              {
                uid: 'b6c75c2f-bae0-4775-b48b-de5154e2a837',
                type: 'PRIMARY_CTA',
                value: 'Got it;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#a500ffff',
                },
                createdAt: '2024-01-04T04:47:04.000Z',
                file: null,
              },
              {
                uid: 'f5ae1a93-d04b-4b67-97dc-aa5fdaee5937',
                type: 'TITLE',
                value: 'Master Instant Filtering|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-04T04:47:04.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'SURVEY',
    id: 'dc6e9042-872c-4d17-ac1d-b371df4bdf85',
    image: Survey1,
    tags: ['Marketing', 'E-commerce'],
    type: 'SNIPPET',
    title: 'Receive subscription emails',
    description:
      'Effortlessly collect customer subscriptions with this template.  Ideal for e-commerce businesses aiming to enhance customer engagement and drive revenue through targeted communication by collecting visitors emails.',
    tourSteps: [],
    steps: [
      {
        uid: '221dc7d0-4892-4c72-b506-50f23a67ddfc',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-04T05:46:42.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '2e38e2f9-2a86-4e32-b552-42f991d69333',
            type: 'TITLE',
            value: 'Get our exclusive offers delivered to your inbox|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 24,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-04T05:46:42.000Z',
            file: null,
            options: [],
          },
          {
            uid: '34588408-4999-4d98-a1a4-323a79ad0b5e',
            type: 'OPEN_QUESTION',
            value: 'Enter your email address',
            rawValue: null,
            flags: 0,
            style: {borderRadius: 8, primaryColor: '#d4d4de4d'},
            createdAt: '2024-01-04T05:46:42.000Z',
            file: null,
            options: [],
          },
          {
            uid: '37db9611-f21a-428c-8bf3-89e63b8fe747',
            type: 'BODY',
            value: '<p>No spammy emails. We guarantee &#x1f44c;</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {text: 'No spammy emails. We guarantee 👌', object: 'text'},
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#9c9c9cff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-04T05:49:19.000Z',
            file: null,
            options: [],
          },
          {
            uid: '50e410c5-2177-4368-9b87-56d6fc199c10',
            type: 'PRIMARY_CTA',
            value: 'Subscribe now;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-01-04T05:46:42.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '386c7e6a-301f-47c3-901a-30be621f6e4a',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step success 1',
        question: null,
        additionalInformation: null,
        type: 'SUCCESS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-24T04:57:51.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '88f79b75-af3f-4850-b7de-fb09e088efa4',
            type: 'TITLE',
            value: 'Email Submitted Successfully|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 24,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-24T04:57:51.000Z',
            file: null,
            options: [],
          },
          {
            uid: '9dc41b3c-d7df-4dd9-b956-cd9eadfacdb9',
            type: 'BODY',
            value:
              '<p>Thank you! Your email has been successfully submitted. Stay tuned for updates and exclusive content &#x1f606;</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Thank you! Your email has been successfully submitted. Stay tuned for updates and exclusive content 😆',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#b5b5b5ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-24T04:57:51.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'b99d6d3b-e5d8-432e-a6d1-32b4868f1f25',
            type: 'ANIMATION',
            value: 'confetti',
            rawValue: null,
            flags: 0,
            style: null,
            createdAt: '2024-01-24T04:57:51.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
    ],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: null,
      background: {
        type: 'color',
        animated: true,
        primaryColor: '#000000ff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: 'fe69a4d2-9c89-4f58-9022-9edb44e04c0e',
    image: Tour16,
    tags: ['Feature Announcement'],
    type: 'TOUR',
    title: 'Announce a new feature with a modal',
    description:
      'Display this modal to highlight new functionalities, ensuring users stay informed and excited about your latest updates. Perfect for enhancing user experience and product awareness.',
    tourSteps: [
      {
        uid: 'da2e431d-a537-412e-8d81-6a4a170167a4',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#00000000',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#c079e5ff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-01-03T03:24:31.000Z',
        createdAt: '2024-01-03T03:21:31.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-24T04:27:22.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'MODAL',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: 'fe69a4d2-9c89-4f58-9022-9edb44e04c0e',
        boostFlags: 2,
        steps: [
          {
            uid: '3463ca40-3589-4695-9b3a-b264c7297a71',
            style: {
              width: 640,
              height: 892,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-03T03:21:31.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '45ceca55-4b1f-4118-a065-da94c96c1c27',
                type: 'PRIMARY_CTA',
                value:
                  'Discover Templates;step-next;url-open;https://yoursite.com',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#a500ffff',
                },
                createdAt: '2024-01-03T03:21:31.000Z',
                file: null,
              },
              {
                uid: '48e72ab3-78c1-4da5-8810-aaf6064e52ec',
                type: 'LABEL',
                value: 'New',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 10,
                  fontSize: 12,
                  fontColor: '#ff3800ff',
                  fontFamily: 'Inter',
                  borderColor: '#FFFFFF',
                  borderRadius: 24,
                  primaryColor: '#ff38001a',
                },
                createdAt: '2024-01-03T03:22:04.000Z',
                file: null,
              },
              {
                uid: 'c65e0b18-a3ed-4088-802d-97c4f4aa373d',
                type: 'TITLE',
                value: 'New Templates are in town!|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#000000ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-03T03:21:31.000Z',
                file: null,
              },
              {
                uid: 'f64ccac9-54d8-444d-b83f-92701b5b4562',
                type: 'BODY',
                value:
                  '<p>Explore our latest templates in town for a fresh and polished look!</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Explore our latest templates in town for a fresh and polished look!',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#545454ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-03T03:21:31.000Z',
                file: null,
              },
              {
                uid: 'fca797a0-d4e6-488e-b8a4-9091cf87a104',
                type: 'MEDIA',
                value:
                  'image;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-gE6eTuHc3q4A.png;logo;fill',
                rawValue: null,
                flags: 0,
                style: {
                  height: 360,
                  padding: 8,
                  position: 'bottom',
                  borderRadius: 8,
                },
                createdAt: '2024-01-03T03:22:24.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#00000000',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#c079e5ff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'TOUR',
    id: 'fe952205-3572-4b5a-bcce-0315389ebf7a',
    image: Tour1,
    tags: ['Feature Announcement'],
    type: 'TOUR',
    title: 'Announce a new beta feature ',
    description:
      'Effortlessly introduce your latest beta feature. This template ensures clear and engaging communication, guiding users to the new beta features. Perfect for keeping your audience informed and involved.',
    tourSteps: [
      {
        uid: '88c8b6b6-4f99-4adc-b526-cfe9cc1552f1',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'color',
            animated: true,
            primaryColor: '#202020ff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2057,
        lastStepChangeAt: '2024-01-04T07:07:35.000Z',
        createdAt: '2024-01-04T06:56:24.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-25T05:53:23.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://templates.usejimo.com/',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;1',
        context: null,
        type: 'MODAL',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: 'fe952205-3572-4b5a-bcce-0315389ebf7a',
        boostFlags: 2,
        steps: [
          {
            uid: 'd15c3b25-6f3d-4ad2-b887-7c16b4420f4b',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-04T06:56:24.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '018aa46d-665c-4d4a-9186-ce3580e246c6',
                type: 'TITLE',
                value: 'Introducing Dark Mode|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-04T06:56:24.000Z',
                file: null,
              },
              {
                uid: '1e3a47fa-64d7-432b-9f64-2c74a2a6998a',
                type: 'MEDIA',
                value:
                  'image;https://help.figma.com/hc/article_attachments/5905110452247/light-dark-theme.png;logo;fill',
                rawValue: null,
                flags: 0,
                style: {
                  height: 312,
                  padding: 20,
                  position: 'top',
                  borderRadius: 16,
                },
                createdAt: '2024-01-04T07:02:07.000Z',
                file: null,
              },
              {
                uid: '5432c04c-3d40-48b8-8730-83c31ca2ca2a',
                type: 'SECONDARY_CTA',
                value: 'Maybe later;close;;undefined',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#d791e2ff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderRadius: 24,
                  primaryColor: '#dc52e23b',
                },
                createdAt: '2024-01-04T07:05:18.000Z',
                file: null,
              },
              {
                uid: '543a97ec-7419-4736-b665-dff3e21e3ed8',
                type: 'PRIMARY_CTA',
                value: 'Try Dark Mode;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#a500ffff',
                },
                createdAt: '2024-01-04T06:56:24.000Z',
                file: null,
              },
              {
                uid: '84881e07-800c-4512-b1b3-a8ab24bc7763',
                type: 'LABEL',
                value: 'BETA',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#bdbdbdff',
                  fontFamily: 'Montserrat',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#e1e1e12b',
                },
                createdAt: '2024-01-04T07:00:15.000Z',
                file: null,
              },
              {
                uid: 'c51f056f-8d17-49e4-a5c5-ab6fad6de7e4',
                type: 'BODY',
                value:
                  '<p>Enhance your visual comfort and enjoy a sleek, modern look. Dive into the beta, explore, and share your feedback to help us refine the experience.</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Enhance your visual comfort and enjoy a sleek, modern look. Dive into the beta, explore, and share your feedback to help us refine the experience.',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#acacacff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-04T06:56:24.000Z',
                file: null,
              },
            ],
          },
        ],
      },
      {
        uid: 'a379f961-58d4-4798-b6f9-ccac1fc2e63c',
        style: {
          margin: 0,
          shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
          overlay: '#0000004d',
          background: {
            type: 'color',
            animated: true,
            primaryColor: '#202020ff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2057,
        lastStepChangeAt: '2024-01-04T07:09:44.000Z',
        createdAt: '2024-01-04T07:08:34.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-01-25T05:53:23.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: '.px-4:nth-child(3)',
        boostedPositionFlags: 16,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://templates.usejimo.com/',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '1;1;0',
        context: null,
        type: 'TOOLTIP',
        deviceFlags: 1,
        priority: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: 'fe952205-3572-4b5a-bcce-0315389ebf7a',
        boostFlags: 32,
        steps: [
          {
            uid: '3a02f7e4-064d-44f5-84ed-03f50601e183',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-01-04T07:08:34.000Z',
            mazeUrl: null,
            stepFlags: 0,
            endSurvey: false,
            blocks: [
              {
                uid: '17c5f821-85d5-4f69-92e4-951b06510ac1',
                type: 'BODY',
                value:
                  '<p>Easily toggle between light and dark themes for a personalized viewing experience.</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Easily toggle between light and dark themes for a personalized viewing experience.',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#acacacff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-01-04T07:08:35.000Z',
                file: null,
              },
              {
                uid: '4eaa08e4-5ef1-4b99-9139-6d91e8c64a5c',
                type: 'TITLE',
                value: 'Switch to dark mode|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 24,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                },
                createdAt: '2024-01-04T07:08:35.000Z',
                file: null,
              },
              {
                uid: '4fdcbca3-f1ef-4720-8eec-cd793ccc5c46',
                type: 'PRIMARY_CTA',
                value: 'Got it;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'right',
                  padding: 12,
                  fontSize: 15,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#a500ffff',
                },
                createdAt: '2024-01-04T07:08:35.000Z',
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'color',
        animated: true,
        primaryColor: '#202020ff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'SURVEY',
    id: '3684f768-8038-42f0-bc6c-2e5a7ac95b57',
    image: Survey9,
    tags: ['Product Evaluation'],
    type: 'SNIPPET',
    title: "Identify visitor's goal when using your product",
    description:
      'This survey template is crafted to identify and understand the primary goals of visitors when interacting with your product. It helps to clarify which activities are deemed most critical, time-consuming, and valuable by your users.',
    tourSteps: [],
    steps: [
      {
        uid: '699a29e1-128e-460a-a8ce-c478fdac4a8d',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T11:20:21.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '42c4f7f5-7366-4d02-8048-93070bfa6451',
            type: 'SECONDARY_CTA',
            value: 'Back;step-prev;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: '#FFFFFF',
              borderRadius: 24,
              primaryColor: '#f4f4f4ff',
            },
            createdAt: '2024-02-12T11:21:17.000Z',
            file: null,
            options: [],
          },
          {
            uid: '5b5e2544-005a-49aa-8a87-b09b1aa41f1d',
            type: 'TITLE',
            value:
              'Interesting! what are the activities you spent the most amount of time last week?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T11:20:21.000Z',
            file: null,
            options: [],
          },
          {
            uid: '6fc6c3c0-801d-4f10-9d16-529468a8d327',
            type: 'USER',
            value:
              'John Doe;CPO @Lance;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;null',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-12T11:20:21.000Z',
            file: null,
            options: [],
          },
          {
            uid: '842312d1-3d26-47a5-a78b-1884157bc847',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
              primaryColor: '#FFFFFF',
            },
            createdAt: '2024-02-12T11:20:21.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'b1a0f4e4-a119-4e55-9602-67a28c2b07c1',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#a500ffff',
            },
            createdAt: '2024-02-12T11:20:21.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '769bff25-89b5-461f-af32-d673c9a0ccef',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 2',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T11:22:04.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '5063bc39-4c11-4c5d-a650-d2b6f94ffbdc',
            type: 'SECONDARY_CTA',
            value: 'Back;step-prev;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: '#FFFFFF',
              borderRadius: 24,
              primaryColor: '#f4f4f4ff',
            },
            createdAt: '2024-02-12T11:22:42.000Z',
            file: null,
            options: [],
          },
          {
            uid: '781341c7-3a56-4926-82d6-62c2e4001a43',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#a500ffff',
            },
            createdAt: '2024-02-12T11:22:05.000Z',
            file: null,
            options: [],
          },
          {
            uid: '99c1baac-30de-4cea-8461-e26bff6db613',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
              primaryColor: '#FFFFFF',
            },
            createdAt: '2024-02-12T11:22:05.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'a38e2c94-ad34-4604-a5f7-538888438c9e',
            type: 'USER',
            value:
              'John Doe;CPO @Lance;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;null',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-12T11:22:05.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'fd1b670b-187b-4610-93f4-f446a4dd9658',
            type: 'TITLE',
            value: 'Why do you think so?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T11:22:05.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '90ca4152-c8de-4533-a9ed-ac1fc91c2f2a',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 3',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 3,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T11:23:22.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '206a5788-8efc-4cf7-910d-fc67afce0e44',
            type: 'USER',
            value:
              'John Doe;CPO @Lance;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;null',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-12T11:23:22.000Z',
            file: null,
            options: [],
          },
          {
            uid: '8b345e31-8dc8-427f-a064-e0496762de24',
            type: 'SECONDARY_CTA',
            value: 'Back;step-prev;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: '#FFFFFF',
              borderRadius: 24,
              primaryColor: '#f4f4f4ff',
            },
            createdAt: '2024-02-12T11:23:56.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'da51ee76-562d-4439-9713-1161b35c7410',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
              primaryColor: '#FFFFFF',
            },
            createdAt: '2024-02-12T11:23:22.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'edb99c54-92a4-4e87-806e-c933f42df69d',
            type: 'PRIMARY_CTA',
            value: 'Finish;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#a500ffff',
            },
            createdAt: '2024-02-12T11:23:22.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'fbf3bb52-c548-4836-9b0e-32286e653276',
            type: 'TITLE',
            value: 'What could be better for us to improve about it?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T11:23:22.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'a2f15016-2113-4f11-973c-aed60e201079',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step multiple choice 1',
        question: null,
        additionalInformation: null,
        type: 'MULTIPLE_CHOICE_MULTI_SELECT',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T11:17:36.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '4c0b3a42-db5c-4a18-b4e5-5655bc8aea0d',
            type: 'CHOICE',
            value: null,
            rawValue: null,
            flags: 2,
            style: {
              padding: 8,
              fontSize: 14,
              fontFamily: 'Inter',
              disposition: 'inline',
              borderRadius: 8,
              primaryColor: '#000000',
              withCheckbox: false,
            },
            createdAt: '2024-02-12T11:17:36.000Z',
            file: null,
            options: [
              {
                uid: '12af26b1-9cbb-42d9-900f-ef346f005550',
                content: 'Activity 3',
                indexOrder: 2,
              },
              {
                uid: '2312f60e-dd00-431d-b79e-3f162230ddf9',
                content: 'Activity 1',
                indexOrder: 0,
              },
              {
                uid: '281c534d-cc95-477b-8aaa-5624516840e3',
                content: 'Activity 2',
                indexOrder: 1,
              },
              {
                uid: 'eeb6d088-89cf-43c6-89b7-1abab73b734e',
                content: 'Activity 4',
                indexOrder: 3,
              },
            ],
          },
          {
            uid: '5d656edb-1e56-4911-bf74-72637d74ba1d',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#a500ffff',
            },
            createdAt: '2024-02-12T11:17:36.000Z',
            file: null,
            options: [],
          },
          {
            uid: '972f9c3c-07e1-429c-bf6a-2cb3ee8ab95f',
            type: 'USER',
            value:
              'John Doe;CPO @Lance;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;null',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-12T11:19:10.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'bc3316db-724a-4148-abb8-eb26a7a7e72a',
            type: 'TITLE',
            value:
              'Hey! What is your three most important activities when using [Product name]?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T11:17:36.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
    ],
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '0',
        y: '0',
        blur: '0',
        color: '#316eda00',
      },
      overlay: '#00000000',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#c079e5ff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'SURVEY',
    id: '4daa3d83-db95-4c3e-bddb-16f4745ffe52',
    image: Survey7,
    tags: ['Customer Satisfaction'],
    type: 'SNIPPET',
    title: 'Measure feature satisfaction',
    description:
      'This survey template is engineered for businesses to measure customer satisfaction with a specific feature. It offers a structured approach to collecting detailed feedback on user interaction and overall satisfaction with a newly implemented feature.',
    tourSteps: [],
    steps: [
      {
        uid: '08852164-e27d-472c-adf3-2c08cb86e7c3',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 3',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 7,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-13T11:36:44.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '104e26a3-c291-4f5a-a920-4586f6505a3a',
            type: 'TITLE',
            value:
              'Do you have any other feedback on [Feature name]?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-13T11:36:44.000Z',
            file: null,
            options: [],
          },
          {
            uid: '1875d639-07ee-45f0-a354-4878fda9b1f6',
            type: 'SECONDARY_CTA',
            value: 'Back;step-prev;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderRadius: 24,
              primaryColor: '#4950731a',
            },
            createdAt: '2024-02-13T11:37:18.000Z',
            file: null,
            options: [],
          },
          {
            uid: '5319b348-5b17-4d98-82e8-3a14ec790ec1',
            type: 'PRIMARY_CTA',
            value: 'Finish;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-02-13T11:36:44.000Z',
            file: null,
            options: [],
          },
          {
            uid: '53f054c5-6aca-41ec-a036-3f1a631ff550',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-13T11:36:44.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'cccac92a-76cf-43b7-a956-bf3fdb7d1f0d',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
              primaryColor: '#d4d4de4d',
            },
            createdAt: '2024-02-13T11:36:44.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '217ec42f-384c-498c-9704-74c39fe462fc',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 2',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 6,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-13T11:36:17.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '2c94348a-0672-4406-8ff7-af0d5c167aaf',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-02-13T11:36:17.000Z',
            file: null,
            options: [],
          },
          {
            uid: '667fb31c-5944-4b97-91df-c075da2ede81',
            type: 'TITLE',
            value: 'Why was it not as you expected?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-13T11:36:17.000Z',
            file: null,
            options: [],
          },
          {
            uid: '6b0e8cbe-2c76-4a8a-bdf0-75d7f67b673c',
            type: 'SECONDARY_CTA',
            value: 'Back;step-prev;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderRadius: 24,
              primaryColor: '#4950731a',
            },
            createdAt: '2024-02-13T11:36:38.000Z',
            file: null,
            options: [],
          },
          {
            uid: '8f2f4f21-5f8c-4413-a226-d9b5950ea7ee',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-13T11:36:17.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'e19a3d45-5a40-44c0-99db-ef6dd3788b33',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
              primaryColor: '#d4d4de4d',
            },
            createdAt: '2024-02-13T11:36:17.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '31671963-a2d4-417f-ab13-0f14120c8cc9',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step nps 2',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-13T11:33:03.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '380c5b90-6b9c-410c-aa3b-6b7bdccc00fb',
            type: 'TITLE',
            value:
              'How was your overall experience with [Feature name]?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-13T11:33:03.000Z',
            file: null,
            options: [],
          },
          {
            uid: '65c2101b-1765-408b-ae10-35f7ad09e6e9',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-13T11:33:03.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'ce622107-defb-4116-8630-5966a272f8a3',
            type: 'NPS',
            value: 'Very bad;Very good',
            rawValue: null,
            flags: 4,
            style: {
              padding: 9,
              fontSize: 14,
            },
            createdAt: '2024-02-13T11:33:03.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '39ebdcd0-85d2-440f-9c44-fddeb758c976',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step nps 1',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-13T11:32:06.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '03b99af3-135f-4b4f-82cb-e1b0f4f595ac',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-13T11:32:06.000Z',
            file: null,
            options: [],
          },
          {
            uid: '2a0a47d4-36bb-4c2a-b732-5e95c16fa387',
            type: 'NPS',
            value: 'Very disappointed;Very satisfied',
            rawValue: null,
            flags: 4,
            style: {
              padding: 9,
              fontSize: 14,
            },
            createdAt: '2024-02-13T11:32:06.000Z',
            file: null,
            options: [],
          },
          {
            uid: '8ed8ac7f-8a6d-4e75-be68-9db26a933af7',
            type: 'TITLE',
            value: 'How satisfied are you with [Feature name]?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-13T11:32:06.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '7e3b7053-f59b-4ea1-9c22-1f881921036c',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step multiple choice 2',
        question: null,
        additionalInformation: null,
        type: 'MULTIPLE_CHOICE_MULTI_SELECT',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 5,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-13T11:35:26.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '4f258e93-38c8-47ae-9a39-b1eb0bc176c5',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-13T11:35:26.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'b541f6e3-f480-4ff9-9bfd-1e07301d4de6',
            type: 'CHOICE',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              padding: 12,
              fontSize: 14,
              fontFamily: 'Inter',
              disposition: 'block',
              borderRadius: 12,
              primaryColor: '#dad3d352',
              withCheckbox: false,
            },
            createdAt: '2024-02-13T11:35:26.000Z',
            file: null,
            options: [
              {
                uid: '2e5da6bb-576a-4a87-9439-64a46ed92d5c',
                content: 'Yes',
                indexOrder: 0,
              },
              {
                uid: '5236ffb6-8ca2-4fb3-b773-7a90269ee8d4',
                content: 'No',
                indexOrder: 1,
              },
            ],
          },
          {
            uid: 'b5d822c7-93d1-4ebb-8d6f-7a183b7af654',
            type: 'TITLE',
            value: 'Did the [Feature name] work as expected?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-13T11:35:26.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: {
          uid: '08852164-e27d-472c-adf3-2c08cb86e7c3',
          style: {
            width: null,
            height: null,
            margin: 0,
            shadow: {
              x: 0,
              y: 0,
              blur: 0,
              color: null,
              opacity: 0,
            },
            overlay: null,
            background: {
              type: 'color',
              animated: false,
              primaryColor: '#FFFFFF',
              secondaryColor: null,
            },
            animationIn: 'fade',
            animationOut: 'fade',
            borderRadius: 0,
            withElementsAnimations: true,
          },
          name: 'Step open question 3',
          question: null,
          additionalInformation: null,
          type: 'TEXT_LONG',
          mdContent: null,
          mdRawContent: null,
          indexOrder: 7,
          ctaLabel: null,
          ctaColor: null,
          ctaBackgroundColor: null,
          ctaBorderRadius: null,
          ctaUrl: null,
          ctaAction: null,
          ctaFontSize: null,
          interactiveLink: null,
          conceptThumbnailUrl: null,
          createdAt: '2024-02-13T11:36:44.000Z',
          mazeUrl: null,
          stepFlags: 4,
          endSurvey: false,
        },
        jumps: [
          {
            uid: 'c955f362-240e-4ffe-bf19-ce98a11a275e',
            operator: 'EQUAL_TO',
            conditionOperator: 'ALL',
            value: null,
            endSurvey: false,
            goTo: {
              uid: '217ec42f-384c-498c-9704-74c39fe462fc',
              style: {
                width: null,
                height: null,
                margin: 0,
                shadow: {
                  x: 0,
                  y: 0,
                  blur: 0,
                  color: null,
                  opacity: 0,
                },
                overlay: null,
                background: {
                  type: 'color',
                  animated: false,
                  primaryColor: '#FFFFFF',
                  secondaryColor: null,
                },
                animationIn: 'fade',
                animationOut: 'fade',
                borderRadius: 0,
                withElementsAnimations: true,
              },
              name: 'Step open question 2',
              question: null,
              additionalInformation: null,
              type: 'TEXT_LONG',
              mdContent: null,
              mdRawContent: null,
              indexOrder: 6,
              ctaLabel: null,
              ctaColor: null,
              ctaBackgroundColor: null,
              ctaBorderRadius: null,
              ctaUrl: null,
              ctaAction: null,
              ctaFontSize: null,
              interactiveLink: null,
              conceptThumbnailUrl: null,
              createdAt: '2024-02-13T11:36:17.000Z',
              mazeUrl: null,
              stepFlags: 4,
              endSurvey: false,
            },
            optionValues: [
              {
                uid: '5236ffb6-8ca2-4fb3-b773-7a90269ee8d4',
                content: 'No',
                indexOrder: 1,
              },
            ],
          },
        ],
        prototypes: [],
      },
      {
        uid: '83785308-c828-44da-9f1b-ac56e3bf5b22',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step success 1',
        question: null,
        additionalInformation: null,
        type: 'SUCCESS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 8,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-13T11:37:37.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '0356194b-54f7-4ec1-9fdf-669872ae1ee8',
            type: 'TITLE',
            value: 'Thank you for your input!|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-13T11:37:37.000Z',
            file: null,
            options: [],
          },
          {
            uid: '6296c0eb-49ff-4094-84fd-6dd1c11b57ca',
            type: 'ANIMATION',
            value: 'confetti',
            rawValue: null,
            flags: 0,
            style: null,
            createdAt: '2024-02-13T11:37:37.000Z',
            file: null,
            options: [],
          },
          {
            uid: '76e85647-40b9-49de-9b37-0661ef5a27f4',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-13T11:37:37.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'c63ff3a2-145d-4e78-ae08-c11bc05b630c',
            type: 'BODY',
            value:
              'You just completed our survey. Thank you a lot for helping us improve our product.',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 15,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-13T11:37:37.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'b5d128fd-cffd-4a69-a204-f6be6ddec707',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 4,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-13T11:34:56.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '0cdf022f-aebd-41ed-ba1d-cb49959b97e8',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-13T11:34:57.000Z',
            file: null,
            options: [],
          },
          {
            uid: '49c9c8b8-7c37-45d8-b7bb-982f390b8feb',
            type: 'SECONDARY_CTA',
            value: 'Back;step-prev;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderRadius: 24,
              primaryColor: '#4950731a',
            },
            createdAt: '2024-02-22T10:38:17.000Z',
            file: null,
            options: [],
          },
          {
            uid: '4c2752d7-8b99-46f2-9355-73df386c9d4b',
            type: 'TITLE',
            value: 'What was the issue that you faced?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-13T11:34:57.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'd11aabf2-73a5-47bc-930d-52761f9c413b',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-02-13T11:34:57.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'e5160aed-5f72-4806-b243-6918c5c0a00d',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
              primaryColor: '#d4d4de4d',
            },
            createdAt: '2024-02-13T11:34:57.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'eae561d1-da23-4faf-acda-5a2602a8629f',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step text 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_BLOCK',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-13T11:27:34.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '93d31e20-3197-42b7-9a81-e4588538b7bf',
            type: 'BODY',
            value:
              '<p>You have used one of our new features called [Feature name].\nWe&apos;d love to hear your thoughts around the feature.</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: "You have used one of our new features called [Feature name].\nWe'd love to hear your thoughts around the feature.",
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-13T11:27:34.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'c37e78e1-2eab-4cbf-8480-dbd55caf8768',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-13T11:30:55.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'cfae2edb-1918-4144-b0d9-018faeb85b01',
            type: 'TITLE',
            value:
              'How was your experience when using our [Feature name]?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-13T11:27:34.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'eb8f4936-7996-4153-9bdc-9e62c47008e3',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-02-13T11:27:34.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'f768fd43-6191-48f4-8bb5-e41f59b76a6b',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step multiple choice 1',
        question: null,
        additionalInformation: null,
        type: 'MULTIPLE_CHOICE_MULTI_SELECT',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 3,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-13T11:33:49.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '00378d4f-e87d-4618-8e7c-f8ec34b027ff',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-13T11:33:49.000Z',
            file: null,
            options: [],
          },
          {
            uid: '9c6d40c2-c23d-4b83-863d-6f5a4abd2d32',
            type: 'CHOICE',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              padding: 12,
              fontSize: 14,
              fontFamily: 'Inter',
              disposition: 'block',
              borderRadius: 12,
              primaryColor: '#dad3d352',
              withCheckbox: false,
            },
            createdAt: '2024-02-13T11:33:49.000Z',
            file: null,
            options: [
              {
                uid: '61eb99ed-f1aa-48fe-a544-39627efe18ed',
                content: 'No',
                indexOrder: 1,
              },
              {
                uid: '95a60eca-1dc5-444e-80fc-990f0628d176',
                content: 'Yes',
                indexOrder: 0,
              },
            ],
          },
          {
            uid: 'a7ba10dd-b623-4a16-bceb-f0af20fc04f1',
            type: 'TITLE',
            value:
              'Any problems accessing or setting up [Feature name?]|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-13T11:33:49.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [
          {
            uid: '16964e0f-a71c-45ad-8b24-04f19f09a4a2',
            operator: 'EQUAL_TO',
            conditionOperator: 'ALL',
            value: null,
            endSurvey: false,
            goTo: {
              uid: '7e3b7053-f59b-4ea1-9c22-1f881921036c',
              style: {
                width: null,
                height: null,
                margin: 0,
                shadow: {
                  x: 0,
                  y: 0,
                  blur: 0,
                  color: null,
                  opacity: 0,
                },
                overlay: null,
                background: {
                  type: 'color',
                  animated: false,
                  primaryColor: '#FFFFFF',
                  secondaryColor: null,
                },
                animationIn: 'fade',
                animationOut: 'fade',
                borderRadius: 0,
                withElementsAnimations: true,
              },
              name: 'Step multiple choice 2',
              question: null,
              additionalInformation: null,
              type: 'MULTIPLE_CHOICE_MULTI_SELECT',
              mdContent: null,
              mdRawContent: null,
              indexOrder: 5,
              ctaLabel: null,
              ctaColor: null,
              ctaBackgroundColor: null,
              ctaBorderRadius: null,
              ctaUrl: null,
              ctaAction: null,
              ctaFontSize: null,
              interactiveLink: null,
              conceptThumbnailUrl: null,
              createdAt: '2024-02-13T11:35:26.000Z',
              mazeUrl: null,
              stepFlags: 4,
              endSurvey: false,
            },
            optionValues: [
              {
                uid: '61eb99ed-f1aa-48fe-a544-39627efe18ed',
                content: 'No',
                indexOrder: 1,
              },
            ],
          },
          {
            uid: 'ce1503c1-cc8d-4ef9-ae73-fa191f867cac',
            operator: 'EQUAL_TO',
            conditionOperator: 'ALL',
            value: null,
            endSurvey: false,
            goTo: {
              uid: 'b5d128fd-cffd-4a69-a204-f6be6ddec707',
              style: {
                width: null,
                height: null,
                margin: 0,
                shadow: {
                  x: 0,
                  y: 0,
                  blur: 0,
                  color: null,
                  opacity: 0,
                },
                overlay: null,
                background: {
                  type: 'color',
                  animated: false,
                  primaryColor: '#FFFFFF',
                  secondaryColor: null,
                },
                animationIn: 'fade',
                animationOut: 'fade',
                borderRadius: 0,
                withElementsAnimations: true,
              },
              name: 'Step open question 1',
              question: null,
              additionalInformation: null,
              type: 'TEXT_LONG',
              mdContent: null,
              mdRawContent: null,
              indexOrder: 4,
              ctaLabel: null,
              ctaColor: null,
              ctaBackgroundColor: null,
              ctaBorderRadius: null,
              ctaUrl: null,
              ctaAction: null,
              ctaFontSize: null,
              interactiveLink: null,
              conceptThumbnailUrl: null,
              createdAt: '2024-02-13T11:34:56.000Z',
              mazeUrl: null,
              stepFlags: 4,
              endSurvey: false,
            },
            optionValues: [
              {
                uid: '95a60eca-1dc5-444e-80fc-990f0628d176',
                content: 'Yes',
                indexOrder: 0,
              },
            ],
          },
        ],
        prototypes: [],
      },
    ],
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '0',
        y: '0',
        blur: '0',
        color: '#316eda00',
      },
      overlay: null,
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: null,
      borderRadius: 16,
      withElementsAnimations: false,
    },
  },
  {
    experienceType: 'SURVEY',
    id: '508749fc-9189-4aeb-b07f-33263508fea4',
    image: Survey11,
    tags: ['Collect Feedback'],
    type: 'SNIPPET',
    title: 'Capture feature request',
    description:
      'This survey template is designed to capture user preferences regarding potential new features. It helps in understanding which enhancements are most and least desired by the user base, guiding product development priorities.',
    tourSteps: [],
    steps: [
      {
        uid: '1e41f381-eb53-4780-9601-af618b63ab0e',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step success 1',
        question: null,
        additionalInformation: null,
        type: 'SUCCESS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 3,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T11:32:32.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '71c7edf5-2061-4a1c-95f1-c22f31ce080c',
            type: 'BODY',
            value:
              'You just completed our survey. Thank you a lot for helping us improve our product.',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-12T11:32:32.000Z',
            file: null,
            options: [],
          },
          {
            uid: '854957f2-57ca-4f4d-9ad7-8058c6b445d7',
            type: 'ANIMATION',
            value: 'confetti',
            rawValue: null,
            flags: 0,
            style: null,
            createdAt: '2024-02-12T11:32:32.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'ac6f9fb9-311b-47ce-a174-a80218e53385',
            type: 'TITLE',
            value: 'Thank you for your input! 🎊|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T11:32:32.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '3bdf0879-b03b-47bf-ad68-42963770a959',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T11:31:53.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '59babd8c-cd1a-49ac-8cf9-a7db3a1832f9',
            type: 'PRIMARY_CTA',
            value: 'Finish;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#08915fff',
            },
            createdAt: '2024-02-12T11:31:53.000Z',
            file: null,
            options: [],
          },
          {
            uid: '6a4c29b9-5c14-4616-826f-a54ff2ff824a',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
              primaryColor: '#FFFFFF',
            },
            createdAt: '2024-02-12T11:31:53.000Z',
            file: null,
            options: [],
          },
          {
            uid: '7f2369fc-e000-4914-9915-372b481d5fba',
            type: 'SECONDARY_CTA',
            value: 'Back;step-prev;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: '#FFFFFF',
              borderRadius: 24,
              primaryColor: '#f1f1f1ff',
            },
            createdAt: '2024-02-12T11:32:26.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'd2d8cb9a-f858-42ad-9095-ce1f030ef3ab',
            type: 'TITLE',
            value:
              'How else could we improve your experience with [Product name]?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T11:31:53.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '42218589-3122-4f6e-9b64-57380d8297f5',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step multiple choice 2',
        question: null,
        additionalInformation: null,
        type: 'MULTIPLE_CHOICE_MULTI_SELECT',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T11:30:15.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '7dbe6b38-03de-46be-b20f-d31289e1d622',
            type: 'CHOICE',
            value: null,
            rawValue: null,
            flags: 2,
            style: {
              padding: 12,
              fontSize: 14,
              fontFamily: 'Inter',
              disposition: 'inline',
              borderRadius: 12,
              primaryColor: '#dad3d352',
              withCheckbox: false,
            },
            createdAt: '2024-02-12T11:30:15.000Z',
            file: null,
            options: [
              {
                uid: '1f2a0764-b40d-4d72-8d52-13d69a57e470',
                content: 'Feature 3',
                indexOrder: 2,
              },
              {
                uid: '4c78ca92-eb6c-4ef5-90bd-507cd305e3f8',
                content: 'Feature 4',
                indexOrder: 3,
              },
              {
                uid: '5234cc83-3a69-4764-9484-37bf3dbc646b',
                content: 'Feature 2',
                indexOrder: 1,
              },
              {
                uid: '8a4e90d2-f57c-487b-8cc4-752fa1740ac7',
                content: 'Feature 1',
                indexOrder: 0,
              },
            ],
          },
          {
            uid: '7e36a0fb-e240-4c1d-acc7-f12e17f3601d',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#08915fff',
            },
            createdAt: '2024-02-12T11:30:15.000Z',
            file: null,
            options: [],
          },
          {
            uid: '91f0958c-7518-48e4-9b29-cb6273b3f158',
            type: 'SECONDARY_CTA',
            value: 'Back;step-prev;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: '#FFFFFF',
              borderRadius: 24,
              primaryColor: '#FFFFFF',
            },
            createdAt: '2024-02-12T11:30:52.000Z',
            file: null,
            options: [],
          },
          {
            uid: '9676e616-9303-4191-910a-4cc2a362849e',
            type: 'TITLE',
            value:
              'Which of these feature would be least valuable to you?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T11:30:15.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '4b6254c6-c887-4458-9061-fbafb27335ab',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step multiple choice 1',
        question: null,
        additionalInformation: null,
        type: 'MULTIPLE_CHOICE_MULTI_SELECT',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T11:28:13.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '812bb629-0671-46f9-bec4-4484fb8613b4',
            type: 'CHOICE',
            value: null,
            rawValue: null,
            flags: 2,
            style: {
              padding: 12,
              fontSize: 14,
              fontFamily: 'Inter',
              disposition: 'inline',
              borderRadius: 12,
              primaryColor: '#dad3d352',
              withCheckbox: false,
            },
            createdAt: '2024-02-12T11:28:13.000Z',
            file: null,
            options: [
              {
                uid: '4d82717c-ad7f-497e-8dfd-09fcd11c71b3',
                content: 'Feature 4',
                indexOrder: 3,
              },
              {
                uid: 'cd667d19-d0d2-4a96-9268-e21dde685b35',
                content: 'Feature 3',
                indexOrder: 2,
              },
              {
                uid: 'd2adaf23-42f4-4050-b0dc-0397dd4d7e29',
                content: 'Feature 2',
                indexOrder: 1,
              },
              {
                uid: 'd7569b18-6982-4128-8bb7-5b5adc3d594e',
                content: 'Feature 1',
                indexOrder: 0,
              },
            ],
          },
          {
            uid: 'aa604c7d-dab3-479b-835a-44a3d8ac05e8',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#08915fff',
            },
            createdAt: '2024-02-12T11:28:13.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'fd680a85-ddf7-4e5b-91e8-be8bd6c75c18',
            type: 'TITLE',
            value:
              'Which of these feature would be most valuable to you?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T11:28:13.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
    ],
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '0',
        y: '0',
        blur: '0',
        color: '#5479bf00',
      },
      overlay: null,
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#f0ffe9ff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 24,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'SURVEY',
    id: '64afd0eb-45a1-4ba5-bb35-c2f95ba843b5',
    image: Survey10,
    tags: ['Product Evaluation'],
    type: 'SNIPPET',
    title: 'Evaluate a Product Idea',
    description:
      'This succinct survey template is crafted for rapid evaluation of a product idea or feature concept. It’s structured to gather user feedback on existing challenges and assess the perceived value of a proposed solution, all within a compact two-minute timeframe.',
    tourSteps: [],
    steps: [
      {
        uid: '2c7209ea-a64c-4ef6-a6c9-9895c3bcfa1a',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T08:46:20.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '57598f3f-3b9f-47ee-9d01-d870b71404af',
            type: 'TITLE',
            value:
              "What's the most difficult for you when it comes to building product tours?|-|none|-|",
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T08:46:20.000Z',
            file: null,
            options: [],
          },
          {
            uid: '66b6b8ad-6f8b-41ca-85c2-4df19a00e430',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T08:46:20.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'ad292161-0cfe-4e78-ab90-58cea5c6f06a',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
              primaryColor: '#FFFFFF',
            },
            createdAt: '2024-02-19T08:46:20.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'f78c3859-af4b-493e-a67b-43328ec38c8b',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#d1370dff',
            },
            createdAt: '2024-02-19T08:46:20.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '4bbe73e7-efdd-49de-bc5c-b928915e9872',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'If answer > 3',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 6,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T08:51:28.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '68dcc63e-eea9-4f4e-bb26-f6afa89163a8',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#d1370dff',
            },
            createdAt: '2024-02-19T08:51:28.000Z',
            file: null,
            options: [],
          },
          {
            uid: '6cdf2491-db03-4ccd-97ea-ef78a863a1a0',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
              primaryColor: '#FFFFFF',
            },
            createdAt: '2024-02-19T08:51:28.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'b285f1e8-e133-4bb3-957b-5329c0bc9b3a',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T08:51:28.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'cf6c0922-9324-453f-86c6-77aa3544732a',
            type: 'TITLE',
            value:
              'Got it. What would be the most valuable to you in a feature like this?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T08:51:28.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '535cb2c0-4d3d-4d78-9cb2-2058e4005765',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step text 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_BLOCK',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T08:43:10.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '1fdc1d29-377f-43df-bc46-f6f22c165167',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T08:43:58.000Z',
            file: null,
            options: [],
          },
          {
            uid: '544c8a7e-b682-4f5f-b710-81afa9db3d3f',
            type: 'BODY',
            value: '<p>This will just take 2 minutes of your time.</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'This will just take 2 minutes of your time.',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-19T08:43:10.000Z',
            file: null,
            options: [],
          },
          {
            uid: '924cbfda-855f-4c9e-a4c8-479de7b24afa',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#d1370dff',
            },
            createdAt: '2024-02-19T08:43:10.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'd9c51598-10e5-48c8-82ef-fef8c5eed316',
            type: 'TITLE',
            value:
              'Hey! Got a minute to tell us what you think about a potential new feature?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 17,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T08:43:10.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '7f0f90d2-280e-4ae7-9ba9-d7b7eca6cc4c',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step nps 2',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 4,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T08:48:23.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '0c05ebec-8e4c-44e7-93ee-914fd8994c5e',
            type: 'TITLE',
            value:
              'How valuable to you would a feature like this be to you?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T08:48:23.000Z',
            file: null,
            options: [],
          },
          {
            uid: '30909db5-d920-4a50-8955-fc87512a1bbf',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T08:48:23.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'c5c9344b-1181-4d97-be51-d74dd0e686c9',
            type: 'NPS',
            value: 'Very worthless;Very valuable',
            rawValue: null,
            flags: 4,
            style: {
              padding: 8,
              fontSize: 14,
            },
            createdAt: '2024-02-19T08:48:23.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'd1e5493d-ba42-4989-a710-4d8dc107ccc2',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#d1370dff',
            },
            createdAt: '2024-02-19T08:48:23.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: {
          uid: 'e48d1698-6353-4d12-9b49-e6b094aeff76',
          style: {
            width: null,
            height: null,
            margin: 0,
            shadow: {
              x: 0,
              y: 0,
              blur: 0,
              color: null,
              opacity: 0,
            },
            overlay: null,
            background: {
              type: 'color',
              animated: false,
              primaryColor: '#FFFFFF',
              secondaryColor: null,
            },
            animationIn: 'fade',
            animationOut: 'fade',
            borderRadius: 0,
            withElementsAnimations: true,
          },
          name: 'Step success 1',
          question: null,
          additionalInformation: null,
          type: 'SUCCESS',
          mdContent: null,
          mdRawContent: null,
          indexOrder: 7,
          ctaLabel: null,
          ctaColor: null,
          ctaBackgroundColor: null,
          ctaBorderRadius: null,
          ctaUrl: null,
          ctaAction: null,
          ctaFontSize: null,
          interactiveLink: null,
          conceptThumbnailUrl: null,
          createdAt: '2024-02-19T08:52:11.000Z',
          mazeUrl: null,
          stepFlags: 0,
          endSurvey: false,
        },
        jumps: [
          {
            uid: '23c16ca7-f247-41fc-b545-ed07f6971b71',
            operator: 'GREATER_THAN',
            conditionOperator: 'ALL',
            value: '3',
            endSurvey: false,
            goTo: {
              uid: '4bbe73e7-efdd-49de-bc5c-b928915e9872',
              style: {
                width: null,
                height: null,
                margin: 0,
                shadow: {
                  x: 0,
                  y: 0,
                  blur: 0,
                  color: null,
                  opacity: 0,
                },
                overlay: null,
                background: {
                  type: 'color',
                  animated: false,
                  primaryColor: '#FFFFFF',
                  secondaryColor: null,
                },
                animationIn: 'fade',
                animationOut: 'fade',
                borderRadius: 0,
                withElementsAnimations: true,
              },
              name: 'If answer > 3',
              question: null,
              additionalInformation: null,
              type: 'TEXT_LONG',
              mdContent: null,
              mdRawContent: null,
              indexOrder: 6,
              ctaLabel: null,
              ctaColor: null,
              ctaBackgroundColor: null,
              ctaBorderRadius: null,
              ctaUrl: null,
              ctaAction: null,
              ctaFontSize: null,
              interactiveLink: null,
              conceptThumbnailUrl: null,
              createdAt: '2024-02-19T08:51:28.000Z',
              mazeUrl: null,
              stepFlags: 4,
              endSurvey: false,
            },
            optionValues: [],
          },
          {
            uid: '384eb00c-1e71-4e6b-b447-be97de9a4791',
            operator: 'LESSER_THAN',
            conditionOperator: 'ALL',
            value: '3',
            endSurvey: false,
            goTo: {
              uid: 'e8b4b091-6037-40d8-adb8-e53d14e342a4',
              style: {
                width: null,
                height: null,
                margin: 0,
                shadow: {
                  x: 0,
                  y: 0,
                  blur: 0,
                  color: null,
                  opacity: 0,
                },
                overlay: null,
                background: {
                  type: 'color',
                  animated: false,
                  primaryColor: '#FFFFFF',
                  secondaryColor: null,
                },
                animationIn: 'fade',
                animationOut: 'fade',
                borderRadius: 0,
                withElementsAnimations: true,
              },
              name: 'If answer < 3',
              question: null,
              additionalInformation: null,
              type: 'TEXT_LONG',
              mdContent: null,
              mdRawContent: null,
              indexOrder: 5,
              ctaLabel: null,
              ctaColor: null,
              ctaBackgroundColor: null,
              ctaBorderRadius: null,
              ctaUrl: null,
              ctaAction: null,
              ctaFontSize: null,
              interactiveLink: null,
              conceptThumbnailUrl: null,
              createdAt: '2024-02-19T08:51:03.000Z',
              mazeUrl: null,
              stepFlags: 4,
              endSurvey: false,
            },
            optionValues: [],
          },
        ],
        prototypes: [],
      },
      {
        uid: 'd62062fe-ff88-4a30-b31b-ba0b2bc327c0',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step nps 1',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T08:45:13.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '0ea23ab5-31d9-45be-ac28-29dfae8dd897',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T08:45:13.000Z',
            file: null,
            options: [],
          },
          {
            uid: '424badfd-eaa3-424b-9f0e-48e127b001ef',
            type: 'TITLE',
            value:
              'Great! First, how easy or difficult is it for you to create a Tour?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T08:45:13.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'f9b3faf4-a5a0-41fe-8851-b3345cbc661e',
            type: 'NPS',
            value: 'Very difficult;Very easy',
            rawValue: null,
            flags: 4,
            style: {
              padding: 8,
              fontSize: 14,
            },
            createdAt: '2024-02-19T08:45:13.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'e48d1698-6353-4d12-9b49-e6b094aeff76',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step success 1',
        question: null,
        additionalInformation: null,
        type: 'SUCCESS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 7,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T08:52:11.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '04d47565-65e5-4021-905d-37204a4ca98d',
            type: 'TITLE',
            value: 'Thank you for your input!|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 20,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T08:52:11.000Z',
            file: null,
            options: [],
          },
          {
            uid: '578c5c0d-677f-4799-93bb-2b440cd21c25',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T08:52:11.000Z',
            file: null,
            options: [],
          },
          {
            uid: '7b43cb7e-131f-4d22-bfc2-dca98d8e1446',
            type: 'BODY',
            value:
              'You just completed our survey. Thank you a lot for helping us improve our product.',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-19T08:52:11.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'e5784e98-6318-42b0-ab5f-3f777fdf26c3',
            type: 'ANIMATION',
            value: 'confetti',
            rawValue: null,
            flags: 0,
            style: null,
            createdAt: '2024-02-19T08:52:11.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'e8b4b091-6037-40d8-adb8-e53d14e342a4',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'If answer < 3',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 5,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T08:51:03.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '0424e179-f23a-4e42-8368-aee6795f08e4',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#d1370dff',
            },
            createdAt: '2024-02-19T08:51:03.000Z',
            file: null,
            options: [],
          },
          {
            uid: '42fbc170-5bff-4452-973f-58a9e9aa49dc',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T08:51:03.000Z',
            file: null,
            options: [],
          },
          {
            uid: '7702e8c5-d23d-400f-98d0-d5563787e70d',
            type: 'TITLE',
            value:
              "Got it. Why wouldn't this feature be valuable to you?|-|none|-|",
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T08:51:03.000Z',
            file: null,
            options: [],
          },
          {
            uid: '8e84c8a7-0475-46c4-a233-aa8c77e5b2cd',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
              primaryColor: '#FFFFFF',
            },
            createdAt: '2024-02-19T08:51:03.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: {
          uid: 'e48d1698-6353-4d12-9b49-e6b094aeff76',
          style: {
            width: null,
            height: null,
            margin: 0,
            shadow: {
              x: 0,
              y: 0,
              blur: 0,
              color: null,
              opacity: 0,
            },
            overlay: null,
            background: {
              type: 'color',
              animated: false,
              primaryColor: '#FFFFFF',
              secondaryColor: null,
            },
            animationIn: 'fade',
            animationOut: 'fade',
            borderRadius: 0,
            withElementsAnimations: true,
          },
          name: 'Step success 1',
          question: null,
          additionalInformation: null,
          type: 'SUCCESS',
          mdContent: null,
          mdRawContent: null,
          indexOrder: 7,
          ctaLabel: null,
          ctaColor: null,
          ctaBackgroundColor: null,
          ctaBorderRadius: null,
          ctaUrl: null,
          ctaAction: null,
          ctaFontSize: null,
          interactiveLink: null,
          conceptThumbnailUrl: null,
          createdAt: '2024-02-19T08:52:11.000Z',
          mazeUrl: null,
          stepFlags: 0,
          endSurvey: false,
        },
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'fb780bda-ba4f-4ef2-8484-4e15192dc164',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step text 2',
        question: null,
        additionalInformation: null,
        type: 'TEXT_BLOCK',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 3,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T08:47:18.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '0ea36451-832e-4780-aa01-f9f053b42edc',
            type: 'TITLE',
            value:
              "We're working on an idea to make it easier for you building Product Tours! |-|none|-|",
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T08:47:18.000Z',
            file: null,
            options: [],
          },
          {
            uid: '2f152584-cbf1-42bb-8821-9324ca299046',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T08:47:18.000Z',
            file: null,
            options: [],
          },
          {
            uid: '89a6a364-daae-4490-934a-7a301b74290f',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#d1370dff',
            },
            createdAt: '2024-02-19T08:47:18.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'cd9cbc5e-5f0e-4462-9fd6-a63284e38b4a',
            type: 'BODY',
            value:
              '<p>Insert concept here. Provide enough detail to make it clear what the feature is, but keep it brief and easy to understand</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Insert concept here. Provide enough detail to make it clear what the feature is, but keep it brief and easy to understand',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-19T08:47:18.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
    ],
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '0',
        y: '0',
        blur: '0',
        color: '#316eda00',
      },
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#db5858ff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'SURVEY',
    id: '852cdda2-d9fa-4cb5-ba8b-5f717ece3c7d',
    image: Survey6,
    tags: ['Product Evaluation'],
    type: 'SNIPPET',
    title: 'Evaluate product with System Usability Scale (SUS)',
    description:
      'This comprehensive survey template employs the System Usability Scale (SUS), a proven tool for evaluating the usability of a product. It captures nuanced feedback on various aspects of the user experience, from ease of use to the need for technical support.',
    tourSteps: [],
    steps: [
      {
        uid: '09b26348-bb27-4db6-a69d-ea693badd88d',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step text 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_BLOCK',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T08:10:25.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '0e7ad31e-8561-429e-af12-9a1c95e71db1',
            type: 'SECONDARY_CTA',
            value: 'No thanks;close;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderRadius: 24,
              primaryColor: '#4950731a',
            },
            createdAt: '2024-02-12T08:25:50.000Z',
            file: null,
            options: [],
          },
          {
            uid: '3eb45b79-4aae-4bf1-abdb-2df821fb2266',
            type: 'TITLE',
            value: 'Share Your Experience to us 🙌|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 15,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T08:10:26.000Z',
            file: null,
            options: [],
          },
          {
            uid: '9e6942a1-e50f-4695-a6a7-795cbb952c45',
            type: 'BODY',
            value:
              '<p>Your opinion counts! Participate in our survey and help us improve our service.</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Your opinion counts! Participate in our survey and help us improve our service.',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-12T08:10:26.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'c2beb7c2-c95f-44da-981e-8c5ae32c6475',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-02-12T08:10:26.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'ddeefc4b-39de-4f15-816a-8baafb7caa4c',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;null',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-22T10:43:24.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '1b8f2d16-3fcb-4bcf-a7d0-adae88ead0ab',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step nps 8',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 8,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T08:08:08.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '24e8bedf-c2c2-451c-ae87-409bd6c3286f',
            type: 'TITLE',
            value: 'I found [Product name] very cumbersome to use|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T08:08:08.000Z',
            file: null,
            options: [],
          },
          {
            uid: '5b011988-0d20-4d62-8550-6422d21efd8e',
            type: 'NPS',
            value: 'Strongly disagree;Strongly agree',
            rawValue: null,
            flags: 4,
            style: {
              padding: 9,
              fontSize: 14,
            },
            createdAt: '2024-02-12T08:08:08.000Z',
            file: null,
            options: [],
          },
          {
            uid: '8002861c-6860-40de-a5f4-1d0e3cbe94a2',
            type: 'STEPPER',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              type: 'dot-line',
              align: 'left',
              position: 'top',
              primaryColor: '#0038ffff',
              secondaryColor: '#0038ff33',
            },
            createdAt: '2024-02-12T08:21:47.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '1cdae4c1-1e25-4e63-b50f-3a25842fe4b1',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step nps 10',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 10,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T08:08:09.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '2e88e07b-d4c8-4004-808d-1bbcd3c03533',
            type: 'TITLE',
            value:
              'I needed to learn a lot of things before I could get going with [Product name]|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T08:08:09.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'bf86c453-4427-43cb-a968-831bb5204d3b',
            type: 'STEPPER',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              type: 'dot-line',
              align: 'left',
              position: 'top',
              primaryColor: '#0038ffff',
              secondaryColor: '#0038ff33',
            },
            createdAt: '2024-02-12T08:21:53.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'ea14f9ea-b88c-418e-b588-4cc0f82dd73b',
            type: 'NPS',
            value: 'Strongly disagree;Strongly agree',
            rawValue: null,
            flags: 4,
            style: {
              padding: 9,
              fontSize: 14,
            },
            createdAt: '2024-02-12T08:08:09.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '30da32c1-594e-41aa-8d90-fe62edb8515f',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step nps 5',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 5,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T08:07:58.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '1fe37d2c-99c7-4b3d-acae-1e97c503832c',
            type: 'TITLE',
            value:
              'I found the various functions in [Product Name] were well integrated|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T08:07:58.000Z',
            file: null,
            options: [],
          },
          {
            uid: '6f08b890-85f6-4215-a914-a9957a4095a4',
            type: 'STEPPER',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              type: 'dot-line',
              align: 'left',
              position: 'top',
              primaryColor: '#0038ffff',
              secondaryColor: '#0038ff33',
            },
            createdAt: '2024-02-12T08:21:39.000Z',
            file: null,
            options: [],
          },
          {
            uid: '8ed8608f-6a5e-4c76-85c2-61e0b2a68bc2',
            type: 'NPS',
            value: 'Strongly disagree;Strongly agree',
            rawValue: null,
            flags: 4,
            style: {
              padding: 9,
              fontSize: 14,
            },
            createdAt: '2024-02-12T08:07:58.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '3db1ce2b-0806-457b-9f02-0bd037eae286',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step nps 6',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 6,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T08:07:58.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '37a247bf-00db-4fcc-94d7-e8cdaf037bc8',
            type: 'NPS',
            value: 'Strongly disagree;Strongly agree',
            rawValue: null,
            flags: 4,
            style: {
              padding: 9,
              fontSize: 14,
            },
            createdAt: '2024-02-12T08:07:59.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'f0974c08-402d-42f4-b229-7e984b8b7b32',
            type: 'STEPPER',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              type: 'dot-line',
              align: 'left',
              position: 'top',
              primaryColor: '#0038ffff',
              secondaryColor: '#0038ff33',
            },
            createdAt: '2024-02-12T08:21:45.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'f3c580a1-f87c-4033-930e-0f3790288604',
            type: 'TITLE',
            value:
              'I thought there was too much inconsistency in [Product name]|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T08:07:59.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '5234eca3-3627-49c7-a31d-4bb00a955f41',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step nps 3',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 3,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T08:07:53.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '29007d1a-0d7f-4360-9435-0cac9c05c432',
            type: 'NPS',
            value: 'Strongly disagree;Strongly agree',
            rawValue: null,
            flags: 4,
            style: {
              padding: 9,
              fontSize: 14,
            },
            createdAt: '2024-02-12T08:07:53.000Z',
            file: null,
            options: [],
          },
          {
            uid: '87f0e061-33e9-4efb-9f25-fc070f86b7d9',
            type: 'TITLE',
            value: 'I thought [Product Name] was easy to use\n|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T08:07:53.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '6e81e695-ec69-4ca5-895f-10f4295511f1',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step nps 2',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T08:05:19.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '0cffa75c-53f2-4dab-b1d2-05df889943ac',
            type: 'STEPPER',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              type: 'dot-line',
              align: 'left',
              position: 'top',
              primaryColor: '#0038ffff',
              secondaryColor: '#0038ff33',
            },
            createdAt: '2024-02-12T08:21:33.000Z',
            file: null,
            options: [],
          },
          {
            uid: '6923f139-edae-476e-88b5-8b94519954bf',
            type: 'TITLE',
            value: 'I found [Product Name] unnecessarily complex|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T08:05:19.000Z',
            file: null,
            options: [],
          },
          {
            uid: '8e003b05-74a7-4828-ae61-fbf21dd00ca3',
            type: 'NPS',
            value: 'Strongly disagree;Strongly disagree',
            rawValue: null,
            flags: 4,
            style: {
              padding: 9,
              fontSize: 14,
            },
            createdAt: '2024-02-12T08:05:19.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '81bf5f3c-c935-44a9-8fd4-70a63ffb9aa0',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step nps 7',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 7,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T08:08:02.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '12f44ee1-2ac4-4885-9d54-da671ce0cd1a',
            type: 'TITLE',
            value:
              'I would imagine that most people would learn to use [Product name] very quickly|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T08:08:02.000Z',
            file: null,
            options: [],
          },
          {
            uid: '1dfc550c-29e2-4bfc-8fdf-28890581c3ad',
            type: 'STEPPER',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              type: 'dot-line',
              align: 'left',
              position: 'top',
              primaryColor: '#0038ffff',
              secondaryColor: '#0038ff33',
            },
            createdAt: '2024-02-12T08:21:45.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'c5972dc5-f4f2-4112-9760-9cb8aaeeb43c',
            type: 'NPS',
            value: 'Strongly disagree;Strongly agree',
            rawValue: null,
            flags: 4,
            style: {
              padding: 9,
              fontSize: 14,
            },
            createdAt: '2024-02-12T08:08:02.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '85cd5b07-8038-4168-b38a-8cb0c528c11f',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step nps 9',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 9,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T08:08:08.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '0ec78ade-5772-405b-9863-2b3bf619654c',
            type: 'STEPPER',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              type: 'dot-line',
              align: 'left',
              position: 'top',
              primaryColor: '#0038ffff',
              secondaryColor: '#0038ff33',
            },
            createdAt: '2024-02-12T08:21:50.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'afdf3061-06ca-4d04-af6d-02a28fddc1d2',
            type: 'TITLE',
            value: 'I felt very confident using [Product name]|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T08:08:08.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'b8686a8f-d9f2-43c9-8442-5e2e15f60b46',
            type: 'NPS',
            value: 'Strongly disagree;Strongly agree',
            rawValue: null,
            flags: 4,
            style: {
              padding: 9,
              fontSize: 14,
            },
            createdAt: '2024-02-12T08:08:08.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'e34a8df3-47af-4b46-b923-1ceb8157b8b5',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step nps 4',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 4,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T08:07:58.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '40fc0253-f093-43d5-97d6-c254f83dec17',
            type: 'NPS',
            value: 'Strongly disagree;Strongly agree',
            rawValue: null,
            flags: 4,
            style: {
              padding: 9,
              fontSize: 14,
            },
            createdAt: '2024-02-12T08:07:58.000Z',
            file: null,
            options: [],
          },
          {
            uid: '8f8871dd-32e4-4f56-8e37-0e19b4dafd48',
            type: 'TITLE',
            value:
              'I think that I would need the support of a technical person to be able to use [Product Name]|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T08:07:58.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'cba5ebe7-e3d6-4918-ab45-8dff3bc85088',
            type: 'STEPPER',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              type: 'dot-line',
              align: 'left',
              position: 'top',
              primaryColor: '#0038ffff',
              secondaryColor: '#0038ff33',
            },
            createdAt: '2024-02-12T08:21:39.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'f1df9637-8f1d-429a-8cb0-96a5a7dea273',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step success 1',
        question: null,
        additionalInformation: null,
        type: 'SUCCESS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 11,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T08:22:26.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '41a77299-2f54-48b1-8ab4-3203427f4820',
            type: 'BODY',
            value:
              'You just completed our survey. Thank you a lot for helping us improve our product.',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-12T08:22:26.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'cb5efe56-d40d-45a8-801d-c275c97ac963',
            type: 'ANIMATION',
            value: 'confetti',
            rawValue: null,
            flags: 0,
            style: null,
            createdAt: '2024-02-12T08:22:26.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'd7bacecc-a154-42ad-93f1-cafdc474f5dc',
            type: 'TITLE',
            value: 'Thank you for your input!|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T08:22:26.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'fe2b3bdf-befa-44c4-99fb-9534e4a6e769',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step nps 1',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T08:03:53.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '2d26867a-2777-440a-91fd-b1ced488482b',
            type: 'NPS',
            value: 'Strongly disagree;Strongly agree',
            rawValue: null,
            flags: 4,
            style: {
              padding: 9,
              fontSize: 14,
            },
            createdAt: '2024-02-12T08:03:54.000Z',
            file: null,
            options: [],
          },
          {
            uid: '6549fe57-5e9b-410d-8e40-5c989e78fe8d',
            type: 'TITLE',
            value:
              'I think that I would like to use [Product Name] frequently|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T08:03:54.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'e7a637de-b628-486e-a2f3-73fd4fb5d66c',
            type: 'STEPPER',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              type: 'dot-line',
              align: 'left',
              position: 'top',
              primaryColor: '#0038ffff',
              secondaryColor: '#0038ff33',
            },
            createdAt: '2024-02-12T08:17:58.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
    ],
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '0',
        y: '0',
        blur: '0',
        color: '#316eda00',
      },
      overlay: null,
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: null,
      animationOut: null,
      borderRadius: 16,
      withElementsAnimations: false,
    },
  },
  {
    experienceType: 'SURVEY',
    id: 'acedb197-bfd8-4f1e-a405-a5f710d272e1',
    image: Survey14,
    tags: ['Collect Feedback'],
    type: 'SNIPPET',
    title: 'Schedule a user interview with the right persona',
    description:
      "This survey template is designed to streamline the process of scheduling user interviews with the most appropriate candidates. It's a quick, efficient way to ensure that you’re engaging with participants who align with the right user persona for your research study.",
    tourSteps: [],
    steps: [
      {
        uid: '6f7562bd-4902-4390-a48c-f33a41809f8f',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step interview 1',
        question: null,
        additionalInformation: null,
        type: 'INTERVIEW',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 3,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T08:58:09.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '037b1f23-fb3d-4bee-888f-7e14d24e7672',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T08:58:09.000Z',
            file: null,
            options: [],
          },
          {
            uid: '1923480f-6c77-4e86-b009-39392fa12a21',
            type: 'TITLE',
            value:
              "That's it! you're qualified for participating the research|-|none|-|",
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T08:58:09.000Z',
            file: null,
            options: [],
          },
          {
            uid: '23f87a8f-d9ea-430e-9949-031cf6da6c9a',
            type: 'PRIMARY_CTA',
            value:
              'Choose schedule;step-next;open-booking-url;https://yourcalendar.com',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#a500ffff',
            },
            createdAt: '2024-02-19T08:58:09.000Z',
            file: null,
            options: [],
          },
          {
            uid: '526c5f35-d7ef-497a-aff2-90d8cb646fc5',
            type: 'BODY',
            value: '<p>Choose any schedule that suits you the best.</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Choose any schedule that suits you the best.',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#acacacff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-19T08:58:34.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: {
          uid: 'cafd0ebf-b07d-45fb-af25-233de4ce3e64',
          style: {
            width: null,
            height: null,
            margin: 0,
            shadow: {
              x: 0,
              y: 0,
              blur: 0,
              color: null,
              opacity: 0,
            },
            overlay: null,
            background: {
              type: 'color',
              animated: false,
              primaryColor: '#FFFFFF',
              secondaryColor: null,
            },
            animationIn: 'fade',
            animationOut: 'fade',
            borderRadius: 0,
            withElementsAnimations: true,
          },
          name: 'Step success 1',
          question: null,
          additionalInformation: null,
          type: 'SUCCESS',
          mdContent: null,
          mdRawContent: null,
          indexOrder: 5,
          ctaLabel: null,
          ctaColor: null,
          ctaBackgroundColor: null,
          ctaBorderRadius: null,
          ctaUrl: null,
          ctaAction: null,
          ctaFontSize: null,
          interactiveLink: null,
          conceptThumbnailUrl: null,
          createdAt: '2024-02-19T09:01:02.000Z',
          mazeUrl: null,
          stepFlags: 0,
          endSurvey: false,
        },
        jumps: [],
        prototypes: [],
      },
      {
        uid: '817a736e-47a5-439c-94e4-a3c390eaa1dd',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step text 2',
        question: null,
        additionalInformation: null,
        type: 'TEXT_BLOCK',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T08:57:15.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '3b3e40d3-1a0a-4691-b544-ff217ab6bee1',
            type: 'BODY',
            value: '<p>This will just take a minute</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'This will just take a minute',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#acacacff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-19T08:57:15.000Z',
            file: null,
            options: [],
          },
          {
            uid: '87da3033-e9e9-4a2d-acc8-cc73968eb8d0',
            type: 'TITLE',
            value:
              'Great! we have few quick questions to see if you qualify|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T08:57:15.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'a226bf7c-2411-44e1-b9f6-ac6f765695ac',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#a500ffff',
            },
            createdAt: '2024-02-19T08:57:15.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'c042bfc5-b2aa-455d-8d54-2a369a3ccfea',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T08:57:15.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'bd4bf336-a5ea-403d-90c5-e6fb7af2776c',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step multiple choice 2',
        question: null,
        additionalInformation: null,
        type: 'MULTIPLE_CHOICE_MULTI_SELECT',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 4,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T08:58:48.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '9cce2a82-eeec-492c-99ed-ad858a15f736',
            type: 'PRIMARY_CTA',
            value: 'Finish;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#a500ffff',
            },
            createdAt: '2024-02-19T08:58:48.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'c938edde-b471-45ed-82b2-b7e3be71caf4',
            type: 'CHOICE',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              padding: 8,
              fontSize: 14,
              fontFamily: 'Inter',
              disposition: 'block',
              borderRadius: 8,
              primaryColor: '#000000',
            },
            createdAt: '2024-02-19T08:58:48.000Z',
            file: null,
            options: [
              {
                uid: '1e51d05a-da77-4be8-950d-7cbdc8c9cbcf',
                content: 'No',
                indexOrder: 1,
              },
              {
                uid: '2b55666d-b1f8-4386-b50b-18f002173e63',
                content: 'Yes',
                indexOrder: 0,
              },
            ],
          },
          {
            uid: 'cc13d535-b3a2-46af-bbb9-4925d091332b',
            type: 'TITLE',
            value:
              "Looks like it's not a fit this time. Would you like us to keep you in mind for future studies?\n|-|none|-|",
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T08:58:48.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'ed269dac-66c3-4473-95ad-efebdb352edb',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T08:58:48.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'cafd0ebf-b07d-45fb-af25-233de4ce3e64',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step success 1',
        question: null,
        additionalInformation: null,
        type: 'SUCCESS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 5,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T09:01:02.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '139bb80f-6575-427e-ac20-29e12fc76521',
            type: 'BODY',
            value:
              'You just completed our survey. Thank you a lot for helping us improve our product.',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#acacacff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-19T09:01:02.000Z',
            file: null,
            options: [],
          },
          {
            uid: '38191dd2-f27e-43e1-99e6-b87ce38cd18b',
            type: 'TITLE',
            value: 'Thank you for your input!|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 18,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T09:01:02.000Z',
            file: null,
            options: [],
          },
          {
            uid: '5d3809fa-3618-42e0-a570-c7fc4a504d0a',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T09:01:02.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'd653ed7f-ae4b-449d-aa94-0e1837825584',
            type: 'ANIMATION',
            value: 'confetti',
            rawValue: null,
            flags: 0,
            style: null,
            createdAt: '2024-02-19T09:01:02.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'ecfd8036-ae0d-48fa-bda0-8d7b88a4fa15',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step multiple choice 1',
        question: null,
        additionalInformation: null,
        type: 'MULTIPLE_CHOICE_MULTI_SELECT',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T08:57:50.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '1029f5d4-74fd-4a45-89f1-bfaabb204278',
            type: 'TITLE',
            value: 'Screener question #1|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T08:57:50.000Z',
            file: null,
            options: [],
          },
          {
            uid: '5405f69b-d7cd-4c5b-b058-6e0d5ee86da3',
            type: 'CHOICE',
            value: null,
            rawValue: null,
            flags: 2,
            style: {
              padding: 8,
              fontSize: 14,
              fontFamily: 'Inter',
              disposition: 'inline',
              borderRadius: 8,
              primaryColor: '#000000',
            },
            createdAt: '2024-02-19T08:57:50.000Z',
            file: null,
            options: [
              {
                uid: '02b4c055-0c56-472a-8beb-74e9ee80eae7',
                content: 'Option 1',
                indexOrder: 0,
              },
              {
                uid: 'edd323be-90e7-43b4-81bc-1c38548cf265',
                content: 'Option 2',
                indexOrder: 1,
              },
            ],
          },
          {
            uid: '955eabb6-a9a7-4480-a6ae-3598887afc26',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T08:57:50.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'c35a132d-7f3d-43b2-8773-b681faed6958',
            type: 'PRIMARY_CTA',
            value: 'continue;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#a500ffff',
            },
            createdAt: '2024-02-19T08:57:50.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [
          {
            uid: 'b7f20c0c-91b3-4feb-b57a-13d48a730e50',
            operator: 'EQUAL_TO',
            conditionOperator: 'ALL',
            value: null,
            endSurvey: false,
            goTo: {
              uid: 'bd4bf336-a5ea-403d-90c5-e6fb7af2776c',
              style: {
                width: null,
                height: null,
                margin: 0,
                shadow: {
                  x: 0,
                  y: 0,
                  blur: 0,
                  color: null,
                  opacity: 0,
                },
                overlay: null,
                background: {
                  type: 'color',
                  animated: false,
                  primaryColor: '#FFFFFF',
                  secondaryColor: null,
                },
                animationIn: 'fade',
                animationOut: 'fade',
                borderRadius: 0,
                withElementsAnimations: true,
              },
              name: 'Step multiple choice 2',
              question: null,
              additionalInformation: null,
              type: 'MULTIPLE_CHOICE_MULTI_SELECT',
              mdContent: null,
              mdRawContent: null,
              indexOrder: 4,
              ctaLabel: null,
              ctaColor: null,
              ctaBackgroundColor: null,
              ctaBorderRadius: null,
              ctaUrl: null,
              ctaAction: null,
              ctaFontSize: null,
              interactiveLink: null,
              conceptThumbnailUrl: null,
              createdAt: '2024-02-19T08:58:48.000Z',
              mazeUrl: null,
              stepFlags: 0,
              endSurvey: false,
            },
            optionValues: [
              {
                uid: 'edd323be-90e7-43b4-81bc-1c38548cf265',
                content: 'Option 2',
                indexOrder: 1,
              },
            ],
          },
          {
            uid: 'f6c07d5e-790a-4b19-97c5-73ed7ffcaa39',
            operator: 'EQUAL_TO',
            conditionOperator: 'ALL',
            value: null,
            endSurvey: false,
            goTo: {
              uid: '6f7562bd-4902-4390-a48c-f33a41809f8f',
              style: {
                width: null,
                height: null,
                margin: 0,
                shadow: {
                  x: 0,
                  y: 0,
                  blur: 0,
                  color: null,
                  opacity: 0,
                },
                overlay: null,
                background: {
                  type: 'color',
                  animated: false,
                  primaryColor: '#FFFFFF',
                  secondaryColor: null,
                },
                animationIn: 'fade',
                animationOut: 'fade',
                borderRadius: 0,
                withElementsAnimations: true,
              },
              name: 'Step interview 1',
              question: null,
              additionalInformation: null,
              type: 'INTERVIEW',
              mdContent: null,
              mdRawContent: null,
              indexOrder: 3,
              ctaLabel: null,
              ctaColor: null,
              ctaBackgroundColor: null,
              ctaBorderRadius: null,
              ctaUrl: null,
              ctaAction: null,
              ctaFontSize: null,
              interactiveLink: null,
              conceptThumbnailUrl: null,
              createdAt: '2024-02-19T08:58:09.000Z',
              mazeUrl: null,
              stepFlags: 0,
              endSurvey: false,
            },
            optionValues: [
              {
                uid: '02b4c055-0c56-472a-8beb-74e9ee80eae7',
                content: 'Option 1',
                indexOrder: 0,
              },
            ],
          },
        ],
        prototypes: [],
      },
      {
        uid: 'f746acfb-03fd-49bb-adec-977097a1c21f',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step text 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_BLOCK',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T08:55:09.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '446d39bd-de19-427c-a14d-7a0f93f85887',
            type: 'BODY',
            value:
              '<p>All participants will be rewarded &#x24;100 gift card in appreciation for their time.</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'All participants will be rewarded $100 gift card in appreciation for their time.',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#acacacff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-19T08:55:10.000Z',
            file: null,
            options: [],
          },
          {
            uid: '78915b50-b520-47e6-b243-91e364c135b9',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T08:55:28.000Z',
            file: null,
            options: [],
          },
          {
            uid: '92475609-d872-4053-a441-1406fb4cbc2a',
            type: 'SECONDARY_CTA',
            value: 'Not now;close;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#d791e2ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderRadius: 24,
              primaryColor: '#dc52e23b',
            },
            createdAt: '2024-02-19T08:56:59.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'a7c39435-760c-48e8-a973-5793f794e0e0',
            type: 'TITLE',
            value:
              'Hi there! Interested in participating a research study?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T08:55:10.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'f3611c9e-a0b2-4dea-a8e5-5b710537eb32',
            type: 'PRIMARY_CTA',
            value: "I'm interested;step-next;none;",
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#a500ffff',
            },
            createdAt: '2024-02-19T08:55:10.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
    ],
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '0',
        y: '0',
        blur: '0',
        color: '#316eda00',
      },
      overlay: '#0000004d',
      background: {
        type: 'color',
        animated: true,
        primaryColor: '#202020ff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'SURVEY',
    id: 'c1686486-5a80-4d1c-9dba-2c230c2b8b13',
    image: Survey13,
    tags: ['Collect Feedback'],
    type: 'SNIPPET',
    title: 'Conduct a usability testing with Figma Prototype',
    description:
      "Ideal for evaluating user interaction with your Figma prototype, this template facilitates a focused usability assessment. It's crafted to introduce participants to a simulated environment of your product, acknowledging the prototype's inherent limitations. This approach ensures feedback is centered on user experience rather than technical aspects.",
    tourSteps: [],
    steps: [
      {
        uid: '22c7e311-f598-42fd-af15-421f4205b7eb',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step success 1',
        question: null,
        additionalInformation: null,
        type: 'SUCCESS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T10:50:45.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '01200c03-1f19-4ce5-a94c-f7305609e34b',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-19T10:50:45.000Z',
            file: null,
            options: [],
          },
          {
            uid: '28418ee8-b504-43b1-bbf0-e2b2310f5aaf',
            type: 'BODY',
            value:
              'You just completed our survey. Thank you a lot for helping us improve our product.',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-19T10:50:45.000Z',
            file: null,
            options: [],
          },
          {
            uid: '7b609fb9-93bf-4b1d-9655-f651c3f5a272',
            type: 'ANIMATION',
            value: 'confetti',
            rawValue: null,
            flags: 0,
            style: null,
            createdAt: '2024-02-19T10:50:45.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'e293888e-89d6-4267-8c90-a53f580c686d',
            type: 'TITLE',
            value: 'Thank you for your input!|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T10:50:45.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'a01f05d1-323f-47e4-b944-3b1785638a17',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step concept test 1',
        question: null,
        additionalInformation: null,
        type: 'CONCEPT_TEST',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T09:14:15.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '14fc4434-af0d-48b2-ad8d-10640d688440',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-02-19T09:14:15.000Z',
            file: null,
            options: [],
          },
          {
            uid: '2b07994c-b5cb-42ae-9f70-d411bc24997c',
            type: 'TITLE',
            value: 'Hey! Curious on our new feature prototype?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T09:14:15.000Z',
            file: null,
            options: [],
          },
          {
            uid: '63343c44-6e57-4715-8e44-4ef78721e515',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-19T09:14:15.000Z',
            file: null,
            options: [],
          },
          {
            uid: '9b45170c-d9e0-4e12-a0db-9a5ec5e5bdea',
            type: 'BODY',
            value:
              '<p>Don&#x2019;t worry if something does not work, this is just a prototype so some functionality will be missing.</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Don’t worry if something does not work, this is just a prototype so some functionality will be missing.',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-19T09:14:39.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'eea621f8-b734-4135-8b8d-b4a51790e281',
            type: 'CONCEPT',
            value: 'image;;;',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 16,
            },
            createdAt: '2024-02-19T09:14:15.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [
          {
            uid: '0f8fa455-c41e-4041-a107-e8d473600f1a',
            name: null,
            description: null,
            interactiveLink: null,
            playCount: 0,
            createdAt: '2024-02-19T09:14:15.000Z',
            steps: [
              {
                uid: '077671a7-4bb9-4cec-beb9-e4394abf0763',
                style: {
                  width: null,
                  height: null,
                  margin: 0,
                  shadow: {
                    x: 0,
                    y: 0,
                    blur: 0,
                    color: null,
                    opacity: 0,
                  },
                  overlay: null,
                  background: {
                    type: 'color',
                    animated: false,
                    primaryColor: '#FFFFFF',
                    secondaryColor: null,
                  },
                  animationIn: 'fade',
                  animationOut: 'fade',
                  borderRadius: 0,
                  withElementsAnimations: true,
                },
                name: '< 60 %',
                question: null,
                additionalInformation: null,
                type: 'TEXT_LONG',
                mdContent: null,
                mdRawContent: null,
                indexOrder: 3,
                ctaLabel: null,
                ctaColor: null,
                ctaBackgroundColor: null,
                ctaBorderRadius: null,
                ctaUrl: null,
                ctaAction: null,
                ctaFontSize: null,
                interactiveLink: null,
                conceptThumbnailUrl: null,
                createdAt: '2024-02-19T10:49:43.000Z',
                mazeUrl: null,
                stepFlags: 0,
                endSurvey: false,
                blocks: [
                  {
                    uid: '02851974-9f6e-4153-9429-220074c88757',
                    type: 'OPEN_QUESTION',
                    value: 'Enter your answer',
                    rawValue: null,
                    flags: 0,
                    style: {
                      borderRadius: 8,
                      primaryColor: '#d4d4de4d',
                    },
                    createdAt: '2024-02-19T10:49:43.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: '1d949b30-3dd0-4942-8476-ad66fe184903',
                    type: 'USER',
                    value:
                      'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
                    rawValue: null,
                    flags: 0,
                    style: {
                      borderRadius: 8,
                    },
                    createdAt: '2024-02-19T10:49:43.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: '57fea960-a3a4-47d2-80d9-5920ca6abaf4',
                    type: 'TITLE',
                    value:
                      'Could you tell us what was unclear or missing?|-|none|-|',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'left',
                      fontSize: 16,
                      fontColor: '#000000ff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                    },
                    createdAt: '2024-02-19T10:49:43.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: 'a741a291-2c1c-41ed-8a94-f233d478dc61',
                    type: 'PRIMARY_CTA',
                    value: 'continue;step-next;none;',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'right',
                      padding: 12,
                      fontSize: 15,
                      fontColor: '#ffffffff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                      borderColor: null,
                      borderRadius: 24,
                      primaryColor: '#0038ffff',
                    },
                    createdAt: '2024-02-19T10:49:43.000Z',
                    file: null,
                    options: [],
                  },
                ],
                jumps: [],
              },
              {
                uid: '417c8fbe-cf7c-4cb3-a783-db3c14b6fb58',
                style: {
                  width: null,
                  height: null,
                  margin: 0,
                  shadow: {
                    x: 0,
                    y: 0,
                    blur: 0,
                    color: null,
                    opacity: 0,
                  },
                  overlay: null,
                  background: {
                    type: 'color',
                    animated: false,
                    primaryColor: '#FFFFFF',
                    secondaryColor: null,
                  },
                  animationIn: 'fade',
                  animationOut: 'fade',
                  borderRadius: 0,
                  withElementsAnimations: true,
                },
                name: 'Step open question 1',
                question: null,
                additionalInformation: null,
                type: 'TEXT_LONG',
                mdContent: null,
                mdRawContent: null,
                indexOrder: 4,
                ctaLabel: null,
                ctaColor: null,
                ctaBackgroundColor: null,
                ctaBorderRadius: null,
                ctaUrl: null,
                ctaAction: null,
                ctaFontSize: null,
                interactiveLink: null,
                conceptThumbnailUrl: null,
                createdAt: '2024-02-19T10:50:03.000Z',
                mazeUrl: null,
                stepFlags: 0,
                endSurvey: false,
                blocks: [
                  {
                    uid: '2ff37259-cf5a-4bc0-b491-254b52a03c9b',
                    type: 'PRIMARY_CTA',
                    value: 'Finish;step-next;none;',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'right',
                      padding: 12,
                      fontSize: 15,
                      fontColor: '#ffffffff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                      borderColor: null,
                      borderRadius: 24,
                      primaryColor: '#0038ffff',
                    },
                    createdAt: '2024-02-19T10:50:03.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: '95eac6db-ea44-4e92-b054-f2d6638ffe4b',
                    type: 'TITLE',
                    value:
                      'Is there anything else that you want to say about the Prototype?|-|none|-|',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'left',
                      fontSize: 16,
                      fontColor: '#000000ff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                    },
                    createdAt: '2024-02-19T10:50:03.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: '98e70f18-1506-4825-9807-a0fe12e0c110',
                    type: 'USER',
                    value:
                      'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
                    rawValue: null,
                    flags: 0,
                    style: {
                      borderRadius: 8,
                    },
                    createdAt: '2024-02-19T10:50:03.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: 'f12a083b-eab0-49fc-ad61-37e36eb55556',
                    type: 'OPEN_QUESTION',
                    value: 'Enter your answer',
                    rawValue: null,
                    flags: 0,
                    style: {
                      borderRadius: 8,
                      primaryColor: '#d4d4de4d',
                    },
                    createdAt: '2024-02-19T10:50:03.000Z',
                    file: null,
                    options: [],
                  },
                ],
                jumps: [],
              },
              {
                uid: '77360855-f5fb-44f6-9a5b-9fc40bc6daa8',
                style: {
                  width: null,
                  height: null,
                  margin: 0,
                  shadow: {
                    x: 0,
                    y: 0,
                    blur: 0,
                    color: null,
                    opacity: 0,
                  },
                  overlay: null,
                  background: {
                    type: 'color',
                    animated: false,
                    primaryColor: '#FFFFFF',
                    secondaryColor: null,
                  },
                  animationIn: 'fade',
                  animationOut: 'fade',
                  borderRadius: 0,
                  withElementsAnimations: true,
                },
                name: '> 60%',
                question: null,
                additionalInformation: null,
                type: 'TEXT_LONG',
                mdContent: null,
                mdRawContent: null,
                indexOrder: 2,
                ctaLabel: null,
                ctaColor: null,
                ctaBackgroundColor: null,
                ctaBorderRadius: null,
                ctaUrl: null,
                ctaAction: null,
                ctaFontSize: null,
                interactiveLink: null,
                conceptThumbnailUrl: null,
                createdAt: '2024-02-19T10:42:42.000Z',
                mazeUrl: null,
                stepFlags: 0,
                endSurvey: false,
                blocks: [
                  {
                    uid: '6822689a-30fe-44b2-8a38-0ec2b379ceb6',
                    type: 'TITLE',
                    value: 'What did you find easy about the task?|-|none|-|',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'left',
                      fontSize: 16,
                      fontColor: '#000000ff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                    },
                    createdAt: '2024-02-19T10:42:43.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: '7dcc825c-692b-451c-a96d-93731819d8a7',
                    type: 'USER',
                    value:
                      'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
                    rawValue: null,
                    flags: 0,
                    style: {
                      borderRadius: 8,
                    },
                    createdAt: '2024-02-19T10:42:43.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: 'bea75578-869b-4395-a04c-50879ccce018',
                    type: 'OPEN_QUESTION',
                    value: 'Enter your answer',
                    rawValue: null,
                    flags: 0,
                    style: {
                      borderRadius: 8,
                      primaryColor: '#d4d4de4d',
                    },
                    createdAt: '2024-02-19T10:42:43.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: 'c163ea62-0f84-4451-b40c-e8d17c712dce',
                    type: 'PRIMARY_CTA',
                    value: 'Next;step-next;none;',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'right',
                      padding: 12,
                      fontSize: 15,
                      fontColor: '#ffffffff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                      borderColor: null,
                      borderRadius: 24,
                      primaryColor: '#0038ffff',
                    },
                    createdAt: '2024-02-19T10:42:43.000Z',
                    file: null,
                    options: [],
                  },
                ],
                jumps: [],
              },
              {
                uid: '823b6c4d-365f-4052-8ac7-c228c4ac26bb',
                style: {
                  width: 400,
                  height: null,
                  margin: 0,
                  shadow: {
                    x: 0,
                    y: 0,
                    blur: 0,
                    color: null,
                    opacity: 0,
                  },
                  overlay: null,
                  background: {
                    type: 'color',
                    animated: false,
                    primaryColor: '#FFFFFF',
                    secondaryColor: null,
                  },
                  animationIn: 'fade',
                  animationOut: 'fade',
                  borderRadius: 0,
                  withElementsAnimations: true,
                },
                name: 'Step slider 1',
                question: null,
                additionalInformation: null,
                type: 'SLIDER',
                mdContent: null,
                mdRawContent: null,
                indexOrder: 1,
                ctaLabel: null,
                ctaColor: null,
                ctaBackgroundColor: null,
                ctaBorderRadius: null,
                ctaUrl: null,
                ctaAction: null,
                ctaFontSize: null,
                interactiveLink: null,
                conceptThumbnailUrl: null,
                createdAt: '2024-02-19T10:41:59.000Z',
                mazeUrl: null,
                stepFlags: 0,
                endSurvey: false,
                blocks: [
                  {
                    uid: '067914d2-bb87-4105-808c-501b5730c8eb',
                    type: 'USER',
                    value:
                      'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
                    rawValue: null,
                    flags: 0,
                    style: {
                      borderRadius: 8,
                    },
                    createdAt: '2024-02-19T10:41:59.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: '2f761d9e-b452-4604-aa53-8ccb1687439a',
                    type: 'SLIDER',
                    value: 'emoji;🔥;So easy;So hard',
                    rawValue: null,
                    flags: 8,
                    style: {
                      height: 8,
                      primaryColor: '#505cffff',
                      secondaryColor: '#00000029',
                    },
                    createdAt: '2024-02-19T10:41:59.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: '86401ca9-76b5-419f-8dd1-51f167308256',
                    type: 'TITLE',
                    value: 'How easy was the task?|-|none|-|',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'left',
                      fontSize: 16,
                      fontColor: '#000000ff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                    },
                    createdAt: '2024-02-19T10:41:59.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: 'f48b1d03-660b-4453-b54c-075a33b4e336',
                    type: 'PRIMARY_CTA',
                    value: 'Next;step-next;none;',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'right',
                      padding: 12,
                      fontSize: 15,
                      fontColor: '#ffffffff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                      borderColor: null,
                      borderRadius: 24,
                      primaryColor: '#0038ffff',
                    },
                    createdAt: '2024-02-19T10:41:59.000Z',
                    file: null,
                    options: [],
                  },
                ],
                jumps: [
                  {
                    uid: '1a09e801-a8ab-4b3b-a878-b5b8d95a02b5',
                    operator: 'LESSER_THAN',
                    conditionOperator: 'ALL',
                    value: '60',
                    endSurvey: false,
                    goTo: {
                      uid: '077671a7-4bb9-4cec-beb9-e4394abf0763',
                      style: {
                        width: null,
                        height: null,
                        margin: 0,
                        shadow: {
                          x: 0,
                          y: 0,
                          blur: 0,
                          color: null,
                          opacity: 0,
                        },
                        overlay: null,
                        background: {
                          type: 'color',
                          animated: false,
                          primaryColor: '#FFFFFF',
                          secondaryColor: null,
                        },
                        animationIn: 'fade',
                        animationOut: 'fade',
                        borderRadius: 0,
                        withElementsAnimations: true,
                      },
                      name: '< 60 %',
                      question: null,
                      additionalInformation: null,
                      type: 'TEXT_LONG',
                      mdContent: null,
                      mdRawContent: null,
                      indexOrder: 3,
                      ctaLabel: null,
                      ctaColor: null,
                      ctaBackgroundColor: null,
                      ctaBorderRadius: null,
                      ctaUrl: null,
                      ctaAction: null,
                      ctaFontSize: null,
                      interactiveLink: null,
                      conceptThumbnailUrl: null,
                      createdAt: '2024-02-19T10:49:43.000Z',
                      mazeUrl: null,
                      stepFlags: 0,
                      endSurvey: false,
                    },
                    optionValues: [],
                  },
                  {
                    uid: '259cb7c6-6412-4185-ba57-e016bcbbff4d',
                    operator: 'GREATER_THAN',
                    conditionOperator: 'ALL',
                    value: '60',
                    endSurvey: false,
                    goTo: {
                      uid: '77360855-f5fb-44f6-9a5b-9fc40bc6daa8',
                      style: {
                        width: null,
                        height: null,
                        margin: 0,
                        shadow: {
                          x: 0,
                          y: 0,
                          blur: 0,
                          color: null,
                          opacity: 0,
                        },
                        overlay: null,
                        background: {
                          type: 'color',
                          animated: false,
                          primaryColor: '#FFFFFF',
                          secondaryColor: null,
                        },
                        animationIn: 'fade',
                        animationOut: 'fade',
                        borderRadius: 0,
                        withElementsAnimations: true,
                      },
                      name: '> 60%',
                      question: null,
                      additionalInformation: null,
                      type: 'TEXT_LONG',
                      mdContent: null,
                      mdRawContent: null,
                      indexOrder: 2,
                      ctaLabel: null,
                      ctaColor: null,
                      ctaBackgroundColor: null,
                      ctaBorderRadius: null,
                      ctaUrl: null,
                      ctaAction: null,
                      ctaFontSize: null,
                      interactiveLink: null,
                      conceptThumbnailUrl: null,
                      createdAt: '2024-02-19T10:42:42.000Z',
                      mazeUrl: null,
                      stepFlags: 0,
                      endSurvey: false,
                    },
                    optionValues: [],
                  },
                ],
              },
              {
                uid: 'b84c97e8-3d92-4f1c-929f-39bbe259a0ff',
                style: {
                  width: null,
                  height: null,
                  margin: 0,
                  shadow: {
                    x: 0,
                    y: 0,
                    blur: 0,
                    color: null,
                    opacity: 0,
                  },
                  overlay: null,
                  background: {
                    type: 'color',
                    animated: false,
                    primaryColor: '#FFFFFF',
                    secondaryColor: null,
                  },
                  animationIn: 'fade',
                  animationOut: 'fade',
                  borderRadius: 0,
                  withElementsAnimations: true,
                },
                name: 'Task description',
                question: null,
                additionalInformation: null,
                type: 'TEXT_BLOCK',
                mdContent: null,
                mdRawContent: null,
                indexOrder: 0,
                ctaLabel: null,
                ctaColor: null,
                ctaBackgroundColor: null,
                ctaBorderRadius: null,
                ctaUrl: null,
                ctaAction: null,
                ctaFontSize: null,
                interactiveLink: null,
                conceptThumbnailUrl: null,
                createdAt: '2024-02-19T09:14:15.000Z',
                mazeUrl: null,
                stepFlags: 0,
                endSurvey: false,
                blocks: [
                  {
                    uid: '178d8136-61f9-4239-9582-21f70f886cba',
                    type: 'BODY',
                    value: 'Task description',
                    rawValue: [
                      {
                        data: {},
                        type: 'paragraph',
                        object: 'block',
                        children: [
                          {
                            text: 'Body',
                            object: 'text',
                          },
                        ],
                      },
                    ],
                    flags: 0,
                    style: {
                      align: 'left',
                      fontSize: 15,
                      fontColor: '#545454ff',
                      fontFamily: 'Inter',
                    },
                    createdAt: '2024-02-19T09:14:15.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: '3be71187-a1a1-4569-adf3-814d17212a32',
                    type: 'PRIMARY_CTA',
                    value: 'Next task;step-next;undefined;undefined',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'right',
                      padding: 12,
                      fontSize: 15,
                      fontColor: '#ffffffff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                      borderColor: null,
                      borderRadius: 24,
                      primaryColor: '#0038ffff',
                    },
                    createdAt: '2024-02-19T09:14:15.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: 'd69031eb-ed2d-4f28-98ad-a0e120d00ae1',
                    type: 'TITLE',
                    value: '[Task 1]|-|none|-|',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'left',
                      fontSize: 16,
                      fontColor: '#000000ff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                    },
                    createdAt: '2024-02-19T09:14:15.000Z',
                    file: null,
                    options: [],
                  },
                ],
                jumps: [],
              },
            ],
          },
        ],
      },
      {
        uid: 'ed96b567-6820-479c-a2c5-9cf2560b71ab',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step text 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_BLOCK',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T09:11:49.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '0859d66f-82c1-4ea9-8182-9c99425d6dcf',
            type: 'BODY',
            value:
              '<p>Don&#x2019;t worry if something does not work, this is just a prototype so some functionality will be missing.</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Don’t worry if something does not work, this is just a prototype so some functionality will be missing.',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-19T09:11:49.000Z',
            file: null,
            options: [],
          },
          {
            uid: '2c952ae4-656c-4cb2-9cd7-e23ea6e45f83',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-19T09:13:54.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'c35409ed-3fd7-406b-8225-a342ad8b0f47',
            type: 'PRIMARY_CTA',
            value: "I'm interested!;step-next;none;",
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-02-19T09:11:49.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'ce341616-45f0-4bff-a0f6-83672cf34b04',
            type: 'TITLE',
            value: 'Hey! Curious on our new feature prototype?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T09:11:49.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
    ],
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '0',
        y: '0',
        blur: '0',
        color: '#316eda00',
      },
      overlay: null,
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'slide-left',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'SURVEY',
    id: 'e6c3b176-5297-49e5-9d80-11629aaac23e',
    image: Survey5,
    tags: ['Customer Satisfaction'],
    type: 'SNIPPET',
    title: 'Measure customer satisfaction',
    description:
      'This survey template is streamlined to measure customer satisfaction with your product efficiently. It seeks to understand the level of contentment among users and uncover actionable insights to enhance their experience.',
    tourSteps: [],
    steps: [
      {
        uid: '2edcc0e1-63c8-4117-89eb-77213a68bd6f',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Satisfied',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T07:51:08.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '3eaec5d9-b244-43db-a55e-7bde34b4fa7b',
            type: 'TITLE',
            value:
              'Great! Is there anything we can do to improve your experience?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T07:51:09.000Z',
            file: null,
            options: [],
          },
          {
            uid: '4205c47c-2c90-492a-abba-c752e8d73260',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
              primaryColor: '#d4d4de4d',
            },
            createdAt: '2024-02-12T07:51:08.000Z',
            file: null,
            options: [],
          },
          {
            uid: '59f05d82-6e93-4e28-a414-53a5417289a2',
            type: 'PRIMARY_CTA',
            value: 'Finish;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-02-12T07:51:09.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'bd56f63e-ee45-492c-9751-ca4d9d845f80',
            type: 'SECONDARY_CTA',
            value: 'Back;step-prev;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderRadius: 24,
              primaryColor: '#4950731a',
            },
            createdAt: '2024-02-12T07:52:30.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: {
          uid: '8a13847a-68f6-4aeb-8ffd-478253394175',
          style: {
            width: null,
            height: null,
            margin: 0,
            shadow: {
              x: 0,
              y: 0,
              blur: 0,
              color: null,
              opacity: 0,
            },
            overlay: null,
            background: {
              type: 'color',
              animated: false,
              primaryColor: '#FFFFFF',
              secondaryColor: null,
            },
            animationIn: 'fade',
            animationOut: 'fade',
            borderRadius: 0,
            withElementsAnimations: true,
          },
          name: 'Thank You',
          question: null,
          additionalInformation: null,
          type: 'SUCCESS',
          mdContent: null,
          mdRawContent: null,
          indexOrder: 3,
          ctaLabel: null,
          ctaColor: null,
          ctaBackgroundColor: null,
          ctaBorderRadius: null,
          ctaUrl: null,
          ctaAction: null,
          ctaFontSize: null,
          interactiveLink: null,
          conceptThumbnailUrl: null,
          createdAt: '2024-02-12T07:53:37.000Z',
          mazeUrl: null,
          stepFlags: 0,
          endSurvey: false,
        },
        jumps: [],
        prototypes: [],
      },
      {
        uid: '7fefbc8e-9b5d-4ac3-87d6-38e2fd8909d0',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Disappointed',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T07:52:49.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '44d0905d-b4d7-4201-99eb-1c8c1119ca4e',
            type: 'TITLE',
            value:
              'Sorry to hear that! How could we improve your experience?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T07:52:49.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'bb447d43-5345-4d8c-a908-92d8ab9cb335',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
              primaryColor: '#d4d4de4d',
            },
            createdAt: '2024-02-12T07:52:49.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'e2d21209-8f5e-44bd-ba5e-e4a13c789247',
            type: 'SECONDARY_CTA',
            value: 'Back;step-prev;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderRadius: 24,
              primaryColor: '#4950731a',
            },
            createdAt: '2024-02-12T07:53:19.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'e57fb54f-e828-4b47-88c2-84de23660050',
            type: 'PRIMARY_CTA',
            value: 'Finish;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-02-12T07:52:49.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '8a13847a-68f6-4aeb-8ffd-478253394175',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Thank You',
        question: null,
        additionalInformation: null,
        type: 'SUCCESS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 3,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T07:53:37.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '005a3151-9659-4395-86f8-6f89f6637644',
            type: 'ANIMATION',
            value: 'confetti',
            rawValue: null,
            flags: 0,
            style: null,
            createdAt: '2024-02-12T07:53:37.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'ac45d919-bc83-4dcb-98bc-2f8b8af9df6d',
            type: 'BODY',
            value:
              'You just completed our survey. Thank you a lot for helping us improve our product.',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-12T07:53:37.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'bf8e7bb2-04ab-4bd4-9b80-c8dcf607d7e1',
            type: 'TITLE',
            value: 'Thank you for your input! 🙌|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T07:53:37.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'a0f19596-2ae8-4e0f-b346-7323617ba32f',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'NPS',
        question: null,
        additionalInformation: null,
        type: 'NPS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T07:50:06.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '7dd8e209-0376-4138-8ae4-b3801b792acb',
            type: 'NPS',
            value: 'Very disappointed;Very satisfied',
            rawValue: null,
            flags: 4,
            style: {
              padding: 9,
              fontSize: 14,
            },
            createdAt: '2024-02-12T07:50:06.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'f98506c7-38ef-4075-8ce1-01ad1781ffd5',
            type: 'TITLE',
            value:
              'How satisfied are you with your experience with Jimo?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T07:50:06.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [
          {
            uid: '7247ca0d-fdff-4f75-bbcb-a029959a63f3',
            operator: 'LESSER_THAN',
            conditionOperator: 'ALL',
            value: '6',
            endSurvey: false,
            goTo: {
              uid: '7fefbc8e-9b5d-4ac3-87d6-38e2fd8909d0',
              style: {
                width: null,
                height: null,
                margin: 0,
                shadow: {
                  x: 0,
                  y: 0,
                  blur: 0,
                  color: null,
                  opacity: 0,
                },
                overlay: null,
                background: {
                  type: 'color',
                  animated: false,
                  primaryColor: '#FFFFFF',
                  secondaryColor: null,
                },
                animationIn: 'fade',
                animationOut: 'fade',
                borderRadius: 0,
                withElementsAnimations: true,
              },
              name: 'Disappointed',
              question: null,
              additionalInformation: null,
              type: 'TEXT_LONG',
              mdContent: null,
              mdRawContent: null,
              indexOrder: 2,
              ctaLabel: null,
              ctaColor: null,
              ctaBackgroundColor: null,
              ctaBorderRadius: null,
              ctaUrl: null,
              ctaAction: null,
              ctaFontSize: null,
              interactiveLink: null,
              conceptThumbnailUrl: null,
              createdAt: '2024-02-12T07:52:49.000Z',
              mazeUrl: null,
              stepFlags: 0,
              endSurvey: false,
            },
            optionValues: [],
          },
          {
            uid: 'bb407303-c977-454b-b2bd-0191a7042611',
            operator: 'GREATER_THAN',
            conditionOperator: 'ALL',
            value: '6',
            endSurvey: false,
            goTo: {
              uid: '2edcc0e1-63c8-4117-89eb-77213a68bd6f',
              style: {
                width: null,
                height: null,
                margin: 0,
                shadow: {
                  x: 0,
                  y: 0,
                  blur: 0,
                  color: null,
                  opacity: 0,
                },
                overlay: null,
                background: {
                  type: 'color',
                  animated: false,
                  primaryColor: '#FFFFFF',
                  secondaryColor: null,
                },
                animationIn: 'fade',
                animationOut: 'fade',
                borderRadius: 0,
                withElementsAnimations: true,
              },
              name: 'Satisfied',
              question: null,
              additionalInformation: null,
              type: 'TEXT_LONG',
              mdContent: null,
              mdRawContent: null,
              indexOrder: 1,
              ctaLabel: null,
              ctaColor: null,
              ctaBackgroundColor: null,
              ctaBorderRadius: null,
              ctaUrl: null,
              ctaAction: null,
              ctaFontSize: null,
              interactiveLink: null,
              conceptThumbnailUrl: null,
              createdAt: '2024-02-12T07:51:08.000Z',
              mazeUrl: null,
              stepFlags: 0,
              endSurvey: false,
            },
            optionValues: [],
          },
        ],
        prototypes: [],
      },
    ],
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '0',
        y: '0',
        blur: '0',
        color: '#316eda00',
      },
      overlay: null,
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'slide-left',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'SURVEY',
    id: 'fa149a80-60b9-40fc-ad8f-1b9a3bf56052',
    image: Survey12,
    tags: ['Collect Feedback'],
    type: 'SNIPPET',
    title: 'Validate a feature with a Figma Prototype',
    description:
      'This survey template is essential when considering the introduction of new features in your application. It’s a quick, two-minute check-in designed to validate potential enhancements directly with your user base, using a Figma prototype to illustrate the concept in question.',
    tourSteps: [],
    steps: [
      {
        uid: '103a3a26-b18e-4d49-9113-81a81dbc42d4',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step success 1',
        question: null,
        additionalInformation: null,
        type: 'SUCCESS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T11:11:19.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '489e5d9c-5096-4a65-abba-e0fc242873c0',
            type: 'ANIMATION',
            value: 'confetti',
            rawValue: null,
            flags: 0,
            style: null,
            createdAt: '2024-02-19T11:11:19.000Z',
            file: null,
            options: [],
          },
          {
            uid: '57394e80-5c54-413d-9924-c382b91a00b6',
            type: 'BODY',
            value:
              'You just completed our survey. Thank you a lot for helping us improve our product.',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-19T11:11:19.000Z',
            file: null,
            options: [],
          },
          {
            uid: '7f7ee14c-602a-4441-87f0-f527457cb9bd',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T11:11:19.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'ed11ed0a-c597-454a-9407-7fa2e78ad928',
            type: 'TITLE',
            value: 'Thank you for your input!|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T11:11:19.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'aba93bb7-17a5-4a80-9e31-1962dbcaa1dd',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step concept test 1',
        question: null,
        additionalInformation: null,
        type: 'CONCEPT_TEST',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T11:03:40.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '25be92c2-05b3-48f6-b441-b59ca44ce8c6',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#08915fff',
            },
            createdAt: '2024-02-19T11:03:40.000Z',
            file: null,
            options: [],
          },
          {
            uid: '42d5f407-9f7e-4968-9780-412b80027a2b',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T11:03:40.000Z',
            file: null,
            options: [],
          },
          {
            uid: '5dd6c408-1d7e-4eda-958d-bdfe775b17df',
            type: 'TITLE',
            value:
              "We're working on an idea to help with [Problem Area]|-|none|-|",
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T11:03:40.000Z',
            file: null,
            options: [],
          },
          {
            uid: '8a9feee7-8283-4878-ac0e-fb064d380c75',
            type: 'BODY',
            value:
              '<p>[Insert concept here. Provide enough detail to make it clear what the feature is, but keep it brief and easy to understand.]</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: '[Insert concept here. Provide enough detail to make it clear what the feature is, but keep it brief and easy to understand.]',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-19T11:04:10.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'e84f75f6-9ebd-437c-aa32-a05ee117cff8',
            type: 'CONCEPT',
            value: 'image;;;',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-19T11:03:40.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [
          {
            uid: '6e3fa4d7-6427-4fec-b3da-de8a966a081a',
            name: null,
            description: null,
            interactiveLink: null,
            playCount: 0,
            createdAt: '2024-02-19T11:03:40.000Z',
            steps: [
              {
                uid: '40288f8d-5380-49fd-b9ab-9a6cfa6ab931',
                style: {
                  width: null,
                  height: null,
                  margin: 0,
                  shadow: {
                    x: 0,
                    y: 0,
                    blur: 0,
                    color: null,
                    opacity: 0,
                  },
                  overlay: null,
                  background: {
                    type: 'color',
                    animated: false,
                    primaryColor: '#FFFFFF',
                    secondaryColor: null,
                  },
                  animationIn: 'fade',
                  animationOut: 'fade',
                  borderRadius: 0,
                  withElementsAnimations: true,
                },
                name: '< 50%',
                question: null,
                additionalInformation: null,
                type: 'TEXT_LONG',
                mdContent: null,
                mdRawContent: null,
                indexOrder: 1,
                ctaLabel: null,
                ctaColor: null,
                ctaBackgroundColor: null,
                ctaBorderRadius: null,
                ctaUrl: null,
                ctaAction: null,
                ctaFontSize: null,
                interactiveLink: null,
                conceptThumbnailUrl: null,
                createdAt: '2024-02-19T11:08:10.000Z',
                mazeUrl: null,
                stepFlags: 0,
                endSurvey: true,
                blocks: [
                  {
                    uid: '824bb396-e95e-4951-a917-e0ab6a74b756',
                    type: 'OPEN_QUESTION',
                    value: 'Enter your answer',
                    rawValue: null,
                    flags: 0,
                    style: {
                      borderRadius: 6,
                      primaryColor: '#FFFFFF',
                    },
                    createdAt: '2024-02-19T11:08:10.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: '8c7d87a8-69ef-4436-b454-097184292eaa',
                    type: 'USER',
                    value:
                      'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
                    rawValue: null,
                    flags: 0,
                    style: {
                      borderRadius: 6,
                    },
                    createdAt: '2024-02-19T11:08:10.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: '8ff8fff6-3f0a-4100-8e51-9513d8e53dd4',
                    type: 'PRIMARY_CTA',
                    value: 'Finish;step-next;none;',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'right',
                      padding: 12,
                      fontSize: 15,
                      fontColor: '#ffffffff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                      borderColor: null,
                      borderRadius: 24,
                      primaryColor: '#08915fff',
                    },
                    createdAt: '2024-02-19T11:08:10.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: 'b113fbd6-fbb4-4f07-beaf-e697fdb64d29',
                    type: 'TITLE',
                    value:
                      "Why wouldn't this feature be valuable to you?|-|none|-|",
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'left',
                      fontSize: 16,
                      fontColor: '#000000ff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                    },
                    createdAt: '2024-02-19T11:08:10.000Z',
                    file: null,
                    options: [],
                  },
                ],
                jumps: [],
              },
              {
                uid: 'b445d33a-104a-4d46-9faa-54f874b20b97',
                style: {
                  width: null,
                  height: null,
                  margin: 0,
                  shadow: {
                    x: 0,
                    y: 0,
                    blur: 0,
                    color: null,
                    opacity: 0,
                  },
                  overlay: null,
                  background: {
                    type: 'color',
                    animated: false,
                    primaryColor: '#FFFFFF',
                    secondaryColor: null,
                  },
                  animationIn: 'fade',
                  animationOut: 'fade',
                  borderRadius: 0,
                  withElementsAnimations: true,
                },
                name: '> 50%',
                question: null,
                additionalInformation: null,
                type: 'TEXT_LONG',
                mdContent: null,
                mdRawContent: null,
                indexOrder: 2,
                ctaLabel: null,
                ctaColor: null,
                ctaBackgroundColor: null,
                ctaBorderRadius: null,
                ctaUrl: null,
                ctaAction: null,
                ctaFontSize: null,
                interactiveLink: null,
                conceptThumbnailUrl: null,
                createdAt: '2024-02-19T11:10:29.000Z',
                mazeUrl: null,
                stepFlags: 0,
                endSurvey: false,
                blocks: [
                  {
                    uid: '1b4a3dcf-5c78-4f9c-8a27-0310e1170ebb',
                    type: 'OPEN_QUESTION',
                    value: 'Enter your answer',
                    rawValue: null,
                    flags: 0,
                    style: {
                      borderRadius: 6,
                      primaryColor: '#FFFFFF',
                    },
                    createdAt: '2024-02-19T11:10:29.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: '8e9feaa8-4535-4847-858d-7a4bc28a32fe',
                    type: 'TITLE',
                    value:
                      'What would be most valuable to you in a feature like this?|-|none|-|',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'left',
                      fontSize: 16,
                      fontColor: '#000000ff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                    },
                    createdAt: '2024-02-19T11:10:29.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: 'bd2f22fb-40b8-4302-b7cd-bf57af3025c7',
                    type: 'USER',
                    value:
                      'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
                    rawValue: null,
                    flags: 0,
                    style: {
                      borderRadius: 6,
                    },
                    createdAt: '2024-02-19T11:10:29.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: 'c8afda2c-dcac-4647-9367-d3963065c36e',
                    type: 'PRIMARY_CTA',
                    value: 'Finish;step-next;none;',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'right',
                      padding: 12,
                      fontSize: 15,
                      fontColor: '#ffffffff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                      borderColor: null,
                      borderRadius: 24,
                      primaryColor: '#08915fff',
                    },
                    createdAt: '2024-02-19T11:10:29.000Z',
                    file: null,
                    options: [],
                  },
                ],
                jumps: [],
              },
              {
                uid: 'e1aea1e4-f659-4707-9223-c0d54141c861',
                style: {
                  width: null,
                  height: null,
                  margin: 0,
                  shadow: {
                    x: 0,
                    y: 0,
                    blur: 0,
                    color: null,
                    opacity: 0,
                  },
                  overlay: null,
                  background: {
                    type: 'color',
                    animated: false,
                    primaryColor: '#FFFFFF',
                    secondaryColor: null,
                  },
                  animationIn: 'fade',
                  animationOut: 'fade',
                  borderRadius: 0,
                  withElementsAnimations: true,
                },
                name: 'Step slider 1',
                question: null,
                additionalInformation: null,
                type: 'SLIDER',
                mdContent: null,
                mdRawContent: null,
                indexOrder: 0,
                ctaLabel: null,
                ctaColor: null,
                ctaBackgroundColor: null,
                ctaBorderRadius: null,
                ctaUrl: null,
                ctaAction: null,
                ctaFontSize: null,
                interactiveLink: null,
                conceptThumbnailUrl: null,
                createdAt: '2024-02-19T11:05:28.000Z',
                mazeUrl: null,
                stepFlags: 0,
                endSurvey: false,
                blocks: [
                  {
                    uid: '33660ae1-7294-4681-9125-40954775f4c2',
                    type: 'SLIDER',
                    value: 'emoji;🥰;So Worthless;So valuable',
                    rawValue: null,
                    flags: 8,
                    style: {
                      height: 8,
                      primaryColor: '#FFFFFF',
                      secondaryColor: '#298d28ff',
                    },
                    createdAt: '2024-02-19T11:05:28.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: '8373d129-e27d-483e-a5f8-a80da65b40b4',
                    type: 'USER',
                    value:
                      'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
                    rawValue: null,
                    flags: 0,
                    style: {
                      borderRadius: 6,
                    },
                    createdAt: '2024-02-19T11:05:28.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: 'c96220f3-3c7a-4410-9675-f5c3634977ee',
                    type: 'TITLE',
                    value: 'How valuable is this feature to you?|-|none|-|',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'left',
                      fontSize: 16,
                      fontColor: '#000000ff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                    },
                    createdAt: '2024-02-19T11:05:28.000Z',
                    file: null,
                    options: [],
                  },
                  {
                    uid: 'eeaffcf7-3be6-425b-94e8-572c7cbec2b3',
                    type: 'PRIMARY_CTA',
                    value: 'Next;step-next;none;',
                    rawValue: null,
                    flags: 0,
                    style: {
                      align: 'right',
                      padding: 12,
                      fontSize: 15,
                      fontColor: '#ffffffff',
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                      borderColor: null,
                      borderRadius: 24,
                      primaryColor: '#08915fff',
                    },
                    createdAt: '2024-02-19T11:05:28.000Z',
                    file: null,
                    options: [],
                  },
                ],
                jumps: [
                  {
                    uid: '5fd36a36-dd6b-43a5-a548-032ce037c556',
                    operator: 'GREATER_THAN',
                    conditionOperator: 'ALL',
                    value: '50',
                    endSurvey: false,
                    goTo: {
                      uid: 'b445d33a-104a-4d46-9faa-54f874b20b97',
                      style: {
                        width: null,
                        height: null,
                        margin: 0,
                        shadow: {
                          x: 0,
                          y: 0,
                          blur: 0,
                          color: null,
                          opacity: 0,
                        },
                        overlay: null,
                        background: {
                          type: 'color',
                          animated: false,
                          primaryColor: '#FFFFFF',
                          secondaryColor: null,
                        },
                        animationIn: 'fade',
                        animationOut: 'fade',
                        borderRadius: 0,
                        withElementsAnimations: true,
                      },
                      name: '> 50%',
                      question: null,
                      additionalInformation: null,
                      type: 'TEXT_LONG',
                      mdContent: null,
                      mdRawContent: null,
                      indexOrder: 2,
                      ctaLabel: null,
                      ctaColor: null,
                      ctaBackgroundColor: null,
                      ctaBorderRadius: null,
                      ctaUrl: null,
                      ctaAction: null,
                      ctaFontSize: null,
                      interactiveLink: null,
                      conceptThumbnailUrl: null,
                      createdAt: '2024-02-19T11:10:29.000Z',
                      mazeUrl: null,
                      stepFlags: 0,
                      endSurvey: false,
                    },
                    optionValues: [],
                  },
                  {
                    uid: 'f71d11af-1653-4709-a667-a37074b22799',
                    operator: 'LESSER_THAN',
                    conditionOperator: 'ALL',
                    value: '50',
                    endSurvey: false,
                    goTo: {
                      uid: '40288f8d-5380-49fd-b9ab-9a6cfa6ab931',
                      style: {
                        width: null,
                        height: null,
                        margin: 0,
                        shadow: {
                          x: 0,
                          y: 0,
                          blur: 0,
                          color: null,
                          opacity: 0,
                        },
                        overlay: null,
                        background: {
                          type: 'color',
                          animated: false,
                          primaryColor: '#FFFFFF',
                          secondaryColor: null,
                        },
                        animationIn: 'fade',
                        animationOut: 'fade',
                        borderRadius: 0,
                        withElementsAnimations: true,
                      },
                      name: '< 50%',
                      question: null,
                      additionalInformation: null,
                      type: 'TEXT_LONG',
                      mdContent: null,
                      mdRawContent: null,
                      indexOrder: 1,
                      ctaLabel: null,
                      ctaColor: null,
                      ctaBackgroundColor: null,
                      ctaBorderRadius: null,
                      ctaUrl: null,
                      ctaAction: null,
                      ctaFontSize: null,
                      interactiveLink: null,
                      conceptThumbnailUrl: null,
                      createdAt: '2024-02-19T11:08:10.000Z',
                      mazeUrl: null,
                      stepFlags: 0,
                      endSurvey: true,
                    },
                    optionValues: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        uid: 'cd7943a6-eb0a-4fd6-9565-3dcc42562de3',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step text 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_BLOCK',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-19T11:00:53.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '04197657-ebee-400d-b2e1-abea80a519e4',
            type: 'TITLE',
            value:
              'Got a minute to tell us what you think about a potential new feature?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-19T11:00:53.000Z',
            file: null,
            options: [],
          },
          {
            uid: '3b048126-dde4-4053-ba6d-679b5caefa55',
            type: 'BODY',
            value: '<p>This will take just 2 minutes of your time</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'This will take just 2 minutes of your time',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 14,
              fontColor: '#938e8eff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-19T11:00:53.000Z',
            file: null,
            options: [],
          },
          {
            uid: '3d7fe01d-f68c-4fd1-ad75-18e176aaa84a',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-02-19T11:02:24.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'bdf30607-961f-41d5-9689-840708d4aa5c',
            type: 'SECONDARY_CTA',
            value: 'No thanks;close;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: '#FFFFFF',
              borderRadius: 24,
              primaryColor: '#f1ededff',
            },
            createdAt: '2024-02-19T11:02:01.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'f935679c-c03b-4da3-92e5-045cca76d511',
            type: 'PRIMARY_CTA',
            value: 'Yes, proceed;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#08915fff',
            },
            createdAt: '2024-02-19T11:00:53.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
    ],
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '0',
        y: '0',
        blur: '0',
        color: '#5479bf00',
      },
      overlay: null,
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#f0ffe9ff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 24,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'SURVEY',
    id: 'd45c58de-bb27-4a33-bb70-7a2a582f04e0',
    image: Survey8,
    tags: ['Product Evaluation'],
    type: 'SNIPPET',
    title: 'Measure product/market fit',
    description: '',
    tourSteps: [],
    steps: [
      {
        uid: '0be6e4b4-f4f4-4850-ba02-1d635fbd9123',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 2',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T11:12:58.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '0500f702-b02e-433e-8a28-a88efafc6013',
            type: 'SECONDARY_CTA',
            value: 'Back;step-prev;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: '#FFFFFF',
              borderRadius: 24,
              primaryColor: '#edededff',
            },
            createdAt: '2024-02-12T11:13:31.000Z',
            file: null,
            options: [],
          },
          {
            uid: '493556f5-13c1-4229-8ac5-9992da1072e3',
            type: 'TITLE',
            value:
              'If [Product name] were no longer available, what would you use instead?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T11:12:58.000Z',
            file: null,
            options: [],
          },
          {
            uid: '5f560b47-aac5-4d11-8efb-a96f9c5650a9',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-TG8OhuVr0GfI.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-13T11:16:54.000Z',
            file: null,
            options: [],
          },
          {
            uid: '72e5ce25-8578-4291-ad9c-abb82e969a73',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0f1e25ff',
            },
            createdAt: '2024-02-12T11:12:58.000Z',
            file: null,
            options: [],
          },
          {
            uid: '95c7963b-c03b-4aa5-a5b9-c34e7c71e3f7',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
              primaryColor: '#FFFFFF',
            },
            createdAt: '2024-02-12T11:12:58.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '282295e4-1a7d-42db-aaa7-e827c3188b08',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T11:12:35.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '2492a82e-9cca-42fa-8caa-6f446ad81589',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0f1e25ff',
            },
            createdAt: '2024-02-12T11:12:35.000Z',
            file: null,
            options: [],
          },
          {
            uid: '62ee3d24-4ff9-40ab-a734-8962fb873623',
            type: 'TITLE',
            value: 'What makes you feel that way?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T11:12:35.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'c2fcc899-dbd8-43e3-a7ab-203d05b9c6bb',
            type: 'USER',
            value:
              'John Doe;CPO @Lance;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-TG8OhuVr0GfI.jpg;null',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-13T11:16:49.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'e165fc4c-0e9f-405e-b4ec-d9e19891cf86',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
              primaryColor: '#FFFFFF',
            },
            createdAt: '2024-02-12T11:12:35.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'ec71a889-2a5c-4ae9-99a8-daeb4c558fc5',
            type: 'SECONDARY_CTA',
            value: 'Back;step-prev;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: '#FFFFFF',
              borderRadius: 24,
              primaryColor: '#edededff',
            },
            createdAt: '2024-02-12T11:12:50.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '58bbe19a-5139-4592-a125-5857150276ab',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step success 1',
        question: null,
        additionalInformation: null,
        type: 'SUCCESS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 4,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T11:14:15.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '5ab0b505-d161-4d6f-8973-2c39c2b0b717',
            type: 'ANIMATION',
            value: 'confetti',
            rawValue: null,
            flags: 0,
            style: null,
            createdAt: '2024-02-12T11:14:15.000Z',
            file: null,
            options: [],
          },
          {
            uid: '5c0379af-1ad9-4a57-9cd3-8fc8e660fab0',
            type: 'TITLE',
            value: 'Thank you for your input!|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T11:14:15.000Z',
            file: null,
            options: [],
          },
          {
            uid: '8253fb0f-200e-4755-a197-640bf2ab2340',
            type: 'BODY',
            value:
              'You just completed our survey. Thank you a lot for helping us improve our product.',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Inter',
            },
            createdAt: '2024-02-12T11:14:15.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'd8879bf9-4727-491d-83eb-1afa0772553b',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-TG8OhuVr0GfI.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-13T11:17:00.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '66b6b3bf-73a8-459e-9080-6cb6801d206d',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 3',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 3,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T11:13:43.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '0e69f237-65be-451c-b180-04008b61b3c9',
            type: 'TITLE',
            value:
              'What is the main benefit you receive from [Product name]?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T11:13:44.000Z',
            file: null,
            options: [],
          },
          {
            uid: '427bc6df-b811-420d-b2db-89e234a75735',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-TG8OhuVr0GfI.jpg;undefined',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-13T11:16:54.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'a06229ee-d0dc-4369-b08a-3c8e6a632a17',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
              primaryColor: '#FFFFFF',
            },
            createdAt: '2024-02-12T11:13:44.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'ae5a6508-2952-4acf-ac3c-a3f72d3bb49c',
            type: 'SECONDARY_CTA',
            value: 'Back;step-prev;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: '#FFFFFF',
              borderRadius: 24,
              primaryColor: '#edededff',
            },
            createdAt: '2024-02-12T11:14:07.000Z',
            file: null,
            options: [],
          },
          {
            uid: 'e3212cd8-e2e4-470d-8bdf-3864e5654558',
            type: 'PRIMARY_CTA',
            value: 'Finish;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0f1e25ff',
            },
            createdAt: '2024-02-12T11:13:44.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'd0a05945-58b4-4bec-b6de-e357300259c7',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step multiple choice 1',
        question: null,
        additionalInformation: null,
        type: 'MULTIPLE_CHOICE_MULTI_SELECT',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-02-12T11:11:33.000Z',
        mazeUrl: null,
        stepFlags: 4,
        endSurvey: false,
        blocks: [
          {
            uid: '46ad0917-baee-431a-8aa0-53be4d96e61c',
            type: 'TITLE',
            value:
              'How would you feel if you could no longer use [Product name]?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-02-12T11:11:33.000Z',
            file: null,
            options: [],
          },
          {
            uid: '522d5d07-5a0d-4e31-8059-1652d2b6f603',
            type: 'CHOICE',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              padding: 12,
              fontSize: 14,
              fontFamily: 'Inter',
              disposition: 'block',
              borderRadius: 12,
              primaryColor: '#dad3d352',
              withCheckbox: false,
            },
            createdAt: '2024-02-12T11:11:33.000Z',
            file: null,
            options: [
              {
                uid: '036e0529-8b45-4691-9ca4-2584bb5144ea',
                content: 'Very disappointed',
                indexOrder: 0,
              },
              {
                uid: 'a955d473-f34b-4b7a-a46e-846b7e963c22',
                content: 'Somewhat disappointed',
                indexOrder: 1,
              },
              {
                uid: 'bf4a2552-a757-48b5-accc-fee0f88b3935',
                content: 'Not disappointed',
                indexOrder: 2,
              },
            ],
          },
          {
            uid: '8f12a212-5368-4b24-a76b-a11ff7392861',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;null',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
            },
            createdAt: '2024-02-13T11:16:23.000Z',
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
    ],
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '0',
        y: '0',
        blur: '0',
        color: '#33548a00',
      },
      overlay: null,
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#e6ebf7ff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },

  {
    experienceType: 'BANNER',
    id: '5d8b5300-a836-42f7-9de1-09ada1f16da3',
    image: TryPremiumFree,
    tags: ['Sales'],
    type: 'TOUR',
    title: 'Try Premium with 2 weeks free',
    description: '',
    tourSteps: [
      {
        uid: 'c2488bee-0293-4d90-8880-f88b976618c2',
        style: {
          width: null,
          height: null,
          margin: 8,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'gradient',
            animated: false,
            primaryColor: '#fcfcfcff',
            secondaryColor: '#e3cfffff',
          },
          animationIn: 'fade',
          borderColor: '#e8ceffff',
          animationOut: 'fade',
          borderRadius: 12,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2359305,
        lastStepChangeAt: '2024-07-01T14:47:50.000Z',
        createdAt: '2024-07-01T14:38:51.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-07-08T13:54:04.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        querySelectorManual: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;22;default',
        boostedSize: null,
        boostedAnimations: 'fade-in;fade-out',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'BANNER',
        deviceFlags: 1,
        priority: 0,
        slackChannelFlags: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '5d8b5300-a836-42f7-9de1-09ada1f16da3',
        boostFlags: 1,
        steps: [
          {
            uid: '4c02092b-9093-4719-bb0d-c924d963a9d0',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {
                x: 0,
                y: 0,
                blur: 0,
                color: null,
                opacity: 0,
              },
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-07-01T14:38:51.000Z',
            mazeUrl: null,
            stepFlags: 0,
            removed: false,
            updateData: {
              date: '2024-07-08T10:16:49.184Z',
              operation: 'modified',
            },
            endSurvey: false,
            blocks: [
              {
                uid: '1312fea5-a531-4dce-a042-8278bad08083',
                type: 'TITLE',
                value: 'Try Premium for two weeks for free|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                  fontWeight: '500',
                },
                createdAt: '2024-07-01T14:38:52.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: 'f886bee6-644d-466c-ab5c-26a29b6a46cf',
                type: 'PRIMARY_CTA',
                value: 'Free trial;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 8,
                  fontSize: 16,
                  fontColor: '#fcfcfcff',
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  borderColor: null,
                  borderRadius: 12,
                  primaryColor: '#7d23baff',
                },
                createdAt: '2024-07-01T14:38:52.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: null,
  },
  {
    experienceType: 'TOUR',
    id: '839a9702-bac6-4d64-8b9b-ad7bb7946ed1',
    image: SuspiciousActivityDetected,
    tags: ['Security'],
    type: 'TOUR',
    title: 'Warning text message',
    description:
      'Warning: Suspicious activity detected on your account. Please verify your recent actions and change your password immediately. Contact support if you need assistance.',
    tourSteps: [
      {
        uid: 'ff9eec1e-b55e-4f28-b9c1-b783cbaea68d',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: '0',
            y: '10',
            blur: '10',
            color: '#0000000f',
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'gradient',
            animated: false,
            primaryColor: '#fff8e6ff',
            secondaryColor: null,
          },
          animationIn: 'fade',
          borderColor: '#fbd38bff',
          animationOut: 'fade',
          borderRadius: 12,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2097161,
        lastStepChangeAt: '2024-07-01T14:52:42.000Z',
        createdAt: '2024-07-01T14:50:36.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-07-08T13:43:26.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        querySelectorManual: null,
        boostedPositionFlags: 32,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;22;default',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'SNIPPET',
        deviceFlags: 1,
        priority: 0,
        slackChannelFlags: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '839a9702-bac6-4d64-8b9b-ad7bb7946ed1',
        boostFlags: 4,
        steps: [
          {
            uid: 'b1651c91-c0aa-4d07-a0c0-c5211f78d6e7',
            style: {
              width: 400,
              height: null,
              margin: 0,
              shadow: {
                x: 0,
                y: 0,
                blur: 0,
                color: null,
                opacity: 0,
              },
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-07-01T14:50:36.000Z',
            mazeUrl: null,
            stepFlags: 0,
            removed: false,
            updateData: {
              date: '2024-07-08T10:09:51.015Z',
              operation: 'modified',
            },
            endSurvey: false,
            blocks: [
              {
                uid: '45a897ee-360f-42de-a6fc-c6cc1f501e91',
                type: 'LABEL',
                value: '⚠️ Warning message',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 6,
                  fontSize: 14,
                  fontColor: '#ef8734ff',
                  fontFamily: 'Inter',
                  fontWeight: '600',
                  borderColor: '#f9dbaaff',
                  borderRadius: 8,
                  primaryColor: null,
                },
                createdAt: '2024-07-08T09:39:10.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: '84020484-1e01-4c81-8cfc-72c89995edc1',
                type: 'PRIMARY_CTA',
                value: 'continue;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                  borderColor: '#FFFFFF',
                  borderRadius: 8,
                  primaryColor: '#FFFFFF',
                },
                createdAt: '2024-07-01T14:50:36.000Z',
                removed: true,
                updateData: null,
                file: null,
              },
              {
                uid: 'd5511a36-b509-4781-a631-d8a1ca243390',
                type: 'BODY',
                value:
                  '<p><strong>Suspicious activity detected on your account</strong></p><p><strong></strong></p><p>Please verify your recent actions and change your password immediately. Contact support if you need assistance. </p>',
                rawValue: [
                  {
                    type: 'p',
                    children: [
                      {
                        bold: true,
                        text: 'Suspicious activity detected on your account',
                      },
                    ],
                  },
                  {
                    type: 'p',
                    children: [
                      {
                        bold: true,
                        text: '',
                      },
                    ],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Please verify your recent actions and change your password immediately. Contact support if you need assistance. ',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 14,
                  fontColor: '#000000ff',
                  fontFamily: 'Inter',
                  fontWeight: '300',
                },
                createdAt: '2024-07-01T14:50:36.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: null,
  },
  {
    experienceType: 'TOUR',
    id: '9dbf1051-82b2-49cd-b8ac-291009e04f2d',
    image: SafetyAndSecurity,
    tags: ['Security'],
    type: 'TOUR',
    title: 'Log out warning',
    description: '',
    tourSteps: [
      {
        uid: '248de4f8-a062-4786-9523-46d2e36ee613',
        style: {
          margin: 0,
          shadow: {
            x: '0',
            y: '8',
            blur: '16',
            color: '#0000000f',
          },
          overlay: '#0000004d',
          background: {
            type: 'color',
            animated: true,
            primaryColor: '#ffffffff',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          borderColor: '#00000014',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 9,
        lastStepChangeAt: '2024-07-01T14:50:53.000Z',
        createdAt: '2024-07-01T14:47:29.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-07-08T13:51:23.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector:
          '#popup-1 > .dropdown-content > .menu > a > .logout-btn',
        querySelectorManual: null,
        boostedPositionFlags: 16,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://testing.i.usejimo.com/events/new',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;pulse',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'HOTSPOT',
        deviceFlags: 1,
        priority: 0,
        slackChannelFlags: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        parentTourId: '9dbf1051-82b2-49cd-b8ac-291009e04f2d',
        boostFlags: 8,
        steps: [
          {
            uid: '96ebe4aa-60e5-4bea-ad5b-c7ef5843880c',
            style: {
              width: 360,
              height: null,
              margin: 0,
              shadow: {
                x: 0,
                y: 0,
                blur: 0,
                color: null,
                opacity: 0,
              },
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'With label',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-07-01T14:47:29.000Z',
            mazeUrl: null,
            stepFlags: 0,
            removed: false,
            updateData: {
              date: '2024-07-08T10:08:24.841Z',
              operation: 'modified',
            },
            endSurvey: false,
            blocks: [
              {
                uid: '00181aaa-b46e-4662-bd48-b026d42ecce5',
                type: 'LABEL',
                value: 'Safety & Security',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#ff0000ff',
                  fontFamily: 'Inter',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 12,
                  primaryColor: '#da31311a',
                },
                createdAt: '2024-07-01T14:47:31.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: '23dc644f-b43d-4d30-9a8b-9014cdafeb2d',
                type: 'BODY',
                value:
                  '<p>To maintain the security of your account, always <u>remember to lock your session</u> or <u>log out</u> when you&apos;re done. </p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'To maintain the security of your account, always ',
                      },
                      {
                        text: 'remember to lock your session',
                        underline: true,
                      },
                      {
                        text: ' or ',
                      },
                      {
                        text: 'log out',
                        underline: true,
                      },
                      {
                        text: " when you're done. ",
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#000000ff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-07-01T14:47:31.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: '4ba8536b-93f4-42a0-9b32-d330ee55ee6f',
                type: 'PRIMARY_CTA',
                value: 'Understood;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#000000ff',
                  fontFamily: 'Inter',
                  fontWeight: '600',
                  borderColor: '#0000001f',
                  borderRadius: 14,
                  primaryColor: '#ffffffff',
                },
                createdAt: '2024-07-01T14:48:02.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: 'c275edd0-5ba0-422f-a0d2-04b0dfc12891',
                type: 'SECONDARY_CTA',
                value: 'back;step-prev;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                  borderColor: '#FFFFFF',
                  borderRadius: 8,
                  primaryColor: '#FFFFFF',
                },
                createdAt: '2024-07-01T14:48:06.000Z',
                removed: true,
                updateData: null,
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: null,
  },

  {
    experienceType: 'BANNER',
    id: '062248bc-a371-4d0d-9596-d70172325970',
    image: GiveUsFeedback,
    tags: ['Collect Feedback'],
    type: 'TOUR',
    title: 'We Want Your Feedback! 📝',
    description: '',
    tourSteps: [
      {
        uid: '0ca93f31-6980-4f53-9d6d-5afb25b15803',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: '0',
            y: '8',
            blur: '16',
            color: '#0000000f',
          },
          overlay: '#0000004d',
          background: {
            type: 'color',
            animated: true,
            primaryColor: '#ffffffff',
            secondaryColor: '#ffffffff',
          },
          hintOffset: 4,
          animationIn: 'fade',
          borderColor: '#00000014',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2359305,
        lastStepChangeAt: '2024-07-12T15:30:31.000Z',
        createdAt: '2024-07-12T15:20:00.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-08-05T13:56:45.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        querySelectorManual: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;24;default;pulse',
        boostedSize: null,
        boostedAnimations: 'fade-in;fade-out',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'BANNER',
        deviceFlags: 1,
        priority: 0,
        slackChannelFlags: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        projectId: 'db9b944c-4075-42b1-ba71-676050ebd188',
        parentTourId: '062248bc-a371-4d0d-9596-d70172325970',
        boostFlags: 1,
        steps: [
          {
            uid: '21bf5d27-20da-46ed-b246-506410a597ff',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {
                x: 0,
                y: 0,
                blur: 0,
                color: null,
                opacity: 0,
              },
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-07-12T15:20:00.000Z',
            mazeUrl: null,
            stepFlags: 0,
            removed: false,
            updateData: {
              date: '2024-07-19T15:13:48.998Z',
              operation: 'modified',
            },
            endSurvey: false,
            blocks: [
              {
                uid: 'aa2799bb-6fc1-42a9-aba8-ec127b3227d2',
                type: 'TITLE',
                value:
                  '📝 Take a quick survey and help us improve your experience. Your input makes a difference!|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-07-12T15:21:25.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: 'db648bf7-9450-4c6b-b6a2-fb33ce076c26',
                type: 'PRIMARY_CTA',
                value: 'Take the Survey Now!;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 12,
                  fontSize: 14,
                  fontColor: '#ffffffff',
                  fontFamily: 'Inter',
                  fontWeight: '600',
                  borderColor: '#0000001f',
                  borderRadius: 14,
                  primaryColor: '#0a1641ff',
                },
                createdAt: '2024-07-12T15:20:00.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {
        x: '0',
        y: '8',
        blur: '16',
        color: '#0000000f',
      },
      overlay: '#0000004d',
      background: {
        type: 'color',
        animated: true,
        primaryColor: '#ffffffff',
        secondaryColor: '#ffffffff',
      },
      hintOffset: 4,
      animationIn: 'fade',
      borderColor: '#00000014',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'HINT',
    id: '0eea78a6-aeab-4d4a-8c68-0d55baaca93c',
    image: NeedHelpCheckOut,
    tags: [],
    type: 'TOUR',
    title: 'Learning Resource Hint - Documentation Link',
    description: '',
    tourSteps: [
      {
        uid: '592767f6-d9d3-4235-a28c-865b00a7203a',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: '1',
            y: '2',
            blur: '12',
            color: '#00000026',
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          hintOffset: 4,
          animationIn: 'fade',
          borderColor: '#00000014',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2496521,
        lastStepChangeAt: '2024-07-19T17:20:12.000Z',
        createdAt: '2024-07-19T17:17:04.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-07-30T13:40:09.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector:
          '.max-w-62\\.5 > .items-center > .flex > .mt-0\\.5:nth-child(3) > .py-2\\.7',
        querySelectorManual: null,
        boostedPositionFlags: 8,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://templates.usejimo.com/',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;22;default',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        deviceFlags: 1,
        priority: 0,
        slackChannelFlags: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        projectId: 'db9b944c-4075-42b1-ba71-676050ebd188',
        parentTourId: '0eea78a6-aeab-4d4a-8c68-0d55baaca93c',
        boostFlags: 128,
        steps: [
          {
            uid: '42db113c-137c-4166-b1f9-ca2171c12183',
            style: {
              width: 400,
              height: 200,
              margin: 0,
              shadow: {
                x: 0,
                y: 0,
                blur: 0,
                color: null,
                opacity: 0,
              },
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'Tooltip with title',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-07-19T17:17:04.000Z',
            mazeUrl: null,
            stepFlags: 0,
            removed: false,
            updateData: {
              date: '2024-07-30T13:40:08.651Z',
              operation: 'modified',
            },
            endSurvey: false,
            blocks: [
              {
                uid: '2b651157-08de-4c4d-9104-d6c227c0ee6b',
                type: 'HINT',
                value: 'New',
                rawValue: null,
                flags: 0,
                style: {
                  0: 'N',
                  1: 'e',
                  2: 'w',
                  type: 'icon',
                  color: '#FFFFFF',
                  iconUrl: null,
                  offsetX: -11,
                  offsetY: 27,
                  padding: 8,
                  fontSize: 20,
                  iconName: 'error',
                  iconColor: '#000000ff',
                  pulsating: true,
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  iconSource: 'built-in',
                  borderColor: null,
                  borderRadius: 16,
                  verticalAlign: 'top',
                  backgroundColor: '#FF4646',
                  horizontalAlign: 'right',
                },
                createdAt: '2024-07-19T17:17:15.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: '30436457-530e-4606-aa11-345c92949657',
                type: 'TITLE',
                value: 'Need Help? Check This Out! 📚|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 18,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-07-19T17:17:15.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: 'e96e56b5-a34d-4ce2-b0fd-e551d08391da',
                type: 'BODY',
                value:
                  '<p>Here&#x2019;s a handy link to our documentation that should help you out with [specific feature]. </p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Here’s a handy link to our documentation that should help you out with [specific feature]. ',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 16,
                  fontColor: '#6b6767ff',
                  fontFamily: 'Inter',
                  fontWeight: '400',
                },
                createdAt: '2024-07-19T17:17:15.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: 'f2ea89d1-20c3-49df-a184-1b1a35253042',
                type: 'PRIMARY_CTA',
                value: 'View Documentation;none;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 8,
                  fontSize: 16,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                  borderColor: '#00000029',
                  borderRadius: 8,
                  primaryColor: '#FFFFFF',
                },
                createdAt: '2024-07-19T17:18:19.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: null,
  },
  {
    experienceType: 'SURVEY',
    id: '1df4c18b-0d68-4c09-b421-fe2b6eb5cc7b',
    image: WhyAreYouLeaving,
    tags: ['Customer Satisfaction', 'Collect Feedback'],
    type: 'MODAL',
    title: 'Churn Prevention Survey',
    description:
      'Identify reasons why users might cancel their subscription and gather suggestions to prevent churn.',
    tourSteps: [],
    steps: [
      {
        uid: '1386e29b-81eb-4474-9a12-ea9ba29cdc2c',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step success 1',
        question: null,
        additionalInformation: null,
        type: 'SUCCESS',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 4,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-07-19T16:56:55.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        updateData: {
          date: '2024-07-30T13:59:12.475Z',
          operation: 'modified',
        },
        endSurvey: false,
        blocks: [
          {
            uid: '509d3ae8-2be7-400c-8e27-49eb8414a414',
            type: 'SECONDARY_CTA',
            value: 'End Survey;close;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderRadius: 24,
              primaryColor: '#4950731a',
            },
            createdAt: '2024-07-19T17:04:41.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: '51548a3d-61f9-4fea-bcbc-5641cb2399fd',
            type: 'PRIMARY_CTA',
            value: 'Book a call;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-07-19T16:56:55.000Z',
            removed: true,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'ea609a8c-6dde-4173-af95-3d8b1ddc111a',
            type: 'TITLE',
            value: '<p>Thank you for your input! &#x1f929;</p>|-|none|-|',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Thank you for your input! 🤩',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-07-19T16:56:55.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'fe3151ea-aab7-45ce-b60b-a553609530c4',
            type: 'ANIMATION',
            value: 'confetti',
            rawValue: null,
            flags: 0,
            style: null,
            createdAt: '2024-07-19T16:56:55.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '1b715ffa-1757-48bd-8212-df644c9a685d',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-07-19T16:46:48.000Z',
        mazeUrl: null,
        stepFlags: 4,
        removed: false,
        updateData: {
          date: '2024-07-19T17:01:31.946Z',
          operation: 'modified',
        },
        endSurvey: false,
        blocks: [
          {
            uid: '7d7214ac-575f-4c27-aba1-8c862b2c79c9',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
              primaryColor: '#d4d4de4d',
            },
            createdAt: '2024-07-19T16:46:48.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'ccea8f32-fbbd-45df-9fa8-bf09673159c9',
            type: 'PRIMARY_CTA',
            value: 'Continue;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-07-19T16:46:48.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'd345bca5-31bf-4ebd-a758-e62e11f259c0',
            type: 'TITLE',
            value: 'Other (please specify)|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-07-19T16:46:48.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '5d378125-ecb5-47c2-9fb7-072c2b4cf3f3',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 2',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 3,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-07-19T16:54:16.000Z',
        mazeUrl: null,
        stepFlags: 4,
        removed: false,
        updateData: {
          date: '2024-07-19T16:59:39.420Z',
          operation: 'modified',
        },
        endSurvey: false,
        blocks: [
          {
            uid: '9c2adcbe-cf54-4d8e-b604-178c952cdfd6',
            type: 'TITLE',
            value:
              'What can we do to improve your experience and keep you with us?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-07-19T16:54:17.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'a991a42a-543c-446e-8495-48dd9a392dbb',
            type: 'PRIMARY_CTA',
            value: 'Continue;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-07-19T16:54:17.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'b2085222-baf8-400e-b1c6-eac3b761c7fb',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 8,
              primaryColor: '#d4d4de4d',
            },
            createdAt: '2024-07-19T16:54:17.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'a82c3916-dbe7-4784-8480-a7218be564be',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step text 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_BLOCK',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-07-19T16:39:09.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        updateData: {
          date: '2024-07-30T13:55:29.335Z',
          operation: 'modified',
        },
        endSurvey: false,
        blocks: [
          {
            uid: '5e4685e4-b70f-4b29-98ac-3e8f2d5c75b0',
            type: 'BODY',
            value:
              '<p>Hi there! </p><p></p><p>We noticed you&#x2019;re thinking about canceling your subscription. &#x2639;&#xfe0f;</p><p></p><p><strong>Your feedback is super important</strong> to us, and we&#x2019;d love to know how we can make things better for you. </p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Hi there! ',
                  },
                ],
              },
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: '',
                  },
                ],
              },
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'We noticed you’re thinking about canceling your subscription. ☹️',
                  },
                ],
              },
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: '',
                  },
                ],
              },
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    bold: true,
                    text: 'Your feedback is super important',
                  },
                  {
                    text: ' to us, and we’d love to know how we can make things better for you. ',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-07-19T16:39:09.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: '86bb9249-e7c3-4909-b354-bc25aa34ad6c',
            type: 'TITLE',
            value: '<p>Why are you leaving? &#x1f494;</p>|-|none|-|',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Why are you leaving? 💔',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-07-19T16:39:09.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: '9a79492e-27ff-438d-bb59-b542df1515ac',
            type: 'PRIMARY_CTA',
            value: 'Share Your Feedback;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-07-19T16:39:09.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'fe46706f-ad08-4942-81bf-9742476cc166',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step multiple choice 1',
        question: null,
        additionalInformation: null,
        type: 'MULTIPLE_CHOICE_MULTI_SELECT',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-07-19T16:44:40.000Z',
        mazeUrl: null,
        stepFlags: 4,
        removed: false,
        updateData: {
          date: '2024-08-05T14:13:52.179Z',
          operation: 'modified',
        },
        endSurvey: false,
        blocks: [
          {
            uid: '25184198-8104-46a1-83fa-e881eda9c5fd',
            type: 'PRIMARY_CTA',
            value: 'Continue;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-07-19T16:44:40.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'a7f62a81-259f-428b-a9ab-8076018047c6',
            type: 'CHOICE',
            value: null,
            rawValue: null,
            flags: 2,
            style: {
              padding: 12,
              fontSize: 14,
              fontFamily: 'Inter',
              disposition: 'block',
              borderRadius: 12,
              primaryColor: '#dad3d352',
              withCheckbox: false,
            },
            createdAt: '2024-07-19T16:44:40.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [
              {
                uid: '10f18378-5a1f-43f5-aeaa-edc5681730ef',
                content: 'Other',
                indexOrder: 4,
              },
              {
                uid: '9e39174d-abcc-4991-982a-2ae891eb3c85',
                content: 'Features',
                indexOrder: 1,
              },
              {
                uid: 'a4b2ecb8-9e03-464e-a3f4-90d1ede5b231',
                content: 'User Experience',
                indexOrder: 3,
              },
              {
                uid: 'e5ac2f88-e9dc-4878-8d56-2334b249850a',
                content: 'Customer Service',
                indexOrder: 2,
              },
              {
                uid: 'f9477aea-5c83-4482-b122-b4e2efd40cec',
                content: 'Pricing',
                indexOrder: 0,
              },
            ],
          },
          {
            uid: 'fc5afc1e-1e19-4c10-b2b8-768f217738e6',
            type: 'TITLE',
            value:
              'Why are you considering canceling your subscription?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-07-19T16:44:40.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
        ],
        goTo: {
          uid: '82673254-19f5-4c10-bff4-e330b17730bd',
          style: {
            width: null,
            height: null,
            margin: 0,
            shadow: {
              x: 0,
              y: 0,
              blur: 0,
              color: null,
              opacity: 0,
            },
            overlay: null,
            background: {
              type: 'color',
              animated: false,
              primaryColor: '#FFFFFF',
              secondaryColor: null,
            },
            animationIn: 'fade',
            animationOut: 'fade',
            borderRadius: 0,
            withElementsAnimations: true,
          },
          name: 'Step slider 1',
          question: null,
          additionalInformation: null,
          type: 'SLIDER',
          mdContent: null,
          mdRawContent: null,
          indexOrder: 3,
          ctaLabel: null,
          ctaColor: null,
          ctaBackgroundColor: null,
          ctaBorderRadius: null,
          ctaUrl: null,
          ctaAction: null,
          ctaFontSize: null,
          interactiveLink: null,
          conceptThumbnailUrl: null,
          createdAt: '2024-07-19T16:48:37.000Z',
          mazeUrl: null,
          stepFlags: 0,
          removed: false,
          updateData: {
            date: '2024-07-19T16:59:39.420Z',
            operation: 'modified',
          },
          endSurvey: false,
        },
        jumps: [
          {
            uid: 'e25eec8d-8934-424e-a47c-9f7f5d54658c',
            operator: 'EQUAL_TO',
            conditionOperator: 'AT_LEAST_ONE_OF',
            value: null,
            endSurvey: false,
            goTo: {
              uid: '1b715ffa-1757-48bd-8212-df644c9a685d',
              style: {
                width: null,
                height: null,
                margin: 0,
                shadow: {
                  x: 0,
                  y: 0,
                  blur: 0,
                  color: null,
                  opacity: 0,
                },
                overlay: null,
                background: {
                  type: 'color',
                  animated: false,
                  primaryColor: '#FFFFFF',
                  secondaryColor: null,
                },
                animationIn: 'fade',
                animationOut: 'fade',
                borderRadius: 0,
                withElementsAnimations: true,
              },
              name: 'Step open question 1',
              question: null,
              additionalInformation: null,
              type: 'TEXT_LONG',
              mdContent: null,
              mdRawContent: null,
              indexOrder: 2,
              ctaLabel: null,
              ctaColor: null,
              ctaBackgroundColor: null,
              ctaBorderRadius: null,
              ctaUrl: null,
              ctaAction: null,
              ctaFontSize: null,
              interactiveLink: null,
              conceptThumbnailUrl: null,
              createdAt: '2024-07-19T16:46:48.000Z',
              mazeUrl: null,
              stepFlags: 4,
              removed: false,
              updateData: {
                date: '2024-07-19T17:01:31.946Z',
                operation: 'modified',
              },
              endSurvey: false,
            },
            optionValues: [
              {
                uid: '10f18378-5a1f-43f5-aeaa-edc5681730ef',
                content: 'Other',
                indexOrder: 4,
              },
            ],
          },
        ],
        prototypes: [],
      },
    ],
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '0',
        y: '0',
        blur: '0',
        color: '#316eda00',
      },
      overlay: null,
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'slide-left',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'BANNER',
    id: '295cd154-54fa-42e2-ada2-fa05ec86d6a8',
    image: InviteToEarnRewards,
    tags: ['Referral'],
    type: 'TOUR',
    title: 'Invite Your Friends and Earn Rewards! 🎉',
    description: '',
    tourSteps: [
      {
        uid: 'f07483f0-dc38-4a13-8159-341f8015cc84',
        style: {
          margin: 12,
          shadow: {
            x: '0',
            y: '0',
            blur: '0',
            color: '#316eda00',
          },
          overlay: '#0000004d',
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#ff8fffe6',
            secondaryColor: '#ffffffff',
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 12,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2359305,
        lastStepChangeAt: '2024-07-12T15:17:43.000Z',
        createdAt: '2024-07-12T15:11:07.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-08-05T13:56:03.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        querySelectorManual: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;22;default',
        boostedSize: null,
        boostedAnimations: 'fade-in;fade-out',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'BANNER',
        deviceFlags: 1,
        priority: 0,
        slackChannelFlags: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        projectId: 'db9b944c-4075-42b1-ba71-676050ebd188',
        parentTourId: '295cd154-54fa-42e2-ada2-fa05ec86d6a8',
        boostFlags: 1,
        steps: [
          {
            uid: '36f71bf9-3bf0-45a7-979c-f3ced99205d7',
            style: {
              width: null,
              height: 100,
              margin: 0,
              shadow: {
                x: 0,
                y: 0,
                blur: 0,
                color: null,
                opacity: 0,
              },
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'New Step',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-07-12T15:11:07.000Z',
            mazeUrl: null,
            stepFlags: 0,
            removed: false,
            updateData: {
              date: '2024-07-12T15:29:36.010Z',
              operation: 'modified',
            },
            endSurvey: false,
            blocks: [
              {
                uid: 'd6273bd5-219a-4bbf-b991-3ad8e4c7ebb3',
                type: 'PRIMARY_CTA',
                value:
                  'Invite Friends;step-next;url-open;https://yoursitearticle.com',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 7,
                  fontSize: 12,
                  fontColor: '#ffffffff',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#0f1835cc',
                },
                createdAt: '2024-07-12T15:11:07.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: 'f48c2a54-c4d8-438e-8f8b-d04656d6194f',
                type: 'BODY',
                value:
                  '<p><strong>Invite your friends</strong> to join us and <strong>earn exciting rewards</strong> for each successful referral.</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        bold: true,
                        text: 'Invite your friends',
                      },
                      {
                        text: ' to join us and ',
                      },
                      {
                        bold: true,
                        text: 'earn exciting rewards',
                      },
                      {
                        text: ' for each successful referral.',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 12,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-07-12T15:11:37.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: {
      margin: 0,
      shadow: {
        x: '0',
        y: '0',
        blur: '0',
        color: '#316eda00',
      },
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'HINT',
    id: '9c207ec2-aed0-49dd-a45a-fcfafa72fc99',
    image: WatchAndLearn,
    tags: [],
    type: 'TOUR',
    title: 'Learning Resource Hint - Demo Video',
    description: '',
    tourSteps: [
      {
        uid: 'f9a0bc86-0d44-4ffa-b5d4-01c17245196d',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: '1',
            y: '2',
            blur: '12',
            color: '#00000026',
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          hintOffset: 4,
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2496521,
        lastStepChangeAt: '2024-07-19T17:20:12.000Z',
        createdAt: '2024-07-19T17:21:09.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-08-05T13:59:35.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector:
          '.max-w-62\\.5 > .items-center > .flex > .mt-0\\.5:nth-child(2) > .py-2\\.7',
        querySelectorManual: null,
        boostedPositionFlags: 512,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;22;default',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        deviceFlags: 1,
        priority: 0,
        slackChannelFlags: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        projectId: 'db9b944c-4075-42b1-ba71-676050ebd188',
        parentTourId: '9c207ec2-aed0-49dd-a45a-fcfafa72fc99',
        boostFlags: 128,
        steps: [
          {
            uid: 'ac22bae4-d7a7-4810-92bb-68facf40ab74',
            style: {
              width: 400,
              height: 200,
              margin: 0,
              shadow: {
                x: 0,
                y: 0,
                blur: 0,
                color: null,
                opacity: 0,
              },
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'Tooltip with title',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-07-19T17:21:09.000Z',
            mazeUrl: null,
            stepFlags: 0,
            removed: false,
            updateData: {
              date: '2024-08-05T12:24:42.597Z',
              operation: 'modified',
            },
            endSurvey: false,
            blocks: [
              {
                uid: '1f335499-63d4-40cc-aa0d-5a4101e3aa59',
                type: 'MEDIA',
                value:
                  'video;https://www.youtube.com/watch?v=NFR0EBv35lU&pp=ygUMamltbyByYXBoYWVs;',
                rawValue: null,
                flags: 0,
                style: {
                  padding: 8,
                  position: 'bottom',
                  borderRadius: 8,
                },
                createdAt: '2024-07-19T17:22:16.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: '57d958e3-b358-42b5-834d-bf0e617cc923',
                type: 'TITLE',
                value: 'Watch and Learn! 🎥|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 20,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-07-19T17:21:09.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: '8b553062-0d63-4464-8427-72c2d7882429',
                type: 'HINT',
                value: 'New',
                rawValue: null,
                flags: 0,
                style: {
                  0: 'N',
                  1: 'e',
                  2: 'w',
                  type: 'icon',
                  color: '#FFFFFF',
                  iconUrl: null,
                  offsetX: -33,
                  offsetY: 25,
                  padding: 8,
                  fontSize: 20,
                  iconName: 'error',
                  iconColor: '#000000ff',
                  pulsating: true,
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  iconSource: 'built-in',
                  borderColor: null,
                  borderRadius: 16,
                  verticalAlign: 'top',
                  backgroundColor: '#FF4646',
                  horizontalAlign: 'right',
                },
                createdAt: '2024-07-19T17:21:09.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: '8cb65f81-8e8b-4bdc-8c42-0415ccffa5da',
                type: 'BODY',
                value:
                  '<p>Need a quick walkthrough? </p><p><strong>Check out this demo video</strong> that shows you exactly how to [use feature]. </p><p></p><p>It&#x2019;s a great way to see everything in action and get up to speed fast! </p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Need a quick walkthrough? ',
                      },
                    ],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        bold: true,
                        text: 'Check out this demo video',
                      },
                      {
                        text: ' that shows you exactly how to [use feature]. ',
                      },
                    ],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'It’s a great way to see everything in action and get up to speed fast! ',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 15,
                  fontColor: '#6b6767ff',
                  fontFamily: 'Inter',
                  fontWeight: '400',
                },
                createdAt: '2024-07-19T17:21:09.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: 'c41dc80a-7ad8-4e09-bf55-3f95e3a0551b',
                type: 'PRIMARY_CTA',
                value: 'View Documentation;none;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                  borderColor: '#121111b8',
                  borderRadius: 8,
                  primaryColor: '#FFFFFF',
                },
                createdAt: '2024-07-19T17:21:09.000Z',
                removed: true,
                updateData: null,
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: null,
  },
  {
    experienceType: 'HINT',
    id: 'bc6be20a-1ceb-4095-943d-e88bfe984b09',
    image: UnlockThisFeature,
    tags: [],
    type: 'TOUR',
    title: 'Unlock this feature!',
    description: '',
    tourSteps: [
      {
        uid: '18b2bc70-376d-456b-b312-ea0613890241',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#071331ff',
            secondaryColor: null,
          },
          hintOffset: 4,
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 4591625,
        lastStepChangeAt: '2024-07-12T15:48:58.000Z',
        createdAt: '2024-07-12T15:43:30.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-08-05T14:00:14.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector:
          '.menu-wrapper > .menu > .section > a > .menu-item-success-tracker',
        querySelectorManual: null,
        boostedPositionFlags: 8,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://testing.i.usejimo.com/tours',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;22;default',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        deviceFlags: 1,
        priority: 0,
        slackChannelFlags: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        projectId: 'db9b944c-4075-42b1-ba71-676050ebd188',
        parentTourId: 'bc6be20a-1ceb-4095-943d-e88bfe984b09',
        boostFlags: 128,
        steps: [
          {
            uid: 'ee7237ff-511b-46be-aca8-7d5641d90c1e',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {
                x: 0,
                y: 0,
                blur: 0,
                color: null,
                opacity: 0,
              },
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'Dark mode tooltip with title',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-07-12T15:43:30.000Z',
            mazeUrl: null,
            stepFlags: 0,
            removed: false,
            updateData: {
              date: '2024-07-16T07:42:50.297Z',
              operation: 'modified',
            },
            endSurvey: false,
            blocks: [
              {
                uid: '4fd0d8ca-867a-4896-89f0-c8bac1616e43',
                type: 'PRIMARY_CTA',
                value: 'Upgrade Now;none;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                  borderColor: '#FFFFFF',
                  borderRadius: 8,
                  primaryColor: '#FFFFFF',
                },
                createdAt: '2024-07-12T15:44:46.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: '7b1484b6-9ee2-40a0-aa32-f41e0ef477ae',
                type: 'BODY',
                value:
                  '<p>Upgrade to a higher-tier plan to access this feature!</p>',
                rawValue: [
                  {
                    type: 'p',
                    children: [
                      {
                        text: 'Upgrade to a higher-tier plan to access this feature!',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 15,
                  fontColor: '#b7b7b7ff',
                  fontFamily: 'Inter',
                  fontWeight: '400',
                },
                createdAt: '2024-07-12T15:43:34.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: '99655d00-390b-4f79-97e2-57a236dc54d0',
                type: 'TITLE',
                value: 'Unlock this Feature 🚀|-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 18,
                  fontColor: '#ffffffff',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-07-12T15:43:34.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: '9aca5d61-8570-44f0-ad6f-9a1f5649919b',
                type: 'HINT',
                value: 'New',
                rawValue: null,
                flags: 0,
                style: {
                  0: 'N',
                  1: 'e',
                  2: 'w',
                  type: 'icon',
                  color: '#FFFFFF',
                  iconUrl:
                    'https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/76c37929ea11fec5c9199906addab334-46GP9IH8uPh3.webp',
                  offsetX: -18,
                  offsetY: 22,
                  padding: 8,
                  fontSize: 20,
                  iconName: null,
                  iconColor: '#000000ff',
                  pulsating: false,
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  iconSource: 'custom',
                  borderColor: null,
                  borderRadius: 16,
                  verticalAlign: 'top',
                  backgroundColor: '#FF4646',
                  horizontalAlign: 'right',
                },
                createdAt: '2024-07-12T15:43:34.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: null,
  },
  {
    experienceType: 'BANNER',
    id: 'cfa43c0d-e64b-4ec9-b01a-99828167ee30',
    image: CongratsOnMilestone,
    tags: ['Celebration'],
    type: 'TOUR',
    title: 'Congratulate Achievements! 👏',
    description: '',
    tourSteps: [
      {
        uid: '83ff22e2-c9e7-462d-bf63-6b9102157382',
        style: {
          width: null,
          height: null,
          margin: 6,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'gradient',
            animated: true,
            primaryColor: '#ffffffff',
            secondaryColor: '#fffaecff',
          },
          animationIn: 'fade',
          borderColor: '#ffe697ff',
          animationOut: 'fade',
          borderRadius: 8,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 2359305,
        lastStepChangeAt: '2024-07-12T15:40:35.000Z',
        createdAt: '2024-07-12T15:36:26.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-08-05T13:57:40.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector: null,
        querySelectorManual: null,
        boostedPositionFlags: 64,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: '',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;22;default',
        boostedSize: null,
        boostedAnimations: 'fade-in;fade-out',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        type: 'BANNER',
        deviceFlags: 1,
        priority: 0,
        slackChannelFlags: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        projectId: 'db9b944c-4075-42b1-ba71-676050ebd188',
        parentTourId: 'cfa43c0d-e64b-4ec9-b01a-99828167ee30',
        boostFlags: 1,
        steps: [
          {
            uid: 'e353d67d-96bb-4aba-b961-6cdc87678ee9',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {
                x: 0,
                y: 0,
                blur: 0,
                color: null,
                opacity: 0,
              },
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'Banner',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-07-12T15:36:26.000Z',
            mazeUrl: null,
            stepFlags: 0,
            removed: false,
            updateData: {
              date: '2024-07-12T16:13:26.852Z',
              operation: 'modified',
            },
            endSurvey: false,
            blocks: [
              {
                uid: '73208597-c471-4e7f-9708-5a7240512049',
                type: 'LABEL',
                value: '👏',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 0,
                  fontSize: 20,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                  fontWeight: '700',
                  borderColor: null,
                  borderRadius: 10,
                  primaryColor: null,
                },
                createdAt: '2024-07-12T15:37:13.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: 'ee3c5a91-f52f-4fe9-ab87-af6127f145c3',
                type: 'TITLE',
                value:
                  'Woohoo! You Nailed It! Congrats on hitting this awesome milestone. |-|none|-|',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 15,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                  fontWeight: '500',
                },
                createdAt: '2024-07-12T15:36:26.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: 'fdc36487-1fa8-4182-baf1-8875b71ef964',
                type: 'PRIMARY_CTA',
                value: 'continue;step-next;none;',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'left',
                  padding: 8,
                  fontSize: 14,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                  borderColor: '#FFFFFF',
                  borderRadius: 8,
                  primaryColor: '#FFFFFF',
                },
                createdAt: '2024-07-12T15:36:26.000Z',
                removed: true,
                updateData: null,
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: null,
  },
  {
    experienceType: 'SURVEY',
    id: 'e91fa4db-88f8-47cc-be6e-042b2eb48cfc',
    image: ExcitingNewFeatures,
    tags: ['Product Insight', 'Collect Feedback', 'Survey'],
    type: 'SNIPPET',
    title: 'Pre-Launch Interest Survey',
    description:
      'Gauge user interest in upcoming features or products before launch. Use this survey to collect valuable feedback and insights from your users to shape the final product.',
    tourSteps: [],
    steps: [
      {
        uid: '0169c580-ffef-4096-a63b-6e9bfcc321ee',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step multiple choice 2',
        question: null,
        additionalInformation: null,
        type: 'MULTIPLE_CHOICE_MULTI_SELECT',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 3,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-07-29T18:01:20.000Z',
        mazeUrl: null,
        stepFlags: 4,
        removed: false,
        updateData: {
          date: '2024-08-05T19:22:26.341Z',
          operation: 'modified',
        },
        endSurvey: false,
        blocks: [
          {
            uid: '0801f5eb-8434-4227-9739-06a0a8656910',
            type: 'TITLE',
            value:
              '<p>Would you be interested in participating in a Beta test? </p>|-|none|-|',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Would you be interested in participating in a Beta test? ',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-07-29T18:01:21.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'c16e4713-d9bf-46f4-83d4-20b0fa7d6cc6',
            type: 'CHOICE',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              padding: 12,
              fontSize: 14,
              fontFamily: 'Inter',
              disposition: 'block',
              borderRadius: 12,
              primaryColor: '#dad3d352',
              withCheckbox: false,
            },
            createdAt: '2024-07-29T18:01:21.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [
              {
                uid: 'aa1718a6-5c19-4242-bd85-a41947d6a08f',
                content: 'No  ❌',
                indexOrder: 1,
              },
              {
                uid: 'cf0bafb2-ecbc-494c-86b9-9e195dbc61ee',
                content: 'Yes  ✔️ ',
                indexOrder: 0,
              },
            ],
          },
          {
            uid: 'e2585dc9-5cff-414e-9cb5-5b61ac51d3e3',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0f1e25ff',
            },
            createdAt: '2024-07-29T18:01:21.000Z',
            removed: true,
            updateData: null,
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: '3309ab8e-7838-443b-8b61-29173c04a2e9',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step multiple choice 1',
        question: null,
        additionalInformation: null,
        type: 'MULTIPLE_CHOICE_MULTI_SELECT',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-07-29T17:53:39.000Z',
        mazeUrl: null,
        stepFlags: 4,
        removed: false,
        updateData: {
          date: '2024-08-07T14:39:21.337Z',
          operation: 'modified',
        },
        endSurvey: false,
        blocks: [
          {
            uid: '0c47e492-c70b-4b60-905e-9ab3310f0b49',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0f1e25ff',
            },
            createdAt: '2024-07-29T17:53:39.000Z',
            removed: true,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: '2cddd8ec-d37f-48d2-b0d5-68daa198fbe3',
            type: 'CHOICE',
            value: null,
            rawValue: null,
            flags: 0,
            style: {
              padding: 12,
              fontSize: 14,
              fontFamily: 'Inter',
              disposition: 'block',
              borderRadius: 12,
              primaryColor: '#dad3d352',
              withCheckbox: false,
            },
            createdAt: '2024-07-29T17:53:39.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [
              {
                uid: '49a6f206-3476-419a-bf73-039051ac2a7f',
                content: 'Feature C',
                indexOrder: 2,
              },
              {
                uid: '5f161825-6161-4631-8652-d278a8f30f72',
                content: 'Other',
                indexOrder: 3,
              },
              {
                uid: 'b1e841ec-e727-4069-a36b-cc2d0aadf43f',
                content: 'Feature B',
                indexOrder: 1,
              },
              {
                uid: 'f12329cb-95ce-4b6d-8b51-f176834444c3',
                content: 'Feature A',
                indexOrder: 0,
              },
            ],
          },
          {
            uid: '5f972521-3728-4282-a933-70942b56f444',
            type: 'TITLE',
            value:
              '<p>What upcoming feature are you most excited about? </p>|-|none|-|',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'What upcoming feature are you most excited about? ',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-07-29T17:53:39.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
        ],
        goTo: {
          uid: '0169c580-ffef-4096-a63b-6e9bfcc321ee',
          style: {
            width: null,
            height: null,
            margin: 0,
            shadow: {
              x: 0,
              y: 0,
              blur: 0,
              color: null,
              opacity: 0,
            },
            overlay: null,
            background: {
              type: 'color',
              animated: false,
              primaryColor: '#FFFFFF',
              secondaryColor: null,
            },
            animationIn: 'fade',
            animationOut: 'fade',
            borderRadius: 0,
            withElementsAnimations: true,
          },
          name: 'Step multiple choice 2',
          question: null,
          additionalInformation: null,
          type: 'MULTIPLE_CHOICE_MULTI_SELECT',
          mdContent: null,
          mdRawContent: null,
          indexOrder: 3,
          ctaLabel: null,
          ctaColor: null,
          ctaBackgroundColor: null,
          ctaBorderRadius: null,
          ctaUrl: null,
          ctaAction: null,
          ctaFontSize: null,
          interactiveLink: null,
          conceptThumbnailUrl: null,
          createdAt: '2024-07-29T18:01:20.000Z',
          mazeUrl: null,
          stepFlags: 4,
          removed: false,
          updateData: {
            date: '2024-08-05T19:22:26.341Z',
            operation: 'modified',
          },
          endSurvey: false,
        },
        jumps: [
          {
            uid: 'cc3d272c-f4a9-4e29-a2d8-df1551ee1476',
            operator: 'EQUAL_TO',
            conditionOperator: 'ALL',
            value: null,
            endSurvey: false,
            goTo: {
              uid: '37f7d10d-5117-40e0-90ab-327f1d629eb2',
              style: {
                width: null,
                height: null,
                margin: 0,
                shadow: {
                  x: 0,
                  y: 0,
                  blur: 0,
                  color: null,
                  opacity: 0,
                },
                overlay: null,
                background: {
                  type: 'color',
                  animated: false,
                  primaryColor: '#FFFFFF',
                  secondaryColor: null,
                },
                animationIn: 'fade',
                animationOut: 'fade',
                borderRadius: 0,
                withElementsAnimations: true,
              },
              name: 'Step open question 1',
              question: null,
              additionalInformation: null,
              type: 'TEXT_LONG',
              mdContent: null,
              mdRawContent: null,
              indexOrder: 2,
              ctaLabel: null,
              ctaColor: null,
              ctaBackgroundColor: null,
              ctaBorderRadius: null,
              ctaUrl: null,
              ctaAction: null,
              ctaFontSize: null,
              interactiveLink: null,
              conceptThumbnailUrl: null,
              createdAt: '2024-07-29T17:57:18.000Z',
              mazeUrl: null,
              stepFlags: 4,
              removed: false,
              updateData: {
                date: '2024-08-05T19:22:19.911Z',
                operation: 'modified',
              },
              endSurvey: false,
            },
            optionValues: [
              {
                uid: '5f161825-6161-4631-8652-d278a8f30f72',
                content: 'Other',
                indexOrder: 3,
              },
            ],
          },
        ],
        prototypes: [],
      },
      {
        uid: '37f7d10d-5117-40e0-90ab-327f1d629eb2',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-07-29T17:57:18.000Z',
        mazeUrl: null,
        stepFlags: 4,
        removed: false,
        updateData: {
          date: '2024-08-05T19:22:19.911Z',
          operation: 'modified',
        },
        endSurvey: false,
        blocks: [
          {
            uid: '1e3d34b5-2491-4c33-adab-55e388596075',
            type: 'TITLE',
            value: '<p>Other (please specify) </p>|-|none|-|',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Other (please specify) ',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-07-29T17:57:18.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'a821b79a-6bcc-45b0-83bb-dc85c5f89c03',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
              primaryColor: '#FFFFFF',
            },
            createdAt: '2024-07-29T17:57:18.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'da475ab2-0d6e-4da2-95e4-27379dd7f4a9',
            type: 'PRIMARY_CTA',
            value: 'Next;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0f1e25ff',
            },
            createdAt: '2024-07-29T17:57:18.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'b085f649-ce9d-4a45-b6b1-5e34fe5f88cd',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step text 1',
        question: null,
        additionalInformation: null,
        type: 'TEXT_BLOCK',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-07-29T17:48:47.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        updateData: {
          date: '2024-07-30T13:47:44.926Z',
          operation: 'modified',
        },
        endSurvey: false,
        blocks: [
          {
            uid: '95856fb1-f7d1-496e-a259-6ae2a70e8285',
            type: 'TITLE',
            value:
              '<p>Exciting New Features Coming Soon! &#x1f680; </p>|-|none|-|',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Exciting New Features Coming Soon! 🚀 ',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-07-29T17:48:47.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'a724454d-4f5c-44a2-a54f-f431a7e1dcce',
            type: 'PRIMARY_CTA',
            value: "Let's Go!;step-next;none;",
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0f1e25ff',
            },
            createdAt: '2024-07-29T17:48:47.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'd070a952-4e74-4863-a58d-d2ec71459b9e',
            type: 'SECONDARY_CTA',
            value: 'Later;close;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 14,
              fontColor: '#000000',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: '#FFFFFF',
              borderRadius: 12,
              primaryColor: '#edededff',
            },
            createdAt: '2024-07-29T17:51:04.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'e193397e-13f4-4996-a252-f0aa7f9161c1',
            type: 'BODY',
            value:
              '<p><strong>This will take less than two minutes! </strong></p><p></p><p>We&#x2019;re working on some cool new stuff, mind giving us your feedback? This will help us ensure we&apos;re building something you&apos;ll love. </p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    bold: true,
                    text: 'This will take less than two minutes! ',
                  },
                ],
              },
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: '',
                  },
                ],
              },
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: "We’re working on some cool new stuff, mind giving us your feedback? This will help us ensure we're building something you'll love. ",
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 14,
              fontColor: '#3e3e3eff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-07-29T17:48:47.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
      {
        uid: 'f67e05f1-83f8-43a1-890f-762afde9c7ca',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Step open question 3',
        question: null,
        additionalInformation: null,
        type: 'TEXT_LONG',
        mdContent: null,
        mdRawContent: null,
        indexOrder: 4,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-07-29T18:03:18.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        updateData: {
          date: '2024-07-30T13:50:32.280Z',
          operation: 'modified',
        },
        endSurvey: false,
        blocks: [
          {
            uid: '6c5a6092-c6b7-461e-9c93-9ca72259f7a7',
            type: 'PRIMARY_CTA',
            value: 'Share Your Thoughts! ;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'right',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0f1e25ff',
            },
            createdAt: '2024-07-29T18:03:18.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'aabc9aa9-f3fa-4003-a5b1-8d0418887bb3',
            type: 'TITLE',
            value: '<p>Any additional comments or suggestions? </p>|-|none|-|',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Any additional comments or suggestions? ',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-07-29T18:03:18.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
          {
            uid: 'b6bf7de6-81a1-439e-8090-c01fc5532be0',
            type: 'OPEN_QUESTION',
            value: 'Enter your answer',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
              primaryColor: '#FFFFFF',
            },
            createdAt: '2024-07-29T18:03:18.000Z',
            removed: false,
            updateData: null,
            file: null,
            options: [],
          },
        ],
        goTo: null,
        jumps: [],
        prototypes: [],
      },
    ],
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '0',
        y: '0',
        blur: '0',
        color: '#33548a00',
      },
      overlay: null,
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#e6ebf7ff',
        secondaryColor: '#ffffffff',
      },
      hintOffset: 4,
      animationIn: 'fade',
      borderColor: '#00000014',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
  },
  {
    experienceType: 'HINT',
    id: 'eec2c4fd-48ce-4d34-9adf-56b849aa7e4d',
    image: DiscoverSaveShortcut,
    tags: [],
    type: 'TOUR',
    title: 'Shortcut Reminder',
    description:
      "Educate users about keyboard shortcuts or quicker navigation paths within your product. It's ideal for promoting underused features by providing users with faster ways to accomplish tasks, which can enhance productivity and user satisfaction.",
    tourSteps: [
      {
        uid: 'd6efee14-3ae2-40de-989c-0c487e65a148',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: '1',
            y: '2',
            blur: '12',
            color: '#00000026',
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          hintOffset: 8,
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 16,
          withElementsAnimations: true,
        },
        title: 'New poke',
        description: null,
        content: null,
        rawContent: null,
        step: null,
        optionsFlags: 12713993,
        lastStepChangeAt: '2024-07-29T17:47:03.000Z',
        createdAt: '2024-07-29T17:42:39.000Z',
        typeformFormId: null,
        mazeUrl: null,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        analyticViewsCount: 0,
        analyticClicksCount: 0,
        boostedAt: '2024-08-05T14:06:27.000Z',
        expiresAt: null,
        isDraft: true,
        boostedUrl: null,
        boostedDelay: 0,
        boostedQueryselector:
          '.events-table > .list > .card-event > .event-icon-wrapper > .event-icon',
        querySelectorManual: null,
        boostedPositionFlags: 8,
        boostedPositionOffsets: '0;0',
        boostedTextsColors: '#071331;#071331',
        boostedPrimaryColor: '#ffffff',
        boostedSecondaryColor: '#1260EB',
        boostedRoundness: '8',
        boostedContentFontSize: '14',
        boostedTitleFontSize: '18',
        boostedActiveUrl: 'https://testing.i.usejimo.com/events',
        boostedActiveOperator: 'EVERYWHERE',
        boostedPathOperator: 'OR',
        boostedDomainFilter: null,
        boostedDotStyle: '#1260EB;22;default',
        boostedSize: null,
        boostedAnimations: 'slide;slide;left;left',
        boostedLightbox: 'SOFT',
        boostedZIndex: null,
        icon: 'DEFAULT',
        triggerType: 'DEFAULT',
        state: 'LIVE',
        sectionIndexOrder: 0,
        recurrencyType: 'SINGLE_TIME',
        recurrencyValue: 1,
        tourStepInfo: '0;0;0',
        context: null,
        deviceFlags: 1,
        priority: 0,
        slackChannelFlags: 0,
        boostedById: null,
        isBoostOfId: null,
        themeId: null,
        projectId: 'db9b944c-4075-42b1-ba71-676050ebd188',
        parentTourId: 'eec2c4fd-48ce-4d34-9adf-56b849aa7e4d',
        boostFlags: 128,
        steps: [
          {
            uid: '48f396d1-d89e-4ee2-abe7-4dd3fbfcbc32',
            style: {
              width: null,
              height: null,
              margin: 0,
              shadow: {
                x: 0,
                y: 0,
                blur: 0,
                color: null,
                opacity: 0,
              },
              overlay: null,
              background: {
                type: 'color',
                animated: false,
                primaryColor: '#FFFFFF',
                secondaryColor: null,
              },
              animationIn: 'fade',
              animationOut: 'fade',
              borderRadius: 0,
              withElementsAnimations: true,
            },
            name: 'Simple question and answer',
            question: null,
            additionalInformation: null,
            type: null,
            mdContent: null,
            mdRawContent: null,
            indexOrder: 0,
            ctaLabel: null,
            ctaColor: null,
            ctaBackgroundColor: null,
            ctaBorderRadius: null,
            ctaUrl: null,
            ctaAction: null,
            ctaFontSize: null,
            interactiveLink: null,
            conceptThumbnailUrl: null,
            createdAt: '2024-07-29T17:42:39.000Z',
            mazeUrl: null,
            stepFlags: 0,
            removed: false,
            updateData: {
              date: '2024-08-05T14:05:07.770Z',
              operation: 'modified',
            },
            endSurvey: false,
            blocks: [
              {
                uid: '1c7547e4-a063-4ec7-aa44-a32bd7791083',
                type: 'BODY',
                value:
                  '<p>Use <strong>Ctrl + S </strong>to save your progress instantly and improve productivity.</p>',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Use ',
                        object: 'text',
                      },
                      {
                        bold: true,
                        text: 'Ctrl + S ',
                        object: 'text',
                      },
                      {
                        text: 'to save your progress instantly and improve productivity.',
                        object: 'text',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 15,
                  fontColor: '#6b6767ff',
                  fontFamily: 'Inter',
                  fontWeight: '400',
                },
                createdAt: '2024-07-29T17:42:44.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: '21dc35bf-9665-4ca2-8198-cc2f9c5f8ed0',
                type: 'HINT',
                value: '⌨️  Ctrl + S',
                rawValue: null,
                flags: 0,
                style: {
                  type: 'label',
                  color: '#3f3b3bff',
                  iconUrl: null,
                  offsetX: 0,
                  offsetY: 0,
                  padding: 8,
                  fontSize: 16,
                  iconName: 'help',
                  iconColor: '#000000ff',
                  pulsating: true,
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  iconSource: 'built-in',
                  borderColor: null,
                  borderRadius: 12,
                  verticalAlign: 'top',
                  backgroundColor: '#e8e8e8ff',
                  horizontalAlign: 'right',
                },
                createdAt: '2024-07-29T17:42:44.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
              {
                uid: '8dc0a673-163d-469b-8df7-1f5f72e57d1c',
                type: 'SECONDARY_CTA',
                value: '⌨️;none;url-open;https://yourdocumentation.com',
                rawValue: null,
                flags: 0,
                style: {
                  align: 'center',
                  padding: 12,
                  fontSize: 16,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                  fontWeight: '600',
                  borderColor: null,
                  borderRadius: 24,
                  primaryColor: '#e8ebf8ff',
                },
                createdAt: '2024-07-29T17:42:44.000Z',
                removed: true,
                updateData: null,
                file: null,
              },
              {
                uid: '9a87b5ec-3fc1-44de-b59c-f8b28cd61b03',
                type: 'TITLE',
                value: '<p>Discover Save Shortcut </p>|-|none|-|',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: 'Discover Save Shortcut ',
                        object: 'text',
                      },
                    ],
                  },
                ],
                flags: 0,
                style: {
                  align: 'left',
                  fontSize: 18,
                  fontColor: '#000000',
                  fontFamily: 'Inter',
                },
                createdAt: '2024-07-29T17:42:44.000Z',
                removed: false,
                updateData: null,
                file: null,
              },
            ],
          },
        ],
      },
    ],
    steps: [],
    style: null,
  },
];

export const replaceUIDsInObject = (obj) => {
  const uidMap = new Map(); // Map to track original uids and their new UUIDs

  // Helper function to check if a value is an object (including arrays, excluding null)
  const isObject = (value) => value !== null && typeof value === 'object';

  // Function to either fetch a new UUID or get an existing one from the map
  function getOrGenerateUUID(originalUID) {
    if (!uidMap.has(originalUID)) {
      uidMap.set(originalUID, uuidv4()); // Generate new UUID if not already done
    }
    return uidMap.get(originalUID); // Return the new UUID
  }

  // Recursive function to process an object or an array
  const processElement = (element) => {
    if (Array.isArray(element)) {
      // Process each element if it's an array
      element.forEach((item) => {
        return processElement(item);
      });
    } else if (isObject(element)) {
      // Process each key-value if it's an object
      Object.keys(element).forEach((key) => {
        if (key === 'uid' && typeof element[key] === 'string') {
          // Replace 'uid' value
          element[key] = getOrGenerateUUID(element[key]);
        } else if (isObject(element[key])) {
          // Recursively process if the value is an object or array
          processElement(element[key]);
        }
      });
    }
  };

  // Start the recursive processing
  processElement(obj);
};

const replaceUIDs = (arr) => {
  arr.forEach((obj) => {
    if (typeof obj === 'object') {
      replaceUIDsInObject(obj);
    }
  });
};

export const templates = () => {
  replaceUIDs(templatesArr);
  return templatesArr;
};
