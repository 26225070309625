import classnames from 'classnames';
import './_styles.scss';

export const ActivableOption = ({children, isActive = false, ...rest}) => {
  const {className, ...moreRest} = rest;

  return (
    <div
      className={classnames('builder-activable-options', className, {
        'is-active': isActive,
      })}
      {...moreRest}>
      {children}
    </div>
  );
};
