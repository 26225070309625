import classnames from 'classnames';
import commaNumber from 'comma-number';
import Button from 'components/Button';
import {crispHelpers} from 'helpers';
import {useStartTrial} from 'hooks/useStartTrial';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {useSelector} from 'react-redux';
import planDetails from 'scenes/Settings/scenes/Subscription/Plan/plan-details';
import {generalSelector} from 'selectors';
import {isEligibleToTrial, isTrying} from 'services/project';
import {
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
  isUpgrade,
} from 'services/subscription';
import './_styles.scss';

export const ItemPlan = ({plan, modeAnnualBilling, preventPeriodChanges}) => {
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const {update, isUpdating} = useUpdateSubscription();
  const {start, isStarting} = useStartTrial();

  const canTry = isEligibleToTrial(plan.uid);
  const isCurrentPlan = subscription.plan === plan.uid;
  const isTryingPlan = isCurrentPlan === true && isTrying();

  return (
    <div
      className={classnames(
        'settings-card item-plan',
        `plan-${plan.uid.toLowerCase()}`
      )}>
      <div className="plan-gradient-container">
        <div className="plan-gradient"></div>
      </div>
      {plan.uid === PLAN_GROWTH_ID && (
        <div className="plan-most-popular subtitle-4">
          <i className="isax isax-star-15"></i>
          Most Popular
        </div>
      )}
      {isCurrentPlan && isTryingPlan && (
        <div className="plan-current-trial body-3">Free trial</div>
      )}
      <div className="plan-section section-header">
        <div className="plan-logo"></div>
        <div className="plan-title">
          <div className="subtitle-2">{plan.title}</div>
          <small className="body-3 n-700">
            {plan.uid === PLAN_STARTUP_ID
              ? 'Best for small teams'
              : plan.uid === PLAN_GROWTH_ID
              ? 'Best for growing businesses'
              : 'Best for large organizations'}
          </small>
        </div>
      </div>
      <div className="plan-section section-price">
        <div className="plan-price title-2">
          ${modeAnnualBilling === true ? plan.prices[0] : plan.prices[1]}
          {modeAnnualBilling === true && (
            <div className="subtitle-1 n-500">${plan.prices[1]}</div>
          )}
        </div>
        <small className="body-3 n-600">
          {modeAnnualBilling === true
            ? '/month, billed yearly'
            : '/month, billed monthly'}
        </small>
      </div>
      {preventPeriodChanges === false &&
        isCurrentPlan === false &&
        canTry === true && (
          <Button
            primary
            onClick={() => start(plan.uid)}
            loading={isStarting}
            className="btn-upgrade">
            Start 14 days free trial
          </Button>
        )}
      {preventPeriodChanges === false &&
        (isCurrentPlan === false ||
          (isCurrentPlan === true && isTrying() === true)) &&
        canTry === false && (
          <Button
            primary
            className="btn-upgrade"
            loading={isUpdating}
            onClick={() => update({planId: plan.uid, modeAnnualBilling})}>
            {isTrying() === true
              ? 'Upgrade'
              : isUpgrade(plan.uid) === true
              ? 'Upgrade'
              : `Downgrade to ${plan.title}`}
          </Button>
        )}
      {preventPeriodChanges === false &&
        isCurrentPlan === true &&
        isTrying() === false && (
          <Button primary disabled className="btn-upgrade">
            Current Plan
          </Button>
        )}
      {preventPeriodChanges === true && (
        <Button
          className="btn-upgrade"
          onClick={() =>
            crispHelpers.startCrispThread(
              "I'd like to change my billing period. Can you help me ? :-)"
            )
          }>
          Contact support
        </Button>
      )}
      <div className="plan-section section-features">
        {[
          ...(plan.uid === PLAN_GROWTH_ID
            ? [
                {
                  uid: 'include-previous-plan',
                  title: `Everything in Startup Plan`,
                },
              ]
            : plan.uid === PLAN_SCALE_ID
            ? [
                {
                  uid: 'include-previous-plan',
                  title: `Everything in Growth Plan`,
                },
              ]
            : []),
          {uid: 'seats', title: `${plan.seats} seats`},
          {
            uid: 'mau',
            title: `Up to ${commaNumber(plan.mau)} Monthly Active Users`,
          },
        ]
          .concat(planDetails[plan.uid])
          .map((feature) => {
            return (
              <div key={feature.uid} className="item-feature">
                <i className="icon-tick"></i>
                <div className="item-body">
                  <div className="body-3">{feature.title}</div>
                  {feature.description != null && (
                    <div className="body-3 n-500">{feature.description}</div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
