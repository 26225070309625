import {Image} from '@styled-icons/material-outlined';
import {ELEMENT_IMAGE, insertImage} from '@udecode/plate';
import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import InputGroup from 'components/Input';
import DefaultLoader from 'components/Loader';
import {useMyPlateEditorRef} from 'components/MarkdownEditor/utils';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {any, func, string} from 'prop-types';
import {useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {fileService} from 'services';
import {BLOCK_TYPE_MEDIA} from 'services/steps';
import {Swaler} from 'swaler';
import MarkToolbarButton from '../MarkToolbarButton';
import './_Styles.scss';

const logger = new Swaler('MediaLink');

const propTypes = {
  type: string,
  fileUrl: string,
  altText: string,
  sizing: string,
  file: any,
  onChange: func,
};

const defaultProps = {
  type: null,
  fileUrl: null,
  altText: null,
  sizing: null,
  file: null,
  onChange: () => {},
};

const ImageToolbarButton = () => {
  const editor = useMyPlateEditorRef();

  const [isUploading, setIsUploading] = useState(false);
  const [urlMode, setUrlMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [url, setUrl] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const refInputFile = useRef();

  const {data: recentFiles} = useQuery({
    queryKey: ['recentFiles', BLOCK_TYPE_MEDIA],
    queryFn: () => fileService.getFiles({type: BLOCK_TYPE_MEDIA}),
    refetchOnWindowFocus: false,
  });

  const handleSelectRecentFile = (file) => {
    setSelectedFile(file);
    setUrl('');
  };

  const handleFileChange = async ({target}) => {
    if (!target.files || target.files.length === 0) {
      return;
    }
    setIsUploading(true);
    const file = await uploadFile(target.files[0]);
    setIsUploading(false);

    if (file != null) {
      setSelectedFile(file);
    }
  };

  const handleDeleteFile = async () => {
    setSelectedFile(null);
  };

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    try {
      const uploadedFile = await fileService.uploadPublicFile({
        file,
      });
      return uploadedFile;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to upload file', code);
      toastDanger([title, message], {actions});
      return null;
    }
  };

  const recentFilesSized = Array(6).fill(null);
  for (let i = 0; i < recentFilesSized?.length; i++) {
    recentFilesSized[i] = recentFiles?.[i];
  }

  return (
    <Dropdown
      open={dropdownOpen}
      onOpen={() => setDropdownOpen(true)}
      onClose={() => setDropdownOpen(false)}
      trigger={
        <MarkToolbarButton nodeType={ELEMENT_IMAGE}>
          <Image />
        </MarkToolbarButton>
      }>
      <div className="media-link-wrapper">
        <div className="media-link">
          <div className="section-item">
            <div
              className={classNames('image-input-wrapper', {
                'is-url': urlMode === true,
              })}>
              {urlMode === true ? (
                <div className="url-input-wrapper">
                  <div className="url-input-wrapper-header">
                    <span>URL</span>
                    <i
                      className="icon-close"
                      onClick={() => {
                        setSelectedFile(null);
                        setUrl('');
                        setUrlMode(false);
                      }}
                    />
                  </div>
                  <InputGroup
                    className="link-input"
                    value={url}
                    onChange={({target}) => setUrl(target.value)}
                    labelTextLeft={<i className="icon-link" />}
                    placeholder="Add..."
                    onKeyPress={({key}) => {
                      if (key === 'Enter') {
                        insertImage(editor, url);
                        setDropdownOpen(false);
                      }
                    }}
                  />
                </div>
              ) : (
                <>
                  <input
                    ref={refInputFile}
                    type="file"
                    style={{display: 'none'}}
                    onChange={handleFileChange}
                  />
                  {selectedFile != null ? (
                    <div className="file-wrapper">
                      <div className="file-preview-name-wrapper">
                        <img
                          src={selectedFile.publicUrl}
                          alt="Media"
                          width={100}
                          height={60}
                        />
                        {selectedFile.originalName.length > 10
                          ? selectedFile.originalName.slice(0, 10) + '...'
                          : selectedFile.originalName}
                      </div>
                      <Button
                        iconRight="icon-trash"
                        className="btn-delete"
                        onClick={handleDeleteFile}
                        danger
                        thin>
                        Delete
                      </Button>
                    </div>
                  ) : (
                    <>
                      <div
                        className={classNames('btn-file', {
                          'is-uploading': isUploading === true,
                        })}
                        onClick={() => refInputFile.current.click()}>
                        {isUploading === true ? (
                          <>
                            <DefaultLoader width={14} /> uploading your image...
                          </>
                        ) : (
                          <>
                            <i className="icon-upload-file"></i> Upload
                          </>
                        )}
                      </div>
                      {isUploading !== true && (
                        <>
                          or
                          <div
                            className={classNames('btn-file')}
                            onClick={() => setUrlMode(true)}>
                            <i className="icon-link"></i> URL
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="recent-uploads-wrapper">
            <div className="recent-label">Or select from your last uploads</div>
            <div className="recent-uploads-list">
              {recentFilesSized?.map((file) => {
                if (file == null) {
                  return (
                    <div className="recent-upload placeholder">
                      <div className="recent-upload-placeholder"></div>
                    </div>
                  );
                }

                const isSelected = selectedFile?.publicUrl === file.publicUrl;

                return (
                  <div
                    className={classNames('recent-upload', {
                      selected: isSelected,
                    })}
                    onClick={() => handleSelectRecentFile(file)}>
                    <img src={file.publicUrl} alt="Media" />
                    <div className="icon-wrapper">
                      {isSelected ? (
                        <i className="icon-checkbox" />
                      ) : (
                        <i className="icon-checkbox-o" />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Divider />
        <div className="actions">
          <Button
            className="btn-cancel"
            onClick={() => {
              setSelectedFile(null);
              setUrl('');
              setUrlMode(false);
              setDropdownOpen(false);
            }}>
            Cancel
          </Button>
          <Button
            primary
            className="btn-add"
            onClick={() => {
              if (urlMode === true) {
                insertImage(editor, url);
              } else {
                insertImage(editor, selectedFile?.publicUrl);
              }
              setDropdownOpen(false);
            }}>
            Add
          </Button>
        </div>
      </div>
    </Dropdown>
  );
};

ImageToolbarButton.propTypes = propTypes;
ImageToolbarButton.defaultProps = defaultProps;

export default ImageToolbarButton;
