import {useState, useEffect} from 'react';

const useScrollDetector = (ref, threshold = 50) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const container = ref?.current;

    const handleScroll = () => {
      const scrollTop = container.scrollTop;

      // Check if the div is scrolled down based on the threshold
      setIsScrolled(scrollTop > threshold);
    };

    // Attach the scroll event listener to the specific div
    container?.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, threshold]); // Empty dependency array ensures that the effect runs once on mount

  return isScrolled;
};

export default useScrollDetector;
