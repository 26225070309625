import classnames from 'classnames';
import Lottie from 'lottie-react';
import React from 'react';
import Popup from 'reactjs-popup';
import animEmojiBell from './animations/emoji-bell.animation.json';
import animEmojiIdk from './animations/emoji-idk.animation.json';
import animEmojiNah from './animations/emoji-nah.animation.json';
import animEmojiTalkative from './animations/emoji-talkative.animation.json';
import animEmojiYeah from './animations/emoji-yeah.animation.json';
import './_styles.scss';

class Emoji extends React.Component {
  render() {
    const {
      inline = false,
      width,
      height,
      baseWidth,
      baseHeight,
      className,
      ...rest
    } = this.props;
    const classNames = classnames('emoji', this.props.className, {
      'is-inline': inline,
    });
    const style = {width: baseWidth, height: baseHeight};

    if (width != null && height != null) {
      throw new Error(
        'You cannot supply both width and height to an Emoji as it can broke proportional ratio between width and height. Supply only one of both!'
      );
    }
    if (
      (width != null && typeof width !== 'number') ||
      (height != null && typeof height !== 'number')
    ) {
      throw new Error('Width or height supplied must be of type number!');
    }
    if (width != null) {
      style.width = width;
      style.height = Math.round((width * baseHeight) / baseWidth);
    }
    if (height != null) {
      style.height = height;
      style.width = Math.round((height * baseWidth) / baseHeight);
    }
    style.width = `${style.width}px`;
    style.height = `${style.height}px`;
    return <div className={classNames} style={style} {...rest}></div>;
  }
}

class EmojiAnimated extends React.Component {
  constructor() {
    super();
    this.refAnim = React.createRef();
  }
  handleOnMouseEnter = () => {
    const {animateOnHover} = this.props;

    if (animateOnHover === true) {
      this.refAnim.current.stop();
      this.refAnim.current.play();
    }
  };
  handleOnMouseLeave = () => {
    const {animateOnHover} = this.props;

    if (animateOnHover === true) {
      this.refAnim.current.stop();
    }
  };

  play = () => {
    this.refAnim.current.play();
  };
  stop = () => {
    this.refAnim.current.stop();
  };

  render() {
    let {
      animation,
      loop = false,
      autoplay = false,
      refAnim,
      animateOnHover,
      className,
      disabled,
      tooltip,
      tooltipProps = {},
      ...props
    } = this.props;
    const classNames = classnames('emoji-animated-wrapper', className, {
      'emoji-animated-disabled': disabled,
    });
    const tooltipClassNames = classnames(
      'emoji-tooltip',
      tooltipProps.className
    );
    const emoji = (
      <div
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
        {...props}
        className={classNames}>
        <Lottie
          animationData={animation}
          loop={loop}
          autoplay={autoplay}
          lottieRef={this.refAnim}></Lottie>
      </div>
    );

    if (tooltip != null) {
      return (
        <Popup
          arrow={false}
          position={'top center'}
          offsetY={8}
          {...tooltipProps}
          className={tooltipClassNames}
          trigger={<div className="emoji-tooltip-trigger">{emoji}</div>}>
          {tooltip}
        </Popup>
      );
    }
    return emoji;
  }
}

export class EmojiYeah extends React.Component {
  render() {
    const {animated, innerRef, ...rest} = this.props;
    const className = this.props.disabled ? 'emoji-yeah-d' : 'emoji-yeah';

    if (animated === true) {
      return (
        <EmojiAnimated
          ref={innerRef}
          {...rest}
          className={className}
          animation={animEmojiYeah}></EmojiAnimated>
      );
    }
    return (
      <Emoji
        {...rest}
        className={className}
        baseWidth={42}
        baseHeight={42}></Emoji>
    );
  }
}
export class EmojiIdk extends React.Component {
  render() {
    const {animated, innerRef, ...rest} = this.props;
    const className = this.props.disabled ? 'emoji-idk-d' : 'emoji-idk';

    if (animated === true) {
      return (
        <EmojiAnimated
          ref={innerRef}
          {...rest}
          className={className}
          animation={animEmojiIdk}></EmojiAnimated>
      );
    }
    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={42}
        baseHeight={42}></Emoji>
    );
  }
}
export class EmojiNah extends React.Component {
  render() {
    const {animated, innerRef, ...rest} = this.props;
    const className = this.props.disabled ? 'emoji-nah-d' : 'emoji-nah';

    if (animated === true) {
      return (
        <EmojiAnimated
          ref={innerRef}
          {...rest}
          className={className}
          animation={animEmojiNah}></EmojiAnimated>
      );
    }
    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={42}
        baseHeight={42}></Emoji>
    );
  }
}
export class EmojiClap extends React.Component {
  render() {
    const className = this.props.disabled ? 'emoji-clap-d' : 'emoji-clap';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={42}
        baseHeight={42}></Emoji>
    );
  }
}
export class EmojiThumbsUp extends React.Component {
  render() {
    const className = 'emoji-thumbs-up';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={42}
        baseHeight={47}></Emoji>
    );
  }
}
export class EmojiEvolutionLiveForVote extends React.Component {
  render() {
    const className = 'emoji-evolution-live-for-vote';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={42}
        baseHeight={44}></Emoji>
    );
  }
}
export class EmojiEvolutionFeaturePreview extends React.Component {
  render() {
    const className = 'emoji-evolution-feature-preview';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={42}
        baseHeight={44}></Emoji>
    );
  }
}
export class EmojiEvolutionAnnouncement extends React.Component {
  render() {
    const className = 'emoji-evolution-announcement';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={42}
        baseHeight={47}></Emoji>
    );
  }
}
export class EmojiPeace extends React.Component {
  render() {
    const className = 'emoji-peace';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={42}
        baseHeight={68}></Emoji>
    );
  }
}
export class EmojiComment extends React.Component {
  render() {
    const className = 'emoji-comment';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={42}
        baseHeight={34}></Emoji>
    );
  }
}
export class EmojiHandDown extends React.Component {
  render() {
    const className = 'emoji-hand-down';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={42}
        baseHeight={68}></Emoji>
    );
  }
}
export class EmojiMultipleEvos extends React.Component {
  render() {
    const className = 'emoji-multiple-evos';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={42}
        baseHeight={42}></Emoji>
    );
  }
}
export class EmojiParty extends React.Component {
  render() {
    const className = 'emoji-party';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={45}
        baseHeight={43}></Emoji>
    );
  }
}
export class EmojiWidgetLeft extends React.Component {
  render() {
    const className = 'emoji-widget-left';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={45}
        baseHeight={43}></Emoji>
    );
  }
}
export class EmojiWidgetRight extends React.Component {
  render() {
    const className = 'emoji-widget-right';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={45}
        baseHeight={43}></Emoji>
    );
  }
}
export class EmojiWidgetTriggerSide extends React.Component {
  render() {
    const className = 'emoji-widget-trigger-side';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={45}
        baseHeight={43}></Emoji>
    );
  }
}
export class EmojiWhoAreYou extends React.Component {
  render() {
    const className = 'emoji-who-are-you';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={45}
        baseHeight={43}></Emoji>
    );
  }
}
export class EmojiConversation extends React.Component {
  render() {
    const className = 'emoji-conversation';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={45}
        baseHeight={43}></Emoji>
    );
  }
}
export class EmojiTalkative extends React.Component {
  render() {
    const {animated = true, innerRef, ...rest} = this.props;
    const className = this.props.disabled
      ? 'emoji-talkative-d'
      : 'emoji-talkative';

    if (animated === true) {
      return (
        <EmojiAnimated
          ref={innerRef}
          {...rest}
          className={className}
          animation={animEmojiTalkative}></EmojiAnimated>
      );
    }
    return 'only animated implementation';
  }
}
export class EmojiBell extends React.Component {
  render() {
    const {animated = true, innerRef, ...rest} = this.props;
    const className = this.props.disabled
      ? 'emoji-talkative-d'
      : 'emoji-talkative';

    if (animated === true) {
      return (
        <EmojiAnimated
          ref={innerRef}
          {...rest}
          className={className}
          animation={animEmojiBell}></EmojiAnimated>
      );
    }
    return 'only animated implementation';
  }
}
export class EmojiBadge extends React.Component {
  render() {
    const className = 'emoji-badge';

    return (
      <Emoji
        {...this.props}
        className={className}
        baseWidth={45}
        baseHeight={43}></Emoji>
    );
  }
}
