import classnames from 'classnames';
import {CircularProgress} from 'components/Progress';
import {useEffect, useState} from 'react';
import {
  ROUTE_ONBOARDING_DETAILS_JOIN,
  ROUTE_ONBOARDING_DETAILS_STEP_1_1,
  ROUTE_ONBOARDING_DETAILS_STEP_1_2,
  ROUTE_ONBOARDING_DETAILS_STEP_2_1,
  ROUTE_ONBOARDING_DETAILS_STEP_2_2,
  ROUTE_ONBOARDING_DETAILS_STEP_2_3,
  ROUTE_ONBOARDING_DETAILS_STEP_3_1,
} from 'router/routes.const';
import './_Styles.scss';

export const ProgressionTracker = ({path, playAnimation}) => {
  const [value, setValue] = useState({
    stepOne:
      path === ROUTE_ONBOARDING_DETAILS_STEP_1_1
        ? 0
        : path === ROUTE_ONBOARDING_DETAILS_STEP_1_2
        ? 50
        : 0,
    stepTwo:
      path === ROUTE_ONBOARDING_DETAILS_STEP_2_1
        ? 0
        : path === ROUTE_ONBOARDING_DETAILS_STEP_2_2
        ? 33
        : path === ROUTE_ONBOARDING_DETAILS_STEP_2_3
        ? 66
        : 0,
    stepThree: path === ROUTE_ONBOARDING_DETAILS_STEP_3_1 ? 0 : 0,
  });

  useEffect(() => {
    setTimeout(() => {
      if (path === ROUTE_ONBOARDING_DETAILS_STEP_1_1) {
        setValue({
          ...value,
          stepOne: 50,
        });
      }
      if (path === ROUTE_ONBOARDING_DETAILS_STEP_1_2) {
        setValue({
          ...value,
          stepOne: 100,
        });
      }
      if (path === ROUTE_ONBOARDING_DETAILS_STEP_2_1) {
        setValue({
          ...value,
          stepTwo: 25,
        });
      }
      if (path === ROUTE_ONBOARDING_DETAILS_STEP_2_2) {
        setValue({
          ...value,
          stepTwo: 66,
        });
      }
      if (path === ROUTE_ONBOARDING_DETAILS_STEP_2_3) {
        setValue({
          ...value,
          stepTwo: 100,
        });
      }
      if (path === ROUTE_ONBOARDING_DETAILS_STEP_3_1) {
        setValue({
          ...value,
          stepThree: 100,
        });
      }
    }, 200);
  }, []);

  const isLabelHidden = [
    ROUTE_ONBOARDING_DETAILS_STEP_2_2,
    ROUTE_ONBOARDING_DETAILS_STEP_2_3,
  ].includes(path);
  const isLabelNotVisible = path === ROUTE_ONBOARDING_DETAILS_STEP_3_1;

  return (
    <div
      className={classnames('onboarding-progression-tracker', {
        'anim-in': path === ROUTE_ONBOARDING_DETAILS_STEP_1_1,
        'label-exiting': playAnimation === 'out',
        'label-entering': playAnimation === 'in',
        'label-not-visible': isLabelNotVisible === true,
      })}>
      <div className="progression-overlay"></div>
      <div className="progression-line"></div>
      <div className="steps-wrapper">
        <div
          className={classnames('step-item', {
            active: [
              ROUTE_ONBOARDING_DETAILS_STEP_1_1,
              ROUTE_ONBOARDING_DETAILS_STEP_1_2,
            ].includes(path),
          })}>
          <CircularProgress
            value={value.stepOne}
            size={32}
            strokeWidth={2}></CircularProgress>{' '}
          <span className="step-number">1</span>{' '}
          {isLabelHidden === false && (
            <span className="step-label">Create profile</span>
          )}
        </div>
        <div
          className={classnames('step-item', {
            active: [
              ROUTE_ONBOARDING_DETAILS_STEP_2_1,
              ROUTE_ONBOARDING_DETAILS_STEP_2_2,
              ROUTE_ONBOARDING_DETAILS_STEP_2_3,
              ROUTE_ONBOARDING_DETAILS_JOIN,
            ].includes(path),
          })}>
          <CircularProgress
            value={value.stepTwo}
            size={32}
            strokeWidth={2}></CircularProgress>{' '}
          <span className="step-number">2</span>{' '}
          {isLabelHidden === false && (
            <span className="step-label">Setup workspace</span>
          )}
        </div>
        <div
          className={classnames('step-item', {
            active: [ROUTE_ONBOARDING_DETAILS_STEP_3_1].includes(path),
          })}>
          <CircularProgress
            value={value.stepThree}
            size={32}
            strokeWidth={2}></CircularProgress>{' '}
          <span className="step-number">3</span>{' '}
          {isLabelHidden === false && (
            <span className="step-label">Start interacting</span>
          )}
        </div>
      </div>
    </div>
  );
};
