import Button from 'components/Button';
import {usePlanPreview} from 'hooks/usePlanPreview';
import {useStartTrial} from 'hooks/useStartTrial';
import {PLAN_GROWTH_ID} from 'services/subscription';
import './_styles.scss';

export const BillingCardLaunchTrial = () => {
  const {start, isStarting} = useStartTrial();
  const {preview} = usePlanPreview();

  return (
    <div className="settings-card billing-card-launch-trial">
      <div className="title-4 p-500">
        Try the Growth plan for free for 14 days
      </div>
      <div className="card-description body-2 n-700">
        Publish unlimited experiences, multiple languages, in-app Changelog and
        more. No credit card needed, cancel anytime.
      </div>
      <div className="actions-wrapper">
        <Button
          thin
          onClick={() =>
            preview({
              planId: PLAN_GROWTH_ID,
            })
          }
          disabled={isStarting}>
          Plan detail
        </Button>
        <Button
          thin
          primary
          onClick={() => start(PLAN_GROWTH_ID)}
          loading={isStarting}>
          Start free trial
        </Button>
      </div>
    </div>
  );
};
