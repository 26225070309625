import {generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import BtnCopy from 'components/ButtonCopy';
import Divider from 'components/Divider';
import {toastDanger, toastWarning} from 'components/Toaster';
import {crispHelpers, errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import Lottie from 'lottie-react';
import {useRef, useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  ROUTE_GET_STARTED,
  ROUTE_SETTINGS_INTEGRATIONS,
  ROUTE_SETTINGS_PLAN,
} from 'router/routes.const';
import {GetStartedStepItem} from 'scenes/GetStarted/component/GetSartedStepItem';
import {GetStartedStepContainer} from 'scenes/GetStarted/component/GetStartedStepContainer';
import {Install} from 'scenes/Onboarding/scenes/Details/step-3-1';
import {generalSelector} from 'selectors';
import {projectService} from 'services';
import {F_EXTRA_USAGE_IDENTIFY, isEligibleToTrial} from 'services/project';
import {PLAN_STARTUP_ID} from 'services/subscription';
import {Swaler} from 'swaler';
import './_styles.scss';
import confettiAnimation from './animations/confetti.json';
import imgSegmentLogo from './imgs/segment.svg';

const logger = new Swaler('GetStarted/Install');

export const GetStartedInstall = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState('sdk');
  const [refreshing, setRefreshing] = useState(null);
  const project = useSelector((state) => generalSelector.getProject(state));

  const uptProject = (data) => dispatch(generalActions.uptProject(data));

  const codeSetEmail = `window.jimo.push(['set', 'user:email', ['john.doe@company.com']])`;
  const codeSetUsername = `window.jimo.push(['set', 'user:name', ['john.doe']])`;
  const codeSetId = `window.jimo.push(['set', 'user:id', ['unique-id']])`;
  const codeSetCustomAttributes = `window.jimo.push(['set', 'user:attributes', [ { foo: "bar" } ]])`;

  const refSuccessDiv = useRef(null);

  const handleRefreshProject = async (context) => {
    setRefreshing(context);
    try {
      const refreshedProject = await projectService.getProject(project.uid);

      uptProject(refreshedProject);
      setRefreshing(null);
      if (
        context === 'install' &&
        refreshedProject.snippetInstalledAt == null
      ) {
        return toastWarning(
          [
            'Snippet not detected',
            "Make sure that you access your website and that the jimo snippet is correctly loaded. If you have done everything, let's chat!",
          ],
          {
            actions: [
              {
                text: 'Chat with us',
                props: {
                  onClick: () => {
                    crispHelpers.startCrispThread(
                      `My project doesn't seems to be set as installed after multiple attempts. Can you help me ? :-)`
                    );
                  },
                  iconLeft: 'icon-chat',
                },
              },
            ],
          }
        );
      }
      if (
        context === 'identify' &&
        hasFlag(F_EXTRA_USAGE_IDENTIFY, refreshedProject.extraFlags) === false
      ) {
        return toastWarning([
          'Identification not detected',
          "We couldn't detect any identification at the moment. Try again after calling our SDK or come back to it later!",
        ]);
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setRefreshing(null);
      logger.error('Refreshing project failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };

  // useEffect(() => {
  //   if (project.snippetInstalledAt != null && refSuccessDiv.current != null) {
  //     refSuccessDiv.current.scrollIntoView({behavior: 'smooth'});
  //   }
  // }, [refSuccessDiv.current]);

  return (
    <div className="s-get-started-install">
      <div className="gs-top">
        <Button
          onClick={() =>
            history.push({
              pathname: ROUTE_GET_STARTED,
              state: {
                expandGetStarted: true,
              },
            })
          }
          light
          iconOnly>
          <i className="icon-chevron-left"></i>
        </Button>
        Install Jimo in your product
      </div>
      <div className="gs-body">
        <GetStartedStepContainer>
          <GetStartedStepItem
            value={1}
            progress={project.snippetInstalledAt != null ? 100 : 0}
            title="Install Jimo snippet">
            <div className="gs-install-wrapper">
              {project.snippetInstalledAt != null ? (
                <div className="gs-container gs-install-success-wrapper">
                  <div className="has-jimo-installed">
                    <div className="confetti-wrapper">
                      <Lottie
                        animationData={confettiAnimation}
                        loop={false}></Lottie>
                    </div>
                    <i className="icon-tick"></i>
                    <small>Well done!</small>
                    <small>Jimo is fully installed in your product</small>
                  </div>
                </div>
              ) : (
                <>
                  <div className="gs-container gs-install-methods-wrapper">
                    <div className="gs-install-choose">Choose your method:</div>
                    <div className="gs-install-methods">
                      <Install withoutAnimation />
                      <Divider dark></Divider>
                      <Button
                        loading={refreshing === 'install'}
                        disabled={
                          refreshing != null && refreshing !== 'install'
                        }
                        cta
                        className="btn-check-installation"
                        onClick={() => handleRefreshProject('install')}>
                        Check installation
                      </Button>
                    </div>
                  </div>
                  <div className="gs-install-tutorial-wrapper">
                    <div className="tutorial-content">
                      <p>Watch our tutorial</p>
                      <div
                        style={{
                          position: 'relative',
                          paddingBottom: '54.86%',
                          height: 0,
                        }}>
                        <iframe
                          title="install-snippet"
                          src="https://www.loom.com/embed/f59595c4a04c4c849b8e5d3f0fcc57e4"
                          frameBorder="0"
                          allowFullScreen
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}></iframe>
                      </div>
                    </div>
                    <iframe
                      className="install-tutorial-animation"
                      title="install-tutorial-animation"
                      src="https://my.spline.design/untitledcopycopycopy-b9311e94ca70776a5ca2ae24de34df90"
                      frameborder="0"></iframe>
                  </div>
                </>
              )}
            </div>
          </GetStartedStepItem>
          <GetStartedStepItem
            value={2}
            progress={
              hasFlag(F_EXTRA_USAGE_IDENTIFY, project.extraFlags) === true
                ? 100
                : 0
            }
            title="Send user data for targeting and personalization">
            <div className="gs-identify-wrapper">
              {hasFlag(F_EXTRA_USAGE_IDENTIFY, project.extraFlags) === true ? (
                <div className="gs-container gs-identify-success-wrapper">
                  <div className="has-jimo-installed">
                    <div className="confetti-wrapper">
                      <Lottie
                        animationData={confettiAnimation}
                        loop={false}></Lottie>
                    </div>
                    <i className="icon-tick"></i>
                    <small>Amazing!</small>
                    <small>
                      Now you can send the right message to the right users!
                    </small>
                  </div>
                </div>
              ) : (
                <>
                  <div className="gs-container gs-identify-methods-wrapper">
                    <div className="gs-identify-choose">
                      Choose how to do it:
                    </div>
                    <div className="gs-identify-methods">
                      <div className="gs-card">
                        <div
                          className="card-cc-top"
                          onClick={() =>
                            setExpanded(expanded === 'sdk' ? null : 'sdk')
                          }>
                          <div className="icon-wrapper">
                            <i className="icon-vanilla-js"></i>
                          </div>
                          <div className="content-description">
                            Identify users with our Javascript SDK
                            <small>
                              Use our SDK to identify and segment users
                            </small>
                          </div>
                          <i
                            className={classnames('icon-chevron-bottom', {
                              rotated: expanded === 'sdk',
                            })}></i>
                        </div>
                        <AnimateHeight height={expanded === 'sdk' ? 'auto' : 0}>
                          <div className="card-cc-body">
                            <div className="identify-sdk-item">
                              <div className="sdk-label-name">Email</div>
                              <div className="sdk-command-wrapper">
                                <pre>{codeSetEmail}</pre>
                                <BtnCopy textToCopy={codeSetEmail} thin>
                                  Copy
                                </BtnCopy>
                              </div>
                            </div>
                            <div className="identify-sdk-item">
                              <div className="sdk-label-name">Username</div>
                              <div className="sdk-command-wrapper">
                                <pre>{codeSetUsername}</pre>
                                <BtnCopy textToCopy={codeSetUsername} thin>
                                  Copy
                                </BtnCopy>
                              </div>
                            </div>
                            <div className="identify-sdk-item">
                              <div className="sdk-label-name">Id</div>
                              <div className="sdk-command-wrapper">
                                <pre>{codeSetId}</pre>
                                <BtnCopy textToCopy={codeSetId} thin>
                                  Copy
                                </BtnCopy>
                              </div>
                            </div>
                            <div className="identify-sdk-item">
                              <div className="sdk-label-name">
                                Any user attributes
                              </div>
                              <div className="sdk-command-wrapper">
                                <pre>{codeSetCustomAttributes}</pre>
                                <BtnCopy
                                  textToCopy={codeSetCustomAttributes}
                                  thin>
                                  Copy
                                </BtnCopy>
                              </div>
                            </div>
                            <div className="go-to-documentation">
                              For a complete description of this methods, please
                              check our{' '}
                              <a
                                href="https://help.usejimo.com/help-center/v/for-developers/for-developers/sdk-methods"
                                target="_blank"
                                rel="noopener noreferrer">
                                SDK method list
                              </a>{' '}
                              and{' '}
                              <a
                                href="https://help.usejimo.com/help-center/"
                                target="_blank"
                                rel="noopener noreferrer">
                                documentation
                              </a>
                            </div>
                          </div>
                        </AnimateHeight>
                      </div>
                      <div className="gs-card">
                        <div
                          className="card-cc-top"
                          onClick={() =>
                            setExpanded(
                              expanded === 'segment' ? null : 'segment'
                            )
                          }>
                          <div className="icon-wrapper">
                            <img src={imgSegmentLogo} alt="segment logo" />
                          </div>
                          <div className="content-description">
                            Identify users with Segment.com
                            <small>
                              Import your user attributes from Segment directly
                              in Jimo
                            </small>
                          </div>
                          <i
                            className={classnames('icon-chevron-bottom', {
                              rotated: expanded === 'segment',
                            })}></i>
                        </div>
                        <AnimateHeight
                          height={expanded === 'segment' ? 'auto' : 0}>
                          <div className="card-identify-body">
                            <Button
                              primary
                              onClick={() =>
                                history.push(ROUTE_SETTINGS_INTEGRATIONS)
                              }>
                              Connect to segment
                            </Button>
                          </div>
                        </AnimateHeight>
                      </div>
                    </div>
                    <Divider dark></Divider>
                    <Button
                      loading={refreshing === 'identify'}
                      disabled={refreshing != null && refreshing !== 'identify'}
                      cta
                      className="btn-check-identification"
                      onClick={() => handleRefreshProject('identify')}>
                      Check identification
                    </Button>
                  </div>
                  <div className="gs-identify-tutorial-wrapper">
                    <div className="tutorial-content">
                      <p>Watch our tutorial</p>
                      <div
                        style={{
                          position: 'relative',
                          paddingBottom: '54.86%',
                          height: 0,
                        }}>
                        <iframe
                          title="install-snippet"
                          src="https://www.loom.com/embed/cf04633b684545e387532a6a8c68b122"
                          frameBorder="0"
                          allowFullScreen
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}></iframe>
                      </div>
                    </div>
                    <iframe
                      title="identify-tutorial-animation"
                      className="identify-tutorial-animation"
                      src="https://my.spline.design/untitledcopycopy-2408c3444ecb62938f6cca99ca1deead/"
                      frameborder="0"
                      width="100%"
                      height="100%"></iframe>
                  </div>
                </>
              )}
            </div>
          </GetStartedStepItem>
          {project.snippetInstalledAt != null && (
            <GetStartedStepItem>
              <div
                className="gs-container gs-container-install-end"
                ref={refSuccessDiv}>
                You are ready to go live!
                <small>
                  It's time to show your content to your users, live in-app
                </small>
                <Button
                  cta
                  secondary
                  iconRight="icon-chevron-right"
                  onClick={() =>
                    history.push({
                      pathname: ROUTE_SETTINGS_PLAN,
                      state: {expandGetStarted: true},
                    })
                  }>
                  {isEligibleToTrial(PLAN_STARTUP_ID) === true
                    ? 'Start free trial'
                    : 'Upgrade'}
                </Button>
                <div className="iframe-wrapper">
                  <iframe
                    src="https://my.spline.design/collaboratecopy-28222a336ead1767d4d9767105da0ce8"
                    frameborder="0"
                    title="animation"
                    width="700px"
                    height="700px"></iframe>
                </div>
              </div>
            </GetStartedStepItem>
          )}
        </GetStartedStepContainer>
      </div>
    </div>
  );
};
