import classNames from 'classnames';
import {useContext} from 'react';
import {useSelector} from 'react-redux';
import {BuilderContext} from 'scenes/Builder/context';
import {generalSelector} from 'selectors';
import {SectionWhen} from './components/SectionWhen';
import {SectionWhere} from './components/SectionWhere';
import {SectionWho} from './components/SectionWho';
import './_styles.scss';

export const BuilderAudience = () => {
  const {evolution} = useContext(BuilderContext);

  const project = useSelector((state) => generalSelector.getProject(state));

  const snippetInstalled = project.snippetInstalledAt != null;
  const isInApp = evolution.boostFlags > 0;

  return (
    <div className="s-builder-audience">
      <div
        className={classNames('main-wrapper', {
          'display-install': isInApp && !snippetInstalled,
        })}>
        <div className="sections-wrapper">
          {isInApp === true && <SectionWhere />}
          <SectionWho />
          <SectionWhen />
        </div>
        <div className="animation-wrapper">
          <iframe
            title="audience-animation"
            src="https://my.spline.design/untitledcopycopycopy-b9311e94ca70776a5ca2ae24de34df90"
            frameborder="0"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
};
