import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import React, {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {UrlTester} from 'scenes/Builder/scenes/Audience/components/PathSelector';
import {
  ACTIVE_OPERATOR_CUSTOM,
  ACTIVE_OPERATOR_SINGLE_URL,
} from 'scenes/EmbeddedElementSelectorBuilder';
import RadioGroup from '../../../../../../components/RadioGroup';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import Rules from '../../components/modals/Rules';
import {hasFlag} from 'helpers/bitwise';
import {F_BOOST_SLOT_TOUR} from 'services/evolution';
import './_Styles.scss';
import classNames from 'classnames';

const NavigationOption = ({
  label,
  description,
  selected,
  onClick = () => {},
}) => {
  return (
    <div
      className={classNames('navigation-option', {
        selected,
      })}
      onClick={onClick}>
      <div className="navigation-option-content">
        <div className="navigation-option-label subtitle-4 n-800">{label}</div>
        <div className="navigation-option-description body-4 n-700">
          {description}
        </div>
      </div>
      <div className="navigation-option-icon">
        <i className="isax isax-tick-circle5 b-400" />
      </div>
    </div>
  );
};

const Navigation = () => {
  const {controlledEvolution: evolution, setControlledEvolution: setEvolution} =
    useContext(BuilderContext);

  return (
    <div className="block-settings navigation">
      <div className="section">
        <div className="section-title">Type</div>
        <div className="section-content">
          <div className="section-item">
            <NavigationOption
              label="Direct URL Navigation"
              description="Redirect users immediately to a specified URL, then resume tour."
              selected={
                evolution.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL
                  ? 'Selected'
                  : ''
              }
              onClick={() =>
                setEvolution({
                  ...evolution,
                  boostedActiveOperator: ACTIVE_OPERATOR_SINGLE_URL,
                })
              }
            />
          </div>
          <div className="section-item">
            <NavigationOption
              label="URL-triggered Progression"
              description="Pause tour until user visits specified URL on their own, then resume tour."
              selected={
                evolution.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM
                  ? 'Selected'
                  : ''
              }
              onClick={() =>
                setEvolution({
                  ...evolution,
                  boostedActiveOperator: ACTIVE_OPERATOR_CUSTOM,
                })
              }
            />
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">
          Navigation
          <div className="subtitle body-3 n-600">
            Resume tour when URL matches with the following rule(s)
          </div>
        </div>
        <div className="section-content">
          {evolution.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL && (
            <div className="section-item">
              <div className="section-item-title">Redirect to</div>
              <div className="section-item-content">
                <InputGroup
                  className="navigation-redirect-input"
                  value={evolution.boostedActiveUrl}
                  onChange={({target}) =>
                    setEvolution({
                      ...evolution,
                      boostedActiveUrl: target.value,
                    })
                  }
                  placeholder="https://"
                />
              </div>
            </div>
          )}
          {evolution.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM && (
            <>
              <div className="section-item">
                <div className="section-item-title">Rules</div>
                <div className="section-item-content">
                  <PopupSetting
                    className="rules-popup-setting"
                    trigger={
                      <ClickableInput
                        value={
                          evolution.boostedPaths?.length > 0
                            ? `${evolution.boostedPaths.length} rule${
                                evolution.boostedPaths.length > 1 ? 's' : ''
                              }`
                            : 'Add...'
                        }
                      />
                    }
                    title="Rules"
                    content={<Rules />}
                    large
                  />
                </div>
              </div>
              <Divider />
              <UrlTester
                paths={evolution.boostedPaths}
                domainFilter={evolution.boostedDomainFilter}
                pathOperator={evolution.boostedPathOperator}
                isTour={hasFlag(evolution.boostedSlot, F_BOOST_SLOT_TOUR)}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
