import Button from 'components/Button';
import {bool} from 'prop-types';
import React, {useContext} from 'react';
import {
  AudienceContext,
  SECTION_GOAL,
  SECTION_TRIGGER,
  SECTION_WHEN,
  SECTION_WHERE,
  SECTION_WHO,
} from 'scenes/PokeAudience';
import {getGoalTitle} from 'scenes/PokeAudience/sections/Goal';
import {getTriggerTitle} from 'scenes/PokeAudience/sections/Trigger';
import {getWhenTitle} from 'scenes/PokeAudience/sections/When';
import {getWhereTitle} from 'scenes/PokeAudience/sections/Where';
import {getWhoTitle} from 'scenes/PokeAudience/sections/Who';
import {
  SECTION_WHEN as SECTION_WHEN_POST,
  SECTION_WHO as SECTION_WHO_POST,
} from 'scenes/PostAudience';
import {EVOLUTION_TYPE_HINT, TRIGGER_TYPE_URL} from 'services/evolution';
import './_Styles.scss';

const SummaryInfoSection = ({name, icon, value, onEdit}) => {
  return (
    <div className="section">
      <div className="section-name">{name}</div>
      <div className="section-value-wrapper">
        <div className="section-value-icon">
          <i className={icon} />
        </div>
        <div className="section-value">{value}</div>
        <Button
          className="edit-btn"
          iconLeft="icon-edit-outline"
          onClick={onEdit}>
          Edit
        </Button>
      </div>
    </div>
  );
};

const propTypes = {
  isPost: bool,
};

const defaultProps = {
  isPost: false,
};

const SummaryInfos = ({isPost}) => {
  const {evolution, setSelectedSection, jimersCount, sections} =
    useContext(AudienceContext);

  const isHint = evolution?.type === EVOLUTION_TYPE_HINT;

  const availableSections = sections.map((s) => s.section);

  return (
    <div className="summary-infos">
      {isPost !== true && (
        <>
          {availableSections.includes(SECTION_TRIGGER) && (
            <SummaryInfoSection
              name="Trigger"
              icon="icon-light"
              value={getTriggerTitle(evolution)}
              onEdit={() => setSelectedSection(SECTION_TRIGGER)}
            />
          )}
          {availableSections.includes(SECTION_WHERE) && (
            <SummaryInfoSection
              name="Where"
              icon="icon-desktop"
              value={getWhereTitle(evolution)}
              onEdit={() => setSelectedSection(SECTION_WHERE)}
            />
          )}
        </>
      )}
      {evolution.triggerType !== TRIGGER_TYPE_URL && (
        <>
          {availableSections.includes(
            isPost ? SECTION_WHO_POST : SECTION_WHO
          ) && (
            <SummaryInfoSection
              name="Who"
              icon="icon-users"
              value={getWhoTitle(evolution, jimersCount)}
              onEdit={() =>
                setSelectedSection(
                  isPost === true ? SECTION_WHO_POST : SECTION_WHO
                )
              }
            />
          )}
          {availableSections.includes(
            isPost ? SECTION_WHEN_POST : SECTION_WHEN
          ) && (
            <SummaryInfoSection
              name="When"
              icon="icon-calendar"
              value={getWhenTitle(evolution, {isPost, isHint})}
              onEdit={() =>
                setSelectedSection(
                  isPost === true ? SECTION_WHEN_POST : SECTION_WHEN
                )
              }
            />
          )}
        </>
      )}
      {availableSections.includes(SECTION_GOAL) && (
        <SummaryInfoSection
          name="Goal"
          icon="icon-target"
          value={getGoalTitle(evolution)}
          onEdit={() => setSelectedSection(SECTION_GOAL)}
        />
      )}
    </div>
  );
};

SummaryInfos.propTypes = propTypes;
SummaryInfos.defaultProps = defaultProps;

export default SummaryInfos;
