import classnames from 'classnames';
import {getStepIssue} from 'helpers/step';
import useDraggableInPortal from 'hooks/useDraggableInPortal';
import {bool, func, object, string} from 'prop-types';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {stepsService} from 'services';
import Block from '../Block';
import {BLOCKS, DropdownAddBlock} from '../DropdownAddBlock';
import './_Styles.scss';

export const getStepData = (type) => {
  const step = BLOCKS.find((b) => b.step === type) ?? {};

  return step;
};

const propTypes = {
  evolution: object.isRequired,
  updateSteps: func.isRequired,
  selectedStepId: string,
  setSelectedStepId: func,
  surveyOnly: bool,
};
const defaultProps = {
  selectedStepId: null,
  setSelectedStepId: () => {},
  surveyOnly: false,
};

const BlockList = ({
  evolution,
  updateSteps,
  selectedStepId,
  setSelectedStepId,
  surveyOnly,
}) => {
  const {steps, typeformFormId} = evolution;

  const renderDraggable = useDraggableInPortal();

  const addStep = (type, fromPrototypeData) => {
    const step = stepsService.createStepObj(type);

    if (fromPrototypeData == null) {
      updateSteps(steps.concat(step));
      setSelectedStepId(step.uid);
    } else {
      const updatedSteps = steps.map((s) =>
        s.uid === fromPrototypeData.stepId
          ? {
              ...s,
              prototypes: s.prototypes.map((p) =>
                p.uid === fromPrototypeData.prototypeId
                  ? {...p, steps: p.steps.concat(step)}
                  : p
              ),
            }
          : s
      );

      setSelectedStepId(step.uid);
      updateSteps(updatedSteps);
    }
  };
  const removeStep = (stepId, fromPrototypeData) => {
    if (fromPrototypeData == null) {
      const stepToRemoveIndex = steps.map((e) => e.uid).indexOf(stepId);

      if (stepToRemoveIndex >= 0) {
        if (stepToRemoveIndex + 1 < steps.length) {
          setSelectedStepId(steps[stepToRemoveIndex + 1]?.uid);
        } else {
          setSelectedStepId(steps[stepToRemoveIndex - 1]?.uid);
        }
        steps.splice(stepToRemoveIndex, 1);
        updateSteps(steps);
      }
    } else {
      const updatedSteps = steps.map((s) =>
        s.uid === fromPrototypeData.stepId
          ? {
              ...s,
              prototypes: s.prototypes.map((p) =>
                p.uid === fromPrototypeData.prototypeId
                  ? {...p, steps: p.steps.filter((ps) => ps.uid !== stepId)}
                  : p
              ),
            }
          : s
      );

      setSelectedStepId(fromPrototypeData.stepId);
      updateSteps(updatedSteps);
    }
  };
  const onDragStart = ({source, type}) => {};
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const {
      source: {index: sourceIndex},
      destination: {index: destinationIndex},
    } = result;

    const reorderedSteps = Array.from(steps);
    const [removed] = reorderedSteps.splice(sourceIndex, 1);
    reorderedSteps.splice(destinationIndex, 0, removed);

    updateSteps(reorderedSteps);
  };

  let content;

  steps.sort((a, b) => a.indexOrder - b.indexOrder);
  if (typeformFormId != null) {
    content = (
      <Block
        className=""
        title="Typeform survey"
        icon={
          <img
            // src={typeform}
            alt="typeform-icon"
            className="icon"
            style={{height: '25px'}}
          />
        }
        hasBorder={true}
      />
    );
  } else {
    content = (
      <>
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          style={{overflow: 'auto'}}>
          <div className="list-wrapper">
            <Droppable droppableId="blocks" type="blocks">
              {(dropProvided) => (
                <>
                  <div
                    ref={dropProvided.innerRef}
                    {...dropProvided.droppableProps}>
                    {steps.map((step, index) => {
                      const {title, icon, className} = getStepData(step.type);
                      const issue = getStepIssue(step);

                      return (
                        <Draggable
                          key={step.uid}
                          draggableId={step.uid}
                          index={index}
                          isDragDisabled={evolution.isDraft !== true}>
                          {renderDraggable((dragProvided, snapshot) => (
                            <>
                              <div
                                className={classnames('block-wrapper', {
                                  'is-dragging': snapshot.isDragging,
                                })}
                                ref={dragProvided.innerRef}
                                {...dragProvided.draggableProps}
                                {...dragProvided.dragHandleProps}>
                                <Block
                                  className={classnames(className, {
                                    invalid:
                                      selectedStepId !== step.uid &&
                                      issue != null,
                                    'is-dragging': snapshot.isDragging,
                                  })}
                                  selected={selectedStepId === step.uid}
                                  active={step?.prototypes?.some((p) =>
                                    p?.steps?.some(
                                      (ps) => ps.uid === selectedStepId
                                    )
                                  )}
                                  title={title}
                                  description={
                                    selectedStepId !== step.uid ? issue : null
                                  }
                                  icon={icon}
                                  draggable
                                  prototypes={step.prototypes}
                                  onClick={() => {
                                    setSelectedStepId(step.uid);
                                  }}
                                  onDeleteClick={
                                    evolution.isDraft === true
                                      ? () => removeStep(step.uid)
                                      : null
                                  }
                                  provided={dragProvided}>
                                  {step.prototypes?.length > 0 && (
                                    <div className="sub-blocks-wrapper">
                                      {step.prototypes[0]?.steps?.map(
                                        (prototypeStep) => {
                                          const {title, icon, className} =
                                            getStepData(prototypeStep.type);
                                          const issue =
                                            getStepIssue(prototypeStep);

                                          return (
                                            <Block
                                              className={classnames(
                                                className,
                                                'sub-block',
                                                {
                                                  invalid:
                                                    selectedStepId !==
                                                      prototypeStep.uid &&
                                                    issue != null,
                                                  'is-dragging':
                                                    snapshot.isDragging,
                                                }
                                              )}
                                              selected={
                                                selectedStepId ===
                                                prototypeStep.uid
                                              }
                                              title={title}
                                              description={
                                                selectedStepId !==
                                                prototypeStep.uid
                                                  ? issue
                                                  : null
                                              }
                                              icon={icon}
                                              onClick={() => {
                                                setSelectedStepId(
                                                  prototypeStep.uid
                                                );
                                              }}
                                              onDeleteClick={
                                                evolution.isDraft === true
                                                  ? () =>
                                                      removeStep(
                                                        prototypeStep.uid,
                                                        {
                                                          stepId: step.uid,
                                                          prototypeId:
                                                            step.prototypes[0]
                                                              ?.uid,
                                                        }
                                                      )
                                                  : null
                                              }
                                              provided={dragProvided}
                                            />
                                          );
                                        }
                                      )}
                                      {evolution.isDraft === true && (
                                        <DropdownAddBlock
                                          dropdownProps={{
                                            position: 'right center',
                                            trigger: (
                                              <div className="btn-add-sub-block">
                                                add block{' '}
                                                <i className="icon-plus"></i>
                                              </div>
                                            ),
                                          }}
                                          omitBlocks={[
                                            stepsService.STEP_TYPE_CONCEPT_TEST,
                                            stepsService.STEP_TYPE_SUCCESS,
                                          ]}
                                          onAdd={(stepToAdd) =>
                                            addStep(stepToAdd, {
                                              stepId: step.uid,
                                              prototypeId:
                                                step.prototypes[0]?.uid,
                                            })
                                          }></DropdownAddBlock>
                                      )}
                                    </div>
                                  )}
                                </Block>
                              </div>
                              {dragProvided.placeholder}
                            </>
                          ))}
                        </Draggable>
                      );
                    })}
                  </div>
                  {dropProvided.placeholder}
                </>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </>
    );
  }

  return (
    <>
      <div className={classnames('block-list')}>
        {evolution.isDraft === true && (
          <div className="block-add-wrapper">
            <DropdownAddBlock
              dropdownProps={{
                trigger: (
                  <div className={'btn-add-block'}>
                    Add block <i className="icon-plus"></i>
                  </div>
                ),
              }}
              onAdd={addStep}
              omitBlocks={[stepsService.STEP_TYPE_SUCCESS]}
            />
          </div>
        )}
        {content}
      </div>
    </>
  );
};

BlockList.propTypes = propTypes;
BlockList.defaultProps = defaultProps;

export default BlockList;
