import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {Menu, MenuItem} from 'components/Menu';
import React from 'react';
import {EmojiEvolutionLiveForVote} from 'shared/front/components/Emojis';
import './_Styles.scss';

export default class DevDropdown extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }
  render() {
    return (
      <div className="_dev-dropdown">
        <Dropdown
          trigger={
            <Button muted iconOnly>
              <i className="icon-menu-vertical"></i>
            </Button>
          }
          position="bottom left">
          <Menu>
            <MenuItem
              iconPosition="left"
              icon={
                <EmojiEvolutionLiveForVote
                  width={10}></EmojiEvolutionLiveForVote>
              }>
              Move to live vote
            </MenuItem>
            <MenuItem iconPosition="left" icon="icon-edit">
              Edit
            </MenuItem>
            <MenuItem iconPosition="left" icon="icon-trash">
              Delete
            </MenuItem>
          </Menu>
        </Dropdown>
      </div>
    );
  }
}
