import React from 'react';
import './_Styles.scss';
import AudienceSection from 'scenes/PokeAudience/components/AudienceSection';
import SummaryPreview from './components/PostSummaryPreview';
import SummaryInfos from '../Summary/components/SummaryInfos';
import {number} from 'prop-types';

const propTypes = {
  index: number.isRequired,
};

const defaultProps = {};

const PostSummary = ({index}) => {
  return (
    <AudienceSection
      className="summary"
      index={index}
      title="Summary"
      isLastSection>
      <SummaryInfos isPost />
      <SummaryPreview />
    </AudienceSection>
  );
};

PostSummary.propTypes = propTypes;
PostSummary.defaultProps = defaultProps;

export default PostSummary;
