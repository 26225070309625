import Divider from 'components/Divider';
import {func} from 'prop-types';
import React, {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import './_Styles.scss';
import Triggers from './components/Triggers';

const propTypes = {
  onClose: func,
};
const defaultProps = {
  onClose: () => {},
};

const TriggerManager = ({onClose}) => {
  const {
    selectedStep: step,
    updateStep,
    selectingElementTriggerConditionId,
  } = useContext(BuilderContext);

  return (
    <div className="trigger-manager-wrapper">
      <div className="trigger-manager">
        <div className="trigger-manager-header">
          <div className="trigger-manager-title">Triggers</div>
        </div>
        <Divider />
        <div className="triggers-content">
          <Triggers
            key={selectingElementTriggerConditionId}
            step={step}
            updateStep={(upt) => updateStep(step.uid, upt)}
          />
        </div>
      </div>
    </div>
  );
};

TriggerManager.propTypes = propTypes;
TriggerManager.defaultProps = defaultProps;

export default TriggerManager;
