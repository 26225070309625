import classnames from 'classnames';
import Button from 'components/Button';
import LivePreview from 'components/LivePreview';
import {hasFlag} from 'helpers/bitwise';
import {func} from 'prop-types';
import {useContext, useEffect, useRef, useState} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import ModalCreatePoke from 'scenes/Pushes/components/ModalCreatePoke';
import {F_BOOST_SLOT_TOP_BAR} from 'services/evolution';
import './_styles.scss';

const propTypes = {
  onCustomize: func,
};

const defaultProps = {
  onCustomize: null,
};

export const Preview = ({onCustomize}) => {
  const {
    evolution: contextEvolution,
    selectedStepId,
    selectedTourStepId,
  } = useContext(BuilderContext);

  const evolution =
    selectedTourStepId != null
      ? contextEvolution.tourSteps.find((s) => s.uid === selectedTourStepId)
      : contextEvolution;

  const [showEditModal, setShowEditModal] = useState(false);

  const previewRef = useRef();

  const previewTopBar = hasFlag(F_BOOST_SLOT_TOP_BAR, evolution?.boostFlags);

  const handleSelectedStepId = (stepId) => {
    previewRef?.current?.sendMessage?.({
      action: 'STEP_ID_SELECTED',
      stepId,
      fromJimo: true,
    });
  };

  useEffect(() => {
    handleSelectedStepId(selectedStepId);
  }, [selectedStepId]);

  const hasNoDeliveryMethod = evolution?.boostFlags === 0;

  return (
    <div
      key={hasNoDeliveryMethod}
      className={classnames('builder-edit-preview fade-in', {
        'has-no-delivery-method': hasNoDeliveryMethod,
        'has-preview-top-bar': previewTopBar === true,
      })}>
      {hasNoDeliveryMethod === true ? (
        'Pick a delivery method to see how it will look like.'
      ) : (
        <>
          <div className="btn-edit-in-app">
            <Button
              className="in-app-btn"
              thin
              rounded={false}
              iconLeft="icon-edit-outline"
              onClick={() => setShowEditModal(true)}>
              edit in-app
            </Button>
          </div>
          {onCustomize != null && (
            <div className="btn-customize-wrapper">
              <Button
                thin
                rounded={false}
                iconLeft="icon-customize"
                inverted
                onClick={onCustomize}>
                Customize appearance
              </Button>
            </div>
          )}

          <div className="main-wrapper">
            <LivePreview boostedEvolution={evolution} previewRef={previewRef} />
          </div>
          <ModalCreatePoke
            isOpen={showEditModal}
            pokeToEdit={contextEvolution}
            onRequestClose={() => setShowEditModal(false)}
          />
        </>
      )}
    </div>
  );
};

Preview.propTypes = propTypes;
Preview.defaultProps = defaultProps;
