import Divider from 'components/Divider';
import {useContext, useEffect, useState} from 'react';
import {InteractiveOption} from 'scenes/Builder/component/InteractiveOption';
import {BuilderContext} from 'scenes/Builder/context';
import {jimerService} from 'services';
import {Swaler} from 'swaler';
import './_styles.scss';
import ModalSegmentation from 'scenes/PokeAudience/sections/Who/components/SegmentationModal';

const logger = new Swaler('SectionWho');

export const SectionWho = () => {
  const {evolution, setEvolution} = useContext(BuilderContext);

  const [showSegmentationModal, setShowSegmentationModal] = useState(false);
  const [jimersCount, setJimersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const hasSetupSegmentation =
    evolution.segments?.length !== 0 ||
    (evolution.onTheFlySegment != null &&
      evolution.onTheFlySegment?.attributes?.length > 0);
  const isPoke = evolution.boostFlags > 0;

  const handleResetSegmentation = () => {
    setEvolution({
      ...evolution,
      segments: [],
      onTheFlySegment: null,
    });
  };

  useEffect(() => {
    if (showSegmentationModal !== true) {
      const hasSetupSegmentation =
        evolution.segments?.length > 0 ||
        evolution.onTheFlySegment?.attributes?.length > 0;
      if (hasSetupSegmentation) {
        fetchJimersCount();
      }
    }
  }, [evolution.segments, evolution.onTheFlySegment, showSegmentationModal]);

  const fetchJimersCount = async () => {
    setIsLoading(true);
    try {
      const res = await jimerService.getJimers(
        {
          ...(evolution.onTheFlySegment?.attributes?.length > 0
            ? {attributes: evolution.onTheFlySegment.attributes}
            : {segmentIds: evolution?.segments?.map((s) => s.uid)}),
          take: 1,
          skip: 0,
        },
        {}
      );

      setJimersCount(res.total);
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }
      logger.error('Fetching jimers failed with error ', err);
    } finally {
      setIsLoading(false);
    }
  };

  const usersSelectedCountStr =
    isLoading === true ? '' : `(${jimersCount} users selected)`;
  const segmentTitle =
    hasSetupSegmentation === true
      ? evolution?.segments?.length > 0
        ? `${evolution.segments
            .map((s) => s.name)
            .join(', ')} ${usersSelectedCountStr}`
        : `On the fly segment ${usersSelectedCountStr}`
      : 'Specific users';
  let descriptionPokeType = isPoke ? 'experience' : 'post';
  let segmentDescription = `Show this ${descriptionPokeType} to a segment of users based on different attributes`;
  if (hasSetupSegmentation === true) {
    segmentDescription = `This ${descriptionPokeType} will be shown to users of ${
      evolution?.segments?.length > 0
        ? `${evolution.segments.map((s) => s.name).join(', ')} segment${
            evolution.segments.length > 1 ? 's' : ''
          }`
        : `your on the fly segment`
    }`;
  }

  return (
    <div className="builder-audience-section-who">
      <div className="section-title">
        <>
          <i className="icon-tick"></i> Who
        </>
      </div>

      <InteractiveOption
        active={hasSetupSegmentation === false}
        icon="icon-users"
        title="All people"
        description={
          isPoke
            ? 'Show this experience to everyone'
            : 'Show this post to everyone'
        }
        onClick={handleResetSegmentation}
        withCheckBox
      />
      <Divider>or</Divider>
      <InteractiveOption
        active={hasSetupSegmentation === true}
        icon="icon-users"
        title={segmentTitle}
        description={segmentDescription}
        onClick={() => {
          setShowSegmentationModal(true);
        }}
        withCheckBox
      />

      <ModalSegmentation
        isOpen={showSegmentationModal}
        onRequestClose={() => setShowSegmentationModal(false)}
        evolution={evolution}
        setEvolution={setEvolution}
      />
    </div>
  );
};
