import {BadgeAI} from 'components/BadgeAI';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {func} from 'prop-types';
import React, {useContext, useState} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_TITLE,
} from 'services/steps';
import './_Styles.scss';
import ButtonBlockInput from './components/ButtonBlockInput';
import ChoiceBlockInputs from './components/ChoiceBlockInputs';
import LabelBlockInput from './components/LabelBlockInput';
import LanguageSelector from './components/LanguageSelector';
import NpsBlockInput from './components/NpsBlockInput';
import OpenQuestionBlockInput from './components/OpenQuestionBlockInput';
import OpinionBlockInput from './components/OpinionBlockInput';
import ParagraphBlockInput from './components/ParagraphBlockInput';
import SliderBlockInput from './components/SliderBlockInput';
import TitleBlockInput from './components/TitleBlockInput';

const propTypes = {
  onClose: func,
};
const defaultProps = {
  onClose: () => {},
};

const TranslationManager = () => {
  const {
    evolution,
    setEvolution,
    smartTranslate,
    isTranslating,
    selectedStep: step,
    updateStep,
    setSelectedLanguage,
    selectedLanguage,
  } = useContext(BuilderContext);

  const [languages, setLanguages] = useState([]);

  const handleDeleteLanguage = (code) => {
    // TO-DO: delete language from prototype steps
    setEvolution({
      ...evolution,
      tourSteps: evolution.tourSteps.map((tourStep) => {
        tourStep.steps = tourStep.steps.map((step) => {
          step.blocks = step.blocks.map((block) => {
            block.translations = block.translations?.filter(
              (t) => t.language !== code
            );
            block.options = block.options?.map((option) => {
              option.labels = option.labels.filter((l) => l.language !== code);
              return option;
            });
            return block;
          });
          return step;
        });
        return tourStep;
      }),
      steps: evolution.steps.map((step) => {
        step.blocks = step.blocks.map((block) => {
          block.translations = block.translations?.filter(
            (t) => t.language !== code
          );
          block.options = block.options?.map((option) => {
            option.labels = option.labels.filter((l) => l.language !== code);
            return option;
          });
          return block;
        });
        return step;
      }),
    });
  };

  const titleBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_TITLE);
  const bodyBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_BODY);
  const tagBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_LABEL);
  const primaryButtonBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_PRIMARY_CTA
  );
  const secondaryButtonBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_SECONDARY_CTA
  );
  const choiceBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_CHOICE);
  const sliderBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_SLIDER);
  const npsBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_NPS);
  const opinionBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_OPINION);
  const openQuestionBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_OPEN_QUESTION
  );

  const updateBlock = (updateObj, blockType) => {
    updateStep(step.uid, {
      blocks: step.blocks.map((block) => {
        if (block.type === blockType) {
          return {
            ...block,
            ...updateObj,
          };
        } else {
          return block;
        }
      }),
    });
  };

  return (
    <div className="translation-manager-wrapper">
      <div className="translation-manager">
        <div className="translation-manager-header">
          <div className="translation-manager-title">Add translations</div>
          {languages?.length > 0 && selectedLanguage != null && (
            <Button
              className="smart-translate-btn"
              thin
              onClick={() => smartTranslate(languages)}
              disabled={languages.length <= 0 || isTranslating}>
              Smart translate
              <BadgeAI />
            </Button>
          )}
        </div>
        {isTranslating ? (
          <div className="loader-wrapper">
            <DefaultLoader />
            Translating...
          </div>
        ) : (
          <>
            <LanguageSelector
              evolution={evolution}
              selectedLanguage={selectedLanguage}
              onAddLanguage={setSelectedLanguage}
              onSelectLanguage={setSelectedLanguage}
              onRemoveLanguage={handleDeleteLanguage}
              languages={languages}
              setLanguages={setLanguages}
            />
            <div className="translation-manager-blocks">
              {titleBlock != null && (
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">Title</div>
                  <div className="translation-manager-block-content">
                    <TitleBlockInput
                      selectedLanguage={selectedLanguage}
                      block={titleBlock}
                      onChange={(value) => {
                        updateBlock(value, BLOCK_TYPE_TITLE);
                      }}
                    />
                  </div>
                </div>
              )}
              {bodyBlock != null && (
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">
                    Paragraph
                  </div>
                  <div className="translation-manager-block-content">
                    <ParagraphBlockInput
                      selectedLanguage={selectedLanguage}
                      block={bodyBlock}
                      onChange={(value) => {
                        updateBlock(value, BLOCK_TYPE_BODY);
                      }}
                    />
                  </div>
                </div>
              )}
              {tagBlock != null && (
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">Label</div>
                  <div className="translation-manager-block-content">
                    <LabelBlockInput
                      selectedLanguage={selectedLanguage}
                      block={tagBlock}
                      onChange={(value) => {
                        updateBlock(value, BLOCK_TYPE_LABEL);
                      }}
                    />
                  </div>
                </div>
              )}
              {primaryButtonBlock != null && (
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">
                    Primary CTA
                  </div>
                  <div className="translation-manager-block-content">
                    <ButtonBlockInput
                      selectedLanguage={selectedLanguage}
                      block={primaryButtonBlock}
                      onChange={(value) => {
                        updateBlock(value, BLOCK_TYPE_PRIMARY_CTA);
                      }}
                    />
                  </div>
                </div>
              )}
              {secondaryButtonBlock != null && (
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">
                    Secondary CTA
                  </div>
                  <div className="translation-manager-block-content">
                    <ButtonBlockInput
                      selectedLanguage={selectedLanguage}
                      block={secondaryButtonBlock}
                      onChange={(value) => {
                        updateBlock(value, BLOCK_TYPE_SECONDARY_CTA);
                      }}
                    />
                  </div>
                </div>
              )}
              {choiceBlock != null && (
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">
                    Multiple choice
                  </div>
                  <div className="translation-manager-block-content">
                    <ChoiceBlockInputs
                      selectedLanguage={selectedLanguage}
                      block={choiceBlock}
                      onChange={(value) => {
                        updateBlock(value, BLOCK_TYPE_CHOICE);
                      }}
                    />
                  </div>
                </div>
              )}
              {sliderBlock != null && (
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">Slider</div>
                  <div className="translation-manager-block-content">
                    <SliderBlockInput
                      selectedLanguage={selectedLanguage}
                      block={sliderBlock}
                      onChange={(value) => {
                        updateBlock(value, BLOCK_TYPE_SLIDER);
                      }}
                    />
                  </div>
                </div>
              )}
              {npsBlock != null && (
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">NPS</div>
                  <div className="translation-manager-block-content">
                    <NpsBlockInput
                      selectedLanguage={selectedLanguage}
                      block={npsBlock}
                      onChange={(value) => {
                        updateBlock(value, BLOCK_TYPE_NPS);
                      }}
                    />
                  </div>
                </div>
              )}
              {opinionBlock != null && (
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">
                    Opinion scale
                  </div>
                  <div className="translation-manager-block-content">
                    <OpinionBlockInput
                      selectedLanguage={selectedLanguage}
                      block={opinionBlock}
                      onChange={(value) => {
                        updateBlock(value, BLOCK_TYPE_OPINION);
                      }}
                    />
                  </div>
                </div>
              )}
              {openQuestionBlock != null && (
                <div className="translation-manager-block">
                  <div className="translation-manager-block-title">
                    Open question
                  </div>
                  <div className="translation-manager-block-content">
                    <OpenQuestionBlockInput
                      selectedLanguage={selectedLanguage}
                      block={openQuestionBlock}
                      onChange={(value) => {
                        updateBlock(value, BLOCK_TYPE_OPEN_QUESTION);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

TranslationManager.propTypes = propTypes;
TranslationManager.defaultProps = defaultProps;

export default TranslationManager;
