import classnames from 'classnames';
import React from 'react';
import './_styles.scss';
import {PostContext} from './context';
import PostBlockBody from './components/PostBlockBody';
import PostBlockLabel from './components/PostBlockLabel';
import PostBlockTitle from './components/PostBlockTitle';
import PostBlockUser from './components/PostBlockUser';
import PostBlockSocials from './components/PostBlockSocials';
import PostBlockCta from './components/PostBlockCta';
import {useState} from 'react';

export const F_STEP_BLOCK_CLOSE_DISABLED = 1;
export const F_STEP_HEIGHT_CUSTOM = 2;
export const F_STEP_DISCOVERY_STEP_REQUIRED = 4;
export const F_OPTION_POKE_CARD_WITH_POINTER = 131072;

export const FADE_OUT_DELAY = 500;
export const JIMO_LABEL_HEIGHT = 34;
export const NO_JIMO_LABEL_EXTRA_HEIGHT = 24;

export const Post = ({
  AnalyticViewTrigger = null,
  post,
  language = null,
  theme = null,
  onLaunchPoke = () => {},
  onUrlClick = () => {},
  onImageClick = () => {},
  PostBlockSocialsContent = null,
  enableCollapse = false,
  ReadMoreOverlay = null,
  hideDate = false,
  addFontFamily = () => {},
}) => {
  const [contentHeight, setContentHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const {cards} = theme ?? {};
  const {backgroundColor, shadow, borderRadius} = cards ?? {};
  const {x, y, blur, color} = shadow ?? {};
  const boxShadow = `${x}px ${y}px ${blur}px ${color}`;

  const isCollapsed =
    enableCollapse === true && contentHeight > 340 && isExpanded !== true;

  return (
    <PostContext.Provider
      value={{
        post,
        language,
        theme,
        onLaunchPoke,
        onUrlClick,
        onImageClick,
        AnalyticViewTrigger,
        hideDate,
        PostBlockSocialsContent,
        addFontFamily,
      }}>
      <div
        className={classnames('jimo-post', {
          'is-collapsed': isCollapsed,
        })}
        style={{
          ...(backgroundColor != null
            ? {backgroundColor: backgroundColor}
            : {}),
          ...(shadow != null
            ? {
                boxShadow,
              }
            : {}),
          ...(borderRadius != null
            ? {
                borderRadius: `${borderRadius}px`,
              }
            : {}),
        }}>
        <div
          className="jimo-post-content"
          ref={(el) => {
            if (el != null) {
              setContentHeight(el.offsetHeight);
            }
          }}>
          <PostBlockUser />
          <PostBlockLabel />
          <PostBlockTitle />
          <PostBlockBody />
          <PostBlockCta />
          <PostBlockSocials />
        </div>
        {isCollapsed && ReadMoreOverlay != null && (
          <ReadMoreOverlay
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
              setIsExpanded(true);
            }}
          />
        )}
      </div>
    </PostContext.Provider>
  );
};
