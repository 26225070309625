import React, {useContext, useState} from 'react';
import './_Styles.scss';
import AudienceSection from 'scenes/PokeAudience/components/AudienceSection';
import {number} from 'prop-types';
import Button from 'components/Button';
import ModalCreatePoke, {
  MODE_PICK_BOOST,
} from 'scenes/Pushes/components/ModalCreatePoke';
import {AudienceContext} from 'scenes/PokeAudience';
import {
  EVOLUTION_STATE_LIVE,
  EVOLUTION_STATE_PAUSED,
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_POP_IN,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOP_BAR,
  F_BOOST_SLOT_TOUR,
} from 'services/evolution';
import classNames from 'classnames';
import {
  PokeAudience,
  PokeDetails,
  PokePreview,
} from 'scenes/Pushes/components/PushesTable';
import {useQuery} from 'react-query';
import {evolutionService} from 'services';
import dayjs from 'dayjs';
import {hasFlag} from 'helpers/bitwise';
import DefaultLoader from 'components/Loader';

const BoostPoke = ({poke}) => {
  const {
    isDraft,
    state,
    lastStepChangeAt,
    expiresAt,
    title,
    createdAt,
    boostFlags,
    tourSteps,
    icon,
    tags,
    onTheFlySegment,
    segments,
    analytics,
    type,
  } = poke;

  const previewPoke = poke?.tourSteps[0] || poke;

  const isLive =
    isDraft !== true && (state === EVOLUTION_STATE_LIVE || state == null);
  const isPaused = isDraft !== true && state === EVOLUTION_STATE_PAUSED;
  const isScheduled =
    isDraft !== true && dayjs(lastStepChangeAt).isAfter(dayjs());
  const isExpired =
    isDraft !== true && expiresAt != null && dayjs(expiresAt).isBefore(dayjs());

  let typeBoostFlags = boostFlags;
  if (hasFlag(F_BOOST_SLOT_TOUR, boostFlags) && tourSteps?.length <= 1) {
    typeBoostFlags = tourSteps[0]?.boostFlags;
  }

  const isTour = hasFlag(F_BOOST_SLOT_TOUR, typeBoostFlags);
  const isParentTour = hasFlag(F_BOOST_SLOT_TOUR, poke.boostFlags);
  const isHotspot = hasFlag(F_BOOST_SLOT_DOT, typeBoostFlags);
  const isTooltip = hasFlag(F_BOOST_SLOT_TOOLTIP, typeBoostFlags);
  const isBanner = hasFlag(F_BOOST_SLOT_TOP_BAR, typeBoostFlags);
  const isSnippet = hasFlag(F_BOOST_SLOT_SNIPPET, typeBoostFlags);
  const isPopIn = hasFlag(F_BOOST_SLOT_POP_IN, typeBoostFlags);

  const audience =
    onTheFlySegment != null
      ? 'On the fly segmentation'
      : segments?.length > 0
      ? segments.map((s) => s.name).join(', ')
      : 'All users';

  return (
    <div className={classNames('boost-poke')}>
      <PokePreview
        poke={poke}
        previewPoke={previewPoke}
        isExpired={isExpired}
        isScheduled={isScheduled}
        isLive={isLive}
        isPaused={isPaused}
      />
      <div className="infos">
        <div className="push-title-wrapper">
          <div className="push-title">
            <div className="boosted-icon-wrapper">
              <i className="icon-send-o" />
            </div>
            {title}
          </div>
          <div className="created-at">Created {dayjs(createdAt).fromNow()}</div>
        </div>
        <PokeDetails
          isTour={isTour}
          isSnippet={isSnippet}
          isBanner={isBanner}
          isHotspot={isHotspot}
          isPopIn={isPopIn}
          isTooltip={isTooltip}
          icon={icon}
          tags={tags}
        />
      </div>
      <PokeAudience
        audience={audience}
        analytics={analytics}
        experienceType={type}
      />
    </div>
  );
};

const propTypes = {
  index: number.isRequired,
};

const defaultProps = {};

const Boost = ({index}) => {
  const {evolution} = useContext(AudienceContext);

  const [showBoostModal, setShowBoostModal] = useState(false);

  const isBoosted = evolution.boostedBy != null;

  const {data: poke, isLoading} = useQuery({
    queryKey: ['poke', evolution.boostedBy?.uid],
    queryFn: async () => {
      const poke = await evolutionService.getEvolutionById(
        evolution.boostedBy?.uid,
        {
          relations: ['author', 'tags', 'steps', 'analytics', 'tourSteps'],
        }
      );
      return poke;
    },
    enabled: evolution.boostedBy?.uid != null,
  });

  return (
    <>
      <AudienceSection
        className="boost"
        index={index}
        title="Boost"
        icon={<i className="icon-send-o" />}
        isLastSection>
        {isBoosted ? (
          <div className="boosted-content">
            {isLoading === true ? (
              <div className="loader-wrapper">
                <DefaultLoader />
              </div>
            ) : (
              <BoostPoke poke={poke} />
            )}
          </div>
        ) : (
          <div className="not-boosted-content">
            <div className="boost-title">This post hasn't been posted yet</div>
            <Button
              className="boost-btn"
              primary
              reverted
              iconLeft="icon-send-o"
              onClick={() => setShowBoostModal(true)}>
              Boost this post
            </Button>
          </div>
        )}
      </AudienceSection>

      {showBoostModal && (
        <ModalCreatePoke
          isOpen={showBoostModal}
          onRequestClose={() => setShowBoostModal(false)}
          defaultMode={MODE_PICK_BOOST}
          boostOf={evolution}
        />
      )}
    </>
  );
};

Boost.propTypes = propTypes;
Boost.defaultProps = defaultProps;

export default Boost;
