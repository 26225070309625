import React, {useContext} from 'react';
import {F_OPTION_DISMISS_ON_CROSS_CLICK} from '../..';
import {hasFlag} from '../../../../helpers/bitwise';
import {
  BLOCK_BODY,
  BLOCK_LABEL,
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_TITLE,
} from '../../constants/blocks';
import {PokeContext, PokeStateContext} from '../../context';
import {BlockBody} from '../BlockBody';
import {BlockClose} from '../BlockClose';
import {BlockCta} from '../BlockCta';
import {BlockLabel} from '../BlockLabel';
import {BlockTitle} from '../BlockTitle';
import './_styles.scss';

export const LayoutBanner = () => {
  const {poke} = useContext(PokeContext);
  const {currentStep, addBlockElem} = useContext(PokeStateContext);

  const shouldShowBlockCta = currentStep.blocks.find((b) =>
    [BLOCK_PRIMARY_CTA, BLOCK_SECONDARY_CTA].includes(b.type)
  );

  const hasLabel =
    currentStep.blocks.find((b) => b.type === BLOCK_LABEL) != null;
  const hasTitle =
    currentStep.blocks.find((b) => b.type === BLOCK_TITLE) != null;
  const hasBody = currentStep.blocks.find((b) => b.type === BLOCK_BODY) != null;
  const shouldShowBlockClose =
    hasFlag(F_OPTION_DISMISS_ON_CROSS_CLICK, poke.optionsFlags) === true;

  return (
    <div
      className="poke-layout-banner"
      ref={(elem) => {
        addBlockElem('banner', elem);
      }}>
      <div className="layout-banner-left-col"></div>
      <div className="layout-banner-center-col">
        <div className="layout-banner-center-col-content">
          {(hasLabel || hasTitle) && (
            <div className="layout-banner-center-col-content-top">
              {hasLabel && <BlockLabel />}
              {hasTitle && <BlockTitle />}
            </div>
          )}
          {hasBody && (
            <div className="layout-banner-center-col-content-bottom">
              <BlockBody />
            </div>
          )}
        </div>
        {shouldShowBlockCta != null && <BlockCta />}
      </div>
      {shouldShowBlockClose === true && (
        <div className="layout-banner-right-col">
          <BlockClose />
        </div>
      )}
    </div>
  );
};
