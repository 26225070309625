import {toastDanger, toastInfo} from 'components/Toaster';
import {PermissionsSettings} from 'constants/permissions';
import {GlobalContext} from 'contextes/Global';
import {errorHelpers} from 'helpers';
import {hasPermissions} from 'helpers/permission';
import {delay} from 'helpers/utils';
import {useContext} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {subscriptionService} from 'services';
import {
  ADDON_WHITE_LABEL_ID,
  getCurrentAddons,
  hasAnnualBilling,
} from 'services/subscription';
import {Swaler} from 'swaler';
import {useUpdateAddons} from './useUpdateAddons';

const logger = new Swaler('useAddonCancel');

export const useAddonCancel = ({
  onSuccess = () => {},
  onError = () => {},
} = {}) => {
  const {setModalAddonCancel} = useContext(GlobalContext);

  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const {update} = useUpdateAddons();

  async function cancel(addonId) {
    setModalAddonCancel({
      addonId,
      onCancel: () => {
        setModalAddonCancel(null);
      },
      onSubmit: async () => {
        await doCancel(addonId).then(() => {
          setModalAddonCancel(null);
          if (addonId === ADDON_WHITE_LABEL_ID) {
            toastInfo([
              'Addon canceled',
              'Jimo label will now be displayed on your experiences',
            ]);
          }
        });
      },
    });
  }

  async function doCancel(addonId) {
    const canDo = hasPermissions([PermissionsSettings.BILLING_ACCESS]);
    const currentAddons = getCurrentAddons();

    if (canDo === false) {
      return toastDanger([
        'Permission denied',
        "You don't have the permissions to cancel this addon. For more information, please contact your project administrator.",
      ]);
    }
    if (currentAddons.includes(ADDON_WHITE_LABEL_ID) === false) {
      return toastInfo([
        'Addon already disabled',
        'It seems that this addon is already disabled.',
      ]);
    }

    try {
      await subscriptionService.createOrUpdateStripeSubscription({
        plan: subscription.plan,
        addons: currentAddons.filter((addon) => addon !== ADDON_WHITE_LABEL_ID),
        modeAnnual: hasAnnualBilling(),
      });
      // Add some delay for the stripe webhook to be triggered
      await delay(2000);
      await update();
      onSuccess();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Canceling addon (${addonId}) failed with error `, code);
      toastDanger([title, message], {
        actions,
      });
      throw new Error('CANCELING_ADDON_FAILED');
    }
  }

  return {
    cancel,
  };
};
