import Axios from 'axios';
import {generalSelector} from 'selectors';

export const F_CONFIG_EVOLUTION_CREATION = 1;
export const F_CONFIG_EVOLUTION_STATUS_CHANGE = 2;

// Endpoint
const EP_OPENAI_TRANSLATE = '/openai/translate';

// Method
export const translate = ({text, languages}) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_OPENAI_TRANSLATE, {text, languages, projectId}).then(
    (response) => response.data
  );
};
