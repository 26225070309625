import {EVENT_LAUNCH_PUSH} from 'amplitude';
import amplitude from 'amplitude-js';
import classnames from 'classnames';
import Button from 'components/Button';
import Input from 'components/Input';
import ModalInstall from 'components/ModalInstall';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Tooltip from 'components/Tooltip';
import {errorHelpers} from 'helpers';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {bool} from 'prop-types';
import {useContext, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {
  ROUTE_BUILDER_AUDIENCE,
  ROUTE_BUILDER_CHANNEL,
  ROUTE_BUILDER_CREATE,
  ROUTE_BUILDER_EDIT,
  ROUTE_FEED,
  ROUTE_GET_STARTED_INSTALL,
  ROUTE_TOURS,
} from 'router/routes.const';
import {BuilderContext} from 'scenes/Builder/context';
import {generalSelector} from 'selectors';
import {evolutionService} from 'services';
import {
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_POP_IN,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOP_BAR,
  F_BOOST_SLOT_TOUR,
  F_OPTION_PORTAL_DISPLAY_FEED,
  F_OPTION_PORTAL_DISPLAY_FEEDBACK,
  F_OPTION_PORTAL_DISPLAY_ROADMAP,
} from 'services/evolution';
import {F_EXTRA_CAN_PUBLISH} from 'services/project';
import {
  F_GROWTH_TRIAL_USED,
  F_STARTUP_TRIAL_USED,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import {Swaler} from 'swaler';
import './_styles.scss';
import {isPokeValid} from './utils';

const logger = new Swaler('BuilderHeader');

export const evolutionIcons = [
  {
    value: 'DEFAULT',
    icon: (
      <div className="icon-wrapper scratch">
        <i className="icon-radiobox-o" />
      </div>
    ),
  },
  {
    value: 'IDEATION',
    icon: (
      <div className="icon-wrapper discovery">
        <i className="icon-filter-ideation" />
      </div>
    ),
  },
  {
    value: 'INTERVIEW',
    icon: (
      <div className="icon-wrapper discovery">
        <i className="icon-user-interview" />
      </div>
    ),
  },
  {
    value: 'PREVIEW',
    icon: (
      <div className="icon-wrapper discovery">
        <i className="icon-filter-preview" />
      </div>
    ),
  },
  {
    value: 'SURVEY',
    icon: (
      <div className="icon-wrapper discovery">
        <i className="icon-user-satisfaction"></i>
      </div>
    ),
  },
  {
    value: 'ANNOUNCEMENT',
    icon: (
      <div className="icon-wrapper adoption">
        <i className="icon-filter-announcement"></i>
      </div>
    ),
  },
  {
    value: 'ONBOARDING',
    icon: (
      <div className="icon-wrapper adoption">
        <i className="icon-tour"></i>
      </div>
    ),
  },
  {
    value: 'CHANGELOG',
    icon: (
      <div className="icon-wrapper discovery">
        <i className="isax isax-slider-vertical5"></i>
      </div>
    ),
  },
];

const propTypes = {
  isFetching: bool,
  isPoke: bool,
  isLoading: bool,
};

const defaultProps = {
  isFetching: false,
  isPoke: false,
  isLoading: false,
};

export const BuilderHeader = ({isFetching, isPoke, isLoading}) => {
  const match = useRouteMatch();

  const history = useHistory();

  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const project = useSelector((state) => generalSelector.getProject(state));

  const {
    evolution,
    setEvolution,
    originalEvolution,
    setOriginalEvolution,
    hasUnSaveChanges,
    refetchEvolution,
  } = useContext(BuilderContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModalInstall, setShowModalInstall] = useState(false);

  const refInputTitle = useRef();

  const {update} = useUpdateSubscription();

  const isSubmitDisabled = isPokeValid(evolution) !== true;
  const isPublishDisabled = isPokeValid(evolution, true) !== true;
  const isInPortal = hasFlags(
    [
      F_OPTION_PORTAL_DISPLAY_FEED,
      F_OPTION_PORTAL_DISPLAY_FEEDBACK,
      F_OPTION_PORTAL_DISPLAY_ROADMAP,
    ],
    evolution.optionsFlags,
    true
  );

  const isActive = (path) => [].concat(path).includes(match.path);
  const handleUpdateEvolution = async (route, shouldLaunch = false) => {
    if (hasUnSaveChanges() === false && shouldLaunch === false) {
      toastSuccess('Changes saved', {toastId: 'changes-saved'});
      return history.push(route);
    }
    setIsSubmitting(true);
    try {
      await evolutionService.updateEvolution(evolution.uid, {
        ...evolution,
      });
      if (shouldLaunch === true && evolution.isDraft === true) {
        await evolutionService.publish(evolution.uid);
        toastSuccess(
          [
            evolution.isDraft === true && shouldLaunch === true
              ? 'Published'
              : 'Changes saved',
            hasFlag(F_EXTRA_CAN_PUBLISH, project.extraFlags) === false
              ? hasFlags(
                  [F_STARTUP_TRIAL_USED, F_GROWTH_TRIAL_USED],
                  subscription.extraFlags
                ) === true
                ? 'Upgrade to make it live!'
                : 'Start your trial to make it live!'
              : null,
          ],
          {toastId: 'changes-saved'}
        );
        if (hasFlag(F_EXTRA_CAN_PUBLISH, project.extraFlags) === false) {
          setIsSubmitting(false);
          return update({planId: PLAN_STARTUP_ID});
        }
        if (isInPortal === false && project.snippetInstalledAt == null) {
          return history.push(ROUTE_GET_STARTED_INSTALL);
        }
      }
      refetchEvolution();
      setIsSubmitting(false);
      if (route != null) {
        history.push(route);
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Update evolution failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };
  const handleNav = (path) => {
    if (isSubmitDisabled === true) {
      return;
    }
    if (hasUnSaveChanges() === true) {
      return;
    }
    return history.push(path);
  };
  const handleUpdateTitle = async () => {
    try {
      await evolutionService.updateEvolutionTitle(
        evolution.uid,
        evolution.title
      );
      setOriginalEvolution({...originalEvolution, title: evolution.title});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Update evolution title', code);
      toastDanger([title, message], {actions});
    }
  };

  const {context} = evolution;

  return (
    <div className="builder-header">
      <div className="left-wrapper">
        <Button
          className="btn-back"
          iconLeft="icon-chevron-left"
          onClick={() =>
            isPoke ? history.push(ROUTE_TOURS) : history.push(ROUTE_FEED)
          }>
          Quit
        </Button>
        <div className="name-wrapper">
          {isFetching === false && (
            <Input
              ref={refInputTitle}
              className="input-name"
              muted
              value={evolution.title}
              onPressEnter={() => {
                refInputTitle.current.blur();
              }}
              onChange={({target}) =>
                setEvolution({...evolution, title: target.value})
              }
              onBlur={() => handleUpdateTitle()}></Input>
          )}
        </div>
      </div>
      {isLoading === false && (
        <div
          className={classnames('center-wrapper', {
            'is-interactive':
              isSubmitDisabled === false && hasUnSaveChanges() === false,
          })}>
          {isPoke ? (
            <div
              className={classnames('step-item', {
                'is-active': isActive(ROUTE_BUILDER_EDIT()),
              })}
              onClick={() => handleNav(ROUTE_BUILDER_EDIT(evolution.uid))}>
              {hasUnSaveChanges() === true ? (
                <Tooltip
                  className="builder-header-tooltip"
                  trigger={<span>Edit</span>}>
                  You have unsaved work, please save first.
                </Tooltip>
              ) : (
                <>Edit</>
              )}
            </div>
          ) : (
            <>
              <div
                className={classnames('step-item', {
                  'is-active': isActive(ROUTE_BUILDER_CREATE()),
                })}
                onClick={() =>
                  handleNav(ROUTE_BUILDER_CREATE({evolutionId: evolution.uid}))
                }>
                {hasUnSaveChanges() === true ? (
                  <Tooltip
                    className="builder-header-tooltip"
                    trigger={<span>Create</span>}>
                    You have unsaved work, please save first.
                  </Tooltip>
                ) : (
                  <>Create</>
                )}
              </div>
              <div
                className={classnames('step-item', {
                  'is-active': isActive(ROUTE_BUILDER_CHANNEL()),
                })}
                onClick={() => handleNav(ROUTE_BUILDER_CHANNEL(evolution.uid))}>
                {hasUnSaveChanges() === true ? (
                  <Tooltip
                    className="builder-header-tooltip"
                    trigger={<span>Channel</span>}>
                    You have unsaved work, please save first.
                  </Tooltip>
                ) : (
                  <>Channel</>
                )}
              </div>
            </>
          )}
          <div
            className={classnames('step-item', {
              'is-active': isActive(ROUTE_BUILDER_AUDIENCE()),
            })}
            onClick={() => handleNav(ROUTE_BUILDER_AUDIENCE(evolution.uid))}>
            {hasUnSaveChanges() === true ? (
              <Tooltip
                className="builder-header-tooltip"
                trigger={<span>Audience</span>}>
                You have unsaved work, please save first.
              </Tooltip>
            ) : (
              <>Audience</>
            )}
          </div>
        </div>
      )}
      <div className="right-wrapper">
        {[
          ROUTE_BUILDER_EDIT(),
          ROUTE_BUILDER_CHANNEL(),
          ROUTE_BUILDER_AUDIENCE(),
        ].includes(match.path) &&
          hasUnSaveChanges() === true && (
            <Button
              cta
              className="fade-in"
              onClick={() => setEvolution({...originalEvolution})}>
              Cancel
            </Button>
          )}
        {[ROUTE_BUILDER_AUDIENCE()].includes(match.path) &&
          evolution.isDraft === true && (
            <Button
              cta
              loading={isSubmitting}
              disabled={isSubmitDisabled}
              className="fade-in btn-save-draft"
              onClick={() => handleUpdateEvolution()}>
              Save draft
            </Button>
          )}
        {[ROUTE_BUILDER_CREATE()].includes(match.path) && (
          <Button
            cta
            primary
            className="btn-continue-to-channel"
            iconRight="icon-chevron-right"
            loading={isSubmitting}
            disabled={isSubmitDisabled}
            onClick={() =>
              handleUpdateEvolution(ROUTE_BUILDER_CHANNEL(evolution.uid))
            }>
            {hasUnSaveChanges() === true
              ? 'Save and go to channel'
              : 'Go to channel'}
          </Button>
        )}
        {[ROUTE_BUILDER_CHANNEL(), ROUTE_BUILDER_EDIT()].includes(
          match.path
        ) && (
          <Button
            cta
            primary
            className="btn-continue-to-audience"
            iconRight="icon-chevron-right"
            loading={isSubmitting}
            disabled={isSubmitDisabled}
            onClick={() => {
              handleUpdateEvolution(ROUTE_BUILDER_AUDIENCE(evolution.uid));
            }}>
            {hasUnSaveChanges() === true
              ? 'Save and continue to audience'
              : 'Continue to audience'}
          </Button>
        )}
        {[ROUTE_BUILDER_AUDIENCE()].includes(match.path) && (
          <Button
            cta
            primary
            className="btn-publish"
            iconRight="icon-chevron-right"
            loading={isSubmitting}
            disabled={isPublishDisabled}
            onClick={() => {
              if (evolution.isDraft !== false) {
                amplitude.getInstance().logEvent(EVENT_LAUNCH_PUSH, {
                  isTopBar: hasFlag(F_BOOST_SLOT_TOP_BAR, evolution.boostFlags),
                  isPopIn: hasFlag(F_BOOST_SLOT_POP_IN, evolution.boostFlags),
                  isSnippet: hasFlag(
                    F_BOOST_SLOT_SNIPPET,
                    evolution.boostFlags
                  ),
                  isDot: hasFlag(F_BOOST_SLOT_DOT, evolution.boostFlags),
                  isTour: hasFlag(F_BOOST_SLOT_TOUR, evolution.boostFlags),
                  isTooltip: hasFlag(
                    F_BOOST_SLOT_TOOLTIP,
                    evolution.boostFlags
                  ),
                  isInFeed: hasFlag(
                    F_OPTION_PORTAL_DISPLAY_FEED,
                    evolution.optionsFlags
                  ),
                  isInRoadmap: hasFlag(
                    F_OPTION_PORTAL_DISPLAY_ROADMAP,
                    evolution.optionsFlags
                  ),
                  isInFeedback: hasFlag(
                    F_OPTION_PORTAL_DISPLAY_FEEDBACK,
                    evolution.optionsFlags
                  ),
                });
              }
              isPoke
                ? handleUpdateEvolution(ROUTE_TOURS, true)
                : handleUpdateEvolution(ROUTE_FEED, true);
            }}>
            {evolution.isDraft === false ? 'Save' : 'Publish'}
          </Button>
        )}
      </div>
      <ModalInstall
        isOpen={showModalInstall}
        onRequestClose={() => setShowModalInstall(false)}
      />
    </div>
  );
};

BuilderHeader.propTypes = propTypes;
BuilderHeader.defaultProps = defaultProps;
