import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import classnames from 'classnames';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Input from 'components/Input';
import RadioGroup from 'components/RadioGroup';
import {toastDanger, toastWarning} from 'components/Toaster';
import countries from 'country-codes-list';
import {crispHelpers, errorHelpers} from 'helpers';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {couponService, subscriptionService} from 'services';
import {isEligibleToTrial} from 'services/project';
import {Swaler} from 'swaler';
import {UpgradeResume} from './upgrade-resume';

const logger = new Swaler('CheckoutForm');
const cardOptions = {
  hidePostalCode: true,
  showIcon: true,
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const crispMessages = {
  country: (error, country) =>
    `My country (${country}) is not recognize, can you help me?`,
  other: (error, title) =>
    `I couldn't upgrade to ${title}! I'm getting the following error : ${error.message} (${error?.body?.message})`,
};

export const CheckoutForm = ({plan, onSuccess, defaultModeAnnualBilling}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [showCouponInput, setShowCouponInput] = useState(false);
  const [error, setError] = useState(null);
  const [inputCoupon, setInputCoupon] = useState('');
  const [cardEmpty, setCardEmpty] = useState(true);
  const [inputCardholderName, setInputCardholderName] = useState('');
  const [inputVAT, setInputVAT] = useState('');
  const [inputZip, setInputZip] = useState('');
  const [inputCountry, setInputCountry] = useState('');
  const [modeAnnualBilling, setModeAnnualBilling] = useState(
    defaultModeAnnualBilling
  );
  const [taxData, setTaxData] = useState([]);
  const [fetchingTaxData, setFetchingTaxData] = useState(false);

  const {isFetching: isConfirming, refetch: confirm} = useQuery({
    queryKey: 'stripeConfirm',
    enabled: false,
    retry: false,
    queryFn: async () => {
      let result = null;
      let stripeResponse = null;

      setError(null);
      if (!stripe || !elements) {
        logger.error('Stripe or Stripe Elements not initialized!');
        throw new Error('STRIPE_NOT_READY');
      }
      if (cardEmpty || inputZip.length === 0 || inputCountry.length === 0) {
        return;
      }
      await subscriptionService.updateStripeCustomer({
        postalCode: inputZip,
        countryCode: inputCountry,
        name: inputCardholderName,
        ...(inputVAT.length > 0 ? {vatNumber: inputVAT} : {}),
      });
      result = await subscriptionService.createOrUpdateStripeSubscription(
        {plan: plan.uid, modeAnnual: modeAnnualBilling},
        {
          stripePromotionCodeId:
            couponData != null ? couponData.stripePromotionCodeId : undefined,
        }
      );
      if (result.clientSecret == null) {
        return onSuccess();
      }
      stripeResponse = await stripe.confirmCardPayment(result.clientSecret, {
        payment_method: {card: elements.getElement(CardElement)},
      });
      if (stripeResponse.error == null) {
        return onSuccess();
      }
      if (stripeResponse.error) {
        const errorCode =
          stripeResponse.error.decline_code || stripeResponse.error.code;

        logger.error('Stripe answered with error code ', errorCode);
        setError({context: 'stripe', errorCode});
      }
    },
    onError: (err) => {
      setError({context: 'other', err});
    },
  });

  let {
    data: couponData,
    isFetching: isFetchingCoupon,
    refetch: refetchCoupon,
    remove: resetCoupon,
  } = useQuery({
    queryKey: 'fetchCoupon',
    queryFn: async () => couponService.fetchCheckoutCoupon(inputCoupon),
    enabled: false,
    initialData: null,
    onSuccess: (data) => {
      if (data == null) {
        toastDanger(
          [
            'Coupon not found',
            'We are unable to find this coupon. Feel free to contact us!',
          ],
          {
            actions: [
              {
                text: 'Contact support',
                props: {
                  onClick: () =>
                    crispHelpers.startCrispThread(
                      `My coupon ${inputCoupon} isn't working. Can you help me ? :-)`
                    ),
                  iconLeft: 'icon-chat',
                },
              },
            ],
          }
        );
      }
      setInputCoupon('');
    },
    onError: (err) => {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Fetch checkout coupon data failed with error ', code);
      toastDanger([title, message], {actions});
    },
  });

  const fetchTaxData = async (countryCode = inputCountry) => {
    if (
      inputCountry.length === 0 ||
      inputZip.length === 0 ||
      countryCode.length === 0
    ) {
      setTaxData([]);
      return;
    }

    setFetchingTaxData(true);
    try {
      const taxData = await subscriptionService.getTaxData({
        plan: plan.uid,
        modeAnnual: modeAnnualBilling,
        country: countryCode,
        zip: inputZip,
      });

      setTaxData(taxData);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Fetch checkout coupon data failed with error ', code);
      toastWarning([title, message], {actions});
    } finally {
      setFetchingTaxData(false);
    }
  };

  useEffect(() => {
    resetCoupon();
  }, []);

  return (
    <div className="content-wrapper">
      <div className="upgrade-form">
        {error != null && (
          <Alert danger>
            {error.context === 'country' ? (
              <>
                We couldn't recognize your country, check it and try again.
                <br />
                Please enter full country name (ex. France, United States)
              </>
            ) : (
              <>Ups, something went wrong when trying to upgrade.</>
            )}
            <br />
            <span
              className="crisp-trigger"
              onClick={() =>
                crispHelpers.startCrispThread(
                  error.context === 'country'
                    ? crispMessages.country(error, inputCountry)
                    : crispMessages.other(error, plan.title)
                )
              }>
              &rarr; Talk with our support
            </span>
          </Alert>
        )}
        <div className="upgrade-title">Upgrade to {plan.title}</div>
        <RadioGroup
          className="element-billing-period"
          options={[
            {label: 'Pay Monthly', value: 'monthly'},
            {
              label: (
                <div>
                  Pay Yearly <span>-16%</span>
                </div>
              ),
              value: 'yearly',
            },
          ]}
          value={modeAnnualBilling === true ? 'yearly' : 'monthly'}
          onSelect={(value) => {
            if (value === 'monthly') {
              setModeAnnualBilling(false);
            } else {
              setModeAnnualBilling(true);
            }
          }}
        />
        <Divider />
        <Input
          className="my-stripe-element element-name"
          value={inputCardholderName}
          placeholder="Cardholder name"
          onChange={({target}) => setInputCardholderName(target.value)}></Input>
        <div className="inputs-address-lines-wrapper">
          <Input
            className="my-stripe-element element-zip"
            value={inputZip}
            placeholder="Postal code"
            onChange={({target}) => setInputZip(target.value)}
            onBlur={() => fetchTaxData()}></Input>
          <div className="element-select-wrapper">
            <select
              className={classnames('my-stripe-element element-country', {
                'is-empty': inputCountry.length === 0,
              })}
              onChange={({target}) => {
                setInputCountry(target.value);
                fetchTaxData(target.value);
              }}>
              <option value="" selected disabled>
                Country
              </option>
              {countries
                .all()
                .sort((a, b) => a.countryNameEn.localeCompare(b.countryNameEn))
                .map((country) => (
                  <option value={country.countryCode}>
                    {country.countryNameEn}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <Input
          className="my-stripe-element element-vat"
          value={inputVAT}
          placeholder="VAT / GST Number (optional)"
          onChange={({target}) => setInputVAT(target.value)}></Input>
        {showCouponInput === true ? (
          <Input
            className="my-stripe-element element-coupon"
            placeholder="Coupon (optional)"
            value={inputCoupon}
            onChange={(e) => {
              if (couponData != null) {
                resetCoupon();
              }
              setInputCoupon(e.target.value);
            }}
            labelTextRight={
              inputCoupon.length !== 0 ? (
                <Button
                  onClick={refetchCoupon}
                  thin
                  light
                  loading={isFetchingCoupon}>
                  apply
                </Button>
              ) : null
            }></Input>
        ) : (
          <span
            className="label-show-coupon"
            onClick={() => setShowCouponInput(true)}>
            <i className="icon-plus"></i>apply a discount coupon
          </span>
        )}
        <CardElement
          className="input-card-element"
          options={cardOptions}
          onChange={({empty}) => setCardEmpty(empty)}></CardElement>
      </div>
      <div className="vertical-divider"></div>
      <UpgradeResume
        plan={plan}
        coupon={couponData}
        modeAnnualBilling={modeAnnualBilling}
        taxData={taxData}
        eligibleToTrial={isEligibleToTrial(plan.uid)}
        requireApplyCoupon={inputCoupon.length !== 0 && couponData == null}
        onConfirm={confirm}
        isConfirming={isConfirming}
        isFetchingTaxData={fetchingTaxData}
        submitDisabled={
          cardEmpty ||
          inputZip.length === 0 ||
          inputCountry.length === 0 ||
          fetchingTaxData === true
        }></UpgradeResume>
    </div>
  );
};
