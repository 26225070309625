import {generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag, hasFlags, reverseFlag} from 'helpers/bitwise';
import Lottie from 'lottie-react';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_ONBOARDING_DETAILS_STEP_2_3} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {meService, projectService} from 'services';
import {ME_F_ONBOARDING_DONE_STEP_2_2} from 'services/me';
import {
  F_EXTRA_PORTAL_STANDALONE_ENABLED,
  F_EXTRA_PORTAL_WIDGET_ENABLED,
  F_USAGE_V2_IN_APP_ANNOUNCEMENT,
  F_USAGE_V2_IN_APP_SURVEY,
  F_USAGE_V2_PORTAL_NEWS_FEED,
  F_USAGE_V2_PRODUCT_TOUR,
  F_USAGE_V2_PUBLIC_ROADMAP,
  F_USAGE_V2_TOOLTIPS,
  F_USAGE_V2_UP_VOTE,
  F_USAGE_V2_WIDGET_NEWS_FEED,
} from 'services/project';
import {Swaler} from 'swaler';
import animAnnouncement from './animations/announces.json';
import animChangelog from './animations/changelog_.mp4';
import animHostspot from './animations/concept-test_.mp4';
import animFeedbackPortal from './animations/feedback-portal_.mp4';
import animSurvey from './animations/survey.json';
import animTour from './animations/tour.json';
import './step-2-2.scss';

const USAGE_IN_APP = [
  {
    flag: F_USAGE_V2_PRODUCT_TOUR,
    label: 'Product tour',
    description: 'Guide users in their discovery',
    icon: 'icon-tour',
    color: '#E6FDE8',
  },
  {
    flag: F_USAGE_V2_IN_APP_ANNOUNCEMENT,
    label: 'In-app announcement',
    description: "Let's your users known what's new",
    icon: 'icon-usage-announcement',
    color: '#FDEFE6',
  },
  {
    flag: F_USAGE_V2_TOOLTIPS,
    label: 'Hotspot',
    description: 'Make sure your users are focused',
    icon: 'icon-usage-tooltip',
    color: '#fde6e6',
  },
  {
    flag: F_USAGE_V2_IN_APP_SURVEY,
    label: 'In-app NPS & Surveys',
    description: 'Measure opinion with rating scale',
    icon: 'icon-emoji',
    color: '#ECE6FD',
  },
];
const USAGE_PORTAL = [
  {
    flag: F_USAGE_V2_PORTAL_NEWS_FEED,
    label: 'Standalone newsfeed',
    description: 'Display your progress on a standalone website',
    icon: 'icon-portal',
    color: '#E6FDE8',
  },
  {
    flag: F_USAGE_V2_WIDGET_NEWS_FEED,
    label: 'In-app newsfeed',
    description: "Embed a what's new feed to keep your users up to date",
    icon: 'icon-usage-widget',
    color: '#FDEFE6',
  },
  {
    flag: F_USAGE_V2_PUBLIC_ROADMAP,
    label: 'Public Roadmap',
    description: 'Show what you are up to',
    icon: 'icon-usage-portal',
    color: '#fde6e6',
  },
  {
    flag: F_USAGE_V2_UP_VOTE,
    label: 'Feature up-vote',
    description: 'Prioritize based on what your users want',
    icon: 'icon-thumbs-up',
    color: '#ECE6FD',
  },
];

const logger = new Swaler('Onboarding/2-2');

export const OnboardingDetailsStep22 = ({triggerSquareAnimationOut}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => generalSelector.getUser(state));
  const project = useSelector((state) => generalSelector.getProject(state));

  const uptUser = (data) => dispatch(generalActions.uptUser(data));
  const uptProject = (data) => dispatch(generalActions.uptProject(data));

  const [playAnimationOut, setPlayAnimationOut] = useState(false);
  const [usage, setUsage] = useState(project.usageFlags);
  const [anim, setAnim] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const onboardingFlags = addFlag(
        ME_F_ONBOARDING_DONE_STEP_2_2,
        user.onboardingFlags
      );
      let extraFlags = project.extraFlags;

      if (
        hasFlags(
          [
            F_USAGE_V2_PORTAL_NEWS_FEED,
            F_USAGE_V2_UP_VOTE,
            F_USAGE_V2_PUBLIC_ROADMAP,
            F_USAGE_V2_WIDGET_NEWS_FEED,
          ],
          usage,
          true
        )
      ) {
        // extraFlags = addFlag(
        //   [F_EXTRA_PORTAL_WIDGET_ENABLED, F_EXTRA_PORTAL_STANDALONE_ENABLED],
        //   extraFlags
        // );
      }

      await projectService.updateProject(project.uid, {
        usageFlags: usage,
        extraFlags,
        ...(hasFlag(F_USAGE_V2_WIDGET_NEWS_FEED, usage) === true
          ? {triggerMode: 'DEFAULT'}
          : {}),
      });
      await meService.updateOnboardingFlag(onboardingFlags);
      uptProject({usageFlags: usage, extraFlags});
      uptUser({onboardingFlags});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsSubmitting(false);
      logger.error('Start trial failed with error ', code);
      toastDanger([title, message], {actions});
      return setIsSubmitting(false);
    }
    setPlayAnimationOut(true);
    triggerSquareAnimationOut();
    setTimeout(() => {
      history.push(ROUTE_ONBOARDING_DETAILS_STEP_2_3);
    }, 1200);
  };

  return (
    <div
      className={classnames(
        's-onboarding-details-step s-onboarding-details-step-2-2',
        {
          'is-exiting': playAnimationOut,
        }
      )}>
      <div className="details-main-wrapper">
        <h1>How would you use Jimo?</h1>
        <p>it helps us personalize your Jimo experience</p>

        <div className="usage-category-label">In-app experiences</div>
        <div className="usage-category-wrapper">
          {USAGE_IN_APP.map((u) => {
            const isActive = hasFlag(u.flag, usage);

            return (
              <div
                key={u.flag}
                className={classnames('usage-item', {
                  active: isActive,
                })}
                onClick={() => {
                  setUsage(reverseFlag(u.flag, usage));
                }}
                onMouseOver={() => setAnim(u.flag)}>
                <div
                  className="usage-icon-wrapper"
                  style={isActive ? {backgroundColor: u.color} : {}}>
                  <i className={u.icon}></i>
                </div>
                <div className="usage-content">
                  {u.label}
                  <small>{u.description}</small>
                </div>
              </div>
            );
          })}
        </div>
        <div className="usage-category-label">Community</div>
        <div className="usage-category-wrapper">
          {USAGE_PORTAL.map((u) => {
            const isActive = hasFlag(u.flag, usage);

            return (
              <div
                key={u.flag}
                className={classnames('usage-item', {
                  active: isActive,
                })}
                onClick={() => {
                  setUsage(reverseFlag(u.flag, usage));
                }}
                onMouseOver={() => setAnim(u.flag)}>
                <div
                  className="usage-icon-wrapper"
                  style={isActive ? {backgroundColor: u.color} : {}}>
                  <i className={u.icon}></i>
                </div>
                <div className="usage-content">
                  {u.label}
                  <small>{u.description}</small>
                </div>
              </div>
            );
          })}
        </div>
        <Button
          loading={isSubmitting}
          className="btn-continue"
          cta
          primary
          secondary
          iconRight="icon-chevron-right"
          onClick={() => handleSubmit()}>
          Continue
        </Button>
      </div>
      <div className="details-illustration-wrapper">
        {anim === F_USAGE_V2_IN_APP_ANNOUNCEMENT && (
          <Lottie animationData={animAnnouncement} />
        )}
        {anim === F_USAGE_V2_IN_APP_SURVEY && (
          <Lottie animationData={animSurvey} />
        )}
        {anim === F_USAGE_V2_PRODUCT_TOUR && (
          <Lottie animationData={animTour} />
        )}
        {anim === F_USAGE_V2_UP_VOTE && (
          <video autoPlay src={animFeedbackPortal}></video>
        )}
        {anim === F_USAGE_V2_WIDGET_NEWS_FEED && (
          <video autoPlay src={animChangelog}></video>
        )}
        {anim === F_USAGE_V2_TOOLTIPS && (
          <video autoPlay src={animHostspot}></video>
        )}
      </div>
    </div>
  );
};
