import React from 'react';
import * as Emojis from 'shared/front/components/Emojis';
import './_Styles.scss';

export default function DevEmoji() {
  return (
    <div className="_dev-emoji">
      <Emojis.EmojiClap></Emojis.EmojiClap>
      <Emojis.EmojiIdk></Emojis.EmojiIdk>
      <Emojis.EmojiNah></Emojis.EmojiNah>
      <Emojis.EmojiPeace></Emojis.EmojiPeace>
      <Emojis.EmojiEvolutionAnnouncement></Emojis.EmojiEvolutionAnnouncement>
      <Emojis.EmojiEvolutionFeaturePreview></Emojis.EmojiEvolutionFeaturePreview>
      <Emojis.EmojiEvolutionLiveForVote></Emojis.EmojiEvolutionLiveForVote>
      <Emojis.EmojiThumbsUp></Emojis.EmojiThumbsUp>
      <Emojis.EmojiYeah></Emojis.EmojiYeah>
      <Emojis.EmojiWidgetLeft></Emojis.EmojiWidgetLeft>
      <Emojis.EmojiWidgetRight></Emojis.EmojiWidgetRight>
    </div>
  );
}
