import {ACTION_STEP_PREV} from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Navigation';
import {isEventConditionValid} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import {
  EVENT_CONDITION_TYPE_DELAY,
  EVENT_CONDITION_TYPE_ELEMENT,
  EVENT_CONDITION_TYPE_INPUT,
  EVENT_CONDITION_TYPE_URL,
} from 'services/event';
import {
  BLOCK_TYPE_ANIMATION,
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
  STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_OPEN_POST,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
} from 'services/steps';

export const getStepIssue = (step, options = {}) => {
  const blockIssues =
    step?.blocks
      ?.filter((block) => block.removed !== true)
      ?.map((block) => ({
        blockType: block.type,
        issues: validateBlock(block, options),
      }))
      ?.filter((block) => block.issues?.length > 0) || [];

  const triggerIssues =
    step?.triggers
      ?.map((trigger) => ({
        triggerId: trigger.uid,
        issues: validateTrigger(trigger),
      }))
      ?.filter((trigger) => trigger.issues?.length > 0) || [];

  const issues = [...blockIssues, ...triggerIssues];

  return issues?.length > 0 ? issues : null;
};

const validateBlock = (block, options = {}) => {
  const issues = [];

  if (block.type === BLOCK_TYPE_TITLE) {
    const [text, action, actionText] = block.value.split('|-|');
    if (!text) {
      issues.push('Title is missing');
    }
  } else if (block.type === BLOCK_TYPE_BODY) {
    if (
      block.value == null ||
      block.value.length === 0 ||
      block.value.replaceAll('<br>', '').replaceAll('\n', '').trim().length ===
        0
    ) {
      issues.push('Body is missing');
    }
  } else if (block.type === BLOCK_TYPE_LABEL) {
    if (block.value == null || block.value.length === 0) {
      issues.push('Label is missing');
    }
  } else if (block.type === BLOCK_TYPE_MEDIA) {
    const [type, fileUrl, altText, sizing] = block.value.split(';');
    if (type == null || type.length === 0) {
      issues.push('Media type is missing');
    }
    if (fileUrl == null || fileUrl.length === 0) {
      issues.push('Media URL is missing');
    }
  } else if (block.type === BLOCK_TYPE_USER) {
    const [name, role, avatarUrl] = block.value.split(';');
    if (name == null || name.length === 0) {
      issues.push('Name is missing');
    }
    if (role == null || role.length === 0) {
      issues.push('Role is missing');
    }
    if (avatarUrl == null || avatarUrl.length === 0) {
      issues.push('Avatar URL is missing');
    }
  } else if (
    [BLOCK_TYPE_PRIMARY_CTA, BLOCK_TYPE_SECONDARY_CTA].includes(block.type)
  ) {
    const [text, action, optAction, optActionText] = block.value.split(';');
    if (text == null || text.length === 0) {
      issues.push('Text is missing');
    }
    if (action == null || action.length === 0) {
      issues.push('Action is missing');
    }
    if (action === ACTION_STEP_PREV && options?.hasNoPrevStep === true) {
      issues.push("Can't have previous step navigation on first step");
    }
    if (action === ACTION_STEP_PREV && options?.prevStepIsNavigation === true) {
      issues.push("Can't have previous step navigation after navigation step");
    }

    if (block?.actions?.length > 0) {
      for (const action of block?.actions) {
        const actionIssues = validateAction(action);
        if (actionIssues.length > 0) {
          issues.push(...actionIssues);
        }
      }
    }
  } else if (block.type === BLOCK_TYPE_ANIMATION) {
    if (block.value == null || block.value.length === 0) {
      issues.push('Animation is missing');
    }
  } else if (block.type === BLOCK_TYPE_CHOICE) {
    if (block.options?.length < 2) {
      issues.push('Not enough options');
    }
    for (const o of block.options) {
      if (!o?.content) {
        issues.push('Content is missing for response option');
      }
    }
  }

  return issues;
};

export const validateTrigger = (trigger) => {
  const issues = [];

  if (trigger.conditions?.length === 0 && trigger.actions?.length === 0) {
    issues.push('Add at least one condition and action to trigger');
  } else if (trigger.conditions?.length === 0) {
    issues.push('Add at least one condition to trigger');
  } else if (trigger.actions?.length === 0) {
    issues.push('Add at least one action to trigger');
  }

  for (const condition of trigger.conditions) {
    if (isEventConditionValid(condition) === false) {
      let type;
      if (condition.type === EVENT_CONDITION_TYPE_ELEMENT) {
        type = 'Element';
      } else if (condition.type === EVENT_CONDITION_TYPE_URL) {
        type = 'URL';
      } else if (condition.type === EVENT_CONDITION_TYPE_DELAY) {
        type = 'Delay';
      } else if (condition.type === EVENT_CONDITION_TYPE_INPUT) {
        type = 'Input';
      }
      issues.push(`${type} condition is invalid`);
    }
  }

  for (const action of trigger.actions) {
    const actionIssues = validateAction(action);
    if (actionIssues.length > 0) {
      issues.push(...actionIssues);
    }
  }

  return issues;
};

export const validateAction = (action) => {
  const issues = [];

  if (action.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP) {
    if (action.step == null) {
      issues.push('Action is missing step');
    }
  }
  if (action.type === STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE) {
    if (action.evolution == null) {
      issues.push('Action is missing experience');
    }
  }
  if (action.type === STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO) {
    if (!action.value) {
      issues.push('Action is missing URL');
    }
  }
  if (action.type === STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE) {
    if (!action.value) {
      issues.push('Action is missing JS code');
    }
  }
  if (action.type === STEP_CONDITION_ACTION_TYPE_OPEN_POST) {
    if (action.evolution == null) {
      issues.push('Action is missing changelog post');
    }
  }
  if (action.type === STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW) {
    if (!action.value) {
      issues.push('Action is missing interview booking URL');
    }
  }

  return issues;
};
