import classnames from 'classnames';
import Button from 'components/Button';
import {Modal} from 'components/Modal';
import {GlobalContext} from 'contextes/Global';
import dayjs from 'dayjs';
import {useUpdateAddons} from 'hooks/useUpdateAddons';
import {useContext} from 'react';
import {getDayToReset} from 'services/project';
import {
  ADDON_EXTRA_MAU_ID,
  ADDON_WHITE_LABEL_ID,
  hasAnnualBilling,
} from 'services/subscription';
import './_styles.scss';

export const ModalAddonPurchased = () => {
  const {modalAddonPurchased, setModalAddonPurchased} =
    useContext(GlobalContext);
  const {addonId} = modalAddonPurchased;

  const {update} = useUpdateAddons();

  const handleRequestClose = () => {
    update();
    setModalAddonPurchased(null);
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={handleRequestClose}
      className={classnames('modal-addon-purchased')}>
      <div className="modal-main">
        <div className="cancel-title title-3 n-800">
          {addonId === ADDON_WHITE_LABEL_ID && 'Jimo label is now hidden'}
          {addonId === ADDON_EXTRA_MAU_ID && 'MAU Increased'}
        </div>
        <div className="cancel-description body-3 n-700">
          {addonId === ADDON_WHITE_LABEL_ID &&
            'Your addon has been successfully purchased and automatically enabled!'}
          {addonId === ADDON_EXTRA_MAU_ID &&
            `Your MAU limit has been increased for the current ${
              hasAnnualBilling() ? 'monthly usage' : 'billing cycle'
            } which ends on ${dayjs()
              .add(getDayToReset(), 'days')
              .format('MMM DD, YYYY')}`}
          .
        </div>
        <Button primary onClick={handleRequestClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};
