import classnames from 'classnames';
import hex2rgb from 'hex-rgb';
import React, {useContext, useEffect} from 'react';
import {hasFlag} from '../../../../helpers/bitwise';
import {BLOCK_CHOICE, getDefaultResponseForBlock} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {useDisableSubmitOnVote} from '../../hooks/useDisableSubmitOnVote';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';

export const BLOCK_FLAG_CHOICE_CAN_MULTIPLE = 2;

export const CHOICE_DISPOSITION_INLINE = 'inline';
export const CHOICE_DISPOSITION_BLOCK = 'block';

export const BlockChoice = () => {
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    response = getDefaultResponseForBlock(BLOCK_CHOICE),
    updateResponse,
    submitResponse,
    language,
    addFontFamily,
  } = useContext(PokeStateContext);

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_CHOICE,
  });
  const disableSubmitOnVote = useDisableSubmitOnVote(currentStep);

  const block = currentStep.blocks.find((b) => b.type === BLOCK_CHOICE);
  const {
    primaryColor,
    disposition,
    shadow,
    withCheckbox,
    fontFamily,
    ...restStyle
  } = block.style;
  const isMultiChoice = hasFlag(BLOCK_FLAG_CHOICE_CAN_MULTIPLE, block.flags);

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  // Customizable CSS variables
  document.documentElement.style.setProperty(
    '--blockChoiceAccentColor',
    hex2rgb(primaryColor || '#000000', {format: 'array'})
      .slice(0, 3)
      .join(',')
  );

  const {x, y, blur, color} = shadow ?? {};
  const boxShadow = `${x}px ${y}px ${blur}px ${color}`;

  const handleResponse = (selectedOptions) => {
    if (inBuilder === true) {
      return;
    }

    if (isMultiChoice === true) {
      updateResponse(selectedOptions);
    } else {
      if (disableSubmitOnVote === false) {
        submitResponse({response: selectedOptions});
      } else {
        updateResponse(selectedOptions);
      }
    }
  };

  return (
    <div
      className={classnames('poke-block-choice', {
        'poke-block-clickable': inBuilder === true,
        compact: disposition === CHOICE_DISPOSITION_INLINE,
        'is-animating-out': playAnimationOut === true,
        selected: selectedBlock === BLOCK_CHOICE,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_CHOICE);
      }}>
      {block.options
        .sort((a, b) => a.indexOrder - b.indexOrder)
        .map((c) => {
          const isActive = response.find((r) => r.uid === c.uid) != null;

          const translation = c.labels?.find((t) => t.language === language);

          return (
            <div
              key={c.uid}
              className={classnames('option-choice', {
                'is-active': isActive,
              })}
              onClick={() =>
                handleResponse(
                  isMultiChoice === true
                    ? isActive
                      ? response.filter((r) => r.uid !== c.uid)
                      : response.concat(c)
                    : [c]
                )
              }
              style={{
                boxShadow,
                fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
                ...restStyle,
              }}>
              {withCheckbox === true && isMultiChoice === true && (
                <>
                  {isActive === true ? (
                    <i className="icon-checkbox" />
                  ) : (
                    <i className="icon-checkbox-o" />
                  )}
                </>
              )}
              {withCheckbox === true && isMultiChoice === false && (
                <>
                  {isActive === true ? (
                    <i className="icon-radiobox" />
                  ) : (
                    <i className="icon-radiobox-o" />
                  )}
                </>
              )}
              {translation != null ? translation.content : c.content}
            </div>
          );
        })}
      <ClickableBlockOverlay />
    </div>
  );
};
