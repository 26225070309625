import classnames from 'classnames';
import commaNumber from 'comma-number';
import Alert from 'components/Alert';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import {toastWarning} from 'components/Toaster';
import {GlobalContext} from 'contextes/Global';
import dayjs from 'dayjs';
import {hasFlag} from 'helpers/bitwise';
import {
  useFetchAddonExtraMauProration,
  useFetchProration,
} from 'hooks/useFetchProration';
import {useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {dataSelector, generalSelector} from 'selectors';
import {subscriptionService} from 'services';
import {getDayToReset} from 'services/project';
import {
  ADDON_WHITE_LABEL_ID,
  F_MODE_ANNUAL_BILLING,
} from 'services/subscription';
import './_styles.scss';

export const ModalAddonCheckout = () => {
  const {modalAddonCheckout, setModalAddonCheckout} = useContext(GlobalContext);
  const {addonId, onCheckout} = modalAddonCheckout;

  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const addons = useSelector((state) =>
    dataSelector.getSubscriptionsAddons(state)
  );

  const [isWorking, setIsWorking] = useState(false);

  const {
    isFetching: isFetchingProration,
    fetch: fetchProration,
    proration,
  } = useFetchProration({
    onError: () => {
      setModalAddonCheckout(null);
    },
  });

  function handleRequestClose() {
    setModalAddonCheckout(null);
  }
  async function handleCheckout() {
    setIsWorking(true);
    await onCheckout(addonId);
    setIsWorking(false);
  }

  const modeAnnualBilling = hasFlag(
    F_MODE_ANNUAL_BILLING,
    subscription.extraFlags
  );
  const addon = addons.find((a) => a.uid === addonId);
  const currentAddons = subscriptionService.getCurrentAddons();
  const newAddons = currentAddons.concat(addonId);
  const basePrice =
    modeAnnualBilling === true ? addon.prices[0] * 12 : addon.prices[1];
  const price =
    proration == null || proration.discount == null
      ? basePrice
      : basePrice * (1 - proration.discount.coupon.percent_off / 100);
  const proratedPrice =
    proration == null ? 0 : price * 100 - proration.subtotal;

  useEffect(() => {
    if (currentAddons.includes(addonId)) {
      toastWarning([
        'Addon already enabled',
        'It seems that the addon you are trying to enable, is already enabled on your project.',
      ]);
      return handleRequestClose();
    }
    fetchProration({
      plan: subscription.plan,
      modeAnnual: modeAnnualBilling,
      addons: newAddons,
    });
  }, []);

  return (
    <Modal isOpen={true} className="modal-addon-checkout">
      <div className="modal-header">
        <div className="title-4">
          {addonId === ADDON_WHITE_LABEL_ID && 'Hide Jimo Label'}
        </div>
        <Button iconOnly onClick={handleRequestClose}>
          <i className="icon-close"></i>
        </Button>
      </div>
      <div className="modal-content">
        <div className="left-side">
          <div className="subscription-info">
            {/* Payment Information */}
            <div className="payment-information">
              <div className="body-3 n-500">Payment information</div>
              <div className="body-2">
                <span className="card-brand">
                  {subscription.paymentMethodBrand}
                </span>{' '}
                ending in {subscription.paymentMethodLast4}
              </div>
            </div>
            {/* Billing Information (disabled for now, require to fetch data from Stripe) */}
            {/* <div className="billing-information">
              <div className="body-3 n-500">Billing information</div>
              <div className="body-2">
                {subscription.paymentMethodName}, France, 44000
              </div>
            </div> */}
            {/* Current plan */}
            <div className="current-plan">
              <div className="body-3 n-500">Current plan</div>
              <div className="body-2">
                <span className="plan-name">
                  {subscription.plan.toLowerCase()} Plan
                </span>
                ,{' '}
                {modeAnnualBilling === true
                  ? 'billed yearly'
                  : 'billed monthly'}
              </div>
            </div>
          </div>
          <Alert success icon="isax isax-tick-circle5" title="Effective today">
            Add-on will be active immediately after checkout
          </Alert>
        </div>
        <div className="right-side">
          {/* Proration Resume */}
          <div
            className={classnames('proration-resume', {
              loading: proration == null,
            })}>
            {proration == null ? (
              <DefaultLoader width="12px" />
            ) : (
              <>
                <div className="item-line">
                  <div className="title-wrapper">
                    <div className="body-2">
                      {addonId === ADDON_WHITE_LABEL_ID && 'Hide Jimo Label'}
                    </div>
                    {proration.discount != null && (
                      <div className="body-3 g-500">
                        Discount applied (
                        {proration.discount.coupon.percent_off}%)
                      </div>
                    )}
                  </div>
                  <div className="subtitle-3">
                    ${commaNumber(price)}
                    {modeAnnualBilling === true ? ' / year' : ' / month'}
                  </div>
                </div>
                {proratedPrice > 0 && (
                  <div className="item-line">
                    <div className="title-wrapper">
                      <div className="body-2">Pro-rated reduction</div>
                      <div className="body-3 n-700">
                        From{' '}
                        {dayjs(proration.periodStart * 1000).format(
                          'DD MMM YYYY'
                        )}{' '}
                        to now
                      </div>
                    </div>
                    <div className="subtitle-3">-${proratedPrice / 100}</div>
                  </div>
                )}
                {proration != null && proration.tax > 0 && (
                  <>
                    <div className="item-line line-subtotal">
                      <div className="body-3">Subtotal</div>
                      <div className="body-3">${proration.subtotal / 100}</div>
                    </div>
                    <div className="item-line line-tax">
                      <div className="body-3">Tax</div>
                      <div className="body-3">${proration.tax / 100}</div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          {/* Total */}
          <div className="total-wrapper">
            <div className="subtitle-3">Total for today</div>
            <div className="subtitle-2">
              {proration == null ? '-' : <>${proration.total / 100}</>}
            </div>
          </div>
          {/* Proration description */}
          <div className="proration-description body-4 n-500">
            Total price is adjusted based on your current billing cycle so, you
            only pay for this addon from now until your next billing day.
          </div>
          {/* Checkout button */}
          <Button
            className="btn-addon-checkout"
            primary
            loading={isWorking}
            onClick={handleCheckout}
            disabled={isFetchingProration}>
            Confirm and pay
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const ModalAddonExtraMauCheckout = () => {
  const {modalAddonCheckout, setModalAddonCheckout} = useContext(GlobalContext);
  const {addonId, onCheckout} = modalAddonCheckout;

  const project = useSelector((state) => generalSelector.getProject(state));
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const {
    isFetching: isFetchingProration,
    fetch: fetchProration,
    proration,
  } = useFetchAddonExtraMauProration({
    onError: () => {
      setModalAddonCheckout(null);
    },
  });

  const [isWorking, setIsWorking] = useState(false);

  function handleRequestClose() {
    setModalAddonCheckout(null);
  }
  async function handleCheckout() {
    setIsWorking(true);
    await onCheckout(addonId);
    setIsWorking(false);
  }

  useEffect(() => {
    fetchProration();
  }, []);

  const modeAnnualBilling = subscriptionService.hasAnnualBilling();
  const dayToReset = getDayToReset();

  return (
    <Modal isOpen={true} className="modal-addon-checkout">
      <div className="modal-header">
        <div className="title-4">
          Increase MAU for current
          {modeAnnualBilling === true ? ' monthly usage' : ' billing cycle'}
        </div>
        <Button iconOnly onClick={handleRequestClose}>
          <i className="icon-close"></i>
        </Button>
      </div>
      <div className="modal-content">
        <div className="left-side">
          <div className="subscription-info">
            {/* Payment Information */}
            <div className="payment-information">
              <div className="body-3 n-500">Payment information</div>
              <div className="body-2">
                <span className="card-brand">
                  {subscription.paymentMethodBrand}
                </span>{' '}
                ending in {subscription.paymentMethodLast4}
              </div>
            </div>
            {/* Billing Information (disabled for now, require to fetch data from Stripe) */}
            {/* <div className="billing-information">
            <div className="body-3 n-500">Billing information</div>
            <div className="body-2">
              {subscription.paymentMethodName}, France, 44000
            </div>
          </div> */}
            {/* Current plan */}
            <div className="current-plan">
              <div className="body-3 n-500">Current plan</div>
              <div className="body-2">
                <span className="plan-name">
                  {subscription.plan.toLowerCase()} Plan
                </span>
                ,{' '}
                {modeAnnualBilling === true
                  ? 'billed yearly'
                  : 'billed monthly'}
              </div>
            </div>
          </div>
        </div>
        <div className="right-side">
          <Alert
            info
            icon="isax isax-calendar-tick"
            title="Effective for the current billing cycle">
            This add-on will increase your MAU limit only for your current
            {modeAnnualBilling === true ? ' month usage' : ' billing cycle'},
            which ends in {dayToReset} days.
          </Alert>
          {/* Proration Resume */}
          <div
            className={classnames('proration-resume', {
              loading: proration == null,
            })}>
            {proration == null ? (
              <DefaultLoader width="12px" />
            ) : (
              <>
                <div className="item-line">
                  <div className="title-wrapper">
                    <div className="subtitle-3">+{proration.quantity} MAU</div>
                    <div className="body-3 n-700">
                      From today to{' '}
                      {dayjs().add(dayToReset, 'days').format('DD MMM, YYYY')}
                    </div>
                  </div>
                  <div className="subtitle-3">
                    ${commaNumber(proration.subtotal / 100)}
                  </div>
                </div>
                {proration != null && proration.tax > 0 && (
                  <>
                    <div className="item-line line-subtotal">
                      <div className="body-3">Subtotal</div>
                      <div className="body-3">${proration.subtotal / 100}</div>
                    </div>
                    <div className="item-line line-tax">
                      <div className="body-3">Tax</div>
                      <div className="body-3">${proration.tax / 100}</div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          {/* Total */}
          <div className="total-wrapper">
            <div className="subtitle-3">Total for today</div>
            <div className="subtitle-2">
              {proration == null ? '-' : <>${proration.total / 100}</>}
            </div>
          </div>
          {/* Checkout button */}
          <Button
            className="btn-addon-checkout"
            primary
            loading={isWorking}
            onClick={handleCheckout}
            disabled={isFetchingProration}>
            Confirm and Pay
          </Button>
        </div>
      </div>
    </Modal>
  );
};
