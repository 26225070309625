import React, {useContext} from 'react';
import {AttributeContext} from '../..';
import './_Styles.scss';
import {useQuery} from 'react-query';
import {evolutionService} from 'services';
import {Swaler} from 'swaler';
import SelectGroup from 'components/Select';
import {selectableAnsweredStepsType} from '../AttributeEvolutionStepSelect';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';

const logger = new Swaler('AttributeEvolutionSelect');

const AttributeEvolutionSelect = () => {
  const {attribute, updateAttribute} = useContext(AttributeContext);
  const {evolutionId, type} = attribute;

  const {data: listEvolutions = [], isLoading: isLoadingListEvolutions} =
    useQuery({
      queryKey: 'listEvolutions',
      queryFn: async () =>
        evolutionService.getEvolutions({
          removeDrafts: true,
          relations: ['steps'],
        }),
      refetchOnWindowFocus: false,
      onError: (err) => {
        logger.error(
          'Could not fetch evolutions list, failed with err',
          err.message
        );
      },
    });

  const filteredEvolutions = listEvolutions.filter((e) => {
    if (type === 'SURVEY_ANSWERED') {
      return (
        e.type === EVOLUTION_TYPE_SURVEY &&
        e.steps?.some((s) => selectableAnsweredStepsType.includes(s.type))
      );
    }
    if (['SURVEY_COMPLETED', 'SURVEY_NCOMPLETED'].includes(type)) {
      return [EVOLUTION_TYPE_TOUR, EVOLUTION_TYPE_SURVEY].includes(e.type);
    }
    return true;
  });

  const tours = filteredEvolutions?.filter(
    (e) => e.type === EVOLUTION_TYPE_TOUR
  );
  const surveys = filteredEvolutions?.filter(
    (e) => e.type === EVOLUTION_TYPE_SURVEY
  );
  const banners = filteredEvolutions?.filter(
    (e) => e.type === EVOLUTION_TYPE_BANNER
  );

  const evolutionOptions = [
    {
      label: 'Tours & Modals',
      options: tours?.map((e) => ({
        label: e.title,
        value: e.uid,
      })),
    },
    {
      label: 'Surveys',
      options: surveys?.map((e) => ({
        label: e.title,
        value: e.uid,
      })),
    },
    {
      label: 'Banners',
      options: banners?.map((e) => ({
        label: e.title,
        value: e.uid,
      })),
    },
  ];

  const flattenedOptions = evolutionOptions.reduce(
    (acc, curr) => [...acc, ...curr.options],
    []
  );

  return (
    <SelectGroup
      menuPortalTarget={document.body}
      options={evolutionOptions}
      closeMenuOnSelect={true}
      isLoading={isLoadingListEvolutions}
      value={flattenedOptions.filter((e) => evolutionId === e?.value)}
      placeholder="Select experience..."
      onChange={(e) => {
        updateAttribute({
          ...attribute,
          evolutionId: e.value,
          evolution: listEvolutions.find((ev) => ev.uid === e.value),
          step: null,
          stepId: null,
          values: [],
        });
      }}
    />
  );
};

export default AttributeEvolutionSelect;
