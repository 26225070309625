import {default as classnames, default as classNames} from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import {ModalConfirm} from 'components/Modal';
import ModalInstall from 'components/ModalInstall';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Tooltip from 'components/Tooltip';
import {errorHelpers} from 'helpers';
import {removeTrailingSlash} from 'helpers/evolution';
import {bool} from 'prop-types';
import {useContext, useEffect, useRef, useState} from 'react';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {
  ROUTE_LIGHTWEIGHT_POKE_BUILDER,
  ROUTE_LIGHTWEIGHT_POKE_BUILDER_WITH_ID,
  ROUTE_POKE_BUILDER_AUDIENCE,
  ROUTE_POKE_BUILDER_FROM_TYPE,
} from 'router/routes.const';
import {isPokeValid} from 'scenes/Builder/component/BuilderHeader/utils';
import {BuilderContext} from 'scenes/Builder/context';
import {ACTIVE_OPERATOR_EVERYWHERE} from 'scenes/EmbeddedElementSelectorBuilder';
import {getPokeRoute} from 'scenes/PokeAudience/components/AudienceHeader';
import ModalCreatePoke from 'scenes/Pushes/components/ModalCreatePoke';
import {evolutionService} from 'services';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {Swaler} from 'swaler';
import AdoptionStepsManager, {
  isUrlMatchingNavigation,
} from '../AdoptionStepsManager';
import DiscoveryStepsManager from '../DiscoveryStepsManager';
import LiveEditWarningModal from '../LiveEditWarningModal';
import PublishChangesModal from '../PublishChangesModal';
import TroubleShootingMenu from '../TroubleshootingMenu';
import './_Styles.scss';
import Logo from './imgs/logo.png';

const logger = new Swaler('PokeBuilderHeader');

const propTypes = {
  isFetching: bool,
  isInApp: bool,
  isLoading: bool,
};

const defaultProps = {
  isFetching: false,
  isInApp: false,
  isLoading: false,
};

const PokeBuilderHeader = ({isFetching, isLoading, isInApp}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const isExtension = query.get('is-extension') != null;

  const {
    evolution,
    setEvolution,
    originalEvolution,
    setOriginalEvolution,
    hasUnSaveChanges,
    refetchEvolution,
    messenger,
    currentUrl,
    setIsInInteractivePreview,
    lastAutoSaveAt,
  } = useContext(BuilderContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [showModalInstall, setShowModalInstall] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [showEditInAppModal, setShowEditInAppModal] = useState(false);
  const [isModalPreviewMode, setIsModalPreviewMode] = useState(false);
  const [troubleshootingMenuOpen, setTroubleshootingMenuOpen] = useState(false);
  const [showLiveEditWarningModal, setShowLiveEditWarningModal] =
    useState(false);
  const [showPublishChangesModal, setShowPublishChangesModal] = useState(false);

  const refInputTitle = useRef();

  const issues = isPokeValid(evolution);
  const isSubmitDisabled = issues !== true;

  useEffect(() => {
    if (isSubmitDisabled !== true) {
      setTroubleshootingMenuOpen(false);
    }
  }, [isSubmitDisabled]);

  useEffect(() => {
    if (isInApp === true) {
      messenger?.sendInAppForegroundForce(troubleshootingMenuOpen === true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [troubleshootingMenuOpen]);

  const handleEditInApp = async () => {
    setShowEditInAppModal(true);
  };

  const handlePreview = async () => {
    setIsPreviewing(true);

    try {
      if (isInApp === true) {
        const isUrlMatching = isUrlMatchingNavigation(evolution, currentUrl);

        messenger.sendStartPreview();
        if (
          isUrlMatching === false ||
          (evolution.boostedActiveOperator === ACTIVE_OPERATOR_EVERYWHERE &&
            removeTrailingSlash(evolution.boostedActiveUrl) !==
              removeTrailingSlash(currentUrl))
        ) {
          // messenger?.sendNavigateTo({url: evolution.boostedActiveUrl});
        }
        setIsInInteractivePreview(true);
        // setSelectedStepId(null);
        // dispatch(builderActions.setSelectedTourStepId(null));
      } else {
        setIsModalPreviewMode(true);
        setShowEditInAppModal(true);
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Update evolution failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsPreviewing(false);
    }
  };

  const savePoke = async () => {
    setIsSaving(true);
    let success = false;
    try {
      await evolutionService.updateEvolution(evolution.uid, {
        ...evolution,
      });
      await refetchEvolution();
      toastSuccess('Changes saved!', {toastId: 'changes-saved'});
      success = true;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Update evolution failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsSaving(false);
    }

    return success;
  };

  const handleTargetAndPublishClick = async () => {
    setIsSubmitting(true);
    const success = await savePoke();
    setIsSubmitting(false);

    if (success === true) {
      refetchEvolution();
      if (isInApp === true) {
        messenger.sendEvolutionSaved({evolution});
      } else {
        if (evolution.isDraft === true) {
          history.push(ROUTE_POKE_BUILDER_AUDIENCE(evolution.uid));
        } else {
          const route = getPokeRoute(evolution.type);
          history.push(route(evolution.uid));
        }
      }
    }
  };

  const handleUpdateTitle = async () => {
    try {
      await evolutionService.updateEvolutionTitle(
        evolution.uid,
        evolution.title
      );
      setOriginalEvolution({...originalEvolution, title: evolution.title});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Update evolution title', code);
      toastDanger([title, message], {actions});
    }
  };

  const handleNavigate = () => {
    messenger?.sendSwitchToNavigatorMode();
  };

  const {type} = evolution;

  const isStepBuilder = [
    ROUTE_POKE_BUILDER_FROM_TYPE(),
    ROUTE_LIGHTWEIGHT_POKE_BUILDER,
    ROUTE_LIGHTWEIGHT_POKE_BUILDER_WITH_ID(),
  ].includes(match.path);

  return (
    <div
      className="poke-builder-header"
      onMouseEnter={() => messenger?.sendInAppForegroundForce(true)}
      onMouseLeave={() => messenger?.sendInAppForegroundForce(false)}>
      <div className="left-wrapper">
        {isExtension === true ? (
          <Tooltip
            className="tooltip-poke-builder-header"
            trigger={
              <div>
                <Button
                  thin
                  className="btn-exit-extension"
                  loading={isPreviewing}
                  disabled={isSaving || isSubmitting || isPreviewing}
                  iconLeft="icon-close"
                  iconOnly
                  onClick={() => messenger.sendCloseEmbeddedBuilder()}
                />
              </div>
            }
            offsetY={4}
            offsetX={14}>
            Exit builder
          </Tooltip>
        ) : isInApp === true ? (
          <div className={classnames('logo-wrapper', {'with-border': true})}>
            <img src={Logo} alt="Logo" className="logo" />
          </div>
        ) : (
          <></>
        )}
        {isInApp === false && (
          <Button
            thin
            className="btn-back"
            iconLeft="icon-arrow-left"
            iconOnly
            tertiary
            onClick={() => {
              const route = getPokeRoute(evolution.type);
              if (isStepBuilder === true) {
                hasUnSaveChanges()
                  ? setShowExitModal(true)
                  : history.push(route(evolution.uid));
              } else {
                history.push(ROUTE_POKE_BUILDER_FROM_TYPE(evolution.uid));
              }
            }}
          />
        )}
        <div className="name-wrapper">
          {type?.charAt(0)?.toUpperCase() + type?.slice(1)?.toLowerCase()}/
          {isFetching === false && (
            <Input
              ref={refInputTitle}
              className="input-name"
              muted
              value={evolution.title}
              onPressEnter={() => {
                refInputTitle.current.blur();
              }}
              onChange={({target}) =>
                setEvolution({...evolution, title: target.value})
              }
              onBlur={() => handleUpdateTitle()}></Input>
          )}
        </div>
      </div>
      {/* {isLoading === false && ( */}
      <div className="center-wrapper">
        {isStepBuilder === true && (
          <>
            {[
              EVOLUTION_TYPE_TOUR,
              EVOLUTION_TYPE_BANNER,
              EVOLUTION_TYPE_HINT,
            ].includes(type) && <AdoptionStepsManager />}
            {[EVOLUTION_TYPE_SURVEY].includes(type) && (
              <DiscoveryStepsManager />
            )}
          </>
        )}
      </div>
      {/* )} */}
      <div className="right-wrapper">
        {evolution.isDraft === true && lastAutoSaveAt != null && (
          <Tooltip
            key={lastAutoSaveAt}
            className="tooltip-auto-save"
            arrow
            arrowStyle={{
              color: '#159201',
              width: '12px',
              height: '8px',
            }}
            trigger={
              <div key={lastAutoSaveAt} className="last-auto-save">
                <i className="icon-tick"></i>
              </div>
            }
            offsetY={2}
            offsetX={10}>
            Work automatically saved!
          </Tooltip>
        )}

        {evolution.isDraft !== true && (
          <div
            className="safe-to-edit-wrapper o-500"
            onClick={() => setShowLiveEditWarningModal(true)}>
            <i className="icon-info-circle-o" />
            <span>What’s safe to edit?</span>
          </div>
        )}
        {evolution.isDraft === true && (
          <Button
            thin
            className="btn-save"
            loading={isSaving && !isSubmitting}
            disabled={isSubmitting || isPreviewing}
            onClick={savePoke}>
            Save
          </Button>
        )}
        {/* )} */}
        {isStepBuilder === true && (
          <>
            {isInApp !== true && (
              <Button
                thin
                className="btn-edit-in-app"
                loading={isPreviewing}
                disabled={isSaving || isSubmitting}
                onClick={handleEditInApp}>
                Edit in-app
              </Button>
            )}
            {isInApp === true && (
              <Tooltip
                className="tooltip-poke-builder-header"
                arrow
                arrowStyle={{
                  color: '#080f25',
                  width: '12px',
                  height: '8px',
                }}
                trigger={
                  <div>
                    <Button
                      thin
                      className="btn-navigate"
                      disabled={isPreviewing || isSaving || isSubmitting}
                      iconLeft="icon-pointer"
                      iconOnly
                      onClick={handleNavigate}
                    />
                  </div>
                }
                offsetY={2}>
                Interact with my app
              </Tooltip>
            )}
            <Tooltip
              className="tooltip-poke-builder-header"
              arrow
              arrowStyle={{
                color: '#080f25',
                width: '12px',
                height: '8px',
              }}
              trigger={
                <div>
                  <Button
                    thin
                    className="btn-play"
                    loading={isPreviewing}
                    disabled={isSaving || isSubmitting}
                    iconLeft="icon-play-o"
                    iconOnly
                    onClick={handlePreview}
                  />
                </div>
              }
              offsetY={2}>
              Preview
            </Tooltip>
            {isSubmitDisabled !== true ? (
              <Button
                reverted
                thin
                primary
                className="btn-target"
                loading={isSubmitting}
                disabled={isSubmitDisabled || isSaving || isPreviewing}
                onClick={() => {
                  if (evolution.isDraft !== true) {
                    setShowPublishChangesModal('publish');
                    return;
                  }

                  handleTargetAndPublishClick();
                }}>
                {evolution.isDraft === true
                  ? 'Target & publish'
                  : 'Publish changes'}
              </Button>
            ) : (
              <Dropdown
                className="dropdown-move-to"
                position="bottom right"
                open={troubleshootingMenuOpen}
                offsetY={4}
                on={['hover']}
                onClose={() => setTroubleshootingMenuOpen(false)}
                trigger={
                  <Button
                    reverted
                    thin
                    primary
                    className={classNames('btn-target', {
                      'is-invalid': isSubmitDisabled,
                    })}
                    loading={isSubmitting}
                    disabled={isSaving || isPreviewing}
                    onClick={() => setTroubleshootingMenuOpen(true)}>
                    {evolution.isDraft === true
                      ? 'Target & publish'
                      : 'Publish changes'}
                    <div className="icon-wrapper">
                      <i className="icon-exclamation-triangle-o" />
                    </div>
                  </Button>
                }>
                <TroubleShootingMenu
                  issues={issues}
                  onClose={() => setTroubleshootingMenuOpen(false)}
                />
              </Dropdown>
            )}
          </>
        )}
      </div>
      <ModalInstall
        isOpen={showModalInstall}
        onRequestClose={() => setShowModalInstall(false)}
      />
      <ModalConfirm
        className="exit-modal"
        title="Exit without saving?"
        isOpen={showExitModal}
        onConfirm={() => {
          setShowExitModal(false);
          const route = getPokeRoute(evolution.type);
          history.push(route(evolution.uid));
        }}
        onCancel={() => setShowExitModal(false)}
        cancelText="Cancel"
        confirmText="Exit"
        cancelBtnProps={{
          cta: false,
        }}
        confirmBtnProps={{
          danger: true,
          primary: false,
          cta: false,
        }}>
        <div className="content">
          You have unsaved changes, are you sure you want to exit without
          saving?
        </div>
      </ModalConfirm>
      <ModalCreatePoke
        isOpen={showEditInAppModal}
        pokeToEdit={evolution}
        type={type}
        onRequestClose={() => {
          setShowEditInAppModal(false);
          setIsModalPreviewMode(false);
        }}
        previewOnly={isModalPreviewMode}
      />

      {showLiveEditWarningModal && (
        <LiveEditWarningModal
          isOpen={showLiveEditWarningModal}
          onRequestClose={() => setShowLiveEditWarningModal(false)}
          closable
        />
      )}

      {showPublishChangesModal !== false && (
        <PublishChangesModal
          isOpen={showPublishChangesModal !== false}
          onRequestClose={() => setShowPublishChangesModal(false)}
          onConfirm={() => {
            if (showPublishChangesModal === 'save') {
              savePoke();
            } else {
              handleTargetAndPublishClick();
            }
            setShowPublishChangesModal(false);
          }}
        />
      )}
    </div>
  );
};

PokeBuilderHeader.propTypes = propTypes;
PokeBuilderHeader.defaultProps = defaultProps;

export default PokeBuilderHeader;
