import classnames from 'classnames';
import React, {useContext} from 'react';
import {PostContext} from '../../context';
import './_styles.scss';
import {
  BLOCK_LABEL,
  BLOCK_USER,
} from 'shared/front/components/Poke/constants/blocks';
import dayjs from 'dayjs';

const PostBlockLabel = () => {
  const {post, theme, hideDate} = useContext(PostContext);

  const step = post.steps[0] || {};
  const tags = post.tags || [];
  const block = step?.blocks.find((b) => b.type === BLOCK_LABEL);

  if (block == null) {
    return null;
  }

  const showDate =
    step?.blocks.find((b) => b.type === BLOCK_USER) == null && !hideDate;

  const {tags: tagsStyle} = theme ?? {};
  const {borderRadius} = tagsStyle ?? {};

  return (
    <div className={classnames('post-block-label')}>
      <div className="tags-wrapper">
        {tags
          .map((pt) => tags.find((t) => t.uid === pt.uid))
          .filter((pt) => pt !== undefined)
          .map((t) => (
            <div
              className={classnames('item-tag')}
              style={{
                backgroundColor: `${t.color}20`,
                color: t.color,
                ...(borderRadius != null
                  ? {
                      borderRadius: `${borderRadius}px`,
                    }
                  : {}),
              }}>
              {t.name}
            </div>
          ))}
      </div>
      {showDate === true && (
        <div
          className="timestamp"
          title={dayjs(post?.lastStepChangeAt || new Date()).format(
            'HH:mm - DD/MM/YYYY'
          )}>
          {dayjs(post?.lastStepChangeAt || new Date()).fromNow()}
        </div>
      )}
    </div>
  );
};

export default PostBlockLabel;
