import Divider from 'components/Divider';
import {object, func} from 'prop-types';
import Input from 'components/Input';
import classnames from 'classnames';
import {
  ACTIVE_OPERATOR_CUSTOM,
  ACTIVE_OPERATOR_SINGLE_URL,
} from 'scenes/EmbeddedElementSelectorBuilder';
import './_Styles.scss';
import Button from 'components/Button';
import {PathSelector} from 'scenes/Builder/scenes/Audience/components/PathSelector';

const propTypes = {
  evolution: object.isRequired,
  setEvolution: func.isRequired,
  requestCurrentUrl: func,
};

const defaultProps = {
  requestCurrentUrl: () => {},
};

const NavigationStep = ({evolution, setEvolution}) => {
  const isHardRedirect =
    evolution.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL;
  const isFree = evolution.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM;

  return (
    <div className="navigation-step">
      <div className="section">
        <div className="label">Behavior</div>
        <div className="behavior-options">
          <Button
            iconLeft="icon-forward"
            onClick={() =>
              setEvolution({
                ...evolution,
                boostedActiveOperator: ACTIVE_OPERATOR_SINGLE_URL,
              })
            }
            className={classnames('option', {selected: isHardRedirect})}>
            Hard redirect
          </Button>
          <Button
            iconLeft="icon-tour"
            onClick={() =>
              setEvolution({
                ...evolution,
                boostedActiveOperator: ACTIVE_OPERATOR_CUSTOM,
              })
            }
            className={classnames('option', {selected: isFree})}>
            Free
          </Button>
        </div>
        <div className="behavior-help">
          Hard redirect takes the user directly to a URL when they progress to
          the next step. If you want them to navigate on their own, try “Free”
          redirect.
        </div>
      </div>
      <Divider />
      {isHardRedirect && (
        <div className="section">
          <div className="label">Redirect to</div>
          <Input
            required
            className="active-url-input"
            name="value"
            type="string"
            onChange={(e) =>
              setEvolution({
                ...evolution,
                boostedActiveUrl: e.target.value,
              })
            }
            placeholder="https://www.yourawesomecompany.com/"
            value={evolution.boostedActiveUrl}
          />
        </div>
      )}
      {isFree && (
        <PathSelector evolution={evolution} setEvolution={setEvolution} small />
      )}
    </div>
  );
};

NavigationStep.propTypes = propTypes;
NavigationStep.defaultProps = defaultProps;

export default NavigationStep;
