import {uptProject, uptProjectMember} from 'actions/general';
import classnames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Input from 'components/Input';
import Select from 'components/Select';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Toggle from 'components/Toggle';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {ROUTE_SETTINGS_INSTALLATION} from 'router/routes.const';
import LabeledInput, {
  InnerLabeledInput,
} from 'scenes/Settings/components/LabeledInput';
import {generalSelector} from 'selectors';
import {projectService} from 'services';
import {
  F_EXTRA_DISABLE_TRIGGER_NOTIF_BADGE,
  F_EXTRA_PORTAL_STANDALONE_ENABLED,
  F_EXTRA_PORTAL_WIDGET_ENABLED,
  F_EXTRA_WIDGET_CLOSE_ON_OUTSIDE_CLICK,
  F_EXTRA_WIDGET_COLLAPSE_POST,
  F_EXTRA_WIDGET_MINIMIZING_SIDE_TRIGGER,
  F_GET_STARTED_CUSTOMIZE_WIDGET,
  F_GET_STARTED_ENABLE_ROADMAP,
  F_PAGE_FEED,
  F_PAGE_ROADMAP,
  TRIGGER_BADGE,
  TRIGGER_CUSTOM,
  TRIGGER_DEFAULT,
  TRIGGER_NONE,
  TRIGGER_SIDE_BADGE,
  WIDGET_POSITION_BOTTOM_LEFT,
  WIDGET_POSITION_BOTTOM_RIGHT,
} from 'services/project';
import {
  EmojiBadge,
  EmojiWidgetLeft,
  EmojiWidgetRight,
  EmojiWidgetTriggerSide,
} from 'shared/front/components/Emojis';
import PreviewContainer from '../../../../components/PreviewContainer';
import Saver from '../../../../components/Saver';
import {PORTAL_TYPE_STANDALONE, PORTAL_TYPE_WIDGET} from '../../Portal';
import {IframeMessenger} from '../../helpers/iframe-messenger';
import {WidgetContentToggler} from '../ContentToggler';
import PreviewWidget from '../PreviewWidget';
import './_Styles.scss';

class AppearanceWidget extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isSaving: false,
      isShowingTriggerPreview: false,
      inputWidgetTriggerMessage: '',
      widgetPosition: undefined,
      triggerMode: undefined,
      widgetLanguage: undefined,
      widgetThemeColor: undefined,
      widgetPrimaryColor: undefined,
      widgetContentFlags: undefined,
      widgetRoundness: undefined,
      widgetOffsets: undefined,
      extraFlags: undefined,
      widgetSectionOrder: undefined,
    };
    this.selectLanguageOptions = [
      {
        label: [<i className="icon-detect"></i>, 'Detect from user country'],
        value: 'AUTO',
      },
      {
        label: [<span className="flag-icon flag-icon-fr"></span>, 'Français'],
        value: 'FR',
      },
      {
        label: [<span className="flag-icon flag-icon-gb"></span>, 'English'],
        value: 'EN',
      },
      {
        label: [<span className="flag-icon flag-icon-es"></span>, 'Español'],
        value: 'ES',
      },
      {
        label: [<span className="flag-icon flag-icon-pt"></span>, 'Português'],
        value: 'PT',
      },
      {
        label: [<span className="flag-icon flag-icon-it"></span>, 'Italiano'],
        value: 'IT',
      },
      {
        label: [<span className="flag-icon flag-icon-de"></span>, 'Deutsch'],
        value: 'DE',
      },
      {
        label: [<span className="flag-icon flag-icon-jp"></span>, '日本'],
        value: 'JP',
      },
      {
        label: [<span className="flag-icon flag-icon-cn"></span>, '中國人'],
        value: 'ZH',
      },
      {
        label: [<span className="flag-icon flag-icon-sk"></span>, 'Slovenský'],
        value: 'SK',
      },
      {
        label: [<span className="flag-icon flag-icon-se"></span>, 'Svenska'],
        value: 'SV',
      },
    ];
    this.selectPositionOptions = [
      {
        label: [
          <EmojiWidgetRight width={20}></EmojiWidgetRight>,
          'Right (default)',
        ],
        value: WIDGET_POSITION_BOTTOM_RIGHT,
      },
      {
        label: [<EmojiWidgetLeft width={20}></EmojiWidgetLeft>, 'Left'],
        value: WIDGET_POSITION_BOTTOM_LEFT,
      },
    ];
    this.selectTypeOptions = [
      {
        label: <>Disable</>,
        value: TRIGGER_NONE,
      },
      {
        label: (
          <>
            <EmojiWidgetRight width={20}></EmojiWidgetRight> Float
          </>
        ),
        value: TRIGGER_DEFAULT,
      },
      {
        label: (
          <>
            <EmojiBadge width={18}></EmojiBadge> Badge
          </>
        ),
        value: TRIGGER_BADGE,
      },
      {
        label: (
          <>
            <EmojiWidgetTriggerSide width={18}></EmojiWidgetTriggerSide> Side
            badge
          </>
        ),
        value: TRIGGER_SIDE_BADGE,
      },
      {
        label: <>Custom</>,
        value: TRIGGER_CUSTOM,
      },
    ];
    this.selectRoundnessHeader = [
      {
        label: (
          <>
            <span className="icon-line-round"></span> Circular
          </>
        ),
        value: '50%',
      },
      {
        label: (
          <>
            <span className="icon-line-straight"></span> Linear
          </>
        ),
        value: '0%',
      },
    ];
    this.iframeMessenger = new IframeMessenger(
      React.createRef(),
      props.project
    );
  }

  hasChanges = () =>
    this.state.widgetPosition != null ||
    this.state.widgetLanguage != null ||
    this.state.widgetThemeColor != null ||
    this.state.widgetPrimaryColor != null ||
    this.state.widgetContentFlags != null ||
    this.state.widgetRoundness != null ||
    this.state.triggerMode != null ||
    this.state.extraFlags != null ||
    this.state.widgetOffsets != null ||
    this.state.widgetSectionOrder != null ||
    this.state.inputWidgetTriggerMessage !==
      this.props.project.widgetTriggerMessage;

  handleInputChange = ({target}) =>
    this.setState({[target.name]: target.value});

  handleTriggerModeChange = async (triggerMode) => {
    const {project} = this.props;

    this.setState({
      triggerMode:
        project.triggerMode === triggerMode.value
          ? undefined
          : triggerMode.value,
    });
  };
  handleLanguageChange = async (option) => {
    const {project} = this.props;

    this.setState({
      widgetLanguage:
        project.widgetLanguage === option.value ? undefined : option.value,
    });
  };
  handlePositionChange = async (option) => {
    const {project} = this.props;

    this.setState({
      widgetPosition:
        project.widgetPosition === option.value ? undefined : option.value,
    });
  };
  handleContentChange = (widgetContentFlags) => {
    const {project} = this.props;

    this.setState({
      widgetContentFlags:
        project.widgetContentFlags === widgetContentFlags
          ? undefined
          : widgetContentFlags,
    });
  };
  handleSectionOrderChange = (widgetSectionOrder) => {
    const {project} = this.props;

    this.setState({
      widgetSectionOrder:
        project.widgetSectionOrder === widgetSectionOrder
          ? undefined
          : widgetSectionOrder,
    });
  };
  handleRoundnessChange = (context, value) => {
    let widgetRoundness =
      this.state.widgetRoundness || this.props.project.widgetRoundness;

    widgetRoundness = widgetRoundness.split(';');
    switch (context) {
      case 'header': {
        widgetRoundness[0] = value;
        break;
      }
      case 'cards': {
        widgetRoundness[1] = value;
        break;
      }
      case 'cta': {
        widgetRoundness[2] = value;
        break;
      }
      default: {
        console.warn('Unknown widget roundness context : ', context);
        return null;
      }
    }
    widgetRoundness = widgetRoundness.join(';');
    if (widgetRoundness === this.props.project.widgetRoundness) {
      widgetRoundness = undefined;
    }
    this.setState({widgetRoundness});
  };
  handleOffsetsChange = (context, value) => {
    let widgetOffsets =
      this.state.widgetOffsets || this.props.project.widgetOffsets;

    widgetOffsets = widgetOffsets.split(';');
    switch (context) {
      case 'top': {
        widgetOffsets[0] = value;
        break;
      }
      case 'side': {
        widgetOffsets[1] = value;
        break;
      }
      default: {
        console.warn('Unknown widget offsets context : ', context);
        return null;
      }
    }
    widgetOffsets = widgetOffsets.join(';');
    if (widgetOffsets === this.props.project.widgetOffsets) {
      widgetOffsets = undefined;
    }
    this.setState({widgetOffsets});
  };
  handleCollapsePosts = ({target}) => {
    const {project} = this.props;
    let extraFlags = this.state.extraFlags || project.extraFlags;

    if (target.checked === true) {
      extraFlags = addFlag(F_EXTRA_WIDGET_COLLAPSE_POST, extraFlags);
    } else {
      extraFlags = removeFlag(F_EXTRA_WIDGET_COLLAPSE_POST, extraFlags);
    }
    this.setState({
      extraFlags: project.extraFlags === extraFlags ? undefined : extraFlags,
    });
  };

  handleCloseOnOutsideClick = ({target}) => {
    const {project} = this.props;
    let extraFlags = this.state.extraFlags || project.extraFlags;

    if (target.checked === true) {
      extraFlags = addFlag(F_EXTRA_WIDGET_CLOSE_ON_OUTSIDE_CLICK, extraFlags);
    } else {
      extraFlags = removeFlag(
        F_EXTRA_WIDGET_CLOSE_ON_OUTSIDE_CLICK,
        extraFlags
      );
    }
    this.setState({
      extraFlags: project.extraFlags === extraFlags ? undefined : extraFlags,
    });
  };
  handleMinimizingTrigger = ({target}) => {
    const {project} = this.props;
    let extraFlags = this.state.extraFlags || project.extraFlags;

    if (target.checked === true) {
      extraFlags = addFlag(F_EXTRA_WIDGET_MINIMIZING_SIDE_TRIGGER, extraFlags);
    } else {
      extraFlags = removeFlag(
        F_EXTRA_WIDGET_MINIMIZING_SIDE_TRIGGER,
        extraFlags
      );
    }
    this.setState({
      extraFlags: project.extraFlags === extraFlags ? undefined : extraFlags,
    });
  };
  handleDisableNotifBadge = ({target}) => {
    const {project} = this.props;
    let extraFlags = this.state.extraFlags || project.extraFlags;

    if (target.checked === true) {
      extraFlags = addFlag(F_EXTRA_DISABLE_TRIGGER_NOTIF_BADGE, extraFlags);
    } else {
      extraFlags = removeFlag(F_EXTRA_DISABLE_TRIGGER_NOTIF_BADGE, extraFlags);
    }
    this.setState({
      extraFlags: project.extraFlags === extraFlags ? undefined : extraFlags,
    });
  };
  handleSave = async () => {
    const {project, projectMember, uptProject, uptProjectMember} = this.props;
    const {
      widgetPosition,
      widgetLanguage,
      widgetThemeColor,
      widgetPrimaryColor,
      widgetContentFlags,
      widgetRoundness,
      widgetOffsets,
      extraFlags,
      triggerMode,
      inputWidgetTriggerMessage,
      widgetSectionOrder,
    } = this.state;

    this.setState({isSaving: true});
    try {
      const projectUpdated = await projectService.updateProject(project.uid, {
        widgetPosition,
        widgetLanguage,
        triggerMode,
        widgetTriggerMessage: inputWidgetTriggerMessage,
        widgetThemeColor,
        widgetPrimaryColor,
        widgetContentFlags,
        widgetRoundness,
        widgetOffsets,
        extraFlags,
        widgetSectionOrder,
      });

      uptProject(projectUpdated);
      this.handleResetChanges();
      this.setState({isSaving: false});
      if (
        hasFlag(
          F_GET_STARTED_CUSTOMIZE_WIDGET,
          projectMember.getStartedFlags
        ) === false
      ) {
        const getStartedFlagsUpdated = addFlag(
          F_GET_STARTED_CUSTOMIZE_WIDGET,
          projectMember.getStartedFlags
        );

        await projectService.updateGetStartedFlag({
          flag: getStartedFlagsUpdated,
        });
        uptProjectMember({getStartedFlags: getStartedFlagsUpdated});
      }
      if (
        hasFlag(F_PAGE_ROADMAP, projectUpdated.widgetContentFlags) === true &&
        hasFlag(F_GET_STARTED_ENABLE_ROADMAP, projectMember.getStartedFlags) ===
          false
      ) {
        const newGetStartedFlag = addFlag(
          F_GET_STARTED_ENABLE_ROADMAP,
          projectMember.getStartedFlags
        );

        await projectService.updateGetStartedFlag({flag: newGetStartedFlag});
        uptProjectMember({
          getStartedFlags: newGetStartedFlag,
        });
      }
      return toastSuccess(['Done!', `Changes has been saved successfully!`], {
        autoClose: 3000,
        toastId: 'changes-saved',
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      this.setState({isSaving: false});
      return toastDanger([title, message], {actions});
    }
  };
  handleResetChanges = () => {
    return this.setState({
      widgetLanguage: undefined,
      widgetPosition: undefined,
      widgetThemeColor: undefined,
      widgetPrimaryColor: undefined,
      widgetContentFlags: undefined,
      widgetRoundness: undefined,
      widgetOffsets: undefined,
      extraFlags: undefined,
      triggerMode: undefined,
      inputWidgetTriggerMessage:
        this.props.project.widgetTriggerMessage != null
          ? this.props.project.widgetTriggerMessage
          : '',
      widgetSectionOrder: undefined,
    });
  };

  render() {
    const {project, portalType, setPortalType} = this.props;
    const {
      inputWidgetTriggerMessage,
      triggerMode = project.triggerMode,
      widgetPosition = project.widgetPosition,
      widgetLanguage = project.widgetLanguage,
      widgetThemeColor = project.widgetThemeColor,
      widgetPrimaryColor = project.widgetPrimaryColor,
      widgetContentFlags = project.widgetContentFlags,
      widgetRoundness = project.widgetRoundness,
      extraFlags = project.extraFlags,
      widgetOffsets = project.widgetOffsets,
      isSaving,
      isShowingTriggerPreview,
      widgetSectionOrder = project.widgetSectionOrder,
    } = this.state;
    const [offsetY, offsetX] = widgetOffsets.split(';');

    return (
      <div className="appearance-widget fade-in">
        <div className="content">
          <div className="settings-wrapper">
            <div className="type-select-btns">
              <Button
                className={classnames({
                  selected: portalType === PORTAL_TYPE_WIDGET,
                })}
                onClick={() => setPortalType(PORTAL_TYPE_WIDGET)}
                disabled={
                  hasFlag(
                    F_EXTRA_PORTAL_WIDGET_ENABLED,
                    this.props.project.extraFlags
                  ) === false
                }>
                Widget
              </Button>
              <Button
                className={classnames({
                  selected: portalType === PORTAL_TYPE_STANDALONE,
                })}
                onClick={() => setPortalType(PORTAL_TYPE_STANDALONE)}
                disabled={
                  hasFlag(
                    F_EXTRA_PORTAL_STANDALONE_ENABLED,
                    this.props.project.extraFlags
                  ) === false
                }>
                Standalone
              </Button>
            </div>
            <LabeledInput
              title="Content"
              description="Decide what to display in your widget"
              className="change-content"
              vertical>
              <WidgetContentToggler
                contentFlags={widgetContentFlags}
                sectionOrder={widgetSectionOrder || 'feed;roadmap;feedback'}
                onChange={this.handleContentChange}
                onSectionOrderChange={this.handleSectionOrderChange}
              />
              <Divider />
              <div className="extra-collapse-posts">
                Collapse posts
                <Toggle
                  checked={hasFlag(F_EXTRA_WIDGET_COLLAPSE_POST, extraFlags)}
                  onChange={this.handleCollapsePosts}></Toggle>
              </div>
              <Divider />
              <div className="extra-close-outside-click">
                Close on outside click
                <Toggle
                  checked={hasFlag(
                    F_EXTRA_WIDGET_CLOSE_ON_OUTSIDE_CLICK,
                    extraFlags
                  )}
                  onChange={this.handleCloseOnOutsideClick}
                />
              </div>
            </LabeledInput>
            <Divider />
            <LabeledInput
              title="Trigger"
              description="Choose how to trigger your widget "
              className="change-trigger"
              vertical>
              <Select
                menuPlacement="top"
                options={this.selectTypeOptions}
                value={this.selectTypeOptions.find(
                  (p) => p.value === triggerMode
                )}
                onChange={this.handleTriggerModeChange}
                onFocus={() => this.setState({isShowingTriggerPreview: true})}
                onBlur={() =>
                  this.setState({isShowingTriggerPreview: false})
                }></Select>
              {triggerMode === TRIGGER_SIDE_BADGE && (
                <>
                  <div className="offset-input-wrapper">
                    <InnerLabeledInput title="Offset top">
                      <Input
                        value={offsetY}
                        onChange={({target}) =>
                          this.handleOffsetsChange('top', target.value)
                        }></Input>
                    </InnerLabeledInput>
                    <InnerLabeledInput title="Offset side">
                      <Input
                        value={offsetX}
                        onChange={({target}) =>
                          this.handleOffsetsChange('side', target.value)
                        }></Input>
                    </InnerLabeledInput>
                  </div>
                  <div className="minimizing-side-badge">
                    Minimizing trigger
                    <Toggle
                      checked={hasFlag(
                        F_EXTRA_WIDGET_MINIMIZING_SIDE_TRIGGER,
                        extraFlags
                      )}
                      onChange={this.handleMinimizingTrigger}></Toggle>
                  </div>
                </>
              )}
              {(triggerMode === TRIGGER_DEFAULT ||
                triggerMode === TRIGGER_SIDE_BADGE) && (
                <div className="extra-disable-notif-badge">
                  Disable red notification badge
                  <Toggle
                    checked={hasFlag(
                      F_EXTRA_DISABLE_TRIGGER_NOTIF_BADGE,
                      extraFlags
                    )}
                    onChange={this.handleDisableNotifBadge}></Toggle>
                </div>
              )}
            </LabeledInput>
            {(triggerMode === TRIGGER_CUSTOM ||
              triggerMode === TRIGGER_BADGE) && (
              <div className="trigger-custom-additional-step">
                {triggerMode !== project.triggerMode ? (
                  <>
                    After saving changes, <span className="number">1</span>{' '}
                    additional step will be added to your installation page
                  </>
                ) : (
                  <>
                    <Link to={ROUTE_SETTINGS_INSTALLATION}>
                      <span className="number">1</span> additional step has been
                      added to your installation page
                    </Link>
                  </>
                )}
              </div>
            )}
            {(triggerMode === TRIGGER_DEFAULT ||
              triggerMode === TRIGGER_SIDE_BADGE) && (
              <>
                <LabeledInput
                  title="Trigger message"
                  description="Use your own tone of voice to introduce your widget"
                  className="change-trigger-message fade-in-right"
                  vertical>
                  <Input
                    name="inputWidgetTriggerMessage"
                    value={inputWidgetTriggerMessage}
                    placeholder={
                      hasFlag(F_PAGE_FEED, widgetContentFlags) === true
                        ? `What's new on ${project.name}?`
                        : `Help us improve ${project.name}`
                    }
                    onChange={this.handleInputChange}
                    onFocus={() =>
                      this.setState({isShowingTriggerPreview: true})
                    }
                    onBlur={() =>
                      this.setState({isShowingTriggerPreview: false})
                    }></Input>
                </LabeledInput>
              </>
            )}
            <Divider />
            <LabeledInput
              title="Position on the page"
              description="Decide where widget should be displayed"
              className="change-position"
              vertical>
              <Select
                menuPlacement="top"
                options={this.selectPositionOptions}
                value={this.selectPositionOptions.find(
                  (p) => p.value === widgetPosition
                )}
                onChange={this.handlePositionChange}></Select>
            </LabeledInput>
            <Divider />
            <LabeledInput
              title="Language"
              description="(autodetect, or force a language)"
              className="change-language">
              <Select
                menuPlacement="top"
                options={this.selectLanguageOptions}
                value={this.selectLanguageOptions.find(
                  (o) => o.value === widgetLanguage
                )}
                onChange={this.handleLanguageChange}></Select>
            </LabeledInput>
          </div>
          <div className="preview-widget-container">
            <PreviewContainer className="preview-widget fade-in">
              <PreviewWidget
                project={{
                  ...project,
                  widgetPosition,
                  widgetLanguage,
                  widgetThemeColor,
                  widgetPrimaryColor,
                  widgetContentFlags,
                  widgetRoundness,
                  extraFlags,
                  widgetSectionOrder,
                }}
                widgetPosition={widgetPosition}
                showTrigger={isShowingTriggerPreview}
                widgetThemeColor={widgetThemeColor}
                widgetTriggerMode={triggerMode}
                widgetTriggerMessage={inputWidgetTriggerMessage}
                widgetContentFlags={widgetContentFlags}
                extraFlags={extraFlags}
                refIframe={this.iframeMessenger.refIframe}></PreviewWidget>
            </PreviewContainer>
          </div>
        </div>
        <Saver
          onSave={this.handleSave}
          onCancel={this.handleResetChanges}
          isOpen={this.hasChanges()}
          isSaving={isSaving}></Saver>
      </div>
    );
  }

  componentDidMount() {
    const {project} = this.props;

    this.setState({
      inputWidgetTriggerMessage:
        project.widgetTriggerMessage != null
          ? project.widgetTriggerMessage
          : '',
    });
  }
}

const mapStateToProps = (state) => ({
  project: generalSelector.getProject(state),
  projectMember: generalSelector.getProjectMember(state),
});
const mapDispatchToProps = (dispatch) => ({
  uptProject: (data) => dispatch(uptProject(data)),
  uptProjectMember: (data) => dispatch(uptProjectMember(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppearanceWidget);
