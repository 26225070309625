import {useContext, useState} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {BoostChannels} from './components/BoostChannels';
import {CustomizeOptions} from './components/CustomizeOptions';
import {Preview} from './components/Preview';
import './_styles.scss';

export const BuilderChannel = () => {
  const {
    evolution,
    setEvolution,
    setOriginalEvolution,
    customization,
    setCustomization,
  } = useContext(BuilderContext);

  const [customizeAppearanceMode, setCustomizeAppearanceMode] = useState(false);

  return (
    <div className="s-builder-channel">
      <div className="main-wrapper">
        {customizeAppearanceMode === true ? (
          <div key={1} className="customize-wrapper fade-in">
            <CustomizeOptions
              boostFlags={evolution.boostFlags}
              evolution={evolution}
              setEvolution={setEvolution}
              setOriginalEvolution={setOriginalEvolution}
              customization={customization}
              setCustomization={setCustomization}
              onClose={() => setCustomizeAppearanceMode(false)}
            />
          </div>
        ) : (
          <div key={2} className="content-wrapper fade-in">
            <BoostChannels />
          </div>
        )}
      </div>
      <div className="preview-wrapper">
        <Preview onCustomize={() => setCustomizeAppearanceMode(true)}></Preview>
      </div>
    </div>
  );
};
