import Button from 'components/Button';
import Input from 'components/Input';
import DefaultLoader from 'components/Loader';
import SelectGroup from 'components/Select';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {
  useHistory,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_SETTINGS_THEMES} from 'router/routes.const';
import {getDefaultBlockFromType} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {themeService} from 'services';
import {
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_HINT,
  F_BOOST_SLOT_POP_IN,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOOLTIP,
} from 'services/evolution';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
} from 'services/steps';
import {Swaler} from 'swaler';
import './_theme.scss';
import ThemeEditor from './components/ThemeEditor';
import ThemePreview from './components/ThemePreview';
import {getPreviewPoke, stylePreviewPoke} from './utils';

const logger = new Swaler('Settings/Theme');

const defaultBlocks = [
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_PRIMARY_CTA,
];
const hintAllowedBlocks = [
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_USER,
];
const PREVIEW_SELECT_OPTIONS = [
  {label: 'Snippet', value: F_BOOST_SLOT_SNIPPET},
  {label: 'Modal', value: F_BOOST_SLOT_POP_IN},
  {label: 'Hotspot', value: F_BOOST_SLOT_DOT},
  {label: 'Tooltip', value: F_BOOST_SLOT_TOOLTIP},
  {label: 'Hint', value: F_BOOST_SLOT_HINT},
];

export const SettingsTheme = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const [previewPoke, setPreviewPoke] = useState(getPreviewPoke());
  const [selectedBlockType, setSelectedBlockType] = useState(null);
  const [editedTheme, setEditedTheme] = useState({});
  const [originalTheme, setOriginalTheme] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {data: themes = []} = useQuery({
    queryKey: 'themes',
    queryFn: themeService.getThemes,
  });

  let theme = themes.find((t) => t.uid === match.params.themeId);

  useEffect(() => {
    if (
      selectedBlockType != null &&
      defaultBlocks.includes(selectedBlockType) !== true
    ) {
      setPreviewPoke((previewPoke) => ({
        ...previewPoke,
        steps: previewPoke.steps.map((step, index) => {
          if (index === 0) {
            return {
              ...previewPoke.steps[0],
              blocks: [
                ...previewPoke.steps[0]?.blocks.filter(
                  (block) => defaultBlocks.includes(block.type) === true
                ),
                getDefaultBlockFromType(selectedBlockType),
              ].filter((b) => {
                if (previewPoke.boostFlags === F_BOOST_SLOT_HINT) {
                  return hintAllowedBlocks.includes(b.type);
                }
                return true;
              }),
            };
          }
          return step;
        }),
      }));
    } else {
      setPreviewPoke((previewPoke) => ({
        ...previewPoke,
        steps: previewPoke.steps.map((step, index) => {
          if (index === 0) {
            return {
              ...previewPoke.steps[0],
              blocks: previewPoke.steps[0]?.blocks.filter(
                (block) => defaultBlocks.includes(block.type) === true
              ),
            };
          }
          return step;
        }),
      }));
    }
  }, [selectedBlockType]);
  useEffect(() => {
    setOriginalTheme(theme);
  }, []);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await themeService.updateTheme(theme.uid, theme);
      setOriginalTheme(editedTheme);
      history.push(ROUTE_SETTINGS_THEMES);
      toastSuccess(
        ['Theme updated', 'Your changes for this theme have been saved!'],
        {
          toastId: 'theme-changes-saved',
        }
      );
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('An error occurred in theme editor : ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsLoading(false);
    }
  };
  const handleCancel = () => {
    setEditedTheme(originalTheme);
  };

  if (theme == null) {
    return (
      <SettingsBody className="s-settings-theme loading">
        <DefaultLoader width="20px" />
      </SettingsBody>
    );
  }

  theme = {...theme, ...editedTheme};

  const hasUnsavedChanges =
    JSON.stringify(originalTheme) !== JSON.stringify(theme);
  const styledPreviewPoke = stylePreviewPoke(theme, previewPoke);

  return (
    <SettingsBody className="s-settings-theme">
      <div className="theme-editor-wrapper">
        <div className="editor-header-wrapper">
          <div className="editor-header-top">
            <Button
              iconOnly
              onClick={() => history.push(ROUTE_SETTINGS_THEMES)}>
              <i className="icon-chevron-left"></i>
            </Button>
            <Input
              value={editedTheme.name ?? theme.name}
              onChange={({target}) =>
                setEditedTheme({...theme, name: target.value})
              }></Input>
          </div>
          {hasUnsavedChanges === true && (
            <div className="editor-header-actions">
              <Button className="btn-cancel-theme" onClick={handleCancel}>
                Cancel
              </Button>
              <Button className="btn-save-theme" primary onClick={handleSave}>
                Save theme
              </Button>
            </div>
          )}
        </div>
        <div className="scrollable-wrapper">
          <ThemeEditor
            poke={styledPreviewPoke}
            theme={theme}
            setTheme={setEditedTheme}
            selectedBlockType={selectedBlockType}
            setSelectedBlockType={setSelectedBlockType}
          />
        </div>
      </div>
      <div className="theme-preview-wrapper">
        <div className="shape-selector-wrapper">
          Preview{' '}
          <SelectGroup
            small
            isSearchable={false}
            value={PREVIEW_SELECT_OPTIONS.find(
              (p) => p.value === styledPreviewPoke.boostFlags
            )}
            options={PREVIEW_SELECT_OPTIONS}
            onChange={(option) => {
              setPreviewPoke({...styledPreviewPoke, boostFlags: option.value});
            }}
          />
        </div>
        <ThemePreview
          poke={styledPreviewPoke}
          setPreviewPoke={setPreviewPoke}
          selectedBlockType={selectedBlockType}
          setSelectedBlockType={setSelectedBlockType}
        />
      </div>
    </SettingsBody>
  );
};
