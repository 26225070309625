import Divider from 'components/Divider';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import React, {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {F_OPTION_HOTSPOT_PERSISTENT} from 'services/evolution';
import RadioGroup from '../../../../../../components/RadioGroup';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import Position from '../../components/modals/Position';
import './_Styles.scss';

export const HOTSPOT_SHAPE_DEFAULT = 'default';
export const HOTSPOT_SHAPE_QUESTION_MARK = 'question-mark';
export const HOTSPOT_SHAPE_EXCLAMATION_MARK = 'exclamation-mark';
export const HOTSPOT_ANIMATION_PULSE = 'pulse';
export const HOTSPOT_ANIMATION_NONE = 'none';

const persistOptions = [
  {label: 'Hide', value: 'hide'},
  {label: 'Persist', value: 'persist'},
];

const shapeOptions = [
  {label: <i className="icon-slot-dot" />, value: HOTSPOT_SHAPE_DEFAULT},
  {
    label: <i className="icon-hotspot-shape-1" />,
    value: HOTSPOT_SHAPE_QUESTION_MARK,
  },
  {
    label: <i className="icon-hotspot-shape-2" />,
    value: HOTSPOT_SHAPE_EXCLAMATION_MARK,
  },
];

const animationOptions = [
  {label: 'Pulse', value: HOTSPOT_ANIMATION_PULSE},
  {label: 'No', value: HOTSPOT_ANIMATION_NONE},
];

const Hotspot = () => {
  const {
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
    isInApp,
    setIsEditingTargetElement,
    evolution: parentEvolution,
    setEvolution: setParentEvolution,
    selectedStepId,
  } = useContext(BuilderContext);

  const {boostedDotStyle, boostedQueryselector, querySelectorManual} =
    evolution;
  const [dotColor, dotSize, dotShape, dotAnimation] =
    boostedDotStyle.split(';');
  const isPersist = hasFlag(
    F_OPTION_HOTSPOT_PERSISTENT,
    evolution.optionsFlags
  );

  return (
    <div className="block-settings hotspot">
      <div className="section">
        <div className="section-title">Behavior</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">On click</div>
            <div className="section-item-content">
              <RadioGroup
                value={isPersist ? 'persist' : 'hide'}
                options={persistOptions}
                onSelect={(value) => {
                  setEvolution({
                    ...evolution,
                    optionsFlags:
                      value === 'persist'
                        ? addFlag(
                            F_OPTION_HOTSPOT_PERSISTENT,
                            evolution.optionsFlags
                          )
                        : removeFlag(
                            F_OPTION_HOTSPOT_PERSISTENT,
                            evolution.optionsFlags
                          ),
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">Style</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Shape</div>
            <div className="section-item-content">
              <RadioGroup
                value={dotShape || 'default'}
                options={shapeOptions}
                onSelect={(value) => {
                  setEvolution({
                    ...evolution,
                    boostedDotStyle: [
                      dotColor,
                      dotSize,
                      value,
                      dotAnimation,
                    ].join(';'),
                  });
                }}
              />
            </div>
          </div>
          {(dotShape === 'default' || !dotShape) && (
            <div className="section-item">
              <div className="section-item-title">Animation</div>
              <div className="section-item-content">
                <RadioGroup
                  value={dotAnimation || 'none'}
                  options={animationOptions}
                  onSelect={(value) => {
                    setEvolution({
                      ...evolution,
                      boostedDotStyle: [
                        dotColor,
                        dotSize,
                        dotShape,
                        value,
                      ].join(';'),
                    });
                  }}
                />
              </div>
            </div>
          )}
          <div className="section-item">
            <div className="section-item-title">Position</div>
            <div className="section-item-content position-picker">
              {isInApp === true ? (
                <ClickableInput
                  value={
                    evolution.querySelectorManual?.elementText != null ||
                    evolution.querySelectorManual?.cssSelector != null
                      ? 'Manual selector'
                      : evolution.boostedQueryselector || 'Select an element'
                  }
                  leftLabel={
                    <div className="position-icon-wrapper">
                      <i className="icon-target" />
                    </div>
                  }
                  onClick={() => {
                    setIsEditingTargetElement(true);
                  }}
                />
              ) : (
                <PopupSetting
                  large
                  trigger={
                    <ClickableInput
                      value={
                        evolution.querySelectorManual?.elementText != null ||
                        evolution.querySelectorManual?.cssSelector != null
                          ? 'Manual selector'
                          : boostedQueryselector || 'Select an element'
                      }
                      leftLabel={
                        <div className="position-icon-wrapper">
                          <i className="icon-target" />
                        </div>
                      }
                    />
                  }
                  title="Position"
                  content={
                    <Position
                      evolution={evolution}
                      setEvolution={setEvolution}
                    />
                  }
                  invalid={
                    evolution.querySelectorManual == null &&
                    !evolution.boostedQueryselector
                  }
                />
              )}
            </div>
          </div>
          <HotspotStyleSection
            style={boostedDotStyle}
            updateStyle={(style) => {
              setEvolution({...evolution, boostedDotStyle: style});
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const HotspotStyleSection = ({style, updateStyle}) => {
  const [dotColor, dotSize, dotShape, dotAnimation] = style.split(';');

  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Hotspot size</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={dotSize}
            min={8}
            max={42}
            onChange={(value) =>
              updateStyle([dotColor, value, dotShape, dotAnimation].join(';'))
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Color</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Color"
            value={dotColor}
            onChange={(value) =>
              updateStyle([value, dotSize, dotShape, dotAnimation].join(';'))
            }
          />
        </div>
      </div>
    </>
  );
};

export default Hotspot;
