import classnames from 'classnames';
import Lottie from 'lottie-react';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {BLOCK_ANIMATION} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {delay} from '../../utils/delay';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import check from './animations/check.json';
import confetti from './animations/confetti.json';
import heart from './animations/heart.json';
import smiley from './animations/smiley.json';
import './_styles.scss';

export const ANIMATION_TYPE_CONFETTI = 'confetti';
export const ANIMATION_TYPE_HEART = 'heart';
export const ANIMATION_TYPE_CHECK = 'check';
export const ANIMATION_TYPE_SMILEY = 'smiley';

export const parseAnimationValue = (value) => {
  const [type] = value.split(';');

  return {
    type,
  };
};

export const BlockAnimation = () => {
  const {currentStep, currentStepIndex, isBookingOpen} =
    useContext(PokeStateContext);

  const [init, setInit] = useState(false);
  const [ended, setEnded] = useState(false);

  const refLottie = useRef(null);

  const block = currentStep.blocks.find((b) => b.type === BLOCK_ANIMATION);
  const value = parseAnimationValue(block.value);
  const animationData = {
    [ANIMATION_TYPE_CONFETTI]: confetti,
    [ANIMATION_TYPE_HEART]: heart,
    [ANIMATION_TYPE_CHECK]: check,
    [ANIMATION_TYPE_SMILEY]: smiley,
  };
  const initialSegment = {
    [ANIMATION_TYPE_SMILEY]: [0, 60],
  };

  const play = async () => {
    setInit(false);
    if (value.type === ANIMATION_TYPE_CHECK) {
      refLottie.current.setSpeed(2);
    }
    refLottie.current.pause();
    await delay(1000);
    setInit(true);
    refLottie.current.goToAndPlay(0);
  };

  useEffect(() => {
    setEnded(false);
  }, [block.value]);

  useEffect(() => {
    if (refLottie.current != null && isBookingOpen !== true) {
      play();
    }
  }, [refLottie, isBookingOpen]);

  return (
    <div
      className={classnames('poke-block-animation', {
        'type-confetti': value.type === ANIMATION_TYPE_CONFETTI,
        'type-heart': value.type === ANIMATION_TYPE_HEART,
        'type-smiley': value.type === ANIMATION_TYPE_SMILEY,
        'type-check': value.type === ANIMATION_TYPE_CHECK,
        'is-hidden': ended === true,
        'is-init': init === true,
      })}>
      <Lottie
        key={currentStepIndex}
        lottieRef={refLottie}
        animationData={animationData[value.type]}
        autoPlay={true}
        initialSegment={initialSegment[value.type]}
        loop={false}
        onComplete={() => {
          if (init === true) {
            setEnded(true);
          }
        }}></Lottie>
      <ClickableBlockOverlay />
    </div>
  );
};
