import classNames from 'classnames';
import Divider from 'components/Divider';
import LivePreviewNew from 'components/LivePreviewNew';
import {hasFlag} from 'helpers/bitwise';
import {useContext, useEffect, useRef} from 'react';
import Draggable from 'react-draggable';
import {BuilderContext} from 'scenes/Builder/context';
import ElementTargeting from 'scenes/EmbeddedPokeBuilder/components/ElementTargeting';
import {F_BOOST_SLOT_NAVIGATION} from 'services/evolution';
import {F_STEP_IS_SELECTING_PRESET} from 'services/steps';
import BlockEditor from '../BlockEditor';
import BlockManager from '../BlockManager';
import LogicManager from '../LogicManager';
import Navigator from '../Navigator';
import PokeBuilderSidebar, {
  MODE_ADD_BLOCK,
  MODE_LOGIC,
  MODE_NAVIGATOR,
  MODE_THEME,
  MODE_TRANSLATIONS,
  MODE_TRIGGERS,
} from '../PokeBuilderSidebar';
import PresetSelector from '../PresetSelector';
import ThemeManager from '../ThemeManager';
import TranslationManager from '../TranslationManager';
import TriggerManager from '../TriggerManager';
import './_Styles.scss';

const LightweightPokeBuilder = () => {
  const {
    mode,
    setMode,
    messenger,
    controlledEvolution: poke,
    selectedStepId,
    selectedBlockType,
    selectedStep,
    inConcept,
    previewedPreset,
    isEditingTargetElement,
  } = useContext(BuilderContext);

  const lightWeightPokeBuilderRef = useRef(null);

  const isSelectingPreset = hasFlag(
    F_STEP_IS_SELECTING_PRESET,
    selectedStep?.stepFlags
  );

  useEffect(() => {
    messenger?.sendBuilderState({
      poke,
      selectedStepId,
      selectedBlockType,
      selectedStep,
      inConcept,
    });
  }, [
    messenger,
    poke,
    selectedStepId,
    selectedBlockType,
    selectedStep,
    inConcept,
  ]);

  useEffect(() => {
    setMode(MODE_NAVIGATOR);
  }, [selectedBlockType]);

  useEffect(() => {
    if (isSelectingPreset === true) {
      messenger?.sendHideExperienceUpdate(true);
    } else {
      messenger?.sendHideExperienceUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectingPreset]);

  const isNavigationStep = hasFlag(F_BOOST_SLOT_NAVIGATION, poke.boostFlags);

  if (poke.steps.length === 0 && isNavigationStep !== true) {
    return <></>;
  }

  return (
    <div className="lightweight-poke-builder-wrapper">
      <Draggable handle=".handle" bounds="body">
        <div
          ref={lightWeightPokeBuilderRef}
          className="lightweight-poke-builder"
          onMouseMove={() => messenger?.sendInAppForegroundForce(true)}
          onMouseLeave={() => messenger?.sendInAppForegroundForce(false)}>
          <div className="lightweight-poke-builder-header">
            {isEditingTargetElement !== true && isNavigationStep !== true && (
              <PokeBuilderSidebar hideContent />
            )}
            <div className="handle">
              <div className="icon-drag">
                <i className="icon-move" />
              </div>
            </div>
          </div>
          <Divider />
          {isNavigationStep === true ? (
            <BlockEditor />
          ) : isEditingTargetElement === true ? (
            <ElementTargeting />
          ) : isSelectingPreset ? (
            <PresetSelector />
          ) : (
            <>
              {mode === MODE_NAVIGATOR && (
                <>
                  <Navigator />
                  <Divider />
                  <BlockEditor />
                </>
              )}
              {mode === MODE_ADD_BLOCK && (
                <BlockManager onClose={() => setMode(MODE_NAVIGATOR)} />
              )}
              {mode === MODE_TRANSLATIONS && (
                <TranslationManager onClose={() => setMode(MODE_NAVIGATOR)} />
              )}
              {mode === MODE_LOGIC && (
                <LogicManager onClose={() => setMode(MODE_NAVIGATOR)} />
              )}
              {mode === MODE_THEME && (
                <ThemeManager onClose={() => setMode(MODE_NAVIGATOR)} />
              )}
              {mode === MODE_TRIGGERS && (
                <TriggerManager onClose={() => setMode(MODE_NAVIGATOR)} />
              )}
            </>
          )}
        </div>
      </Draggable>

      {isSelectingPreset === true && previewedPreset != null && (
        <div className="previewed-preset">
          <LivePreviewNew
            poke={previewedPreset.experience}
            selectedStepId={previewedPreset.stepId}
          />
        </div>
      )}
    </div>
  );
};

export default LightweightPokeBuilder;
