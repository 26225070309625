import LivePreview from 'components/LivePreview';
import {number, object, string} from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import {getBoostFlags} from 'scenes/Pushes/components/ModalCreatePoke';
import {
  TYPE_BANNER,
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {defaultCustomization, getDefaultEvolution} from 'services/evolution';
import {bannerTemplate, defaultTemplate} from './templates';
import './_Styles.scss';

const propTypes = {
  type: string,
  customization: object,
  forcedStepIndex: number,
};

const defaultProps = {
  type: TYPE_BANNER,
  customization: defaultCustomization,
  forcedStepIndex: 0,
};

const pokePreviewTemplates = [
  {
    type: TYPE_BANNER,
    template: bannerTemplate(),
  },
  {
    type: TYPE_HOTSPOT,
    template: defaultTemplate(),
  },
  {
    type: TYPE_SNIPPET,
    template: defaultTemplate(),
  },
  {
    type: TYPE_MODAL,
    template: defaultTemplate(),
  },
  {
    type: TYPE_TOOLTIP,
    template: defaultTemplate(),
  },
];

const PreviewPokes = ({forcedStepIndex, type, customization}) => {
  const template = pokePreviewTemplates.find((t) => t.type === type);
  const poke = getDefaultEvolution({
    boostFlags: getBoostFlags(template.type),
    steps: template.template.steps || [],
    ...customization,
  });
  const {steps} = poke;

  const [selectedStepId] = useState(steps[0].uid);

  const previewRef = useRef();

  const handleSelectedStepId = (stepId) => {
    previewRef?.current?.sendMessage?.({
      action: 'STEP_ID_SELECTED',
      stepId,
      fromJimo: true,
    });
  };

  useEffect(() => {
    handleSelectedStepId(selectedStepId);
  }, [selectedStepId]);

  return (
    <div className="preview-wrapper">
      <div className="preview-group-wrapper">
        <div className="preview-group">
          <div className="main-wrapper">
            <LivePreview boostedEvolution={poke} previewRef={previewRef} />
          </div>
        </div>
      </div>
    </div>
  );
};

PreviewPokes.propTypes = propTypes;
PreviewPokes.defaultProps = defaultProps;

export default PreviewPokes;
