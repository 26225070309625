import {object, func, string, bool} from 'prop-types';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import './_Styles.scss';
import {
  TAB_CHART,
  TAB_COMPLETION,
  TAB_CTA,
  TAB_GOAL,
  TAB_HINTS,
  TAB_STEPS,
  TAB_SUBMISSIONS,
} from '../..';
import classNames from 'classnames';

const propTypes = {
  analytics: object,
  evolution: object,
  onTabClick: func,
  selectedTab: string,
  isSingleStepPoke: bool,
};

const defaultProps = {
  analytics: null,
  evolution: null,
  onTabClick: () => {},
  selectedTab: null,
  isSingleStepPoke: false,
};

const StatsRow = ({
  analytics,
  evolution,
  onTabClick,
  selectedTab,
  isSingleStepPoke,
}) => {
  const {type, tracker} = evolution;
  const isTour = type === EVOLUTION_TYPE_TOUR;
  const isHint = type === EVOLUTION_TYPE_HINT;
  const isBanner = type === EVOLUTION_TYPE_BANNER;

  let viewsCount,
    completedCount,
    completedRate,
    ctaClicksCount,
    reachedGoal,
    reachedGoalRate;

  if (isTour || isBanner) {
    if (isTour) {
      viewsCount = analytics?.uniqueTourStartedCount || 0;
      completedCount = analytics?.uniqueTourCompletedCount || 0;
      completedRate =
        analytics?.uniqueTourStartedCount > 0
          ? parseInt(
              (analytics?.uniqueTourCompletedCount /
                analytics?.uniqueTourStartedCount) *
                100 *
                100,
              10
            ) / 100
          : 0;
    } else if (isBanner) {
      viewsCount =
        analytics?.uniqueBannerSeenCount ||
        0 + analytics?.uniqueTourStartedCount ||
        0; // legacy
    }
    ctaClicksCount = analytics?.ctaClicksCount || 0;
    reachedGoal = tracker?.analytics?.uniqueCount || 0;
    reachedGoalRate =
      viewsCount > 0
        ? parseInt((reachedGoal / viewsCount) * 100 * 100, 10) / 100
        : 0;

    return (
      <>
        <div className="statistics-row">
          {isSingleStepPoke !== true && (
            <div
              className={classNames('stat-wrapper', {
                selected: selectedTab === TAB_STEPS,
              })}
              onClick={() => onTabClick(TAB_STEPS)}>
              <div className="stat-icon users-engaged">
                <i className="icon-filter" />
              </div>
              <div className="stat-content">
                <div className="stat-value">
                  {completedCount} user{completedCount > 1 ? 's' : ''}
                  <div className="extra-value">{completedRate} %</div>
                </div>
                <div className="stat-title">Went through all steps</div>
              </div>
            </div>
          )}
          <div
            className={classNames('stat-wrapper', {
              selected: selectedTab === TAB_GOAL,
            })}
            onClick={() => onTabClick(TAB_GOAL)}>
            <div className="stat-icon users-engaged">
              <i className="isax isax-status-up" />
            </div>
            <div className="stat-content">
              <div className="stat-value">
                {reachedGoal} user{reachedGoal > 1 ? 's' : ''}
                <div className="extra-value">{reachedGoalRate} %</div>
              </div>
              <div className="stat-title">Reached goal</div>
            </div>
          </div>
          <div
            className={classNames('stat-wrapper', {
              selected: selectedTab === TAB_CHART,
            })}
            onClick={() => onTabClick(TAB_CHART)}>
            <div className="stat-icon views">
              <i className="icon-users" />
            </div>
            <div className="stat-content">
              <div className="stat-value">
                {viewsCount} user{viewsCount > 1 ? 's' : ''}
              </div>
              <div className="stat-title">Users reached</div>
            </div>
          </div>
          <div
            className={classNames('stat-wrapper', {
              selected: selectedTab === TAB_CTA,
            })}
            onClick={() => onTabClick(TAB_CTA)}>
            <div className="stat-icon cta">
              <i className="icon-pointer" />
            </div>
            <div className="stat-content">
              <div className="stat-value">
                {ctaClicksCount || 0} click{ctaClicksCount > 1 ? 's' : ''}
              </div>
              <div className="stat-title">Button action</div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (isHint) {
    const usersCount = analytics?.uniqueHintGroupSeenCount || 0;
    const totalHintsCount = analytics?.hintGroupSeenCount || 0;
    ctaClicksCount = analytics?.ctaClicksCount || 0;

    return (
      <>
        <div className="statistics-row">
          <div
            className={classNames('stat-wrapper', {
              selected: selectedTab === TAB_CHART,
            })}
            onClick={() => onTabClick(TAB_CHART)}>
            <div className="stat-icon views">
              <i className="icon-users" />
            </div>
            <div className="stat-content">
              <div className="stat-value">
                {usersCount} user{usersCount > 1 ? 's' : ''}
              </div>
              <div className="stat-title">Users reached</div>
            </div>
          </div>
          <div
            className={classNames('stat-wrapper', {
              selected: selectedTab === TAB_HINTS,
            })}
            onClick={() => onTabClick(TAB_HINTS)}>
            <div className="stat-icon views">
              <i className="icon-slot-tooltip" />
            </div>
            <div className="stat-content">
              <div className="stat-value">
                {totalHintsCount} time{totalHintsCount > 1 ? 's' : ''}
              </div>
              <div className="stat-title">Total tooltip shown</div>
            </div>
          </div>
          <div
            className={classNames('stat-wrapper', {
              selected: selectedTab === TAB_CTA,
            })}
            onClick={() => onTabClick(TAB_CTA)}>
            <div className="stat-icon cta">
              <i className="icon-pointer" />
            </div>
            <div className="stat-content">
              <div className="stat-value">
                {ctaClicksCount || 0} click{ctaClicksCount > 1 ? 's' : ''}
              </div>
              <div className="stat-title">Button action</div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    // survey
    viewsCount =
      parseInt(analytics?.uniqueSurveyStartedCount || 0, 10) +
      parseInt(analytics?.uniqueViewsCount || 0, 10); // legacy
    completedCount = analytics?.surveyCompletedCount || 0;
    completedRate =
      analytics?.surveyCount > 0
        ? parseInt(
            (analytics?.surveyCompletedCount / analytics?.surveyCount) *
              100 *
              100,
            10
          ) / 100
        : 0;
    ctaClicksCount = analytics?.ctaClicksCount || 0;

    return (
      <>
        <div className="statistics-row">
          <div
            className={classNames('stat-wrapper', {
              selected: selectedTab === TAB_CHART,
            })}
            onClick={() => onTabClick(TAB_CHART)}>
            <div className="stat-icon views">
              <i className="icon-users" />
            </div>
            <div className="stat-content">
              <div className="stat-value">{viewsCount} reached</div>
              <div className="stat-title">Users reached</div>
            </div>
          </div>
          <div
            className={classNames('stat-wrapper', {
              selected: selectedTab === TAB_SUBMISSIONS,
            })}
            onClick={() => onTabClick(TAB_SUBMISSIONS)}>
            <div className="stat-icon users-engaged">
              <i className="icon-filter" />
            </div>
            <div className="stat-content">
              <div className="stat-value">
                {completedCount} submission{completedCount > 1 ? 's' : ''}
              </div>
              <div className="stat-title">Finished submissions</div>
            </div>
          </div>
          <div
            className={classNames('stat-wrapper', {
              selected: selectedTab === TAB_COMPLETION,
            })}
            onClick={() => onTabClick(TAB_COMPLETION)}>
            <div className="stat-icon users-engaged">
              <i className="icon-filter" />
            </div>
            <div className="stat-content">
              <div className="stat-value">{completedRate}%</div>
              <div className="stat-title">Completion rate</div>
            </div>
          </div>
          <div
            className={classNames('stat-wrapper', {
              selected: selectedTab === TAB_CTA,
            })}
            onClick={() => onTabClick(TAB_CTA)}>
            <div className="stat-icon cta">
              <i className="icon-pointer" />
            </div>
            <div className="stat-content">
              <div className="stat-value">
                {ctaClicksCount || 0} click{ctaClicksCount > 1 ? 's' : ''}
              </div>
              <div className="stat-title">Button action</div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

StatsRow.propTypes = propTypes;
StatsRow.defaultProps = defaultProps;

export default StatsRow;
