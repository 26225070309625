import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import React, {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {BLOCK_TYPE_USER} from 'services/steps';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import ProfileLink from '../../components/modals/ProfileLink';
import {fontFamiliesOptions} from '../Title/utils';
import './_Styles.scss';

const Profile = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_USER, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_USER);

  if (block == null) {
    return <></>;
  }

  const {value, file, style} = block;
  const [name, title, fileUrl, altText] = value.split(';');

  return (
    <div className="block-settings profile">
      <div className="section">
        <div className="section-title">Content</div>
        <div className="section-content">
          <div className="section-item">
            <InputGroup
              className="profile-info-input"
              value={name}
              placeholder="Name"
              onChange={({target}) => {
                updateBlock({
                  value: `${target.value};${title};${fileUrl};${altText}`,
                });
              }}
            />
          </div>

          <div className="section-item">
            <InputGroup
              className="profile-info-input"
              value={title}
              placeholder="Title"
              onChange={({target}) => {
                updateBlock({
                  value: `${name};${target.value};${fileUrl};${altText}`,
                });
              }}
            />
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">Settings</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Image</div>
            <div className="section-item-content">
              <PopupSetting
                trigger={
                  <ClickableInput
                    key={fileUrl}
                    value={file?.originalName}
                    leftLabel={
                      fileUrl ? (
                        <div className="preview-profile-media">
                          <img src={fileUrl} alt="Media" />
                        </div>
                      ) : (
                        <span
                          className="placeholder-empty-media"
                          style={{background: '#FFFFFF'}}
                        />
                      )
                    }
                    erasable
                    onErase={() => {
                      updateBlock({
                        value: `${name};${title};;${altText}`,
                        file: null,
                        fileId: null,
                      });
                    }}
                  />
                }
                title="Media"
                content={
                  <ProfileLink
                    fileUrl={fileUrl}
                    altText={altText}
                    file={file}
                    onChange={({value, file}) => {
                      updateBlock({
                        value: `${name};${title};${value}`,
                        file,
                        fileId: file?.uid,
                      });
                    }}
                  />
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title body-3">Font family</div>
            <div className="section-item-content">
              <SelectGroup
                small
                options={fontFamiliesOptions}
                value={
                  fontFamiliesOptions.find(
                    (o) => o.value === style.fontFamily
                  ) ??
                  fontFamiliesOptions.find((font) => font.value === 'Inter')
                }
                onChange={(option) =>
                  updateBlock({
                    style: {...style, fontFamily: option.value},
                  })
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title body-3">Color</div>
            <div className="section-item-content">
              <ColorPickerInput
                inputProps={{small: true}}
                title="Color"
                value={style.fontColor ?? null}
                onChange={(value) =>
                  updateBlock({
                    style: {...style, fontColor: value},
                  })
                }
                erasable
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
