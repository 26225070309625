import Button from 'components/Button';
import EmptyState from 'components/EmptyState';
import React from 'react';
import {EmojiIdk} from 'shared/front/components/Emojis';
import './_Styles.scss';

export default function DevEmptyState() {
  return (
    <div className="_dev-empty-state">
      <div className="wrapper">
        <EmptyState
          icon={<EmojiIdk></EmojiIdk>}
          title="No data found"
          actions={
            <Button primary iconLeft="icon-plus">
              Add
            </Button>
          }>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit.
          Reprehenderit quibusdam impedit explicabo ut necessitatibus molestias
          totam laboriosam. Tempore cupiditate tenetur eveniet fugit sunt omnis.
          Placeat praesentium autem dolorem voluptatibus architecto.
        </EmptyState>
      </div>
    </div>
  );
}
