import {toastDanger} from 'components/Toaster';
import {GlobalContext} from 'contextes/Global';
import {errorHelpers} from 'helpers';
import {useJimoIdentify} from 'helpers/jimoOnJimo';
import {useContext, useState} from 'react';
import {subscriptionService} from 'services';
import {isEligibleToTrial} from 'services/project';
import {Swaler} from 'swaler';
import {useUpdatePlan} from './useUpdatePlan';

const logger = new Swaler('setModalWelcomePlan');

export const useStartTrial = ({
  onSuccess = () => {},
  onError = () => {},
  showWelcomeModal = true,
} = {}) => {
  const {setModalWelcomePlan} = useContext(GlobalContext);
  const {pushAttributes} = useJimoIdentify({forceIsIdentified: true});

  const {update} = useUpdatePlan();

  const [isStarting, setIsStarting] = useState(false);

  async function start(planId) {
    if (planId == null) return;
    if (isEligibleToTrial(planId) === false) return;

    setIsStarting(true);
    try {
      await subscriptionService.createOrUpdateStripeSubscription(
        {plan: planId},
        {
          isTrial: true,
        }
      );
      await update(planId);
      onSuccess();
      pushAttributes({
        isTrialing: true,
      });
      if (showWelcomeModal === true) {
        setModalWelcomePlan({planId, refreshProjectAfterClose: true});
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Launching trial failed with error ', code);
      toastDanger([title, message], {
        actions,
      });
    } finally {
      setIsStarting(false);
    }
  }

  return {
    start,
    isStarting,
  };
};
