import {GlobalContext} from 'contextes/Global';
import {useContext, useState} from 'react';
import {isEligibleToTrial, isPaying} from 'services/project';
import {isUpgrade} from 'services/subscription';
import {useDowngradePlan} from './useDowngradePlan';
import {usePlanPreview} from './usePlanPreview';
import {useUpgradePlan} from './useUpgradePlan';

// Open either a checkout, upgrade or downgrade modal should be shown based on the plan provided
export const useUpdateSubscription = ({onSuccess = () => {}} = {}) => {
  const {setModalSubscriptionCheckout} = useContext(GlobalContext);

  const {downgrade} = useDowngradePlan();
  const {upgrade} = useUpgradePlan();
  const {preview} = usePlanPreview();

  const [isUpdating, setIsUpdating] = useState(false);

  async function update({
    planId,
    modeAnnualBilling = false,
    forcePreviewMode = false,
  }) {
    if (forcePreviewMode === true) {
      preview({planId});
    } else if (isEligibleToTrial(planId)) {
      preview({planId});
    } else if (isPaying() === false) {
      setModalSubscriptionCheckout({
        planId,
        modeAnnualBilling,
        onCancel: () => {
          setIsUpdating(false);
        },
        onSuccess,
      });
    } else if (isUpgrade(planId) === true) {
      upgrade(planId);
    } else {
      downgrade(planId);
    }
  }

  return {
    update,
    isUpdating,
  };
};
