import classnames from 'classnames';
import commaNumber from 'comma-number';
import Button from 'components/Button';
import {Modal} from 'components/Modal';
import {GlobalContext} from 'contextes/Global';
import {useStartTrial} from 'hooks/useStartTrial';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {useContext} from 'react';
import {useSelector} from 'react-redux';
import planDetails from 'scenes/Settings/scenes/Subscription/Plan/plan-details';
import {dataSelector} from 'selectors';
import {isEligibleToTrial} from 'services/project';
import {
  hasAnnualBilling,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
} from 'services/subscription';
import './_styles.scss';

export const ModalPlanPreview = () => {
  const {modalPlanPreview, setModalPlanPreview} = useContext(GlobalContext);

  const {start, isStarting} = useStartTrial({
    onSuccess: () => {
      handleRequestClose();
    },
  });
  const {update} = useUpdateSubscription();

  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );

  const {planId, title} = modalPlanPreview ?? {};

  const handleRequestClose = () => {
    setModalPlanPreview(null);
  };
  const handleSubmit = () => {
    if (canTry === true) {
      start(planId);
    } else {
      update({planId, modeAnnualBilling: hasAnnualBilling()});
      setTimeout(() => {
        handleRequestClose();
      }, 1000);
    }
  };

  const plan = plans.find((plan) => plan.uid === planId);
  const canTry = isEligibleToTrial(planId);

  return (
    <Modal
      isOpen={true}
      onRequestClose={handleRequestClose}
      className={classnames(
        'modal-plan-preview',
        `plan-${planId.toLowerCase()}`
      )}>
      <Button
        className="btn-plan-preview-close"
        iconOnly
        thin
        onClick={handleRequestClose}>
        <i className="icon-close"></i>
      </Button>
      <div className="modal-main">
        <div className="background-gradient-wrapper">
          <div className="background-gradient"></div>
        </div>
        <div className="plan-logo"></div>
        <div
          className={classnames('plan-title title-3', {
            capitalize: title == null,
          })}>
          {title ?? `${planId.toLowerCase()} plan`}
        </div>
        {title != null && (
          <div className="body-2 n-700">You will also enjoy</div>
        )}

        <div className="plan-features">
          {[
            ...(planId === PLAN_GROWTH_ID
              ? [
                  {
                    uid: 'include-previous-plan',
                    title: `Everything in Startup Plan`,
                  },
                ]
              : planId === PLAN_SCALE_ID
              ? [
                  {
                    uid: 'include-previous-plan',
                    title: `Everything in Growth Plan`,
                  },
                ]
              : []),
            {uid: 'seats', title: `${plan.seats} seats`},
            {
              uid: 'mau',
              title: `Up to ${commaNumber(plan.mau)} Monthly Active Users`,
            },
          ]
            .concat(planDetails[planId])
            .map((feature) => {
              return (
                <div key={feature.uid} className="item-feature">
                  <i className="icon-tick"></i>
                  <div className="item-body">
                    <div className="body-3">{feature.title}</div>
                    {feature.description != null && (
                      <div className="body-3 n-500">{feature.description}</div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="modal-footer">
        <Button primary onClick={handleSubmit} loading={isStarting}>
          {canTry === true ? 'Start 14 days free trial' : 'Upgrade'}
        </Button>
        {canTry === true && (
          <div className="label-no-credit-card body-3 n-700">
            <i className="isax isax-tick-circle5"></i> No credit card required
          </div>
        )}
      </div>
    </Modal>
  );
};
