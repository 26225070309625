import classnames from 'classnames';
import React, {useContext} from 'react';
import {BLOCK_MEDIA} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';
import isURL from 'validator/lib/isURL';
import {hasFlag} from '../../../../helpers/bitwise';

export const F_BLOCK_ZOOM_ON_MEDIA_CLICK = 16;
export const MEDIA_TYPE_IMAGE = 'image';
export const MEDIA_TYPE_VIDEO = 'video';
export const MEDIA_SCALE_TYPE_FIT = 'fit';
export const MEDIA_SCALE_TYPE_FILL = 'fill';
export const MEDIA_POSITION_TOP = 'top';
export const MEDIA_POSITION_BOTTOM = 'bottom';

export const parseMediaValue = (value) => {
  const [type, url, altText, scaleType] = value.split(';');

  return {
    type,
    url,
    altText,
    scaleType,
  };
};

const getYoutubeId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};
const getYoutubeEmbedUrl = (url) => {
  const videoId = getYoutubeId(url);

  url = `https://www.youtube.com/embed/${videoId}`;
  return url;
};
const getEmbedUrl = (url) => {
  const vimeoPattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;

  // Loom
  if (url.startsWith('https://www.loom.com/share')) {
    return {
      player: 'loom',
      url: url.replace('share', 'embed'),
    };
  }
  // Youtube
  if (getYoutubeId(url)) {
    return {
      player: 'youtube',
      url: getYoutubeEmbedUrl(url),
    };
  }
  // Vimeo
  if (vimeoPattern.test(url) && !url.includes('player.vimeo.com')) {
    return {
      player: 'vimeo',
      url: url.replace(vimeoPattern, 'https://player.vimeo.com/video/$1'),
    };
  }
  return {
    player: null,
    url,
  };
};

export const BlockMedia = () => {
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    onImageClick = () => {},
  } = useContext(PokeStateContext);

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_MEDIA,
  });

  const block = currentStep.blocks.find((b) => b.type === BLOCK_MEDIA);
  const value = parseMediaValue(block.value);
  const {position, padding, height = 200, ...restStyle} = block.style ?? {};
  const marginBottom = position === MEDIA_POSITION_BOTTOM ? 0 : padding;
  const marginTop = position === MEDIA_POSITION_BOTTOM ? 12 : padding;

  const hasZoomOnMediaClick = hasFlag(F_BLOCK_ZOOM_ON_MEDIA_CLICK, block.flags);

  const defaultImageUrl = 'https://dummyimage.com/640x360/fff/aaa&text=media';

  const renderImage = () => {
    return (
      <>
        {value.scaleType === MEDIA_SCALE_TYPE_FILL && (
          <div
            className="media-file"
            style={{
              ...restStyle,
              backgroundImage: `url(${value.url || defaultImageUrl})`,
              backgroundSize:
                value.scaleType === MEDIA_SCALE_TYPE_FIT ? 'contain' : 'cover',
            }}
          />
        )}
        {value.scaleType === MEDIA_SCALE_TYPE_FIT && (
          <img
            src={value.url}
            alt={value.altText}
            style={{
              ...restStyle,
            }}
          />
        )}
        {hasZoomOnMediaClick === true && <i className="icon-search" />}
      </>
    );
  };
  const renderVideo = () => {
    const {player, url} = getEmbedUrl(value.url);

    if (player == null) {
      if (isURL(url || '') === true) {
        return (
          <iframe
            src={url}
            title="embedded video"
            allow="fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              ...restStyle,
            }}
          />
        );
      }
      return <></>;
    }
    return player === 'vimeo' ? (
      <div
        style={{
          position: 'relative',
          height: '100%',
        }}>
        <iframe
          src={url}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            ...restStyle,
          }}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="embedded video"
        />
      </div>
    ) : (
      <iframe
        src={url}
        title="embedded video"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        style={{
          ...restStyle,
        }}
      />
    );
  };

  return (
    <div
      className={classnames('poke-block-media', {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        'is-image': value.type === MEDIA_TYPE_IMAGE,
        'is-video': value.type === MEDIA_TYPE_VIDEO,
        'is-fit': value.scaleType === MEDIA_SCALE_TYPE_FIT,
        selected: selectedBlock === BLOCK_MEDIA,
        'is-clickable':
          value.type === MEDIA_TYPE_IMAGE && hasZoomOnMediaClick === true,
      })}
      style={{margin: padding, height, marginBottom, marginTop}}
      onClick={(e) => {
        e.stopPropagation();
        if (value.type === MEDIA_TYPE_IMAGE && hasZoomOnMediaClick === true) {
          onImageClick(value.url);
        }
        onBlockSelected(BLOCK_MEDIA);
      }}>
      {value.type === MEDIA_TYPE_IMAGE && renderImage()}
      {value.type === MEDIA_TYPE_VIDEO && renderVideo()}
      <ClickableBlockOverlay withoutOverflow />
    </div>
  );
};
