import ProgressBar from '@ramonak/react-progress-bar';
import classnames from 'classnames';
import Button from 'components/Button';
import CustomSlider from 'components/CustomSlider';
import Divider from 'components/Divider';
import {
  expiredTag,
  scheduledTag,
} from 'components/EvolutionList/components/Evolution';
import {MarkdownContent} from 'components/Markdown';
import Nps from 'components/Nps';
import {TagButton} from 'components/TagButton';
import TextArea from 'components/TextArea';
import {VOTE_IDK, VOTE_NAH, VOTE_YEAH} from 'constants/index';
import dayjs from 'dayjs';
import {hasFlag} from 'helpers/bitwise';
import {array, bool, func, number, object} from 'prop-types';
import {useEffect, useState} from 'react';
import {
  F_OPTION_COMMENTS_ENABLED,
  F_OPTION_LIKE_ENABLED,
} from 'services/evolution';
import {
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_BLOCK,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import {EmojiIdk, EmojiNah, EmojiYeah} from 'shared/front/components/Emojis';
import './_styles.scss';
import Vote from './components/Vote';

const propTypes = {
  step: object.isRequired,
  progress: number.isRequired,
  onVote: func.isRequired,
  responses: array,
  hideStatus: bool,
};

const defaultProps = {
  responses: [],
  hideStatus: false,
};

const PreviewSurvey = ({
  evolution,
  step,
  stepIndex,
  responses,
  progress,
  onVote,
  hideStatus,
}) => {
  const [value, setValue] = useState(null);
  const classNames = classnames('portal-channel-survey');

  useEffect(() => {
    if (step?.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT) {
      setValue([]);
    }
    if (step?.type === STEP_TYPE_TEXT_LONG) {
      setValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    const response = responses.find((r) => r?.step?.uid === step.uid);

    if (response != null) {
      // eslint-disable-next-line default-case
      switch (step?.type) {
        case STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT: {
          setValue(
            step.options.filter((o) =>
              response.selectedOptions.map((s) => s.uid).includes(o.uid)
            )
          );
          break;
        }
        case STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT: {
          setValue(
            step.options.find(
              (o) => o.uid === response.selectedOptions?.[0]?.uid
            )
          );
          break;
        }
        case STEP_TYPE_OPINION_SCALE: {
          setValue(parseInt(response.value, 10));
          break;
        }
        case STEP_TYPE_TEXT_LONG: {
          setValue(response.value);
          break;
        }
        case STEP_TYPE_SLIDER: {
          setValue(parseInt(response.value, 10));
          break;
        }
      }
    }
  }, [step, responses]);

  const handleSelectChange = (e, option, selected) => {
    e.preventDefault();
    if (step.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT) {
      // add option id to value array
      if (selected === true) {
        setValue([...value, option]);
      }
      // remove option id from value array
      else {
        setValue(value.filter((e) => e.uid !== option.uid));
      }
    }
    if (step.type === STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT) {
      if (selected === true) {
        setValue(option);
      } else {
        setValue(null);
      }
    }
  };

  const handleSubmit = async (val) => {
    // send survey data to api
    const data = {};

    if (step?.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT) {
      data.selectedOptions = value;
    }
    if (step?.type === STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT) {
      data.selectedOptions = value != null ? [value] : null;
    }
    if (step?.type === STEP_TYPE_TEXT_LONG) {
      data.value = value;
    }
    if (step?.type === STEP_TYPE_OPINION_SCALE) {
      data.value = val.toString();
    }
    if (step?.type === STEP_TYPE_SLIDER) {
      data.value = value?.toString();
    }
    if (step?.type === STEP_TYPE_NPS) {
      data.value = val;
    }
    onVote();
  };

  const question = step?.question;
  const content = step?.additionalInformation;
  const curDate = new Date();

  let actionContent;
  let extraTag = null;

  if (new Date(evolution.lastStepChangeAt).getTime() > curDate.getTime()) {
    extraTag = scheduledTag(evolution.lastStepChangeAt);
  }
  if (
    evolution.isDraft !== true &&
    extraTag == null &&
    evolution.expiresAt != null
  ) {
    extraTag = expiredTag(evolution.expiresAt);
  }

  if (
    [
      STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
      STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    ].includes(step?.type)
  ) {
    let inputType = 'radio';
    if (step?.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT) {
      inputType = 'checkbox';
    }
    actionContent = (
      <>
        <div className="select-wrapper">
          {step.options
            ?.sort((a, b) => a.indexOrder - b.indexOrder)
            ?.map((o) => {
              const content = o.content;
              const selected =
                step.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
                  ? value?.includes(o)
                  : value === o;
              return (
                <div key={o.uid}>
                  <div
                    className={classnames(
                      'survey-btn',
                      step?.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
                        ? 'checkbox-btn'
                        : 'select-btn',
                      {
                        selected,
                      }
                    )}
                    rounded="false"
                    onClick={(e) => handleSelectChange(e, o, !selected)}>
                    <input
                      type={inputType}
                      name="radio"
                      checked={selected || false}
                      readOnly
                    />
                    {selected === false && <span className="checkmark" />}
                    {selected === true &&
                      (step.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT ? (
                        <i className="icon-tick"></i>
                      ) : (
                        <span className="select-fill"></span>
                      ))}
                    <span className="content">{content}</span>
                  </div>
                </div>
              );
            })}
        </div>
        <Divider dark />
        <Vote step={step} onVote={handleSubmit} value={value} />
      </>
    );
  }

  if (step?.type === STEP_TYPE_TEXT_LONG) {
    actionContent = (
      <>
        <div className="input-wrapper">
          <TextArea
            className="text-long-textarea"
            style={{resize: 'none'}}
            placeholder="Tap your answer here"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <Divider dark />
        <Vote step={step} onVote={handleSubmit} value={value} />
      </>
    );
  }

  if (step?.type === STEP_TYPE_OPINION_SCALE) {
    actionContent = (
      <>
        <Divider dark />
        <div className="opinion-wrapper">
          <div className="opinion-scale">
            <EmojiYeah
              width={48}
              animated
              animateOnHover
              disabled={value !== VOTE_YEAH}
              tooltip=""
              tooltipProps={{on: ['hover', 'focus']}}
              onClick={() => handleSubmit(VOTE_YEAH)}
            />
            <EmojiIdk
              animated
              animateOnHover
              disabled={value !== VOTE_IDK}
              tooltip=""
              tooltipProps={{on: ['hover', 'focus']}}
              onClick={() => handleSubmit(VOTE_IDK)}
            />
            <EmojiNah
              animated
              animateOnHover
              disabled={value !== VOTE_NAH}
              tooltip=""
              tooltipProps={{on: ['hover', 'focus']}}
              onClick={() => handleSubmit(VOTE_NAH)}
            />
          </div>
        </div>
      </>
    );
  }

  if (step?.type === STEP_TYPE_SLIDER) {
    actionContent = (
      <>
        <div className="slider-wrapper">
          <CustomSlider value={value || 667} onChange={setValue} />
        </div>
        <Divider dark />
        <Vote step={step} onVote={handleSubmit} value={value} />
      </>
    );
  }

  if (step?.type === STEP_TYPE_NPS) {
    actionContent = (
      <div className="nps-wrapper">
        <Nps value={value} onChange={handleSubmit}></Nps>
      </div>
    );
  }

  if (step?.type === STEP_TYPE_TEXT_BLOCK) {
    const hasLikeEnabled = hasFlag(
      F_OPTION_LIKE_ENABLED,
      evolution.optionsFlags
    );
    const hasCommentEnabled = hasFlag(
      F_OPTION_COMMENTS_ENABLED,
      evolution.optionsFlags
    );

    actionContent = (
      <div className="text-block-wrapper">
        <MarkdownContent content={step.mdContent} />
        {(hasLikeEnabled === true || hasCommentEnabled === true) && (
          <div className="btns-wrapper">
            {hasLikeEnabled === true && (
              <Button iconLeft="icon-heart-o">
                {evolution.countVotes} likes
              </Button>
            )}
            {hasCommentEnabled === true && (
              <Button iconLeft="icon-comment-o">
                {evolution.countComments} comments
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }

  const shouldShowStatus =
    (hideStatus === false && evolution?.tags?.length > 0) ||
    evolution?.isDraft === true ||
    extraTag != null;

  return (
    <div className={classNames}>
      <div className="scrollable-wrapper">
        <div className="question-wrapper">
          {evolution.steps.length > 1 && (
            <ProgressBar
              completed={progress}
              className="progress-wrapper"
              barContainerClassName="progress-container"
              bgColor="#1260eb"
              isLabelVisible={false}
            />
          )}
          {shouldShowStatus === true && (
            <div className="tags-timestamp-wrapper">
              <div className="tags-wrapper">
                {hideStatus === false &&
                  evolution.tags.map((t) => <TagButton tag={t} />)}
              </div>
              <div className="date">
                {evolution.isDraft === true
                  ? `drafted ${dayjs(evolution.createdAt).fromNow()}`
                  : dayjs(evolution.lastStepChangeAt).fromNow()}
              </div>
            </div>
          )}
          <div className="infos">
            <div className="title-date-wrapper">
              <div className="title">{question}</div>
              {shouldShowStatus !== true && (
                <div className="date">
                  {dayjs(evolution.lastStepChangeAt).fromNow()}
                </div>
              )}
            </div>
            <div className="content">
              <div className="md-wrapper">
                <MarkdownContent content={content} />
              </div>
            </div>
          </div>
        </div>
        {actionContent}
      </div>
      {step?.type === STEP_TYPE_TEXT_BLOCK &&
        (step.ctaAction ||
          step.ctaUrl != null ||
          stepIndex + 1 !== evolution.steps.length) && (
          <div className="text-block-actions-wrapper">
            {(step.ctaAction || step.ctaUrl != null) && (
              <Button
                className="cta-btn"
                style={{
                  color: step.ctaColor,
                  backgroundColor: step.ctaBackgroundColor,
                  borderRadius: `${step.ctaBorderRadius}px`,
                }}>
                {step.ctaLabel}
              </Button>
            )}
            {stepIndex + 1 !== evolution.steps.length && (
              <Button primary onClick={onVote}>
                Next
              </Button>
            )}
          </div>
        )}
    </div>
  );
};

PreviewSurvey.propTypes = propTypes;
PreviewSurvey.defaultProps = defaultProps;

export default PreviewSurvey;
