import Divider from 'components/Divider';
import RadioGroup from 'components/RadioGroup';
import Toggle from 'components/Toggle';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import ChangelogCardsStyle from 'scenes/Settings/scenes/Themes/components/ChangelogThemeEditor/components/Cards';
import ChangelogTextStyle from 'scenes/Settings/scenes/Themes/components/ChangelogThemeEditor/components/Text';
import {
  F_EXTRA_COMMENTS_PRIVATE,
  F_EXTRA_WIDGET_COLLAPSE_POST,
} from 'services/project';
import './_Styles.scss';

const Feed = () => {
  const {
    changelogTheme: theme,
    setChangelogTheme: setTheme,
    project,
    setProject,
  } = useContext(BuilderContext);

  const updateStyle = (blockType, style) => {
    const newTheme = {
      ...theme,
      [blockType]: {
        ...theme?.[blockType],
        ...style,
      },
    };
    setTheme(newTheme);
  };

  return (
    <div className="block-settings group">
      <div className="section">
        <div className="section-title">Post</div>
        <div className="section-content">
          <ChangelogCardsStyle
            style={theme?.cards}
            setStyle={(style) => updateStyle('cards', style)}
          />
          <div className="section-item">
            <div className="section-item-title body-3">Preview</div>
            <div className="section-item-content">
              <RadioGroup
                options={[
                  {label: 'Collapse', value: true},
                  {label: 'Expand', value: false},
                ]}
                value={hasFlag(
                  F_EXTRA_WIDGET_COLLAPSE_POST,
                  project.extraFlags
                )}
                onSelect={(value) =>
                  setProject({
                    ...project,
                    extraFlags: value
                      ? addFlag(
                          F_EXTRA_WIDGET_COLLAPSE_POST,
                          project.extraFlags
                        )
                      : removeFlag(
                          F_EXTRA_WIDGET_COLLAPSE_POST,
                          project.extraFlags
                        ),
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      {/* <div className="section">
        <div className="section-content">
          <div className="section-item-toggle">
            <div className="section-item-text">
              <div className="section-item-title subtitle-4">
                Show Profile on Post
              </div>
              <div className="section-item-subtitle body-3">
                Always show profile information on every post you posted
              </div>
            </div>
            <div>
              <Toggle
                value={false}
                onChange={(value) => {
                  
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider /> */}
      <div className="section">
        <div className="section-content">
          <div className="section-item-toggle">
            <div className="section-item-text">
              <div className="section-item-title subtitle-4">
                Private Comments
              </div>
              <div className="section-item-subtitle body-3 n-700">
                Allow users to view only the comments they have posted
              </div>
            </div>
            <div>
              <Toggle
                checked={hasFlag(F_EXTRA_COMMENTS_PRIVATE, project.extraFlags)}
                onChange={() => {
                  setProject({
                    ...project,
                    extraFlags: hasFlag(
                      F_EXTRA_COMMENTS_PRIVATE,
                      project.extraFlags
                    )
                      ? removeFlag(F_EXTRA_COMMENTS_PRIVATE, project.extraFlags)
                      : addFlag(F_EXTRA_COMMENTS_PRIVATE, project.extraFlags),
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">Text</div>
        <div className="section-content">
          <ChangelogTextStyle
            style={theme?.text}
            setStyle={(style) => updateStyle('text', style)}
          />
        </div>
      </div>
    </div>
  );
};

export default Feed;
