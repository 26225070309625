import {Environment} from 'conf/env';
import React from 'react';
import {Redirect} from 'react-router-dom';
import DevAlert from './Examples/Alert';
import DevButton from './Examples/Button';
import DevCard from './Examples/Card';
import DevDropdown from './Examples/Dropdown';
import DevEmoji from './Examples/Emoji';
import DevEmptyState from './Examples/EmptyState';
import DevIcons from './Examples/Icons';
import DevInput from './Examples/Input';
import DevLoader from './Examples/Loader';
import DevMenu from './Examples/Menu';
import DevModal from './Examples/Modal';
import DevProgress from './Examples/Progress';
import DevSelect from './Examples/Select';
import DevTip from './Examples/Tip';
import DevToaster from './Examples/Toaster';
import './_Styles.scss';

export default class Dev extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showComponent: 'input'};
  }

  render() {
    if (Environment.NODE_ENV === 'production')
      return <Redirect path="/"></Redirect>;
    return (
      <div className="_dev">
        <h3>Components</h3>
        <ul>
          <li onClick={() => this.setState({showComponent: 'btn'})}>Button</li>
          <li onClick={() => this.setState({showComponent: 'input'})}>Input</li>
          <li onClick={() => this.setState({showComponent: 'alert'})}>Alert</li>
          <li onClick={() => this.setState({showComponent: 'modal'})}>Modal</li>
          <li onClick={() => this.setState({showComponent: 'emptystate'})}>
            EmptyState
          </li>
          <li onClick={() => this.setState({showComponent: 'tip'})}>Tip</li>
          <li onClick={() => this.setState({showComponent: 'dropdown'})}>
            Dropdown
          </li>
          <li onClick={() => this.setState({showComponent: 'card'})}>Card</li>
          <li onClick={() => this.setState({showComponent: 'menu'})}>Menu</li>
          <li onClick={() => this.setState({showComponent: 'icons'})}>Icon</li>
          <li onClick={() => this.setState({showComponent: 'emoji'})}>Emoji</li>
          <li onClick={() => this.setState({showComponent: 'loader'})}>
            Loader
          </li>
          <li onClick={() => this.setState({showComponent: 'toaster'})}>
            Toaster
          </li>
          <li onClick={() => this.setState({showComponent: 'select'})}>
            Select
          </li>
          <li onClick={() => this.setState({showComponent: 'progress'})}>
            Progress
          </li>
        </ul>

        <hr />

        {this.state.showComponent === 'btn' && <DevButton></DevButton>}
        {this.state.showComponent === 'input' && <DevInput></DevInput>}
        {this.state.showComponent === 'alert' && <DevAlert></DevAlert>}
        {this.state.showComponent === 'icons' && <DevIcons></DevIcons>}
        {this.state.showComponent === 'modal' && <DevModal></DevModal>}
        {this.state.showComponent === 'emptystate' && (
          <DevEmptyState></DevEmptyState>
        )}
        {this.state.showComponent === 'tip' && <DevTip></DevTip>}
        {this.state.showComponent === 'dropdown' && <DevDropdown></DevDropdown>}
        {this.state.showComponent === 'menu' && <DevMenu></DevMenu>}
        {this.state.showComponent === 'card' && <DevCard></DevCard>}
        {this.state.showComponent === 'loader' && <DevLoader></DevLoader>}
        {this.state.showComponent === 'emoji' && <DevEmoji></DevEmoji>}
        {this.state.showComponent === 'toaster' && <DevToaster></DevToaster>}
        {this.state.showComponent === 'select' && <DevSelect></DevSelect>}
        {this.state.showComponent === 'progress' && <DevProgress></DevProgress>}
      </div>
    );
  }
}
