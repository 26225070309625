import classNames from 'classnames';
import React, {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {ChangelogBuilderContext} from 'scenes/ChangelogBuilder';
import {TRIGGER_NONE} from 'services/project';
import './_Styles.scss';

const propTypes = {};
const defaultProps = {};

export const CHANGELOG_BLOCK_NAVBAR = 'CHANGELOG_BLOCK_NAVBAR';
export const CHANGELOG_BLOCK_POST_FEED = 'CHANGELOG_BLOCK_POST_FEED';
export const CHANGELOG_BLOCK_FEEDBACK = 'CHANGELOG_BLOCK_FEEDBACK';
export const CHANGELOG_BLOCK_TRIGGER = 'CHANGELOG_BLOCK_TRIGGER';

export const blocksObj = [
  {
    type: CHANGELOG_BLOCK_NAVBAR,
    name: 'Navbar',
    iconClassName: 'isax isax-menu-1',
  },
  {
    type: CHANGELOG_BLOCK_POST_FEED,
    name: 'Post feed page',
    iconClassName: 'isax isax-message-text',
  },
  {
    type: CHANGELOG_BLOCK_FEEDBACK,
    name: 'Feedback page',
    iconClassName: 'isax isax-message-favorite',
  },
];

const ChangelogNavigator = () => {
  const {selectedBlockType, setSelectedBlockType} = useContext(
    ChangelogBuilderContext
  );
  const {project} = useContext(BuilderContext);

  const orderedBlockTypes = [
    CHANGELOG_BLOCK_NAVBAR,
    CHANGELOG_BLOCK_POST_FEED,
    CHANGELOG_BLOCK_FEEDBACK,
  ];

  const NavigatorItem = ({type, iconClassName, name}) => {
    return (
      <div
        className={classNames('navigator-item', {
          selected: selectedBlockType === type,
        })}
        onClick={() => setSelectedBlockType(type)}>
        <div className="icon-wrapper">
          <i className={iconClassName} />
        </div>
        {name}
      </div>
    );
  };

  return (
    <div className="changelog-navigator-wrapper">
      <div className="navigator">
        <div className="section-title">Navigator</div>
        <div
          className={classNames('blocks-wrapper', {
            expanded: true,
          })}>
          <div
            className={classNames('blocks-title', {
              selected: selectedBlockType == null,
            })}
            onClick={() => setSelectedBlockType(null)}>
            <div className={classNames('step-name', {})}>
              <i className="isax isax-directbox-notif" />
              Changelog
            </div>
          </div>
          <div className="blocks">
            {orderedBlockTypes.map((type) => {
              const {iconClassName, name} = blocksObj.find(
                (b) => b.type === type
              );
              return (
                <NavigatorItem
                  key={type}
                  type={type}
                  iconClassName={iconClassName}
                  name={name}
                />
              );
            })}
          </div>
        </div>
        <div className="blocks-wrapper empty">
          <div
            className={classNames('blocks-title block-trigger', {
              disabled: project.triggerMode === TRIGGER_NONE,
              selected: selectedBlockType === CHANGELOG_BLOCK_TRIGGER,
            })}
            onClick={() => setSelectedBlockType(CHANGELOG_BLOCK_TRIGGER)}>
            <i className="isax isax-screenmirroring" />
            <div className="step-name">Trigger</div>
          </div>
        </div>
      </div>
    </div>
  );
};

ChangelogNavigator.propTypes = propTypes;
ChangelogNavigator.defaultProps = defaultProps;

export default ChangelogNavigator;
