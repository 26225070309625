import classNames from 'classnames';
import React, {useContext} from 'react';
import {
  BLOCK_BODY,
  BLOCK_CHOICE,
  BLOCK_CONCEPT,
  BLOCK_INTERVIEW,
  BLOCK_LABEL,
  BLOCK_MEDIA,
  BLOCK_NPS,
  BLOCK_OPEN_QUESTION,
  BLOCK_OPINION,
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_SLIDER,
  BLOCK_STEPPER,
  BLOCK_TITLE,
} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {BlockBody} from '../BlockBody';
import {BlockChoice} from '../BlockChoice';
import {BlockConcept} from '../BlockConcept';
import {BlockInterview} from '../BlockInterview';
import {BlockLabel} from '../BlockLabel';
import {BlockMedia, MEDIA_POSITION_BOTTOM} from '../BlockMedia';
import {BlockNPS} from '../BlockNPS';
import {BlockOpenQuestion} from '../BlockOpenQuestion';
import {BlockOpinion} from '../BlockOpinion';
import {BlockSlider} from '../BlockSlider';
import {BlockTitle} from '../BlockTitle';
import './_styles.scss';

export const BlockStep = () => {
  const {currentStep, currentStepIndex} = useContext(PokeStateContext);

  const blockMedia = currentStep.blocks.find((b) => b.type === BLOCK_MEDIA);

  //
  const isBottomBlock =
    currentStep.blocks.some((b) => {
      return (
        (b.type === BLOCK_STEPPER && b.style.position === 'bottom') ||
        b.type === BLOCK_CHOICE ||
        b.type === BLOCK_INTERVIEW ||
        b.type === BLOCK_NPS ||
        b.type === BLOCK_OPINION ||
        b.type === BLOCK_OPEN_QUESTION ||
        b.type === BLOCK_SLIDER ||
        b.type === BLOCK_CONCEPT ||
        (b.type === BLOCK_PRIMARY_CTA && b.removed !== true) ||
        (b.type === BLOCK_SECONDARY_CTA && b.removed !== true)
      );
    }) !== true;

  return (
    <div
      className={classNames('poke-block-step', {
        // 'auto-height': isHeightAuto,
        'is-bottom-block': isBottomBlock,
      })}>
      {/* Content */}
      {currentStep.blocks.findIndex((b) => b.type === BLOCK_LABEL) > -1 && (
        <BlockLabel />
      )}
      {currentStep.blocks.findIndex((b) => b.type === BLOCK_TITLE) > -1 && (
        <BlockTitle />
      )}
      {currentStep.blocks.findIndex((b) => b.type === BLOCK_BODY) > -1 && (
        <BlockBody />
      )}
      {blockMedia != null &&
        blockMedia?.style?.position === MEDIA_POSITION_BOTTOM && <BlockMedia />}

      {/* Interactive (adding key to make sure the component is fully rerender)*/}
      {currentStep.blocks.findIndex((b) => b.type === BLOCK_NPS) > -1 && (
        <BlockNPS key={`nps-${currentStepIndex}`} />
      )}
      {currentStep.blocks.findIndex((b) => b.type === BLOCK_SLIDER) > -1 && (
        <BlockSlider key={`slider-${currentStepIndex}`} />
      )}
      {currentStep.blocks.findIndex((b) => b.type === BLOCK_CHOICE) > -1 && (
        <BlockChoice key={`choice-${currentStepIndex}`} />
      )}
      {currentStep.blocks.findIndex((b) => b.type === BLOCK_OPEN_QUESTION) >
        -1 && <BlockOpenQuestion key={`open-question-${currentStepIndex}`} />}
      {currentStep.blocks.findIndex((b) => b.type === BLOCK_OPINION) > -1 && (
        <BlockOpinion key={`opinion-${currentStepIndex}`} />
      )}
      {currentStep.blocks.findIndex((b) => b.type === BLOCK_INTERVIEW) > -1 && (
        <BlockInterview key={`interview-${currentStepIndex}`} />
      )}
      {currentStep.blocks.findIndex((b) => b.type === BLOCK_CONCEPT) > -1 && (
        <BlockConcept key={`concept-${currentStepIndex}`} />
      )}
    </div>
  );
};
