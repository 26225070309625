import {useRouteMatch} from 'react-router-dom';
import {
  ROUTE_SETTINGS_SUBSCRIPTION_CARDS,
  ROUTE_SETTINGS_SUBSCRIPTION_COUPON,
  ROUTE_SETTINGS_SUBSCRIPTION_INVOICES,
  ROUTE_SETTINGS_SUBSCRIPTION_PLAN,
} from 'router/routes.const';
import Cards from './Cards';
import Coupon from './Coupon';
import SettingsSubscriptionInvoices from './Invoices';
import Plan from './Plan';
import './_Styles.scss';

const Subscription = () => {
  const routeMatch = useRouteMatch();

  return (
    <div className="s-settings-subscription">
      {routeMatch.path === ROUTE_SETTINGS_SUBSCRIPTION_PLAN ? (
        <Plan />
      ) : routeMatch.path === ROUTE_SETTINGS_SUBSCRIPTION_INVOICES ? (
        <SettingsSubscriptionInvoices />
      ) : routeMatch.path === ROUTE_SETTINGS_SUBSCRIPTION_CARDS ? (
        <Cards />
      ) : routeMatch.path === ROUTE_SETTINGS_SUBSCRIPTION_COUPON ? (
        <Coupon />
      ) : null}
    </div>
  );
};

export default Subscription;
