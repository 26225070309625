import {
  CircleNotifications as BellCircle,
  NewReleases as InfoStar,
  FiberNew as LabelNew,
  NotificationImportant as ShapBell,
  Notifications as SimpleBell,
  NotificationsNone as SimpleBell2,
  HelpCenter as SimpleHelp2,
  Info as SimpleInfo,
} from '@styled-icons/material';
import {
  AccountCircle as AccountCircleOutline,
  CircleNotifications as BellOutline,
  HelpOutline,
  NewReleases as InfoStarOutline,
  Textsms as MessageOutline,
  Verified as VerifiedOutline,
} from '@styled-icons/material-outlined';
import {
  AccountCircle,
  CalendarMonth,
  CheckCircle,
  CheckCircleOutline,
  ErrorOutline,
  Favorite,
  FavoriteBorder,
  Groups,
  Help,
  HelpCenter as HelpRounded,
  Help as HelpRounded2,
  Info,
  NewReleases as InfoStarRounded,
  Launch,
  Mail,
  MailOutline,
  Message,
  PermIdentity,
  Report,
  Info as RoundedInfo,
  Sms,
  Verified,
  VerifiedUser,
  Warning,
} from '@styled-icons/material-rounded';
import {
  CircleNotifications as CircleTwoToneBell,
  Help as HelpTwoTone,
  NewReleases as InfoStarTwoTone,
  Info as InfoTwoTone,
  FiberNew as LabelNewTwoTone,
  Notifications as TwoToneBell,
} from '@styled-icons/material-twotone';
import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import DefaultLoader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {func, string} from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {fileService} from 'services';
import {BLOCK_TYPE_HINT} from 'services/steps';
import {EmojiBell} from 'shared/front/components/Emojis';
import {Swaler} from 'swaler';
import RadioGroup from '../../../../../../../components/RadioGroup';
import './_Styles.scss';

export const HINT_ICON_BUILT_IN = 'built-in';
export const HINT_ICON_CUSTOM = 'custom';

const logger = new Swaler('HintIcons');

const propTypes = {
  value: string,
  onChange: func,
};

const defaultProps = {
  value: {},
  onChange: () => {},
};

const iconSourceOptions = [
  {label: 'Built-in', value: HINT_ICON_BUILT_IN},
  {label: 'Custom', value: HINT_ICON_CUSTOM},
];

export const hintIconsList = [
  {icon: <Groups fontSize="small" />, label: 'Group', value: 'group'},
  {icon: <CalendarMonth />, label: 'Calendar', value: 'calendar'},
  {icon: <VerifiedUser />, label: 'Verified', value: 'verified'},
  {icon: <CheckCircle />, label: 'Check', value: 'check'},
  {icon: <Help />, label: 'Help', value: 'help'},
  {icon: <Mail />, label: 'Envelope', value: 'mail'},
  {icon: <Message />, label: 'Message', value: 'message'},
  {icon: <Verified />, label: 'New Badge', value: 'new-badge'},
  {icon: <Report />, label: 'Report', value: 'report'},
  {icon: <Warning />, label: 'Warning', value: 'warning'},
  {icon: <Info />, label: 'Info', value: 'info'},
  {icon: <Favorite />, label: 'Heart', value: 'heart'},
  {icon: <Sms />, label: 'Message', value: 'message'},
  {
    icon: <AccountCircle />,
    label: 'Circular Profile',
    value: 'circular-profile',
  },
  {icon: <Launch />, label: 'Arrow Up-Right', value: 'arrow-up-right'},
  {icon: <ErrorOutline />, label: 'Error', value: 'error'},
  {icon: <PermIdentity />, label: 'Profile', value: 'profile'},
  {
    icon: <CheckCircleOutline />,
    label: 'Check (outline)',
    value: 'check-outline',
  },
  {
    icon: <VerifiedOutline />,
    label: 'Verified (outline)',
    value: 'verified-outline',
  },
  {icon: <HelpOutline />, label: 'Help (outline)', value: 'help-outline'},
  {icon: <FavoriteBorder />, label: 'Heart (outline)', value: 'heart-outline'},
  {
    icon: <AccountCircleOutline />,
    label: 'Circular profile (outline)',
    value: 'profile-outline',
  },
  {icon: <MailOutline />, label: 'Envelope (outline)', value: 'mail-outline'},
  {
    icon: <MessageOutline />,
    label: 'Message (outline)',
    value: 'message-outline',
  },
];

export const triggerIconsList = [
  {
    icon: <EmojiBell autoplay={true} />,
    label: 'Animated Bell',
    value: null,
  },
  {icon: <BellCircle />, label: 'Bell Circle', value: 'bell-circle'},
  {icon: <BellOutline />, label: 'Bell Outline', value: 'bell-outline'},
  {icon: <SimpleBell />, label: 'Simple Bell', value: 'simple-bell'},
  {icon: <SimpleBell2 />, label: 'Simple Bell 2', value: 'simple-bell-2'},
  {icon: <ShapBell />, label: 'Shap Bell', value: 'shap-bell'},
  {icon: <TwoToneBell />, label: 'Two tone Bell', value: 'two-tone-bell'},
  {
    icon: <CircleTwoToneBell />,
    label: 'Circle Two tone Bell',
    value: 'circle-two-tone-bell',
  },
  {icon: <InfoTwoTone />, label: 'Info two tone', value: 'info-two-tone'},
  {icon: <SimpleInfo />, label: 'Simple Info', value: 'simple-info'},
  {icon: <RoundedInfo />, label: 'Rounded Info', value: 'rounded-info'},
  {icon: <HelpTwoTone />, label: 'Help two tone', value: 'help-two-tone'},
  {icon: <Help />, label: 'Simple Help', value: 'simple-help'},
  {icon: <HelpOutline />, label: 'Help Outline', value: 'help-outline'},
  {icon: <SimpleHelp2 />, label: 'Simple Help 2', value: 'simple-help-2'},
  {icon: <HelpRounded />, label: 'Help rounded', value: 'help-rounded'},
  {icon: <HelpRounded2 />, label: 'Help Rounded 2', value: 'help-rounded-2'},
  {
    icon: <InfoStarTwoTone />,
    label: 'Info Star two tone',
    value: 'info-star-two-tone',
  },
  {
    icon: <InfoStarOutline />,
    label: 'Info Star outline',
    value: 'info-star-outline',
  },
  {icon: <InfoStar />, label: 'Info Star', value: 'info-star'},
  {
    icon: <InfoStarRounded />,
    label: 'Info Star rounded',
    value: 'info-star-rounded',
  },
  {icon: <LabelNew />, label: 'Label new', value: 'label-new'},
  {
    icon: <LabelNewTwoTone />,
    label: 'Label new two tone',
    value: 'label-new-two-tone',
  },
];

const FILE_TYPE_CHANGELOG_TRIGGER = 'CHANGELOG_TRIGGER';

const Icon = ({icon, url, label, onClick, selected}) => {
  return (
    <div
      className={classNames('item-icon-wrapper', {
        selected: selected,
      })}
      onClick={onClick}>
      <div className="item-icon">
        {icon && icon}
        {url && <img src={url} alt="" />}
      </div>
      {label && <div className="label body-4 n-700">{label}</div>}
    </div>
  );
};

const IconPicker = ({style, file, onChange, type, setCloseOnDocumentClick}) => {
  const [isUploading, setIsUploading] = useState(false);

  const refInputFile = useRef();
  const iconWrapperRef = useRef();

  const fileType =
    type === 'hint'
      ? BLOCK_TYPE_HINT
      : type === 'trigger'
      ? FILE_TYPE_CHANGELOG_TRIGGER
      : null;

  const {
    data: recentFiles,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['recentFiles', fileType],
    queryFn: () => fileService.getFiles({type: fileType, limit: 10}),
    refetchOnWindowFocus: false,
  });

  const handleFileChange = async ({target}) => {
    if (!target.files || target.files.length === 0) {
      return;
    }
    setIsUploading(true);
    const file = await uploadFile(target.files[0]);
    setIsUploading(false);

    if (file != null) {
      onChange({
        style: {
          ...style,
          iconUrl: file.publicUrl,
        },
        file,
      });
    }
  };

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    try {
      const uploadedFile = await fileService.uploadPublicFile({
        file,
        blockType: fileType,
      });
      refetch();
      return uploadedFile;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to upload file', code);
      toastDanger([title, message], {actions});
      return null;
    }
  };

  const customList =
    recentFiles?.map((file) => ({
      url: file.publicUrl,
      file: file,
    })) || [];

  const iconList = type === 'hint' ? hintIconsList : triggerIconsList;

  useEffect(() => {
    const svg = iconWrapperRef.current?.querySelector('svg');
    if (svg) {
      const paths = svg.querySelectorAll('path');
      paths.forEach((path) => {
        path.style.fill = 'black';
      });
    }
  }, [iconWrapperRef.current, style.iconSource]);

  return (
    <>
      <div className="section-item">
        <RadioGroup
          value={style.iconSource}
          options={iconSourceOptions}
          onSelect={(v) =>
            onChange({
              style: {
                ...style,
                iconSource: v,
              },
              file,
            })
          }
        />
      </div>
      <Divider />
      <div className="picker-icon-content">
        {style.iconSource === HINT_ICON_BUILT_IN && (
          <div className="built-in-list" ref={iconWrapperRef}>
            {iconList.map((icon) => (
              <Icon
                icon={icon.icon}
                label={icon.label}
                onClick={() =>
                  onChange({
                    style: {
                      ...style,
                      iconUrl: null,
                      iconName: icon.value,
                    },
                    file: null,
                  })
                }
                selected={style.iconName === icon.value}
              />
            ))}
          </div>
        )}
        {style.iconSource === HINT_ICON_CUSTOM && (
          <>
            {isLoading === true ? (
              <div className="loading-wrapper">
                <DefaultLoader />
              </div>
            ) : customList.length === 0 ? (
              <div className="empty-state-wrapper body-3 n-700">
                Upload icons from your computer to use them in Jimo (PNG, JPG,
                and SVG)
              </div>
            ) : (
              <div className="custom-list">
                {customList.map((icon) => {
                  return (
                    <Icon
                      url={icon.url}
                      onClick={() =>
                        onChange({
                          style: {
                            ...style,
                            iconName: null,
                            iconUrl: icon.url,
                          },
                          file: icon.file,
                        })
                      }
                      selected={style.iconUrl === icon.url}
                    />
                  );
                })}
              </div>
            )}
            <Divider />
            <div className="action-wrapper">
              <input
                ref={refInputFile}
                type="file"
                accept="image/svg+xml, image/png, image/jpeg"
                style={{display: 'none'}}
                onChange={handleFileChange}
              />
              <Button
                thin
                iconLeft="isax isax-document-upload"
                onClick={() => refInputFile.current.click()}
                disabled={isUploading}>
                Upload
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

IconPicker.propTypes = propTypes;
IconPicker.defaultProps = defaultProps;

export default IconPicker;
