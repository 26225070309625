import {Menu, MenuItem} from 'components/Menu';
import React from 'react';
import {
  EmojiEvolutionAnnouncement,
  EmojiEvolutionFeaturePreview,
  EmojiEvolutionLiveForVote,
} from 'shared/front/components/Emojis';
import './_Styles.scss';

export default function DevMenu() {
  return (
    <div className="_dev-menu">
      <div className="wrapper">
        <Menu>
          <MenuItem>Test</MenuItem>
          <MenuItem icon="icon-overview">Overview</MenuItem>
          <MenuItem
            badge={1}
            icon={
              <EmojiEvolutionLiveForVote
                inline
                width={16}></EmojiEvolutionLiveForVote>
            }>
            Live for vote
          </MenuItem>
          <MenuItem
            icon={
              <EmojiEvolutionFeaturePreview
                inline
                width={16}></EmojiEvolutionFeaturePreview>
            }>
            Feature preview
          </MenuItem>
          <MenuItem
            icon={
              <EmojiEvolutionAnnouncement
                inline
                width={16}></EmojiEvolutionAnnouncement>
            }>
            Announcement
          </MenuItem>
          <MenuItem icon="icon-chevron-right" iconPosition="right">
            Sign out
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}
