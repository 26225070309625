import Button from 'components/Button';
import {EmptyStateOnboarding} from 'components/EmptyState';
import React from 'react';
import {Link} from 'react-router-dom';
import {ROUTE_CHANGELOG_BUILDER} from 'router/routes.const';
import './_Styles.scss';
import animation from './animation.json';

export default class ReleaseEmptyState extends React.Component {
  render() {
    return (
      <EmptyStateOnboarding
        className="release-empty-state"
        title="Announce your latest update."
        actions={
          <Link to={ROUTE_CHANGELOG_BUILDER}>
            <Button primary iconLeft="icon-plus">
              Create changelog
            </Button>
          </Link>
        }
        animation={animation}>
        <p>
          Give credit to either new or existing features or bug fixes and make
          sure your users stay tune with your product evolution.
        </p>
      </EmptyStateOnboarding>
    );
  }
}
