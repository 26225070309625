import classnames from 'classnames';
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './_Styles.scss';

const SelectGroup = (props) => {
  const {hideDropdown, small = false, legend, styles = {}} = props;
  const classNames = classnames('jimo-select', props.className, {
    small,
  });
  const handleKeyDown = (e) => {
    const {onPressEnter = null} = props;

    if (onPressEnter != null) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        onPressEnter();
      }
    }
  };

  return (
    <div className="select-group-wrapper">
      {legend != null && (
        <div
          className={classnames('select-legend body-3', {
            disabled: props.isDisabled === true,
          })}>
          {legend}
        </div>
      )}
      <Select
        {...props}
        components={{
          DropdownIndicator() {
            return hideDropdown === true ? null : (
              <i className="icon-chevron-bottom"></i>
            );
          },
          ...props.components,
        }}
        onKeyDown={handleKeyDown}
        className={classNames}
        classNamePrefix={classnames(props.classNamePrefix, 'jimo-select')}
        styles={{
          ...(styles || {}),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            ...(styles.menuPortal || {}),
          }),
        }}
      />
    </div>
  );
};

export const Creatable = (props) => {
  const classNames = classnames('jimo-creatable', props.className);

  return (
    <CreatableSelect
      {...props}
      className={classNames}
      classNamePrefix="jimo-creatable"
    />
  );
};

export default SelectGroup;
