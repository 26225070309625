import {generalActions} from 'actions';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Card from 'components/Card';
import Input from 'components/Input';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import React from 'react';
import {connect} from 'react-redux';
import {generalSelector} from 'selectors';
import {couponService, projectService} from 'services';
import {COUPON_TYPE_SAAS_MANTRA_OFFER_1} from 'services/coupon';
import {EmojiIdk} from 'shared/front/components/Emojis';
import {Swaler} from 'swaler';
import './_Styles.scss';
import logoSaasMantra from './img/logo_saas_mantra.svg';

class SettingsSubscriptionCoupon extends React.Component {
  logger = new Swaler('Settings/Team');

  constructor() {
    super();
    this.state = {
      isSubmitting: false,
      submitError: null,
      inputCoupon: '',
    };
  }

  handleInputChange = ({target}) =>
    this.setState({[target.name]: target.value});
  handleSubmitCoupon = async (e) => {
    const {project, uptProject} = this.props;
    const {inputCoupon} = this.state;

    e.preventDefault();
    this.setState({isSubmitting: true, submitError: null});
    try {
      await couponService.activate({
        projectId: project.uid,
        code: inputCoupon,
      });
      const updatedProject = await projectService.getProject(project.uid);

      this.setState({
        isSubmitting: false,
        inputCoupon: '',
      });
      uptProject(updatedProject);
      return toastSuccess(
        ['Coupon applied', `The coupon has been successfully applied.`],
        {toastId: 'coupon-applied'}
      );
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      this.logger.error('Unable to activate coupon : ', code);
      return toastDanger([title, message], {actions});
    }
  };

  render() {
    const {project} = this.props;
    const {isSubmitting, inputCoupon, submitError} = this.state;

    return (
      <div className="s-settings-subscription-coupon fade-in">
        <div className="settings-body">
          <div className="coupon-form-wrapper">
            <div className="title">Add a coupon</div>
            <form onSubmit={this.handleSubmitCoupon}>
              <Input
                placeholder="Enter a coupon code"
                value={inputCoupon}
                name="inputCoupon"
                onChange={this.handleInputChange}
                required></Input>
              {submitError != null && (
                <Alert danger>
                  <EmojiIdk width={22} inline></EmojiIdk>
                  {submitError === 'COUPON_ALREADY_ACTIVATED' ? (
                    'This coupon has already been used.'
                  ) : submitError === 'COUPON_NOT_FOUND' ? (
                    "This coupon doesn't exists."
                  ) : submitError === 'COUPON_MAX_PROJECTS_REACHED' ? (
                    'This coupon has reach a maximum of 5 projects'
                  ) : submitError === 'COUPON_DIFFERENT_ACCOUNT_USAGE' ? (
                    'This coupon has already been used by another account'
                  ) : submitError === 'SAAS_MANTRA_ALREADY_ACTIVATED' ? (
                    'This project is already under a Saas Mantra deal!'
                  ) : (
                    <>
                      We could not apply your coupon, please{' '}
                      <a href="mailto:sam@usejimo.com?subject=Issues with my coupon">
                        contact our team
                      </a>
                      .
                    </>
                  )}
                </Alert>
              )}
              <Button primary loading={isSubmitting}>
                Apply coupon
              </Button>
            </form>
          </div>
          <div className="coupon-list">
            {project.coupons.map((c) => (
              <Card className="item">
                <div className="title">
                  {c.type === COUPON_TYPE_SAAS_MANTRA_OFFER_1
                    ? 'SaaS Mantra coupon'
                    : 'Coupon'}{' '}
                  applied! 🎉
                </div>
                <p>
                  {c.type === COUPON_TYPE_SAAS_MANTRA_OFFER_1 ? (
                    <>
                      <ul>
                        <li>Unlimited active users</li>
                        <li>Unlimited seats</li>
                        <li>Collect Requests</li>
                        <li>Validate Ideas</li>
                        <li>Test designs</li>
                        <li>Announcement</li>
                        <li>Standalone changelog / Embeddable widget</li>
                      </ul>
                      <p>
                        You used the coupon{' '}
                        {c.code.slice(0, c.code.length - 6).concat('******')}.
                        You can still use this coupon {5 - c.projects.length}{' '}
                        times.
                      </p>
                      <img src={logoSaasMantra} alt="" width="200px" />
                    </>
                  ) : (
                    ''
                  )}
                </p>
              </Card>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  project: generalSelector.getProject(state),
});
const mapDispatchToProps = (dispatch) => ({
  uptProject: (data) => dispatch(generalActions.uptProject(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsSubscriptionCoupon);
