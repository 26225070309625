import Divider from 'components/Divider';
import TextArea from 'components/TextArea';
import {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {BLOCK_TYPE_OPEN_QUESTION} from 'services/steps';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import './_Styles.scss';

const OpenQuestion = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_OPEN_QUESTION, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_OPEN_QUESTION);

  if (block == null) {
    return <></>;
  }

  const {value, style, flags} = block;

  return (
    <div className="block-settings open-question">
      <div className="section">
        <div className="section-title">Content</div>
        <div className="section-content">
          <TextArea
            className="open-question-content-input"
            placeholder="Placeholder"
            value={value}
            onChange={(e) => updateBlock({value: e.target.value})}
          />
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">Style</div>
        <div className="section-content">
          <OpenQuestionStyle style={style} updateStyle={updateBlock} />
        </div>
      </div>
    </div>
  );
};

export const OpenQuestionStyle = ({style, updateStyle}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Background</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Background"
            value={style.primaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, primaryColor: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Radius</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.borderRadius}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderRadius: value},
              })
            }
            radius
          />
        </div>
      </div>
    </>
  );
};

export default OpenQuestion;
