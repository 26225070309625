import Button from 'components/Button';
import {node, string, bool} from 'prop-types';
import './_Styles.scss';
import classNames from 'classnames';
import {AudienceContext} from 'scenes/PokeAudience';
import {useContext, useEffect} from 'react';
import {usePrevious} from 'helpers/utils';
import AnimateHeight from 'react-animate-height';

const propTypes = {
  index: string.isRequired,
  title: string.isRequired,
  children: node.isRequired,
  className: string,
  expanded: bool,
  isLastSection: bool,
  icon: node,
  forceChecked: bool,
};

const defaultProps = {
  className: '',
  expanded: false,
  isLastSection: false,
  icon: null,
  forceChecked: false,
};

const AudienceSection = ({
  className,
  index,
  title,
  children,
  isLastSection,
  icon,
  forceChecked,
}) => {
  const {
    selectedSection,
    setSelectedSection,
    checkedSections,
    setCheckedSections,
    save,
    evolution,
  } = useContext(AudienceContext);

  const expanded = selectedSection === index;
  const isChecked = checkedSections.includes(index);

  const previousExpanded = usePrevious(expanded);

  useEffect(() => {
    if (previousExpanded === true && expanded === false) {
      setCheckedSections([...checkedSections, index]);
      if (evolution.isDraft === true) {
        save();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return (
    <div
      className={classNames('audience-section', className, {
        expanded,
      })}
      onClick={() => {
        if (expanded !== true) {
          setSelectedSection(index);
        }
      }}>
      <div className="audience-section-header">
        <div
          className="audience-section-expand"
          onClick={() => setSelectedSection(index)}>
          <i className="icon-chevron-bottom" />
        </div>
        {forceChecked !== true && (isChecked !== true || expanded === true) ? (
          <div className="audience-section-index">
            {icon != null ? icon : index}
          </div>
        ) : (
          <div className="audience-section-index checked">
            <i className="icon-tick" />
          </div>
        )}
        <div className="audience-section-title">{title}</div>
        {expanded === true && isLastSection !== true && (
          <Button
            className="done-btn"
            thin
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              setSelectedSection(selectedSection + 1);
            }}>
            Done
          </Button>
        )}
      </div>
      <AnimateHeight height={expanded === true ? 'auto' : 0} duration={300}>
        <div className="audience-section-content">{children}</div>
      </AnimateHeight>
    </div>
  );
};

AudienceSection.propTypes = propTypes;
AudienceSection.defaultProps = defaultProps;

export default AudienceSection;
