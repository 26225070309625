import SelectGroup from 'components/Select';
import React, {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import './_Styles.scss';

const SettingsGoToStep = ({action, setAction}) => {
  const {evolution, selectedStep} = useContext(BuilderContext);

  const steps = evolution.tourSteps
    .map((ts) => ts.steps)
    .flat()
    .filter((s) => s.removed !== true);

  const currentStepIndex = steps.findIndex((s) => s.uid === selectedStep.uid);
  const nextStep = steps[currentStepIndex + 1];

  const stepOptions = [
    ...steps.map((s) => ({
      label: s.name,
      value: s.uid,
    })),
    ...(nextStep == null
      ? [{label: 'Next step', value: 'next-step-placeholder'}]
      : []),
  ].filter((s) => s.value !== selectedStep.uid);

  return (
    <div className="settings-go-to-step">
      <div className="settings-go-to-step-title body-3">Choose step:</div>
      <SelectGroup
        isSearchable={false}
        options={stepOptions}
        value={stepOptions.find(
          (s) => s.value === (action.step?.uid || action.value)
        )}
        onChange={(selected) => {
          if (selected.value === 'next-step-placeholder') {
            setAction({...action, value: 'next-step-placeholder', step: null});
            return;
          }

          setAction({...action, step: {uid: selected.value}});
        }}
      />
    </div>
  );
};

export default SettingsGoToStep;
