import classnames from 'classnames';
import React, {useContext, useState} from 'react';
import {
  BLOCK_OPEN_QUESTION,
  getDefaultResponseForBlock,
} from '../../constants/blocks';
import {PokeContext, PokeStateContext} from '../../context';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import colorContrast from 'font-color-contrast';
import './_styles.scss';
import {hex2Rgb} from '../../utils/hex-2-rgb';

export const parseOpenQuestionValue = (value) => {
  const [placeholder] = value.split(';');

  return {
    placeholder,
  };
};

export const BlockOpenQuestion = () => {
  const {poke} = useContext(PokeContext);
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    response = getDefaultResponseForBlock(BLOCK_OPEN_QUESTION),
    updateResponse,
    language,
  } = useContext(PokeStateContext);

  const [focused, setFocused] = useState(false);

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_OPEN_QUESTION,
  });

  const block = currentStep.blocks.find((b) => b.type === BLOCK_OPEN_QUESTION);
  const {placeholder} = parseOpenQuestionValue(block.value);
  const {primaryColor, ...restStyle} = block.style || {};

  const {style} = poke;
  const {background} = style ?? {};
  const borderColor = colorContrast(
    background?.primaryColor != null
      ? background.primaryColor.length > 7
        ? background?.primaryColor?.slice(
            0,
            background?.primaryColor?.length - 2
          )
        : background?.primaryColor
      : '#FFFFFF'
  );

  const translation = block.translations?.find((t) => t.language === language);

  const handleResponse = (value) => {
    updateResponse(value);
  };

  return (
    <div
      className={classnames('poke-block-open-question', {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        selected: selectedBlock === BLOCK_OPEN_QUESTION,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_OPEN_QUESTION);
      }}>
      <textarea
        style={{
          ...restStyle,
          backgroundColor: primaryColor,
          borderColor: `rgba(${hex2Rgb(borderColor).join(',')},${
            focused === true ? '0.2' : '0.1'
          })`,
        }}
        placeholder={translation != null ? translation.value : placeholder}
        value={response}
        onChange={({target}) => handleResponse(target.value)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}></textarea>
      <ClickableBlockOverlay />
    </div>
  );
};
