import classNames from 'classnames';
import Avatar from 'components/Avatar';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import {errorHelpers} from 'helpers';
import {hasUsernameGenerated} from 'helpers/utils';
import {bool, func} from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import {BottomScrollListener} from 'react-bottom-scroll-listener';
import {trackerAnalyticsService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import RadioGroup from 'components/RadioGroup';
import Button from 'components/Button';
import dayjs from 'dayjs';
import {CircularProgressbar} from 'react-circular-progressbar';
import Tooltip from 'components/Tooltip';
import {JimerProfile} from 'scenes/Users/components/ModalJimerProfile';
import {
  TRACKER_TYPE_ACTIVATION_TRACKER,
  TRACKER_TYPE_FEATURE_USAGE,
  TRACKER_TYPE_GOAL,
} from 'services/tracker';

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func,
};

const defaultProps = {};

const JIMERS_PER_PAGE = 20;

const logger = new Swaler('TrackerListUsersDrawer');

const TrackerListUsersDrawer = ({
  isOpen,
  onRequestClose,
  tracker,
  selectedTrackerStep,
  startDate,
  endDate,
  ...rest
}) => {
  const isActivationTracker = tracker.type === TRACKER_TYPE_ACTIVATION_TRACKER;
  const isFeatureUsageTracker = tracker.type === TRACKER_TYPE_FEATURE_USAGE;
  const isGoalTracker = tracker.type === TRACKER_TYPE_GOAL;

  const [trackerStep, setTrackerStep] = useState(selectedTrackerStep);
  const [page, setPage] = useState(0);
  const [jimers, setJimers] = useState({
    data: [],
    skip: 0,
    take: JIMERS_PER_PAGE,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [jimerId, setJimerId] = useState(null);
  const [onlyUsersStuckAtStep, setOnlyUsersStuckAtStep] = useState(false);

  const cancelCtrl = useRef();

  const fetchJimers = async (pageParam = page) => {
    setIsLoading(true);

    const oldPage = page;
    setPage(pageParam);

    cancelCtrl.current = new AbortController();

    try {
      const res = await trackerAnalyticsService.getLastActivity(
        {
          trackerId: tracker.uid,
          stepId: trackerStep?.uid,
          take: JIMERS_PER_PAGE,
          skip: pageParam * JIMERS_PER_PAGE,
          startDate,
          endDate,
          onlyStuckAtStep: onlyUsersStuckAtStep,
        },
        {signal: cancelCtrl.current.signal}
      );

      setJimers((prev) => ({
        ...res,
        data: [...prev.data, ...res.data],
      }));
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }
      const {code} = errorHelpers.parseError(err);

      setPage(oldPage);
      logger.error('Fetching values failed with error ', code);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setJimers({
      data: [],
      skip: 0,
      take: JIMERS_PER_PAGE,
      total: 0,
    });
    fetchJimers(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackerStep, onlyUsersStuckAtStep]);

  const closeModal = () => {
    onRequestClose();
  };

  const handleExportUsers = async () => {
    const responses = await trackerAnalyticsService.getLastActivity({
      trackerId: tracker.uid,
      stepId: trackerStep?.uid,
      startDate,
      endDate,
      onlyStuckAtStep: onlyUsersStuckAtStep,
      exportCsv: true,
    });
    const fileName = `users-${tracker.name}.csv`;
    const data = new Blob([responses], {type: 'text/csv'});
    const jsonURL = window.URL.createObjectURL(data);
    const link = document.createElement('a');

    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
  };

  const steps = tracker?.steps || [];
  steps?.sort((a, b) => a.indexOrder - b.indexOrder);

  const isLastStep = steps?.[steps.length - 1]?.uid === trackerStep?.uid;

  const stepOptions = [
    {label: 'All users', value: null},
    ...steps?.map((step) => ({
      label: step.name,
      value: step.uid,
    })),
  ];

  let title = 'Users breakdown';

  if (startDate != null && endDate != null) {
    title = `Users breakdown (${dayjs(startDate).format('MMM D')} - ${dayjs(
      endDate
    ).format('MMM D')})`;
  }

  return (
    <Modal
      className="tracker-list-users-drawer fade-in-right"
      title={title}
      isOpen={isOpen}
      {...rest}
      onRequestClose={() => {
        closeModal();
      }}>
      {jimerId != null ? (
        <JimerProfile onBack={() => setJimerId(null)} jimerId={jimerId} />
      ) : (
        <div className="content-wrapper">
          {isActivationTracker === true && (
            <RadioGroup
              options={stepOptions}
              onSelect={(value) =>
                setTrackerStep(steps.find((s) => s.uid === value))
              }
              value={trackerStep?.uid}
            />
          )}
          {isLastStep === true || trackerStep == null ? null : (
            <div
              className="users-filter"
              onClick={() => setOnlyUsersStuckAtStep(!onlyUsersStuckAtStep)}>
              <i
                className={
                  onlyUsersStuckAtStep ? 'icon-checkbox-a' : 'icon-checkbox-o'
                }
              />
              <span>Only users stuck at this step</span>
              <Tooltip
                className="tooltip-stuck-users"
                contentStyle={{zIndex: 10000}}
                position="bottom right"
                arrow
                trigger={
                  <i
                    className="icon-info-circle-o"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                }>
                Select this option to only view users who are stuck in this
                step.
              </Tooltip>
            </div>
          )}
          <div className={classNames('poke-segment-users-wrapper', {})}>
            <div className="poke-segment-users-content">
              {isLoading === true && jimers.total === 0 ? (
                <div className="loader-wrapper">
                  <DefaultLoader />
                </div>
              ) : (
                <>
                  <div className="users-header">
                    <div className="count-users">
                      <i className="isax isax-profile-2user" />
                      {jimers?.total} user{jimers?.total > 1 ? 's' : ''}
                    </div>
                    <div className="users-actions">
                      <Button onClick={() => handleExportUsers()}>
                        Export as CSV
                      </Button>
                    </div>
                  </div>

                  <div className="users-list">
                    <div className="top">
                      <div></div>
                      <div>Name</div>
                      <div>Email / Id</div>
                      <div>
                        {isGoalTracker ? 'Reached goal' : 'Last activity'}
                      </div>
                    </div>
                    {isLoading === true && jimers.data.length <= 0 ? (
                      <div className="loader-wrapper">
                        <DefaultLoader width={24} />
                      </div>
                    ) : (
                      <BottomScrollListener
                        triggerOnNoScroll
                        offset={30}
                        onBottom={() => {
                          if (
                            jimers.data.length < jimers.total &&
                            isLoading !== true
                          ) {
                            fetchJimers(page + 1);
                          }
                        }}>
                        {(scrollRef) => (
                          <div className="list" ref={scrollRef}>
                            {jimers.data.map((jimer) => {
                              const username =
                                jimer.externalUsername != null &&
                                jimer.externalUsername.length !== 0
                                  ? jimer.externalUsername
                                  : jimer.username;
                              const email =
                                jimer.personalEmail != null
                                  ? jimer.personalEmail
                                  : jimer.externalEmail;
                              const isAnonymous =
                                hasUsernameGenerated(username);
                              const emailAndId = [email, jimer.externalUid]
                                .filter((v) => v != null && v.length !== 0)
                                .map((v) => <div>{v}</div>);

                              let lastRecordedEvent = null;
                              let completedAllSteps = false;

                              if (isActivationTracker === true) {
                                lastRecordedEvent = jimer.trackerEvents.sort(
                                  (a, b) => {
                                    return (
                                      new Date(b.createdAt) -
                                      new Date(a.createdAt)
                                    );
                                  }
                                )[0];

                                completedAllSteps =
                                  jimer.trackerEvents?.length === steps.length;
                              }

                              return (
                                <div
                                  key={jimer.uid}
                                  className={classNames('card-user', {
                                    completed: completedAllSteps,
                                  })}
                                  onClick={() => {
                                    setJimerId(jimer.uid);
                                  }}>
                                  <div className="user-avatar-wrapper">
                                    <Avatar jimer={jimer} />
                                  </div>
                                  <div className="username-wrapper">
                                    <div className="username">
                                      {isAnonymous ? (
                                        'Anonymous'
                                      ) : !!username ? (
                                        username
                                      ) : (
                                        <em>username</em>
                                      )}
                                    </div>
                                    {isAnonymous === true && (
                                      <div className="anonymous-name">
                                        {username}
                                      </div>
                                    )}
                                  </div>
                                  <div className="user-email">
                                    <span>
                                      {emailAndId?.length > 0
                                        ? emailAndId
                                        : 'no email'}
                                    </span>
                                  </div>

                                  <div
                                    className={classNames(
                                      'user-last-activity'
                                    )}>
                                    {isActivationTracker === true && (
                                      <>
                                        <div className="circular-progress-wrapper">
                                          <CircularProgressbar
                                            value={
                                              jimer?.trackerEvents?.length ??
                                              0 / steps.length ??
                                              1
                                            }
                                            text={<i className="icon-eye"></i>}
                                            maxValue={steps.length}
                                            strokeWidth={10}
                                            styles={{
                                              path: {
                                                stroke: completedAllSteps
                                                  ? '#167322'
                                                  : '#1260EB',
                                                transition:
                                                  'stroke-dashoffset 0.5s ease 0s',
                                              },
                                              trail: {
                                                stroke:
                                                  'rgba(33, 93, 137, 0.06)',
                                              },
                                            }}
                                          />
                                        </div>
                                        <div className="content">
                                          <div className="step-name">
                                            {completedAllSteps === true
                                              ? 'Completed all steps 🎉'
                                              : jimer?.trackerEvents?.[0]
                                                  .trackerStep?.name ??
                                                'Unknown step'}
                                          </div>
                                          <div className="event-date">
                                            {dayjs(
                                              lastRecordedEvent.createdAt
                                            ).fromNow()}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    {isFeatureUsageTracker === true && (
                                      <>
                                        <div className="icon-wrapper">
                                          <i className="isax isax-mouse-circle" />
                                        </div>
                                        <div className="content">
                                          <div className="step-name">
                                            Used {jimer.eventCount} time
                                            {jimer.eventCount > 1 ? 's' : ''}
                                          </div>
                                          <div className="event-date">
                                            {dayjs(
                                              jimer.latestEventCreatedAt
                                            ).fromNow()}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    {isGoalTracker === true && (
                                      <>
                                        <div className="content">
                                          <div className="event-date">
                                            {dayjs(
                                              jimer.latestEventCreatedAt
                                            ).fromNow()}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </BottomScrollListener>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

TrackerListUsersDrawer.propTypes = propTypes;
TrackerListUsersDrawer.defaultProps = defaultProps;

export default TrackerListUsersDrawer;
