import {default as classNames, default as classnames} from 'classnames';
import Divider from 'components/Divider';
import Select from 'components/Select';
import Toggle from 'components/Toggle';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {bool, func, object} from 'prop-types';
import {
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOP_BAR,
  F_OPTION_DISMISS_ON_OUTSIDE_CLICK,
  F_OPTION_DOT_SHOW_ON_HOVER,
  F_OPTION_PROGRESS_ON_TARGET_CLICK,
  F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
  F_OPTION_TOUR_STEP_SKIPPABLE,
  F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND,
} from 'services/evolution';
import './_styles.scss';

const propTypes = {
  evolution: object,
  setEvolution: func,
  isTourStep: bool,
};

const defaultProps = {
  evolution: null,
  setEvolution: () => {},
  isTourStep: false,
};

const dismissOptions = [
  {label: 'Click on the cross', value: 'cross'},
  {label: 'Click anywhere outside', value: 'outside'},
];

const showOptions = [
  {label: 'On click', value: 'click'},
  {label: 'On hover', value: 'hover'},
];

const progressOptions = [
  {label: 'Next button', value: 'next-button'},
  {label: 'Targeted element click', value: 'pointed-element'},
];

export const BehaviorOptions = ({evolution, setEvolution, isTourStep}) => {
  const previewDot = hasFlag(F_BOOST_SLOT_DOT, evolution.boostFlags);
  const previewTooltip = hasFlag(F_BOOST_SLOT_TOOLTIP, evolution.boostFlags);
  const previewTopBar = hasFlag(F_BOOST_SLOT_TOP_BAR, evolution.boostFlags);

  const handleToggleSkippable = (enable) => {
    if (enable) {
      setEvolution({
        ...evolution,
        optionsFlags: addFlag(
          F_OPTION_TOUR_STEP_SKIPPABLE,
          evolution.optionsFlags
        ),
      });
    } else {
      setEvolution({
        ...evolution,
        optionsFlags: removeFlag(
          F_OPTION_TOUR_STEP_SKIPPABLE,
          evolution.optionsFlags
        ),
      });
    }
  };

  const handleToggleSkipIfElementNotFound = (enable) => {
    if (enable) {
      setEvolution({
        ...evolution,
        optionsFlags: addFlag(
          F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND,
          evolution.optionsFlags
        ),
      });
    } else {
      setEvolution({
        ...evolution,
        optionsFlags: removeFlag(
          F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND,
          evolution.optionsFlags
        ),
      });
    }
  };

  const handleToggleTopBarNotPushingContent = (enable) => {
    if (enable) {
      setEvolution({
        ...evolution,
        optionsFlags: addFlag(
          F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
          evolution.optionsFlags
        ),
      });
    } else {
      setEvolution({
        ...evolution,
        optionsFlags: removeFlag(
          F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
          evolution.optionsFlags
        ),
      });
    }
  };

  return (
    <div className="behavior-options">
      <div className="opts-content">
        {previewTopBar === true && (
          <div
            className={classnames('top-bar-push-content', {
              'is-active':
                hasFlag(
                  F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
                  evolution.optionsFlags
                ) === false,
            })}
            onClick={() => {
              handleToggleTopBarNotPushingContent(
                !hasFlag(
                  F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
                  evolution.optionsFlags
                )
              );
            }}>
            <div className="description-wrapper">
              Move all element down
              <small>
                Automatically move down all fixed element to make sure top bar
                doesn't overlap them.
              </small>
            </div>
            <Toggle
              checked={
                hasFlag(
                  F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT,
                  evolution.optionsFlags
                ) === false
              }
              onChange={handleToggleTopBarNotPushingContent}
            />
          </div>
        )}
        {[previewDot].some((b) => b === true) && (
          <>
            <OptionItem label="Show">
              <Select
                options={showOptions}
                value={
                  hasFlag(F_OPTION_DOT_SHOW_ON_HOVER, evolution.optionsFlags)
                    ? showOptions.find((o) => o.value === 'hover')
                    : showOptions.find((o) => o.value === 'click')
                }
                onChange={(option) => {
                  if (option.value === 'click') {
                    setEvolution({
                      ...evolution,
                      optionsFlags: removeFlag(
                        F_OPTION_DOT_SHOW_ON_HOVER,
                        evolution.optionsFlags
                      ),
                    });
                  } else {
                    setEvolution({
                      ...evolution,
                      optionsFlags: addFlag(
                        F_OPTION_DOT_SHOW_ON_HOVER,
                        evolution.optionsFlags
                      ),
                    });
                  }
                }}
              />
            </OptionItem>
            <Divider />
          </>
        )}
        {[previewDot, previewTooltip].some((b) => b === true) && (
          <>
            <OptionItem label="Dismiss">
              <Select
                className="dismiss-option-select"
                options={dismissOptions}
                value={
                  hasFlag(
                    F_OPTION_DISMISS_ON_OUTSIDE_CLICK,
                    evolution.optionsFlags
                  )
                    ? dismissOptions.find((o) => o.value === 'outside')
                    : dismissOptions.find((o) => o.value === 'cross')
                }
                onChange={(option) => {
                  if (option.value === 'cross') {
                    setEvolution({
                      ...evolution,
                      optionsFlags: removeFlag(
                        F_OPTION_DISMISS_ON_OUTSIDE_CLICK,
                        evolution.optionsFlags
                      ),
                    });
                  } else {
                    setEvolution({
                      ...evolution,
                      optionsFlags: addFlag(
                        F_OPTION_DISMISS_ON_OUTSIDE_CLICK,
                        evolution.optionsFlags
                      ),
                    });
                  }
                }}
              />
            </OptionItem>
            <Divider />
          </>
        )}
        {isTourStep && (previewTooltip || previewDot) && (
          <>
            {previewTooltip && (
              <OptionItem label="Progression on click of">
                <Select
                  className="progress-option-select"
                  options={progressOptions}
                  value={
                    hasFlag(
                      F_OPTION_PROGRESS_ON_TARGET_CLICK,
                      evolution.optionsFlags
                    )
                      ? progressOptions.find(
                          (o) => o.value === 'pointed-element'
                        )
                      : progressOptions.find((o) => o.value === 'next-button')
                  }
                  onChange={(option) => {
                    if (option.value === 'next-button') {
                      setEvolution({
                        ...evolution,
                        optionsFlags: removeFlag(
                          F_OPTION_PROGRESS_ON_TARGET_CLICK,
                          evolution.optionsFlags
                        ),
                      });
                    } else {
                      setEvolution({
                        ...evolution,
                        optionsFlags: addFlag(
                          F_OPTION_PROGRESS_ON_TARGET_CLICK,
                          evolution.optionsFlags
                        ),
                      });
                    }
                  }}
                />
              </OptionItem>
            )}
            {[previewDot, previewTooltip].some((b) => b === true) && (
              <>
                <Divider />
                <OptionItem label="Options">
                  <div className="options-group">
                    {/* <div
                  className={classnames('skippable-step', {
                    'is-active': hasFlag(
                      F_OPTION_TOUR_STEP_SKIPPABLE,
                      evolution.optionsFlags
                    ),
                  })}
                  onClick={() => {
                    handleToggleSkippable(
                      !hasFlag(
                        F_OPTION_TOUR_STEP_SKIPPABLE,
                        evolution.optionsFlags
                      )
                    );
                  }}>
                  <div className="description-wrapper">
                    Skippable
                    <small>The step can be skipped by the user</small>
                  </div>
                  <Toggle
                    checked={hasFlag(
                      F_OPTION_TOUR_STEP_SKIPPABLE,
                      evolution.optionsFlags
                    )}
                    onChange={handleToggleSkippable}
                  />
                </div> */}
                    <div
                      className={classnames('skip-step-element-not-found', {
                        'is-active': hasFlag(
                          F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND,
                          evolution.optionsFlags
                        ),
                      })}
                      onClick={() => {
                        handleToggleSkipIfElementNotFound(
                          !hasFlag(
                            F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND,
                            evolution.optionsFlags
                          )
                        );
                      }}>
                      <div className="description-wrapper">
                        Skip step if element not found
                        <small>
                          If the targeted element is not found on the page, this
                          step will be skipped and the next one will be shown.
                        </small>
                      </div>
                      <Toggle
                        checked={hasFlag(
                          F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND,
                          evolution.optionsFlags
                        )}
                        onChange={handleToggleSkipIfElementNotFound}
                      />
                    </div>
                  </div>
                </OptionItem>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

BehaviorOptions.propTypes = propTypes;
BehaviorOptions.defaultProps = defaultProps;

const OptionItem = ({children, label, className}) => {
  return (
    <div className={classNames('opt-item', className)}>
      <div className="opt-label">{label}</div>
      <div className="opt-inputs">{children}</div>
    </div>
  );
};
