import {addFlag, removeFlag} from 'helpers/bitwise';
import React, {useContext, useState} from 'react';
import {useQuery} from 'react-query';
import {InteractiveOption} from 'scenes/Builder/component/InteractiveOption';
import {AudienceContext} from 'scenes/PokeAudience';
import SharableLinkModal from 'components/SharableLinkModal';
import AudienceSection from 'scenes/PokeAudience/components/AudienceSection';
import {segmentioService} from 'services';
import {EVENT_SOURCE_SEGMENT} from 'services/event';
import {
  F_OPTION_IGNORE_RATE_LIMITING,
  TRIGGER_TYPE_DEFAULT,
  TRIGGER_TYPE_EVENT,
  TRIGGER_TYPE_LEAVE_PAGE,
  TRIGGER_TYPE_MANUAL,
  TRIGGER_TYPE_NONE,
  TRIGGER_TYPE_URL,
} from 'services/evolution';
import './_Styles.scss';
import EventModal from './components/EventModal';
import ManualTriggerModal from './components/ManualTriggerModal';
import PageOpeningModal from './components/PageOpeningModal';
import SegmentioEventModal from './components/SegmentioEventModal';

export const getTriggerTitle = (evolution) => {
  const triggerMode = evolution?.triggerType || TRIGGER_TYPE_DEFAULT;

  if (triggerMode === TRIGGER_TYPE_DEFAULT) {
    return `On page opening${
      ', after ' + (evolution.boostedDelay || 0) / 1000 + ' seconds'
    }`;
  } else if (triggerMode === TRIGGER_TYPE_LEAVE_PAGE) {
    return 'On page leaving';
  } else if (triggerMode === TRIGGER_TYPE_EVENT) {
    if (evolution?.event != null) {
      return `On event: ${evolution.event.name || 'Unnamed event'}`;
    } else if (evolution?.onTheFlyEvent != null) {
      return 'On event: on the fly event';
    }
  } else if (triggerMode === TRIGGER_TYPE_MANUAL) {
    return 'On SDK call';
  } else if (triggerMode === TRIGGER_TYPE_URL) {
    return 'On shared URL';
  } else if (triggerMode === TRIGGER_TYPE_NONE) {
    return 'On other experiences';
  }
};

const propTypes = {};

const defaultProps = {};

const Trigger = ({index}) => {
  const {evolution, setEvolution} = useContext(AudienceContext);

  const [manualTriggerModalOpen, setManualTriggerModalOpen] = useState(false);
  const [pageOpeningModalOpen, setPageOpeningModalOpen] = useState(false);
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [segmentioEventModalOpen, setSegmentioEventModalOpen] = useState(false);
  const [sharableLinkModalOpen, setSharableLinkModalOpen] = useState(false);

  const triggerMode = evolution?.triggerType || TRIGGER_TYPE_DEFAULT;

  const isSegmentioEvent = evolution?.event?.source === EVENT_SOURCE_SEGMENT;

  const title = getTriggerTitle(evolution);

  const {data: segmentioEvents} = useQuery({
    queryKey: ['segmentioEvents'],
    queryFn: () => segmentioService.getSegmentioEvents(),
    initialData: [],
  });

  return (
    <AudienceSection
      className="experience-trigger"
      index={index}
      title={!!title ? `Trigger: ${title}` : 'Trigger'}>
      <InteractiveOption
        withRadioBox
        active={triggerMode === TRIGGER_TYPE_DEFAULT}
        iconComponent={
          <>
            <i className="icon default-icon isax isax-eye" />
            <i className="icon active-hovered-icon isax isax-setting-2" />
          </>
        }
        title={`On page opening${
          ', after ' + (evolution.boostedDelay || 0) / 1000 + ' seconds'
        }`}
        description="Everytime the user arrive on selected pages"
        onClick={() => {
          setEvolution({
            ...evolution,
            triggerType: TRIGGER_TYPE_DEFAULT,
            optionsFlags: removeFlag(
              F_OPTION_IGNORE_RATE_LIMITING,
              evolution.optionsFlags
            ),
          });
          setPageOpeningModalOpen(true);
        }}
      />
      <InteractiveOption
        withRadioBox
        active={triggerMode === TRIGGER_TYPE_LEAVE_PAGE}
        icon="isax isax-logout"
        title="On page leaving"
        description="When the user is about to leave the page"
        onClick={() =>
          setEvolution({
            ...evolution,
            triggerType: TRIGGER_TYPE_LEAVE_PAGE,
            optionsFlags: removeFlag(
              F_OPTION_IGNORE_RATE_LIMITING,
              evolution.optionsFlags
            ),
          })
        }
      />
      <InteractiveOption
        withRadioBox
        active={triggerMode === TRIGGER_TYPE_EVENT}
        iconComponent={
          <>
            <i className="icon default-icon isax isax-mouse-circle" />
            <i className="icon active-hovered-icon isax isax-setting-2" />
          </>
        }
        title={`On event${
          triggerMode === TRIGGER_TYPE_EVENT
            ? evolution.event != null
              ? `: ${evolution.event.name || 'Unnamed event'}`
              : evolution.onTheFlyEvent != null
                ? `: on the fly event`
                : ''
            : ''
        }`}
        description={
          <>
            When the user triggers selected event{' '}
            {segmentioEvents.length > 0 && (
              <>
                (includes{' '}
                <span className="body-4 label-segmentio">segment.io</span>)
              </>
            )}
          </>
        }
        onClick={() => {
          setEventModalOpen(true);
        }}
      />
      <InteractiveOption
        withRadioBox
        active={triggerMode === TRIGGER_TYPE_MANUAL}
        iconComponent={
          <>
            <i className="icon default-icon isax isax-document-code" />
            <i className="icon active-hovered-icon isax isax-setting-2" />
          </>
        }
        title="On manual trigger"
        description="Manually trigger the poke using our web SDK"
        onClick={() => {
          setEvolution({
            ...evolution,
            triggerType: TRIGGER_TYPE_MANUAL,
            ...(evolution.triggerType !== TRIGGER_TYPE_MANUAL
              ? {
                  optionsFlags: addFlag(
                    F_OPTION_IGNORE_RATE_LIMITING,
                    evolution.optionsFlags
                  ),
                }
              : {}),
          });
          setManualTriggerModalOpen(true);
        }}
      />
      <InteractiveOption
        withRadioBox
        active={triggerMode === TRIGGER_TYPE_URL}
        iconComponent={
          <>
            <i className="icon default-icon isax isax-link-2" />
            <i className="icon active-hovered-icon isax isax-setting-2" />
          </>
        }
        title="On shared URL"
        description="Share a link to trigger the experience"
        onClick={() => {
          setEvolution({
            ...evolution,
            triggerType: TRIGGER_TYPE_URL,
            ...(evolution.triggerType !== TRIGGER_TYPE_URL
              ? {
                  optionsFlags: addFlag(
                    F_OPTION_IGNORE_RATE_LIMITING,
                    evolution.optionsFlags
                  ),
                }
              : {}),
          });
          setSharableLinkModalOpen(true);
        }}
      />
      <InteractiveOption
        withRadioBox
        active={triggerMode === TRIGGER_TYPE_NONE}
        icon="isax isax-close-circle"
        title="No trigger"
        description="Only can start on other experiences actions e.g. buttons"
        onClick={() => {
          setEvolution({
            ...evolution,
            triggerType: TRIGGER_TYPE_NONE,
            optionsFlags: addFlag(
              F_OPTION_IGNORE_RATE_LIMITING,
              evolution.optionsFlags
            ),
          });
        }}
      />
      {/* {segmentioEvents.length > 0 && (
        <InteractiveOption
          className="trigger-segmentio-track-event"
          withRadioBox
          active={
            triggerMode === TRIGGER_TYPE_EVENT && isSegmentioEvent === true
          }
          iconComponent={
            <>
              <img
                src={SegmentioIcon}
                width={20}
                height={20}
                alt="segment-icon"
              />
              <i className="icon active-hovered-icon isax isax-setting-2" />
            </>
          }
          title={
            <>
              On track event{' '}
              <span className="body-4 label-segmentio">segment.io</span>
            </>
          }
          description="When a track event is triggered in segment.io"
          onClick={() => {
            setEvolution({
              ...evolution,
              triggerType: TRIGGER_TYPE_EVENT,
              ...(evolution.triggerType !== TRIGGER_TYPE_URL
                ? {
                    optionsFlags: addFlag(
                      F_OPTION_IGNORE_RATE_LIMITING,
                      evolution.optionsFlags
                    ),
                  }
                : {}),
            });
            setSegmentioEventModalOpen(true);
          }}
        />
      )}{' '}
      */}
      <PageOpeningModal
        isOpen={pageOpeningModalOpen}
        onRequestClose={() => setPageOpeningModalOpen(false)}
      />
      {eventModalOpen && (
        <EventModal
          isOpen={eventModalOpen}
          onRequestClose={() => setEventModalOpen(false)}
        />
      )}
      {segmentioEventModalOpen && (
        <SegmentioEventModal
          isOpen={true}
          onRequestClose={() => setSegmentioEventModalOpen(false)}
        />
      )}
      <ManualTriggerModal
        isOpen={manualTriggerModalOpen}
        onRequestClose={() => setManualTriggerModalOpen(false)}
      />
      <SharableLinkModal
        isOpen={sharableLinkModalOpen}
        onRequestClose={() => setSharableLinkModalOpen(false)}
        evolution={evolution}
      />
    </AudienceSection>
  );
};

Trigger.propTypes = propTypes;
Trigger.defaultProps = defaultProps;

export default Trigger;
