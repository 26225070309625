import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import {Menu, MenuItem} from 'components/Menu';
import {toastWarning} from 'components/Toaster';
import {addFlag, hasFlag, hasFlags, removeFlag} from 'helpers/bitwise';
import {removeTrailingSlash} from 'helpers/evolution';
import {getStepIssue} from 'helpers/step';
import {isActiveOnDomain, isActiveOnPath} from 'helpers/utils';
import React, {useContext, useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {useSelector} from 'react-redux';
import {getCustomizationFromTheme} from 'router/Layouts';
import {isPokeValid} from 'scenes/Builder/component/BuilderHeader/utils';
import {getTypeFromBoostFlags} from 'scenes/Builder/component/CtaModal';
import {BuilderContext} from 'scenes/Builder/context';
import {
  ACTIVE_OPERATOR_CUSTOM,
  ACTIVE_OPERATOR_EVERYWHERE,
  ACTIVE_OPERATOR_SINGLE_URL,
} from 'scenes/EmbeddedElementSelectorBuilder';
import {getTypeNameAndIcon} from 'scenes/Poke/component/Overview';
import {getBoostFlags} from 'scenes/Pushes/components/ModalCreatePoke';
import {getIcon} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal';
import {
  TYPE_BANNER,
  TYPE_HINT,
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_NAVIGATION,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {defaultHotspotStyle} from 'scenes/Settings/scenes/Themes/components/ThemeEditor';
import {generalSelector} from 'selectors';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  F_BOOST_SLOT_NAVIGATION,
  F_BOOST_SLOT_TOP_BAR,
  F_OPTION_DISMISS_ON_CROSS_CLICK,
  F_OPTION_DISMISS_ON_OUTSIDE_HOVER,
  F_OPTION_DOT_SHOW_ON_HOVER,
  F_OPTION_POKE_CARD_WITH_POINTER,
  getDefaultEvolution,
  getDefaultOptionsFlags,
} from 'services/evolution';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_HINT,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_STEPPER,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
  F_STEP_IS_SELECTING_PRESET,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  getDefaultStep,
  getDefaultStepStyle,
} from 'services/steps';
import {
  F_SLOT_DOT,
  F_SLOT_HINT,
  F_SLOT_TOOLTIP,
} from 'shared/front/components/Poke/constants/poke';
import {v4 as uuidv4} from 'uuid';
import isURL from 'validator/lib/isURL';
import {
  HINT_TYPE_HIDDEN,
  HINT_TYPE_ICON,
  HINT_TYPE_LABEL,
} from '../BlockEditor/blocks/Hint';
import {getDefaultBlockFromType} from '../BlockManager/utils';
import RemoveStepModal from '../RemoveStepModal';
import StepPreview from '../StepPreview';
import './_Styles.scss';

const stepItems = [
  {
    value: TYPE_HOTSPOT,
    icon: (
      <div className="icon-wrapper">
        <i className="icon-slot-dot" />
      </div>
    ),
    title: 'Hotspot',
    description: 'Target a specific element of your page',
  },
  {
    value: TYPE_TOOLTIP,
    icon: (
      <div className="icon-wrapper">
        <i className="icon-slot-tooltip" />
      </div>
    ),
    title: 'Tooltip',
    description: `Keep your user's attention on an element`,
  },
  {
    value: TYPE_MODAL,
    icon: (
      <div className="icon-wrapper">
        <i className="icon-slot-pop-in" />
      </div>
    ),
    title: 'Pop-in',
    description: 'Show the full post in a centered pop-in',
  },
  {
    value: TYPE_SNIPPET,
    icon: (
      <div className="icon-wrapper">
        <i className="icon-slot-snippet" />
      </div>
    ),
    title: 'Snippet',
    description: 'Keep it contextual and discreet',
  },
];

const hintItems = [
  {
    value: TYPE_HINT,
    icon: (
      <div className="icon-wrapper">
        <i className="icon-question-circle" />
      </div>
    ),
    title: 'Normal Icon',
    description: 'Standard icon tooltip for basic guidance',
    hintType: HINT_TYPE_ICON,
  },
  {
    value: TYPE_HINT,
    icon: (
      <div className="icon-wrapper">
        <i className="icon-label-icon" />
      </div>
    ),
    title: 'Label',
    description: 'Text label tooltip for clear and concise guidance',
    hintType: HINT_TYPE_LABEL,
  },
  {
    value: TYPE_HINT,
    icon: (
      <div className="icon-wrapper">
        <i className="icon-target" />
      </div>
    ),
    title: 'Target Element',
    description: `Hint highlighting an already existing element`,
    hintType: HINT_TYPE_HIDDEN,
  },
];

export const isUrlMatchingNavigation = (evolution, url) => {
  const isUrl = isURL(url || '', {
    require_tld: false, // to allow localhost
  });
  if (evolution == null || isUrl !== true) {
    return false;
  }
  let pathname = null;
  let host = null;
  try {
    const urlObject = new URL(url);
    host = urlObject.host;
    pathname = urlObject.href.replace(urlObject.origin, '');
  } catch (e) {
    return false;
  }

  const activeOnDomain =
    evolution.boostedDomainFilter != null
      ? isActiveOnDomain(evolution.boostedDomainFilter, host)
      : {isActive: true};
  const isActOnDomain = activeOnDomain.isActive;
  if (
    evolution.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM &&
    isActiveOnPath(evolution.pathOperator, evolution.boostedPaths, pathname) ===
      true &&
    isActOnDomain === true
  ) {
    return true;
  }
  if (
    evolution.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL &&
    evolution.boostedActiveUrl === url
  ) {
    return true;
  }
  if (evolution.boostedActiveOperator === ACTIVE_OPERATOR_EVERYWHERE) {
    return true;
  }
  return false;
};

const propTypes = {};

const defaultProps = {};

const AdoptionStepsManager = () => {
  const {
    evolution,
    setEvolution,
    selectedStepId,
    setSelectedStepId,
    selectedTourStepId,
    setSelectedTourStepId,
    messenger,
    isInApp,
    setInAppForegroundForce,
    currentUrl,
    setSelectedBlockType,
    isBoosted,
    originalEvolution,
  } = useContext(BuilderContext);

  const project = useSelector((state) => generalSelector.getProject(state));
  const builder = useSelector((state) => state.builder);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [stepIdToDelete, setStepIdToDelete] = useState(null);
  const [draggedTourStepId, setDraggedTourStepId] = useState(null);
  const [draggedStepId, setDraggedStepId] = useState(null);

  const isBanner = evolution?.type === EVOLUTION_TYPE_BANNER;

  useEffect(() => {
    if (
      selectedStepId == null &&
      builder.tourStepId == null &&
      builder.stepId == null
    ) {
      const tourSteps = evolution?.tourSteps || [];
      tourSteps.forEach((s) => {
        const [tourIndexOrder] = (s.tourStepInfo || '0;0;0').split(';');
        s.tourIndexOrder = tourIndexOrder;
      });
      tourSteps.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);
      setSelectedStepId(tourSteps[0]?.steps[0]?.uid);
    }
  }, []);

  // For in-app builder, we need to bring it to the foreground to click on the confirm delete modale
  useEffect(() => {
    if (isInApp === false) {
      return;
    }
    setInAppForegroundForce(stepIdToDelete != null);
  }, [stepIdToDelete]);
  // For in-app builder, we need to bring it to the foreground to prevent dropdown to be under poke
  useEffect(() => {
    if (isInApp === false) {
      return;
    }
    setInAppForegroundForce(dropdownOpen);
  }, [dropdownOpen]);

  const navigateTo = (url) => {
    if (url != null) {
      messenger?.sendNavigateTo({url});
    } else {
      messenger?.sendSwitchToNavigatorMode();
    }
  };

  const handleSelectStep = (stepId, tourStepId = null) => {
    const tourSteps = evolution.tourSteps || [];
    tourSteps.forEach((s) => {
      const [tourIndexOrder] = (s.tourStepInfo || '0;0;0').split(';');
      s.tourIndexOrder = tourIndexOrder;
    });
    tourSteps.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);
    const tourStep =
      stepId != null
        ? evolution.tourSteps?.find((s) =>
            s.steps.some((s) => s.uid === stepId)
          )
        : evolution.tourSteps?.find((s) => s.uid === tourStepId);
    const index = evolution.tourSteps?.map((s) => s.uid).indexOf(tourStep?.uid);
    const stepsBefore = evolution.tourSteps?.slice(0, index + 1).reverse();
    const lastNavigationStep =
      stepsBefore.find((s) => hasFlag(F_BOOST_SLOT_NAVIGATION, s.boostFlags)) ||
      evolution;

    const isUrlMatching = isUrlMatchingNavigation(
      lastNavigationStep,
      currentUrl
    );

    // if there is no navigation step before, navigate to boostedActiveUrl anyway if different from currentUrl
    if (
      lastNavigationStep === evolution &&
      removeTrailingSlash(currentUrl || '') !==
        removeTrailingSlash(evolution.boostedActiveUrl || '')
    ) {
      // navigateTo(evolution.boostedActiveUrl);
    } else if (isUrlMatching !== true) {
      // navigateTo(lastNavigationStep.boostedActiveUrl);
    }

    setSelectedStepId(stepId);
    setSelectedTourStepId(tourStepId);
    setSelectedBlockType(null);
    setDropdownOpen(false);
  };

  const handleTypeClick = ({
    type,
    addToCurrentTourStep = false,
    hintType = null,
  }) => {
    const profileBlockInFirstStep =
      evolution.tourSteps?.[0]?.steps?.[0]?.blocks?.find(
        (b) => b.type === BLOCK_TYPE_USER
      );
    const stepperBlockInExistingSteps = evolution.tourSteps
      ?.map((ts) => ts.steps)
      ?.flat()
      ?.map((s) => s.blocks)
      ?.flat()
      ?.find((b) => b.type === BLOCK_TYPE_STEPPER);
    const isHint = evolution?.type === EVOLUTION_TYPE_HINT;

    let newTourStep;
    const newStep = getDefaultStep({
      name: isHint ? 'Tooltip' : 'New Step',
      blocks:
        isHint === true
          ? [
              {
                ...getDefaultBlockFromType(BLOCK_TYPE_BODY, evolution.theme),
                value: 'Your text here',
                rawValue: [
                  {
                    object: 'block',
                    type: 'paragraph',
                    children: [
                      {
                        object: 'text',
                        text: 'Your text here',
                      },
                    ],
                    data: {},
                  },
                ],
              },
              {
                ...getDefaultBlockFromType(BLOCK_TYPE_HINT, evolution.theme),
                ...(hintType === HINT_TYPE_HIDDEN
                  ? {
                      style: {
                        ...getDefaultBlockFromType(
                          BLOCK_TYPE_HINT,
                          evolution.theme
                        ).style,
                        type: HINT_TYPE_HIDDEN,
                      },
                    }
                  : hintType === HINT_TYPE_LABEL
                  ? {
                      style: {
                        ...getDefaultBlockFromType(
                          BLOCK_TYPE_HINT,
                          evolution.theme
                        ).style,
                        type: HINT_TYPE_LABEL,
                      },
                    }
                  : {}),
              },
            ]
          : [
              getDefaultBlockFromType(BLOCK_TYPE_TITLE, evolution.theme),
              ...(type !== TYPE_BANNER
                ? [getDefaultBlockFromType(BLOCK_TYPE_BODY, evolution.theme)]
                : []),
              getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
              ...(profileBlockInFirstStep != null
                ? [
                    {
                      ...profileBlockInFirstStep,
                      uid: uuidv4(),
                    },
                  ]
                : []),
              ...(stepperBlockInExistingSteps != null
                ? [{...stepperBlockInExistingSteps, uid: uuidv4()}]
                : []),
            ],
    });
    newStep.stepFlags = addFlag(F_STEP_IS_SELECTING_PRESET, newStep.stepFlags);
    const selectedTourStep = evolution.tourSteps?.find((s) =>
      s.steps.some((s) => s.uid === selectedStepId)
    );
    let tourSteps;
    if (addToCurrentTourStep === true) {
      tourSteps = evolution.tourSteps?.map((s) => {
        if (s.uid === selectedTourStep.uid) {
          return {
            ...s,
            steps: [
              ...s.steps,
              {
                ...newStep,
                indexOrder: s.steps.length,
              },
            ],
          };
        }
        return s;
      });
    } else {
      const themeStepStyle =
        evolution.theme?.style?.stepStyle != null
          ? JSON.parse(JSON.stringify(evolution.theme?.style?.stepStyle))
          : null;
      delete themeStepStyle?.width;
      delete themeStepStyle?.height;

      const hotspotStyle = evolution.theme?.style?.blocksStyle?.['HOTSPOT'];

      let optionsFlags = getDefaultOptionsFlags();

      if (isHint === true) {
        optionsFlags = removeFlag(
          F_OPTION_DISMISS_ON_CROSS_CLICK,
          optionsFlags
        );
        optionsFlags = addFlag(F_OPTION_DISMISS_ON_OUTSIDE_HOVER, optionsFlags);
        optionsFlags = addFlag(F_OPTION_DOT_SHOW_ON_HOVER, optionsFlags);
        optionsFlags = addFlag(F_OPTION_POKE_CARD_WITH_POINTER, optionsFlags);
      }

      newTourStep = getDefaultEvolution({
        uid: uuidv4(),
        boostFlags: getBoostFlags(type),
        ...getCustomizationFromTheme(type, project.theme),
        ...(type === TYPE_NAVIGATION
          ? {
              boostedActiveOperator: ACTIVE_OPERATOR_SINGLE_URL,
            }
          : {}),
        ...(type === TYPE_TOOLTIP ? {boostedPositionFlags: 0} : {}),
        ...(type === TYPE_HINT ? {boostedPositionFlags: 1024} : {}),
        style:
          themeStepStyle ||
          getDefaultStepStyle({
            ...([TYPE_MODAL, TYPE_TOOLTIP].includes(type)
              ? {overlay: '#0000001a'} // 10% opacity overlay
              : {}),
          }),
        steps: type !== TYPE_NAVIGATION ? [newStep] : [],
        boostedDotStyle: hotspotStyle?.style || defaultHotspotStyle,
        optionsFlags: optionsFlags,
      });
      let afterStepIndex = evolution.tourSteps
        ?.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder)
        .map((s) => s.uid)
        .indexOf(selectedTourStep?.uid);
      afterStepIndex =
        afterStepIndex === -1 ? evolution.tourSteps?.length : afterStepIndex;
      tourSteps = [
        ...(evolution.tourSteps?.slice(0, afterStepIndex + 1) || []),
        newTourStep,
        ...(evolution.tourSteps?.slice(afterStepIndex + 1) || []),
      ];
    }

    tourSteps.forEach((step, index) => {
      step.tourStepInfo = [index].join(';');
    });

    const previousStep = selectedTourStep?.steps?.find(
      (s) => s.uid === selectedStepId
    );

    setEvolution({
      ...evolution,
      tourSteps: tourSteps.map((ts) => {
        if (ts.steps.some((s) => s.uid === previousStep?.uid)) {
          return {
            ...ts,
            steps: ts.steps.map((s) => {
              if (s.uid === previousStep?.uid) {
                return {
                  ...s,
                  triggers: previousStep?.triggers?.map((t) => {
                    return {
                      ...t,
                      actions: t.actions.map((a) => {
                        if (
                          a.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP &&
                          a.value === 'next-step-placeholder'
                        ) {
                          return {
                            ...a,
                            step: {uid: newStep.uid},
                            value: null,
                          };
                        }
                        return a;
                      }),
                    };
                  }),
                };
              }
              return s;
            }),
          };
        }
        return ts;
      }),
    });

    setDropdownOpen(false);
    messenger?.sendInAppForegroundForce(false);
    if (type === TYPE_NAVIGATION) {
      setSelectedStepId(null);
      setSelectedTourStepId(newTourStep.uid);
      if (isInApp) {
        messenger.sendSwitchToNavigatorMode();
      }
    } else {
      setSelectedTourStepId(null);
      setSelectedStepId(newStep.uid);
    }
    setSelectedBlockType(null);
  };

  const isStepLive =
    evolution.isDraft !== true &&
    originalEvolution.tourSteps?.some((s) =>
      s.steps.some((s) => s.uid === stepIdToDelete)
    );

  const handleDeleteStep = (stepId) => {
    const stepIds = evolution.tourSteps
      .map((t) => t.steps)
      .flat()
      .filter((s) => s)
      .map((s) => s.uid);

    if (isStepLive === true) {
      setEvolution({
        ...evolution,
        tourSteps: evolution.tourSteps.map((t) => {
          t.steps = t.steps.map((s) => {
            if (s.uid === stepId) {
              return {
                ...s,
                removed: true,
              };
            }
            return s;
          });
          return t;
        }),
      });
    } else {
      if (stepId === selectedStepId) {
        const index = stepIds.indexOf(stepId);
        setSelectedStepId(stepIds[index > 0 ? index - 1 : index + 1]);
        setSelectedBlockType(null);
      } else if (stepId === selectedTourStepId) {
        setSelectedStepId(stepIds[0]);
        setSelectedBlockType(null);
      }

      setEvolution({
        ...evolution,
        tourSteps: evolution.tourSteps
          .map((t) => {
            t.steps = t.steps.filter((s) => s.uid !== stepId);
            return t;
          })
          .filter(
            (t) =>
              t.steps.length > 0 ||
              (hasFlag(F_BOOST_SLOT_NAVIGATION, t.boostFlags) &&
                t.uid !== stepId)
          ),
      });
    }

    setStepIdToDelete(null);
  };

  const unhideStep = (stepId) => {
    setEvolution({
      ...evolution,
      tourSteps: evolution.tourSteps.map((t) => {
        t.steps = t.steps.map((s) => {
          if (s.uid === stepId) {
            return {
              ...s,
              removed: false,
            };
          }
          return s;
        });
        return t;
      }),
    });
  };

  const onDragStart = ({draggableId, type}) => {
    if (type === 'tour-steps') {
      setDraggedTourStepId(draggableId);
    } else if (type === 'steps') {
      setDraggedStepId(draggableId);
    }
  };
  const onDragEnd = async (result) => {
    setDraggedStepId(null);
    setDraggedTourStepId(null);

    if (!result.destination) {
      return;
    }
    const {
      type,
      source: {index: sourceIndex, droppableId: sourceDroppableId},
      destination: {index: destinationIndex},
    } = result;

    if (sourceIndex === destinationIndex) {
      return;
    }

    if (type === 'tour-steps') {
      const navigationGroups = evolution.tourSteps?.reduce(
        (acc, cur, index) => {
          const isNavigationStep = hasFlag(
            F_BOOST_SLOT_NAVIGATION,
            cur.boostFlags
          );
          if (index === 0 || isNavigationStep) {
            acc.push([cur]);
          } else {
            acc[acc.length - 1].push(cur);
          }

          return acc;
        },
        []
      );

      const selectedTourStep = evolution.tourSteps[sourceIndex];

      if (
        destinationIndex === 0 &&
        hasFlag(
          F_BOOST_SLOT_NAVIGATION,
          navigationGroups[sourceDroppableId][0]?.boostFlags
        )
      ) {
        toastWarning('Cannot move tour step before navigation step.');
        return;
      }

      const offsetIndex = navigationGroups.reduce((acc, cur, index) => {
        if (index < sourceDroppableId) {
          acc += cur.length;
        }
        return acc;
      }, 0);

      const reorderedSteps = Array.from(evolution.tourSteps);
      const [removed] = reorderedSteps.splice(sourceIndex + offsetIndex, 1);
      reorderedSteps.splice(destinationIndex + offsetIndex, 0, removed);
      reorderedSteps.forEach((s, index) => {
        s.tourStepInfo = [index].join(';');
        s.tourIndexOrder = index;
        if (s.uid === selectedTourStep.uid) {
          s.steps.forEach((step) => {
            step.reordered = true;
          });
        }
      });
      setEvolution({...evolution, tourSteps: reorderedSteps});
    } else if (type === 'steps') {
      setEvolution({
        ...evolution,
        tourSteps: evolution.tourSteps.map((t) => {
          if (t.uid === sourceDroppableId) {
            const [removed] = t.steps.splice(sourceIndex, 1);
            t.steps.splice(destinationIndex, 0, removed);
            t.steps.forEach((s, index) => {
              s.indexOrder = index;
              if (s.uid === removed.uid) {
                s.reordered = true;
              }
            });
          }
          return t;
        }),
      });
    }
  };

  const navigationGroups = evolution.tourSteps?.reduce((acc, cur, index) => {
    const isNavigationStep = hasFlag(F_BOOST_SLOT_NAVIGATION, cur.boostFlags);
    if (index === 0 || isNavigationStep) {
      acc.push([cur]);
    } else {
      acc[acc.length - 1].push(cur);
    }

    return acc;
  }, []);

  const selectedTourStep =
    selectedTourStepId != null
      ? evolution.tourSteps?.find((s) => s.uid === selectedTourStepId)
      : evolution.tourSteps?.find((s) =>
          s.steps.some((s) => s.uid === selectedStepId)
        );

  const hasBannerStep = evolution.tourSteps?.some((s) =>
    hasFlag(F_BOOST_SLOT_TOP_BAR, s.boostFlags)
  );
  const issues = isPokeValid(evolution);

  return (
    <div className="adoption-steps-manager">
      <div className="tour-steps-wrapper">
        {!navigationGroups?.length > 0 && (
          <DropdownAddStep
            dropdownOpen={true}
            setDropdownOpen={setDropdownOpen}
            handleTypeClick={handleTypeClick}
            isHint={evolution?.type === EVOLUTION_TYPE_HINT}
          />
        )}

        <div className="groups-wrapper">
          {navigationGroups?.map((group, groupIndex) => {
            const isGroupActive = group
              .map((s) => s.uid)
              .includes(selectedTourStep?.uid);
            const lastGroupStep = group[group.length - 1];

            return (
              <>
                <DragDropContext
                  onDragEnd={onDragEnd}
                  onDragStart={onDragStart}>
                  <Droppable
                    droppableId={groupIndex.toString()}
                    type="tour-steps"
                    direction="horizontal">
                    {(dropProvided) => (
                      <>
                        <div
                          className={classNames('group-steps-wrapper', {
                            passive:
                              draggedTourStepId != null &&
                              group
                                ?.map((s) => s.uid)
                                .includes(draggedTourStepId) !== true,
                          })}
                          style={{position: 'relative'}}
                          ref={dropProvided.innerRef}
                          {...dropProvided.droppableProps}>
                          <div className="group-steps">
                            {group?.map((tourStep, index) => {
                              const type = getTypeFromBoostFlags(
                                tourStep.boostFlags
                              );
                              let icon = getIcon(type);

                              if (type === TYPE_HINT) {
                                const blockHint =
                                  tourStep.steps[0]?.blocks?.find(
                                    (b) => b.type === BLOCK_TYPE_HINT
                                  );
                                if (blockHint?.style?.type === HINT_TYPE_ICON) {
                                  icon = <i className="icon-question-circle" />;
                                } else if (
                                  blockHint?.style?.type === HINT_TYPE_LABEL
                                ) {
                                  icon = <i className="icon-label-icon" />;
                                } else if (
                                  blockHint?.style?.type === HINT_TYPE_HIDDEN
                                ) {
                                  icon = <i className="icon-target" />;
                                }
                              }

                              const isTargetNotSelected =
                                hasFlags(
                                  [F_SLOT_DOT, F_SLOT_TOOLTIP, F_SLOT_HINT],
                                  tourStep.boostFlags,
                                  true
                                ) &&
                                !tourStep.boostedQueryselector &&
                                !tourStep.querySelectorManual?.cssSelector &&
                                !tourStep.querySelectorManual?.elementText;

                              if (
                                hasFlag(
                                  F_BOOST_SLOT_NAVIGATION,
                                  tourStep.boostFlags
                                )
                              ) {
                                return (
                                  <div
                                    className={classNames(
                                      'group',
                                      'navigation',
                                      {
                                        passive:
                                          draggedStepId != null &&
                                          tourStep.steps
                                            ?.map((s) => s.uid)
                                            .includes(draggedStepId) !== true,
                                      }
                                    )}>
                                    <div
                                      className={classNames('tour-step', {
                                        selected:
                                          tourStep.uid ===
                                          selectedTourStep?.uid,
                                      })}
                                      onClick={() =>
                                        handleSelectStep(null, tourStep.uid)
                                      }>
                                      {icon}

                                      <Button
                                        className="delete-btn"
                                        iconLeft="icon-close"
                                        iconOnly
                                        danger
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();

                                          setStepIdToDelete(tourStep.uid);
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                              return (
                                <Draggable
                                  key={tourStep.uid}
                                  draggableId={tourStep.uid}
                                  index={index}>
                                  {(dragProvided, snapshot) => {
                                    const draggableStyle =
                                      dragProvided.draggableProps.style;
                                    let style = {...draggableStyle};

                                    const isGroup = tourStep?.steps.length > 1;

                                    return (
                                      <div
                                        className={classNames('group', {
                                          'is-group': isGroup,
                                          passive:
                                            draggedStepId != null &&
                                            tourStep.steps
                                              ?.map((s) => s.uid)
                                              .includes(draggedStepId) !== true,
                                          active:
                                            draggedStepId != null &&
                                            tourStep.steps
                                              ?.map((s) => s.uid)
                                              .includes(draggedStepId) === true,
                                          'is-dragged': snapshot.isDragging,
                                          'cannot-drag':
                                            draggedTourStepId != null &&
                                            tourStep.uid !== draggedTourStepId,
                                        })}
                                        ref={dragProvided.innerRef}
                                        {...dragProvided.draggableProps}
                                        {...(isGroup !== true
                                          ? dragProvided.dragHandleProps
                                          : {})}
                                        style={style}>
                                        {isGroup && (
                                          <div
                                            className="dragger"
                                            isDragging={snapshot.isDragging}
                                            {...(isGroup
                                              ? dragProvided.dragHandleProps
                                              : {})}>
                                            <i className="icon-menu-vertical"></i>
                                            <i className="icon-menu-vertical"></i>
                                          </div>
                                        )}
                                        <Droppable
                                          droppableId={tourStep.uid}
                                          type={'steps'}
                                          isDropDisabled={false}
                                          direction="horizontal">
                                          {(dropProvidedStep) => (
                                            <div
                                              style={{
                                                display: 'flex',
                                              }}
                                              ref={dropProvidedStep.innerRef}>
                                              {tourStep?.steps.map(
                                                (step, stepIndex) => {
                                                  const hasNoPrevStep =
                                                    stepIndex === 0 &&
                                                    index === 0;
                                                  const prevStepIsNavigation =
                                                    index > 0 &&
                                                    group[index - 1]
                                                      ?.boostFlags ===
                                                      F_BOOST_SLOT_NAVIGATION &&
                                                    stepIndex === 0;

                                                  const hasIssue =
                                                    issues !== true
                                                      ? issues.find(
                                                          (issue) =>
                                                            issue.stepId ===
                                                            step.uid
                                                        )
                                                      : false;
                                                  const issue = getStepIssue(
                                                    step,
                                                    {
                                                      hasNoPrevStep,
                                                      prevStepIsNavigation,
                                                    }
                                                  );

                                                  return (
                                                    <>
                                                      <Draggable
                                                        key={step.uid}
                                                        draggableId={step.uid}
                                                        index={stepIndex}
                                                        isDragDisabled={
                                                          isGroup !== true
                                                        }>
                                                        {(
                                                          dragProvidedStep,
                                                          dragSnapshotStep
                                                        ) => {
                                                          return (
                                                            <div
                                                              className={classNames(
                                                                'tour-step',
                                                                {
                                                                  selected:
                                                                    step.uid ===
                                                                    selectedStepId,
                                                                  invalid:
                                                                    selectedStepId !==
                                                                      step.uid &&
                                                                    (issue !=
                                                                      null ||
                                                                      hasIssue ||
                                                                      isTargetNotSelected),
                                                                  'is-dragging':
                                                                    dragSnapshotStep.isDragging,
                                                                  'has-prev-step':
                                                                    stepIndex >
                                                                    0,
                                                                  'has-next-step':
                                                                    stepIndex <
                                                                    tourStep
                                                                      .steps
                                                                      .length -
                                                                      1,
                                                                  removed:
                                                                    step.removed,
                                                                }
                                                              )}
                                                              onClick={() =>
                                                                handleSelectStep(
                                                                  step.uid
                                                                )
                                                              }
                                                              ref={
                                                                dragProvidedStep.innerRef
                                                              }
                                                              {...dragProvidedStep.draggableProps}
                                                              {...dragProvidedStep.dragHandleProps}>
                                                              {icon}
                                                              {isBanner !==
                                                                true && (
                                                                <>
                                                                  {step.removed ===
                                                                  true ? (
                                                                    <Button
                                                                      className="unhide-btn"
                                                                      iconLeft="icon-eye"
                                                                      iconOnly
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();

                                                                        unhideStep(
                                                                          step.uid
                                                                        );
                                                                      }}
                                                                    />
                                                                  ) : (
                                                                    <Button
                                                                      className="delete-btn"
                                                                      iconLeft="icon-close"
                                                                      iconOnly
                                                                      danger
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();

                                                                        setStepIdToDelete(
                                                                          step.uid
                                                                        );
                                                                      }}
                                                                    />
                                                                  )}
                                                                </>
                                                              )}
                                                              {
                                                                dragProvidedStep.placeholder
                                                              }
                                                              {hasBannerStep !==
                                                                true && (
                                                                <div className="step-preview-wrapper">
                                                                  <StepPreview
                                                                    icon={icon}
                                                                    title={
                                                                      step.name
                                                                    }
                                                                    subtitle={
                                                                      getTypeNameAndIcon(
                                                                        tourStep
                                                                      )
                                                                        ?.typeName
                                                                    }
                                                                    poke={
                                                                      tourStep
                                                                    }
                                                                    selectedStepId={
                                                                      step.uid
                                                                    }
                                                                  />
                                                                </div>
                                                              )}
                                                            </div>
                                                          );
                                                        }}
                                                      </Draggable>
                                                    </>
                                                  );
                                                }
                                              )}
                                              {dropProvidedStep.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                        {dragProvided.placeholder}
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {dropProvided.placeholder}
                          </div>
                          {isGroupActive === true &&
                            hasBannerStep !== true &&
                            isBoosted !== true && (
                              <DropdownAddStep
                                dropdownOpen={dropdownOpen}
                                setDropdownOpen={setDropdownOpen}
                                handleTypeClick={handleTypeClick}
                                lastGroupStep={lastGroupStep}
                                withNavigationStep={
                                  evolution?.type !== EVOLUTION_TYPE_HINT
                                }
                                currentType={getTypeFromBoostFlags(
                                  selectedTourStep?.boostFlags
                                )}
                                onClose={() => setDropdownOpen(false)}
                                isHint={evolution?.type === EVOLUTION_TYPE_HINT}
                              />
                            )}
                        </div>
                        {groupIndex < navigationGroups.length - 1 && (
                          <i className="icon-chevron-right group-separator" />
                        )}
                      </>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            );
          })}
        </div>
      </div>
      {stepIdToDelete != null && (
        <RemoveStepModal
          isOpen={stepIdToDelete != null}
          onRequestClose={() => setStepIdToDelete(null)}
          onConfirm={() => handleDeleteStep(stepIdToDelete)}
          isLiveStep={isStepLive}
        />
      )}
    </div>
  );
};

AdoptionStepsManager.propTypes = propTypes;
AdoptionStepsManager.defaultProps = defaultProps;

const DropdownAddStep = ({
  dropdownOpen,
  setDropdownOpen,
  handleTypeClick,
  withNavigationStep = false,
  currentType = null,
  onClose = () => {},
  isHint = false,
}) => {
  const currentItem = stepItems.find((item) => item.value === currentType);

  const items = isHint ? hintItems : stepItems;

  return (
    <Dropdown
      className="dropdown-add-step"
      open={dropdownOpen}
      position="bottom"
      offsetY={8}
      onClose={onClose}
      trigger={
        <div className="add-step-btn-wrapper">
          <Button
            className="add-step-btn"
            onClick={() => setDropdownOpen(true)}
            rounded={false}
            iconLeft="icon-plus"
            iconOnly
          />
        </div>
      }>
      <Menu className="steps-menu-builder">
        {isHint !== true && currentItem != null && (
          <>
            <div className="menu-title subtitle-3">
              Add to the same {currentItem.title.toLowerCase()}
            </div>
            <div className="poke-steps">
              <MenuItem
                className="steps-menu-item"
                icon={currentItem.icon}
                iconPosition="left"
                onClick={() =>
                  handleTypeClick({
                    type: currentItem.value,
                    addToCurrentTourStep: true,
                  })
                }>
                <div className="content">
                  <div className="title subtitle-3">
                    New {currentItem.title.toLowerCase()} step
                  </div>
                  <div className="description body-3 n-700">
                    {currentItem.description}
                  </div>
                </div>
              </MenuItem>
            </div>
          </>
        )}
        <div className="menu-title subtitle-3">
          {isHint === true ? 'Add a Hint' : 'Add to the experience'}
        </div>
        <div className="poke-steps">
          {items.map((item) => {
            const {value, icon, title, description, hintType} = item;
            return (
              <MenuItem
                className="steps-menu-item"
                icon={icon}
                iconPosition="left"
                onClick={() =>
                  handleTypeClick({type: value, hintType: hintType})
                }>
                <div className="content">
                  <div className="title subtitle-3">{title}</div>
                  <div className="description body-3 n-700">{description}</div>
                </div>
              </MenuItem>
            );
          })}
        </div>
        {withNavigationStep && (
          <>
            <Divider />
            <div className="navigation-step-wrapper">
              <MenuItem
                className="steps-menu-item"
                icon={
                  <div className="icon-wrapper preview">
                    <i className="icon-forward" />
                  </div>
                }
                iconPosition="left"
                onClick={() => handleTypeClick({type: TYPE_NAVIGATION})}>
                <div className="content">
                  <div className="title subtitle-3">Navigation step</div>
                  <div className="description body-3 n-700">
                    Redirect users to another page
                  </div>
                </div>
              </MenuItem>
            </div>
          </>
        )}
      </Menu>
    </Dropdown>
  );
};

export default AdoptionStepsManager;
