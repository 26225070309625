import {Elements} from '@stripe/react-stripe-js';
import {dataActions, generalActions} from 'actions';
import classnames from 'classnames';
import commaNumber from 'comma-number';
import Button from 'components/Button';
import Loader from 'components/Loader';
import {Modal} from 'components/Modal';
import {stripePromised} from 'conf/stripe';
import {PermissionsSettings} from 'constants/permissions';
import {hasPermissions} from 'helpers/permission';
import {useEffect, useState} from 'react';
import Lottie from 'react-lottie';
import {useQuery} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {ROUTE_SETTINGS_SUBSCRIPTION_PLAN} from 'router/routes.const';
import planDetails from 'scenes/Settings/scenes/Subscription/Plan/plan-details';
import {dataSelector, generalSelector} from 'selectors';
import {projectService, subscriptionService} from 'services';
import {isEligibleToTrial} from 'services/project';
import {Swaler} from 'swaler';
import './_Styles.scss';
import confettiData from './animations/confetti.animation.json';
import {CheckoutForm} from './checkout-form';
import {DowngradeForm} from './downgrade-form';
import {TrialLauncher} from './trial-launcher';

export const MODE_CHECKOUT = 'checkout';
export const MODE_DOWNGRADE = 'downgrade';
export const MODE_LAUNCH_TRIAL = 'launchTrial';
const MODE_DEFAULT = 'default';
const MODE_SUCCESS = 'success';

const defaultLottieOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
  animationData: confettiData,
};

const logger = new Swaler('ModalPlanUpgrade');

const ModalPlanUpgrade = ({
  initialMode = MODE_DEFAULT,
  title,
  planId: defaultPlanId,
  className,
  omitFeatures,
  defaultModeAnnualBilling = true,
  ...rest
}) => {
  const dispatch = useDispatch();
  const subscriptionPlans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );
  const project = useSelector((state) => generalSelector.getProject(state));

  const [planId, setPlanId] = useState(defaultPlanId);
  const [mode, setMode] = useState(initialMode);

  const plan = subscriptionPlans.find((p) => p.uid === planId);
  const price = plan != null ? plan.prices[0] : 0;
  const classNames = classnames(
    'modal-plan-upgrade',
    `plan-${planId?.toLowerCase()}`,
    className,
    {
      'is-loading': plan == null,
      'mode-checkout': mode === MODE_CHECKOUT,
      'mode-downgrade': mode === MODE_DOWNGRADE,
      'mode-success': mode === MODE_SUCCESS,
      'mode-launch-trial': mode === MODE_LAUNCH_TRIAL,
    }
  );
  const canCheckoutOrLaunchTrial = hasPermissions([
    PermissionsSettings.BILLING_ACCESS,
  ]);

  useEffect(() => setMode(initialMode), [initialMode, rest.isOpen]);
  useEffect(() => setPlanId(defaultPlanId), [defaultPlanId]);
  useQuery({
    queryKey: 'subscriptionPlans',
    queryFn: async () => subscriptionService.getSubscriptionPlans(),
    onSuccess: (plans) => dispatch(dataActions.setSubscriptionPlans(plans)),
    onError: (err) =>
      logger.error('Fetching subscription plans with error ', err),
    enabled: subscriptionPlans.length === 0,
    refetchOnWindowFocus: false,
  });

  const handleSuccess = async () => {
    setMode(MODE_SUCCESS);
  };
  const updateStore = async () => {
    const planData = subscriptionPlans.find((p) => p.uid === planId);
    const subscription = await subscriptionService.getSubscriptionById(
      project.subscription.uid
    );
    const updatedProject = await projectService.getProject();

    dispatch(
      generalActions.uptProject({
        ...updatedProject,
        thresholdJimers: plan.jimers,
        thresholdSeats: planData.seats,
      })
    );
    dispatch(
      generalActions.uptProjectSubscription({
        plan: planId,
        extraFlags: subscription.extraFlags,
      })
    );
    rest.onRequestClose(null, true);
  };

  return (
    <Modal
      key={planId}
      className={classNames}
      shouldCloseOnOverlayClick={mode === MODE_SUCCESS ? false : true}
      {...rest}>
      {plan == null ? (
        <Loader width="16px" dark></Loader>
      ) : (
        <div className="modal-content-wrapper">
          <div className="plan-header-anim"></div>
          {mode === MODE_DEFAULT && (
            <div className="content-wrapper">
              <div className="plan-description">
                <div className="upgrade-title">{title}</div>
                <div className="plan-details">
                  <p>And also...</p>
                  {[
                    {title: `${commaNumber(plan.mau)} monthly active users`},
                    {title: `${plan.seats} seats`},
                  ]
                    .concat(
                      planDetails[planId]
                        .filter(
                          (d) =>
                            [].concat(omitFeatures).includes(d.uid) === false
                        )
                        .splice(0, 4)
                    )
                    .map((d, i) => (
                      <div key={i} className="detail fade-in-top">
                        <div className="detail-icon-wrapper">
                          <i className="icon-tick"></i>
                        </div>
                        <div className="detail-content">
                          <div className="detail-title">{d.title}</div>
                          <div className="detail-description">
                            {d.description != null && d.description}
                            {d.cta != null && (
                              <div
                                className="description-cta"
                                onClick={() => setPlanId(d.ctaPlan)}>
                                {d.cta}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                {planDetails[planId].filter(
                  (d) => [].concat(omitFeatures).includes(d.uid) === false
                ).length > 4 && (
                  <Link
                    to={ROUTE_SETTINGS_SUBSCRIPTION_PLAN}
                    className="label-and-more">
                    and more <i className="icon-chevron-right"></i>
                  </Link>
                )}
              </div>
              <div className="vertical-divider"></div>
              <div className="plan-cta">
                <small>Available on</small>
                <div className="plan-name">{plan.title}.</div>
                <div
                  className={classnames('plan-price', {
                    'is-trial-eligible': isEligibleToTrial(planId),
                  })}>
                  {isEligibleToTrial(planId) === false ? (
                    <>
                      {price}$<small>/month</small>
                    </>
                  ) : (
                    <div className="trial-label">
                      You are eligible for a 14 days trial.{' '}
                      <small>Credit card not required.</small>
                    </div>
                  )}
                </div>
                <div
                  className="plan-button"
                  onClick={() => {
                    if (canCheckoutOrLaunchTrial === false) return;
                    setMode(
                      isEligibleToTrial(planId) === true
                        ? MODE_LAUNCH_TRIAL
                        : MODE_CHECKOUT
                    );
                  }}>
                  <Button
                    primary
                    thin={canCheckoutOrLaunchTrial === false}
                    iconRight={
                      canCheckoutOrLaunchTrial === true
                        ? 'icon-chevron-right'
                        : null
                    }
                    disabled={canCheckoutOrLaunchTrial === false}>
                    {canCheckoutOrLaunchTrial === false
                      ? 'Contact your project administrator'
                      : isEligibleToTrial(planId) === true
                      ? 'Start my trial'
                      : `Upgrade to ${plan.title}`}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {mode === MODE_LAUNCH_TRIAL && (
            <TrialLauncher
              plan={plan}
              onSuccess={handleSuccess}></TrialLauncher>
          )}
          {mode === MODE_CHECKOUT && (
            <Elements stripe={stripePromised}>
              <CheckoutForm
                plan={plan}
                defaultModeAnnualBilling={defaultModeAnnualBilling}
                onSuccess={handleSuccess}></CheckoutForm>
            </Elements>
          )}
          {mode === MODE_DOWNGRADE && (
            <DowngradeForm
              plan={plan}
              onCancel={() => rest.onRequestClose()}
              onDowngrade={handleSuccess}></DowngradeForm>
          )}
          {mode === MODE_SUCCESS && (
            <div className="content-wrapper">
              <div className="confetti-wrapper">
                <Lottie
                  options={defaultLottieOptions}
                  width="100%"
                  height="100%"></Lottie>
              </div>
              You are now on <div className="plan-name">{plan.title}!</div>
              <p>
                We have updated your active users and seats limitation. If you
                upgraded and had held requests or votes, you should be able to
                see them now!
              </p>
              <Button muted onClick={() => updateStore()}>
                Close
              </Button>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ModalPlanUpgrade;
