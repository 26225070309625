import React, {useContext} from 'react';
import {AudienceContext} from 'scenes/PokeAudience';
import AudienceSection from 'scenes/PokeAudience/components/AudienceSection';
import './_Styles.scss';

import Divider from 'components/Divider';
import {hasFlag} from 'helpers/bitwise';
import {bool, number} from 'prop-types';
import {
  EVOLUTION_TYPE_HINT,
  F_OPTION_IGNORE_RATE_LIMITING,
  RECURRENCY_EVERY_TIME,
  RECURRENCY_SINGLE_TIME,
} from 'services/evolution';
import Priority from './components/Priority';
import RecurrencySelection from './components/RecurrencySelection';
import TimingSelection, {dateToString} from './components/TimingSelection';

export const getWhenTitle = (
  evolution,
  {isPost = false, isHint = false} = {}
) => {
  const recurrency = evolution?.recurrencyType;
  const recurrencyValue = evolution?.recurrencyValue;
  const priority = evolution?.priority || 0;
  const ignorePokeControl = hasFlag(
    F_OPTION_IGNORE_RATE_LIMITING,
    evolution?.optionsFlags
  );

  const curDate = new Date();
  let publishAtLabel = 'Now';
  let expiresAtLabel = 'No expiration';

  if (evolution.lastStepChangeAt != null) {
    if (new Date(evolution.lastStepChangeAt).getTime() > curDate.getTime()) {
      publishAtLabel = `Live on ${dateToString(evolution.lastStepChangeAt)}`;
    }
    if (new Date(evolution.lastStepChangeAt).getTime() < curDate.getTime()) {
      publishAtLabel = `Live since ${dateToString(evolution.lastStepChangeAt)}`;
    }
  }

  if (evolution.expiresAt != null) {
    if (new Date(evolution.expiresAt).getTime() < curDate.getTime()) {
      expiresAtLabel = `Expired on ${dateToString(evolution.expiresAt)}`;
    }
    if (new Date(evolution.expiresAt).getTime() > curDate.getTime()) {
      expiresAtLabel = `Expires on ${dateToString(evolution.expiresAt)}`;
    }
  }

  const recurrencyStr =
    recurrency === RECURRENCY_SINGLE_TIME
      ? 'Show once'
      : recurrency === RECURRENCY_EVERY_TIME
      ? 'Show every time'
      : `Show every ${recurrencyValue} days`;
  const priorityStr =
    ignorePokeControl === true
      ? 'Ignore rate limiting'
      : `Priority ${priority}`;

  if (isPost === true || isHint === true) {
    return `${publishAtLabel}, ${expiresAtLabel}`;
  }
  return `${publishAtLabel}, ${expiresAtLabel}, ${recurrencyStr}, ${priorityStr}`;
};

const propTypes = {
  index: number.isRequired,
  isPost: bool,
};

const defaultProps = {
  isPost: false,
};

const When = ({index, isPost}) => {
  const {evolution} = useContext(AudienceContext);

  const isHint = evolution?.type === EVOLUTION_TYPE_HINT;

  return (
    <AudienceSection
      className="when"
      index={index}
      title={`When: ${getWhenTitle(evolution, {isPost, isHint})}`}>
      <div className="sub-section-title">
        Select when the experience is live
      </div>
      <TimingSelection />
      {isPost !== true && isHint !== true && (
        <>
          <Divider />
          <div className="sub-section-title">Select experience recurrence</div>
          <RecurrencySelection />
          <Divider />
          <div className="sub-section-title">Select experience priority</div>
          <Priority />
        </>
      )}
    </AudienceSection>
  );
};

When.propTypes = propTypes;
When.defaultProps = defaultProps;

export default When;
