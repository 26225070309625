import {generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getCustomizationFromTheme} from 'router/Layouts';
import {CustomizeOptions} from 'scenes/Builder/scenes/Channel/components/CustomizeOptions';
import {
  TYPE_BANNER,
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {generalSelector} from 'selectors';
import {themeService} from 'services';
import {
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_POP_IN,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOP_BAR,
} from 'services/evolution';
import {Swaler} from 'swaler';
import Saver from '../../../components/Saver';
import PreviewPokes from '../components/PreviewPokes';
import './_Styles.scss';

export const defaultTheme = {
  name: 'Default',
  // banner
  bannerTextsColors: '#071331;#071331',
  bannerPrimaryColor: '#ffffff',
  bannerTitleFontSize: '18',
  bannerCtaBorderRadius: '34',
  bannerCtaBackgroundColor: '#1260eb',
  bannerCtaColor: '#ffffff',
  // hotspot
  hotspotPositionFlags: 64,
  hotspotTextsColors: '#071331;#071331',
  hotspotPrimaryColor: '#ffffff',
  hotspotSecondaryColor: '#1260EB',
  hotspotRoundness: '8',
  hotspotContentFontSize: '14',
  hotspotTitleFontSize: '18',
  hotspotStyle: '#1260EB;22',
  hotspotSize: null,
  hotspotCtaBorderRadius: '34',
  hotspotCtaBackgroundColor: '#1260eb',
  hotspotCtaColor: '#ffffff',
  //snippet
  snippetTextsColors: '#071331;#071331',
  snippetPrimaryColor: '#ffffff',
  snippetSecondaryColor: '#1260EB',
  snippetRoundness: '8',
  snippetContentFontSize: '14',
  snippetTitleFontSize: '18',
  snippetSize: null,
  snippetCtaBorderRadius: '34',
  snippetCtaBackgroundColor: '#1260eb',
  snippetCtaColor: '#ffffff',
  //modal
  modalTextsColors: '#071331;#071331',
  modalPrimaryColor: '#ffffff',
  modalSecondaryColor: '#1260EB',
  modalRoundness: '8',
  modalContentFontSize: '14',
  modalTitleFontSize: '18',
  modalSize: null,
  modalCtaBorderRadius: '34',
  modalCtaBackgroundColor: '#1260eb',
  modalCtaColor: '#ffffff',
  modalLightbox: 'SOFT',

  // tootlip
  tooltipPositionFlags: 64,
  tooltipTextsColors: '#071331;#071331',
  tooltipPrimaryColor: '#ffffff',
  tooltipSecondaryColor: '#1260EB',
  tooltipRoundness: '8',
  tooltipContentFontSize: '14',
  tooltipTitleFontSize: '18',
  tooltipSize: null,
  tooltipCtaBorderRadius: '34',
  tooltipCtaBackgroundColor: '#1260eb',
  tooltipCtaColor: '#ffffff',
  tooltipLightbox: 'SOFT',

  style: {
    stepStyle: {
      margin: 0,
      shadow: {
        x: '0',
        y: '24',
        blur: '24',
        color: '#00000014',
      },
      overlay: null,
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#1260ebff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      'animation-in': null,
      animationOut: 'fade',
      borderRadius: 12,
      'animation-out': 'slide-up',
    },
    blocksStyle: {
      NPS: {
        style: {
          padding: 8,
          fontSize: 16,
        },
      },
      BODY: {
        style: {
          align: 'left',
          fontSize: 16,
          fontColor: '#000000',
          fontFamily: 'Inter',
          fontWeight: '300',
        },
      },
      USER: {
        style: {
          borderRadius: 8,
        },
      },
      LABEL: {
        style: {
          align: 'left',
          padding: 8,
          fontSize: 12,
          fontColor: '#1260ebff',
          fontFamily: 'Inter',
          fontWeight: '400',
          borderColor: '#1260ebbf',
          borderRadius: 8,
          primaryColor: '#1260eb14',
        },
      },
      MEDIA: {
        style: {
          padding: 8,
          position: 'top',
          borderRadius: 8,
        },
      },
      TITLE: {
        style: {
          align: 'left',
          fontSize: 24,
          fontColor: '#000000',
          fontFamily: 'Inter',
          fontWeight: '600',
        },
      },
      CHOICE: {
        style: {
          shadow: {
            x: '0',
            y: '8',
            blur: '12',
            color: '#00000014',
          },
          padding: 12,
          fontSize: 16,
          fontFamily: 'Inter',
          disposition: 'inline',
          borderRadius: 12,
          primaryColor: '#1260ebff',
        },
      },
      SLIDER: {
        style: {
          height: 6,
          primaryColor: '#1260ebb3',
          secondaryColor: '#1260eb29',
        },
      },
      OPINION: {
        style: {
          padding: 8,
          fontSize: 20,
        },
      },
      STEPPER: {
        style: {
          type: 'dot-line',
          align: 'left',
          position: 'top',
          primaryColor: '#1260ebff',
          secondaryColor: '#1260eb29',
        },
      },
      INTERVIEW: {
        style: {
          align: 'center',
          fontSize: 16,
          fontFamily: 'Inter',
          borderRadius: 8,
          primaryColor: '#ffffffff',
          secondaryColor: '#ffffff',
          fontPrimaryColor: '#1260ebff',
          borderPrimaryColor: '#1260ebff',
          fontSecondaryColor: '#000000ff',
          borderSecondaryColor: '#00000029',
        },
      },
      PRIMARY_CTA: {
        style: {
          align: 'right',
          padding: 12,
          fontSize: 16,
          fontColor: '#ffffffff',
          fontFamily: 'Inter',
          fontWeight: '500',
          borderRadius: 12,
          primaryColor: '#1260ebff',
        },
      },
      OPEN_QUESTION: {
        style: {
          borderRadius: 6,
          primaryColor: '#f3f3f3ff',
        },
      },
      SECONDARY_CTA: {
        style: {
          align: 'left',
          padding: 12,
          fontSize: 16,
          fontColor: '#000000',
          fontFamily: 'Arial',
          borderColor: '#0000001f',
          borderRadius: 12,
          primaryColor: '#ffffff',
        },
      },
    },
  },
};

const getBoostFlags = (type) => {
  if (type === TYPE_BANNER) {
    return F_BOOST_SLOT_TOP_BAR;
  } else if (type === TYPE_HOTSPOT) {
    return F_BOOST_SLOT_DOT;
  } else if (type === TYPE_SNIPPET) {
    return F_BOOST_SLOT_SNIPPET;
  } else if (type === TYPE_MODAL) {
    return F_BOOST_SLOT_POP_IN;
  } else if (type === TYPE_TOOLTIP) {
    return F_BOOST_SLOT_TOOLTIP;
  }
};

export const getThemeFieldsFromCustomization = (type, customization) => {
  if (type === TYPE_BANNER) {
    return {
      bannerTextsColors: customization.boostedTextsColors,
      bannerPrimaryColor: customization.boostedPrimaryColor,
      bannerTitleFontSize: customization.boostedTitleFontSize,
      bannerCtaBorderRadius: customization.ctaBorderRadius,
      bannerCtaBackgroundColor: customization.ctaBackgroundColor,
      bannerCtaColor: customization.ctaColor,
    };
  } else if (type === TYPE_HOTSPOT) {
    return {
      hotspotPositionFlags: customization.boostedPositionFlags,
      hotspotTextsColors: customization.boostedTextsColors,
      hotspotPrimaryColor: customization.boostedPrimaryColor,
      hotspotSecondaryColor: customization.boostedSecondaryColor,
      hotspotRoundness: customization.boostedRoundness,
      hotspotContentFontSize: customization.boostedContentFontSize,
      hotspotTitleFontSize: customization.boostedTitleFontSize,
      hotspotStyle: customization.boostedDotStyle,
      hotspotSize: customization.boostedSize,
      hotspotCtaBorderRadius: customization.ctaBorderRadius,
      hotspotCtaBackgroundColor: customization.ctaBackgroundColor,
      hotspotCtaColor: customization.ctaColor,
    };
  } else if (type === TYPE_SNIPPET) {
    return {
      snippetTextsColors: customization.boostedTextsColors,
      snippetPrimaryColor: customization.boostedPrimaryColor,
      snippetSecondaryColor: customization.boostedSecondaryColor,
      snippetRoundness: customization.boostedRoundness,
      snippetContentFontSize: customization.boostedContentFontSize,
      snippetTitleFontSize: customization.boostedTitleFontSize,
      snippetSize: customization.boostedSize,
      snippetCtaBorderRadius: customization.ctaBorderRadius,
      snippetCtaBackgroundColor: customization.ctaBackgroundColor,
      snippetCtaColor: customization.ctaColor,
    };
  } else if (type === TYPE_MODAL) {
    return {
      modalTextsColors: customization.boostedTextsColors,
      modalPrimaryColor: customization.boostedPrimaryColor,
      modalSecondaryColor: customization.boostedSecondaryColor,
      modalRoundness: customization.boostedRoundness,
      modalContentFontSize: customization.boostedContentFontSize,
      modalTitleFontSize: customization.boostedTitleFontSize,
      modalSize: customization.boostedSize,
      modalCtaBorderRadius: customization.ctaBorderRadius,
      modalCtaBackgroundColor: customization.ctaBackgroundColor,
      modalCtaColor: customization.ctaColor,
      modalLightbox: customization.boostedLightbox,
    };
  } else if (type === TYPE_TOOLTIP) {
    return {
      tooltipPositionFlags: customization.boostedPositionFlags,
      tooltipTextsColors: customization.boostedTextsColors,
      tooltipPrimaryColor: customization.boostedPrimaryColor,
      tooltipSecondaryColor: customization.boostedSecondaryColor,
      tooltipRoundness: customization.boostedRoundness,
      tooltipContentFontSize: customization.boostedContentFontSize,
      tooltipTitleFontSize: customization.boostedTitleFontSize,
      tooltipSize: customization.boostedSize,
      tooltipCtaBorderRadius: customization.ctaBorderRadius,
      tooltipCtaBackgroundColor: customization.ctaBackgroundColor,
      tooltipCtaColor: customization.ctaColor,
      tooltipLightbox: customization.boostedLightbox,
    };
  }
};

const isCtaUpdate = (prevTheme, newTheme) => {
  for (const key in prevTheme) {
    if (key.includes('Cta')) {
      if (prevTheme[key] !== newTheme[key]) {
        return true;
      }
    }
  }
  return false;
};

const isContentFontSizeUpdate = (prevTheme, newTheme) => {
  for (const key in prevTheme) {
    if (key.includes('Content')) {
      if (prevTheme[key] !== newTheme[key]) {
        return true;
      }
    }
  }
  return false;
};

const logger = new Swaler('AppearancePokes');

const AppearancePokes = () => {
  const dispatch = useDispatch();
  const project = useSelector((state) => generalSelector.getProject(state));

  const [type, setType] = useState(TYPE_HOTSPOT);
  const [isSaving, setIsSaving] = useState(false);
  const [theme, setTheme] = useState(project.theme || defaultTheme);
  const [forcedStepIndex, setForcedStepIndex] = useState(0);

  useEffect(() => {
    setForcedStepIndex(0);
  }, [type]);

  const handleCustomizationChange = (customization) => {
    const updatedTheme = {
      ...theme,
      ...getThemeFieldsFromCustomization(type, customization),
    };
    if (isCtaUpdate(theme, updatedTheme) && type !== TYPE_BANNER) {
      setForcedStepIndex(3);
    } else if (
      isContentFontSizeUpdate(theme, updatedTheme) &&
      forcedStepIndex === 3
    ) {
      setForcedStepIndex(0);
    }
    setTheme(updatedTheme);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const savedTheme = await themeService.createTheme(theme);
      dispatch(
        generalActions.uptProject({...project, theme: savedTheme}, project.uid)
      );
      setTheme(savedTheme);
    } catch (err) {
      logger.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleReset = () => {
    setTheme(project.theme || defaultTheme);
  };

  const boostFlags = getBoostFlags(type);
  const customization = getCustomizationFromTheme(type, theme);

  return (
    <div className="appearance-pokes">
      <div className="main-wrapper">
        <div className="preview-widget fade-in">
          <PreviewPokes
            type={type}
            customization={customization}
            forcedStepIndex={forcedStepIndex}
          />
        </div>
        <div className="main-settings-wrapper">
          <div className="type-select-btns">
            <Button
              className={classnames({selected: type === TYPE_HOTSPOT})}
              iconLeft="icon-slot-dot"
              onClick={() => setType(TYPE_HOTSPOT)}>
              Hotspot
            </Button>
            <Button
              className={classnames({selected: type === TYPE_TOOLTIP})}
              iconLeft="icon-slot-tooltip"
              onClick={() => setType(TYPE_TOOLTIP)}>
              Tooltip
            </Button>
            <Button
              className={classnames({selected: type === TYPE_BANNER})}
              iconLeft="icon-slot-top-bar"
              onClick={() => setType(TYPE_BANNER)}>
              Banner
            </Button>
            <Button
              className={classnames({selected: type === TYPE_SNIPPET})}
              iconLeft="icon-slot-snippet"
              onClick={() => setType(TYPE_SNIPPET)}>
              Snippet
            </Button>
            <Button
              className={classnames({selected: type === TYPE_MODAL})}
              iconLeft="icon-slot-pop-in"
              onClick={() => setType(TYPE_MODAL)}>
              Modal
            </Button>
          </div>
          <CustomizeOptions
            customization={customization}
            setCustomization={handleCustomizationChange}
            boostFlags={boostFlags}
            cardless
            dotPositionInCustomization
            withCtaStyling
          />
        </div>
      </div>
      <Saver
        onSave={handleSave}
        onCancel={handleReset}
        isOpen={
          JSON.stringify(theme) !==
          JSON.stringify(project.theme || defaultTheme)
        }
        isSaving={isSaving}
      />
    </div>
  );
};

export default AppearancePokes;
