import classNames from 'classnames';
import Button from 'components/Button';
import {errorHelpers} from 'helpers';
import {validateAction} from 'helpers/step';
import {bool, func, object} from 'prop-types';
import {useContext, useState} from 'react';
import {useQuery} from 'react-query';
import {BuilderContext} from 'scenes/Builder/context';
import {evolutionService} from 'services';
import {EVOLUTION_STATE_LIVE} from 'services/evolution';
import {
  STEP_CONDITION_ACTION_TYPE_DISMISS,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
} from 'services/steps';
import {Swaler} from 'swaler';
import EditActionDropdown from '../EditActionDropdown';
import './_Styles.scss';

const propTypes = {
  action: object.isRequired,
  onChange: func,
  onDelete: func,
  isExpanded: bool,
  defaultOpen: bool,
};

const defaultProps = {
  onChange: () => {},
  onDelete: () => {},
  isExpanded: false,
  defaultOpen: false,
};

const logger = new Swaler('Action');

const Action = ({action, onChange, onDelete, isExpanded, defaultOpen}) => {
  const {evolution: evolutionContext} = useContext(BuilderContext);
  const steps = evolutionContext.tourSteps.map((ts) => ts.steps).flat();

  const {type, evolution, step, value} = action;

  const {data: experiences = [], isLoading} = useQuery({
    queryKey: 'experiences',
    queryFn: async () => {
      try {
        const experiences = await evolutionService.getEvolutions({
          relations: [],
          onlyPokes: true,
          states: [EVOLUTION_STATE_LIVE],
          removeDrafts: true,
        });

        return experiences;
      } catch (err) {
        const {code} = errorHelpers.parseError(err);

        logger.error('Fetch experiences failed with error ', code);
      }
    },
  });

  const isGoToStep = type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP;
  const isRunJSCode = type === STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE;
  const isDimiss = type === STEP_CONDITION_ACTION_TYPE_DISMISS;
  const isLaunchExperience =
    type === STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE;
  const isNavigateTo = type === STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO;

  const [showEditDropdown, setShowEditDropdown] = useState(
    isDimiss !== true && defaultOpen
  );

  const isValid = validateAction(action).length === 0;

  const selectedStep =
    step != null ? steps.find((s) => s.uid === step.uid) : null;
  const selectedExperience = experiences.find((e) => e.uid === evolution?.uid);

  let content = '',
    icon,
    iconClassName,
    title,
    header;

  if (isGoToStep) {
    icon = 'isax isax-arrow-right';
    iconClassName = 'go-to-step';
    header = 'Go to step';
    title =
      selectedStep?.name ||
      (value === 'next-step-placeholder' ? 'Next step' : 'Select a step');
  } else if (isLaunchExperience) {
    icon = 'isax isax-routing';
    iconClassName = 'launch-experience';
    header = 'Launch experience';
    title = selectedExperience?.title || 'Select an experience';
  } else if (isNavigateTo) {
    icon = 'isax isax-send-2';
    iconClassName = 'navigate-to';
    header = 'Navigate to';
    title = value || 'Enter a URL';
  } else if (isRunJSCode) {
    icon = 'isax isax-document-code';
    iconClassName = 'run-js-code';
    title = 'Run JavaScript code';
  } else if (isDimiss) {
    icon = 'isax isax-close-square';
    iconClassName = 'dismiss';
    title = 'Dismiss flow';
  }

  content = (
    <>
      <div className={classNames('icon-wrapper', iconClassName)}>
        <i className={icon} />
      </div>
      <div className="content">
        {header && <div className="action-title body-4 n-500">{header}</div>}
        {title && <div className="action-subtitle body-3 n-800">{title}</div>}
      </div>
    </>
  );

  return (
    <EditActionDropdown
      action={action}
      setAction={(action) => onChange(action)}
      isOpen={showEditDropdown}
      onClose={() => setShowEditDropdown(false)}
      disabled={isDimiss === true || isExpanded !== true}
      trigger={
        <div
          className={classNames('trigger-action', {
            'is-invalid': isValid !== true,
            selected: showEditDropdown,
            'is-reduced': isExpanded !== true,
          })}
          onClick={() => {
            if (isDimiss === true) {
              return;
            }
            setShowEditDropdown(true);
          }}>
          {content}
          <div className="actions-wrapper">
            <Button
              iconOnly
              iconLeft="isax isax-trash"
              danger
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                onDelete();
              }}
            />
          </div>
        </div>
      }
      position="right"
      offsetX={8}
    />
  );
};

Action.propTypes = propTypes;
Action.defaultProps = defaultProps;

export default Action;
