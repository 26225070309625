import classNames from 'classnames';
import React, {useContext} from 'react';
import {PokeStateContext} from '../../context';
import {BlockStep} from '../BlockStep';
import './_styles.scss';
import {
  BLOCK_BODY,
  BLOCK_CHOICE,
  BLOCK_CONCEPT,
  BLOCK_INTERVIEW,
  BLOCK_LABEL,
  BLOCK_MEDIA,
  BLOCK_NPS,
  BLOCK_OPEN_QUESTION,
  BLOCK_OPINION,
  BLOCK_SLIDER,
  BLOCK_TITLE,
} from '../../constants/blocks';

const LayoutMiddle = () => {
  const {addBlockElem, currentStep} = useContext(PokeStateContext);

  const hasBlockLabel = currentStep.blocks.some((b) => b.type === BLOCK_LABEL);
  const hasBlockTitle = currentStep.blocks.some((b) => b.type === BLOCK_TITLE);
  const hasBlockBody = currentStep.blocks.some((b) => b.type === BLOCK_BODY);
  const hasBlockMedia = currentStep.blocks.some((b) => b.type === BLOCK_MEDIA);
  const hasBlockNPS = currentStep.blocks.some((b) => b.type === BLOCK_NPS);
  const hasBlockSlider = currentStep.blocks.some(
    (b) => b.type === BLOCK_SLIDER
  );
  const hasBlockOpenQuestion = currentStep.blocks.some(
    (b) => b.type === BLOCK_OPEN_QUESTION
  );
  const hasBlockOpinion = currentStep.blocks.some(
    (b) => b.type === BLOCK_OPINION
  );
  const hasBlockInterview = currentStep.blocks.some(
    (b) => b.type === BLOCK_INTERVIEW
  );
  const hasBlockChoice = currentStep.blocks.some(
    (b) => b.type === BLOCK_CHOICE
  );
  const hasBlockConcept = currentStep.blocks.some(
    (b) => b.type === BLOCK_CONCEPT
  );

  if (
    hasBlockLabel !== true &&
    hasBlockTitle !== true &&
    hasBlockBody !== true &&
    hasBlockMedia !== true &&
    hasBlockNPS !== true &&
    hasBlockSlider !== true &&
    hasBlockOpenQuestion !== true &&
    hasBlockOpinion !== true &&
    hasBlockInterview !== true &&
    hasBlockChoice !== true &&
    hasBlockConcept !== true
  ) {
    return <></>;
  }

  return (
    <div
      className={classNames('poke-layout-middle', {})}
      ref={(elem) => {
        if (elem != null) {
          addBlockElem('middle', elem);
        }
      }}>
      <BlockStep />
    </div>
  );
};

export default LayoutMiddle;
