import classnames from 'classnames';
import Button from 'components/Button';
import {Modal} from 'components/Modal';
import {GlobalContext} from 'contextes/Global';
import {useUpdatePlan} from 'hooks/useUpdatePlan';
import Lottie from 'lottie-react';
import {useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {dataSelector} from 'selectors';
import {
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import './_styles.scss';
import confetti from './animations/confetti.json';

export const ModalWelcomePlan = () => {
  const {modalWelcomePlan, setModalWelcomePlan} = useContext(GlobalContext);
  const {planId, refreshProjectAfterClose} = modalWelcomePlan;

  const {update} = useUpdatePlan();

  const [animationComplete, setAnimationComplete] = useState(false);

  const handleRequestClose = () => {
    setModalWelcomePlan(null);
    if (refreshProjectAfterClose === true) {
      update(planId);
    }
  };

  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );
  const plan = plans.find((p) => p.uid === planId);

  return (
    <Modal
      isOpen={true}
      onRequestClose={handleRequestClose}
      className={classnames(
        'modal-welcome-plan',
        `plan-${planId.toLowerCase()}`
      )}>
      <div className="background-gradient-wrapper">
        <div className="background-gradient"></div>
      </div>
      <div className="modal-main">
        <div className="plan-logo"></div>
        <div className="plan-title subtitle-1 n-700">
          Welcome to
          <div className="title-3 n-800">{planId.toLowerCase()} plan</div>
        </div>
        <div className="plan-description body-2 n-700">
          {planId === PLAN_STARTUP_ID &&
            `Enjoy multiple languages in your experiences, in-app Changelog, 
              ${plan.mau} MAU, ${plan.seats} seats, and more!`}
          {planId === PLAN_GROWTH_ID &&
            `Publish unlimited experiences, multiple languages, in-app Changelog, ${plan.mau}
              MAU, ${plan.seats} seats, and more!`}
          {planId === PLAN_SCALE_ID &&
            `Publish unlimited experiences, dedicated customer support, multiple languages, in-app Changelog, Jimo
              label hidden, ${plan.mau} MAU, ${plan.seats} seats, and more!`}
        </div>
        <Button primary onClick={handleRequestClose}>
          Let's go!
        </Button>
        <Lottie
          animationData={confetti}
          className={classnames('animation-confetti', {
            completed: animationComplete,
          })}
          loop={false}
          onComplete={() => setAnimationComplete(true)}
        />
      </div>
    </Modal>
  );
};
