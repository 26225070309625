import {dataActions, generalActions} from 'actions';
import axios from 'axios';
import classnames from 'classnames';
import Button from 'components/Button';
import Input from 'components/Input';
import DefaultLoader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {addFlag} from 'helpers/bitwise';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_ONBOARDING_DETAILS_STEP_2_2} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {
  meService,
  projectService,
  rolesService,
  subscriptionService,
} from 'services';
import {ME_F_ONBOARDING_DONE_STEP_2_1} from 'services/me';
import {Swaler} from 'swaler';
import './step-2-1.scss';

const logger = new Swaler('Onboarding/2-1');

export const OnboardingDetailsStep21 = ({
  triggerPTLAnimation,
  triggerSquareAnimationOut,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => generalSelector.getUser(state));
  const project = useSelector((state) => generalSelector.getProject(state));

  const setProject = (project) => dispatch(generalActions.setProject(project));
  const setProjects = (projects) =>
    dispatch(generalActions.setProjects(projects));
  const uptProjectSubscription = (subscription) =>
    dispatch(generalActions.uptProjectSubscription(subscription));
  const uptProject = (data) => dispatch(generalActions.uptProject(data));
  const setSubscriptionPlans = (plans) =>
    dispatch(dataActions.setSubscriptionPlans(plans));
  const setSegments = (segments) => dispatch(dataActions.setSegments(segments));
  const setCustomAttributes = (attributes) =>
    dispatch(dataActions.setCustomAttributes(attributes));
  const uptUser = (data) => generalActions.uptUser(data);
  const setBuiltInRoles = (roles) =>
    dispatch(dataActions.setBuiltInRoles(roles));

  const [playAnimationOut, setPlayAnimationOut] = useState(false);
  const [favicons, setFavicons] = useState([]);
  const [[customFavicon, customFaviconPreview], setCustomFavicon] = useState([
    null,
    null,
  ]);
  const [isFetchingFavicon, setIsFetchingFavicon] = useState(true);
  const [name, setName] = useState(project != null ? project.name : '');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const refInputFile = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let subscription = null;
    let plans = null;
    let projects = [];
    let createdProject = project;
    let logoData =
      customFavicon != null
        ? customFavicon
        : favicon != null
        ? new File([Buffer.from(favicon.data.data)], 'logo')
        : null;

    setIsSubmitting(true);
    // Create project (if not already done)
    if (project == null) {
      try {
        createdProject = await projectService.createProject({
          name: name,
        });
        projects = await meService.getMyProjects();

        subscription = await subscriptionService.initSubscription(
          createdProject.uid
        );
        plans = await subscriptionService.getSubscriptionPlans();
        const builtInRoles = await rolesService.getBuiltInRoles();
        const setCustomRoles = (roles) =>
          dispatch(dataActions.setCustomRoles(roles));

        setSubscriptionPlans(plans);
        setProjects(projects);
        setProject(createdProject.uid);
        uptProjectSubscription(subscription);
        setSegments([]);
        setCustomAttributes([]);
        setBuiltInRoles(builtInRoles);
        setCustomRoles([]);
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        setIsSubmitting(false);
        logger.error('Create project failed with error ', code);
        toastDanger([title, message], {actions});
        return setIsSubmitting(false);
      }
      // Start subscription
      try {
        const onboardingFlags = addFlag(
          ME_F_ONBOARDING_DONE_STEP_2_1,
          user.onboardingFlags
        );

        await meService.updateOnboardingFlag(onboardingFlags);
        uptUser({
          onboardingFlags,
        });
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        setIsSubmitting(false);
        logger.error('Updating user onboarding flags failed with error ', code);
        toastDanger([title, message], {actions});
        return setIsSubmitting(false);
      }
    }
    // Upload logo
    if (logoData != null) {
      try {
        const uploadedFile = await projectService.uploadPortalLogo({
          file: logoData,
        });
        uptProject({portalLogoUrl: uploadedFile.publicUrl});
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        logger.error('Upload changelog logo failed with error ', code);
        return toastDanger([title, message], {actions});
      }
    }

    const onboardingFlags = addFlag(
      ME_F_ONBOARDING_DONE_STEP_2_1,
      user.onboardingFlags
    );

    await meService.updateOnboardingFlag(onboardingFlags);
    uptUser({...user, onboardingFlags});
    setPlayAnimationOut(true);
    triggerSquareAnimationOut();
    setTimeout(() => {
      triggerPTLAnimation('out', () => {
        history.push(ROUTE_ONBOARDING_DETAILS_STEP_2_2);
      });
    }, 1200);
  };

  const deleteFavicon = () => {
    setCustomFavicon([null, null]);
    setFavicons([]);
  };

  useEffect(() => {
    const grabFavicons = async () => {
      const favicons = await axios
        .post(
          'https://oqzsfogtvltgmusrtmmt6bsbuy0ejwkt.lambda-url.eu-west-3.on.aws/',
          {
            url: user.companyWebsite,
          },
          {timeout: 10000}
        )
        .then((response) => response.data)
        .catch((err) => {
          if (err.message !== 'Network Error') {
            console.error(
              `Get favicon from website url failed with error `,
              err.message
            );
          }
          return [];
        });

      setFavicons(favicons.sort((a, b) => b.meta.length - a.meta.length));
      setIsFetchingFavicon(false);
    };

    grabFavicons();
  }, []);

  const favicon = favicons[0];
  const canContinue =
    name.length > 0 && name.length <= 32 && isFetchingFavicon === false;

  return (
    <div
      className={classnames(
        's-onboarding-details-step s-onboarding-details-step-2-1',
        {
          'is-exiting': playAnimationOut,
        }
      )}>
      <h1>Name your workspace</h1>
      <p>Set your workspace icon and name. You can change it later.</p>

      <div className="icon-container">
        <div
          className={classnames('icon-wrapper', {
            'is-loading':
              (project == null || project.portalLogoUrl == null) &&
              isFetchingFavicon === true,
          })}>
          {project != null && project.portalLogoUrl != null ? (
            <>
              <img src={project.portalLogoUrl} alt="favicon" width={54} />
            </>
          ) : isFetchingFavicon === true ? (
            <DefaultLoader width="24px" />
          ) : customFavicon != null || favicons.length > 0 ? (
            <>
              <Button iconOnly danger onClick={() => deleteFavicon()}>
                <i className="icon-trash"></i>
              </Button>
              <img
                src={
                  customFaviconPreview != null
                    ? customFaviconPreview
                    : favicon != null
                    ? favicon.url
                    : ''
                }
                alt="favicon"
                width={54}
              />
            </>
          ) : (
            <div
              className="no-favicon"
              onClick={() => refInputFile.current.click()}>
              <i className="icon-upload-o"></i>
              <input
                ref={refInputFile}
                type="file"
                style={{display: 'none'}}
                onChange={(e) => {
                  const file =
                    e.target.files.length !== 0 ? e.target.files[0] : null;
                  const preview = new FileReader();

                  preview.onloadend = () => {
                    setCustomFavicon([file, preview.result]);
                  };
                  preview.readAsDataURL(file);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="inputs-wrapper">
          <Input
            placeholder="Workspace of company"
            required
            value={name}
            onChange={({target}) => setName(target.value)}></Input>
        </div>
        <Button
          loading={isSubmitting}
          disabled={canContinue === false}
          cta
          primary
          secondary
          iconRight="icon-chevron-right">
          Create my workspace
        </Button>
      </form>
    </div>
  );
};
