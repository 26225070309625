import classnames from 'classnames';
import colorContrast from 'font-color-contrast';
import {hasFlag} from '../../../../helpers/bitwise';
import React, {useContext} from 'react';
import {F_SLOT_HINT, F_SLOT_TOP_BAR} from '../../constants/poke';
import {PokeContext, PokeStateContext} from '../../context';
import './_styles.scss';

export const BlockClose = () => {
  const {poke} = useContext(PokeContext);
  const {close} = useContext(PokeStateContext);

  const {style} = poke;
  const {background} = style ?? {};
  const colorToUSe = background?.primaryColor;
  const textColor = colorContrast(
    colorToUSe != null
      ? colorToUSe.length > 7
        ? colorToUSe.slice(0, colorToUSe.length - 2)
        : colorToUSe
      : '#FFFFFF'
  );

  return (
    <div
      className={classnames('poke-block-close', {
        'is-in-poke-banner': hasFlag(F_SLOT_TOP_BAR, poke.boostFlags),
        'is-in-hint': hasFlag(F_SLOT_HINT, poke.boostFlags),
      })}
      onClick={() => {
        close({
          forceDismiss: true,
        });
      }}
      style={{
        color: textColor,
      }}>
      <i className="icon-close"></i>
    </div>
  );
};
