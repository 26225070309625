import classnames from 'classnames';
import Button from 'components/Button';
import {Modal} from 'components/Modal';
import {GlobalContext} from 'contextes/Global';
import dayjs from 'dayjs';
import {useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {dataSelector, generalSelector} from 'selectors';
import {
  PLAN_GROWTH_ID,
  PLAN_STARTUP_ID,
  hasAnnualBilling,
} from 'services/subscription';
import logoGrowth from './../../assets/imgs/logo-growth.svg';
import logoScale from './../../assets/imgs/logo-scale.svg';
import logoStartup from './../../assets/imgs/logo-startup.svg';
import './_styles.scss';

export const ModalPlanDowngrade = () => {
  const {modalPlanDowngrade} = useContext(GlobalContext);
  const {planId, onSubmit, onCancel} = modalPlanDowngrade;

  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );

  const [isDowngrading, setIsDowngrading] = useState(false);

  function handleDowngrade() {
    setIsDowngrading(true);
    onSubmit();
  }

  const currentPlan = plans.find((p) => p.uid === subscription.plan);
  const newPlan = plans.find((p) => p.uid === planId);

  return (
    <Modal
      isOpen={true}
      onRequestClose={onCancel}
      className={classnames('modal-plan-downgrade')}>
      <div className="modal-main">
        <div className="downgrade-title title-4">Confirm Downgrade</div>

        {/* Current plan */}
        <div
          className={classnames(
            'plan-item',
            `plan-${subscription.plan.toLowerCase()}`
          )}>
          <div className="plan-label body-3 n-700">Current Plan</div>
          <div className="plan-details">
            <div className="left-side">
              <div className="plan-icon-wrapper subtitle-3">
                <img
                  src={
                    subscription.plan === PLAN_STARTUP_ID
                      ? logoStartup
                      : subscription.plan === PLAN_GROWTH_ID
                      ? logoGrowth
                      : logoScale
                  }
                  width="24px"
                  alt="Icon plan"
                />
                {subscription.plan.toLowerCase()} Plan
              </div>
            </div>
            <div className="right-side subtitle-3">
              {hasAnnualBilling() && `$${currentPlan.prices[0] * 12} / year`}
              {hasAnnualBilling() === false &&
                `$${currentPlan.prices[1]} / month`}
            </div>
          </div>
        </div>
        {/* New plan */}
        <div
          className={classnames('plan-item', `plan-${planId.toLowerCase()}`)}>
          <div className="plan-label body-3 n-700">New Plan</div>
          <div className="plan-details">
            <div className="left-side">
              <div className="plan-icon-wrapper subtitle-3">
                <img
                  src={
                    planId === PLAN_STARTUP_ID
                      ? logoStartup
                      : planId === PLAN_GROWTH_ID
                      ? logoGrowth
                      : logoScale
                  }
                  width="24px"
                  alt="Icon plan"
                />
                {planId.toLowerCase()} Plan
              </div>
            </div>
            <div className="right-side subtitle-3">
              {hasAnnualBilling() && `$${newPlan.prices[0] * 12} / year`}
              {hasAnnualBilling() === false && `$${newPlan.prices[1]} / month`}
            </div>
          </div>
        </div>

        {/* Description */}
        <div className="downgrade-description body-3 n-700">
          You will lose access to your current plan features today, and you
          won't be charged until your next billing cycle, which is on{' '}
          <b>
            {dayjs(subscription.stripeCurrentPeriodEnd * 1000).format(
              'MMMM DD, YYYY'
            )}
          </b>
          .
        </div>
        <div className="actions-wrapper">
          <Button onClick={onCancel} disabled={isDowngrading}>
            Cancel
          </Button>
          <Button primary onClick={handleDowngrade} loading={isDowngrading}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};
