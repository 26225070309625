import {default as classnames, default as classNames} from 'classnames';
import React, {useContext, useEffect} from 'react';
import {hasFlag} from '../../../../helpers/bitwise';
import {BLOCK_PRIMARY_CTA, BLOCK_SECONDARY_CTA} from '../../constants/blocks';
import {F_SLOT_TOP_BAR} from '../../constants/poke';
import {PokeContext, PokeStateContext} from '../../context';
import {useBackgroundContrastedColor} from '../../hooks/useBackgroundContrastedColor';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import {hex2Rgb} from '../../utils/hex-2-rgb';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';
import {
  requiredInteractivityBlock,
  shouldSubmitResponse,
} from './should-submit-response';

export const NAVIGATION_NONE = 'none';
export const NAVIGATION_STEP_NEXT = 'step-next';
export const NAVIGATION_STEP_PREV = 'step-prev';
export const NAVIGATION_CLOSE = 'close';
export const ACTION_URL_OPEN = 'url-open';
export const ACTION_POST_OPEN = 'post-open';
export const ACTION_BOOK_INTERVIEW = 'open-booking-url';
export const ACTION_LAUNCH_EXPERIENCE = 'launch-experience';

export const parseCtaValue = (value) => {
  const [text, navigation, action, actionData, optionalUrl] = value.split(';');

  return {
    text,
    navigation,
    action,
    actionData, // url | postId
    optionalUrl,
  };
};

export const BlockCta = ({isBottom}) => {
  const {poke} = useContext(PokeContext);
  const {currentStep} = useContext(PokeStateContext);
  const contrastedColor = useBackgroundContrastedColor('bottom');
  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_PRIMARY_CTA,
  });

  const primaryCta = currentStep.blocks.find(
    (b) => b.type === BLOCK_PRIMARY_CTA && b.removed !== true
  );
  const secondaryCta = currentStep.blocks.find(
    (b) => b.type === BLOCK_SECONDARY_CTA && b.removed !== true
  );
  const ctaLeft = []
    .concat(secondaryCta?.style?.align === 'left' ? secondaryCta : [])
    .concat(primaryCta?.style?.align === 'left' ? primaryCta : []);
  const ctaCenter = []
    .concat(secondaryCta?.style?.align === 'center' ? secondaryCta : [])
    .concat(primaryCta?.style?.align === 'center' ? primaryCta : []);
  const ctaRight = []
    .concat(secondaryCta?.style?.align === 'right' ? secondaryCta : [])
    .concat(primaryCta?.style?.align === 'right' ? primaryCta : []);
  const isBanner = hasFlag(F_SLOT_TOP_BAR, poke.boostFlags);

  return (
    <div
      className={classnames('poke-block-cta', {
        'is-animating-out': playAnimationOut,
        'is-in-poke-banner': isBanner,
        'is-bottom': isBanner !== true && isBottom === true,
      })}
      style={{
        borderTopColor: `rgba(${hex2Rgb(contrastedColor).join(',')},${
          contrastedColor === '#000000' ? '0.05' : '0.2'
        })`,
      }}>
      <div
        className={classnames('cta-left', {
          'is-empty': ctaLeft.length === 0,
        })}>
        {ctaLeft.map((cta, i) => (
          <Cta key={i} cta={cta} />
        ))}
      </div>
      <div
        className={classnames('cta-center', {
          'is-empty': ctaCenter.length === 0,
        })}>
        {ctaCenter.map((cta, i) => (
          <Cta key={i} cta={cta} />
        ))}
      </div>
      <div
        className={classnames('cta-right', {
          'is-empty': ctaRight.length === 0,
        })}>
        {ctaRight.map((cta, i) => (
          <Cta key={i} cta={cta} />
        ))}
      </div>
    </div>
  );
};

const Cta = ({cta = null}) => {
  const {poke} = useContext(PokeContext);
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    goToNextStep,
    goToPrevStep,
    close,
    onCtaClick,
    submitResponse,
    response,
    language,
    onTriggerActions,
    addFontFamily,
  } = useContext(PokeStateContext);
  const {fontColor, fontFamily, primaryColor, ...restStyles} = cta.style;

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const value = parseCtaValue(cta.value);
  const isStepRequired = requiredInteractivityBlock(currentStep);

  const isDisabled =
    value.navigation === NAVIGATION_STEP_NEXT &&
    isStepRequired === true &&
    (response == null || !response || response?.length === 0) &&
    response !== 0;

  const handleClick = () => {
    const callbackActions = cta.actions;

    if (value.navigation === NAVIGATION_STEP_NEXT) {
      if (isDisabled === true) {
        return;
      }
      // Trigger submitResponse if we find interactive blocks
      if (shouldSubmitResponse(currentStep) === true) {
        submitResponse({callbackActions});
      } else {
        goToNextStep({callbackActions});
      }
    } else if (value.navigation === NAVIGATION_STEP_PREV) {
      goToPrevStep({callbackActions});
    } else if (value.navigation === NAVIGATION_CLOSE) {
      close({forceDismiss: true, callbackActions});
    } else {
      onTriggerActions(callbackActions);
    }
  };

  const translation = cta.translations?.find((t) => t.language === language);

  return (
    <div
      className={classNames('cta-item', {
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === cta.type,
        disabled: isDisabled,
      })}
      style={{
        ...restStyles,
        fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
        color: fontColor,
        backgroundColor: primaryColor,
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (inBuilder === true) {
          onBlockSelected(cta.type);
        } else {
          handleClick();
        }
        if (
          [
            ACTION_URL_OPEN,
            ACTION_POST_OPEN,
            ACTION_BOOK_INTERVIEW,
            ACTION_LAUNCH_EXPERIENCE,
          ].includes(value.action)
        ) {
          onCtaClick({poke, step: currentStep, block: cta});
        }
      }}>
      {translation != null ? translation.value : value.text}
      <ClickableBlockOverlay />
    </div>
  );
};
