import classnames from 'classnames';
import {projectService} from 'services';
import './_Styles.scss';

export default (props) => {
  const {project, isMobile, refIframe} = props;
  const iframeUrl = projectService.getProjectPortalUrl(project);
  const classNames = classnames('preview-portal', {
    'is-portal': isMobile === false,
    'is-mobile': isMobile === true,
  });

  return (
    <div className={classNames}>
      <div className="iframe-wrapper">
        <iframe
          ref={refIframe}
          src={`${iframeUrl}/dalaran/live/portal?jimerId=preview-anonymous-jimer`}
          frameborder="0"></iframe>
      </div>
    </div>
  );
};
