import classnames from 'classnames';
import Button from 'components/Button';
import Card from 'components/Card';
import Dropdown from 'components/Dropdown';
import {Menu, MenuItem} from 'components/Menu';
import {toastDanger} from 'components/Toaster';
import {UserAvatar} from 'components/UserAvatar';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import React, {useState} from 'react';
import {generalSelector} from 'selectors';
import {commentService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import ModalJimerProfile from 'scenes/Users/components/ModalJimerProfile';

const logger = new Swaler('Comment');

const Comment = ({
  comment,
  onCommentEdit,
  onCommentDelete,
  onCommentReply,
  onCommentLiked,
  onCommentUnLiked,
  children,
}) => {
  const [isSubmittingLike, setIsSubmittingLike] = useState(null);
  const [showJimerProfileId, setShowJimerProfileId] = useState(null);
  const [likes, setLikes] = useState(comment?.likes?.length || 0); // set likes to update locally unless we refactor to a more robust like system

  const handleSubmitCommentLike = async () => {
    setIsSubmittingLike(comment.uid);

    try {
      const like = await commentService.createCommentLike(comment.uid);

      setIsSubmittingLike(null);
      onCommentLiked(like);
      setLikes((like) => like + 1);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Submitting comment like failed with error ', code);
      toastDanger([title, message], {actions});
      setIsSubmittingLike(null);
    }
  };
  const handleSubmitCommentUnLike = async (like) => {
    setIsSubmittingLike(comment.uid);
    try {
      await commentService.deleteCommentLike(comment.uid, like.uid);

      setIsSubmittingLike(null);
      onCommentUnLiked(comment.uid, like.uid);
      setLikes((like) => like - 1);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting comment like failed with error ', code);
      toastDanger([title, message], {actions});
      setIsSubmittingLike(null);
    }
  };

  const hasUsernameGenerated = (username) => {
    const nameSplit = username?.split(' ');

    if (username == null) {
      return false;
    }
    if (nameSplit.length !== 2) {
      return false;
    }
    if (nameSplit[0] !== 'Jimer') {
      return false;
    }
    if (nameSplit[1].length !== 4) {
      return false;
    }
    return true;
  };

  const me = generalSelector.getUser();
  const authorIsJimer = comment.jimer != null ? true : false;
  const author = authorIsJimer === true ? comment.jimer : comment.user;
  const username =
    authorIsJimer === false
      ? author?.username
      : hasUsernameGenerated(author?.username) &&
          author?.externalUsername != null
        ? author?.externalUsername
        : author?.username;
  const externalJimerInfo =
    authorIsJimer === true
      ? author?.externalEmail != null
        ? author?.externalEmail
        : author?.identifyToken || author?.externalUid
      : null;

  return (
    <div key={comment.uid} className="comment" id={`comment_${comment.uid}`}>
      <UserAvatar
        user={author}
        onlyAvatar
        showBadgeTeam={comment.user != null}
      ></UserAvatar>
      <div className="comment-content-wrapper">
        <Card>
          <div className="top-wrapper">
            <div className="left-side">
              <div
                className={classnames('author', {
                  'is-team': authorIsJimer === false,
                  'is-clickable': authorIsJimer === true,
                })}
                onClick={() => {
                  if (authorIsJimer === true) {
                    setShowJimerProfileId(author.uid);
                  }
                }}
              >
                {author != null ? username : 'Deleted user'}
                {externalJimerInfo != null && (
                  <span> ({externalJimerInfo})</span>
                )}
              </div>
              <div
                className="date-posted"
                title={dayjs(comment.createdAt).format('HH:mm - DD/MM/YYYY')}
              >
                {' '}
                • {dayjs(comment.createdAt).fromNow()}
              </div>
            </div>
            {(authorIsJimer === true ||
              (comment.user != null && comment.user.uid === me.uid)) && (
              <Dropdown
                trigger={
                  <Button thin tertiary iconOnly>
                    <i className="isax isax-more" />
                  </Button>
                }
                position={'bottom right'}
              >
                <Menu>
                  <MenuItem
                    icon="icon-edit"
                    iconPosition="left"
                    onClick={() => onCommentEdit(comment)}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    icon="icon-trash"
                    iconPosition="left"
                    onClick={() => onCommentDelete(comment)}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </Dropdown>
            )}
          </div>
          <div className="message">{comment.message}</div>
        </Card>
        <div className="actions">
          <Button
            loading={isSubmittingLike === comment.uid}
            muted
            iconLeft={`isax ${
              comment.myLike != null ? 'isax-heart5' : 'isax-heart'
            }`}
            thin
            onClick={() => {
              if (comment.myLike != null) {
                handleSubmitCommentUnLike(comment.myLike);
              } else {
                handleSubmitCommentLike(comment);
              }
            }}
            className={classnames('btn-like', {
              'is-liked': comment.myLike != null,
            })}
          >
            {likes} like{likes > 1 && 's'}
          </Button>
          <Button
            className="btn-comment-reply"
            thin
            muted
            inverted
            onClick={onCommentReply}
            iconLeft="isax isax-message-text"
          >
            Reply
          </Button>
        </div>
        {children}
      </div>

      {showJimerProfileId != null && (
        <ModalJimerProfile
          isOpen={showJimerProfileId != null}
          onRequestClose={() => setShowJimerProfileId(null)}
          jimerId={showJimerProfileId}
        />
      )}
    </div>
  );
};

export default Comment;
