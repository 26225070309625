import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import React, {useContext} from 'react';
import {BuilderContext} from 'scenes/Builder/context';
import {BLOCK_TYPE_INTERVIEW} from 'services/steps';
import RadioGroup from '../../../../../../components/RadioGroup';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import {fontWeightOptions} from '../Title';
import {fontFamiliesOptions} from '../Title/utils';
import './_Styles.scss';

const alignOptions = [
  {label: <i className="icon-align-b-l" />, value: 'left'},
  {label: <i className="icon-align-b-c" />, value: 'center'},
  {label: <i className="icon-align-b-r" />, value: 'right'},
];

const Interview = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_INTERVIEW, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_INTERVIEW);

  if (block == null) {
    return <></>;
  }

  const {value, style} = block;
  const [buttonBookText, calendarUrl, buttonCancelText] = value.split(';');

  return (
    <div className="block-settings interview">
      <div className="section">
        <div className="section-title">Content</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Calendar url</div>
            <div className="section-item-content">
              <InputGroup
                className="button-content-input"
                value={calendarUrl}
                onChange={({target}) =>
                  updateBlock({
                    value: `${buttonBookText};${target.value};${buttonCancelText}`,
                  })
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Book text</div>
            <div className="section-item-content">
              <InputGroup
                className="button-content-input"
                value={buttonBookText}
                onChange={({target}) =>
                  updateBlock({
                    value: `${target.value};${calendarUrl};${buttonCancelText}`,
                  })
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Cancel text</div>
            <div className="section-item-content">
              <InputGroup
                className="button-content-input"
                value={buttonCancelText}
                onChange={({target}) =>
                  updateBlock({
                    value: `${buttonBookText};${calendarUrl};${target.value}`,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">Layout</div>
        <div className="section-content">
          <InterviewStyle style={style} updateStyle={updateBlock} />
        </div>
      </div>
    </div>
  );
};

export const InterviewStyle = ({style, updateStyle}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Font family</div>
        <div className="section-item-content">
          <SelectGroup
            small
            options={fontFamiliesOptions}
            value={fontFamiliesOptions.find(
              (o) => o.value === style.fontFamily
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontFamily: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font-size</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.fontSize}
            min={8}
            max={42}
            onChange={(value) =>
              updateStyle({
                style: {...style, fontSize: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font weight</div>
        <div className="section-item-content">
          <SelectGroup
            isSearchable={false}
            small
            options={fontWeightOptions}
            value={fontWeightOptions.find(
              (o) => o.value === (style.fontWeight || '400')
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontWeight: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Padding</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.padding}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, padding: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Radius</div>
        <div className="section-item-content">
          <PixelPicker
            value={style.borderRadius}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderRadius: value},
              })
            }
            radius
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Primary text color</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Primary text color"
            value={style.fontPrimaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, fontPrimaryColor: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Primary background</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Primary background"
            value={style.primaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, primaryColor: value},
              })
            }
            erasable
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Primary border</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Primary border"
            value={style.borderPrimaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderPrimaryColor: value},
              })
            }
            erasable
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Secondary text color</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Secondary text color"
            value={style.fontSecondaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, fontSecondaryColor: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Secondary background</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Secondary background"
            value={style.secondaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, secondaryColor: value},
              })
            }
            erasable
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Secondary border</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Secondary border"
            value={style.borderSecondaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderSecondaryColor: value},
              })
            }
            erasable
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Position</div>
        <div className="section-item-content">
          <RadioGroup
            value={style.align}
            options={alignOptions}
            onSelect={(value) =>
              updateStyle({
                style: {
                  ...style,
                  align: value,
                },
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export default Interview;
