import React, {useRef} from 'react';
import './_Styles.scss';
import {buildStormwindUrl} from 'helpers/utils';
import {projectService} from 'services';
import {generalSelector} from 'selectors';
import {useSelector} from 'react-redux';
import IframeResizer from 'iframe-resizer-react';
import {Swaler} from 'swaler';
import {func} from 'prop-types';

const logger = new Swaler('ChangelogDrawerPreview');

const propTypes = {
  onClose: func,
};

const defaultProps = {
  onClose: () => {},
};

const ChangelogDrawerPreview = ({onClose}) => {
  const iframeRef = useRef(null);

  const project = useSelector((state) => generalSelector.getProject(state));

  const url = buildStormwindUrl(
    projectService.getProjectPortalUrl(project),
    `/w/feed`
  );

  return (
    <div className="changelog-drawer-preview">
      <div className="changelog-wrapper">
        <IframeResizer
          forwardRef={iframeRef}
          title="changelog-preview-iframe"
          loading="lazy"
          src={url}
          className="changelog-iframe"
          allowFullScreen
          sizeHeight={false}
          onMessage={({message}) => {
            try {
              const m = JSON.parse(message);

              if (m.action === 'CLOSE_CHANGELOG') {
                onClose();
              }
            } catch (err) {
              logger.error(`Couldn't parse message sent by widget: ${err}`);
            }
          }}
        />
      </div>
    </div>
  );
};

ChangelogDrawerPreview.propTypes = propTypes;
ChangelogDrawerPreview.defaultProps = defaultProps;

export default ChangelogDrawerPreview;
