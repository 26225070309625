import classNames from 'classnames';
import {validateAction} from 'helpers/step';
import {bool, func, object} from 'prop-types';
import {useState} from 'react';
import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import SettingsBookInterview from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/EditActionDropdown/components/SettingsBookInterview';
import SettingsLaunchExperience from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/EditActionDropdown/components/SettingsLaunchExperience';
import SettingsNavigateTo from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/EditActionDropdown/components/SettingsNavigateTo';
import SettingsOpenPost from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/EditActionDropdown/components/SettingsOpenPost';
import SettingsRunJSCode from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/EditActionDropdown/components/SettingsRunJSCode';
import {
  STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  STEP_CONDITION_ACTION_TYPE_DISMISS,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_OPEN_POST,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
} from 'services/steps';
import './_Styles.scss';

const propTypes = {
  action: object.isRequired,
  setAction: func,
  onDelete: func,
  defaultOpen: bool,
};

const defaultProps = {
  setAction: () => {},
  onDelete: () => {},
  defaultOpen: false,
};

const ButtonAction = ({
  key,
  action,
  setAction,
  onDelete,
  defaultOpen,
  ...rest
}) => {
  const [preventClose, setPreventClose] = useState(false);

  const {type} = action;

  const isRunJSCode = type === STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE;
  const isDimiss = type === STEP_CONDITION_ACTION_TYPE_DISMISS;
  const isLaunchExperience =
    type === STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE;
  const isNavigateTo = type === STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO;
  const isOpenPost = type === STEP_CONDITION_ACTION_TYPE_OPEN_POST;
  const isBookInterview = type === STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW;

  const isValid = validateAction(action).length === 0;

  let content = '',
    icon,
    iconClassName,
    title;

  if (isLaunchExperience) {
    icon = 'isax isax-routing';
    iconClassName = 'launch-experience';
    title = 'Launch experience';
    content = (
      <SettingsLaunchExperience action={action} setAction={setAction} />
    );
  } else if (isNavigateTo) {
    icon = 'isax isax-send-2';
    iconClassName = 'navigate-to';
    title = 'Navigate to';
    content = (
      <SettingsNavigateTo
        action={action}
        setAction={setAction}
        setPreventClose={setPreventClose}
      />
    );
  } else if (isRunJSCode) {
    icon = 'isax isax-document-code';
    iconClassName = 'run-js-code';
    title = 'Run JavaScript code';
    content = <SettingsRunJSCode action={action} setAction={setAction} />;
  } else if (isDimiss) {
    icon = 'isax isax-close-square';
    iconClassName = 'dismiss';
    title = 'Dismiss flow';
  } else if (isOpenPost) {
    icon = 'isax isax-slider-vertical-1';
    iconClassName = 'open-post';
    title = 'Open post';
    content = <SettingsOpenPost action={action} setAction={setAction} />;
  } else if (isBookInterview) {
    icon = 'isax isax-calendar-search';
    iconClassName = 'book-interview';
    title = 'Book interview';
    content = <SettingsBookInterview action={action} setAction={setAction} />;
  }

  return (
    <PopupSetting
      key={key}
      className="button-action-dropdown"
      large
      title={
        <>
          <div className={classNames('icon-wrapper', iconClassName)}>
            <i className={icon} />
          </div>
          {title}
        </>
      }
      trigger={
        <ClickableInput
          className="button-action"
          value={title}
          leftLabel={
            <div className={classNames('icon-wrapper', iconClassName)}>
              <i className={icon} />
            </div>
          }
          erasable={type !== STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW}
          onErase={onDelete}
          invalid={isValid !== true}
        />
      }
      dropdownProps={{
        position: 'left top',
        offsetX: 8,
        isOpen: defaultOpen,
        ...(preventClose === true ? {closeOnDocumentClick: false} : {}),
      }}
      content={content}
      {...rest}
    />
  );
};

ButtonAction.propTypes = propTypes;
ButtonAction.defaultProps = defaultProps;

export default ButtonAction;
