import {default as classnames, default as classNames} from 'classnames';
import fontColorContrast from 'font-color-contrast';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, {useContext, useEffect, useState} from 'react';
import {EmojiIdk, EmojiNah, EmojiYeah} from '../../../../components/Emojis';
import {hasFlag} from '../../../../helpers/bitwise';
import {
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_SLIDER,
  BLOCK_STEPPER,
  getDefaultResponseForBlock,
} from '../../constants/blocks';
import {PokeContext, PokeStateContext} from '../../context';
import {BACKGROUND_TYPE_COLOR} from '../BlockGradient';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';

export const F_SLIDER_ABOVE_EMOJI_ANIMATION = 8;
export const SLIDER_TYPE_EMOJI = 'emoji';
export const SLIDER_TYPE_SMILEY = 'smiley';

export const parseSliderValue = (value) => {
  const [type, customEmoji, labelLeft, labelRight] = value.split(';');

  return {
    type,
    customEmoji,
    labelLeft,
    labelRight,
  };
};

export const BlockSlider = () => {
  const {poke} = useContext(PokeContext);
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    response = getDefaultResponseForBlock(BLOCK_SLIDER),
    updateResponse,
    inBuilder,
    language,
    addFontFamily,
  } = useContext(PokeStateContext);

  const [showExtraEmoji, setShowExtraEmoji] = useState(false);

  const block = currentStep.blocks.find((b) => b.type === BLOCK_SLIDER);
  const {type, customEmoji, labelLeft, labelRight} = parseSliderValue(
    block.value
  );
  const {
    primaryColor,
    secondaryColor,
    labelsColor: forcedLabelsColor,
    fontFamily = 'Inter',
    ...restStyle
  } = block.style;
  const shouldShowAboveEmoji =
    type === SLIDER_TYPE_EMOJI &&
    hasFlag(block.flags, F_SLIDER_ABOVE_EMOJI_ANIMATION);
  const labelsColor =
    forcedLabelsColor != null
      ? forcedLabelsColor
      : poke?.style?.background == null
      ? '#FFFFFFFF'
      : poke.style.background.type !== BACKGROUND_TYPE_COLOR
      ? '#FFFFFFFF'
      : poke.style.background.primaryColor;

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const handleShowExtraEmoji = () => {
    setShowExtraEmoji((showExtraEmoji) =>
      showExtraEmoji === true ? false : false
    );
  };
  const handleResponse = (value) => {
    updateResponse(value);
  };

  useEffect(() => {
    if (shouldShowAboveEmoji === true) {
      window.addEventListener('mouseup', handleShowExtraEmoji);
    }

    return () => {
      if (shouldShowAboveEmoji === true) {
        window.removeEventListener('mouseup', handleShowExtraEmoji);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const translation = block.translations?.find((t) => t.language === language);
  const [translatedLabelLeft, translatedLabelRight] = translation?.value?.split(
    ';'
  ) ?? [labelLeft, labelRight];

  const hasCta =
    currentStep.blocks.some((b) => {
      return (
        (b.type === BLOCK_PRIMARY_CTA && b.removed !== true) ||
        (b.type === BLOCK_SECONDARY_CTA && b.removed !== true)
      );
    }) === true;

  const hasStepperBelow =
    hasCta !== true &&
    currentStep.blocks.some((b) => {
      return b.type === BLOCK_STEPPER && b.style.position === 'bottom';
    }) === true;

  const handleSetDefault = () => {
    if (response === '') {
      updateResponse(500);
    }
  };

  return (
    <div
      className={classNames('poke-block-slider', {
        'poke-block-clickable': inBuilder === true,
        'has-cta': hasCta === true,
        'has-stepper-below': hasStepperBelow === true,
        'is-bottom-block': hasCta !== true && hasStepperBelow !== true,
        selected: selectedBlock === BLOCK_SLIDER,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_SLIDER);
      }}>
      <Slider
        min={0}
        max={1000}
        handleRender={({props}) => {
          if (type === SLIDER_TYPE_SMILEY) {
            return (
              <div {...props}>
                {props['aria-valuenow'] > 667 ? (
                  <EmojiYeah width={48} animated />
                ) : props['aria-valuenow'] > 334 ? (
                  <EmojiIdk width={48} animated />
                ) : (
                  <EmojiNah width={48} animated />
                )}
              </div>
            );
          }
          return (
            <div className="slider-custom-emoji-wrapper">
              <div
                {...props}
                className={classnames('slider-custom-emoji', props.className)}
                onMouseDown={(e) => {
                  if (shouldShowAboveEmoji === true) {
                    setShowExtraEmoji(true);
                  }
                  props.onMouseDown(e);
                }}>
                {showExtraEmoji === true && (
                  <div
                    className="slider-custom-emoji-extra-wrapper"
                    style={{
                      top: `-${25 + response / 20}px`,
                      transform: `scale(${1 + 4 * (response / 1000)})`,
                      padding: `0px ${2 + response / 1000}px`,
                    }}>
                    <div className="slider-custom-emoji-extra">
                      {customEmoji}
                    </div>
                  </div>
                )}
                {customEmoji}
              </div>
            </div>
          );
        }}
        onChange={(value) => handleResponse(value)}
        onBeforeChange={() => handleSetDefault()}
        value={response || response === 0 ? response : 500}
        railStyle={{
          ...restStyle,
          backgroundColor: secondaryColor,
        }}
        trackStyle={{
          ...restStyle,
          backgroundColor: primaryColor,
        }}
      />
      <div
        className="labels-wrapper"
        style={{
          fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
        }}>
        <div
          className="label-left"
          style={{
            color:
              forcedLabelsColor != null
                ? labelsColor
                : fontColorContrast(
                    labelsColor.slice(
                      0,
                      labelsColor.length > 7
                        ? labelsColor.length - 2
                        : labelsColor.length
                    )
                  ),
          }}>
          {translatedLabelLeft}
        </div>
        <div
          className="label-right"
          style={{
            color:
              forcedLabelsColor != null
                ? labelsColor
                : fontColorContrast(
                    labelsColor.slice(
                      0,
                      labelsColor.length > 7
                        ? labelsColor.length - 2
                        : labelsColor.length
                    )
                  ),
          }}>
          {translatedLabelRight}
        </div>
      </div>
      <ClickableBlockOverlay />
    </div>
  );
};
